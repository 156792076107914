import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import ReportNavbar from "./ReportNavbar";
import TableOfContent from "./TableOfContent";
import api from "./axiosConfig";
import Section from "./Section";
// import tableOfContentData from "./TableOfContent.json";
import tableOfContentData from "./TableofContentDemo.json";
import SerpAiLogo from "../images/serpai.png";
import TopAiToolsLogo from "../images/topaitools.png";
import ThereIsAnAIForThatLogo from "../images/thereisanaiforthat.png";
import ToolifyLogo from "../images/toolify.png";
import AiHuntLogo from "../images/aihunt.png";
import ScaledUpIdeas from "./ScaledUpIdeas";
import ReportFooter from "./ReportFooter";
import TopBanner from "./TopBanner";

function BlurComponent() {
  const navigate = useNavigate();
  const [timeLeft, setTimeLeft] = useState(15 * 60); // 15 minutes in seconds

  useEffect(() => {
    if (!timeLeft) return;

    const intervalId = setInterval(() => {
      setTimeLeft(timeLeft - 1);
    }, 1000);

    return () => clearInterval(intervalId);
  }, [timeLeft]);

  const redirectToPricing = () => {
    navigate("/credits#pricing-card");
  };

  const formatTime = () => {
    const minutes = Math.floor(timeLeft / 60);
    const seconds = timeLeft % 60;
    return `${minutes}:${seconds < 10 ? "0" : ""}${seconds}`;
  };

  return (
    <div className="relative w-full max-w-4xl mx-auto mt-10">
      <div className="backdrop-blur-md bg-white/90 rounded-xl p-8 text-center shadow-xl border-t-4 border-blue-600">
        <h2 className="text-5xl font-bold text-gray-800 mb-6">
          {/* 🔒 Unlock Full Potential of Your Business Idea */}
          🔒 Unlock Full 100+ Page Report
        </h2>
        <p className="mb-6 text-2xl text-gray-700">
          You've just scratched the surface with our "Executive Summary".Upnext are 3 New Improved Versions of Your Business Idea,Market
          Size,Competitor Analysis,Building Your MVP,Defining Core Features, 3 Tap UI,Landing Page Copy, Marketing your MVP,Ad Copies,Email
          Sequence,Customer Personas,Raise Capital, a tailored startup checklist,list of potential investors ,relevant government schemes
          and 45+ more sections.
          <a
            href="/previewx/"
            className="bg-grren-300 text-2xl underline font-lg hover:bg-blue-400 text-blue-500 hover:text-white px-2 py-1 rounded transition duration-300 inline-flex items-center justify-center sm:justify-start"
            target="_blank"
            rel="noopener noreferrer"
          >
            [👉 See a Full Business Report]
          </a>
        </p>
        <button
          onClick={redirectToPricing}
          className="bg-green-500 hover:bg-green-600 text-white font-bold py-5 mt-2 px-10 rounded-full text-lg animate-pulse "
        >
          📈 Get Full Report Now
        </button>
      </div>
    </div>
  );
}

const UnpurchasedBanner = () => {
  return (
    <div className="bg-indigo-50 border-t-4 border-indigo-500 rounded-lg text-indigo-900 px-6 py-4 shadow-lg my-4 mx-4">
      <div className="flex flex-col md:flex-row items-center">
        <div className="shrink-0">
          <svg className="fill-current h-8 w-8 text-indigo-500" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
            <path d="M8.707 14.707a1 1 0 0 1-1.414 0L2 9.414 3.414 8l4.293 4.293L14.586 5H16v1.586l-7.293 7.293z" />
          </svg>
        </div>
        <div className="mt-4 md:mt-0 md:ml-4">
          <h3 className="font-semibold text-lg md:text-xl">Unlock the Secrets to Business Success</h3>
          <p className="mt-1 text-sm md:text-base">AI-powered 45+ feature analysis to validate, refine, and optimize your business idea.</p>
          <p className="mt-2 text-md md:text-lg font-semibold">⭐⭐⭐⭐⭐ Trusted by over 5,000 businesses, investors, and analysts</p>
          <div className="flex flex-wrap justify-center md:justify-start items-center gap-4 mt-4">
            {/* Image sources are placeholders; replace with your actual paths */}
            <img src={TopAiToolsLogo} alt="TopAiTools Logo" className="h-10" />
            <span className="font-semibold text-gray-800">TopAiTools</span>
            <img src={SerpAiLogo} alt="Serp.ai Logo" className="h-10" />
            <span className="font-semibold text-gray-800">SERPAI</span>
            <img src={ThereIsAnAIForThatLogo} alt="ThereIsAnAIForThat Logo" className="h-10" />
            <span className="font-semibold text-gray-800">ThereIsAnAIForThat</span>

            <img src={ToolifyLogo} alt="ToolifyLogo" className="h-10" />
            <span className="font-semibold text-gray-800">Toolify.AI</span>
            {/* <img src={AiHuntLogo} alt="AiHuntLogo" className="h-10" />
            <span className="font-semibold text-gray-800">AHunt</span> */}
          </div>
        </div>
      </div>
    </div>
  );
};
const Report = () => {
  const [currentSection, setCurrentSection] = useState(1);
  const [purchased, setPurchased] = useState(false);
  const [language, setLanguage] = useState("english"); // Default to English
  const [preview, setPreview] = useState(false);
  const [sectionsToLoad, setSectionsToLoad] = useState([]);
  const { reportId } = useParams();
  const [userEmail, setUserEmail] = useState("");
  const [userReportCredits, setUserReportCredits] = useState(0);
  const [reportType, setReportType] = useState("full"); // Example, set this based on your app logic
  const [scaledUpIdeas, setScaledUpIdeas] = useState([]);
  const [isLoadingIdeas, setIsLoadingIdeas] = useState(false);

  useEffect(() => {
    // console.log("Component mounted.ha ha ");
  }, []);

  useEffect(() => {
    // console.log("useEffect triggered for fetching scaled-up ideas.");
    // console.log("Current reportId:", reportId);

    const generateScaledUpIdeas = async () => {
      try {
        // console.log(`Generating ideas for reportId: ${reportId}`);
        const generateResponse = await api.post(`generate_scaledup_ideas/${reportId}/`);
        if (generateResponse.status === 200) {
          // console.log("Generated new scaled-up ideas successfully.");
        }
      } catch (error) {
        console.error("Error generating scaled-up ideas:", error);
      }
    };

    const fetchScaledUpIdeas = async () => {
      if (!reportId) {
        // console.log("Report ID is not defined.");
        return;
      }
      setIsLoadingIdeas(true); // Set loading to true before fetching

      try {
        // console.log(`Fetching ideas for reportId: ${reportId}`);
        let response = await api.get(`list_scaledup_ideas/${reportId}`);
        // console.log("Response data:", response.data);

        if (response.data.length === 0) {
          await generateScaledUpIdeas();
          // Refetch the scaled-up ideas list after generating new ideas
          response = await api.get(`list_scaledup_ideas/${reportId}`);
        }

        const validIdeas = response.data.filter((idea) => idea.title && idea.description);
        setScaledUpIdeas(validIdeas);
      } catch (error) {
        console.error("Error fetching scaled-up ideas:", error);
        setIsLoadingIdeas(false); // End loading in case of error
      } finally {
        setIsLoadingIdeas(false); // Set loading to false after fetching or error
      }
    };

    fetchScaledUpIdeas();
  }, [reportId]);

  const handleViewReportClick = async (idea) => {
    const mergedInput = `${idea.title} - ${idea.description}`;
    try {
      const response = await api.post("/create-report/", {
        business_idea_input: mergedInput,
      });
      if (response.status === 200 || response.status === 201) {
        window.location.href = `/full-report/${response.data.report_id}`;
      }
    } catch (error) {
      console.error("Error creating new report:", error);
    }
  };

  // Update ScaledUpIdeasDisplay to accept the handleViewReportClick function
  // and pass it to each "View Report" button

  useEffect(() => {
    const handleScroll = () => {
      const sections = document.querySelectorAll("section");
      sections.forEach((section, index) => {
        const rect = section.getBoundingClientRect();
        if (rect.top <= 0 && rect.bottom >= 0) {
          setCurrentSection(index + 1);
        }
      });
    };

    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  // useEffect(() => {
  //   // existing useEffect logic

  //   // Display an alert if not purchased and restricted actions are attempted
  //   if (!purchased) {
  //     const showAlert = () => {
  //       alert("Hold on! You are not allowed to do this action.");
  //     };

  //     const handleRightClick = (event) => {
  //       event.preventDefault();
  //       showAlert();
  //     };

  //     const disableCopyShortcut = (event) => {
  //       if ((event.ctrlKey || event.metaKey) && event.key === "c") {
  //         event.preventDefault();
  //         showAlert();
  //       }
  //     };

  //     document.addEventListener("contextmenu", handleRightClick);
  //     document.addEventListener("keydown", disableCopyShortcut);

  //     return () => {
  //       document.removeEventListener("contextmenu", handleRightClick);
  //       document.removeEventListener("keydown", disableCopyShortcut);
  //     };
  //   }
  // }, [purchased]);

  const fetchUserCredits = async () => {
    try {
      const response = await api.get("get-user-credits/");
      if (response.status === 200) {
        setUserReportCredits(response.data.report_credits);
      }
    } catch (error) {
      console.error("Error fetching user credits:", error);
    }
  };

  const fetchReportDetails = async () => {
    try {
      const reportDetailsResponse = await api.get(`${process.env.REACT_APP_API_BASE_URL}report/${reportId}/`);
      const reportData = reportDetailsResponse.data;
      setPurchased(reportData.is_purchased);
      setPreview(reportData.is_preview);
      setLanguage(reportData.language);
    } catch (error) {
      console.error("Error fetching report data:", error);
    }
  };

  useEffect(() => {
    fetchUserCredits();
    fetchReportDetailsAndUpdateCredits();
  }, [reportId, userReportCredits]); // Add use\ReportCredits as a dependency

  const fetchReportDetailsAndUpdateCredits = async () => {
    try {
      // Fetch report details
      const reportDetailsResponse = await api.get(`${process.env.REACT_APP_API_BASE_URL}report/${reportId}/`);
      const reportData = reportDetailsResponse.data;
      setLanguage(reportData.language);

      // Check and update purchased status
      if (!reportData.is_purchased && userReportCredits > 0) {
        // Deduct a credit and update the report's purchased status
        const updateResponse = await api.post(`${process.env.REACT_APP_API_BASE_URL}update-minireport-purchase-status/`, {
          report_id: reportId,
        });
        if (updateResponse.status === 200) {
          setPurchased(true);
          setUserReportCredits(userReportCredits - 1);
        }
      } else {
        setPurchased(reportData.is_purchased);
      }

      setPreview(reportData.is_preview);
    } catch (error) {
      console.error("Error updating report status:", error);
    }
  };

  const shouldLoadSection = (sectionNumber) => {
    const sectionNum = parseInt(sectionNumber, 10); // Parse section number to integer
    return purchased || sectionNum <= 1; // Load first three sections regardless of purchase status
  };

  return (
    <div className="font-inter antialiased bg-white text-gray-900 tracking-tight">
      {/* {purchased && <TopBanner />}  */}
      {/* <TopBanner /> */}
      <ReportNavbar purchased={purchased} />
      <div className="h-1 bg-gray-200 fixed top-0 left-0 z-50 w-full">
        <div
          style={{
            width: `${(currentSection / tableOfContentData.sections.length) * 100}%`,
          }}
          className="h-1 bg-gradient-to-r from-blue-600 to-blue-400"
        ></div>
      </div>
      <div className="flex flex-col md:flex-row grow">
        <div className="md:w-1/4 w-full p-4 md:p-0">
          <TableOfContent
            currentSection={currentSection}
            isPurchased={purchased} // Replace 'purchased' with the appropriate state or prop
            language={language}
          />
        </div>
        <div className="md:w-3/4 w-full p-4 md:pt-0">
          {/* {!purchased && <UnpurchasedBanner />} */}
          {/* {<UnpurchasedBanner />} */}
          {tableOfContentData.sections.map((section, sectionIndex) =>
            section.content.map((content, contentIndex) => (
              <React.Fragment key={content.number}>
                {shouldLoadSection(content.number) && (
                  <Section
                    sectionTitle={content.text}
                    sectionNumber={content.number}
                    reportId={reportId}
                    id={content.number}
                    purchased={purchased}
                    defaultOpen={content.number <= 1} // Open first three sections by default
                    preview={preview}
                    language={language}
                  />
                )}
              </React.Fragment>
            ))
          )}

          {/* {scaledUpIdeas.length > 0 || isLoadingIdeas ? (
            <ScaledUpIdeas
              ideas={scaledUpIdeas}
              onViewReportClick={handleViewReportClick}
              isLoading={isLoadingIdeas}
              purchased={purchased}
            />
          ) : null} */}
          <ScaledUpIdeas ideas={scaledUpIdeas} onViewReportClick={handleViewReportClick} isLoading={isLoadingIdeas} purchased={purchased} />

          {!purchased && <BlurComponent />}

          {/* {scaledUpIdeas.length > 0 && (
            <ScaledUpIdeas
              ideas={scaledUpIdeas}
              onViewReportClick={handleViewReportClick}
            />
          )} */}
        </div>
      </div>
      <ReportFooter />
    </div>
  );
};

export default Report;
