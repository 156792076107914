import React from "react";
import { Link } from "react-router-dom";

const FooterLink = ({ to, children, highlighted = false }) => (
  <Link
    to={to}
    className={`transition-colors duration-300 ${highlighted ? "text-black hover:text-blue-800 " : "text-gray-600 hover:text-green-600"}`}
  >
    {children}
  </Link>
);

export default function Footer() {
  return (
    <footer className="bg-white border-t border-gray-200">
      <div className="max-w-6xl mx-auto px-4 sm:px-6">
        <div className="py-8 md:py-12 flex flex-wrap justify-between items-center">
          <ul className="flex flex-wrap justify-center space-x-5 mb-6 md:mb-0">
            <li>
              <FooterLink to="https://feedbackbyai1.lemonsqueezy.com/affiliates/">Affiliate Program</FooterLink>
            </li>
            <li>
              <FooterLink to="/terms/">Terms</FooterLink>
            </li>
            <li>
              <FooterLink to="/privacy/">Privacy</FooterLink>
            </li>
            <li>
              <FooterLink to="mailto:business@feedbackbyai.com">Support</FooterLink>
            </li>
            <li>
              <FooterLink to="/blog/">Blog</FooterLink>
            </li>
            <li>
              <FooterLink to="https://twitter.com/feedbackbyai">Twitter</FooterLink>
            </li>
          </ul>
          <div className="text-sm text-gray-600">© 2025 FeedbackbyAI. All rights reserved.</div>
        </div>
      </div>
    </footer>
  );
}
