// import React, { useState, useEffect } from "react";
// import { Link, useLocation } from "react-router-dom";
// import { motion } from "framer-motion";
// import api from "./axiosConfig";
// import DashboardHeader from "./DashboardHeader";
// import ReportFooter from "./ReportFooter";
// import Modal from "react-modal";
// import {
//   ChevronDown,
//   Sparkles,
//   Radio,
//   Eye,
//   MessageSquare,
//   Clock,
//   Target,
//   Gift,
//   Star,
//   ChevronRight,
//   X,
//   ExternalLink,
//   Megaphone,
//   Activity,
//   Users,
//   Calendar,
//   Building,
//   TrendingUp,
//   Mail,
//   CheckCircle,
//   Search,
//   ArrowRight,
//   Zap,
//   Shield,
// } from "lucide-react";
// import TopBanner from "./TopBanner";

// const CustomModal = ({ isOpen, onRequestClose, content }) => {
//   const [isCopied, setIsCopied] = useState(false);

//   const handleCopy = async () => {
//     try {
//       await navigator.clipboard.writeText(content);
//       setIsCopied(true);
//       setTimeout(() => setIsCopied(false), 2000);
//     } catch (err) {
//       console.error("Failed to copy text: ", err);
//     }
//   };

//   return (
//     <Modal
//       isOpen={isOpen}
//       onRequestClose={onRequestClose}
//       className="fixed inset-0 z-50 flex items-center justify-center"
//       overlayClassName="fixed inset-0 bg-black bg-opacity-50 z-40"
//     >
//       <motion.div
//         initial={{ opacity: 0, scale: 0.95 }}
//         animate={{ opacity: 1, scale: 1 }}
//         exit={{ opacity: 0, scale: 0.95 }}
//         transition={{ duration: 0.3 }}
//         className="bg-white rounded-2xl shadow-xl max-w-lg w-full mx-4 p-6"
//       >
//         <h3 className="text-xl font-semibold text-gray-900 mb-4">Full Input</h3>
//         <div className="max-h-96 overflow-y-auto p-4 bg-gray-50 rounded-lg mb-4">
//           <p className="text-sm text-gray-700 whitespace-pre-wrap">{content}</p>
//         </div>
//         <div className="flex justify-end space-x-3">
//           <button
//             onClick={handleCopy}
//             className={`px-4 py-2 rounded-lg text-white font-medium transition-colors ${
//               isCopied ? "bg-green-600" : "bg-blue-600 hover:bg-blue-700"
//             }`}
//           >
//             {isCopied ? "Copied!" : "Copy Full Text"}
//           </button>
//           <button
//             onClick={onRequestClose}
//             className="px-4 py-2 bg-gray-200 rounded-lg text-gray-700 font-medium hover:bg-gray-300 transition-colors"
//           >
//             Close
//           </button>
//         </div>
//       </motion.div>
//     </Modal>
//   );
// };

// export default function ReportDashboard() {
//   const location = useLocation();
//   const [businessIdea, setBusinessIdea] = useState("");
//   const [language, setLanguage] = useState("english");
//   const [isSubmitting, setIsSubmitting] = useState(false);
//   const [latestFullReports, setLatestFullReports] = useState([]);
//   const [isFetchingReports, setIsFetchingReports] = useState(false);
//   const [message, setMessage] = useState("");
//   const [showRechargeLink, setShowRechargeLink] = useState(false);
//   const [isModalOpen, setIsModalOpen] = useState(false);
//   const [selectedReport, setSelectedReport] = useState(null);
//   const [showBoothForm, setShowBoothForm] = useState(false);
//   const [showModal, setShowModal] = useState(false);
//   const [showPreview, setShowPreview] = useState(false);
//   const [showBusinessMatchModal, setShowBusinessMatchModal] = useState(false);

//   useEffect(() => {
//     async function fetchReports() {
//       setIsFetchingReports(true);
//       try {
//         const response = await api.get("user-latest-report/");
//         if (response.status === 200) {
//           setLatestFullReports(response.data);
//         }
//       } catch (error) {
//         console.error("Error fetching reports:", error);
//       } finally {
//         setIsFetchingReports(false);
//       }
//     }

//     fetchReports();
//   }, []);

//   async function handleSubmit(e) {
//     e.preventDefault();
//     setMessage("");
//     setShowRechargeLink(false);
//     setIsSubmitting(true);

//     const timeoutId = setTimeout(() => {
//       alert("Submission is taking longer than expected. Please try again.");
//       setIsSubmitting(false);
//     }, 10000);

//     const confirmReport = window.confirm("This will deduct one report credit. Are you sure you want to continue?");
//     if (!confirmReport) {
//       clearTimeout(timeoutId);
//       setIsSubmitting(false);
//       return;
//     }

//     try {
//       const response = await api.post("create-report/", {
//         business_idea_input: businessIdea,
//         language,
//       });
//       clearTimeout(timeoutId);

//       if (response.status === 200 || response.status === 201) {
//         window.location.href = `/full-report/${response.data.report_id}`;
//       }
//     } catch (error) {
//       clearTimeout(timeoutId);
//       if (error.response) {
//         const errorMessage = error.response.data.message;
//         setMessage(errorMessage || "An error occurred. Please try again.");
//         if (errorMessage.includes("Not enough credits")) {
//           setShowRechargeLink(true);
//         }
//       } else {
//         setMessage("An unexpected error occurred.");
//       }
//       console.error("Failed to submit form:", error.response?.data);
//     } finally {
//       setIsSubmitting(false);
//     }
//   }

//   const openModal = (report) => {
//     setSelectedReport(report);
//     setIsModalOpen(true);
//   };

//   const closeModal = () => {
//     setIsModalOpen(false);
//     setSelectedReport(null);
//   };

//   return (
//     <div className="font-sans bg-gradient-to-b from-green-50 to-white min-h-screen">
//       <TopBanner />
//       <DashboardHeader />
//       {/* <NetworkPreviewBanner /> */}

//       <main className="max-w-4xl mx-auto px-4 py-12">
//         {/* Add right after main opening */}

//         {/* <div className="flex justify-center gap-4 mb-8">
//           <button className="px-4 py-2 text-green-800 font-medium border-b-2 border-green-600">Validate Idea</button>
//           <button className="px-4 py-2 text-green-800 font-medium border-b-2 border-green-600">Run AI Interviews</button>
//           <button className="px-4 py-2 text-gray-500 font-medium hover:text-gray-700 relative group">
//             Launch Page
//             <span className="absolute -top-1 -right-1 bg-green-500 text-white text-xs px-1.5 py-0.5 rounded-full">New</span>
//           </button>
//         </div> */}

//         <motion.h1
//           initial={{ opacity: 0, y: -20 }}
//           animate={{ opacity: 1, y: 0 }}
//           transition={{ duration: 0.5 }}
//           className="text-4xl md:text-5xl font-bold text-green-800 text-center mb-8"
//         >
//           Analyze My Business Idea
//         </motion.h1>

//         <p className="text-center text-gray-600 mb-12 max-w-2xl mx-auto">Get comprehensive analysis to refine your business concept.</p>

//         <div className="bg-white rounded-2xl shadow-xl p-8 mb-12">
//           {message && (
//             <div
//               className={`mb-6 p-4 rounded-lg ${
//                 showRechargeLink ? "bg-yellow-50 border-l-4 border-yellow-400" : "bg-red-50 border-l-4 border-red-400"
//               }`}
//             >
//               <p className="text-sm font-medium text-gray-800">{message}</p>
//               {showRechargeLink && (
//                 <Link to="/credits/" className="inline-block mt-2 text-green-600 hover:text-green-800 font-semibold">
//                   Recharge credits →
//                 </Link>
//               )}
//             </div>
//           )}

//           <form onSubmit={handleSubmit} className="space-y-6">
//             <div>
//               <label htmlFor="businessIdea" className="block text-sm font-medium text-gray-700 mb-2">
//                 Describe your business idea
//               </label>
//               <textarea
//                 id="businessIdea"
//                 value={businessIdea}
//                 onChange={(e) => setBusinessIdea(e.target.value)}
//                 placeholder="Detail your business concept, including unique features, target market, pricing strategy, and potential challenges. The more comprehensive your description, the more tailored our analysis will be."
//                 rows="7"
//                 className="w-full p-4 text-gray-700 bg-gray-50 border border-gray-300 rounded-lg focus:ring-2 focus:ring-green-500 focus:border-transparent transition-all duration-200"
//                 required
//               />
//             </div>

//             <div>
//               <label htmlFor="language" className="block text-sm font-medium text-gray-700 mb-2">
//                 Select Report Language
//               </label>
//               <div className="relative">
//                 <select
//                   id="language"
//                   value={language}
//                   onChange={(e) => setLanguage(e.target.value)}
//                   className="block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:ring-green-500 focus:border-green-500 sm:text-sm rounded-lg appearance-none bg-white"
//                 >
//                   <option value="english">English</option>
//                   <option value="hindi">Hindi</option>
//                   <option value="spanish">Spanish</option>
//                   <option value="french">French</option>
//                   <option value="arabic">Arabic</option>
//                   <option value="russian">Russian</option>
//                   <option value="portuguese">Portuguese</option>
//                   <option value="indonesian">Indonesian</option>
//                   <option value="german">German</option>
//                   <option value="japanese">Japanese</option>
//                   <option value="swahili">Swahili</option>
//                   <option value="turkish">Turkish</option>
//                   <option value="korean">Korean</option>
//                   <option value="italian">Italian</option>
//                   <option value="vietnamese">Vietnamese</option>
//                   <option value="polish">Polish</option>
//                   <option value="ukrainian">Ukrainian</option>
//                   <option value="dutch">Dutch</option>
//                   <option value="greek">Greek</option>
//                   <option value="hebrew">Hebrew</option>
//                   <option value="thai">Thai</option>
//                   <option value="persian">Persian</option>
//                   <option value="czech">Czech</option>
//                   <option value="swedish">Swedish</option>
//                   <option value="romanian">Romanian</option>
//                   <option value="hungarian">Hungarian</option>
//                   <option value="danish">Danish</option>
//                   <option value="finnish">Finnish</option>
//                   <option value="slovak">Slovak</option>
//                   <option value="norwegian">Norwegian</option>
//                   <option value="serbian">Serbian</option>
//                   <option value="bulgarian">Bulgarian</option>
//                   <option value="mandarin">Mandarin</option>
//                   {/* Add more language options here */}
//                 </select>
//                 <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
//                   <ChevronDown className="h-4 w-4" />
//                 </div>
//               </div>
//             </div>

//             <button
//               type="submit"
//               disabled={isSubmitting}
//               className={`w-full bg-gradient-to-r from-green-500 to-green-600 text-white text-lg font-semibold py-3 rounded-lg
//                 ${isSubmitting ? "opacity-75 cursor-not-allowed" : "hover:from-green-600 hover:to-green-700"}
//                 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500 transition-all duration-300 transform hover:-translate-y-1`}
//             >
//               {isSubmitting ? (
//                 <span className="flex items-center justify-center">
//                   <svg
//                     className="animate-spin -ml-1 mr-3 h-5 w-5 text-white"
//                     xmlns="http://www.w3.org/2000/svg"
//                     fill="none"
//                     viewBox="0 0 24 24"
//                   >
//                     <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
//                     <path
//                       className="opacity-75"
//                       fill="currentColor"
//                       d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
//                     ></path>
//                   </svg>
//                   Generating Your Report...
//                 </span>
//               ) : (
//                 "Generate My Business Report!"
//               )}
//             </button>
//           </form>
//         </div>

//         <div className="bg-white rounded-2xl shadow-lg p-8">
//           <h2 className="text-2xl font-bold text-gray-800 mb-6">My Previous Full Reports</h2>
//           <p className="text-sm text-gray-600 mb-4">
//             *Opening a new report deducts report credits automatically. Select the correct report if you have multiple reports.
//           </p>
//           {isFetchingReports ? (
//             <div className="flex justify-center items-center h-24">
//               <div className="animate-spin rounded-full h-10 w-10 border-b-2 border-green-500"></div>
//             </div>
//           ) : (
//             <ul className="space-y-4">
//               {latestFullReports.length > 0 ? (
//                 latestFullReports.map((report) => (
//                   <li key={report.report_id} className="border-b border-gray-200 pb-4">
//                     <Link to={`/full-report/${report.report_id}`} className="text-blue-500 hover:text-green-800 font-medium">
//                       {report.input.substring(0, 100) + (report.input.length > 100 ? "..." : "")}
//                     </Link>
//                     {report.input.length > 100 && (
//                       <button onClick={() => openModal(report)} className="ml-4 text-sm text-gray-500 hover:text-gray-700">
//                         View More
//                       </button>
//                     )}
//                   </li>
//                 ))
//               ) : (
//                 <p className="text-gray-600">No previous reports found. Generate your first comprehensive analysis now!</p>
//               )}
//             </ul>
//           )}
//         </div>
//       </main>
//       <ReportFooter />
//       <CustomModal isOpen={isModalOpen} onRequestClose={closeModal} content={selectedReport?.input} />
//     </div>
//   );
// }

import React, { useState, useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import { motion } from "framer-motion";
import api from "./axiosConfig";
import DashboardHeader from "./DashboardHeader";
import ReportFooter from "./ReportFooter";
import Modal from "react-modal";
import {
  ChevronDown,
  Sparkles,
  Radio,
  Eye,
  MessageSquare,
  Clock,
  Target,
  Gift,
  Star,
  ChevronRight,
  X,
  ExternalLink,
  Megaphone,
  Activity,
  Users,
  Calendar,
  Building,
  TrendingUp,
  Mail,
  CheckCircle,
  Search,
  ArrowRight,
  Zap,
  Shield,
  FileText,
} from "lucide-react";
import TopBanner from "./TopBanner";

const CustomModal = ({ isOpen, onRequestClose, content }) => {
  const [isCopied, setIsCopied] = useState(false);

  const handleCopy = async () => {
    try {
      await navigator.clipboard.writeText(content);
      setIsCopied(true);
      setTimeout(() => setIsCopied(false), 2000);
    } catch (err) {
      console.error("Failed to copy text: ", err);
    }
  };

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      className="fixed inset-0 z-50 flex items-center justify-center"
      overlayClassName="fixed inset-0 bg-black bg-opacity-50 z-40"
    >
      <motion.div
        initial={{ opacity: 0, scale: 0.95 }}
        animate={{ opacity: 1, scale: 1 }}
        exit={{ opacity: 0, scale: 0.95 }}
        transition={{ duration: 0.3 }}
        className="bg-white rounded-2xl shadow-xl max-w-lg w-full mx-4 p-6"
      >
        <h3 className="text-xl font-semibold text-gray-900 mb-4">Full Input</h3>
        <div className="max-h-96 overflow-y-auto p-4 bg-gray-50 rounded-lg mb-4">
          <p className="text-sm text-gray-700 whitespace-pre-wrap">{content}</p>
        </div>
        <div className="flex justify-end space-x-3">
          <button
            onClick={handleCopy}
            className={`px-4 py-2 rounded-lg text-white font-medium transition-colors ${
              isCopied ? "bg-green-600" : "bg-blue-600 hover:bg-blue-700"
            }`}
          >
            {isCopied ? "Copied!" : "Copy Full Text"}
          </button>
          <button
            onClick={onRequestClose}
            className="px-4 py-2 bg-gray-200 rounded-lg text-gray-700 font-medium hover:bg-gray-300 transition-colors"
          >
            Close
          </button>
        </div>
      </motion.div>
    </Modal>
  );
};

export default function ReportDashboard() {
  const location = useLocation();
  const [businessIdea, setBusinessIdea] = useState("");
  const [language, setLanguage] = useState("english");
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [latestFullReports, setLatestFullReports] = useState([]);
  const [isFetchingReports, setIsFetchingReports] = useState(false);
  const [message, setMessage] = useState("");
  const [showRechargeLink, setShowRechargeLink] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedReport, setSelectedReport] = useState(null);
  const [showBoothForm, setShowBoothForm] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [showPreview, setShowPreview] = useState(false);
  const [showBusinessMatchModal, setShowBusinessMatchModal] = useState(false);

  useEffect(() => {
    // Check if there's data from the Ideation Dashboard
    const storedInput = localStorage.getItem("businessPlanInput");
    if (storedInput) {
      setBusinessIdea(storedInput);
      // Optional: Clear after loading
      localStorage.removeItem("businessPlanInput");
    }
    async function fetchReports() {
      setIsFetchingReports(true);
      try {
        const response = await api.get("user-latest-report/");
        if (response.status === 200) {
          setLatestFullReports(response.data);
        }
      } catch (error) {
        console.error("Error fetching reports:", error);
      } finally {
        setIsFetchingReports(false);
      }
    }

    fetchReports();
  }, []);

  async function handleSubmit(e) {
    e.preventDefault();
    setMessage("");
    setShowRechargeLink(false);
    setIsSubmitting(true);

    const timeoutId = setTimeout(() => {
      alert("Submission is taking longer than expected. Please try again.");
      setIsSubmitting(false);
    }, 10000);

    const confirmReport = window.confirm("This will deduct one report credit. Are you sure you want to continue?");
    if (!confirmReport) {
      clearTimeout(timeoutId);
      setIsSubmitting(false);
      return;
    }

    try {
      const response = await api.post("create-report/", {
        business_idea_input: businessIdea,
        language,
      });
      clearTimeout(timeoutId);

      if (response.status === 200 || response.status === 201) {
        window.location.href = `/full-report/${response.data.report_id}`;
      }
    } catch (error) {
      clearTimeout(timeoutId);
      if (error.response) {
        const errorMessage = error.response.data.message;
        setMessage(errorMessage || "An error occurred. Please try again.");
        if (errorMessage.includes("Not enough credits")) {
          setShowRechargeLink(true);
        }
      } else {
        setMessage("An unexpected error occurred.");
      }
      console.error("Failed to submit form:", error.response?.data);
    } finally {
      setIsSubmitting(false);
    }
  }

  const openModal = (report) => {
    setSelectedReport(report);
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
    setSelectedReport(null);
  };

  return (
    <div className="min-h-screen bg-white">
      {/* Simple Header - Matching the dashboard page */}
      <header className="h-16 border-b border-gray-100 flex items-center px-8">
        <div className="max-w-6xl w-full mx-auto flex justify-between">
          <span className="font-medium text-xl text-green-600">FeedbackbyAI</span>
          <Link to="/dashboard/" className="text-gray-500 hover:text-gray-700">
            Back to Dashboard
          </Link>
        </div>
      </header>

      <main className="max-w-4xl mx-auto px-6 py-12">
        {/* Page Header with Icon - Matching the card style */}
        <div className="flex flex-col items-center justify-center mb-12">
          <div className="w-20 h-20 bg-green-50 rounded-full flex items-center justify-center mb-6">
            <FileText className="w-10 h-10 text-green-600" />
          </div>
          <motion.h1
            initial={{ opacity: 0, y: -20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.5 }}
            className="text-3xl font-bold text-gray-900 text-center mb-2"
          >
            Generate Business Plan
          </motion.h1>
          <p className="text-center text-gray-600 max-w-2xl mx-auto">
            Get a comprehensive 100+ page analysis to validate and refine your business concept.
          </p>
        </div>

        <div className="bg-white rounded-2xl shadow-xl border border-gray-100 p-8 mb-12">
          {message && (
            <div
              className={`mb-6 p-4 rounded-lg ${
                showRechargeLink ? "bg-yellow-50 border-l-4 border-yellow-400" : "bg-red-50 border-l-4 border-red-400"
              }`}
            >
              <p className="text-sm font-medium text-gray-800">{message}</p>
              {showRechargeLink && (
                <Link to="/credits/" className="inline-block mt-2 text-green-600 hover:text-green-800 font-semibold">
                  Recharge credits →
                </Link>
              )}
            </div>
          )}

          <form onSubmit={handleSubmit} className="space-y-6">
            <div>
              <label htmlFor="businessIdea" className="block text-sm font-medium text-gray-700 mb-2">
                Describe your business idea in detail
              </label>
              <textarea
                id="businessIdea"
                value={businessIdea}
                onChange={(e) => setBusinessIdea(e.target.value)}
                placeholder="Detail your business concept, including unique features, target market, pricing strategy, and potential challenges. The more comprehensive your description, the more tailored our analysis will be."
                rows="7"
                className="w-full p-4 text-gray-700 bg-gray-50 border border-gray-300 rounded-lg focus:ring-2 focus:ring-green-500 focus:border-transparent transition-all duration-200"
                required
              />
            </div>

            <div>
              <label htmlFor="language" className="block text-sm font-medium text-gray-700 mb-2">
                Select Report Language
              </label>
              <div className="relative">
                <select
                  id="language"
                  value={language}
                  onChange={(e) => setLanguage(e.target.value)}
                  className="block w-full pl-3 pr-10 py-3 text-base border-gray-300 focus:ring-green-500 focus:border-green-500 rounded-lg appearance-none bg-white"
                >
                  <option value="english">English</option>
                  <option value="hindi">Hindi</option>
                  <option value="spanish">Spanish</option>
                  <option value="french">French</option>
                  <option value="arabic">Arabic</option>
                  <option value="russian">Russian</option>
                  <option value="portuguese">Portuguese</option>
                  <option value="indonesian">Indonesian</option>
                  <option value="german">German</option>
                  <option value="japanese">Japanese</option>
                  <option value="swahili">Swahili</option>
                  <option value="turkish">Turkish</option>
                  <option value="korean">Korean</option>
                  <option value="italian">Italian</option>
                  <option value="vietnamese">Vietnamese</option>
                  <option value="polish">Polish</option>
                  <option value="ukrainian">Ukrainian</option>
                  <option value="dutch">Dutch</option>
                  <option value="greek">Greek</option>
                  <option value="hebrew">Hebrew</option>
                  <option value="thai">Thai</option>
                  <option value="persian">Persian</option>
                  <option value="czech">Czech</option>
                  <option value="swedish">Swedish</option>
                  <option value="romanian">Romanian</option>
                  <option value="hungarian">Hungarian</option>
                  <option value="danish">Danish</option>
                  <option value="finnish">Finnish</option>
                  <option value="slovak">Slovak</option>
                  <option value="norwegian">Norwegian</option>
                  <option value="serbian">Serbian</option>
                  <option value="bulgarian">Bulgarian</option>
                  <option value="mandarin">Mandarin</option>
                </select>
                <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
                  <ChevronDown className="h-4 w-4" />
                </div>
              </div>
            </div>

            <button
              type="submit"
              disabled={isSubmitting}
              className={`w-full bg-green-600 hover:bg-green-700 text-white text-lg font-semibold py-3 rounded-lg
                ${isSubmitting ? "opacity-75 cursor-not-allowed" : ""}
                focus:outline-none transition-all duration-300`}
            >
              {isSubmitting ? (
                <span className="flex items-center justify-center">
                  <svg
                    className="animate-spin -ml-1 mr-3 h-5 w-5 text-white"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                  >
                    <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                    <path
                      className="opacity-75"
                      fill="currentColor"
                      d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                    ></path>
                  </svg>
                  Generating Your Report...
                </span>
              ) : (
                "Generate My Business Plan"
              )}
            </button>
          </form>
        </div>

        {/* Feature highlights that match the card style */}
        <div className="grid grid-cols-1 md:grid-cols-3 gap-6 mb-12">
          <div className="bg-gray-50 rounded-xl p-6">
            <div className="flex items-center mb-3">
              <div className="w-8 h-8 bg-green-100 rounded-full flex items-center justify-center mr-3">
                <FileText className="w-4 h-4 text-green-600" />
              </div>
              <h3 className="font-medium text-gray-900">100+ Page Report</h3>
            </div>
            <p className="text-sm text-gray-600">Comprehensive business analysis with actionable insights for launch.</p>
          </div>

          <div className="bg-gray-50 rounded-xl p-6">
            <div className="flex items-center mb-3">
              <div className="w-8 h-8 bg-blue-100 rounded-full flex items-center justify-center mr-3">
                <Target className="w-4 h-4 text-blue-600" />
              </div>
              <h3 className="font-medium text-gray-900">Market Analysis</h3>
            </div>
            <p className="text-sm text-gray-600">In-depth research on your target market, customers, and competitors.</p>
          </div>

          <div className="bg-gray-50 rounded-xl p-6">
            <div className="flex items-center mb-3">
              <div className="w-8 h-8 bg-purple-100 rounded-full flex items-center justify-center mr-3">
                <TrendingUp className="w-4 h-4 text-purple-600" />
              </div>
              <h3 className="font-medium text-gray-900">Growth Strategy</h3>
            </div>
            <p className="text-sm text-gray-600">Actionable recommendations for marketing, sales, and scaling.</p>
          </div>
        </div>

        {/* Previous Reports Section */}
        <div className="bg-white rounded-2xl shadow-lg border border-gray-100 p-8">
          <h2 className="text-xl font-bold text-gray-800 mb-6">My Previous Business Plans</h2>
          <p className="text-sm text-gray-600 mb-4">
            *Opening a new report deducts report credits automatically. Select the correct report if you have multiple reports.
          </p>
          {isFetchingReports ? (
            <div className="flex justify-center items-center h-24">
              <div className="animate-spin rounded-full h-10 w-10 border-b-2 border-green-500"></div>
            </div>
          ) : (
            <ul className="space-y-4">
              {latestFullReports.length > 0 ? (
                latestFullReports.map((report) => (
                  <li key={report.report_id} className="border-b border-gray-200 pb-4">
                    <Link to={`/full-report/${report.report_id}`} className="text-green-600 hover:text-green-800 font-medium">
                      {report.input.substring(0, 100) + (report.input.length > 100 ? "..." : "")}
                    </Link>
                    {report.input.length > 100 && (
                      <button onClick={() => openModal(report)} className="ml-4 text-sm text-gray-500 hover:text-gray-700">
                        View More
                      </button>
                    )}
                  </li>
                ))
              ) : (
                <p className="text-gray-600">No previous reports found. Generate your first business plan now!</p>
              )}
            </ul>
          )}
        </div>
      </main>

      <CustomModal isOpen={isModalOpen} onRequestClose={closeModal} content={selectedReport?.input} />
    </div>
  );
}
