// TikTokPhonePreview.js
import React, { useRef, useState, useEffect } from "react";
import { Play, Pause, X, Heart, MessageCircle, Bookmark, Share } from "lucide-react";

const TikTokPhonePreview = ({ videoUrl, caption, onClose }) => {
  const videoRef = useRef(null);
  const progressBarRef = useRef(null);
  const [isPlaying, setIsPlaying] = useState(false);
  const [currentTime, setCurrentTime] = useState(0);
  const [duration, setDuration] = useState(0);

  const togglePlayback = () => {
    const el = videoRef.current;
    if (!el) return;

    if (el.paused) {
      el.play()
        .then(() => setIsPlaying(true))
        .catch(console.error);
    } else {
      el.pause();
      setIsPlaying(false);
    }
  };

  const handleTimeUpdate = (e) => {
    setCurrentTime(e.target.currentTime);
    setDuration(e.target.duration);
  };

  const handleScrub = (e) => {
    if (!progressBarRef.current || !videoRef.current) return;
    const rect = progressBarRef.current.getBoundingClientRect();
    const percentage = (e.clientX - rect.left) / rect.width;
    const newTime = Math.max(0, Math.min(duration, percentage * duration));
    videoRef.current.currentTime = newTime;
  };

  return (
    <div className="relative w-[320px] h-[580px] bg-black rounded-[2.5rem] shadow-xl overflow-hidden">
      <video
        ref={videoRef}
        src={videoUrl}
        className="w-full h-full object-cover"
        loop
        playsInline
        onTimeUpdate={handleTimeUpdate}
        onLoadedData={(e) => {
          setDuration(e.target.duration);
          e.target.play().catch(console.log);
          setIsPlaying(true);
        }}
        onEnded={(e) => {
          e.target.currentTime = 0;
          e.target.pause();
          setIsPlaying(false);
        }}
      />

      {/* Play overlay */}
      {!isPlaying && (
        <div
          onClick={togglePlayback}
          className="absolute inset-0 flex items-center justify-center bg-black/30 backdrop-blur-sm cursor-pointer z-20"
        >
          <div className="w-16 h-16 rounded-full bg-white/20 flex items-center justify-center">
            <Play className="w-8 h-8 text-white" />
          </div>
        </div>
      )}

      {/* Caption */}
      {caption && (
        <div className="absolute top-12 inset-x-0 px-4 text-center z-10">
          <p className="text-white text-lg font-bold" style={{ textShadow: "0 2px 4px rgba(0,0,0,0.8)" }}>
            {caption}
          </p>
        </div>
      )}

      {/* TikTok buttons */}
      <div className="absolute inset-y-0 right-4 flex flex-col items-center justify-end gap-4 pb-20 z-10">
        {[Heart, MessageCircle, Bookmark, Share].map((Icon, i) => (
          <button key={i} className="p-3 flex flex-col items-center">
            <div className="w-12 h-12 flex items-center justify-center rounded-full bg-black/40 mb-1">
              <Icon className="w-6 h-6 text-white/70" />
            </div>
          </button>
        ))}
      </div>

      {/* Progress bar */}
      {isPlaying && (
        <div className="absolute bottom-0 left-0 right-0 z-20">
          <div ref={progressBarRef} onClick={handleScrub} className="w-full h-1.5 bg-white/30 rounded-full cursor-pointer relative">
            <div
              className="absolute inset-y-0 left-0 bg-white rounded-full transition-all duration-100"
              style={{ width: `${(currentTime / (duration || 1)) * 100}%` }}
            />
          </div>
        </div>
      )}

      {/* Close Button */}
      {onClose && (
        <button onClick={onClose} className="absolute top-3 right-3 p-2 bg-black/50 text-white rounded-full z-30 hover:bg-black">
          <X className="w-4 h-4" />
        </button>
      )}
    </div>
  );
};

export default TikTokPhonePreview;
