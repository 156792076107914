import React from "react";
import Header from "../components/Header";

const styles = {
  fontFamily: "Inter, sans-serif",
};

const Terms = () => {
  return (
    <div className="font-inter antialiased bg-white text-gray-900 tracking-tight">
      <Header />
      <div className="grow p-4">
        <section className="bg-gradient-to-b from-gray-50 to-green-100 min-h-screen">
          <div className="max-w-4xl mx-auto py-12">
            <h1 className="text-2xl font-bold mb-4">Terms of Service</h1>
            <p className="mb-2">
              <strong>Last updated on March 10, 2025.</strong>
            </p>
            <p className="mb-4">
              This website is operated by feedbackbyai. Throughout the site, the terms "we", "us", and "our" refer to the trading name,
              feedbackbyai.com. feedbackbyai.com offers this website, including all information, tools and services available from this
              website to you, the user.
            </p>

            <h2 className="text-xl font-semibold mb-3">Purchasing</h2>
            <p className="mb-4">
              Suppose you plan to purchase a subscription or paid plan on the website. In that case, you will be asked to supply certain
              information relevant to your purchase, including, without limitation, your payment information (card or account details) and
              personal data. By proceeding with the purchase, you agree to the processing of your personal data by our service for the
              purpose of delivering our product and content.
            </p>
            <h2 className="text-xl font-semibold mb-3">Services</h2>
            <p className="mb-4">
              As a subscriber to the feedbackbyai.com service, you are entitled to receive assistance with your problems and ideas and
              contact our team if or when needed. As a promise, we try to answer every request as quickly as possible. You agree to receive
              newsletter, notices and other services related notifications on the email id provided to us. You can opt out of newsletters
              and promotional campaigns but you cannot opt out from receiving important service related notifications.
            </p>
            <h2 className="text-xl font-semibold mb-3">Accounts</h2>
            <p className="mb-4">
              Accounts can be used by anyone on your team, but if we notice any suspicious actions, we reserve the right to terminate your
              account until the actions are justified or clarified.
            </p>
            <h2 className="text-xl font-semibold mb-3">Termination</h2>
            <p className="mb-4">
              We reserve the right to terminate or suspend your access to our service at any time, and without prior notice or liability,
              for any reason, including, without limitation, if you violate the Terms. All provisions of the Terms that, by their nature,
              must survive termination, including, but not limited to, ownership provisions, warranty disclaimers, indemnity, and liability
              limitations, shall survive termination.
            </p>
            <h2 className="text-xl font-semibold mb-3">Taxes</h2>
            <p className="mb-4">
              The cost of feedbackbyai.com does not include taxes. Furthermore, you are responsible for any other fees and taxes associated
              with your activities.
            </p>
            <h2 className="text-xl font-semibold mb-3">Refunds</h2>
            <p className="mb-4">
              Unfortunately, we don't offer any form of refunds, but if you're unhappy with our service, please reach out
              business@feedbackbyai.com, and we'll help where we can. Links to other sites Our website may contain links to external sites
              that are operated by third-parties. Please be aware that we have no control over the content and terms of these sites, and we
              cannot accept responsibility or liability for their respective privacy policies.
            </p>

            <h2 className="text-xl font-semibold mb-3">Modification</h2>
            <p className="mb-4">
              We reserve the right to modify these Terms of Service at any time. Changes and updates will take effect immediately upon going
              live on our website. feedbackbyai.com advises its visitors to frequently visit this page to check for any changes to its Terms
              of Service. We'll notify you if and when we make changes to our Terms.
            </p>

            <h2 className="text-xl font-semibold mb-3">Contact</h2>
            <p className="mb-4">
              If you would like to ask any questions or provide feedback regarding this Privacy Policy, please reach out to us at
              business@feedbackbyai.com
            </p>
          </div>
        </section>
      </div>
    </div>
  );
};

export default Terms;
