// import React from "react";
// import Header from "../components/Header";
// import Hero from "../components/NewHero";
// import Problem from "./Problem";
// import Footer from "./Footer";
// import HowItWorks from "./HowItWorks";
// import PricingTables from "./Pricing";
// import Faqs from "./Faqs";
// import Cta from "./Cta";
// import { useState, useEffect } from "react";
// import TopBanner from "./TopBanner";
// import IframeResizer from "iframe-resizer-react";
// import { motion } from "framer-motion";
// import { ArrowRight, Zap, Sparkles, ChevronRight } from "lucide-react";

// const styles = {
//   fontFamily: "Inter, sans-serif",
// };

// const LandingPage = () => {
//   return (
//     <div style={styles} className="font-inter antialiased bg-white text-gray-900 tracking-tight ">
//       <TopBanner />
//       <Header />
//       {/* Add the Banner component */}
//       <div className="grow ">
//         <Hero />

//         <HowItWorks />
//         <Problem />
//         <PricingTables />

//         <Faqs />
//         <Cta />
//         <Footer />
//       </div>
//     </div>
//   );
// };

// export default LandingPage;

import React, { useState } from "react";
import {
  HiBeaker,
  HiChartBar,
  HiChatAlt2,
  HiSparkles,
  HiLightningBolt,
  HiPlay,
  HiOutlineCheck,
  HiChevronDown,
  HiStar,
  HiDocumentText,
  HiUserGroup,
  HiChevronRight,
  HiOutlineX,
} from "react-icons/hi";
import { FcGoogle } from "react-icons/fc";
import { motion } from "framer-motion";
import Header from "../components/Header";
import IframeResizer from "iframe-resizer-react";
import Footer from "../components/Footer";
import api from "./axiosConfig";
import { auth } from "../firebaseConfig";
import { GoogleAuthProvider, signInWithPopup } from "firebase/auth";

const handleSignUp = () => {
  window.location.href = "/signup";
};

const handleGoogleSignUp = async () => {
  const provider = new GoogleAuthProvider();
  try {
    const result = await signInWithPopup(auth, provider);
    const token = result.user.accessToken;
    const response = await api.post("/google-login/", { token });
    if (response.status === 200) {
      localStorage.setItem("access", response.data.access);
      window.location.href = "/dashboard/";
    }
  } catch (error) {
    console.error("Error with Google Sign Up:", error.message);
  }
};

// Hero Components
const FeaturePill = ({ icon: Icon, text }) => (
  <div className="flex items-center bg-green-50 hover:bg-green-100 px-4 py-2 rounded-full transition-colors duration-300">
    <Icon className="h-5 w-5 text-green-600 mr-2" />
    <span className="text-sm font-medium text-gray-900">{text}</span>
  </div>
);

const fadeInUp = {
  initial: { opacity: 0, y: 20 },
  animate: { opacity: 1, y: 0 },
  transition: { duration: 0.5 },
};

const Feature = ({ icon: Icon, text }) => (
  <div className="bg-green-50 hover:bg-green-100 px-4 sm:px-6 py-3 rounded-full transition-all duration-300 w-full sm:w-auto">
    <div className="flex items-center justify-center sm:justify-start">
      <Icon className="h-5 w-5 flex-shrink-0 text-green-600 mr-2" />
      <span className="text-sm sm:text-base font-medium text-gray-900 whitespace-nowrap">{text}</span>
    </div>
  </div>
);

const Stat = ({ icon: Icon, text }) => (
  <div className="flex items-center justify-center bg-gray-50 hover:bg-gray-100 p-4 rounded-2xl transition-all duration-300">
    <Icon className="text-green-600 text-2xl mr-3 flex-shrink-0" />
    <span className="text-gray-900 font-medium text-sm sm:text-base">{text}</span>
  </div>
);

const Hero = ({ handleSignUp, handleGoogleSignUp }) => {
  const features = [
    { icon: HiChartBar, text: "Validate with 100+ Page AI Business Plan" },
    { icon: HiLightningBolt, text: "Automate TikToks to Drive Traffic" },
    { icon: HiSparkles, text: "AI Agents Find High-Intent Buyers Daily" },
    { icon: HiBeaker, text: "AI Agents Discover Profitable Ideas" },
    { icon: HiChatAlt2, text: "Run User Interviews with 50+ AI Characters" },
  ];

  const stats = [
    { icon: HiDocumentText, text: "5 Powerful AI Tools" },
    { icon: HiUserGroup, text: "Save 30+ Hours Weekly" },
    { icon: HiLightningBolt, text: "Trusted by 10,000+ Businesses" },
  ];

  return (
    <div className="relative bg-gradient-to-b from-white to-gray-50 min-h-screen overflow-hidden">
      <div className="absolute inset-0 bg-grid-pattern opacity-5" />

      <div className="relative max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 pt-16 sm:pt-24 pb-16 sm:pb-20">
        <div className="max-w-4xl mx-auto">
          <motion.div className="text-center space-y-8" initial="initial" animate="animate">
            <motion.h1 {...fadeInUp} className="text-4xl md:text-6xl lg:text-7xl font-bold text-gray-900 tracking-tight">
              Go From Idea to Paying Customers,
              <span className="bg-green-600 bg-clip-text text-transparent"> Faster with AI</span>
            </motion.h1>

            <motion.p {...fadeInUp} transition={{ delay: 0.2 }} className="text-lg md:text-2xl text-gray-600 max-w-3xl mx-auto">
              FeedbackbyAI is your integrated AI suite for validating opportunities, automating marketing, finding high-intent leads, and
              launching successfully – <span className="font-semibold">all in one place</span>.
            </motion.p>

            <motion.div {...fadeInUp} transition={{ delay: 0.3 }} className="flex flex-col sm:flex-row justify-center gap-4 pt-4">
              <button
                onClick={handleSignUp}
                className="group px-8 py-4 text-lg font-medium bg-green-600 text-white rounded-full hover:bg-green-700 transition-all duration-300 hover:shadow-xl transform hover:-translate-y-1"
              >
                <div className="flex items-center justify-center">
                  Get Started for Free
                  <HiChevronRight className="ml-2 h-5 w-5 group-hover:translate-x-1 transition-transform duration-300" />
                </div>
              </button>

              <button
                onClick={handleGoogleSignUp}
                className="flex items-center justify-center px-8 py-4 text-lg font-medium text-gray-900 bg-white border border-gray-200 hover:border-gray-300 rounded-full transition-all duration-300 hover:shadow-xl transform hover:-translate-y-1"
              >
                <FcGoogle className="text-xl mr-2" />
                Sign up with Google
              </button>
            </motion.div>

            {/* <motion.div {...fadeInUp} transition={{ delay: 0.4 }} className="flex flex-wrap justify-center gap-4 pt-8">
              {features.map((feature, index) => (
                <Feature key={index} {...feature} />
              ))}
            </motion.div> */}

            <motion.div {...fadeInUp} transition={{ delay: 0.5 }} className="grid grid-cols-1 sm:grid-cols-3 gap-6 pt-12">
              {stats.map((stat, index) => (
                <Stat key={index} {...stat} />
              ))}
            </motion.div>

            <motion.div {...fadeInUp} transition={{ delay: 0.6 }} className="pt-16">
              <div className="relative rounded-2xl overflow-hidden shadow-2xl bg-white">
                <iframe
                  className="w-full aspect-video"
                  src="https://www.youtube.com/embed/xSv39H_egc0?si=0TVMNHORlyRxvNGh"
                  title="FeedbackbyAI Demo"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowFullScreen
                />
              </div>
            </motion.div>

            <motion.div {...fadeInUp} transition={{ delay: 0.7 }} className="pt-16 text-center">
              <h3 className="text-3xl md:text-4xl font-bold text-gray-800 mb-6">
                Trusted by <span className="text-green-600">10,000+</span> Businesses
              </h3>
              <p className="text-xl text-gray-600 mb-8">
                Join thousands of visionaries who've scaled their ideas into successful businesses with FeedbackbyAI.
              </p>
              <div className="w-full rounded-xl shadow-lg overflow-hidden">
                <IframeResizer src="https://embed-v2.testimonial.to/w/feedbackbyai" style={{ width: "1px", minWidth: "100%" }} />
              </div>
            </motion.div>
          </motion.div>
        </div>
      </div>
    </div>
  );
};

const VideoFeature = ({ title, description, video, isReversed, features }) => {
  const [isPlaying, setIsPlaying] = useState(false);
  const [isHovered, setIsHovered] = useState(false);
  const videoRef = React.useRef(null);

  const togglePlay = () => {
    if (videoRef.current) {
      if (isPlaying) {
        videoRef.current.pause();
      } else {
        videoRef.current.play();
      }
      setIsPlaying(!isPlaying);
    }
  };

  return (
    <div className="py-24">
      <div className="grid md:grid-cols-2 gap-16 xl:gap-20 items-center">
        <div className={isReversed ? "md:order-2" : ""}>
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            whileInView={{ opacity: 1, y: 0 }}
            viewport={{ once: true }}
            transition={{ duration: 0.5 }}
          >
            <h2 className="text-3xl font-semibold text-gray-900 tracking-tight mb-6">{title}</h2>
            <p className="text-xl text-gray-600 mb-10 leading-relaxed">{description}</p>
            <div className="space-y-5">
              {features.map((feature, index) => (
                <motion.div
                  key={index}
                  initial={{ opacity: 0, x: -20 }}
                  whileInView={{ opacity: 1, x: 0 }}
                  viewport={{ once: true }}
                  transition={{ delay: index * 0.2 }}
                  className="flex items-start"
                >
                  <div className="flex-shrink-0 h-6 w-6 rounded-full bg-green-100 flex items-center justify-center mt-1">
                    <HiOutlineCheck className="h-4 w-4 text-green-600" />
                  </div>
                  <p className="ml-4 text-gray-600 leading-relaxed">{feature}</p>
                </motion.div>
              ))}
            </div>
          </motion.div>
        </div>

        <motion.div
          initial={{ opacity: 0, y: 20 }}
          whileInView={{ opacity: 1, y: 0 }}
          viewport={{ once: true }}
          className={`relative ${isReversed ? "md:order-1" : ""}`}
          onMouseEnter={() => setIsHovered(true)}
          onMouseLeave={() => setIsHovered(false)}
        >
          <div className="rounded-2xl overflow-hidden bg-gray-50 shadow-xl transform transition-all duration-500 hover:shadow-2xl">
            <div className="relative">
              <div className="absolute inset-0 bg-gradient-to-br from-black/5 to-black/20 z-10" />
              <video
                ref={videoRef}
                src={video}
                className="w-full rounded-2xl transform transition-transform duration-700 scale-[1.01] hover:scale-[1.02]"
                loop
                muted
              />
              <div
                className={`absolute inset-0 flex items-center justify-center transition-opacity duration-500 ${
                  isHovered ? "opacity-100" : "opacity-0"
                }`}
              >
                <button
                  onClick={togglePlay}
                  className="relative z-20 bg-white/95 p-5 rounded-full shadow-[0_8px_28px_-6px_rgba(0,0,0,0.3)] transform transition-all duration-500 hover:scale-110 hover:bg-white"
                >
                  <HiPlay className="h-8 w-8 text-green-600" />
                </button>
              </div>
            </div>
          </div>
        </motion.div>
      </div>
    </div>
  );
};

const Features = () => (
  <section className="max-w-[1200px] mx-auto px-4 sm:px-6" id="features">
    <VideoFeature
      title="Validate Your Vision with Data, Not Guesses"
      description="Stop wondering if your idea will work. Our AI generates a comprehensive 100+ page business report, including deep market research, ideal customer profiles, a step-by-step launch roadmap. Launch with confidence."
      // video="/videos/businessplan.mp4"
      video="https://feedbackbyaibe-production.s3.us-east-2.amazonaws.com/businessplan.mp4"
      isReversed={true}
      features={[
        "Step-by-step launch roadmap",
        "Financial projections & pricing strategy",
        "Ideal customer profiles",
        "Marketing & positioning tactics",
      ]}
    />

    <VideoFeature
      title="Get Realistic Feedback Instantly"
      description="Why wait weeks for user interviews? Test your concept, features, and messaging against 50+ realistic AI personas representing diverse demographics and roles"
      // video="/videos/userfeedback.mp4"
      video="https://feedbackbyaibe-production.s3.us-east-2.amazonaws.com/userfeedback.mp4"
      features={["Uncover objections", "Gauge sentiment", "Improvement suggestions"]}
    />

    <VideoFeature
      title=" Discover Untapped Market Opportunities"
      description="Don't just build what you think is needed. Our AI agents constantly scan social media conversations to identify emerging trends, market gaps, and urgent customer pain points, helping you find profitable ideas before competitors do."
      // video="/videos/ideation.mp4"
      video="https://feedbackbyaibe-production.s3.us-east-2.amazonaws.com/ideation.mp4"
      isReversed={true}
      features={["Find Your Next Big Idea", "Realtime market analysis", "Competitor & gap research"]}
    />

    <VideoFeature
      // title="Generate Viral-Ready Social Content"
      title=" Drive Traffic with Automated Social Videos"
      description='Struggling with content creation? Generate engaging "hook + demo" UGC-style videos optimized for TikTok, Instagram Reels, and YouTube Shorts in just a few clicks. Use proven templates, add your product demo, and start driving traffic effortlessly.'
      // description="Create engaging TikTok & Instagram Reels automatically. Our AI handles scripts, hooks, and video structure to help you grow your audience."
      // video="/videos/drivetraffic.mp4"
      video="https://feedbackbyaibe-production.s3.us-east-2.amazonaws.com/drivetraffic.mp4"
      isReversed={true}
      features={[
        "50+ proven AI hook templates",
        "Edit Hook Templates + Add your Product Demo",
        "1-click download for TikTok, Instagram, Shorts",
        "Custom AI hooks (coming soon)",
      ]}
    />

    <VideoFeature
      title="Connect with High-Intent Buyers Daily"
      description="Stop cold outreach. Our AI actively listens across social platforms, identifying businesses and individuals actively searching for solutions like yours right now. Get a daily list of auto-qualified leads with smart prompts to start conversations that convert."
      // video="/videos/leadfinder.mp4"
      video="https://feedbackbyaibe-production.s3.us-east-2.amazonaws.com/leadfinder.mp4"
      features={["Daily social listening", "Auto-qualified & scored leads", "Smart engagement prompts"]}
    />
  </section>
);

// Pricing Section
const PricingTier = ({ name, price, description, features, isPopular }) => (
  <motion.div
    initial={{ opacity: 0, y: 20 }}
    whileInView={{ opacity: 1, y: 0 }}
    viewport={{ once: true }}
    className={`bg-white rounded-2xl ${isPopular ? "ring-2 ring-green-600 shadow-xl" : "border border-gray-100 shadow-lg"}`}
  >
    <div className="p-8">
      {isPopular && (
        <span className="inline-block bg-green-50 text-green-600 text-sm font-medium px-3 py-1 rounded-full mb-4">Most Popular</span>
      )}
      <h3 className="text-xl font-semibold text-gray-900 mb-2">{name}</h3>
      <div className="mb-6">
        <span className="text-4xl font-bold text-gray-900">${price}</span>
        <span className="text-gray-600">/month</span>
      </div>
      <p className="text-gray-600 mb-8">{description}</p>
      <ul className="space-y-4 mb-8">
        {features.map((feature, index) => (
          <li key={index} className="flex items-start">
            <HiOutlineCheck className="h-5 w-5 text-green-600 mt-1 mr-3 flex-shrink-0" />
            <span className="text-gray-600">{feature}</span>
          </li>
        ))}
      </ul>
      <button
        className={`w-full py-4 rounded-full text-[17px] font-medium transition-all duration-300 ${
          isPopular ? "bg-green-600 text-white hover:bg-green-700" : "bg-gray-100 text-gray-900 hover:bg-gray-200"
        }`}
        onClick={handleSignUp}
      >
        Get Started
      </button>
    </div>
  </motion.div>
);

const Pricing = () => (
  <section className="py-24" id="pricing">
    <div className="max-w-[1200px] mx-auto px-4 sm:px-6">
      <div className="text-center mb-16">
        <motion.h2
          initial={{ opacity: 0, y: 20 }}
          whileInView={{ opacity: 1, y: 0 }}
          viewport={{ once: true }}
          className="text-3xl font-semibold text-gray-900 mb-4"
        >
          Simple, transparent pricing
        </motion.h2>
      </div>

      <div className="grid md:grid-cols-3 gap-8">
        <PricingTier
          name="Starter"
          price={19}
          description="For testing traction & getting your first real users"
          features={[
            // "Daily Ideation",
            // "10 AI User Interviews",
            // "10 AI UGC videos/month",
            // "10 leads/month",
            // "No Business Report",
            // "No Community Access (Coming Soon)",

            // "Daily Ideation",
            "10 Ideation Credits",
            "10 AI User Interviews",
            "5 AI UGC videos/month",
            "10 leads/month",
            "Business Report : Executive Summary Access",
            "❌ No Full Business Report Generation",
            "❌ No Community Access (Coming Soon)",
          ]}
        />
        <PricingTier
          name="Select"
          price={59}
          description="Everything you need to validate and launch your business"
          features={[
            // "Daily Ideation",
            // "50 AI User Interviews",
            // "50 UGC videos/month",
            // "50 leads/month",
            // "1 Full 100+ Page Business Report",
            // "Community Access (coming soon)",
            "50 Ideation Credits",
            "50 AI User Interviews",
            "25 AI UGC videos/month",
            "50 leads/month",
            "1 Full 100+ Page Business Report",
            "Community Access",
          ]}
        />
        <PricingTier
          name="Pro"
          price={248}
          isPopular={true}
          description="For businesses launching or managing multiple products"
          features={[
            // "Daily Ideation",
            // "100 AI User Interviews",
            // "100 UGC videos/month",
            // "100 leads/month",
            // "2 Full 100+ Page Business Report",
            // "Community Access (coming soon)",
            "250 Ideation Credits",
            "150 AI User Interviews",
            "100 AI UGC videos/month",
            "300 leads/month",
            "5 Full 100+ Page Business Reports",
            "Community Access (Coming Soon)",
          ]}
        />
      </div>
    </div>
  </section>
);

// FAQ Section
const FAQItem = ({ question, answer }) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <motion.div
      initial={{ opacity: 0, y: 20 }}
      whileInView={{ opacity: 1, y: 0 }}
      viewport={{ once: true }}
      className="border-b border-gray-200 last:border-0"
    >
      <button onClick={() => setIsOpen(!isOpen)} className="flex justify-between items-center w-full py-6 text-left">
        <span className="text-lg font-medium text-gray-900">{question}</span>
        <HiChevronDown className={`h-5 w-5 text-gray-600 transform transition-transform duration-300 ${isOpen ? "rotate-180" : ""}`} />
      </button>
      <div className={`overflow-hidden transition-all duration-300 ${isOpen ? "max-h-96 pb-6" : "max-h-0"}`}>
        <p className="text-gray-600 leading-relaxed">{answer}</p>
      </div>
    </motion.div>
  );
};

const FAQ = () => (
  <section className="py-24 bg-gray-50" id="faq">
    <div className="max-w-[800px] mx-auto px-4 sm:px-6">
      <div className="text-center mb-16">
        <motion.h2
          initial={{ opacity: 0, y: 20 }}
          whileInView={{ opacity: 1, y: 0 }}
          viewport={{ once: true }}
          className="text-3xl font-semibold text-gray-900 mb-4"
        >
          Frequently asked questions
        </motion.h2>
      </div>

      <div className="space-y-2">
        <FAQItem
          question="How does the AI idea discovery work?"
          answer="Our AI analyzes real-time conversations on platforms like Twitter, Reddit, and forums to identify trending topics, problems people are discussing, and gaps in current solutions relevant to your interests."
        />

        <FAQItem
          question="What's included in the 100+ page business plan?"
          answer="The business plan includes comprehensive market analysis, detailed customer personas, competitive landscape review, financial projections, marketing strategies, and a step-by-step launch roadmap. Each section is backed by real market data and customized to your specific business idea."
        />

        <FAQItem
          question="How realistic are the AI user interviews?"
          answer="Our 51+ AI personas are crafted based on real demographic data, consumer behavior patterns, and market research. Each persona has unique characteristics, preferences, and decision-making patterns, providing diverse perspectives that closely mirror real customer feedback."
        />

        <FAQItem
          question="How does the automated TikTok creation work?"
          answer="Choose from proven hook templates, customize the text, upload a short demo video of your product, and our AI combines them with background music into a ready-to-download video formatted for TikTok, Reels, or Shorts."
        />

        <FAQItem
          question="How does the lead generation feature find potential customers?"
          answer="The AI scans public social media posts and comments for keywords indicating purchase intent or problems your solution addresses. It then qualifies these potential leads based on relevance."
        />

        <FAQItem
          question="Can I upgrade or downgrade my plan anytime?"
          answer="Yes, you can easily change your plan from your account settings at any time."
        />

        <FAQItem question="How to contact you?" answer="You can talk to us via chat or via email at business@feedbackbyai.com" />
      </div>
    </div>
  </section>
);

// Final CTA Section
const FinalCTA = () => (
  <section className="py-12 bg-green-600">
    <div className="max-w-[800px] mx-auto px-4 sm:px-6 text-center">
      <motion.h2
        initial={{ opacity: 0, y: 20 }}
        whileInView={{ opacity: 1, y: 0 }}
        viewport={{ once: true }}
        className="text-4xl font-bold text-white mb-6"
      >
        Ready to Stop Guessing and Start Growing?
      </motion.h2>
      <motion.p
        initial={{ opacity: 0, y: 20 }}
        whileInView={{ opacity: 1, y: 0 }}
        viewport={{ once: true }}
        transition={{ delay: 0.1 }}
        className="text-xl text-green-50 mb-8"
      >
        Transform your business journey from a slow crawl to an AI-accelerated sprint. Validate faster, launch smarter, and connect with
        paying customers sooner than you thought possible.
      </motion.p>

      <motion.div
        initial={{ opacity: 0, y: 20 }}
        whileInView={{ opacity: 1, y: 0 }}
        viewport={{ once: true }}
        transition={{ delay: 0.2 }}
        className="flex flex-col sm:flex-row justify-center gap-4 mb-8"
      >
        <button
          className="px-8 py-4 text-[17px] font-medium bg-white text-green-600 rounded-full hover:bg-green-50 transition-all duration-300"
          onClick={handleSignUp}
        >
          Get Started
        </button>
        <button
          className="flex items-center justify-center px-8 py-4 text-[17px] font-medium bg-green-700 text-white rounded-full hover:bg-green-800 transition-all duration-300"
          onClick={handleGoogleSignUp}
        >
          <FcGoogle className="text-xl mr-2" />
          Get Started with Google
        </button>
      </motion.div>
    </div>
  </section>
);

const ProblemSection = ({ handleSignUp }) => {
  const painPoints = [
    "Drowning in spreadsheets",
    "Endless market research",
    "Multiple scattered tools",
    "Finding first customers",
    "Unproven concepts",
    "Wasted resources",
  ];

  const solutions = [
    "AI-powered validation",
    "Automated research",
    "All-in-one platform",
    "High-intent leads",
    "Data-backed decisions",
    "Quick time to market",
  ];

  return (
    <section className="relative py-24 overflow-hidden bg-gradient-to-b from-gray-50 to-white">
      {/* Background Elements */}
      <div className="absolute inset-0 bg-grid-pattern opacity-[0.03]" />
      <div className="absolute top-0 left-0 w-1/3 h-1/3 bg-green-50/30 rounded-full blur-3xl -translate-x-1/2 -translate-y-1/2" />
      <div className="absolute bottom-0 right-0 w-1/3 h-1/3 bg-green-50/30 rounded-full blur-3xl translate-x-1/2 translate-y-1/2" />

      <div className="relative max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="max-w-4xl mx-auto">
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            whileInView={{ opacity: 1, y: 0 }}
            viewport={{ once: true }}
            className="text-center mb-16"
          >
            <h2 className="text-4xl md:text-5xl font-bold text-gray-900 mb-6">
              Tired of the Launch Grind?
              <span className="bg-gradient-to-r from-green-600 to-green-400 bg-clip-text text-transparent"> There's a Faster Way.</span>
            </h2>
          </motion.div>

          <div className="grid md:grid-cols-2 gap-12 lg:gap-16">
            {/* Problem Column */}
            <motion.div initial={{ opacity: 0, x: -20 }} whileInView={{ opacity: 1, x: 0 }} viewport={{ once: true }} className="relative">
              <div className="bg-white/80 backdrop-blur-sm rounded-2xl p-8 shadow-lg border border-gray-100">
                <h3 className="text-2xl font-semibold text-gray-900 mb-6">The Traditional Way</h3>
                <div className="space-y-4">
                  {painPoints.map((point, index) => (
                    <div key={index} className="flex items-center text-gray-600">
                      <HiOutlineX className="h-5 w-5 text-red-500 mr-3 flex-shrink-0" />
                      <span>{point}</span>
                    </div>
                  ))}
                </div>
              </div>
            </motion.div>

            {/* Solution Column */}
            <motion.div initial={{ opacity: 0, x: 20 }} whileInView={{ opacity: 1, x: 0 }} viewport={{ once: true }} className="relative">
              <div className="bg-white/80 backdrop-blur-sm rounded-2xl p-8 shadow-lg border border-gray-100">
                <h3 className="text-2xl font-semibold text-gray-900 mb-6">The FeedbackbyAI Way</h3>
                <div className="space-y-4">
                  {solutions.map((solution, index) => (
                    <div key={index} className="flex items-center text-gray-600">
                      <HiOutlineCheck className="h-5 w-5 text-green-500 mr-3 flex-shrink-0" />
                      <span>{solution}</span>
                    </div>
                  ))}
                </div>
              </div>
            </motion.div>
          </div>

          <motion.div
            initial={{ opacity: 0, y: 20 }}
            whileInView={{ opacity: 1, y: 0 }}
            viewport={{ once: true }}
            className="mt-16 text-center"
          >
            <p className="text-xl text-gray-600 mb-8 max-w-3xl mx-auto">
              <span className="font-semibold text-green-600">FeedbackbyAI streamlines the entire journey.</span> We replace the chaos with a
              single, intelligent platform. Leverage the power of AI to validate your ideas, pinpoint your market, automate outreach, and
              connect with buyers – moving you from concept to revenue significantly faster.
            </p>

            <button
              onClick={handleSignUp}
              className="inline-flex items-center px-8 py-4 text-lg font-medium bg-green-600 text-white rounded-full hover:bg-green-700 
                transition-all duration-300 hover:shadow-xl transform hover:-translate-y-1"
            >
              Start Your Journey
              <HiChevronRight className="ml-2 h-5 w-5 group-hover:translate-x-1 transition-transform duration-300" />
            </button>
          </motion.div>
        </div>
      </div>
    </section>
  );
};

const ToolkitIntro = () => {
  const tools = [
    {
      icon: HiChartBar,
      title: "AI Business Plan Generator",
      description: "Comprehensive validation and strategy",
    },
    {
      icon: HiChatAlt2,
      title: "AI User Interviews",
      description: "Instant feedback from 50+ personas",
    },
    {
      icon: HiBeaker,
      title: "Market Opportunity Finder",
      description: "Discover untapped market gaps",
    },
    {
      icon: HiLightningBolt,
      title: "Social Video Generator",
      description: "Automated content for growth",
    },
    {
      icon: HiSparkles,
      title: "Lead Generation AI",
      description: "Connect with ready buyers",
    },
  ];

  return (
    <section className="relative py-0 bg-white">
      <div className="absolute inset-0 bg-grid-pattern opacity-[0.03]" />

      <div className="relative max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          whileInView={{ opacity: 1, y: 0 }}
          viewport={{ once: true }}
          className="text-center mb-16"
        >
          <h2 className="text-5xl font-bold text-gray-900 mb-6">Your Integrated AI Toolkit for Success</h2>
          <p className="text-xl text-gray-600 max-w-3xl mx-auto">
            Harness these powerful tools, seamlessly integrated to take you from zero to launch and beyond:
          </p>
        </motion.div>
      </div>
    </section>
  );
};

// Minimal Footer
// const Footer = () => (
//   <footer className="py-12 border-t border-gray-100">
//     <div className="max-w-[1200px] mx-auto px-4 sm:px-6">
//       <div className="mt-12 pt-8 border-t border-gray-100">
//         <div className="flex flex-col md:flex-row justify-between items-center gap-4">
//           <p className="text-gray-600">© 2024 FeedbackbyAI. All rights reserved.</p>
//           <div className="flex space-x-6">
//             <a href="#" className="text-gray-600 hover:text-gray-900">
//               Terms
//             </a>
//             <a href="#" className="text-gray-600 hover:text-gray-900">
//               Privacy
//             </a>
//             <a href="#" className="text-gray-600 hover:text-gray-900">
//               Cookies
//             </a>
//           </div>
//         </div>
//       </div>
//     </div>
//   </footer>
// );
// Main Landing Page Component
export default function LandingPage() {
  return (
    <div className="min-h-screen bg-white">
      <Header />
      <Hero handleSignUp={handleSignUp} handleGoogleSignUp={handleGoogleSignUp} />
      <ProblemSection handleSignUp={handleSignUp} />
      <ToolkitIntro />
      <Features />

      <Pricing />
      <FAQ />
      <FinalCTA />
      <Footer />
    </div>
  );
}
