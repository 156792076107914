// import React, { useState, useEffect } from "react";
// import axios from "axios";
// import DashboardHeader from "./DashboardHeader";
// import { Link } from "react-router-dom";
// import TopBanner from "./TopBanner";
// import Satisfaction from "../images/satisfaction.png";
import SerpAiLogo from "../images/serpai.png";
import TopAiToolsLogo from "../images/topaitools.png";
import ThereIsAnAIForThatLogo from "../images/thereisanaiforthat.png";
import ToolifyLogo from "../images/toolify.png";
import AiHuntLogo from "../images/aihunt.png";
// import ReactCountryFlag from "react-country-flag";
// import { getName } from "country-list";
// import ReportFooter from "./ReportFooter";
// import IframeResizer from "iframe-resizer-react";

// const pppGroups = {
//   "0.1-0.2": {
//     countries: ["Iran", "Sudan"],
//     // discount: 70,
//     discount: 30,
//     code: "PDTOMQOR30",
//     // code: "PDNTSAAG70", // Replace with actual coupon code
//   },
//   "0.2-0.3": {
//     countries: [
//       "Afghanistan",
//       "Egypt",
//       "Kyrgyzstan",
//       "Sri Lanka",
//       "Burundi",
//       "Bhutan",
//       "Georgia",
//       "Laos",
//       "Lebanon",
//       "Libya",
//       "Myanmar",
//       "Pakistan",
//       "Sierra Leone",
//       "Tajikistan",
//       "Nepal",
//       "Suriname",
//       "Uzbekistan",
//     ],
//     // discount: 60,
//     discount: 30,
//     code: "PDTOMQOR30",
//     // code: "PDLJXOLV60", // Replace with actual coupon code
//   },
//   "0.3-0.4": {
//     countries: [
//       "Bangladesh",
//       "Burkina Faso",
//       "Benin",
//       "Colombia",
//       "Ethiopia",
//       "Cambodia",
//       "Armenia",
//       "Angola",
//       "Azerbaijan",
//       "Bolivia",
//       "Belarus",
//       "Algeria",
//       "Eritrea",
//       "Ghana",
//       "Gambia",
//       "Guinea-Bissau",
//       "Guyana",
//       "Indonesia",
//       "Kenya",
//       "Kazakhstan",
//       "Moldova",
//       "North Macedonia",
//       "Mali",
//       "Malawi",
//       "Malaysia",
//       "Mozambique",
//       "Nigeria",
//       "Nicaragua",
//       "Philippines",
//       "Paraguay",
//       "Russia",
//       "Rwanda",
//       "Thailand",
//       "Tanzania",
//       "Ukraine",
//       "Uganda",
//       "Vietnam",
//       // "India",
//       "Madagascar",
//       "Mongolia",
//       "Mauritania",
//       "Mauritius",
//       "Somalia",
//       "East Timor",
//       "Tunisia",
//       "Turkey",
//       "Zambia",
//     ],
//     // discount: 50,
//     discount: 30,
//     code: "PDTOMQOR30",
//     // code: "PDJWCEVV50", // Replace with actual coupon code
//   },
//   "0.4-0.5": {
//     countries: [
//       "Albania",
//       "Bosnia and Herzegovina",
//       "Bulgaria",
//       "Botswana",
//       "Cameroon",
//       "Cape Verde",
//       "Equatorial Guinea",
//       "Honduras",
//       "Jordan",
//       "Argentina",
//       "Bahrain",
//       "Brazil",
//       "Democratic Republic of the Congo",
//       "Ivory Coast",
//       "Dominican Republic",
//       "Fiji",
//       "Guinea",
//       "Iraq",
//       "Jamaica",
//       "Comoros",
//       "Liberia",
//       "Lesotho",
//       "Montenegro",
//       "Oman",
//       "Panama",
//       "Mexico",
//       "Namibia",
//       "Saudi Arabia",
//       "Peru",
//       "Poland",
//       "Romania",
//       "Serbia",
//       "Seychelles",
//       "Senegal",
//       "El Salvador",
//       "Turkmenistan",
//       "Taiwan",
//       "Brunei",
//       "Morocco",
//       "Maldives",
//       "Niger",
//       "Eswatini",
//       "Chad",
//       "Togo",
//       "Yemen",
//       "South Africa",
//     ],
//     discount: 30,
//     code: "PDTOMQOR30",
//     // discount: 40,
//     // code: "PDPEMKVY40", // Replace with actual coupon code
//   },
//   "0.5-0.6": {
//     countries: [
//       "United Arab Emirates",
//       "Djibouti",
//       "Ecuador",
//       "Guatemala",
//       "Croatia",
//       "Lithuania",
//       "Central African Republic",
//       "Chile",
//       "Costa Rica",
//       "Czech Republic",
//       "Gabon",
//       "Grenada",
//       "Haiti",
//       "Hungary",
//       "Kuwait",
//       "Latvia",
//       "Sao Tome and Principe",
//       "Trinidad and Tobago",
//       "Saint Vincent and the Grenadines",
//     ],
//     // discount: 30,
//     discount: 20,
//     code: "PDCMDMGO20",
//     // code: "PDTOMQOR30",
//     // code: "PDTOMQOR30", // Replace with actual coupon code
//   },
//   "0.6-0.7": {
//     countries: [
//       "Estonia",
//       "Greece",
//       "Saint Kitts and Nevis",
//       "Belize",
//       "Republic of the Congo",
//       "China",
//       "Kiribati",
//       "Saint Lucia",
//       "Portugal",
//       "Malta",
//       "Qatar",
//       "Slovenia",
//       "Slovakia",
//       "Papua New Guinea",
//       "Singapore",
//       "Dominica",
//       "Macau",
//       "Tonga",
//       "Uruguay",
//       "Venezuela",
//       "Samoa",
//     ],
//     discount: 10,
//     code: "PDCMDMGO10", // Replace with actual coupon code
//     // discount: 20,
//     // code: "PDCMDMGO20", // Replace with actual coupon code
//   },
//   "0.7-0.8": {
//     countries: [
//       "Spain",
//       "Hong Kong",
//       "Aruba",
//       "Cyprus",
//       "Italy",
//       "South Korea",
//       "Antigua and Barbuda",
//       "San Marino",
//       "Zimbabwe",
//       "Japan",
//       "United States",
//       "Germany",
//       "United Kingdom",
//       "France",
//       "Canada",
//       "Australia",
//       "Netherlands",
//       "Sweden",
//       "Switzerland",
//       "Belgium",
//       "Norway",
//     ],
//     discount: 10,
//     code: "PDCMDMGO10", // Replace with actual coupon code
//   },
//   // "0.8-0.9": {
//   //   countries: [
//   //     "Belgium",
//   //     "Bahamas",
//   //     "Germany",
//   //     "France",
//   //     "Palau",
//   //     "Solomon Islands",
//   //     "Nauru",
//   //   ],
//   //   discount: 10,
//   //   code: "COUPON10", // Replace with actual coupon code
//   // },
//   // Add more groups as needed
// };

// const UnpurchasedBanner = () => {
//   return (
//     <div className="bg-green-50 border-l-4 border-green-500 rounded-lg text-gray-800 px-6 py-8 shadow-sm my-8 mx-4">
//       <h3 className="font-semibold text-2xl md:text-3xl mb-4">Unlock Full Potential Of Your Business Idea</h3>
//       <p className="text-lg mb-6">100+ Page Comprehensive Analysis to turn your rough concept into a bulletproof business plan.</p>
//       <p className="text-xl font-medium italic mb-6">⭐⭐⭐⭐⭐ Trusted by over 10,000 businesses, investors, and analysts</p>
//       <div className="flex flex-wrap justify-center md:justify-start items-center gap-8 mt-6">
//         <img src={TopAiToolsLogo} alt="TopAiTools Logo" className="h-10" />
//         <img src={SerpAiLogo} alt="Serp.ai Logo" className="h-10" />
//         <img src={ThereIsAnAIForThatLogo} alt="ThereIsAnAIForThat Logo" className="h-10" />
//         <img src={ToolifyLogo} alt="ToolifyLogo" className="h-10" />
//       </div>
//     </div>
//   );
// };

// function Credits() {
//   const [questionCredits, setQuestionCredits] = useState(0);
//   const [reportCredits, setReportCredits] = useState(0);
//   const [minireportCredits, setMiniReportCredits] = useState(0);
//   const [email, setEmail] = useState([""]);
//   const [userID, setUserID] = useState(0);
//   const [isLoading, setIsLoading] = useState(true);
//   const [userCountry, setUserCountry] = useState("");
//   const [discountInfo, setDiscountInfo] = useState({});

//   const environment = process.env.REACT_APP_ENV;

//   useEffect(() => {
//     const fetchInitialData = async () => {
//       setIsLoading(true);
//       const token = localStorage.getItem("access"); // Assuming token is stored in localStorage
//       if (!token) {
//         alert("Please log in first!");
//         setIsLoading(false);
//         return;
//       }

//       try {
//         // Fetch user email and ID
//         const response = await axios.get(`${process.env.REACT_APP_API_BASE_URL}get-user-email/`, {
//           headers: {
//             Authorization: `Bearer ${token}`,
//           },
//         });

//         if (response.data) {
//           const { email, id } = response.data;
//           setEmail(email);
//           setUserID(id);

//           // Fetch user location
//           const locationResponse = await axios.get("https://ipinfo.io?token=54225501c526ff");
//           const countryCode = locationResponse.data.country;
//           const countryName = getName(countryCode);
//           setUserCountry(countryName);
//           findDiscountInfo(countryName);
//         }
//       } catch (error) {
//         console.error("Error fetching data:", error);
//       } finally {
//         setIsLoading(false);
//       }
//     };

//     fetchInitialData();
//     fetchUserCredits();
//   }, []); // This effect runs once on component mount.

//   const calculatePricing = (basePrice) => {
//     if (!discountInfo || !discountInfo.discount) return { originalPrice: basePrice, discountedPrice: basePrice };

//     const discountedPrice = basePrice * (1 - discountInfo.discount / 100);
//     return {
//       originalPrice: basePrice,
//       discountedPrice: discountedPrice.toFixed(2),
//     };
//   };

//   const findDiscountInfo = (countryName) => {
//     let discountDetails = {};
//     Object.entries(pppGroups).forEach(([_, groupInfo]) => {
//       if (groupInfo.countries.includes(countryName)) {
//         discountDetails = { ...groupInfo, countryName }; // Include countryName
//       }
//     });
//     setDiscountInfo(discountDetails);
//   };

//   // Define the different parts that change between environments
//   const urlConfig = {
//     questionsId:
//       process.env.REACT_APP_ENV === "production" ? "afc176d7-b7f1-4de7-bd5d-806c10d241b5" : "86ba0ed1-9ce1-4322-a03c-f625fa8cda80",
//     reportId: process.env.REACT_APP_ENV === "production" ? "ae705003-1f8d-4a13-80d5-73adf1742630" : "5c6763da-aae6-4cb1-80ac-9fcc2fb17545",
//     characterId:
//       process.env.REACT_APP_ENV === "production" ? "855feb8c-be09-47c2-9f4d-cb7c3bd6071d" : "114482fb-ae03-4b52-ab0c-d979d538eae7",
//   };

//   // Base URL
//   const baseUrl = "https://feedbackbyai1.lemonsqueezy.com/checkout/buy/";

//   const fetchUserCredits = async () => {
//     const token = localStorage.getItem("access");
//     if (!token) {
//       alert("Please log in first!");
//       return;
//     }
//     const bearer_token = "Bearer " + token;
//     try {
//       const response = await axios.get(`${process.env.REACT_APP_API_BASE_URL}get-user-credits/`, {
//         headers: {
//           Authorization: bearer_token,
//         },
//       });
//       const data = response.data;
//       setQuestionCredits(data.question_credits);
//       setReportCredits(data.report_credits);
//       setMiniReportCredits(data.mini_report_credits);
//       setIsLoading(false);
//     } catch (error) {
//       console.error("Error fetching user credits:", error);
//       setIsLoading(false);
//     }
//   };

//   return (
//     <div className="bg-gray-50 min-h-screen">
//       <TopBanner />
//       <DashboardHeader />

//       <div className="min-h-screen px-4 py-20 md:px-16 ">
//         <UnpurchasedBanner />

//         <div className="max-w-8xl mx-auto bg-gradient-to-b from-gray-50 to-green-100 rounded-xl  p-6 md:p-8 space-y-6">
//           {isLoading ? (
//             <div className="text-center text-2xl font-semibold">Loading...</div>
//           ) : (
//             <>
//               {/* Credits Section */}
//               <div className="space-y-6 mt-10">
//                 <h1 className="text-4xl font-extrabold text-center">Your Credits</h1>
//                 <div className="md:grid md:grid-cols-2 gap-8 bg-white shadow-lg p-8 rounded-xl">
//                   <CreditBox title="Question Credits" credit={questionCredits} />
//                   <CreditBox title="Report Credits" credit={reportCredits} />

//                   {/* <CreditBox
//                     title="Mini Report Credits"
//                     credit={minireportCredits}
//                   /> */}
//                 </div>
//               </div>

//               <div className="bg-indigo-100 border-t-4  rounded-lg  px-6 py-4 shadow-lg mx-4">
//                 <h3 className="font-bold text-lg md:text-xl mb-2">Loved Our Service?</h3>
//                 <p className="text-md md:text-lg text-indigo-800 mb-6">
//                   Share your experience and get a <strong>discount coupon code</strong> as a thank you!
//                 </p>
//                 <a
//                   href="https://testimonial.to/feedbackbyai"
//                   className="bg-indigo-500 hover:bg-indigo-600 text-white font-bold py-2 px-4 rounded"
//                 >
//                   Submit Testimonial
//                 </a>
//               </div>

//               {/* Affiliate Program Card */}
//               <div className="mt-10">
//                 <div className="bg-blue-100 border-t-4 border-blue-500 rounded-lg text-blue-900 px-6 py-4 shadow-lg mx-4">
//                   <h3 className="font-bold text-lg md:text-xl mb-2">Join Our Affiliate Program</h3>
//                   <p className="text-sm md:text-base">
//                     Earn While Helping Others Better Their Business! Join our affiliate program and get rewarded for every one you help.
//                     {/* p.s. Also join Our Affiliate Program – Earn While Helping Others Better Their Business! */}
//                   </p>
//                   <a
//                     href="https://feedbackbyai1.lemonsqueezy.com/affiliates/"
//                     target="_blank"
//                     rel="noopener noreferrer"
//                     className="inline-block mt-4 font-bold bg-blue-500 text-white px-6 py-2 rounded-md hover:bg-blue-600 transition-colors duration-300"
//                   >
//                     Learn More &amp; Join Now
//                   </a>
//                 </div>
//               </div>
//               {/* Suggest a Feature  */}

//               <div className="mt-10">
//                 <div className="border-t-4 bg-indigo-100 rounded-lg text-blue-900 px-6 py-4 shadow-lg mx-4">
//                   <h3 className="font-bold text-lg md:text-xl mb-2">Suggest a Feature</h3>
//                   <p className="text-sm md:text-base">
//                     Some feature missing on FeedbackbyAI? Suggest it here.
//                     {/* p.s. Also join Our Affiliate Program – Earn While Helping Others Better Their Business! */}
//                   </p>
//                   <a
//                     href="https://form.jotform.com/240401848839462"
//                     target="_blank"
//                     rel="noopener noreferrer"
//                     className="inline-block mt-4 font-bold bg-blue-500 text-white px-6 py-2 rounded-md hover:bg-blue-600 transition-colors duration-300"
//                   >
//                     Suggest Feature
//                   </a>
//                 </div>
//               </div>

//               <div className="space-y-8 ">
//                 {/* 100% Satisifaction Guarantee */}
//                 <section className="bg-white py-10">
//                   <div className="max-w-4xl mx-auto px-4 sm:px-6 text-center">
//                     <div className="flex flex-col items-center justify-center space-y-6">
//                       <img
//                         className="h-48 w-48 md:h-48 md:w-48" // Increase the size of the image
//                         src={Satisfaction} // Make sure to use the correct path to your image
//                         alt="100% Satisfaction Guarantee Badge"
//                       />

//                       <p className="mt-8 text-gray-600 text-xl italic">
//                         If you're not satisfied with our report, we'll refund you 100% without any questions asked! But we're sure you'll{" "}
//                         <span className="text-green-500">love our advice </span> for success in entrepreneurship!
//                       </p>
//                       <p className="mt-4 text-gray-600 text-md italic font-semibold">
//                         *Refund valid within 24 hours of purchase, provided less than 5 sections of the report are generated.
//                       </p>
//                     </div>
//                   </div>
//                 </section>

//                 <h1 className="text-4xl font-bold text-center mt-20" id="pricing-card">
//                   {/* Unlock Full Potential Of Your Business Idea */}
//                   Comprehensive Roadmap For Your Business Idea
//                 </h1>

//                 <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-3 gap-8">
//                   {[
//                     {
//                       price: "$59",
//                       plan: "Starter",
//                       basePrice: 59, // Add base price here
//                       benefits: [
//                         "1 Full 100+ Page Business Report",
//                         // "Free Preview of Mini Report",
//                         "25 AI Character Feedbacks",
//                         " Save/Download Your Report as Docs & PDF",

//                         " Access to all 50+ AI Experts",
//                         // "1 Free Corporate Astrology Consultation",
//                         // "✅  Save $21.50",
//                       ],
//                       linkTo: {
//                         local: `https://feedbackbyai1.lemonsqueezy.com/checkout/buy/5bd7a26c-6298-46f6-9f17-9f100cfb9d8a?checkout[email]=${email}&checkout[custom][report_credits]=1&checkout[custom][question_credits]=25&checkout[custom][mini_report]=0&checkout[custom][type]=report&checkout[custom][astroplan]=no&checkout[custom][astrologyReportId]=0&checkout[custom][userId]=${userID}`,
//                         production: `https://feedbackbyai1.lemonsqueezy.com/checkout/buy/5bd7a26c-6298-46f6-9f17-9f100cfb9d8a?checkout[email]=${email}&checkout[custom][report_credits]=1&checkout[custom][question_credits]=25&checkout[custom][mini_report]=0&checkout[custom][type]=report&checkout[custom][astroplan]=no&checkout[custom][astrologyReportId]=0&checkout[custom][userId]=${userID}`,
//                       },

//                       // this in live product-->  linkTo: `https://feedbackbyai1.lemonsqueezy.com/checkout/buy/ff1e004b-3ef0-4478-8837-9f31a31e9b97?checkout[email]=${email}&checkout[custom][report_credits]=1&checkout[custom][question_credits]=50`,
//                       // linkTo: `https://feedbackbyai1.lemonsqueezy.com/checkout/buy/58f0ed06-c27d-40d7-9ebf-3d821c703fa9?checkout[email]=${email}&checkout[custom][report_credits]=1&checkout[custom][question_credits]=50`,
//                     },

//                     {
//                       price: "$99",
//                       plan: "Select",
//                       basePrice: 99, // Add base price here
//                       benefits: [
//                         "2 Full 100+ Page Business Report",
//                         // "Free Preview of Mini Report",
//                         "40 AI Character Feedbacks",
//                         // " Save/Download Your Report as PDF",
//                         " Save/Download Your Report as Docs & PDF",
//                         " Access to all 50+ AI Experts",
//                         // "1 Free Corporate Astrology Consultation",
//                         // "✅  Save $21.50",
//                       ],
//                       linkTo: {
//                         local: `https://feedbackbyai1.lemonsqueezy.com/checkout/buy/e9992d3f-639a-4820-8371-d57b5ab99ec4?checkout[email]=${email}&checkout[custom][report_credits]=2&checkout[custom][question_credits]=40&checkout[custom][mini_report]=0&checkout[custom][type]=report&checkout[custom][astroplan]=no&checkout[custom][astrologyReportId]=0&checkout[custom][userId]=${userID}`,
//                         production: `https://feedbackbyai1.lemonsqueezy.com/checkout/buy/e9992d3f-639a-4820-8371-d57b5ab99ec4?checkout[email]=${email}&checkout[custom][report_credits]=2&checkout[custom][question_credits]=40&checkout[custom][mini_report]=0&checkout[custom][type]=report&checkout[custom][astroplan]=no&checkout[custom][astrologyReportId]=0&checkout[custom][userId]=${userID}`,
//                       },

//                       // this in live product-->  linkTo: `https://feedbackbyai1.lemonsqueezy.com/checkout/buy/ff1e004b-3ef0-4478-8837-9f31a31e9b97?checkout[email]=${email}&checkout[custom][report_credits]=1&checkout[custom][question_credits]=50`,
//                       // linkTo: `https://feedbackbyai1.lemonsqueezy.com/checkout/buy/58f0ed06-c27d-40d7-9ebf-3d821c703fa9?checkout[email]=${email}&checkout[custom][report_credits]=1&checkout[custom][question_credits]=50`,
//                     },

//                     // {
//                     //   price: "$104",
//                     //   plan: "Growth",
//                     //   basePrice: 104, // Add base price here
//                     //   benefits: [
//                     //     "3 Full 100+ Page Business Reports",
//                     //     // "Free Preview of Mini Report",
//                     //     "50 AI Character Feedbacks",
//                     //     " Save/Download Your Report as Docs & PDF",
//                     //     "Access to all 50+ AI Experts",
//                     //     // "1 Free Corporate Astrology Consultation",
//                     //     // " ✅ Save $96",
//                     //   ],
//                     //   linkTo: {
//                     //     local: `https://feedbackbyai1.lemonsqueezy.com/checkout/buy/4945e48e-bde4-4616-a83c-7d9ebc6e19fe?checkout[email]=${email}&checkout[custom][report_credits]=3&checkout[custom][question_credits]=50&checkout[custom][mini_report]=0&checkout[custom][type]=report&checkout[custom][astroplan]=no&checkout[custom][astrologyReportId]=0&checkout[custom][userId]=${userID}`,
//                     //     production: `https://feedbackbyai1.lemonsqueezy.com/checkout/buy/60daf73b-479a-439d-a045-4ccef5a02512?checkout[email]=${email}&checkout[custom][report_credits]=3&checkout[custom][question_credits]=50&checkout[custom][mini_report]=0&checkout[custom][type]=report&checkout[custom][astroplan]=no&checkout[custom][astrologyReportId]=0&checkout[custom][userId]=${userID}`,
//                     //   },

//                     //   // this in live product-->  linkTo: `https://feedbackbyai1.lemonsqueezy.com/checkout/buy/ff1e004b-3ef0-4478-8837-9f31a31e9b97?checkout[email]=${email}&checkout[custom][report_credits]=3&checkout[custom][question_credits]=75`,
//                     //   // linkTo: `https://feedbackbyai1.lemonsqueezy.com/checkout/buy/4945e48e-bde4-4616-a83c-7d9ebc6e19fe?checkout[email]=${email}&checkout[custom][report_credits]=3&checkout[custom][question_credits]=75`,
//                     // },
//                     {
//                       price: "$248",
//                       plan: "Pro",
//                       basePrice: 248, // Add base price here
//                       benefits: [
//                         "Save $350",
//                         "10 Full 100+ Page Business Reports",
//                         // "Free Preview of Mini Report",
//                         "100 AI Character Feedbacks",
//                         " Save/Download Your Report as Docs & PDF",
//                         "Access to all 50+ AI Experts",
//                         // "1 Free Corporate Astrology Consultation",
//                       ],
//                       linkTo: {
//                         local: `https://feedbackbyai1.lemonsqueezy.com/checkout/buy/c8e93455-8f64-49ff-a7d8-ae517e9c0ea5?checkout[email]=${email}&checkout[custom][report_credits]=10&checkout[custom][question_credits]=100&checkout[custom][mini_report]=0&checkout[custom][type]=report&checkout[custom][astroplan]=no&checkout[custom][astrologyReportId]=0&checkout[custom][userId]=${userID}`,
//                         production: `https://feedbackbyai1.lemonsqueezy.com/checkout/buy/ee88bf6b-38ae-4eac-9e11-d2ca8d38a051?checkout[email]=${email}&checkout[custom][report_credits]=10&checkout[custom][question_credits]=100&checkout[custom][mini_report]=0&checkout[custom][type]=report&checkout[custom][astroplan]=no&checkout[custom][astrologyReportId]=0&checkout[custom][userId]=${userID}`,
//                       },

//                       // this in live product-->  linkTo: `https://feedbackbyai1.lemonsqueezy.com/checkout/buy/ff1e004b-3ef0-4478-8837-9f31a31e9b97?checkout[email]=${email}&checkout[custom][report_credits]=5&checkout[custom][question_credits]=100`,
//                       // linkTo: `https://feedbackbyai1.lemonsqueezy.com/checkout/buy/c8e93455-8f64-49ff-a7d8-ae517e9c0ea5?checkout[email]=${email}&checkout[custom][report_credits]=5&checkout[custom][question_credits]=100`,
//                     },

//                     // {
//                     //   price: "$491",
//                     //   plan: "Ultimate",
//                     //   basePrice: 491, // Add base price here
//                     //   benefits: [
//                     //     "Save $984",
//                     //     "25 Full 100+ Page Business Reports",
//                     //     // "Free Preview of Mini Report",
//                     //     "250 AI Character Feedbacks",
//                     //     " Save/Download Your Report as Docs & PDF",
//                     //     "Access to all 50+ AI Experts",
//                     //     // "1 Free Corporate Astrology Consultation",
//                     //   ],
//                     //   linkTo: {
//                     //     local: `https://feedbackbyai1.lemonsqueezy.com/checkout/buy/25a5a875-f9f2-44d0-9ed0-5591f8285391?checkout[email]=${email}&checkout[custom][report_credits]=25&checkout[custom][question_credits]=250&checkout[custom][mini_report]=0&checkout[custom][type]=report&checkout[custom][astroplan]=no&checkout[custom][astrologyReportId]=0&checkout[custom][userId]=${userID}`,
//                     //     production: `https://feedbackbyai1.lemonsqueezy.com/checkout/buy/25a5a875-f9f2-44d0-9ed0-5591f8285391?checkout[email]=${email}&checkout[custom][report_credits]=25&checkout[custom][question_credits]=250&checkout[custom][mini_report]=0&checkout[custom][type]=report&checkout[custom][astroplan]=no&checkout[custom][astrologyReportId]=0&checkout[custom][userId]=${userID}`,
//                     //   },

//                     // this in live product-->  linkTo: `https://feedbackbyai1.lemonsqueezy.com/checkout/buy/ff1e004b-3ef0-4478-8837-9f31a31e9b97?checkout[email]=${email}&checkout[custom][report_credits]=5&checkout[custom][question_credits]=100`,
//                     // linkTo: `https://feedbackbyai1.lemonsqueezy.com/checkout/buy/c8e93455-8f64-49ff-a7d8-ae517e9c0ea5?checkout[email]=${email}&checkout[custom][report_credits]=5&checkout[custom][question_credits]=100`,
//                     // },
//                   ].map((plan, index) => (
//                     <PricingCard
//                       key={index}
//                       {...plan}
//                       linkTo={plan.linkTo[environment]}
//                       isHighlighted={index === 2}
//                       originalPrice={calculatePricing(plan.basePrice).originalPrice}
//                       price={calculatePricing(plan.basePrice).discountedPrice}
//                       discountInfo={discountInfo}
//                     />
//                   ))}
//                 </div>
//               </div>
//             </>
//           )}
//         </div>
//       </div>
//       <div className="text-center">
//         <h3 className="text-center text-4xl lg:text-5xl text-gray-800 mb-4 font-bold mt-5">Wall of Love</h3>
//         <IframeResizer src="https://embed-v2.testimonial.to/w/feedbackbyai" style={{ width: "1px", minWidth: "100%" }} />
//       </div>
//       <ReportFooter />
//     </div>
//   );
// }

// const CreditBox = ({ title, credit }) => (
//   <div className="flex flex-col items-center space-y-2 bg-white p-6 rounded-2xl shadow-sm">
//     <h2 className="text-lg font-medium text-gray-600">{title}</h2>
//     <div className="text-4xl font-light text-green-600">{credit}</div>
//   </div>
// );

// // Define the Feature component for list items with conditional styling for numbers
// // Define the Feature component for list items with specific styling
// const Feature = ({ children }) => (
//   <li className="flex items-center mb-3">
//     <svg className="w-5 h-5 text-green-500 mr-3" fill="none" viewBox="0 0 24 24" stroke="currentColor">
//       <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M5 13l4 4L19 7" />
//     </svg>
//     <span className="text-gray-700">{children}</span>
//   </li>
// );
// function PricingCard({ plan, price, originalPrice, discountInfo, benefits, linkTo, isHighlighted = false }) {
//   const highlightedClasses = isHighlighted ? "border border-green-500 bg-green-50 shadow-2xl" : "shadow-lg";

//   // const hasDiscount = discountInfo && discountInfo.code;
//   // Determine if there's a discount
//   const hasDiscount = discountInfo && discountInfo.discount;
//   const pricingMessage = hasDiscount
//     ? `🇮🇳 Special Pricing for ${discountInfo.countryName} - ${discountInfo.discount}% off (Use Code ${discountInfo.code})`
//     : "";

//   // Modified linkTo to include the discount code dynamically
//   const finalLinkTo = hasDiscount ? `${linkTo}&checkout%5Bdiscount_code%5D=${discountInfo.code}` : linkTo;

//   return (
//     <div
//       className={`max-w-sm mx-auto bg-white rounded-lg overflow-hidden hover:shadow-2xl transition-shadow duration-300 ${highlightedClasses}`}
//     >
//       {isHighlighted && (
//         <div className="text-center py-2 bg-green-100">
//           <span className="text-green-600 font-semibold">Best Value Plan!</span>
//         </div>
//       )}
//       <div className="px-6 py-8">
//         <div className="text-center">
//           <span className="text-3xl font-bold text-gray-900">{plan}</span>
//           <div className="mt-2">
//             <span className={`text-2xl font-bold ${isHighlighted ? "text-green-600" : "text-gray-900"}`}>
//               {plan === "Free" ? "Free" : `$${price}`}
//             </span>
//             {originalPrice && hasDiscount && <span className="ml-2 text-xl font-bold line-through text-gray-500">${originalPrice}</span>}
//           </div>
//         </div>
//         {pricingMessage && (
//           <div className="text-center mt-4">
//             <span className="text-green-600 font-semibold">{pricingMessage}</span>
//           </div>
//         )}
//         {/* ... other code */}
//         <hr className="my-6 border-gray-200" />
//         {/* ... Rest of the component */}
//         <ul className="mb-4 space-y-3">
//           {benefits.map((benefit, idx) => {
//             // Check if the benefit contains a number and highlight it
//             const isHighlight = /\d+/.test(benefit);
//             return (
//               <Feature key={idx} highlight={isHighlight}>
//                 {benefit}
//               </Feature>
//             );
//           })}
//         </ul>
//       </div>
//       {price !== "$0" && (
//         <div className="px-6 pt-6 pb-8 bg-gray-50 rounded-b-lg">
//           <Link to={finalLinkTo}>
//             <button
//               className={`btn-sm block w-full px-4 py-3 text-sm font-semibold text-center text-white ${
//                 isHighlighted
//                   ? "bg-green-600 hover:bg-green-700"
//                   : "bg-gradient-to-r from-green-600 to-green-600 hover:bg-gradient-to-r hover:from-green-600 hover:to-green-700 animate-pulse"
//               }`}
//             >
//               {isHighlighted ? "Choose This Plan" : "Buy Now"}
//             </button>
//           </Link>
//         </div>
//       )}
//     </div>
//   );
// }

// function RechargeOption({ description, price, linkTo }) {
//   return (
//     <div className="flex justify-between items-center shadow-sm p-4 rounded-lg bg-white">
//       <span className="text-xl font-semibold">{description}</span>
//       <Link to={linkTo}>
//         <button className="bg-green-600 hover:bg-green-700 text-white px-6 py-3 rounded-md transition-colors duration-300 ease-in-out">
//           Recharge
//         </button>
//       </Link>
//     </div>
//   );
// }

// export default Credits;

// import React, { useState, useEffect } from "react";
// import axios from "axios";
// import { Link } from "react-router-dom";
// import TopBanner from "./TopBanner";
// import ReportFooter from "./ReportFooter";
// import ReactCountryFlag from "react-country-flag";
// import { getName } from "country-list";
// import IframeResizer from "iframe-resizer-react";
// import SerpAiLogo from "../images/serpai.png";
// import TopAiToolsLogo from "../images/topaitools.png";
// import ThereIsAnAIForThatLogo from "../images/thereisanaiforthat.png";
// import ToolifyLogo from "../images/toolify.png";
// import Satisfaction from "../images/satisfaction.png";

// // PPP Groups configuration
// const pppGroups = {
//   "0.1-0.2": {
//     countries: ["Iran", "Sudan"],
//     discount: 30,
//     code: "PDTOMQOR30",
//   },
//   // .... rest of the pppGroups object remains unchanged
//   "0.7-0.8": {
//     countries: [
//       "Spain",
//       "Hong Kong",
//       "Aruba",
//       "Cyprus",
//       "Italy",
//       "South Korea",
//       "Antigua and Barbuda",
//       "San Marino",
//       "Zimbabwe",
//       "Japan",
//       "United States",
//       "Germany",
//       "United Kingdom",
//       "France",
//       "Canada",
//       "Australia",
//       "Netherlands",
//       "Sweden",
//       "Switzerland",
//       "Belgium",
//       "Norway",
//     ],
//     discount: 10,
//     code: "PDCMDMGO10",
//   },
// };

// function Credits() {
//   // State variables
//   const [questionCredits, setQuestionCredits] = useState(0);
//   const [reportCredits, setReportCredits] = useState(0);
//   const [minireportCredits, setMiniReportCredits] = useState(0);
//   const [email, setEmail] = useState([""]);
//   const [userID, setUserID] = useState(0);
//   const [isLoading, setIsLoading] = useState(true);
//   const [userCountry, setUserCountry] = useState("");
//   const [discountInfo, setDiscountInfo] = useState({});

//   const environment = process.env.REACT_APP_ENV;

//   // Initial data loading
//   useEffect(() => {
//     const fetchInitialData = async () => {
//       setIsLoading(true);
//       const token = localStorage.getItem("access");
//       if (!token) {
//         alert("Please log in first!");
//         setIsLoading(false);
//         return;
//       }

//       try {
//         // Fetch user email and ID
//         const response = await axios.get(`${process.env.REACT_APP_API_BASE_URL}get-user-email/`, {
//           headers: {
//             Authorization: `Bearer ${token}`,
//           },
//         });

//         if (response.data) {
//           const { email, id } = response.data;
//           setEmail(email);
//           setUserID(id);

//           // Fetch user location
//           const locationResponse = await axios.get("https://ipinfo.io?token=54225501c526ff");
//           const countryCode = locationResponse.data.country;
//           const countryName = getName(countryCode);
//           setUserCountry(countryName);
//           findDiscountInfo(countryName);
//         }
//       } catch (error) {
//         console.error("Error fetching data:", error);
//       } finally {
//         setIsLoading(false);
//       }
//     };

//     fetchInitialData();
//     fetchUserCredits();
//   }, []);

//   // Calculate pricing with discounts
//   const calculatePricing = (basePrice) => {
//     if (!discountInfo || !discountInfo.discount) return { originalPrice: basePrice, discountedPrice: basePrice };

//     const discountedPrice = basePrice * (1 - discountInfo.discount / 100);
//     return {
//       originalPrice: basePrice,
//       discountedPrice: discountedPrice.toFixed(2),
//     };
//   };

//   // Find discount information based on country
//   const findDiscountInfo = (countryName) => {
//     let discountDetails = {};
//     Object.entries(pppGroups).forEach(([_, groupInfo]) => {
//       if (groupInfo.countries.includes(countryName)) {
//         discountDetails = { ...groupInfo, countryName };
//       }
//     });
//     setDiscountInfo(discountDetails);
//   };

//   // URL configuration based on environment
//   const urlConfig = {
//     questionsId:
//       process.env.REACT_APP_ENV === "production" ? "afc176d7-b7f1-4de7-bd5d-806c10d241b5" : "86ba0ed1-9ce1-4322-a03c-f625fa8cda80",
//     reportId: process.env.REACT_APP_ENV === "production" ? "ae705003-1f8d-4a13-80d5-73adf1742630" : "5c6763da-aae6-4cb1-80ac-9fcc2fb17545",
//     characterId:
//       process.env.REACT_APP_ENV === "production" ? "855feb8c-be09-47c2-9f4d-cb7c3bd6071d" : "114482fb-ae03-4b52-ab0c-d979d538eae7",
//   };

//   // Fetch user credits
//   const fetchUserCredits = async () => {
//     const token = localStorage.getItem("access");
//     if (!token) {
//       alert("Please log in first!");
//       return;
//     }
//     const bearer_token = "Bearer " + token;
//     try {
//       const response = await axios.get(`${process.env.REACT_APP_API_BASE_URL}get-user-credits/`, {
//         headers: {
//           Authorization: bearer_token,
//         },
//       });
//       const data = response.data;
//       setQuestionCredits(data.question_credits);
//       setReportCredits(data.report_credits);
//       setMiniReportCredits(data.mini_report_credits);
//       setIsLoading(false);
//     } catch (error) {
//       console.error("Error fetching user credits:", error);
//       setIsLoading(false);
//     }
//   };

//   return (
//     <div className="min-h-screen bg-white">
//       {/* Header - Matching the report dashboard */}
//       <header className="h-16 border-b border-gray-100 flex items-center px-8">
//         <div className="max-w-6xl w-full mx-auto flex justify-between">
//           <span className="font-medium text-xl text-green-600">FeedbackbyAI</span>
//           <Link to="/" className="text-gray-500 hover:text-gray-700">
//             Back to Dashboard
//           </Link>
//         </div>
//       </header>

//       <main className="max-w-6xl mx-auto px-6 py-12">
//         {/* Page Header */}
//         <div className="flex flex-col items-center justify-center mb-10">
//           <div className="w-20 h-20 bg-green-50 rounded-full flex items-center justify-center mb-6">
//             <svg className="w-10 h-10 text-green-600" fill="none" viewBox="0 0 24 24" stroke="currentColor">
//               <path
//                 strokeLinecap="round"
//                 strokeLinejoin="round"
//                 strokeWidth="2"
//                 d="M12 8c-1.657 0-3 .895-3 2s1.343 2 3 2 3 .895 3 2-1.343 2-3 2m0-8c1.11 0 2.08.402 2.599 1M12 8V7m0 1v8m0 0v1m0-1c-1.11 0-2.08-.402-2.599-1M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
//               />
//             </svg>
//           </div>
//           <h1 className="text-3xl font-bold text-gray-900 text-center mb-2">Your Credits</h1>
//           <p className="text-center text-gray-600 max-w-2xl mx-auto">
//             View your current credits and purchase more to continue using FeedbackbyAI
//           </p>
//         </div>

//         {/* Loading Indicator */}
//         {isLoading ? (
//           <div className="flex justify-center items-center py-12">
//             <div className="animate-spin rounded-full h-12 w-12 border-t-2 border-b-2 border-green-500"></div>
//           </div>
//         ) : (
//           <>
//             {/* Current Credits */}
//             <div className="bg-white rounded-2xl shadow-xl border border-gray-100 p-8 mb-12">
//               <h2 className="text-xl font-bold text-gray-800 mb-6">Your Available Credits</h2>
//               <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
//                 <div className="bg-gray-50 rounded-xl p-6 border border-gray-200">
//                   <div className="flex items-center mb-4">
//                     <div className="w-10 h-10 bg-green-100 rounded-full flex items-center justify-center mr-3">
//                       <svg className="w-5 h-5 text-green-600" fill="none" viewBox="0 0 24 24" stroke="currentColor">
//                         <path
//                           strokeLinecap="round"
//                           strokeLinejoin="round"
//                           strokeWidth="2"
//                           d="M8 10h.01M12 10h.01M16 10h.01M9 16H5a2 2 0 01-2-2V6a2 2 0 012-2h14a2 2 0 012 2v8a2 2 0 01-2 2h-5l-5 5v-5z"
//                         />
//                       </svg>
//                     </div>
//                     <div>
//                       <h3 className="font-medium text-gray-800">Question Credits</h3>
//                       <p className="text-sm text-gray-500">Use for AI character feedback</p>
//                     </div>
//                   </div>
//                   <p className="text-3xl font-bold text-green-600">{questionCredits}</p>
//                 </div>

//                 <div className="bg-gray-50 rounded-xl p-6 border border-gray-200">
//                   <div className="flex items-center mb-4">
//                     <div className="w-10 h-10 bg-blue-100 rounded-full flex items-center justify-center mr-3">
//                       <svg className="w-5 h-5 text-blue-600" fill="none" viewBox="0 0 24 24" stroke="currentColor">
//                         <path
//                           strokeLinecap="round"
//                           strokeLinejoin="round"
//                           strokeWidth="2"
//                           d="M9 12h6m-6 4h6m2 5H7a2 2 0 01-2-2V5a2 2 0 012-2h5.586a1 1 0 01.707.293l5.414 5.414a1 1 0 01.293.707V19a2 2 0 01-2 2z"
//                         />
//                       </svg>
//                     </div>
//                     <div>
//                       <h3 className="font-medium text-gray-800">Report Credits</h3>
//                       <p className="text-sm text-gray-500">Use for business plan reports</p>
//                     </div>
//                   </div>
//                   <p className="text-3xl font-bold text-blue-600">{reportCredits}</p>
//                 </div>
//               </div>
//             </div>

//             {/* Business Plan Section */}
//             <div className="bg-white rounded-2xl shadow-xl border border-gray-100 p-8 mb-12">
//               <div className="flex flex-col md:flex-row md:items-center md:justify-between gap-6 mb-8">
//                 <div>
//                   <h2 className="text-2xl font-bold text-gray-800">Comprehensive Business Plan</h2>
//                   <p className="text-gray-600 mt-2">Get a detailed roadmap for your business idea with actionable insights</p>
//                 </div>

//                 <div className="flex flex-wrap items-center gap-4">
//                   <img src={TopAiToolsLogo} alt="TopAiTools Logo" className="h-8" />
//                   <img src={SerpAiLogo} alt="Serp.ai Logo" className="h-8" />
//                   <img src={ThereIsAnAIForThatLogo} alt="ThereIsAnAIForThat Logo" className="h-8" />
//                 </div>
//               </div>

//               <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
//                 {/* Pricing cards */}
//                 {[
//                   {
//                     price: "$59",
//                     plan: "Starter",
//                     basePrice: 59,
//                     benefits: [
//                       "1 Full 100+ Page Business Report",
//                       "25 AI Character Feedbacks",
//                       "Save/Download Your Report as Docs & PDF",
//                       "Access to all 50+ AI Experts",
//                     ],
//                     linkTo: {
//                       local: `https://feedbackbyai1.lemonsqueezy.com/checkout/buy/5bd7a26c-6298-46f6-9f17-9f100cfb9d8a?checkout[email]=${email}&checkout[custom][report_credits]=1&checkout[custom][question_credits]=25&checkout[custom][mini_report]=0&checkout[custom][type]=report&checkout[custom][astroplan]=no&checkout[custom][astrologyReportId]=0&checkout[custom][userId]=${userID}`,
//                       production: `https://feedbackbyai1.lemonsqueezy.com/checkout/buy/5bd7a26c-6298-46f6-9f17-9f100cfb9d8a?checkout[email]=${email}&checkout[custom][report_credits]=1&checkout[custom][question_credits]=25&checkout[custom][mini_report]=0&checkout[custom][type]=report&checkout[custom][astroplan]=no&checkout[custom][astrologyReportId]=0&checkout[custom][userId]=${userID}`,
//                     },
//                   },
//                   {
//                     price: "$99",
//                     plan: "Select",
//                     basePrice: 99,
//                     benefits: [
//                       "2 Full 100+ Page Business Reports",
//                       "40 AI Character Feedbacks",
//                       "Save/Download Your Report as Docs & PDF",
//                       "Access to all 50+ AI Experts",
//                     ],
//                     linkTo: {
//                       local: `https://feedbackbyai1.lemonsqueezy.com/checkout/buy/e9992d3f-639a-4820-8371-d57b5ab99ec4?checkout[email]=${email}&checkout[custom][report_credits]=2&checkout[custom][question_credits]=40&checkout[custom][mini_report]=0&checkout[custom][type]=report&checkout[custom][astroplan]=no&checkout[custom][astrologyReportId]=0&checkout[custom][userId]=${userID}`,
//                       production: `https://feedbackbyai1.lemonsqueezy.com/checkout/buy/e9992d3f-639a-4820-8371-d57b5ab99ec4?checkout[email]=${email}&checkout[custom][report_credits]=2&checkout[custom][question_credits]=40&checkout[custom][mini_report]=0&checkout[custom][type]=report&checkout[custom][astroplan]=no&checkout[custom][astrologyReportId]=0&checkout[custom][userId]=${userID}`,
//                     },
//                   },
//                   {
//                     price: "$248",
//                     plan: "Pro",
//                     basePrice: 248,
//                     benefits: [
//                       "Save $350",
//                       "10 Full 100+ Page Business Reports",
//                       "100 AI Character Feedbacks",
//                       "Save/Download Your Report as Docs & PDF",
//                       "Access to all 50+ AI Experts",
//                     ],
//                     linkTo: {
//                       local: `https://feedbackbyai1.lemonsqueezy.com/checkout/buy/c8e93455-8f64-49ff-a7d8-ae517e9c0ea5?checkout[email]=${email}&checkout[custom][report_credits]=10&checkout[custom][question_credits]=100&checkout[custom][mini_report]=0&checkout[custom][type]=report&checkout[custom][astroplan]=no&checkout[custom][astrologyReportId]=0&checkout[custom][userId]=${userID}`,
//                       production: `https://feedbackbyai1.lemonsqueezy.com/checkout/buy/ee88bf6b-38ae-4eac-9e11-d2ca8d38a051?checkout[email]=${email}&checkout[custom][report_credits]=10&checkout[custom][question_credits]=100&checkout[custom][mini_report]=0&checkout[custom][type]=report&checkout[custom][astroplan]=no&checkout[custom][astrologyReportId]=0&checkout[custom][userId]=${userID}`,
//                     },
//                   },
//                 ].map((plan, index) => (
//                   <PricingCard
//                     key={index}
//                     {...plan}
//                     linkTo={plan.linkTo[environment]}
//                     isHighlighted={index === 2}
//                     originalPrice={calculatePricing(plan.basePrice).originalPrice}
//                     price={calculatePricing(plan.basePrice).discountedPrice}
//                     discountInfo={discountInfo}
//                   />
//                 ))}
//               </div>
//             </div>

//             {/* Satisfaction Guarantee */}
//             <div className="bg-white rounded-2xl shadow-xl border border-gray-100 p-8 mb-12">
//               <div className="flex flex-col md:flex-row items-center gap-8">
//                 <img src={Satisfaction} alt="100% Satisfaction Guarantee" className="w-32 h-32" />
//                 <div>
//                   <h2 className="text-2xl font-bold text-gray-800 mb-4">100% Satisfaction Guarantee</h2>
//                   <p className="text-gray-600">
//                     If you're not satisfied with our report, we'll refund you 100% without any questions asked! But we're sure you'll{" "}
//                     <span className="text-green-600 font-medium">love our advice</span> for success in entrepreneurship!
//                   </p>
//                   <p className="text-sm text-gray-500 mt-3">
//                     *Refund valid within 24 hours of purchase, provided less than 5 sections of the report are generated.
//                   </p>
//                 </div>
//               </div>
//             </div>

//             {/* Additional Features */}
//             <div className="grid grid-cols-1 md:grid-cols-2 gap-6 mb-12">
//               <div className="bg-blue-50 rounded-xl p-6 border border-blue-100">
//                 <h3 className="text-lg font-bold text-gray-900 mb-3">Join Our Affiliate Program</h3>
//                 <p className="text-gray-700 mb-4">
//                   Earn while helping others better their business! Join our affiliate program and get rewarded for every referral.
//                 </p>
//                 <a
//                   href="https://feedbackbyai1.lemonsqueezy.com/affiliates/"
//                   target="_blank"
//                   rel="noopener noreferrer"
//                   className="inline-block px-4 py-2 bg-blue-600 text-white rounded-lg font-medium hover:bg-blue-700 transition-colors"
//                 >
//                   Learn More & Join
//                 </a>
//               </div>

//               <div className="bg-indigo-50 rounded-xl p-6 border border-indigo-100">
//                 <h3 className="text-lg font-bold text-gray-900 mb-3">Share Your Experience</h3>
//                 <p className="text-gray-700 mb-4">
//                   Loved our service? Share your experience and get a discount coupon code as a thank you!
//                 </p>
//                 <a
//                   href="https://testimonial.to/feedbackbyai"
//                   target="_blank"
//                   rel="noopener noreferrer"
//                   className="inline-block px-4 py-2 bg-indigo-600 text-white rounded-lg font-medium hover:bg-indigo-700 transition-colors"
//                 >
//                   Submit Testimonial
//                 </a>
//               </div>
//             </div>
//           </>
//         )}

//         {/* Testimonials Section */}
//         <div className="mb-12">
//           <h2 className="text-2xl font-bold text-gray-800 text-center mb-8">What Our Users Say</h2>
//           <IframeResizer src="https://embed-v2.testimonial.to/w/feedbackbyai" style={{ width: "1px", minWidth: "100%" }} />
//         </div>
//       </main>

//       <ReportFooter />
//     </div>
//   );
// }

// // Pricing Card Component
// function PricingCard({ plan, price, originalPrice, discountInfo, benefits, linkTo, isHighlighted = false }) {
//   const highlightedClasses = isHighlighted ? "border-2 border-green-500 shadow-lg" : "border border-gray-200";

//   const hasDiscount = discountInfo && discountInfo.discount;
//   const pricingMessage = hasDiscount ? `Special pricing for ${discountInfo.countryName} - ${discountInfo.discount}% off` : "";

//   const finalLinkTo = hasDiscount ? `${linkTo}&checkout%5Bdiscount_code%5D=${discountInfo.code}` : linkTo;

//   return (
//     <div className={`bg-white rounded-xl overflow-hidden transition-all duration-300 ${highlightedClasses}`}>
//       {isHighlighted && <div className="bg-green-100 text-green-800 py-2 text-center font-medium">Most Popular</div>}

//       <div className="p-6">
//         <h3 className="text-xl font-bold text-gray-900 mb-2">{plan}</h3>

//         <div className="mb-4">
//           <span className={`text-2xl font-bold ${isHighlighted ? "text-green-600" : "text-gray-900"}`}>${price}</span>
//           {originalPrice && hasDiscount && <span className="ml-2 text-lg text-gray-500 line-through">${originalPrice}</span>}
//         </div>

//         {pricingMessage && (
//           <div className="mb-4 py-1 px-3 bg-green-50 text-green-800 text-sm rounded-lg inline-block">{pricingMessage}</div>
//         )}

//         <ul className="mt-6 space-y-3 text-sm">
//           {benefits.map((benefit, idx) => (
//             <li key={idx} className="flex items-start">
//               <svg className="w-5 h-5 text-green-500 mr-2 flex-shrink-0" fill="none" viewBox="0 0 24 24" stroke="currentColor">
//                 <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M5 13l4 4L19 7" />
//               </svg>
//               <span className="text-gray-700">{benefit}</span>
//             </li>
//           ))}
//         </ul>
//       </div>

//       <div className="px-6 pt-2 pb-6">
//         <a
//           href={finalLinkTo}
//           className={`block w-full py-2 px-4 text-center rounded-lg font-medium text-white transition-colors ${
//             isHighlighted ? "bg-green-600 hover:bg-green-700" : "bg-gray-800 hover:bg-gray-900"
//           }`}
//         >
//           {isHighlighted ? "Best Value" : "Select Plan"}
//         </a>
//       </div>
//     </div>
//   );
// }

// export default Credits;

// import React, { useState, useEffect } from "react";
// import { Link } from "react-router-dom";
// import api from "./axiosConfig";
// import IframeResizer from "iframe-resizer-react";
// import { FaSync, FaFileAlt, FaBullhorn } from "react-icons/fa";
// import TopAiToolsLogo from "../images/topaitools.png";
// import SerpAiLogo from "../images/serpai.png";
// import ThereIsAnAIForThatLogo from "../images/thereisanaiforthat.png";
// import ToolifyLogo from "../images/toolify.png";

// const formatDate = (dateString) => {
//   const options = { year: "numeric", month: "long", day: "numeric" };
//   return new Date(dateString).toLocaleDateString(undefined, options);
// };

// function Credits() {
//   const [plans, setPlans] = useState([]);
//   const [usage, setUsage] = useState({});
//   const [planName, setPlanName] = useState("");
//   const [portalUrl, setPortalUrl] = useState("");
//   const [email, setEmail] = useState("");
//   const [userID, setUserID] = useState("");
//   const [isLoading, setIsLoading] = useState(true);
//   const [discountInfo, setDiscountInfo] = useState({});
//   const [planStatus, setPlanStatus] = useState("");
//   const [isFreePlan, setIsFreePlan] = useState(false);
//   const [isCanceled, setIsCanceled] = useState(false);
//   const [planDate, setPlanDate] = useState("");
//   const formattedDate = formatDate(planDate);
//   const environment = process.env.REACT_APP_ENV;

//   const staticPrices = {
//     starter: 59,
//     select: 95,
//     pro: 248,
//   };

//   const calculatePricing = (basePrice) => {
//     if (!discountInfo || !discountInfo.discount) return { originalPrice: basePrice, discountedPrice: basePrice };

//     const discountedPrice = basePrice * (1 - discountInfo.discount / 100);
//     return {
//       originalPrice: basePrice,
//       discountedPrice: discountedPrice.toFixed(2),
//     };
//   };

//   // Check if the user is authenticated
//   useEffect(() => {
//     const token = localStorage.getItem("access");
//     if (!token) {
//       alert("Please log in first!");
//       setIsLoading(false);
//     } else {
//       setIsLoading(true);
//     }
//   }, []);

//   // Fetch user email and ID
//   useEffect(() => {
//     const fetchUserEmailAndID = async () => {
//       try {
//         const useremailresponse = await api.get("get-user-email/");
//         const { email, id } = useremailresponse.data;
//         setEmail(email);
//         setUserID(id);
//       } catch (error) {
//         console.error("Error fetching user email and ID:", error);
//       }
//     };

//     fetchUserEmailAndID();
//   }, []);

//   useEffect(() => {
//     const fetchUserCredits = async () => {
//       try {
//         const response = await api.get("get-user-credits");
//         if (response.data) {
//           setPlanName(response.data.plan_name);
//           setPlanStatus(response.data.subscription_active ? "active" : "inactive");
//           setPlanDate(response.data.end_date);
//           setUsage({
//             question_credits: response.data.question_credits || 0,
//             report_credits: response.data.report_credits || 0,
//             video_credits: response.data.video_credits || 0,
//           }); // ✅ Fix: Ensure credits are properly stored

//           if (response.data.plan_name === "Free") {
//             setIsFreePlan(true);
//           }
//           if (!response.data.subscription_active) {
//             setIsCanceled(true);
//           }
//         }
//       } catch (error) {
//         console.error("Error fetching user credits:", error);
//       }
//     };
//     fetchUserCredits();
//   }, []);

//   // Fetch customer portal URL
//   useEffect(() => {
//     fetchCustomerPortalURL();
//   }, []);

//   const fetchCustomerPortalURL = async () => {
//     try {
//       const portalResponse = await api.get("customer-portal-url/");
//       if (portalResponse.data && portalResponse.data.portal_url) {
//         setPortalUrl(portalResponse.data.portal_url);
//       } else {
//         setPortalUrl(""); // ✅ Ensure we don't trigger alerts
//       }
//     } catch (error) {
//       console.error("Error fetching customer portal URL:", error);
//       setPortalUrl(""); // ✅ Don't alert the user
//     }
//   };

//   // Fetch all plans with the variant_id for the current environment
//   useEffect(() => {
//     const fetchPlans = async () => {
//       try {
//         const plansResponse = await api.get(`get-all-plans/?environment=${environment}`);
//         setPlans(plansResponse.data);
//       } catch (error) {
//         console.error("Error fetching plans:", error);
//       } finally {
//         setIsLoading(false);
//       }
//     };

//     fetchPlans();
//   }, [environment]);

//   return (
//     <div className="min-h-screen bg-white">
//       {/* Header - Matching the report dashboard */}
//       <header className="h-16 border-b border-gray-100 flex items-center px-8">
//         <div className="max-w-6xl w-full mx-auto flex justify-between">
//           <span className="font-medium text-xl text-green-600">FeedbackbyAI</span>
//           <Link to="/dashboard/" className="text-gray-500 hover:text-gray-700">
//             Back to Dashboard
//           </Link>
//         </div>
//       </header>

//       <main className="max-w-6xl mx-auto px-6 py-12">
//         {/* Page Header */}
//         <div className="flex flex-col items-center justify-center mb-10">
//           <div className="w-20 h-20 bg-green-50 rounded-full flex items-center justify-center mb-6">
//             <svg className="w-10 h-10 text-green-600" fill="none" viewBox="0 0 24 24" stroke="currentColor">
//               <path
//                 strokeLinecap="round"
//                 strokeLinejoin="round"
//                 strokeWidth="2"
//                 d="M15 9a2 2 0 10-4 0v5a2 2 0 01-2 2h6m-6-4h4m8 0a9 9 0 11-18 0 9 9 0 0118 0z"
//               />
//             </svg>
//           </div>
//           <h1 className="text-3xl font-bold text-gray-900 text-center mb-2">Your Subscription</h1>
//           <p className="text-center text-gray-600 max-w-2xl mx-auto">Manage your plan and view your available credits</p>
//         </div>

//         {/* Loading Indicator */}
//         {isLoading ? (
//           <div className="flex justify-center items-center py-12">
//             <div className="animate-spin rounded-full h-12 w-12 border-t-2 border-b-2 border-green-500"></div>
//           </div>
//         ) : (
//           <>
//             {/* Current Subscription */}
//             <div className="bg-white rounded-2xl shadow-xl border border-gray-100 p-8 mb-12">
//               <div className="flex flex-col md:flex-row justify-between items-start md:items-center mb-8">
//                 <div>
//                   <h2 className="text-2xl font-bold text-gray-800">Your Plan: {planName || "No active plan"}</h2>
//                   {planStatus === "active" && planDate && <p className="text-gray-600 mt-1">Renews on {formattedDate}</p>}
//                 </div>
//                 {portalUrl && (
//                   <a href={portalUrl} target="_blank" rel="noopener noreferrer" className="bg-green-600 text-white px-4 py-2 rounded-lg">
//                     Manage Subscription
//                   </a>
//                 )}
//               </div>

//               <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
//                 <div className="bg-gray-50 rounded-xl p-6 border border-gray-200">
//                   <div className="flex items-center mb-3">
//                     <div className="w-10 h-10 bg-green-100 rounded-full flex items-center justify-center mr-3">
//                       <FaSync className="w-5 h-5 text-green-600" />
//                     </div>
//                     <h3 className="font-medium text-gray-900">Question Credits</h3>
//                   </div>
//                   <p className="text-3xl font-bold text-green-600">{usage.question_credits || 0}</p>
//                   <p className="text-sm text-gray-500 mt-2">For AI character feedback</p>
//                 </div>

//                 <div className="bg-gray-50 rounded-xl p-6 border border-gray-200">
//                   <div className="flex items-center mb-3">
//                     <div className="w-10 h-10 bg-blue-100 rounded-full flex items-center justify-center mr-3">
//                       <FaFileAlt className="w-5 h-5 text-blue-600" />
//                     </div>
//                     <h3 className="font-medium text-gray-900">Report Credits</h3>
//                   </div>
//                   <p className="text-3xl font-bold text-blue-600">{usage.report_credits || 0}</p>
//                   <p className="text-sm text-gray-500 mt-2">For business plan reports</p>
//                 </div>

//                 <div className="bg-gray-50 rounded-xl p-6 border border-gray-200">
//                   <div className="flex items-center mb-3">
//                     <div className="w-10 h-10 bg-red-100 rounded-full flex items-center justify-center mr-3">
//                       <FaBullhorn className="w-5 h-5 text-red-600" />
//                     </div>
//                     <h3 className="font-medium text-gray-900">Video Credits</h3>
//                   </div>
//                   <p className="text-3xl font-bold text-red-600">{usage.video_credits || 0}</p>
//                   <p className="text-sm text-gray-500 mt-2">For promotion tools</p>
//                 </div>
//               </div>
//             </div>

//             {/* Available Plans */}
//             <div className="bg-white rounded-2xl shadow-xl border border-gray-100 p-8 mb-12">
//               <div className="flex flex-col md:flex-row md:items-center md:justify-between gap-6 mb-8">
//                 <div>
//                   <h2 className="text-2xl font-bold text-gray-800">Available Plans</h2>
//                   <p className="text-gray-600 mt-2">Choose the plan that fits your business needs</p>
//                 </div>

//                 <div className="flex flex-wrap items-center gap-4">
//                   <img src={TopAiToolsLogo} alt="TopAiTools Logo" className="h-8" />
//                   <img src={SerpAiLogo} alt="Serp.ai Logo" className="h-8" />
//                   <img src={ThereIsAnAIForThatLogo} alt="ThereIsAnAIForThat Logo" className="h-8" />
//                 </div>
//               </div>

//               <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
//                 {[
//                   {
//                     price: staticPrices.starter,
//                     plan: "Starter",
//                     basePrice: 59,
//                     benefits: ["1 daily idea refreshes", "25 Spark an Idea requests Per Month", "Access to Coworking Cafes"],
//                     linkTo: {
//                       local: `https://feedbackbyai1.lemonsqueezy.com/buy/43bb3e9c-7f9e-4a15-8dab-52cbcbe6be1f?checkout[email]=${email}&checkout[custom][userId]=${userID}&checkout[custom][plan]=Starter`,
//                       production: `https://feedbackbyai1.lemonsqueezy.com/buy/d3241df3-38bc-4df3-a70e-2208c9dc2e75?checkout[email]=${email}&checkout[custom][userId]=${userID}&checkout[custom][plan]=Starter`,
//                     },
//                   },
//                   {
//                     price: staticPrices.select,
//                     plan: "Select",
//                     basePrice: 95,
//                     benefits: ["2 Daily Idea Refreshes", "100 Spark an Idea requests Per Month", "2 Full Business Plans"],
//                     linkTo: {
//                       local: `https://feedbackbyai1.lemonsqueezy.com/buy/b096120f-36f0-47d9-b8db-a13f76ec0b93?checkout[email]=${email}&checkout[custom][userId]=${userID}&checkout[custom][plan]=Select`,
//                       production: `https://feedbackbyai1.lemonsqueezy.com/buy/b1cc5601-03a3-4ca8-ba5b-c7f51ac4c426?checkout[email]=${email}&checkout[custom][userId]=${userID}&checkout[custom][plan]=Select`,
//                     },
//                   },
//                   {
//                     price: staticPrices.pro,
//                     plan: "Pro",
//                     basePrice: 248,
//                     benefits: ["25 Daily Idea Refreshes", "500 Spark an Idea requests Per Month", "10 Business Plans"],
//                     linkTo: {
//                       local: `https://feedbackbyai1.lemonsqueezy.com/buy/1039a42c-4175-4729-9cab-eb089d2a222c?checkout[email]=${email}&checkout[custom][userId]=${userID}&checkout[custom][plan]=Pro`,
//                       production: `https://feedbackbyai1.lemonsqueezy.com/buy/2c495bb1-1f63-4ba7-811c-b7b8e031db39?checkout[email]=${email}&checkout[custom][userId]=${userID}&checkout[custom][plan]=Pro`,
//                     },
//                   },
//                 ].map((planData, index) => (
//                   <PricingCard
//                     key={index}
//                     {...planData}
//                     linkTo={planData.linkTo}
//                     environment={environment}
//                     isHighlighted={index === 2}
//                     originalPrice={calculatePricing(planData.basePrice).originalPrice}
//                     discountInfo={discountInfo}
//                     currencySymbol="$"
//                   />
//                 ))}
//               </div>
//             </div>

//             {/* Testimonials Section */}
//             <div className="mb-12">
//               <h2 className="text-2xl font-bold text-gray-800 text-center mb-8">What Our Users Say</h2>
//               <IframeResizer src="https://embed-v2.testimonial.to/w/feedbackbyai" style={{ width: "1px", minWidth: "100%" }} />
//             </div>
//           </>
//         )}
//       </main>
//     </div>
//   );
// }

// // Pricing Card Component
// function PricingCard({ plan, price, originalPrice, discountInfo, benefits, linkTo, environment, isHighlighted = false, currencySymbol }) {
//   const highlightedClasses = isHighlighted ? "border-2 border-green-500 shadow-lg" : "border border-gray-200";

//   const hasDiscount = discountInfo && discountInfo.discount;
//   const pricingMessage = hasDiscount ? `${discountInfo.countryName} - ${discountInfo.discount}% off` : "";

//   const finalLinkTo = hasDiscount ? `${linkTo[environment]}&discount_code=${discountInfo.code}` : linkTo[environment];

//   return (
//     <div className={`bg-white rounded-xl overflow-hidden transition-all duration-300 ${highlightedClasses}`}>
//       {isHighlighted && <div className="bg-green-100 text-green-800 py-2 text-center font-medium">Most Popular</div>}

//       <div className="p-6">
//         <h3 className="text-xl font-bold text-gray-900 mb-2">{plan}</h3>

//         <div className="mb-4">
//           <span className={`text-2xl font-bold ${isHighlighted ? "text-green-600" : "text-gray-900"}`}>
//             {currencySymbol}
//             {price}
//           </span>
//           {originalPrice && hasDiscount && <span className="ml-2 text-lg text-gray-500 line-through">${originalPrice}</span>}
//         </div>

//         {pricingMessage && (
//           <div className="mb-4 py-1 px-3 bg-green-50 text-green-800 text-sm rounded-lg inline-block">{pricingMessage}</div>
//         )}

//         <hr className="my-4 border-gray-200" />

//         <ul className="mt-4 space-y-3 text-sm">
//           {benefits.map((benefit, idx) => (
//             <li key={idx} className="flex items-center">
//               <svg className="w-4 h-4 text-green-500 mr-2 flex-shrink-0" fill="none" viewBox="0 0 24 24" stroke="currentColor">
//                 <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M5 13l4 4L19 7" />
//               </svg>
//               <span className="text-gray-700">{benefit}</span>
//             </li>
//           ))}
//         </ul>
//       </div>

//       <div className="px-6 pt-2 pb-6">
//         <a
//           href={finalLinkTo}
//           className={`block w-full py-2 px-4 text-center rounded-lg font-medium text-white transition-colors ${
//             isHighlighted ? "bg-green-600 hover:bg-green-700" : "bg-gray-800 hover:bg-gray-900"
//           }`}
//         >
//           {isHighlighted ? "Choose This Plan" : "Select Plan"}
//         </a>
//       </div>
//     </div>
//   );
// }

// export default Credits;

import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { motion } from "framer-motion";
import api from "./axiosConfig";
import IframeResizer from "iframe-resizer-react";
import {
  HiBeaker,
  HiChartBar,
  HiChatAlt2,
  HiSparkles,
  HiLightningBolt,
  HiPlay,
  HiOutlineCheck,
  HiChevronDown,
  HiStar,
  HiDocumentText,
  HiUserGroup,
  HiChevronRight,
} from "react-icons/hi";
import { Sparkles, FileText, ArrowRight, MessageSquare, Video, Target, ChevronLeft, Users, Star, ChevronRight } from "lucide-react";

const fadeInUp = {
  initial: { opacity: 0, y: 20 },
  animate: { opacity: 1, y: 0 },
  transition: { duration: 0.4 },
};

const formatDate = (dateString) => {
  const options = { year: "numeric", month: "long", day: "numeric" };
  return new Date(dateString).toLocaleDateString(undefined, options);
};

const CreditCard = ({ icon: Icon, title, count, description, color }) => (
  <div
    className="bg-white rounded-xl p-6 border border-gray-200 hover:border-gray-300 transition-all duration-300
                  hover:shadow-lg"
  >
    <div className="flex items-center mb-3">
      <div className={`w-10 h-10 ${color} rounded-full flex items-center justify-center mr-3`}>
        <Icon className="w-5 h-5 text-gray-800" />
      </div>
      <h3 className="font-medium text-gray-900">{title}</h3>
    </div>
    <p className={`text-3xl font-bold mb-1 ${color.replace("bg-", "text-")}`}>{count}</p>
    <p className="text-sm text-gray-500">{description}</p>
  </div>
);

function PricingCard({ plan, price, benefits, linkTo, environment, isPopular = false, discountInfo, originalPrice, currencySymbol = "$" }) {
  const hasDiscount = discountInfo && discountInfo.discount;
  const pricingMessage = hasDiscount ? `${discountInfo.countryName} - ${discountInfo.discount}% off` : "";
  const finalLinkTo = hasDiscount ? `${linkTo[environment]}&discount_code=${discountInfo.code}` : linkTo[environment];

  return (
    <div
      className={`relative bg-white rounded-xl ${
        isPopular ? "border-2 border-green-500 shadow-xl" : "border border-gray-200"
      } overflow-hidden`}
    >
      {isPopular && (
        <div className="absolute top-0 right-0 bg-green-500 text-white px-3 py-1 rounded-bl-lg text-sm font-medium">Most Popular</div>
      )}
      <div className="p-6">
        <h3 className="text-xl font-bold text-gray-900 mb-2">{plan}</h3>
        <div className="flex items-baseline mb-4">
          <span className="text-3xl font-bold text-gray-900">
            {currencySymbol}
            {price}
          </span>
          <span className="text-gray-500 ml-1">/month</span>
          {originalPrice && hasDiscount && <span className="ml-2 text-lg text-gray-500 line-through">${originalPrice}</span>}
        </div>
        {pricingMessage && (
          <div className="mb-4 py-1 px-3 bg-green-50 text-green-800 text-sm rounded-lg inline-block">{pricingMessage}</div>
        )}
        <hr className="my-6 border-gray-200" />
        <ul className="space-y-4">
          {benefits.map((benefit, idx) => (
            <li key={idx} className="flex items-start">
              <HiOutlineCheck className="w-5 h-5 text-green-500 mr-2 flex-shrink-0" />
              <span className="text-gray-700">{benefit}</span>
            </li>
          ))}
        </ul>
      </div>
      <div className="p-6 bg-gray-50 mt-6">
        <a
          href={finalLinkTo}
          className={`block w-full py-3 px-4 text-center rounded-lg font-medium transition-colors ${
            isPopular ? "bg-green-600 hover:bg-green-700 text-white" : "bg-white border border-gray-300 hover:border-gray-400 text-gray-900"
          }`}
        >
          Buy Plan
        </a>
      </div>
    </div>
  );
}

const Credits = () => {
  const [plans, setPlans] = useState([]);
  const [usage, setUsage] = useState({});
  const [planName, setPlanName] = useState("");
  const [portalUrl, setPortalUrl] = useState("");
  const [email, setEmail] = useState("");
  const [userID, setUserID] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const [discountInfo, setDiscountInfo] = useState({});
  const [planStatus, setPlanStatus] = useState("");
  const [isFreePlan, setIsFreePlan] = useState(false);
  const [isCanceled, setIsCanceled] = useState(false);
  const [planDate, setPlanDate] = useState("");
  const formattedDate = formatDate(planDate);
  const environment = process.env.REACT_APP_ENV;

  // Static Pricing Config
  const planConfigs = {
    starter: {
      price: 19,
      basePrice: 19,
      benefits: [
        // "AI Idea Discovery: 2 search/day",
        // "Daily Ideation",
        "10 Ideation Credits",
        "10 AI User Interviews",
        "5 AI UGC videos/month",
        "10 leads/month",
        "Business Report : Executive Summary Access",
        "❌ No Full Business Report Generation",
        "❌ No Community Access (Coming Soon)",
      ],
      linkTo: {
        // local: `https://feedbackbyai1.lemonsqueezy.com/buy/dc41548d-d2a4-4f7e-8efd-f876d8f9a113?checkout[email]=${email}&checkout[custom][userId]=${userID}&checkout[custom][plan]=Starter`,
        // production: `https://feedbackbyai1.lemonsqueezy.com/buy/6789d080-c650-4d7d-83a1-5a5083c09d04?checkout[email]=${email}&checkout[custom][userId]=${userID}&checkout[custom][plan]=Starter`,
        // the below is 19 usd plan
        local: `https://feedbackbyai1.lemonsqueezy.com/buy/4d610dd9-7dd0-494b-8b84-ac0f1480125a?checkout[email]=${email}&checkout[custom][userId]=${userID}&checkout[custom][plan]=Starter`,
        production: `https://feedbackbyai1.lemonsqueezy.com/buy/1514f65a-1925-48ea-8ecd-28e4475986a2?checkout[email]=${email}&checkout[custom][userId]=${userID}&checkout[custom][plan]=Starter`,
      },
    },
    select: {
      price: 59,
      basePrice: 59,
      benefits: [
        "50 Ideation Credits",
        "50 AI User Interviews",
        "25 AI UGC videos/month",
        "50 leads/month",
        "1 Full 100+ Page Business Report",
        "Community Access",
      ],
      linkTo: {
        local: `https://feedbackbyai1.lemonsqueezy.com/buy/43bb3e9c-7f9e-4a15-8dab-52cbcbe6be1f?checkout[email]=${email}&checkout[custom][userId]=${userID}&checkout[custom][plan]=Select`,
        production: `https://feedbackbyai1.lemonsqueezy.com/buy/b3ccc8cf-c7c7-4bda-aa59-67667423a503?checkout[email]=${email}&checkout[custom][userId]=${userID}&checkout[custom][plan]=Select`,
      },
    },
    // select: {
    //   price: 95,
    //   basePrice: 95,
    //   benefits: [
    //     // "AI Idea Discovery: 5 search/day",
    //     "100 Ideation Credits",

    //     "50 AI User Interviews",
    //     "50 AI UGC videos/month",
    //     "100 leads/month",
    //     "2 Full 100+ Page Business Reports",
    //     "Community Access (Coming Soon)",
    //   ],
    //   linkTo: {
    //     local: `https://feedbackbyai1.lemonsqueezy.com/buy/b096120f-36f0-47d9-b8db-a13f76ec0b93?checkout[email]=${email}&checkout[custom][userId]=${userID}&checkout[custom][plan]=Select`,
    //     production: `https://feedbackbyai1.lemonsqueezy.com/buy/b1cc5601-03a3-4ca8-ba5b-c7f51ac4c426?checkout[email]=${email}&checkout[custom][userId]=${userID}&checkout[custom][plan]=Select`,
    //   },
    // },

    // pro: {
    //   price: 95,
    //   basePrice: 95,
    //   benefits: [
    //     "Daily Ideation",
    //     "100 AI User Interviews",
    //     "100 AI UGC videos/month",
    //     "100 leads/month",
    //     "2 Full 100+ Page Business Report",
    //     "Community Access",
    //   ],
    //   linkTo: {
    //     local: `https://feedbackbyai1.lemonsqueezy.com/buy/b096120f-36f0-47d9-b8db-a13f76ec0b93?checkout[email]=${email}&checkout[custom][userId]=${userID}&checkout[custom][plan]=Pro`,
    //     production: `https://feedbackbyai1.lemonsqueezy.com/buy/b1cc5601-03a3-4ca8-ba5b-c7f51ac4c426?checkout[email]=${email}&checkout[custom][userId]=${userID}&checkout[custom][plan]=Pro`,
    //   },
    // },

    pro: {
      price: 248,
      basePrice: 248,
      benefits: [
        // "AI Idea Discovery: 10 search/day",
        "250 Ideation Credits",
        "150 AI User Interviews",
        "100 AI UGC videos/month",
        "300 leads/month",
        "5 Full 100+ Page Business Reports",
        "Community Access (Coming Soon)",
      ],
      linkTo: {
        local: `https://feedbackbyai1.lemonsqueezy.com/buy/1039a42c-4175-4729-9cab-eb089d2a222c?checkout[email]=${email}&checkout[custom][userId]=${userID}&checkout[custom][plan]=Pro`,
        production: `https://feedbackbyai1.lemonsqueezy.com/buy/2c495bb1-1f63-4ba7-811c-b7b8e031db39?checkout[email]=${email}&checkout[custom][userId]=${userID}&checkout[custom][plan]=Pro`,
      },
    },
  };

  // API call effects
  useEffect(() => {
    const token = localStorage.getItem("access");
    if (!token) {
      window.location.href = "/signin/";
      return;
    }
  }, []);

  useEffect(() => {
    const fetchUserEmailAndID = async () => {
      try {
        const response = await api.get("get-user-email/");
        const { email, id } = response.data;
        setEmail(email);
        setUserID(id);
      } catch (error) {
        console.error("Error fetching user email and ID:", error);
      }
    };
    fetchUserEmailAndID();
  }, []);

  useEffect(() => {
    const fetchUserCredits = async () => {
      try {
        const response = await api.get("get-user-credits/");
        if (response.data) {
          setPlanName(response.data.plan_name);
          setPlanStatus(response.data.subscription_active ? "active" : "inactive");
          setPlanDate(response.data.end_date);
          setUsage({
            question_credits: response.data.question_credits || 0,
            report_credits: response.data.report_credits || 0,
            video_credits: response.data.video_credits || 0,
            ideation_credits: response.data.ideation_credits || 0,
            lead_credits: response.data.lead_credits || 0,
          });
          setIsFreePlan(response.data.plan_name === "Free");
          setIsCanceled(!response.data.subscription_active);
        }
      } catch (error) {
        console.error("Error fetching user credits:", error);
      } finally {
        setIsLoading(false);
      }
    };
    fetchUserCredits();
  }, []);

  useEffect(() => {
    const fetchCustomerPortalURL = async () => {
      try {
        const response = await api.get("customer-portal-url/");
        if (response.data?.portal_url) {
          setPortalUrl(response.data.portal_url);
        }
      } catch (error) {
        console.error("Error fetching customer portal URL:", error);
      }
    };
    fetchCustomerPortalURL();
  }, []);

  if (isLoading) {
    return (
      <div className="min-h-screen bg-white flex items-center justify-center">
        <div className="w-12 h-12 border-t-3 border-green-600 border-solid rounded-full animate-spin"></div>
      </div>
    );
  }

  return (
    <div className="min-h-screen bg-gray-50">
      {/* Simple Header - Matching the dashboard page */}
      <header className="h-16 border-b border-gray-100 bg-white shadow-sm flex items-center px-8 sticky top-0 z-10">
        <div className="max-w-6xl w-full mx-auto flex justify-between items-center">
          <span className="font-semibold text-xl text-green-600 tracking-tight">FeedbackbyAI</span>
          <Link to="/dashboard/" className="text-gray-600 hover:text-green-600 transition-colors flex items-center">
            <ArrowRight className="h-4 w-4 mr-1 transform rotate-180" />
            Back to Dashboard
          </Link>
        </div>
      </header>

      <main className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-12">
        <motion.div {...fadeInUp}>
          <div className="text-center mb-12">
            <h1 className="text-3xl font-bold text-gray-900 mb-4">Credits & Subscription</h1>
            <p className="text-gray-600 max-w-2xl mx-auto">Monitor your credit usage and manage your subscription plan</p>
          </div>

          {/* Current Plan Status */}
          <div className="bg-white rounded-xl shadow-sm border border-gray-200 p-6 mb-12">
            <div className="flex items-center justify-between mb-6">
              <div>
                <h2 className="text-2xl font-bold text-gray-900">{planName} Plan</h2>
                <p className="text-gray-600 mt-1">{planStatus === "active" && planDate && `Renews on ${formattedDate}`}</p>
              </div>
              {portalUrl && (
                <a
                  href={portalUrl}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="bg-green-600 hover:bg-green-700 text-white px-6 py-2 rounded-lg transition-colors"
                >
                  Manage Subscription
                </a>
              )}
            </div>

            {/* Credit Cards Grid */}
            <div className="grid grid-cols-1 md:grid-cols-3 lg:grid-cols-5 gap-6">
              <CreditCard
                icon={Sparkles}
                title="Ideation Credits"
                count={usage.ideation_credits}
                description="For discovering ideas"
                color="bg-purple-100"
              />
              <CreditCard
                icon={MessageSquare}
                title="Interview Credits"
                count={usage.question_credits}
                description="For AI user interviews"
                color="bg-blue-100"
              />
              <CreditCard
                icon={Video}
                title="Video Credits"
                count={usage.video_credits}
                description="For UGC content"
                color="bg-orange-100"
              />
              <CreditCard
                icon={Target}
                title="Lead Credits"
                count={usage.lead_credits}
                description="For lead generation"
                color="bg-red-100"
              />
              <CreditCard
                icon={FileText}
                title="Report Credits"
                count={usage.report_credits}
                description="For business reports"
                color="bg-green-100"
              />
            </div>
          </div>

          {/* Available Plans */}
          <div className="mb-12">
            <h2 className="text-2xl font-bold text-gray-900 mb-8 text-center">Available Plans</h2>
            <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
              <PricingCard {...planConfigs.starter} plan="Starter" discountInfo={discountInfo} environment={environment} />
              <PricingCard {...planConfigs.select} plan="Select" isPopular={true} discountInfo={discountInfo} environment={environment} />
              <PricingCard {...planConfigs.pro} plan="Pro" discountInfo={discountInfo} environment={environment} />
            </div>
          </div>

          {/* Features Comparison */}
          <div className="bg-white rounded-xl shadow-sm border border-gray-200 p-8 mb-12">
            <h2 className="text-2xl font-bold text-gray-900 mb-8 text-center">Plan Features</h2>
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
              <div className="p-6 rounded-xl bg-gray-50">
                <div className="flex items-center mb-4">
                  <div className="w-8 h-8 rounded-full bg-purple-100 flex items-center justify-center mr-3">
                    <Sparkles className="w-4 h-4 text-purple-600" />
                  </div>
                  <h3 className="font-medium text-gray-900">Daily Ideation</h3>
                </div>
                <p className="text-gray-600 text-sm">Get fresh business ideas and insights every day based on market trends</p>
              </div>

              <div className="p-6 rounded-xl bg-gray-50">
                <div className="flex items-center mb-4">
                  <div className="w-8 h-8 rounded-full bg-blue-100 flex items-center justify-center mr-3">
                    <MessageSquare className="w-4 h-4 text-blue-600" />
                  </div>
                  <h3 className="font-medium text-gray-900">AI Interviews</h3>
                </div>
                <p className="text-gray-600 text-sm">Run simulated user interviews with AI personas for instant feedback</p>
              </div>

              <div className="p-6 rounded-xl bg-gray-50">
                <div className="flex items-center mb-4">
                  <div className="w-8 h-8 rounded-full bg-green-100 flex items-center justify-center mr-3">
                    <FileText className="w-4 h-4 text-green-600" />
                  </div>
                  <h3 className="font-medium text-gray-900">Business Reports</h3>
                </div>
                <p className="text-gray-600 text-sm">Generate comprehensive business plans and market analysis reports</p>
              </div>

              <div className="p-6 rounded-xl bg-gray-50">
                <div className="flex items-center mb-4">
                  <div className="w-8 h-8 rounded-full bg-orange-100 flex items-center justify-center mr-3">
                    <Video className="w-4 h-4 text-orange-600" />
                  </div>
                  <h3 className="font-medium text-gray-900">UGC Videos</h3>
                </div>
                <p className="text-gray-600 text-sm">Create engaging video content to promote your business on social media</p>
              </div>

              <div className="p-6 rounded-xl bg-gray-50">
                <div className="flex items-center mb-4">
                  <div className="w-8 h-8 rounded-full bg-red-100 flex items-center justify-center mr-3">
                    <Target className="w-4 h-4 text-red-600" />
                  </div>
                  <h3 className="font-medium text-gray-900">Lead Generation</h3>
                </div>
                <p className="text-gray-600 text-sm">Find and connect with qualified leads interested in your products</p>
              </div>

              <div className="p-6 rounded-xl bg-gray-50">
                <div className="flex items-center mb-4">
                  <div className="w-8 h-8 rounded-full bg-emerald-100 flex items-center justify-center mr-3">
                    <Users className="w-4 h-4 text-emerald-600" />
                  </div>
                  <h3 className="font-medium text-gray-900">Community Access</h3>
                </div>
                <p className="text-gray-600 text-sm">Join our exclusive community of entrepreneurs (Coming Soon)</p>
              </div>
            </div>
          </div>

          {/* Testimonials */}
          <div className="mb-12">
            <div className="text-center mb-8">
              <h2 className="text-2xl font-bold text-gray-900">What Our Users Say</h2>
              <p className="text-gray-600 mt-2">Join thousands of satisfied entrepreneurs</p>
            </div>
            <div className="bg-white rounded-xl shadow-sm border border-gray-200 p-6">
              <IframeResizer
                src="https://embed-v2.testimonial.to/w/feedbackbyai"
                style={{ width: "1px", minWidth: "100%" }}
                className="rounded-xl"
              />
            </div>
          </div>
        </motion.div>
      </main>
    </div>
  );
};

export default Credits;
