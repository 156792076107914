// import React, { useState } from "react";
// // import { Rocket, Play, ArrowRight, CheckCircle2, Users, Sparkles } from "lucide-react";
// import DashboardHeader from "./DashboardHeader";

// import { Rocket, Play, ArrowRight, ChevronRight, Users, Globe, Star } from "lucide-react";

// const LaunchVoteDashboard = ({ userLaunches = [] }) => {
//   return (
//     <div className="font-inter antialiased bg-gradient-to-b from-gray-50 to-green-100 text-gray-900 tracking-tight min-h-screen">
//       {/* <div className="min-h-screen"> */}
//       <DashboardHeader />
//       {/* Hero Section */}
//       <div className="container mx-auto px-4 sm:px-6 lg:px-8 max-w-6xl pt-12">
//         <div className="bg-white  rounded-xl p-6 lg:p-8">
//           {/* Header */}
//           <div className="text-center mb-8">
//             <span className="inline-flex items-center gap-1 bg-green-100 text-green-800 text-sm font-medium px-3 py-1 rounded-full">
//               <Star className="w-4 h-4" /> Introducing LaunchVote
//             </span>
//             <h1 className="text-4xl lg:text-5xl font-bold text-gray-900 mt-4 mb-4">
//               Launch Your Idea in <span className="text-green-600">60 Seconds</span>
//             </h1>
//             <p className="text-xl text-gray-600 max-w-2xl mx-auto">
//               Auto-generate your coming soon page, collect feature votes,desired pricing and get your first users—all before writing a
//               single line of code.
//             </p>
//           </div>

//           {/* CTA */}
//           <div className="text-center mb-12">
//             <button className="bg-green-600  text-white px-8 py-3 rounded-lg font-medium text-lg inline-flex items-center gap-2 hover:bg-green-700 transition-all hover:shadow-lg">
//               Launch Your Page Now
//               <ArrowRight className="w-5 h-5" />
//             </button>
//           </div>

//           {/* Video Section */}
//           <div className="max-w-3xl mx-auto">
//             <div className="relative w-full bg-[#112318] rounded-xl overflow-hidden border border-[#1C3828] shadow-xl">
//               <div className="relative pt-[56.25%]">
//                 <iframe
//                   className="absolute inset-0 w-full h-full"
//                   src="https://www.youtube.com/embed/zYbMRtMqlFc?si=GwOvnivPF5PLmEYU"
//                   title="LaunchVote Demo"
//                   allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
//                   allowFullScreen
//                 ></iframe>
//               </div>
//             </div>
//             <p className="text-center text-green-600 text-sm font-medium mt-4">Watch how to launch your page in 60 seconds</p>
//           </div>
//         </div>
//       </div>

//       {/* Trending Launches */}
//       <div className="container mx-auto px-4 sm:px-6 lg:px-8 max-w-6xl pt-12">
//         <div className="bg-white rounded-xl p-8">
//           <div className="flex items-center justify-between mb-8">
//             <h2 className="text-2xl font-bold text-gray-900">Explore Launches</h2>
//             <div className="flex items-center gap-3">
//               <select className="text-sm border border-gray-200 rounded-lg px-3 py-2 bg-white" defaultValue="trending">
//                 <option value="trending">Trending</option>
//                 <option value="newest">Newest</option>
//                 <option value="mostVoted">Most Voted</option>
//               </select>
//             </div>
//           </div>

//           {/* Launch Grid */}
//           <div className="grid md:grid-cols-2 lg:grid-cols-3 gap-6">
//             {[
//               {
//                 title: "AIPortfolio.dev",
//                 description: "AI-powered portfolio that writes itself",
//                 signups: 234,
//                 votes: 89,
//                 trend: "+45%",
//                 isHot: true,
//               },
//               {
//                 title: "DevConnect",
//                 description: "Find dev partners for your startup",
//                 signups: 156,
//                 votes: 67,
//                 trend: "+28%",
//               },
//               {
//                 title: "MarketPulse",
//                 description: "Real-time market analysis for founders",
//                 signups: 198,
//                 votes: 78,
//                 trend: "+35%",
//                 isHot: true,
//               },
//               // Add more mock launches
//             ].map((launch, idx) => (
//               <div
//                 key={idx}
//                 className="bg-gradient-to-br from-white to-gray-50 border border-gray-100 rounded-xl p-6 hover:shadow-lg transition-all"
//               >
//                 <div className="flex items-center justify-between mb-3">
//                   <h3 className="font-semibold text-lg">{launch.title}</h3>
//                   {launch.isHot && <span className="bg-red-50 text-red-600 text-xs px-2 py-1 rounded-full font-medium">🔥 Hot</span>}
//                 </div>
//                 <p className="text-gray-600 text-sm mb-4">{launch.description}</p>
//                 <div className="flex items-center justify-between">
//                   <div className="flex items-center gap-4 text-sm">
//                     <span className="flex items-center gap-1 text-gray-500">
//                       <Users className="w-4 h-4" /> {launch.signups}
//                     </span>
//                     <span className="text-green-600 font-medium">{launch.trend}</span>
//                   </div>
//                   <button className="text-green-600 hover:text-green-700 hover:bg-green-50 px-3 py-1 rounded-lg text-sm font-medium transition-colors">
//                     View Page
//                   </button>
//                 </div>
//               </div>
//             ))}
//           </div>
//         </div>
//       </div>

//       {/* Launches Section */}
//       <div className="container mx-auto px-4 sm:px-6 lg:px-8 max-w-6xl py-12">
//         <div className="bg-white shadow-xl rounded-xl p-6 lg:p-8">
//           <h2 className="text-2xl font-bold text-gray-900 mb-6">Your Launch Pages</h2>

//           {userLaunches.length > 0 ? (
//             <div className="grid md:grid-cols-2 gap-6">
//               {userLaunches.map((launch) => (
//                 <div key={launch.id} className="bg-gray-50 rounded-lg p-6 hover:shadow-md transition-shadow">
//                   <h3 className="font-semibold text-lg mb-2">{launch.title}</h3>
//                   <p className="text-gray-600 text-sm mb-4">{launch.description}</p>
//                   <div className="flex items-center justify-between">
//                     <div className="flex items-center gap-4 text-sm text-gray-500">
//                       <span className="flex items-center gap-1">
//                         <Users className="w-4 h-4" /> {launch.signups}
//                       </span>
//                       <span className="flex items-center gap-1">
//                         <Globe className="w-4 h-4" /> {launch.countries}
//                       </span>
//                     </div>
//                     <button className="text-green-600 hover:text-green-700 font-medium flex items-center gap-1">
//                       View Page <ChevronRight className="w-4 h-4" />
//                     </button>
//                   </div>
//                 </div>
//               ))}
//             </div>
//           ) : (
//             <div className="text-center py-12">
//               <Rocket className="w-12 h-12 text-gray-400 mx-auto mb-4" />
//               <h3 className="text-xl font-semibold text-gray-900 mb-2">No Launch Pages Yet</h3>
//               <p className="text-gray-600 mb-6 max-w-md mx-auto">
//                 Turn your validated idea into a beautiful landing page and start collecting signups.
//               </p>
//               <button className="bg-green-600 text-white px-6 py-3 rounded-lg font-medium inline-flex items-center gap-2 hover:bg-green-700 transition-all">
//                 Create Your First Page
//                 <ArrowRight className="w-5 h-5" />
//               </button>
//             </div>
//           )}
//         </div>
//       </div>
//     </div>
//   );
// };

// export default LaunchVoteDashboard;

import React, { useState } from "react";
import { Rocket, Play, ArrowRight, ChevronRight, Users, Globe, Star, TrendingUp } from "lucide-react";
import DashboardHeader from "./DashboardHeader";
import { useNavigate } from "react-router-dom";

const LaunchVoteDashboard = ({ userLaunches = [] }) => {
  const [activeTab, setActiveTab] = useState("explore");
  const [filter, setFilter] = useState("trending");
  const navigate = useNavigate();

  const mockLaunches = [
    {
      title: "AIPortfolio.dev",
      description: "AI-powered portfolio that writes itself",
      signups: 234,
      votes: 89,
      trend: "+45%",
      isHot: true,
    },
    {
      title: "DevConnect",
      description: "Find dev partners for your startup",
      signups: 156,
      votes: 67,
      trend: "+28%",
    },
    {
      title: "MarketPulse",
      description: "Real-time market analysis for founders",
      signups: 198,
      votes: 78,
      trend: "+35%",
      isHot: true,
    },
    // Add more mock data...
  ];

  return (
    <div className="font-inter antialiased bg-gradient-to-b from-gray-50 to-green-100 text-gray-900 tracking-tight min-h-screen">
      <DashboardHeader />

      {/* Hero Section */}
      <div className="container mx-auto px-4 sm:px-6 lg:px-8 max-w-6xl pt-12">
        <div className="bg-white rounded-xl p-6 lg:p-8">
          {/* Header */}
          <div className="text-center mb-8">
            <span className="inline-flex items-center gap-1 bg-green-100 text-green-800 text-sm font-medium px-3 py-1 rounded-full">
              <Star className="w-4 h-4" /> Introducing LaunchVote
            </span>
            <h1 className="text-4xl lg:text-5xl font-bold text-gray-900 mt-4 mb-4">
              Launch Your Idea in <span className="text-green-600">2 Minutes</span>
            </h1>
            <p className="text-xl text-gray-600 max-w-2xl mx-auto">
              Auto-generate your coming soon page, collect feature votes,desired pricing and get your first users—all before writing a
              single line of code.
            </p>
          </div>

          {/* CTA */}
          {/* <div className="text-center mb-12">
            <button className="bg-green-600 text-white px-8 py-3 rounded-lg font-medium text-lg inline-flex items-center gap-2 hover:bg-green-700 transition-all hover:shadow-lg">
              Launch Your Page Now
              <ArrowRight className="w-5 h-5" />
            </button>
          </div> */}
          {/* <div className="text-center mb-12">
            <button
              onClick={() => navigate("/createlaunch/")}
              className="bg-green-600 text-white px-8 py-3 rounded-lg font-medium text-lg inline-flex items-center gap-2 hover:bg-green-700 transition-all hover:shadow-lg"
            >
              Launch Your Page Now
              <ArrowRight className="w-5 h-5" />
            </button>
          </div> */}

          <div className="mt-8 flex justify-center gap-4 text-center mb-8">
            <button
              onClick={() => navigate("/createlaunch/")}
              className="bg-green-600 text-white px-8 py-3 rounded-lg font-medium text-lg inline-flex items-center gap-2 hover:bg-green-700 transition-all hover:shadow-lg"
            >
              Launch Your Page Now
              <ArrowRight className="w-5 h-5" />
            </button>
            <button className="bg-white text-gray-700 px-8 py-4 rounded-lg font-medium text-lg inline-flex items-center gap-2 hover:bg-gray-50 transition-colors border">
              View Example
            </button>
          </div>

          {/* Video Section */}
          <div className="max-w-3xl mx-auto">
            <div className="relative w-full bg-[#112318] rounded-xl overflow-hidden border border-[#1C3828] shadow-xl">
              <div className="relative pt-[56.25%]">
                <iframe
                  className="absolute inset-0 w-full h-full"
                  src="https://www.youtube.com/embed/zYbMRtMqlFc?si=GwOvnivPF5PLmEYU"
                  title="LaunchVote Demo"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowFullScreen
                ></iframe>
              </div>
            </div>
            <p className="text-center text-green-600 text-sm font-medium mt-4">Watch how to launch your page in 60 seconds</p>
          </div>
        </div>
      </div>

      {/* Launches Section with Tabs */}
      <div className="container mx-auto px-4 sm:px-6 lg:px-8 max-w-6xl pt-12">
        <div className="bg-white rounded-xl p-6 lg:p-8">
          {/* Tabs */}
          <div className="flex items-center gap-6 border-b border-gray-200 mb-8">
            <button
              onClick={() => setActiveTab("explore")}
              className={`pb-4 px-2 font-medium transition-colors relative ${
                activeTab === "explore" ? "text-green-600 border-b-2 border-green-600" : "text-gray-600 hover:text-gray-900"
              }`}
            >
              Explore Launches
              <span className="ml-2 bg-green-100 text-green-800 text-xs px-2 py-0.5 rounded-full">New</span>
            </button>
            <button
              onClick={() => setActiveTab("my-launches")}
              className={`pb-4 px-2 font-medium transition-colors relative ${
                activeTab === "my-launches" ? "text-green-600 border-b-2 border-green-600" : "text-gray-600 hover:text-gray-900"
              }`}
            >
              My Launches
              {userLaunches.length > 0 && (
                <span className="ml-2 bg-gray-100 text-gray-600 text-xs px-2 py-0.5 rounded-full">{userLaunches.length}</span>
              )}
            </button>
          </div>

          {activeTab === "explore" ? (
            <>
              <div className="flex items-center justify-between mb-8">
                <h2 className="text-2xl font-bold text-gray-900">Trending Now</h2>
                <div className="flex items-center gap-3">
                  <select
                    className="text-sm border border-gray-200 rounded-lg px-3 py-2 bg-white"
                    value={filter}
                    onChange={(e) => setFilter(e.target.value)}
                  >
                    <option value="trending">Trending</option>
                    <option value="newest">Newest</option>
                    <option value="mostVoted">Most Voted</option>
                  </select>
                </div>
              </div>

              <div className="grid md:grid-cols-2 lg:grid-cols-3 gap-6">
                {mockLaunches.map((launch, idx) => (
                  <div
                    key={idx}
                    className="bg-gradient-to-br from-white to-gray-50 border border-gray-100 rounded-xl p-6 hover:shadow-lg transition-all group"
                  >
                    <div className="flex items-center justify-between mb-3">
                      <h3 className="font-semibold text-lg">{launch.title}</h3>
                      {launch.isHot && <span className="bg-red-50 text-red-600 text-xs px-2 py-1 rounded-full font-medium">🔥 Hot</span>}
                    </div>
                    <p className="text-gray-600 text-sm mb-4">{launch.description}</p>
                    <div className="flex items-center justify-between">
                      <div className="flex items-center gap-4 text-sm">
                        <span className="flex items-center gap-1 text-gray-500">
                          <Users className="w-4 h-4" /> {launch.signups}
                        </span>
                        <span className="flex items-center gap-1 text-green-600 font-medium">
                          <TrendingUp className="w-4 h-4" /> {launch.trend}
                        </span>
                      </div>
                      <button className="text-green-600 opacity-0 group-hover:opacity-100 hover:text-green-700 px-3 py-1 rounded-lg text-sm font-medium transition-all">
                        View Page →
                      </button>
                    </div>
                  </div>
                ))}
              </div>
            </>
          ) : // My Launches Tab
          userLaunches.length > 0 ? (
            <div className="grid md:grid-cols-2 gap-6">
              {userLaunches.map((launch) => (
                <div key={launch.id} className="bg-gray-50 rounded-xl p-6 hover:shadow-md transition-all">
                  <h3 className="font-semibold text-lg mb-2">{launch.title}</h3>
                  <p className="text-gray-600 text-sm mb-4">{launch.description}</p>
                  <div className="flex items-center justify-between">
                    <div className="flex items-center gap-4 text-sm text-gray-500">
                      <span className="flex items-center gap-1">
                        <Users className="w-4 h-4" /> {launch.signups}
                      </span>
                      <span className="flex items-center gap-1">
                        <Globe className="w-4 h-4" /> {launch.countries}
                      </span>
                    </div>
                    <button className="text-green-600 hover:text-green-700 font-medium flex items-center gap-1">
                      View Analytics <ChevronRight className="w-4 h-4" />
                    </button>
                  </div>
                </div>
              ))}
            </div>
          ) : (
            <div className="text-center py-12">
              <Rocket className="w-12 h-12 text-gray-400 mx-auto mb-4" />
              <h3 className="text-xl font-semibold text-gray-900 mb-2">No Launch Pages Yet</h3>
              <p className="text-gray-600 mb-6 max-w-md mx-auto">
                Turn your validated idea into a beautiful landing page and start collecting signups.
              </p>
              <button className="bg-green-600 text-white px-6 py-3 rounded-lg font-medium inline-flex items-center gap-2 hover:bg-green-700 transition-all">
                Create Your First Page
                <ArrowRight className="w-5 h-5" />
              </button>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default LaunchVoteDashboard;

// const LaunchVoteLanding = () => {
//   const [isVideoPlaying, setIsVideoPlaying] = useState(false);

//   return (
//     <div className="min-h-screen bg-white">
//       <DashboardHeader />
//       {/* Hero Section */}
//       <div className="bg-gradient-to-b from-green-50 to-white">
//         <div className="max-w-6xl mx-auto px-4 pt-20 pb-24 text-center">
//           <span className="bg-green-100 text-green-800 text-sm font-medium px-4 py-1.5 rounded-full">Introducing LaunchVote</span>

//           <h1 className="mt-8 text-6xl font-bold text-gray-900">
//             Stop Getting Stuck in the Ideation Phase. Launch Your Idea in <span className="text-green-600">60 Seconds</span>
//           </h1>

//           <p className="mt-6 text-xl text-gray-600 max-w-2xl mx-auto">
//             {/* Auto-generate a Product Hunt style landing page, collect feature votes, and build what people actually want. */}
//             Auto-generate your coming soon page, collect feature votes,desired pricing and get your first users—all before writing a single
//             line of code.
//           </p>

//           <div className="mt-8 flex justify-center gap-4">
//             <button className="bg-green-600 text-white px-8 py-4 rounded-lg font-medium text-lg inline-flex items-center gap-2 hover:bg-green-700 transition-colors">
//               Launch Your Page
//               <ArrowRight className="w-5 h-5" />
//             </button>
//             <button className="bg-white text-gray-700 px-8 py-4 rounded-lg font-medium text-lg inline-flex items-center gap-2 hover:bg-gray-50 transition-colors border">
//               View Example
//             </button>
//           </div>

//           {/* Video Preview */}
//           {/* <div className="mt-16 relative max-w-4xl mx-auto rounded-xl overflow-hidden shadow-2xl">
//             <img src="/api/placeholder/1200/675" alt="LaunchVote Demo" className="w-full" />
//             <button
//               onClick={() => setIsVideoPlaying(true)}
//               className="absolute inset-0 flex items-center justify-center bg-black bg-opacity-30 hover:bg-opacity-40 transition-opacity group"
//             >
//               <div className="w-20 h-20 bg-white rounded-full flex items-center justify-center shadow-lg group-hover:scale-110 transition-transform">
//                 <Play className="w-8 h-8 text-green-600 ml-1" />
//               </div>
//             </button>
//           </div>
//         </div> */}
//           {/* Video Section */}
//           <div className="mb-32 max-w-[900px] mx-auto mt-20">
//             <div className="relative w-full bg-[#112318] rounded-3xl overflow-hidden border border-[#1C3828] shadow-2xl">
//               <div className="relative pt-[56.25%]">
//                 <iframe
//                   className="absolute inset-0 w-full h-full"
//                   src="https://www.youtube.com/embed/zYbMRtMqlFc?si=GwOvnivPF5PLmEYU"
//                   title="PROOV Demo"
//                   allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
//                   referrerPolicy="strict-origin-when-cross-origin"
//                   allowFullScreen
//                 ></iframe>
//               </div>
//             </div>
//             <div className="mt-6 text-center">
//               <p className="text-[#00DC82] text-sm font-medium">See how PROOV replaces pitch decks with proof</p>
//             </div>
//           </div>

//           {/* Early User Testimonial */}
//           <div className=" backdrop-blur-lg rounded-2xl p-6 max-w-3xl mx-auto">
//             <div className="flex items-center gap-6">
//               <img src="/api/placeholder/80/80" alt="Profile" className="w-20 h-20 rounded-full border-4 border-white/20" />
//               <div className="text-white">
//                 <p className="text-lg mb-2 text-black">
//                   "As the founder of FeedbackbyAI, I built this because every validated idea needs a smooth path to real users. We used it
//                   ourselves and got 100+ signups in 24 hours."
//                 </p>
//                 <div className="font-medium">Rohit Sharma</div>
//                 <div className="text-black">Founder, FeedbackbyAI</div>
//               </div>
//             </div>
//           </div>
//         </div>
//       </div>

//       {/* How it Works */}
//       <div className="max-w-6xl mx-auto px-4 py-24">
//         <h2 className="text-3xl font-bold text-center mb-16">From Validation to Launch in Three Steps</h2>

//         <div className="grid md:grid-cols-3 gap-12">
//           <div className="text-center">
//             <div className="w-16 h-16 bg-green-100 rounded-2xl flex items-center justify-center mx-auto mb-6">
//               <Rocket className="w-8 h-8 text-green-600" />
//             </div>
//             <h3 className="text-xl font-semibold mb-4">1. Click Launch</h3>
//             <p className="text-gray-600">After validation, click launch and we'll auto-generate your coming soon page.</p>
//           </div>

//           <div className="text-center">
//             <div className="w-16 h-16 bg-green-100 rounded-2xl flex items-center justify-center mx-auto mb-6">
//               <Users className="w-8 h-8 text-green-600" />
//             </div>
//             <h3 className="text-xl font-semibold mb-4">2. Share Your Page</h3>
//             <p className="text-gray-600">Get featured in our directory and weekly newsletter to 10,000+ founders.</p>
//           </div>

//           <div className="text-center">
//             <div className="w-16 h-16 bg-green-100 rounded-2xl flex items-center justify-center mx-auto mb-6">
//               <Sparkles className="w-8 h-8 text-green-600" />
//             </div>
//             <h3 className="text-xl font-semibold mb-4">3. Track Interest</h3>
//             <p className="text-gray-600">Watch signups roll in and learn what features users want most.</p>
//           </div>
//         </div>
//       </div>

//       {/* Features */}
//       <div className="bg-gray-50 py-24">
//         <div className="max-w-6xl mx-auto px-4">
//           <h2 className="text-3xl font-bold text-center mb-16">Everything You Need to Launch Right</h2>

//           <div className="grid md:grid-cols-2 gap-8">
//             {[
//               "Auto-generated coming soon page",
//               "Feature voting system",
//               "Email capture forms",
//               "Price feedback collection",
//               "Geographic user tracking",
//               "Real-time analytics",
//               "Directory listing",
//               "Newsletter promotion",
//             ].map((feature, idx) => (
//               <div key={idx} className="flex items-center gap-3 bg-white p-4 rounded-lg">
//                 <CheckCircle2 className="w-5 h-5 text-green-600 flex-shrink-0" />
//                 <span>{feature}</span>
//               </div>
//             ))}
//           </div>
//         </div>
//       </div>

//       {/* CTA */}
//       <div className="max-w-6xl mx-auto px-4 py-24 text-center">
//         <h2 className="text-3xl font-bold mb-6">Ready to Launch Your Idea?</h2>
//         <p className="text-xl text-gray-600 mb-8">Special launch price: $29 (Regular $49)</p>
//         <button className="bg-green-600 text-white px-8 py-4 rounded-lg font-medium text-lg inline-flex items-center gap-2 hover:bg-green-700 transition-colors">
//           Create Your Launch Page
//           <ArrowRight className="w-5 h-5" />
//         </button>
//       </div>
//     </div>
//   );
// };

// export default LaunchVoteLanding;
