// import React, { useState, useEffect, useRef } from "react";
// import axios from "axios";
// import api from "./axiosConfig";
// import ReactMarkdown from "react-markdown";
// import DashboardHeader from "./DashboardHeader";
// import { Link } from "react-router-dom";
// import { AiOutlineInfoCircle } from "react-icons/ai";
// import TopBanner from "./TopBanner";

// function FeedbackAssistant() {
//   const [categories, setCategories] = useState([]);
//   const [idea, setIdea] = useState("");
//   const [groupQuestions, setGroupQuestions] = useState([]);
//   const [privateQuestions, setPrivateQuestions] = useState([""]);
//   const [selectedCharacters, setSelectedCharacters] = useState([]);
//   const [characters, setCharacters] = useState([]);
//   //   const [feedbacks, setFeedbacks] = useState([]);
//   const [feedbacks, setFeedbacks] = useState({}); // Using object to store feedbacks with character names as keys

//   const [streamingFeedback, setStreamingFeedback] = useState([]);
//   const [isDropdownOpen, setDropdownOpen] = useState(false);
//   const [selectedGroupQuestions, setSelectedGroupQuestions] = useState([]);
//   const [ideaKey, setIdeaKey] = useState("");
//   const [showTooltip1, setShowTooltip1] = useState(false);
//   const [showTooltip2, setShowTooltip2] = useState(false);
//   const backgroundClasses = [
//     "bg-blue-100 border-blue-300",
//     "bg-red-100 border-red-300",
//     "bg-green-100 border-green-300",
//     "bg-yellow-100 border-yellow-300",
//     "bg-purple-100 border-purple-300",
//     "bg-pink-100 border-pink-300",
//     "bg-indigo-100 border-indigo-300",
//     "bg-orange-100 border-orange-300",
//     "bg-teal-100 border-teal-300",
//     "bg-gray-100 border-gray-300",
//   ];

//   const getBackgroundClass = (index) => backgroundClasses[index % backgroundClasses.length];

//   const dropdownRef = useRef(null);
//   const REGIONS = [
//     "Global",
//     "United States",
//     "Canada",
//     "Mexico",
//     "Brazil",
//     "Argentina",
//     "Rest of South America",
//     "United Kingdom",
//     "France",
//     "Germany",
//     "Sweden",
//     "Denmark",
//     "Poland",
//     "Russia",
//     "China",
//     "Japan",
//     "India",
//     "Singapore",
//     "Indonesia",
//     "UAE",
//     "Saudi Arabia",
//     "Rest of MENA",
//     "South Africa",
//     "Nigeria",
//     "Rest of Sub-Saharan Africa",
//     "Australia",
//     "New Zealand",
//     "Central Asia",
//     "Rest of Eastern Europe",
//     "Rest of Southeast Asia",
//   ];
//   // Define your regions or fetch from API if dynamic
//   const REGION_FLAGS = {
//     Global: "🌍",
//     "United States": "🇺🇸",
//     Canada: "🇨🇦",
//     Mexico: "🇲🇽",
//     Brazil: "🇧🇷",
//     Argentina: "🇦🇷",
//     "Rest of South America": "🌎", // Using a generic continent emoji for the 'Rest of' categories
//     "United Kingdom": "🇬🇧",
//     France: "🇫🇷",
//     Germany: "🇩🇪",
//     Sweden: "🇸🇪",
//     Denmark: "🇩🇰",
//     Poland: "🇵🇱",
//     Russia: "🇷🇺",
//     China: "🇨🇳",
//     Japan: "🇯🇵",
//     India: "🇮🇳",
//     Singapore: "🇸🇬",
//     Indonesia: "🇮🇩",
//     UAE: "🇦🇪",
//     "Saudi Arabia": "🇸🇦",
//     "Rest of MENA": "🌍",
//     "South Africa": "🇿🇦",
//     Nigeria: "🇳🇬",
//     "Rest of Sub-Saharan Africa": "🌍",
//     Australia: "🇦🇺",
//     "New Zealand": "🇳🇿",
//     "Central Asia": "🌍",
//     "Rest of Eastern Europe": "🌍",
//     "Rest of Southeast Asia": "🌍",
//   };

//   const [selectedCategory, setSelectedCategory] = useState("Business Idea Validation");
//   const [headline, setHeadline] = useState("Instant Feedback");
//   const [subheadline, setSubheadline] = useState(
//     // "Target audience, research and advantages/disadvantages in no time"
//     "Get immediate,diverse feedback on your business idea"
//   );
//   const [selectedRegion, setSelectedRegion] = useState("Global");
//   const [credits, setCredits] = useState(0);
//   const [isLoading, setIsLoading] = useState(true);
//   const [isSSEInProgress, setSSEInProgress] = useState(false);
//   const [showReportLinks, setShowReportLinks] = useState({});
//   // input limit < 500
//   const [isExceedingLimit, setIsExceedingLimit] = useState(false);

//   const mapCategoryToCode = (category) => {
//     switch (category) {
//       case "Business Idea Validation":
//         return "BV";
//       case "Advertisement Validation":
//         return "AV";
//       case "Content Idea Validation":
//         return "CV";

//       // ... Add other categories here ...
//       default:
//         return "BV";
//     }
//   };

//   const handleInputChange = (e) => {
//     const inputText = e.target.value;
//     if (inputText.length <= 500) {
//       setIdea(inputText);
//       setIsExceedingLimit(false); // Reset the limit exceeding warning
//     } else {
//       // Here, ideally, we don't set the new value because it exceeds the limit
//       // and we possibly show a warning message
//       setIsExceedingLimit(true);
//     }
//   };

//   const handlePaste = (e) => {
//     const pastedText = e.clipboardData.getData("text");
//     const newText = idea + pastedText;
//     if (newText.length > 500) {
//       e.preventDefault(); // Prevent the paste
//       setIsExceedingLimit(true);
//     }
//   };

//   const mapRegionToCode = (region) => {
//     switch (region) {
//       case "Global":
//         return "Global";
//       case "United States":
//         return "US";
//       case "Canada":
//         return "CA";
//       case "Mexico":
//         return "MX";
//       case "Brazil":
//         return "BR";
//       case "Argentina":
//         return "AR";
//       case "Rest of South America":
//         return "RSA";
//       case "United Kingdom":
//         return "UK";
//       case "France":
//         return "FR";
//       case "Germany":
//         return "DE";
//       case "Sweden":
//         return "SE";
//       case "Denmark":
//         return "DK";
//       case "Poland":
//         return "PL";
//       case "Russia":
//         return "RU";
//       case "China":
//         return "CN";
//       case "Japan":
//         return "JP";
//       case "India":
//         return "IN";
//       case "Singapore":
//         return "SG";
//       case "Indonesia":
//         return "ID";
//       case "UAE":
//         return "UAE";
//       case "Saudi Arabia":
//         return "SA";
//       case "Rest of MENA":
//         return "RM";
//       case "South Africa":
//         return "ZA";
//       case "Nigeria":
//         return "NG";
//       case "Rest of Sub-Saharan Africa":
//         return "RSAF";
//       case "Australia":
//         return "AU";
//       case "New Zealand":
//         return "NZ";
//       case "Central Asia":
//         return "CAZ";
//       case "Rest of Eastern Europe":
//         return "REE";
//       case "Rest of Southeast Asia":
//         return "RSEA";
//       default:
//         return "Global"; // Defaulting to Global for simplicity
//     }
//   };

//   const fetchUserCredits = async () => {
//     const token = localStorage.getItem("access");
//     const bearer_token = "Bearer " + token;

//     try {
//       const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}get-user-credits/`, {
//         method: "GET",
//         headers: {
//           Authorization: bearer_token,
//         },
//       });
//       const data = await response.json();
//       setCredits(data.question_credits);
//       setIsLoading(false);
//     } catch (error) {
//       console.error("Error fetching user credits:", error);
//       setIsLoading(false);
//     }
//   };

//   useEffect(() => {
//     setCategories(["Business Idea Validation", "Content Idea Validation", "Advertisement Validation"]);

//     const fetchCharacters = async () => {
//       try {
//         const response = await axios.get(`${process.env.REACT_APP_API_BASE_URL}character/`);

//         if (response.data && Array.isArray(response.data)) {
//           setCharacters(response.data);
//           if (credits < 4 && response.data.length >= 2) {
//             setSelectedCharacters([response.data[1].id]);
//           }
//         }
//       } catch (error) {
//         console.error("Error fetching characters:", error);
//         // Handle error appropriately (e.g., show an error message to the user)
//       }
//     };

//     fetchCharacters();

//     switch (selectedCategory) {
//       case "Business Idea Validation":
//         const businessQuestions = [
//           "Are you interested in the idea?",
//           "Are you willing to pay for this idea?",
//           "Is there anything that you feel is missing from this idea?",
//           "What are the drawbacks or limitations of this idea?",
//           "How likely are you to use this product/service?",
//         ];
//         setGroupQuestions(businessQuestions);
//         setSelectedGroupQuestions(["Are you interested in the idea?"]); // Select only the first question by default
//         // setSelectedGroupQuestions(businessQuestions); // auto-select all

//         // setHeadline("Idea Validation");
//         setHeadline("Instant Feedback");
//         // setSubheadline("Polish  and fine-tune your business ideas");
//         // setSubheadline("Know What People Might Say About Your  Business Idea");
//         setSubheadline("Uncover Concerns About Your Business Idea ");
//         break;

//         const contentQuestions = [
//           "Does the content text maintain your interest and engagement throughout?",
//           "Are there any changes or improvements you would suggest for the content text to make it more effective or useful?",
//           "Are there any parts of the content text that are confusing or difficult to follow?",
//           "Does the content text provide new or valuable information that you did not already know?",
//         ];
//         setGroupQuestions(contentQuestions);
//         setSelectedGroupQuestions(contentQuestions); // auto-select all

//         setHeadline("Content Validation by AI");
//         setSubheadline("Make your content bulletproof. Confirm its worth.");

//         break;
//       default:
//         setGroupQuestions([]);
//         break;
//     }
//   }, [selectedCategory, setFeedbacks]);

//   useEffect(() => {
//     if (credits < 4 && groupQuestions.length > 0) {
//       setSelectedGroupQuestions([groupQuestions[0]]);
//     }
//   }, [credits, groupQuestions]);

//   useEffect(() => {
//     const handleClickOutside = (event) => {
//       if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
//         setDropdownOpen(false);
//       }
//     };

//     document.addEventListener("mousedown", handleClickOutside);
//     return () => {
//       document.removeEventListener("mousedown", handleClickOutside);
//     };
//   }, []);

//   useEffect(() => {
//     const token = localStorage.getItem("access");

//     if (!token) {
//       window.location.href = "/signin/";
//     } else {
//       // Check token expiry if using JWT
//       try {
//         const decodedToken = JSON.parse(atob(token.split(".")[1]));
//         const currentTimestamp = Math.floor(Date.now() / 1000); // current time in seconds since epoch

//         if (decodedToken.exp && decodedToken.exp < currentTimestamp) {
//           localStorage.removeItem("access"); // remove the expired token
//           window.location.href = "/signin/";
//         } else {
//           //   setUser(true);
//           fetchUserCredits();
//         }
//       } catch (error) {
//         // Failed to decode token or other error
//         console.error("Failed to decode the token:", error);
//         window.location.href = "/signin/";
//       }
//     }
//   }, []);

//   const addPrivateQuestion = () => {
//     setPrivateQuestions((prev) => [...prev, ""]);
//   };

//   const handlePrivateQuestionChange = (index, value) => {
//     const updatedQuestions = [...privateQuestions];
//     updatedQuestions[index] = value;
//     setPrivateQuestions(updatedQuestions);
//   };

//   const createFeedback = async () => {
//     const token = localStorage.getItem("access");
//     const bearer_token = "Bearer " + token;
//     const url = `${process.env.REACT_APP_API_BASE_URL}feedback/`;
//     const payload = {
//       input: idea,
//       // category: mapCategoryToCode(selectedCategory),
//       category: "BV",
//       region: mapRegionToCode(selectedRegion),
//       questions: [...selectedGroupQuestions, ...privateQuestions]
//         .filter((q) => q.trim() !== "") // This will filter out empty or just whitespace questions
//         .map((q) => ({ text: q })),
//       characters: selectedCharacters,
//     };

//     try {
//       const response = await fetch(url, {
//         method: "POST",
//         headers: {
//           "Content-Type": "application/json",
//           Authorization: bearer_token,
//         },
//         body: JSON.stringify(payload),
//       });

//       const data = await response.json();
//       // Handle "Not enough question credits" error
//       if (data.error && data.error === "Not enough question credits.") {
//         alert("Not enough question credits. Please recharge your account or reduce question or characters.");
//         return; // Exit the function to avoid further processing
//       }
//       const feedbackId = data.id; // Assuming the backend returns the feedback ID

//       // Handle response and store idea with timestamp
//       if (data && !data.error) {
//         const ideaKey = `idea-${Date.now()}`;
//         const ideaData = { storedIdea: idea, timestamp: Date.now() };
//         setIdeaKey(ideaKey);
//         localStorage.setItem(ideaKey, JSON.stringify(ideaData));
//         // console.log("Idea stored with timestamp:", ideaKey, ideaData);
//       }

//       // Empty the questions after sending
//       setPrivateQuestions([""]); // Reset to initial state with an empty string
//       setSelectedGroupQuestions([]); // Reset to an empty array
//       setIdea(""); // Reset the input
//       // Clear previous feedbacks
//       setFeedbacks({});

//       listenForFeedbackUpdates(feedbackId);
//       // Set isSSEInProgress to true here
//       setSSEInProgress(true);
//       // Fetch user credits after successful submission
//       fetchUserCredits();
//     } catch (error) {
//       console.error("Error creating feedback:", error);
//     }
//   };

//   const listenForFeedbackUpdates = async (feedbackId) => {
//     const token = localStorage.getItem("access");
//     const bearer_token = "Bearer " + token;
//     const url = `${process.env.REACT_APP_API_BASE_URL}feedback/${feedbackId}/updates/`;

//     try {
//       const response = await fetch(url, {
//         headers: {
//           Authorization: bearer_token,
//         },
//       });

//       const contentType = response.headers.get("content-type");

//       if (contentType && contentType.startsWith("application/json")) {
//         const data = await response.json();
//         const feedbackByCharacter = {};
//         data.forEach((feedback) => {
//           if (!feedbackByCharacter[feedback.character]) {
//             feedbackByCharacter[feedback.character] = [];
//           }
//           feedbackByCharacter[feedback.character].push(feedback);
//         });
//         setFeedbacks(feedbackByCharacter);
//       } else if (contentType && contentType.startsWith("text/event-stream")) {
//         const eventSource = new EventSource(url, {
//           headers: {
//             Authorization: bearer_token,
//           },
//         });

//         eventSource.onmessage = (event) => {
//           const newFeedback = JSON.parse(event.data);

//           if (newFeedback.end_of_stream) {
//             eventSource.close();
//             // Set isSSEInProgress to false once the SSE completes
//             setSSEInProgress(false);
//             return;
//           }

//           setFeedbacks((prevFeedbacks) => {
//             const feedbackByCharacter = { ...prevFeedbacks };

//             // Ensure the character has an entry; if not, create one.
//             if (!feedbackByCharacter[newFeedback.character]) {
//               feedbackByCharacter[newFeedback.character] = [];
//             }

//             // Find if there's existing feedback for the same character and question.
//             const existingFeedbackIndex = feedbackByCharacter[newFeedback.character].findIndex((f) => f.question === newFeedback.question);

//             if (existingFeedbackIndex > -1) {
//               const existingFeedback = feedbackByCharacter[newFeedback.character][existingFeedbackIndex];

//               // Instead of appending, set the latest feedback as the new response.
//               existingFeedback.response = newFeedback.response;
//             } else {
//               // If no feedback exists for the character's specific question, add the new feedback.
//               feedbackByCharacter[newFeedback.character].push(newFeedback);
//             }

//             return feedbackByCharacter;
//           });
//         };

//         eventSource.onerror = (error) => {
//           console.error("Error with SSE:", error);
//           setSSEInProgress(false); // Reset if there's an error
//           eventSource.close();
//         };
//       } else {
//         console.warn("Unknown content type:", contentType);
//       }
//     } catch (error) {
//       setSSEInProgress(false); // Reset if there's an error outside of the event listeners
//       console.error("Error while listening for updates:", error);
//     }
//   };

//   const removePrivateQuestion = (index) => {
//     setPrivateQuestions((prev) => prev.filter((_, idx) => idx !== index));
//   };

//   const truncateDetails = (str, length = 80) => {
//     if (str.length <= length) return str;
//     return str.slice(0, length) + "...";
//   };

//   const hasFeedback = (character) => {
//     return selectedCharacters.includes(character.id) && feedbacks[character.name] && feedbacks[character.name].length > 0;
//   };

//   const sortedCharacters = characters.sort((a, b) => {
//     return hasFeedback(b) - hasFeedback(a);
//   });

//   useEffect(() => {
//     // Set a timeout for each character when feedback starts
//     characters.forEach((character) => {
//       if (selectedCharacters.includes(character.id)) {
//         setTimeout(() => {
//           setShowReportLinks((prev) => ({ ...prev, [character.name]: true }));
//         }, 15000); // 15 seconds delay
//       }
//     });
//   }, [selectedCharacters, characters]);

//   const CreditDisplay = () => (
//     <div className="credit-display flex flex-col md:flex-row justify-between items-center p-3 bg-gradient-to-r from-gray-50 to-gray-100 rounded-lg shadow-sm border border-gray-300 mt-4">
//       {isLoading ? (
//         <span>Loading...</span>
//       ) : (
//         <>
//           <div className="flex flex-col md:flex-row items-center space-y-2 md:space-y-0 md:space-x-2">
//             <span className={`text-lg font-semibold ${credits <= 0 ? "text-red-600" : "text-gray-800"}`}>{credits} Credits</span>
//             <span className="text-sm text-gray-600">(1 credit = 1 answer from 1 character )</span>
//             <span className="text-sm text-gray-600"></span>
//             {credits <= 0 && <span className="text-sm text-red-500">Low on credits!</span>}
//           </div>

//           {/* Show button only if credits are 0 */}
//           {credits === 0 && (
//             <button
//               onClick={() => (window.location.href = "/credits/")}
//               className="mt-2 ml-2 md:mt-0 py-2 px-4 rounded-lg font-medium shadow-sm transition duration-200 bg-red-500 hover:bg-red-600 text-white"
//             >
//               Recharge Now
//             </button>
//           )}
//         </>
//       )}
//     </div>
//   );

//   return (
//     <div className="font-inter antialiased bg-gradient-to-b from-gray-50 to-green-100 text-gray-900 tracking-tight">
//       <TopBanner />
//       <DashboardHeader />
//       <div className="min-h-screen py-10 lg:py-20">
//         <div className="container mx-auto px-4 sm:px-6 lg:px-8 max-w-5xl bg-white shadow-xl rounded-xl p-6 lg:p-8 space-y-8">
//           {/* Stepper Functionality */}
//           <div className="text-center my-6">
//             <h1 className="text-5xl font-bold text-gray-900 mb-4">
//               {/* Instant Feedback */}
//               AI Character Feedback
//             </h1>
//             <p className="text-xl text-gray-700 max-w-5xl mx-auto">Run AI Interviews with Your Target Customers</p>
//           </div>

//           <div className="space-y-6 ">
//             <div>
//               <label className="block text-md font-semibold text-gray-700">Enter Your Idea Here*</label>
//               <textarea
//                 className="mt-1 w-full p-2 border border-gray-300 rounded-md shadow-sm focus:border-indigo-500 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
//                 // placeholder="Type your idea here. Be detailed for better results..."
//                 placeholder="I am building an app which lets you rent..."
//                 value={idea}
//                 // onChange={(e) => setIdea(e.target.value)}
//                 onChange={handleInputChange}
//                 onPaste={handlePaste}
//                 rows={5}
//                 maxLength={500}
//               ></textarea>
//               {isExceedingLimit && <div className="text-red-500 text-sm mt-2">Your idea exceeds the 500 characters limit.</div>}
//               <div className="text-right text-sm text-gray-500">{idea.length}/500</div>
//             </div>

//             <div>
//               <label className="block text-md font-semibold text-gray-700">Target Market You're Building For *</label>
//               <select
//                 className="mt-1 block w-full rounded-md text-sm font-medium border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
//                 value={selectedRegion}
//                 onChange={(e) => setSelectedRegion(e.target.value)}
//               >
//                 <option value="">--Select Region--</option>
//                 {REGIONS.map((region, index) => (
//                   <option key={index} value={region}>
//                     {REGION_FLAGS[region]} {region}
//                   </option>
//                 ))}
//               </select>
//             </div>

//             <div>
//               <label className="block text-md font-semibold text-gray-700">
//                 Whose Feedback You Need * (example remote workers, software developers, investor, online trolls)
//               </label>
//               <div className="relative mt-2 text-sm font-medium" ref={dropdownRef}>
//                 <button
//                   onClick={() => setDropdownOpen(!isDropdownOpen)}
//                   className="w-full text-left p-2 border rounded focus:outline-none focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
//                 >
//                   Select Characters
//                 </button>
//                 {isDropdownOpen && (
//                   <div
//                     className="absolute text-sm font-medium w-full mt-2 z-10 border border-gray-300 bg-white rounded shadow-md overflow-y-auto"
//                     style={{ maxHeight: "250px" }}
//                   >
//                     {characters.map((character, index) => (
//                       <label
//                         key={character.id}
//                         className={`block p-2 hover:bg-gray-100 cursor-pointer text-sm font-medium ${
//                           credits < 4 && character.id !== selectedCharacters[0] ? "bg-gray-200 cursor-not-allowed" : ""
//                         }`}
//                       >
//                         <input
//                           type="checkbox"
//                           className="mr-2 rounded border-gray-300 text-indigo-600 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
//                           checked={selectedCharacters.includes(character.id)}
//                           onChange={() => {
//                             if (credits >= 4) {
//                               const updated = [...selectedCharacters];
//                               if (selectedCharacters.includes(character.id)) {
//                                 const index = updated.indexOf(character.id);
//                                 updated.splice(index, 1);
//                               } else {
//                                 updated.push(character.id);
//                               }
//                               setSelectedCharacters(updated);
//                             }
//                           }}
//                           disabled={credits < 4 && !selectedCharacters.includes(character.id)}
//                         />
//                         <span className="text-gray-900">
//                           {character.emoji} {character.name}
//                         </span>
//                         {credits < 4 && character.id !== selectedCharacters[0] && (
//                           <span className="text-xs text-red-500 ml-2">Pro feature</span>
//                         )}
//                       </label>
//                     ))}
//                   </div>
//                 )}
//               </div>
//             </div>

//             <div>
//               <label className="block text-md font-semibold text-gray-700">Questions to Ask From Them*</label>
//               <ul className="space-y-2 mt-2">
//                 {groupQuestions.map((q, idx) => (
//                   <li key={idx} className="flex items-center space-x-3">
//                     <input
//                       type="checkbox"
//                       id={`gq-${idx}`}
//                       className="rounded border-gray-300 text-indigo-600 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
//                       checked={selectedGroupQuestions.includes(q)}
//                       onChange={() => {
//                         if (credits >= 4) {
//                           if (selectedGroupQuestions.includes(q)) {
//                             setSelectedGroupQuestions((prev) => prev.filter((question) => question !== q));
//                           } else {
//                             setSelectedGroupQuestions((prev) => [...prev, q]);
//                           }
//                         }
//                       }}
//                       disabled={credits < 4 && q !== groupQuestions[0]}
//                     />
//                     <label
//                       htmlFor={`gq-${idx}`}
//                       className={`text-gray-600 text-sm font-medium ${credits < 4 && q !== groupQuestions[0] ? "text-gray-400" : ""}`}
//                     >
//                       {q}
//                     </label>
//                     {idx !== 0 && credits < 4 && <span className="text-xs text-red-500 ml-2">Pro feature</span>}
//                   </li>
//                 ))}
//               </ul>
//             </div>

//             <div>
//               <label className="block text-md font-semibold text-gray-700">
//                 Create Your Own Question (if any)
//                 {credits < 4 && <span className="text-xs text-red-500 ml-2">Pro feature</span>}
//               </label>
//               {privateQuestions.map((q, index) => (
//                 <div className="flex items-center">
//                   <input
//                     key={index}
//                     type="text"
//                     className={`mt-1 w-full rounded-md text-sm font-medium border-gray-900 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50 mb-2 ${
//                       credits < 4 ? "bg-gray-200 cursor-not-allowed" : ""
//                     }`}
//                     disabled={credits < 4}
//                     placeholder={`Custom Question ${index + 1}`}
//                     value={q}
//                     onChange={(e) => handlePrivateQuestionChange(index, e.target.value)}
//                   />
//                   <button className="ml-2 text-gray-700 hover:text-gray-800" onClick={() => removePrivateQuestion(index)}>
//                     ×
//                   </button>
//                 </div>
//               ))}
//               <button
//                 className={`text-indigo-600 hover:text-indigo-800 ${credits < 4 ? "cursor-not-allowed opacity-50" : ""}`}
//                 onClick={() => {
//                   if (credits >= 4) {
//                     addPrivateQuestion();
//                   }
//                 }}
//                 disabled={credits < 4}
//               >
//                 + Add More
//               </button>
//             </div>
//           </div>

//           <div className="flex flex-col lg:flex-row justify-between items-center space-y-4 lg:space-y-0">
//             <div className="space-x-2">
//               <CreditDisplay />
//             </div>

//             <button
//               className={`px-8 py-3 text-lg rounded-md font-semibold transition-all duration-200 ${
//                 idea && selectedCharacters.length > 0 && credits > 0
//                   ? "bg-indigo-600 text-white hover:bg-indigo-700 hover:shadow-md"
//                   : "bg-gray-300 text-gray-500 cursor-not-allowed"
//               }`}
//               onClick={createFeedback}
//               disabled={!(idea && selectedCharacters.length > 0 && credits > 0)}
//             >
//               Give Me Feedback
//             </button>
//           </div>

//           <div className="character-display grid grid-cols-1 md:grid-cols-2 gap-6 mt-6 ">
//             {sortedCharacters.map((character, index) => (
//               <div
//                 key={character.id}
//                 className={`p-4 rounded-md space-y-4 transition-shadow duration-200 hover:shadow-md ${
//                   selectedCharacters.includes(character.id) && feedbacks[character.name] && feedbacks[character.name].length !== 0
//                     ? `${getBackgroundClass(index)} border-2`
//                     : "bg-gray-100 border border-gray-400 cursor-not-allowed"
//                 }`}
//                 onClick={() => {
//                   if (!feedbacks[character.name] || feedbacks[character.name].length === 0) {
//                     const updated = [...selectedCharacters];
//                     if (selectedCharacters.includes(character.id)) {
//                       const index = updated.indexOf(character.id);
//                       updated.splice(index, 1);
//                     } else {
//                       updated.push(character.id);
//                     }
//                     setSelectedCharacters(updated);
//                   }
//                 }}
//               >
//                 <h4 className="font-bold text-gray-900 flex items-center space-x-2">
//                   <span className="text-2xl">{character.emoji}</span>
//                   <span className="underline">{character.name}</span>
//                 </h4>
//                 <p className="text-gray-600 text-sm mt-1" title={character.details}>
//                   {truncateDetails(character.details)}
//                 </p>
//                 {selectedCharacters.includes(character.id) &&
//                   (!feedbacks[character.name] ? (
//                     isSSEInProgress && <p className="text-gray-900 italic">Typing...</p>
//                   ) : (
//                     <div className="mt-2 space-y-3">
//                       {feedbacks[character.name].map((feedback) => (
//                         <div key={feedback.question} className="space-y-1 border-b border-gray-200 pb-2">
//                           <p className="text-gray-900 font-bold break-words">
//                             <ReactMarkdown>{feedback.question}</ReactMarkdown>
//                           </p>
//                           <p className="prose prose-a:text-blue-600 mx-auto leading-normal font-inter antialiased text-sm text-gray-900 tracking-tight">
//                             <ReactMarkdown>{feedback.response}</ReactMarkdown>
//                           </p>
//                         </div>
//                       ))}
//                       {/* Link to generate report */}
//                       {showReportLinks[character.name] && (
//                         <a
//                           href={`/report/?ideaKey=${ideaKey}`}
//                           className="text-red-700 hover:text-indigo-800  mt-2 block text-xl font-bold animate-pulse leading-snug"
//                           target="_blank"
//                           rel="noopener noreferrer"
//                         >
//                           👉 Move to (Improve Your Business Idea)
//                         </a>
//                       )}
//                     </div>
//                   ))}
//               </div>
//             ))}
//           </div>
//         </div>
//       </div>
//     </div>
//   );
// }

// export default FeedbackAssistant;

import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import api from "./axiosConfig";
import ReactMarkdown from "react-markdown";
import DashboardHeader from "./DashboardHeader";
import { Link } from "react-router-dom";
import {
  MessageCircle,
  Globe,
  User,
  MessageSquare,
  ChevronDown,
  X,
  ArrowRight,
  PlusCircle,
  MinusCircle,
  Check,
  AlertCircle,
  Info,
  Send,
} from "lucide-react";
import TopBanner from "./TopBanner";

function FeedbackAssistant() {
  const [categories, setCategories] = useState([]);
  const [idea, setIdea] = useState("");
  const [groupQuestions, setGroupQuestions] = useState([]);
  const [privateQuestions, setPrivateQuestions] = useState([""]);
  const [selectedCharacters, setSelectedCharacters] = useState([]);
  const [characters, setCharacters] = useState([]);
  const [feedbacks, setFeedbacks] = useState({}); // Using object to store feedbacks with character names as keys

  const [streamingFeedback, setStreamingFeedback] = useState([]);
  const [isDropdownOpen, setDropdownOpen] = useState(false);
  const [selectedGroupQuestions, setSelectedGroupQuestions] = useState([]);
  const [ideaKey, setIdeaKey] = useState("");
  const [showTooltip1, setShowTooltip1] = useState(false);
  const [showTooltip2, setShowTooltip2] = useState(false);

  // Color schemes for character cards
  const backgroundClasses = [
    "bg-blue-50 border-blue-300",
    "bg-red-50 border-red-300",
    "bg-green-50 border-green-300",
    "bg-yellow-50 border-yellow-300",
    "bg-purple-50 border-purple-300",
    "bg-pink-50 border-pink-300",
    "bg-indigo-50 border-indigo-300",
    "bg-orange-50 border-orange-300",
    "bg-teal-50 border-teal-300",
    "bg-gray-50 border-gray-300",
  ];

  const getBackgroundClass = (index) => backgroundClasses[index % backgroundClasses.length];

  const dropdownRef = useRef(null);
  const REGIONS = [
    "Global",
    "United States",
    "Canada",
    "Mexico",
    "Brazil",
    "Argentina",
    "Rest of South America",
    "United Kingdom",
    "France",
    "Germany",
    "Sweden",
    "Denmark",
    "Poland",
    "Russia",
    "China",
    "Japan",
    "India",
    "Singapore",
    "Indonesia",
    "UAE",
    "Saudi Arabia",
    "Rest of MENA",
    "South Africa",
    "Nigeria",
    "Rest of Sub-Saharan Africa",
    "Australia",
    "New Zealand",
    "Central Asia",
    "Rest of Eastern Europe",
    "Rest of Southeast Asia",
  ];

  // Define your regions or fetch from API if dynamic
  const REGION_FLAGS = {
    Global: "🌍",
    "United States": "🇺🇸",
    Canada: "🇨🇦",
    Mexico: "🇲🇽",
    Brazil: "🇧🇷",
    Argentina: "🇦🇷",
    "Rest of South America": "🌎", // Using a generic continent emoji for the 'Rest of' categories
    "United Kingdom": "🇬🇧",
    France: "🇫🇷",
    Germany: "🇩🇪",
    Sweden: "🇸🇪",
    Denmark: "🇩🇰",
    Poland: "🇵🇱",
    Russia: "🇷🇺",
    China: "🇨🇳",
    Japan: "🇯🇵",
    India: "🇮🇳",
    Singapore: "🇸🇬",
    Indonesia: "🇮🇩",
    UAE: "🇦🇪",
    "Saudi Arabia": "🇸🇦",
    "Rest of MENA": "🌍",
    "South Africa": "🇿🇦",
    Nigeria: "🇳🇬",
    "Rest of Sub-Saharan Africa": "🌍",
    Australia: "🇦🇺",
    "New Zealand": "🇳🇿",
    "Central Asia": "🌍",
    "Rest of Eastern Europe": "🌍",
    "Rest of Southeast Asia": "🌍",
  };

  const [selectedCategory, setSelectedCategory] = useState("Business Idea Validation");
  const [headline, setHeadline] = useState("Instant Feedback");
  const [subheadline, setSubheadline] = useState("Get immediate, diverse feedback on your business idea");

  const [selectedRegion, setSelectedRegion] = useState("Global");
  const [credits, setCredits] = useState(0);
  const [isLoading, setIsLoading] = useState(true);
  const [isSSEInProgress, setSSEInProgress] = useState(false);
  const [showReportLinks, setShowReportLinks] = useState({});
  // input limit < 500
  const [isExceedingLimit, setIsExceedingLimit] = useState(false);

  const mapCategoryToCode = (category) => {
    switch (category) {
      case "Business Idea Validation":
        return "BV";
      case "Advertisement Validation":
        return "AV";
      case "Content Idea Validation":
        return "CV";
      default:
        return "BV";
    }
  };

  const handleInputChange = (e) => {
    const inputText = e.target.value;
    if (inputText.length <= 500) {
      setIdea(inputText);
      setIsExceedingLimit(false); // Reset the limit exceeding warning
    } else {
      // Here, ideally, we don't set the new value because it exceeds the limit
      // and we possibly show a warning message
      setIsExceedingLimit(true);
    }
  };

  const handlePaste = (e) => {
    const pastedText = e.clipboardData.getData("text");
    const newText = idea + pastedText;
    if (newText.length > 500) {
      e.preventDefault(); // Prevent the paste
      setIsExceedingLimit(true);
    }
  };

  const mapRegionToCode = (region) => {
    switch (region) {
      case "Global":
        return "Global";
      case "United States":
        return "US";
      case "Canada":
        return "CA";
      case "Mexico":
        return "MX";
      case "Brazil":
        return "BR";
      case "Argentina":
        return "AR";
      case "Rest of South America":
        return "RSA";
      case "United Kingdom":
        return "UK";
      case "France":
        return "FR";
      case "Germany":
        return "DE";
      case "Sweden":
        return "SE";
      case "Denmark":
        return "DK";
      case "Poland":
        return "PL";
      case "Russia":
        return "RU";
      case "China":
        return "CN";
      case "Japan":
        return "JP";
      case "India":
        return "IN";
      case "Singapore":
        return "SG";
      case "Indonesia":
        return "ID";
      case "UAE":
        return "UAE";
      case "Saudi Arabia":
        return "SA";
      case "Rest of MENA":
        return "RM";
      case "South Africa":
        return "ZA";
      case "Nigeria":
        return "NG";
      case "Rest of Sub-Saharan Africa":
        return "RSAF";
      case "Australia":
        return "AU";
      case "New Zealand":
        return "NZ";
      case "Central Asia":
        return "CAZ";
      case "Rest of Eastern Europe":
        return "REE";
      case "Rest of Southeast Asia":
        return "RSEA";
      default:
        return "Global"; // Defaulting to Global for simplicity
    }
  };

  const fetchUserCredits = async () => {
    const token = localStorage.getItem("access");
    const bearer_token = "Bearer " + token;

    try {
      const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}get-user-credits/`, {
        method: "GET",
        headers: {
          Authorization: bearer_token,
        },
      });
      const data = await response.json();
      setCredits(data.question_credits);
      setIsLoading(false);
    } catch (error) {
      console.error("Error fetching user credits:", error);
      setIsLoading(false);
    }
  };

  useEffect(() => {
    setCategories(["Business Idea Validation", "Content Idea Validation", "Advertisement Validation"]);

    const fetchCharacters = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_API_BASE_URL}character/`);

        if (response.data && Array.isArray(response.data)) {
          setCharacters(response.data);
          if (credits < 4 && response.data.length >= 2) {
            setSelectedCharacters([response.data[1].id]);
          }
        }
      } catch (error) {
        console.error("Error fetching characters:", error);
        // Handle error appropriately (e.g., show an error message to the user)
      }
    };

    fetchCharacters();

    switch (selectedCategory) {
      case "Business Idea Validation":
        const businessQuestions = [
          "Are you interested in the idea?",
          "Are you willing to pay for this idea?",
          "Is there anything that you feel is missing from this idea?",
          "What are the drawbacks or limitations of this idea?",
          "How likely are you to use this product/service?",
        ];
        setGroupQuestions(businessQuestions);
        setSelectedGroupQuestions(["Are you interested in the idea?"]); // Select only the first question by default
        // setSelectedGroupQuestions(businessQuestions); // auto-select all

        // setHeadline("Idea Validation");
        setHeadline("Instant Feedback");
        // setSubheadline("Polish  and fine-tune your business ideas");
        // setSubheadline("Know What People Might Say About Your  Business Idea");
        setSubheadline("Uncover Concerns About Your Business Idea ");
        break;

        const contentQuestions = [
          "Does the content text maintain your interest and engagement throughout?",
          "Are there any changes or improvements you would suggest for the content text to make it more effective or useful?",
          "Are there any parts of the content text that are confusing or difficult to follow?",
          "Does the content text provide new or valuable information that you did not already know?",
        ];
        setGroupQuestions(contentQuestions);
        setSelectedGroupQuestions(contentQuestions); // auto-select all

        setHeadline("Content Validation by AI");
        setSubheadline("Make your content bulletproof. Confirm its worth.");

        break;
      default:
        setGroupQuestions([]);
        break;
    }
  }, [selectedCategory, setFeedbacks]);

  useEffect(() => {
    if (credits < 4 && groupQuestions.length > 0) {
      setSelectedGroupQuestions([groupQuestions[0]]);
    }
  }, [credits, groupQuestions]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setDropdownOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  useEffect(() => {
    const token = localStorage.getItem("access");

    if (!token) {
      window.location.href = "/signin/";
    } else {
      // Check token expiry if using JWT
      try {
        const decodedToken = JSON.parse(atob(token.split(".")[1]));
        const currentTimestamp = Math.floor(Date.now() / 1000); // current time in seconds since epoch

        if (decodedToken.exp && decodedToken.exp < currentTimestamp) {
          localStorage.removeItem("access"); // remove the expired token
          window.location.href = "/signin/";
        } else {
          //   setUser(true);
          fetchUserCredits();
        }
      } catch (error) {
        // Failed to decode token or other error
        console.error("Failed to decode the token:", error);
        window.location.href = "/signin/";
      }
    }
  }, []);

  const addPrivateQuestion = () => {
    setPrivateQuestions((prev) => [...prev, ""]);
  };

  const handlePrivateQuestionChange = (index, value) => {
    const updatedQuestions = [...privateQuestions];
    updatedQuestions[index] = value;
    setPrivateQuestions(updatedQuestions);
  };

  const createFeedback = async () => {
    const token = localStorage.getItem("access");
    const bearer_token = "Bearer " + token;
    const url = `${process.env.REACT_APP_API_BASE_URL}feedback/`;
    const payload = {
      input: idea,
      // category: mapCategoryToCode(selectedCategory),
      category: "BV",
      region: mapRegionToCode(selectedRegion),
      questions: [...selectedGroupQuestions, ...privateQuestions]
        .filter((q) => q.trim() !== "") // This will filter out empty or just whitespace questions
        .map((q) => ({ text: q })),
      characters: selectedCharacters,
    };

    try {
      const response = await fetch(url, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: bearer_token,
        },
        body: JSON.stringify(payload),
      });

      const data = await response.json();
      // Handle "Not enough question credits" error
      if (data.error && data.error === "Not enough question credits.") {
        alert("Not enough question credits. Please recharge your account or reduce question or characters.");
        return; // Exit the function to avoid further processing
      }
      const feedbackId = data.id; // Assuming the backend returns the feedback ID

      // Handle response and store idea with timestamp
      if (data && !data.error) {
        const ideaKey = `idea-${Date.now()}`;
        const ideaData = { storedIdea: idea, timestamp: Date.now() };
        setIdeaKey(ideaKey);
        localStorage.setItem(ideaKey, JSON.stringify(ideaData));
        // console.log("Idea stored with timestamp:", ideaKey, ideaData);
      }

      // Empty the questions after sending
      setPrivateQuestions([""]); // Reset to initial state with an empty string
      setSelectedGroupQuestions([]); // Reset to an empty array
      setIdea(""); // Reset the input
      // Clear previous feedbacks
      setFeedbacks({});

      listenForFeedbackUpdates(feedbackId);
      // Set isSSEInProgress to true here
      setSSEInProgress(true);
      // Fetch user credits after successful submission
      fetchUserCredits();
    } catch (error) {
      console.error("Error creating feedback:", error);
    }
  };

  const listenForFeedbackUpdates = async (feedbackId) => {
    const token = localStorage.getItem("access");
    const bearer_token = "Bearer " + token;
    const url = `${process.env.REACT_APP_API_BASE_URL}feedback/${feedbackId}/updates/`;

    try {
      const response = await fetch(url, {
        headers: {
          Authorization: bearer_token,
        },
      });

      const contentType = response.headers.get("content-type");

      if (contentType && contentType.startsWith("application/json")) {
        const data = await response.json();
        const feedbackByCharacter = {};
        data.forEach((feedback) => {
          if (!feedbackByCharacter[feedback.character]) {
            feedbackByCharacter[feedback.character] = [];
          }
          feedbackByCharacter[feedback.character].push(feedback);
        });
        setFeedbacks(feedbackByCharacter);
      } else if (contentType && contentType.startsWith("text/event-stream")) {
        const eventSource = new EventSource(url, {
          headers: {
            Authorization: bearer_token,
          },
        });

        eventSource.onmessage = (event) => {
          const newFeedback = JSON.parse(event.data);

          if (newFeedback.end_of_stream) {
            eventSource.close();
            // Set isSSEInProgress to false once the SSE completes
            setSSEInProgress(false);
            return;
          }

          setFeedbacks((prevFeedbacks) => {
            const feedbackByCharacter = { ...prevFeedbacks };

            // Ensure the character has an entry; if not, create one.
            if (!feedbackByCharacter[newFeedback.character]) {
              feedbackByCharacter[newFeedback.character] = [];
            }

            // Find if there's existing feedback for the same character and question.
            const existingFeedbackIndex = feedbackByCharacter[newFeedback.character].findIndex((f) => f.question === newFeedback.question);

            if (existingFeedbackIndex > -1) {
              const existingFeedback = feedbackByCharacter[newFeedback.character][existingFeedbackIndex];

              // Instead of appending, set the latest feedback as the new response.
              existingFeedback.response = newFeedback.response;
            } else {
              // If no feedback exists for the character's specific question, add the new feedback.
              feedbackByCharacter[newFeedback.character].push(newFeedback);
            }

            return feedbackByCharacter;
          });
        };

        eventSource.onerror = (error) => {
          console.error("Error with SSE:", error);
          setSSEInProgress(false); // Reset if there's an error
          eventSource.close();
        };
      } else {
        console.warn("Unknown content type:", contentType);
      }
    } catch (error) {
      setSSEInProgress(false); // Reset if there's an error outside of the event listeners
      console.error("Error while listening for updates:", error);
    }
  };

  const removePrivateQuestion = (index) => {
    setPrivateQuestions((prev) => prev.filter((_, idx) => idx !== index));
  };

  const truncateDetails = (str, length = 80) => {
    if (str.length <= length) return str;
    return str.slice(0, length) + "...";
  };

  const hasFeedback = (character) => {
    return selectedCharacters.includes(character.id) && feedbacks[character.name] && feedbacks[character.name].length > 0;
  };

  const sortedCharacters = characters.sort((a, b) => {
    return hasFeedback(b) - hasFeedback(a);
  });

  useEffect(() => {
    // Set a timeout for each character when feedback starts
    characters.forEach((character) => {
      if (selectedCharacters.includes(character.id)) {
        setTimeout(() => {
          setShowReportLinks((prev) => ({ ...prev, [character.name]: true }));
        }, 15000); // 15 seconds delay
      }
    });
  }, [selectedCharacters, characters]);

  const CreditDisplay = () => (
    <div className="flex items-center justify-between bg-white rounded-lg shadow-sm p-4 border border-gray-200">
      {isLoading ? (
        <div className="flex items-center space-x-2">
          <div className="w-4 h-4 rounded-full border-2 border-green-500 border-t-transparent animate-spin"></div>
          <span className="text-gray-600">Loading credits...</span>
        </div>
      ) : (
        <>
          <div className="flex items-center space-x-3">
            <div className="flex h-10 w-10 items-center justify-center rounded-full bg-green-100">
              <span className="text-green-600 text-lg font-bold">{credits}</span>
            </div>
            <div>
              <p className={`font-medium ${credits <= 0 ? "text-red-600" : "text-gray-800"}`}>Credits Available</p>
              <p className="text-xs text-gray-500">1 credit = 1 character response</p>
            </div>
          </div>

          {credits === 0 && (
            <button
              onClick={() => (window.location.href = "/credits/")}
              className="px-4 py-2 bg-green-600 hover:bg-green-700 text-white rounded-lg shadow-sm transition-colors duration-200 focus:outline-none focus:ring-2 focus:ring-green-500 focus:ring-opacity-50"
            >
              Recharge Now
            </button>
          )}
        </>
      )}
    </div>
  );

  return (
    <div className="min-h-screen bg-white">
      {/* <TopBanner /> */}
      {/* <DashboardHeader /> */}
      {/* Simple Header - Matching the dashboard page */}
      <header className="h-16 border-b border-gray-100 flex items-center px-8">
        <div className="max-w-6xl w-full mx-auto flex justify-between">
          <span className="font-medium text-xl text-green-600">FeedbackbyAI</span>
          <Link to="/dashboard/" className="text-gray-500 hover:text-gray-700">
            Back to Dashboard
          </Link>
        </div>
      </header>

      <main className="max-w-6xl mx-auto px-6 py-12">
        {/* Header Section */}
        <div className="flex flex-col items-center justify-center mb-10">
          <div className="w-20 h-20 bg-green-50 rounded-full flex items-center justify-center mb-6">
            <MessageSquare className="w-10 h-10 text-green-600" />
          </div>
          <h1 className="text-3xl font-bold text-gray-900 text-center mb-2">AI Character Feedback</h1>
          <p className="text-center text-gray-600 max-w-2xl mx-auto">
            Run AI interviews with your target customers to get valuable insights about your business idea
          </p>
        </div>

        {/* Main Form Card */}
        <div className="bg-white rounded-2xl shadow-xl border border-gray-100 p-8 mb-12">
          <div className="space-y-6">
            {/* Idea Input */}
            <div>
              <div className="flex justify-between items-center mb-2">
                <label className="block text-sm font-medium text-gray-700">
                  Enter Your Idea Here<span className="text-green-600">*</span>
                </label>
                <span className={`text-sm ${idea.length > 400 ? "text-orange-500" : "text-gray-500"}`}>{idea.length}/500</span>
              </div>
              <textarea
                className={`w-full p-4 text-gray-700 bg-gray-50 border ${
                  isExceedingLimit ? "border-red-400" : "border-gray-300"
                } rounded-lg focus:ring-2 focus:ring-green-500 focus:border-transparent transition-all duration-200`}
                placeholder="I am building an app which lets you rent..."
                value={idea}
                onChange={handleInputChange}
                onPaste={handlePaste}
                rows={4}
                maxLength={500}
              ></textarea>
              {isExceedingLimit && (
                <div className="flex items-center mt-2 text-red-500 text-sm">
                  <AlertCircle size={16} className="mr-2" />
                  Your idea exceeds the 500 characters limit.
                </div>
              )}
            </div>

            {/* Region Selection */}
            <div>
              <label className="block text-sm font-medium text-gray-700 mb-2">
                Target Market You're Building For<span className="text-green-600">*</span>
              </label>
              <div className="relative">
                <select
                  className="block w-full pl-3 pr-10 py-3 text-base border-gray-300 focus:ring-green-500 focus:border-green-500 rounded-lg appearance-none bg-white"
                  value={selectedRegion}
                  onChange={(e) => setSelectedRegion(e.target.value)}
                >
                  <option value="">--Select Region--</option>
                  {REGIONS.map((region, index) => (
                    <option key={index} value={region}>
                      {REGION_FLAGS[region]} {region}
                    </option>
                  ))}
                </select>
                <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
                  <ChevronDown className="h-4 w-4" />
                </div>
              </div>
            </div>

            {/* Character Selection */}
            <div>
              <label className="block text-sm font-medium text-gray-700 mb-2">
                Whose Feedback You Need<span className="text-green-600">*</span>
                <span className="text-sm text-gray-500 font-normal ml-2">
                  (e.g., remote workers, software developers, investors, online trolls)
                </span>
              </label>
              <div className="relative" ref={dropdownRef}>
                <button
                  onClick={() => setDropdownOpen(!isDropdownOpen)}
                  className="w-full flex items-center justify-between px-4 py-3 text-left border border-gray-300 rounded-lg bg-white"
                >
                  <span className="text-gray-700">
                    {selectedCharacters.length ? `${selectedCharacters.length} Characters Selected` : "Select Characters"}
                  </span>
                  <ChevronDown className="h-4 w-4 text-gray-500" />
                </button>

                {isDropdownOpen && (
                  <div className="absolute z-10 w-full mt-1 bg-white border border-gray-200 rounded-lg shadow-lg overflow-y-auto max-h-64">
                    <div className="p-2 border-b border-gray-100 bg-gray-50">
                      <p className="text-xs text-gray-500 font-medium">Select the characters whose feedback you want to receive</p>
                    </div>
                    <div className="py-1">
                      {characters.map((character) => (
                        <label
                          key={character.id}
                          className={`flex items-center px-4 py-3 hover:bg-gray-50 cursor-pointer ${
                            credits < 4 && !selectedCharacters.includes(character.id) ? "opacity-60" : ""
                          }`}
                        >
                          <input
                            type="checkbox"
                            className="h-4 w-4 rounded border-gray-300 text-green-600 focus:ring-green-500"
                            checked={selectedCharacters.includes(character.id)}
                            onChange={() => {
                              if (credits >= 4 || selectedCharacters.includes(character.id)) {
                                const updated = [...selectedCharacters];
                                if (selectedCharacters.includes(character.id)) {
                                  const index = updated.indexOf(character.id);
                                  updated.splice(index, 1);
                                } else {
                                  updated.push(character.id);
                                }
                                setSelectedCharacters(updated);
                              }
                            }}
                            disabled={credits < 4 && !selectedCharacters.includes(character.id)}
                          />
                          <div className="ml-3">
                            <div className="flex items-center">
                              <span className="text-xl mr-2">{character.emoji}</span>
                              <span className="font-medium text-gray-800">{character.name}</span>
                              {credits < 4 && !selectedCharacters.includes(character.id) && (
                                <span className="ml-2 px-2 py-0.5 text-xs font-medium bg-gray-100 text-gray-600 rounded">Pro</span>
                              )}
                            </div>
                            <p className="text-xs text-gray-500 mt-0.5">{truncateDetails(character.details, 60)}</p>
                          </div>
                        </label>
                      ))}
                    </div>
                  </div>
                )}
              </div>
            </div>

            {/* Questions Section */}
            <div>
              <label className="block text-sm font-medium text-gray-700 mb-2">
                Questions to Ask<span className="text-green-600">*</span>
              </label>
              <div className="bg-gray-50 rounded-lg p-4 space-y-2 border border-gray-200">
                {groupQuestions.map((q, idx) => (
                  <div
                    key={idx}
                    className={`flex items-start p-2 rounded-md ${
                      selectedGroupQuestions.includes(q) ? "bg-green-50 border border-green-100" : "bg-white border border-gray-100"
                    }`}
                  >
                    <input
                      type="checkbox"
                      id={`gq-${idx}`}
                      className="h-4 w-4 mt-1 rounded border-gray-300 text-green-600 focus:ring-green-500"
                      checked={selectedGroupQuestions.includes(q)}
                      onChange={() => {
                        if (credits >= 4 || q === groupQuestions[0]) {
                          if (selectedGroupQuestions.includes(q)) {
                            setSelectedGroupQuestions((prev) => prev.filter((question) => question !== q));
                          } else {
                            setSelectedGroupQuestions((prev) => [...prev, q]);
                          }
                        }
                      }}
                      disabled={credits < 4 && q !== groupQuestions[0]}
                    />
                    <label
                      htmlFor={`gq-${idx}`}
                      className={`ml-3 ${credits < 4 && q !== groupQuestions[0] ? "text-gray-400" : "text-gray-700"}`}
                    >
                      <div className="font-medium">{q}</div>
                      {idx === 0 && <p className="text-xs text-gray-500 mt-1">This question will be asked to all selected characters</p>}
                    </label>
                    {idx !== 0 && credits < 4 && (
                      <span className="ml-auto px-2 py-0.5 text-xs font-medium bg-gray-100 text-gray-600 rounded">Pro</span>
                    )}
                  </div>
                ))}
              </div>
            </div>

            {/* Custom Questions Section */}
            <div>
              <div className="flex justify-between items-center mb-2">
                <label className="block text-sm font-medium text-gray-700">
                  Create Your Own Questions
                  {credits < 4 && (
                    <span className="ml-2 px-2 py-0.5 text-xs font-medium bg-gray-100 text-gray-600 rounded">Pro feature</span>
                  )}
                </label>
                <button
                  className={`flex items-center text-sm font-medium ${
                    credits >= 4 ? "text-green-600 hover:text-green-700" : "text-gray-400 cursor-not-allowed"
                  }`}
                  onClick={() => {
                    if (credits >= 4) {
                      addPrivateQuestion();
                    }
                  }}
                  disabled={credits < 4}
                >
                  <PlusCircle size={16} className="mr-1" />
                  Add Question
                </button>
              </div>

              <div className="space-y-3">
                {privateQuestions.map((q, index) => (
                  <div key={index} className="flex items-center">
                    <input
                      type="text"
                      className={`flex-grow p-3 border ${
                        credits < 4 ? "bg-gray-100 border-gray-200" : "border-gray-300 focus:ring-green-500 focus:border-green-500"
                      } rounded-lg`}
                      placeholder={`Custom question ${index + 1}`}
                      value={q}
                      onChange={(e) => handlePrivateQuestionChange(index, e.target.value)}
                      disabled={credits < 4}
                    />
                    <button
                      className="ml-2 text-gray-500 hover:text-red-500"
                      onClick={() => removePrivateQuestion(index)}
                      disabled={credits < 4}
                    >
                      <X size={20} />
                    </button>
                  </div>
                ))}
              </div>
            </div>

            {/* Credits Display */}
            <div className="flex items-center justify-between bg-white rounded-lg shadow-sm p-4 border border-gray-200">
              {isLoading ? (
                <div className="flex items-center space-x-2">
                  <div className="w-4 h-4 rounded-full border-2 border-green-500 border-t-transparent animate-spin"></div>
                  <span className="text-gray-600">Loading credits...</span>
                </div>
              ) : (
                <>
                  <div className="flex items-center space-x-3">
                    <div className="flex h-10 w-10 items-center justify-center rounded-full bg-green-100">
                      <span className="text-green-600 text-lg font-bold">{credits}</span>
                    </div>
                    <div>
                      <p className={`font-medium ${credits <= 0 ? "text-red-600" : "text-gray-800"}`}>Credits Available</p>
                      <p className="text-xs text-gray-500">1 credit = 1 character response</p>
                    </div>
                  </div>

                  {credits === 0 && (
                    <button
                      onClick={() => (window.location.href = "/credits/")}
                      className="px-4 py-2 bg-green-600 hover:bg-green-700 text-white rounded-lg font-medium transition-colors"
                    >
                      Recharge Now
                    </button>
                  )}
                </>
              )}
            </div>

            {/* Submit Button */}
            <div className="flex justify-end">
              <button
                className={`flex items-center px-6 py-3 rounded-lg shadow-sm text-white font-medium text-lg transition-all duration-200 ${
                  idea && selectedCharacters.length > 0 && credits > 0
                    ? "bg-green-600 hover:bg-green-700"
                    : "bg-gray-300 cursor-not-allowed"
                }`}
                onClick={createFeedback}
                disabled={!(idea && selectedCharacters.length > 0 && credits > 0)}
              >
                <Send size={20} className="mr-2" />
                Get Feedback
              </button>
            </div>
          </div>
        </div>

        {/* Results Section */}
        {Object.keys(feedbacks).length > 0 && (
          <div className="bg-white rounded-2xl shadow-xl border border-gray-100 p-8">
            <h2 className="text-xl font-bold text-gray-800 mb-6">Character Feedback</h2>
            <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
              {sortedCharacters.map((character, index) => (
                <div
                  key={character.id}
                  className={`rounded-xl shadow-sm transition-all duration-200 overflow-hidden ${
                    selectedCharacters.includes(character.id) && feedbacks[character.name] && feedbacks[character.name].length !== 0
                      ? `${getBackgroundClass(index)} border`
                      : "bg-gray-50 border border-gray-200 opacity-50"
                  }`}
                >
                  {/* Character Header */}
                  <div className="px-5 py-4 bg-white bg-opacity-80 border-b border-gray-200">
                    <div className="flex items-center">
                      <div className="text-2xl mr-3">{character.emoji}</div>
                      <div>
                        <h3 className="font-bold text-lg text-gray-900">{character.name}</h3>
                        <p className="text-xs text-gray-500">{truncateDetails(character.details, 100)}</p>
                      </div>
                    </div>
                  </div>

                  {/* Feedback Content */}
                  <div className="p-5">
                    {selectedCharacters.includes(character.id) ? (
                      !feedbacks[character.name] ? (
                        isSSEInProgress && (
                          <div className="flex items-center justify-center py-8">
                            <div className="flex items-center space-x-2">
                              <div className="w-2 h-2 bg-gray-600 rounded-full animate-bounce" style={{ animationDelay: "0s" }}></div>
                              <div className="w-2 h-2 bg-gray-600 rounded-full animate-bounce" style={{ animationDelay: "0.2s" }}></div>
                              <div className="w-2 h-2 bg-gray-600 rounded-full animate-bounce" style={{ animationDelay: "0.4s" }}></div>
                            </div>
                            <p className="ml-3 text-gray-600 italic">Generating feedback...</p>
                          </div>
                        )
                      ) : (
                        <div className="space-y-4">
                          {feedbacks[character.name].map((feedback, idx) => (
                            <div key={idx} className="pb-4 border-b border-gray-200 last:border-b-0">
                              <div className="font-semibold text-gray-800 mb-2">{feedback.question}</div>
                              <div className="prose prose-sm max-w-none text-gray-700">
                                <ReactMarkdown>{feedback.response}</ReactMarkdown>
                              </div>
                            </div>
                          ))}

                          {/* Report Link */}
                          {showReportLinks[character.name] && (
                            <a
                              href={`/report/?ideaKey=${ideaKey}`}
                              className="mt-4 block px-4 py-3 bg-green-50 border border-green-200 rounded-lg text-green-700 font-semibold hover:bg-green-100 transition-colors duration-200 text-center"
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              <ArrowRight size={18} className="inline-block mr-2" />
                              Improve Your Business Idea
                            </a>
                          )}
                        </div>
                      )
                    ) : (
                      <div className="py-6 text-center text-gray-500">
                        <User size={32} className="mx-auto mb-2 text-gray-400" />
                        This character is not selected
                      </div>
                    )}
                  </div>
                </div>
              ))}
            </div>
          </div>
        )}
      </main>
    </div>
  );
}

export default FeedbackAssistant;
