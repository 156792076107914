import React, { useState, useEffect, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import {
  Search,
  TrendingUp,
  MessageSquare,
  Filter,
  ChevronDown,
  Bookmark,
  ArrowRight,
  Clock,
  ChevronUp,
  RefreshCw,
  AlertCircle,
  Pencil,
  X,
  Info,
  Sparkles,
  Loader2,
  Zap,
  Tag, // For category tag
  Tags, // For keyword tags
  AlertTriangle, // For Core Problem (Example)
  Target, // For Underserved Gap (Example)
  BarChart, // For Problem Evidence (Example)
  Lightbulb, // For Solution Concept (Example)
  Compass, // For Strategic Angle (Example)
  Disc, // For default bullet points
} from "lucide-react";
import axios from "./axiosConfig";
import { motion, AnimatePresence } from "framer-motion";
import { formatDistanceToNow } from "date-fns";
import { Link } from "react-router-dom";
import { InsightCard } from "./InsightCard";

// Component for source badges
const SourceBadge = ({ source }) => {
  const getSourceInfo = (source) => {
    switch (source) {
      case "twitter":
        return {
          icon: <MessageSquare className="h-3 w-3 mr-1" />,
          label: "Twitter",
          bgColor: "bg-blue-100",
          textColor: "text-blue-800",
        };
      case "reddit":
        return {
          icon: <MessageSquare className="h-3 w-3 mr-1" />,
          label: "Reddit",
          bgColor: "bg-orange-100",
          textColor: "text-orange-800",
        };
      case "linkedin":
        return {
          icon: <MessageSquare className="h-3 w-3 mr-1" />,
          label: "LinkedIn",
          bgColor: "bg-blue-100",
          textColor: "text-blue-900",
        };
      default:
        return {
          icon: <MessageSquare className="h-3 w-3 mr-1" />,
          label: source,
          bgColor: "bg-gray-100",
          textColor: "text-gray-800",
        };
    }
  };

  // Handle both object and string source formats
  const sourceInfo = getSourceInfo(typeof source === "object" ? source.name : source);
  const { icon, label, bgColor, textColor } = sourceInfo;

  return (
    <span className={`inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium ${bgColor} ${textColor}`}>
      {icon}
      {label}
    </span>
  );
};

const FeatureIcon = ({ Icon, text }) => (
  <div className="flex items-center space-x-2 text-xl">
    <Icon className="w-5 h-5" />
    <span className="text-xl">{text}</span>
  </div>
);

const InsightsTab = () => {
  const [isNewUser, setIsNewUser] = useState(true);
  const [activeTab, setActiveTab] = useState("insights");
  const [showUpgradeModal, setShowUpgradeModal] = useState(false);
  const [userDetails, setUserDetails] = useState(null);
  const [isPremium, setIsPremium] = useState(false);
  const [monitoringConfig, setMonitoringConfig] = useState({
    keywords: "",
    frequency: "daily",
    sources: [],
    notifications: true,
  });
  const [isGeneratingInsights, setIsGeneratingInsights] = useState(false);
  const [generationProgress, setGenerationProgress] = useState(0);
  const [insights, setInsights] = useState([]);
  const [nextPageUrl, setNextPageUrl] = useState(null);
  const [loadingMore, setLoadingMore] = useState(false);
  const [expandedCards, setExpandedCards] = useState([]);
  const [currentPlan, setCurrentPlan] = useState("Free");
  const [showKeywordModal, setShowKeywordModal] = useState(false);
  const [likedPainPoints, setLikedPainPoints] = useState(new Set());
  const [bookmarkedPainPoints, setBookmarkedPainPoints] = useState(new Set());
  const [insightCredits, setInsightCredits] = useState(0);
  const [lastUpdated, setLastUpdated] = useState("");
  const [searchQuery, setSearchQuery] = useState("");
  const [showFilters, setShowFilters] = useState(false);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const navigate = useNavigate();

  const suggestedKeywords = ["sustainable packaging", "remote work tools", "business networking"];

  // Fetch user details including credits
  const fetchUserDetails = async () => {
    try {
      setLoading(true);
      const response = await axios.get("/get-user-credits/");
      setUserDetails(response.data);
      setIsPremium(response.data.subscription_active);
      setCurrentPlan(response.data.plan_name);
      setInsightCredits(response.data.ideation_credits);
      setIsNewUser(!response.data.accessed_ideation_free_preview);
      setMonitoringConfig((prev) => ({
        ...prev,
        keywords: response.data.keywords || "",
      }));
      setLoading(false);
    } catch (error) {
      console.error("Failed to fetch user details:", error);
      setLoading(false);
      setError("Failed to load user data. Please refresh the page.");
    }
  };

  // Fetch insights
  const fetchUserInsights = async (url = "fetch-user-insights/") => {
    try {
      setLoading(true);
      const response = await axios.get(url);
      setInsights((prev) =>
        url === "fetch-user-insights/" ? response.data.results.insights : [...prev, ...response.data.results.insights]
      );

      // Set keywords from the response if available
      if (response.data.results.monitored_keywords) {
        setMonitoringConfig((prev) => ({
          ...prev,
          keywords: Array.isArray(response.data.results.monitored_keywords)
            ? response.data.results.monitored_keywords.join(", ")
            : response.data.results.monitored_keywords || "",
        }));
      }

      setNextPageUrl(response.data.next);
      setLoading(false);
    } catch (error) {
      console.error("Error fetching user insights:", error);
      setLoading(false);
      setError("Failed to load insights. Please try again later.");
    }
  };

  // Initial data loading
  useEffect(() => {
    fetchUserDetails();
    fetchUserInsights();

    // Fetch liked and bookmarked pain points
    axios
      .get("/list-liked/")
      .then((response) => {
        const likedIds = new Set(response.data.map((item) => item.id));
        setLikedPainPoints(likedIds);
      })
      .catch((error) => console.error("Error fetching liked pain points:", error));

    axios
      .get("/list-bookmarks/")
      .then((response) => {
        const bookmarkedIds = new Set(response.data.map((item) => item.id));
        setBookmarkedPainPoints(bookmarkedIds);
      })
      .catch((error) => console.error("Error fetching bookmarked pain points:", error));
  }, []);

  // Calculate last updated time
  useEffect(() => {
    if (insights && insights.length > 0) {
      // Find the most recent `created_at` timestamp
      const mostRecentInsight = insights.reduce((latest, insight) => {
        const insightDate = new Date(insight.created_at);
        return insightDate > latest ? insightDate : latest;
      }, new Date(insights[0].created_at));

      // Format the date
      const formattedDate = formatDistanceToNow(new Date(mostRecentInsight), { addSuffix: true });
      setLastUpdated(formattedDate);
    }
  }, [insights]);

  // Handle infinite scroll
  useEffect(() => {
    const handleScroll = () => {
      if (window.innerHeight + document.documentElement.scrollTop >= document.documentElement.offsetHeight - 100) {
        loadMoreInsights();
      }
    };
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, [nextPageUrl, loadingMore]);

  const loadMoreInsights = useCallback(() => {
    if (nextPageUrl && !loadingMore) {
      setLoadingMore(true);
      fetchUserInsights(nextPageUrl);
    }
  }, [nextPageUrl, loadingMore]);

  // Handle toggle expand for cards
  const toggleExpand = (index) => {
    setExpandedCards((prev) => (prev.includes(index) ? prev.filter((i) => i !== index) : [...prev, index]));
  };

  // Handle toggle like
  const handleToggleLike = async (painpointId) => {
    try {
      const response = await axios.post(`/toggle-like/${painpointId}/`);
      setLikedPainPoints((prev) => {
        const newSet = new Set(prev);
        if (response.data.liked) {
          newSet.add(painpointId);
        } else {
          newSet.delete(painpointId);
        }
        return newSet;
      });
    } catch (error) {
      console.error("Error toggling like:", error);
    }
  };

  // Handle toggle bookmark
  const handleToggleBookmark = async (painpointId) => {
    try {
      const response = await axios.post(`/toggle-bookmark/${painpointId}/`);
      setBookmarkedPainPoints((prev) => {
        const newSet = new Set(prev);
        if (response.data.bookmarked) {
          newSet.add(painpointId);
        } else {
          newSet.delete(painpointId);
        }
        return newSet;
      });
    } catch (error) {
      console.error("Error toggling bookmark:", error);
    }
  };

  // Handle new insight generation
  const handleNewInsight = async () => {
    if (insightCredits <= 0 && !isNewUser) {
      setShowUpgradeModal(true);
      return;
    }

    // Ensure we have keywords to search
    const keywords = monitoringConfig.keywords
      .split(",")
      .map((kw) => kw.trim())
      .filter((kw) => kw.length > 0);

    if (keywords.length === 0) {
      alert("Please enter at least one valid keyword.");
      return;
    }

    setIsGeneratingInsights(true);
    setGenerationProgress(0);

    // Show progress animation
    const progressInterval = setInterval(() => {
      setGenerationProgress((prev) => (prev >= 90 ? prev : prev + 1));
    }, 1500);

    try {
      const response = await axios.post("generate-insights/", { interests: keywords });

      if (response.status === 200) {
        // Refresh user details to get updated credits
        await fetchUserDetails();

        // Refresh insights
        await fetchUserInsights();

        setGenerationProgress(100);
        setTimeout(() => {
          setIsGeneratingInsights(false);
        }, 500);
      }
    } catch (error) {
      console.error("Error generating insights:", error);
      if (error.response && error.response.status === 402) {
        setShowUpgradeModal(true);
      } else {
        alert("Something went wrong. Please try again later.");
      }
    } finally {
      clearInterval(progressInterval);
      setIsGeneratingInsights(false);
    }
  };

  // Handle monitoring setup and keyword changes
  const handleMonitoringSetup = async (e) => {
    e.preventDefault();
    handleNewInsight();
  };

  const handleConfigChange = (e) => {
    const { name, value } = e.target;
    setMonitoringConfig((prev) => ({ ...prev, [name]: value }));
  };

  const handleSearch = (e) => {
    e.preventDefault();
    if (!searchQuery.trim()) return;

    setLoading(true);
    const keywords = searchQuery
      .split(",")
      .map((kw) => kw.trim())
      .filter((kw) => kw.length > 0);

    // Update monitoring config
    setMonitoringConfig((prev) => ({ ...prev, keywords: searchQuery }));

    // Fetch insights based on search query
    axios
      .post("generate-insights/", { interests: keywords })
      .then((response) => {
        fetchUserInsights();
        setLoading(false);
      })
      .catch((error) => {
        console.error("Search error:", error);
        setLoading(false);
        if (error.response && error.response.status === 402) {
          setShowUpgradeModal(true);
        } else {
          setError("Search failed. Please try again.");
        }
      });
  };

  // Upgrade Modal Component
  const UpgradeModal = ({ show, onClose }) => {
    if (!show) return null;
    return (
      <div className="fixed inset-0 bg-black bg-opacity-50 z-50 flex items-center justify-center p-4">
        <div className="bg-white rounded-lg p-6 w-full max-w-md">
          <h3 className="text-lg font-medium leading-6 text-gray-900 mb-4">Upgrade Your Plan</h3>
          <p className="text-gray-600 mb-4">You've run out of credits. Upgrade your plan to continue generating insights!</p>
          <div className="flex justify-end space-x-2">
            <button onClick={onClose} className="w-full mt-4 text-gray-600 hover:text-gray-800 transition-colors">
              Maybe Later
            </button>
            <button
              onClick={() => {
                navigate("/credits/");
              }}
              className="w-full bg-green-600 text-white py-3 rounded-xl font-semibold hover:bg-green-700 transition-colors"
            >
              Upgrade Now
            </button>
          </div>
        </div>
      </div>
    );
  };

  return (
    <div className="space-y-8 max-w-4xl mx-auto px-4 sm:px-6 lg:px-8">
      {/* Header */}
      <header className="h-16 border-b border-gray-100 bg-white shadow-sm flex items-center px-8 sticky top-0 z-10">
        <div className="max-w-6xl w-full mx-auto flex justify-between items-center">
          <span className="font-semibold text-xl text-green-600 tracking-tight">FeedbackbyAI</span>
          <Link to="/dashboard/" className="text-gray-600 hover:text-green-600 transition-colors flex items-center">
            <ArrowRight className="h-4 w-4 mr-1 transform rotate-180" />
            Back to Dashboard
          </Link>
        </div>
      </header>
      {/* Loading/Generation Overlay */}
      <AnimatePresence>
        {isGeneratingInsights && (
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            className="fixed inset-0 bg-black bg-opacity-50 z-50 flex items-center justify-center p-4"
          >
            <motion.div
              initial={{ scale: 0.9, opacity: 0 }}
              animate={{ scale: 1, opacity: 1 }}
              exit={{ scale: 0.9, opacity: 0 }}
              className="bg-white rounded-2xl p-6 sm:p-8 w-full max-w-md shadow-xl"
            >
              <h3 className="text-xl sm:text-2xl font-semibold mb-4 text-gray-900">Generating Premium Insights</h3>
              <div className="w-full bg-gray-200 rounded-full h-3 mb-4">
                <motion.div
                  className="bg-green-600 h-3 rounded-full"
                  initial={{ width: 0 }}
                  animate={{ width: `${generationProgress}%` }}
                  transition={{ duration: 0.5 }}
                />
              </div>
              <p className="text-sm sm:text-base text-gray-600">Analyzing market trends, user needs, and potential opportunities...</p>
              <p className="text-sm text-gray-500 mt-2">This may take up to 2-3 minutes. Please don't close this window.</p>
            </motion.div>
          </motion.div>
        )}
      </AnimatePresence>
      {/* Intro Header */}
      <motion.div
        initial={{ opacity: 0, y: -20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.5 }} // Smooth transition
        // Using emerald theme consistent with other examples
        className="bg-gradient-to-br from-emerald-700 to-emerald-500 rounded-3xl p-6 sm:p-8 text-white shadow-lg hover:shadow-xl transition-shadow duration-300"
      >
        <div className="flex items-center space-x-3 mb-5">
          {/* Subtle initial scale animation instead of pulse */}
          <motion.div initial={{ scale: 0 }} animate={{ scale: 1 }} transition={{ delay: 0.2, type: "spring", stiffness: 200 }}>
            <Sparkles className="w-8 h-8 text-yellow-300" />
          </motion.div>
          <h2 className="text-2xl sm:text-3xl font-bold">Ideate</h2>
        </div>

        {/* Simplified structure if only one main description point */}
        <div className="flex items-start space-x-3">
          {" "}
          {/* Use flex instead of grid for single item */}
          <MessageSquare className="w-6 h-6 text-emerald-300 flex-shrink-0 mt-1" /> {/* Adjusted icon alignment */}
          <p className="text-emerald-100 text-base sm:text-lg">
            {" "}
            {/* Standard text size */}
            Discover high-potential business opportunities identified by AI from real-time social media conversations and market analysis.
          </p>
        </div>

        {/* If you wanted multiple points, FeatureIcon component usage was fine, just ensure correct text size */}
        {/* Example if using FeatureIcon component: */}
        {/* <div className="space-y-4">
          <FeatureIcon
             Icon={MessageSquare}
             text="Discover high-potential business opportunities identified by AI from real-time social media conversations and market analysis."
           />
           <FeatureIcon
             Icon={Zap} // Example
             text="Uncover unmet needs and feature requests users are discussing right now."
           />
      </div> */}
      </motion.div>
      {/* Keywords Monitoring Section */}

      <div className="bg-white rounded-2xl shadow-lg border border-gray-100 overflow-hidden">
        {/* Main Content Padding */}
        <div className="p-6 sm:p-8">
          {/* Title */}
          <h2 className="text-2xl font-semibold text-gray-900 mb-6">Topic/Industry to Analyze</h2>

          {/* Keyword Display & Edit Button */}
          <div className="mb-6">
            <label className="block text-sm font-medium text-gray-700 mb-2">Enter a single topic or industry</label>
            <button
              type="button" // Explicitly type button
              onClick={() => setShowKeywordModal(true)}
              className="w-full flex items-center justify-between bg-gray-50 border border-gray-200 rounded-xl py-3 px-4 text-left text-gray-700 hover:border-emerald-300 hover:bg-emerald-50 focus:outline-none focus:ring-2 focus:ring-emerald-500 focus:ring-offset-2 transition-all duration-200"
              aria-label="Edit topic focus" // Accessibility
            >
              <span className="truncate">
                {" "}
                {/* Handle long keywords */}
                {monitoringConfig.keywords || "Enter a single topic or industry"}
              </span>
              <Pencil size={18} className="text-gray-400 group-hover:text-emerald-600 ml-2 flex-shrink-0" />
            </button>
          </div>

          {/* Example Keywords Info Box */}
          <div className="bg-emerald-50/60 border border-emerald-100 rounded-xl p-4 mb-6 flex items-start space-x-3">
            <Info size={20} className="text-emerald-500 flex-shrink-0 mt-0.5" />
            <div>
              <p className="text-sm text-emerald-800 font-medium mb-2">Examples:</p>
              <div className="flex flex-wrap gap-2">
                {suggestedKeywords.map((keyword) => (
                  <span
                    key={keyword}
                    className="bg-emerald-100 text-emerald-800 px-2.5 py-0.5 rounded-full text-xs font-medium cursor-default"
                  >
                    {keyword}
                  </span>
                ))}
              </div>
            </div>
          </div>

          {/* Action Button */}
          <button
            type="button" // Explicitly type button
            className="w-full flex items-center justify-center bg-gradient-to-r from-emerald-500 to-emerald-600 hover:from-emerald-600 hover:to-emerald-700 text-white text-base font-medium py-3.5 px-6 rounded-xl transition-all duration-300 focus:outline-none focus:ring-2 focus:ring-emerald-500 focus:ring-offset-2 shadow-md disabled:opacity-60 disabled:cursor-not-allowed" // Clearer disabled style
            onClick={handleNewInsight}
            disabled={isGeneratingInsights || insightCredits === 0} // Disable if 0 credits too? Maybe add tooltip
            title={insightCredits === 0 ? "Requires Insight Credits" : "Find Opportunities"} // Tooltip for disabled state
          >
            <Sparkles size={18} className={`mr-2 ${isGeneratingInsights ? "animate-spin" : ""}`} /> {/* Optional spin */}
            {isGeneratingInsights ? "Generating Opportunities..." : "Find Opportunities"}
          </button>
        </div>{" "}
        {/* End Main Content Padding */}
        {/* Footer for Credits & Plan Info */}
        <div className="bg-gray-50 px-6 sm:px-8 py-4 flex flex-col sm:flex-row justify-between items-center space-y-1 sm:space-y-0 border-t border-gray-200">
          <div className="text-sm">
            <span className="text-gray-500">Insight Credits Available: </span>
            <span className="font-semibold text-gray-900">{insightCredits ?? "N/A"}</span>
            {/* Optional: Add upgrade link if 0 credits */}
            {insightCredits === 0 && (
              <Link
                to="/credits/"
                className="ml-2 text-xs text-emerald-600 hover:text-emerald-800 font-medium focus:outline-none focus:ring-1 focus:ring-emerald-500 rounded"
              >
                (Get More)
              </Link>
            )}
          </div>
          <div className="text-sm">
            <span className="text-gray-500">Current Plan: </span>
            <span className="font-semibold text-gray-900">{currentPlan ?? "N/A"}</span>
          </div>
        </div>
      </div>
      {/* Insights Display Section */}
      {loading && !isGeneratingInsights ? (
        <div className="flex flex-col justify-center items-center py-12">
          <Loader2 className="h-10 w-10 text-green-600 animate-spin mb-3" />
          <span className="text-gray-600">Loading insights...</span>
        </div>
      ) : error ? (
        <div className="bg-red-50 border border-red-200 rounded-md p-4 mb-6">
          <div className="flex">
            <AlertCircle className="h-5 w-5 text-red-500 mr-2" />
            <p className="text-red-700">{error}</p>
          </div>
        </div>
      ) : insights && insights.length > 0 ? (
        <>
          <h3 className="text-2xl font-bold text-gray-900 mb-6">Your AI-Discovered Opportunities</h3>
          {lastUpdated && (
            <p className="text-gray-500 text-sm mb-4">
              Showing newest results. Last Updated: <span className="font-medium">{lastUpdated}</span>
            </p>
          )}
          <div className="space-y-8">
            {insights.map((insight, index) => (
              <InsightCard
                key={insight.id} // Use a stable unique ID for the key
                insight={insight} // Pass the full insight object
                index={index} // Pass the index for animation delay & expansion state
                expandedCards={expandedCards} // Pass expansion state
                toggleExpand={toggleExpand} // Pass handler function
                bookmarkedPainPoints={bookmarkedPainPoints} // Pass bookmark state
                handleToggleBookmark={handleToggleBookmark} // Pass handler function
              />
            ))}
          </div>

          {loadingMore && (
            <div className="text-center py-4">
              <Loader2 className="animate-spin mx-auto" size={24} />
              <p className="text-gray-600 mt-2">Loading more insights...</p>
            </div>
          )}
        </>
      ) : (
        <div className="text-center py-12 bg-white rounded-xl shadow-md border border-gray-200">
          <div className="rounded-full bg-gray-100 p-4 inline-block mx-auto mb-4">
            <Search className="h-8 w-8 text-gray-400" />
          </div>
          <h3 className="text-xl font-medium text-gray-900 mb-2">No insights available</h3>
          <p className="text-gray-500 mb-5 max-w-md mx-auto">Start monitoring a trend or keyword to generate insights.</p>
        </div>
      )}
      {/* Keyword Modal */}
      {showKeywordModal && (
        <div className="fixed inset-0 bg-black bg-opacity-50 z-50 flex items-center justify-center p-4">
          <div className="bg-white rounded-lg p-6 w-full max-w-md">
            <h3 className="text-lg font-medium leading-6 text-gray-900 mb-4">Set Topic/Industry</h3>
            <input
              type="text"
              value={monitoringConfig.keywords}
              onChange={handleConfigChange}
              name="keywords"
              className="w-full px-3 py-2 text-gray-700 border rounded-lg focus:outline-none focus:border-green-500 mb-4"
              placeholder="Enter a single topic (e.g., AI marketing)"
            />
            <div className="flex justify-end space-x-2">
              <button
                type="button"
                className="px-4 py-2 text-sm font-medium text-gray-700 bg-gray-100 rounded-md hover:bg-gray-200 focus:outline-none focus:ring-2 focus-ring-offset-2 focus:ring-green-500"
                onClick={() => setShowKeywordModal(false)}
              >
                Cancel
              </button>
              <button
                type="button"
                className="px-4 py-2 text-sm font-medium text-white bg-green-600 rounded-md hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500"
                onClick={() => {
                  handleMonitoringSetup({ preventDefault: () => {} });
                  setShowKeywordModal(false);
                }}
              >
                Save Changes
              </button>
            </div>
          </div>
        </div>
      )}
      {/* Upgrade Modal */}
      <UpgradeModal show={showUpgradeModal} onClose={() => setShowUpgradeModal(false)} />
    </div>
  );
};

export default InsightsTab;
