import React, { useState, useEffect } from "react";
import { NavLink, Link } from "react-router-dom";

import { Menu, X, ChevronDown, Sparkles } from "lucide-react";
import Logo from "../images/thumbnail.png";
import LogoutButton from "./LogoutButton";
import { motion, AnimatePresence } from "framer-motion";

const NavItem = ({ to, children }) => (
  <NavLink
    to={to}
    className={({ isActive }) =>
      `px-4 py-2 rounded-full text-sm font-medium transition-all duration-200 ${
        isActive ? "bg-green-100 text-green-800" : "text-gray-600 hover:bg-green-50 hover:text-green-700"
      }`
    }
  >
    {children}
  </NavLink>
);

const MobileNavItem = ({ to, children, onClick }) => (
  <NavLink
    to={to}
    onClick={onClick}
    className={({ isActive }) =>
      `block px-4 py-2 text-base font-medium transition-colors duration-200 ${
        isActive ? "bg-green-100 text-green-800" : "text-gray-600 hover:bg-green-50 hover:text-green-700"
      }`
    }
  >
    {children}
  </NavLink>
);

export default function DashboardHeader() {
  const [user, setUser] = useState(null);
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    const token = localStorage.getItem("access");

    if (!token) {
      window.location.href = "/signin/";
    } else {
      try {
        const decodedToken = JSON.parse(atob(token.split(".")[1]));
        const currentTimestamp = Math.floor(Date.now() / 1000);

        if (decodedToken.exp && decodedToken.exp < currentTimestamp) {
          localStorage.removeItem("access");
          window.location.href = "/signin/";
        } else {
          setUser(true);
        }
      } catch (error) {
        console.error("Failed to decode the token:", error);
        window.location.href = "/signin/";
      }
    }
  }, []);

  return (
    <header className="bg-white shadow-sm">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="flex justify-between items-center py-4 md:justify-start md:space-x-10">
          <div className="flex justify-start lg:w-0 lg:flex-1">
            <NavLink to="/dashboard/" className="flex items-center">
              <img className="h-8 w-auto sm:h-10" src={Logo} alt="FeedbackbyAI" />
              <span className="ml-2 text-xl font-bold text-green-600">FeedbackbyAI</span>
            </NavLink>
          </div>
          <div className="-mr-2 -my-2 md:hidden">
            <button
              type="button"
              className="bg-white rounded-md p-2 inline-flex items-center justify-center text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-green-500"
              onClick={() => setIsOpen(!isOpen)}
            >
              <span className="sr-only">Open menu</span>
              <Menu className="h-6 w-6" aria-hidden="true" />
            </button>
          </div>
          <nav className="hidden md:flex space-x-6">
            <NavItem to="/feedback/">AI Character Feedback</NavItem>
            <NavItem to="/dashboard/">100+ Page AI Report</NavItem>
            <NavItem to="/launchvote/">
              Launch Your Idea
              <span className="ml-1.5 inline-flex items-center rounded-full bg-green-600 px-2 py-0.5 text-xs font-medium text-white">
                New
              </span>
            </NavItem>
            {/* <NavItem to="/aimarketgapfinder/">AI Market Gap Finder</NavItem> */}
            {/* <NavItem to="/previewx/">Sample Report</NavItem> */}
            {/* <NavItem to="/spotlighte/">
 
              SpotLight•E
              <span className="ml-1.5 inline-flex items-center rounded-full animate-pulse bg-green-100 px-2 py-0.5 text-xs font-medium text-green-800">
                <Sparkles size={12} className="mr-1" />
                New
              </span>
            </NavItem> */}
            {/* <NavItem to="/realtimeresearch/">Realtime AI Research</NavItem> */}
            {/* <NavItem to="/networking/">Business Exchange</NavItem> */}
            <NavItem to="/credits/">Credits & Recharge</NavItem>
          </nav>
          <div className="hidden md:flex items-center justify-end md:flex-1 lg:w-0">
            <a
              href="mailto:business@feedbackbyai.com"
              className="ml-8 whitespace-nowrap inline-flex items-center justify-center px-4 py-2 border border-transparent rounded-full shadow-sm text-base font-medium text-white bg-green-600 hover:bg-green-700"
            >
              Contact Us
            </a>
            {user && (
              <div className="ml-8">
                <LogoutButton setUser={setUser} />
              </div>
            )}
          </div>
        </div>
      </div>

      <AnimatePresence>
        {isOpen && (
          <motion.div
            initial={{ opacity: 0, height: 0 }}
            animate={{ opacity: 1, height: "auto" }}
            exit={{ opacity: 0, height: 0 }}
            transition={{ duration: 0.3 }}
            className="absolute top-0 inset-x-0 p-2 transition transform origin-top-right md:hidden"
          >
            <div className="rounded-lg shadow-lg ring-1 ring-black ring-opacity-5 bg-white divide-y-2 divide-gray-50">
              <div className="pt-5 pb-6 px-5">
                <div className="flex items-center justify-between">
                  <div>
                    <img className="h-8 w-auto" src={Logo} alt="FeedbackbyAI" />
                  </div>
                  <div className="-mr-2">
                    <button
                      type="button"
                      className="bg-white rounded-md p-2 inline-flex items-center justify-center text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-green-500"
                      onClick={() => setIsOpen(false)}
                    >
                      <span className="sr-only">Close menu</span>
                      <X className="h-6 w-6" aria-hidden="true" />
                    </button>
                  </div>
                </div>
                <div className="mt-6">
                  <nav className="grid gap-y-8">
                    <MobileNavItem to="/feedback/" onClick={() => setIsOpen(false)}>
                      AI Character Feedback
                    </MobileNavItem>
                    <MobileNavItem to="/dashboard/" onClick={() => setIsOpen(false)}>
                      100+ Page AI Report
                    </MobileNavItem>
                    <MobileNavItem to="/launchvote/" onClick={() => setIsOpen(false)}>
                      Launch Your Idea
                    </MobileNavItem>
                    {/* <MobileNavItem to="/aimarketgapfinder/" onClick={() => setIsOpen(false)}>
                      AI Market GapFinder
                    </MobileNavItem> */}
                    {/* <MobileNavItem to="/previewx/" onClick={() => setIsOpen(false)}>
                      Sample Report
                    </MobileNavItem> */}
                    {/* <MobileNavItem to="/spotlighte/" onClick={() => setIsOpen(false)}>
                  
                      SpotLight•E
                      <span className="ml-1.5 inline-flex items-center rounded-full bg-green-100 px-2 py-0.5 text-xs font-medium text-green-800">
                        <Sparkles size={12} className="mr-1" />
                        New
                      </span>
                    </MobileNavItem> */}
                    {/* <MobileNavItem to="/audience-finder/" onClick={() => setIsOpen(false)}>
                      AI Audience Finder
                      <span className="ml-1.5 inline-flex items-center rounded-full bg-green-100 px-2 py-0.5 text-xs font-medium text-green-800">
                        <Sparkles size={12} className="mr-1" />
                        New
                      </span>
                    </MobileNavItem> */}
                    {/* <MobileNavItem to="/networking/" onClick={() => setIsOpen(false)}>
                      Business Exchange
                    </MobileNavItem> */}
                    <MobileNavItem to="/credits/" onClick={() => setIsOpen(false)}>
                      Credits & Recharge
                    </MobileNavItem>
                  </nav>
                </div>
              </div>
              <div className="py-6 px-5 space-y-6">
                <div>
                  <a
                    href="mailto:business@feedbackbyai.com"
                    className="w-full flex items-center justify-center px-4 py-2 border border-transparent rounded-md shadow-sm text-base font-medium text-white bg-green-600 hover:bg-green-700"
                  >
                    Contact Us
                  </a>
                  {user && (
                    <div className="mt-6">
                      <LogoutButton setUser={setUser} />
                    </div>
                  )}
                </div>
              </div>
            </div>
          </motion.div>
        )}
      </AnimatePresence>
    </header>
  );
}
