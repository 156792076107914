// import React, { useState } from "react";
import { Link } from "react-router-dom";
import { GoogleAuthProvider, signInWithPopup } from "firebase/auth";
import { auth } from "../firebaseConfig"; // Ensure this path is correct
import firebase from "firebase/app";
import api from "./axiosConfig"; // Adjust the path as necessary
// import { FcGoogle } from "react-icons/fc"; // Importing the Google icon

// const SignIn = () => {
//   const [email, setEmail] = useState("");
//   const [password, setPassword] = useState("");
//   const [errorMsg, setErrorMsg] = useState("");

//   const handleEmailPasswordSubmit = async (e) => {
//     e.preventDefault();
//     try {
//       const response = await api.post("login/", { email, password });
//       if (response.status === 200) {
//         localStorage.setItem("access", response.data.access);
//         window.location.href = "/dashboard/";
//       } else {
//         setErrorMsg(response.data.error || "Something went wrong.");
//       }
//     } catch (error) {
//       setErrorMsg(error.response?.data.error || "Something went wrong.");
//     }
//   };

//   const handleGoogleSignIn = async () => {
//     // const provider = new firebase.auth.GoogleAuthProvider();
//     const provider = new GoogleAuthProvider();
//     try {
//       // const result = await auth.signInWithPopup(provider);
//       const result = await signInWithPopup(auth, provider);
//       // const token = result.credential.accessToken;
//       const token = result.user.accessToken;
//       const response = await api.post("/google-login/", { token });
//       if (response.status === 200) {
//         localStorage.setItem("access", response.data.access);
//         window.location.href = "/dashboard/";
//       } else {
//         setErrorMsg(response.data.error || "Something went wrong.");
//       }
//     } catch (error) {
//       setErrorMsg(error.message || "Failed to sign in with Google.");
//     }
//   };

//   return (
//     <section className="bg-gradient-to-b from-gray-50 to-green-100">
//       <div className="max-w-6xl mx-auto px-4 sm:px-6">
//         {/* Navigation Bar */}
//         <nav className="py-4 mb-8">
//           <div className="container mx-auto">
//             <div className="flex justify-between items-center">
//               <Link to="/" className="text-2xl font-bold text-gray-800">
//                 FeedbackbyAI
//               </Link>
//             </div>
//           </div>
//         </nav>

//         {/* Sign In Form */}
//         <div className="pt-32 pb-12 md:pt-20 md:pb-20">
//           <div className="max-w-3xl mx-auto text-center pb-12 md:pb-10">
//             <h1 className="h1">Welcome back to FeedbackbyAI</h1>
//           </div>

//           <div className="max-w-sm mx-auto">
//             {/* Google Sign In Button */}
//             <div className="flex flex-wrap -mx-3 mt-3">
//               <div className="w-full px-3">
//                 <button
//                   onClick={handleGoogleSignIn}
//                   className="flex items-center justify-center text-black bg-white border border-gray-300 hover:bg-gray-100 w-full py-2 px-4 rounded"
//                   style={{ boxShadow: "0 2px 4px 0 rgba(0,0,0,0.25)" }}
//                 >
//                   <FcGoogle className="text-2xl mr-3" /> {/* Google Icon */}
//                   Sign in with Google
//                 </button>
//               </div>
//             </div>

//             {/* Divider */}
//             <div className="flex items-center my-6">
//               <div className="border-t border-gray-300 grow mr-3" aria-hidden="true"></div>
//               <div className="text-gray-600 italic">Or</div>
//               <div className="border-t border-gray-300 grow ml-3" aria-hidden="true"></div>
//             </div>

//             <form onSubmit={handleEmailPasswordSubmit}>
//               <div className="flex flex-wrap -mx-3 mb-4">
//                 <div className="w-full px-3">
//                   {errorMsg && <p className="text-red-500 text-sm mt-2">{errorMsg}</p>}
//                   <label className="block text-gray-800 text-sm font-medium mb-1" htmlFor="email">
//                     Email
//                   </label>
//                   <input
//                     id="email"
//                     type="email"
//                     className="form-input w-full text-gray-800"
//                     placeholder="Enter your email address"
//                     value={email}
//                     onChange={(e) => setEmail(e.target.value)}
//                     required
//                   />
//                 </div>
//               </div>

//               <div className="flex flex-wrap -mx-3 mb-4">
//                 <div className="w-full px-3">
//                   <label className="block text-gray-800 text-sm font-medium mb-1" htmlFor="password">
//                     Password
//                   </label>
//                   <input
//                     id="password"
//                     type="password"
//                     className="form-input w-full text-gray-800"
//                     placeholder="Enter your password"
//                     value={password}
//                     onChange={(e) => setPassword(e.target.value)}
//                     required
//                   />
//                 </div>
//               </div>

//               <div className="flex flex-wrap -mx-3 mb-4">
//                 <div className="w-full px-3">
//                   <div className="flex justify-between">
//                     <div></div>
//                     <Link to="/forgot-password" className="text-sm font-medium text-green-600 hover:underline">
//                       Having trouble signing in?
//                     </Link>
//                   </div>
//                 </div>
//               </div>

//               <div className="flex flex-wrap -mx-3 mt-6">
//                 <div className="w-full px-3">
//                   <button className="btn text-white bg-green-600 hover:bg-green-700 w-full">Sign in</button>
//                 </div>
//               </div>
//             </form>

//             {/* Sign Up Link */}
//             <div className="text-gray-600 text-center mt-6">
//               Don't have an account?{" "}
//               <Link to="/signup" className="text-green-600 hover:underline transition duration-150 ease-in-out">
//                 Sign up
//               </Link>
//             </div>
//           </div>
//         </div>
//       </div>
//     </section>
//   );
// };

// export default SignIn;

import React, { useState } from "react";
import { motion } from "framer-motion";
import { FcGoogle } from "react-icons/fc";
import { HiLockClosed, HiMail, HiChevronLeft } from "react-icons/hi";

const fadeInUp = {
  initial: { opacity: 0, y: 30 },
  animate: { opacity: 1, y: 0 },
  transition: {
    duration: 0.6,
    ease: [0.22, 1, 0.36, 1],
  },
};

const PremiumButton = ({ children, variant = "primary", onClick, type = "button", className = "" }) => {
  const baseClasses = "group relative isolate px-6 py-3.5 text-base font-medium rounded-full transition-all duration-500";
  const variants = {
    primary: `${baseClasses} bg-gradient-to-r from-green-600 to-green-500 text-white hover:from-green-500 hover:to-green-400
              shadow-[0_0_0_1px_rgba(0,0,0,0.02)] hover:shadow-[0_8px_16px_rgba(22,163,74,0.15),0_2px_4px_rgba(22,163,74,0.12)]`,
    secondary: `${baseClasses} bg-white border border-gray-200 text-gray-900 hover:border-gray-300 hover:bg-gray-50/80
                shadow-[0_0_0_1px_rgba(0,0,0,0.02)] hover:shadow-[0_4px_8px_rgba(0,0,0,0.04),0_1px_2px_rgba(0,0,0,0.06)]`,
  };

  return (
    <button type={type} onClick={onClick} className={`${variants[variant]} ${className}`}>
      <motion.div
        initial={false}
        whileHover={{ scale: 1.02 }}
        whileTap={{ scale: 0.98 }}
        className="flex items-center justify-center gap-2"
      >
        {children}
      </motion.div>
    </button>
  );
};

const FormInput = ({ icon: Icon, label, ...props }) => (
  <div className="space-y-2">
    <label className="block text-sm font-medium text-gray-700 pl-4">{label}</label>
    <div className="relative">
      <div className="absolute inset-y-0 left-0 pl-4 flex items-center pointer-events-none">
        <Icon className="h-5 w-5 text-gray-400" />
      </div>
      <input
        {...props}
        className="w-full pl-11 pr-4 py-3 bg-white/80 backdrop-blur-sm border border-gray-200 rounded-full 
                  text-gray-900 placeholder-gray-500 text-base transition-all duration-200
                  focus:outline-none focus:border-green-500 focus:ring-2 focus:ring-green-500/20"
      />
    </div>
  </div>
);

const SignIn = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [errorMsg, setErrorMsg] = useState("");

  const handleEmailPasswordSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await api.post("login/", { email, password });
      if (response.status === 200) {
        localStorage.setItem("access", response.data.access);
        window.location.href = "/dashboard/";
      }
    } catch (error) {
      setErrorMsg(error.response?.data.error || "Something went wrong.");
    }
  };

  const handleGoogleSignIn = async () => {
    const provider = new GoogleAuthProvider();
    try {
      const result = await signInWithPopup(auth, provider);
      const response = await api.post("/google-login/", { token: result.user.accessToken });
      if (response.status === 200) {
        localStorage.setItem("access", response.data.access);
        window.location.href = "/dashboard/";
      }
    } catch (error) {
      setErrorMsg(error.message || "Failed to sign in with Google.");
    }
  };

  return (
    <div className="min-h-screen relative bg-gradient-to-b from-white to-gray-50/50">
      {/* Premium background effects */}
      <div className="absolute inset-0 overflow-hidden pointer-events-none">
        <div className="absolute -top-1/4 -right-1/4 w-1/2 h-1/2 bg-green-100/30 rounded-full blur-3xl" />
        <div className="absolute -bottom-1/4 -left-1/4 w-1/2 h-1/2 bg-blue-100/20 rounded-full blur-3xl" />
      </div>

      <div className="relative max-w-6xl mx-auto px-4 sm:px-6">
        <nav className="py-6">
          <a href="/" className="inline-flex items-center group">
            <motion.div whileHover={{ x: -4 }} className="mr-2 text-gray-600 group-hover:text-gray-900">
              <HiChevronLeft className="w-5 h-5" />
            </motion.div>
            <span className="bg-gradient-to-r from-gray-900 to-gray-800 bg-clip-text text-transparent font-semibold text-xl">
              FeedbackbyAI
            </span>
          </a>
        </nav>

        <div className="pt-20 pb-12">
          <div className="max-w-3xl mx-auto text-center pb-12">
            <motion.div {...fadeInUp}>
              <h1 className="text-4xl sm:text-5xl font-bold tracking-tight mb-6">
                <span className="bg-gradient-to-r from-gray-900 to-gray-800 bg-clip-text text-transparent">Welcome back</span>
              </h1>
              <p className="text-lg text-gray-600">Sign in to continue to your account</p>
            </motion.div>
          </div>

          <motion.div {...fadeInUp} transition={{ delay: 0.1 }} className="max-w-sm mx-auto">
            <div
              className="bg-white/80 backdrop-blur-xl border border-gray-100 rounded-2xl p-8
                          shadow-[0_0_0_1px_rgba(0,0,0,0.02)] hover:shadow-[0_24px_48px_rgba(0,0,0,0.08),0_2px_4px_rgba(0,0,0,0.04)]
                          transition-all duration-500"
            >
              <PremiumButton onClick={handleGoogleSignIn} variant="secondary" className="w-full mb-6">
                <FcGoogle className="text-xl" />
                <span>Continue with Google</span>
              </PremiumButton>

              <div className="flex items-center my-6">
                <div className="border-t border-gray-200 grow mr-3" />
                <div className="text-sm text-gray-500 font-medium">or continue with email</div>
                <div className="border-t border-gray-200 grow ml-3" />
              </div>

              {errorMsg && <div className="mb-6 p-4 bg-red-50 border border-red-100 rounded-xl text-red-600 text-sm">{errorMsg}</div>}

              <form onSubmit={handleEmailPasswordSubmit} className="space-y-4">
                <FormInput
                  icon={HiMail}
                  label="Email"
                  type="email"
                  placeholder="Enter your email address"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  required
                />

                <FormInput
                  icon={HiLockClosed}
                  label="Password"
                  type="password"
                  placeholder="Enter your password"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  required
                />

                <div className="flex justify-between items-center pt-2">
                  <label className="flex items-center">
                    <input
                      type="checkbox"
                      className="w-4 h-4 rounded border-gray-300 text-green-600 
                               focus:ring-green-500 focus:ring-offset-0"
                    />
                    <span className="ml-2 text-sm text-gray-600">Remember me</span>
                  </label>
                  <a href="/forgot-password" className="text-sm font-medium text-green-600 hover:text-green-500">
                    Forgot password?
                  </a>
                </div>

                <PremiumButton type="submit" className="w-full mt-6">
                  Sign in
                </PremiumButton>
              </form>
            </div>

            <div className="text-center mt-8">
              <span className="text-gray-600">Don't have an account?</span>{" "}
              <a href="/signup" className="font-medium text-green-600 hover:text-green-500">
                Sign up
              </a>
            </div>
          </motion.div>
        </div>
      </div>
    </div>
  );
};

export default SignIn;
