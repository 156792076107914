// import React, { useEffect, useState } from "react";
// import Uppy from "@uppy/core";
// import { Dashboard } from "@uppy/react";
// import AwsS3 from "@uppy/aws-s3";
// import ProgressBar from "@uppy/progress-bar";
// import instance from "./axiosConfig";
// import "@uppy/core/dist/style.css";
// import "@uppy/dashboard/dist/style.css";
// import "@uppy/progress-bar/dist/style.css";

// const UppyUploader = ({ onUploadDone }) => {
//   const [progress, setProgress] = useState(0);
//   const [uploading, setUploading] = useState(false);
//   const [uppyInstance, setUppyInstance] = useState(null);

//   useEffect(() => {
//     const uppy = new Uppy({
//       restrictions: {
//         maxNumberOfFiles: 1,
//         allowedFileTypes: ["video/*"],
//       },
//       autoProceed: true,
//     });

//     setUppyInstance(uppy);

//     uppy.on("file-added", (file) => {
//       uppy.setFileMeta(file.id, {
//         customName: file.name,
//       });
//     });

//     uppy.use(AwsS3, {
//       async getUploadParameters(file) {
//         const res = await instance.post("/api/get-presigned-url/", {
//           filename: file.name,
//           content_type: file.type,
//         });

//         return {
//           method: "PUT",
//           url: res.data.upload_url,
//           headers: {
//             // "Content-Type": file.type,
//             // Don't include x-amz-acl here if it's breaking local
//           },
//           uploadURL: res.data.upload_url.split("?")[0],
//         };
//       },
//     });

//     // uppy.use(AwsS3, {
//     //   async getUploadParameters(file) {
//     //     const res = await instance.post("/api/get-presigned-url/", {
//     //       filename: file.name,
//     //       content_type: file.type,
//     //     });

//     //     return {
//     //       method: "PUT",
//     //       url: res.data.upload_url,
//     //       headers: {
//     //         "Content-Type": file.type,
//     //         // "x-amz-acl": "public-read"  // Add this line
//     //       },
//     //       uploadURL: res.data.upload_url.split("?")[0],
//     //     };
//     //   },
//     // });

//     // uppy.use(AwsS3, {
//     //   async getUploadParameters(file) {
//     //     const res = await instance.post("/api/get-presigned-url/", {
//     //       filename: file.name,
//     //       content_type: file.type,
//     //     });

//     //     return {
//     //       method: "PUT",
//     //       url: res.data.upload_url,
//     //       headers: {
//     //         "Content-Type": file.type,
//     //       },

//     //       uploadURL: res.data.upload_url.split("?")[0],
//     //     };
//     //   },
//     // });

//     uppy.use(ProgressBar, {
//       target: "body",
//       fixed: true,
//       hideAfterFinish: false,
//     });

//     uppy.on("upload-progress", (file, progressData) => {
//       const percent = Math.round((progressData.bytesUploaded / progressData.bytesTotal) * 100);
//       setProgress(percent);
//       setUploading(true);
//     });

//     uppy.on("complete", async (result) => {
//       const uploadedFile = result.successful?.[0];

//       if (!uploadedFile) {
//         console.error("❌ No file uploaded:", result);
//         alert("Upload failed. No file was uploaded.");
//         return;
//       }

//       const s3Url = uploadedFile.uploadURL;
//       const file = uploadedFile.data;
//       const title = uploadedFile.meta?.customName || uploadedFile.name;

//       if (!s3Url || !file) {
//         alert("Upload failed. Missing file or URL.");
//         return;
//       }

//       try {
//         // 👉 1. Generate thumbnail from video
//         const thumbnailBlob = await extractThumbnail(file);

//         // 👉 2. Get presigned URL for thumbnail
//         const thumbRes = await instance.post("/api/get-presigned-url/", {
//           filename: `${file.name.split(".")[0]}-thumbnail.jpg`,
//           content_type: "image/jpeg",
//         });

//         const thumbUploadUrl = thumbRes.data.upload_url;
//         const thumbnailS3Url = thumbRes.data.file_url;

//         // 👉 3. Upload thumbnail to S3
//         await fetch(thumbUploadUrl, {
//           method: "PUT",
//           headers: { "Content-Type": "image/jpeg" },
//           body: thumbnailBlob,
//         });

//         // 👉 4. Save video + thumbnail to backend
//         const saveRes = await instance.post("/api/save-uploaded-video/", {
//           s3_url: s3Url,
//           thumbnail_url: thumbnailS3Url,
//           title,
//         });

//         if (onUploadDone) onUploadDone(saveRes.data);
//         alert("✅ Video and thumbnail uploaded & saved!");
//       } catch (err) {
//         console.error("❌ Upload failed:", err);
//         alert("Upload succeeded, but thumbnail or save failed.");
//       } finally {
//         setUploading(false);
//       }
//     });

//     return () => {
//       uppy.close?.();
//     };
//   }, [onUploadDone]);

//   return (
//     <div>
//       {uppyInstance && <Dashboard uppy={uppyInstance} proudlyDisplayPoweredByUppy={false} height={350} />}
//       {uploading && <p style={{ textAlign: "center", marginTop: 10 }}>Uploading... {progress}%</p>}
//     </div>
//   );
// };

// export default UppyUploader;

// // ✅ Extracts thumbnail from first frame
// const extractThumbnail = (file) =>
//   new Promise((resolve, reject) => {
//     const video = document.createElement("video");
//     video.preload = "metadata";
//     video.src = URL.createObjectURL(file);
//     video.muted = true;
//     video.playsInline = true;
//     video.crossOrigin = "anonymous";

//     video.addEventListener("loadeddata", () => {
//       video.currentTime = 1;
//     });

//     video.addEventListener("seeked", () => {
//       const canvas = document.createElement("canvas");
//       canvas.width = video.videoWidth;
//       canvas.height = video.videoHeight;
//       const ctx = canvas.getContext("2d");
//       ctx.drawImage(video, 0, 0, canvas.width, canvas.height);
//       canvas.toBlob(
//         (blob) => {
//           resolve(blob);
//         },
//         "image/jpeg",
//         0.92
//       );
//     });

//     video.onerror = (e) => reject("Failed to load video for thumbnail");
//   });

import React, { useEffect, useState } from "react";
import Uppy from "@uppy/core";
import { Dashboard } from "@uppy/react";
import AwsS3 from "@uppy/aws-s3";
import ProgressBar from "@uppy/progress-bar";
import instance from "./axiosConfig";
import "@uppy/core/dist/style.css";
import "@uppy/dashboard/dist/style.css";
import "@uppy/progress-bar/dist/style.css";

const UppyUploader = ({ onUploadDone }) => {
  const [progress, setProgress] = useState(0);
  const [uploading, setUploading] = useState(false);
  const [uppyInstance, setUppyInstance] = useState(null);

  useEffect(() => {
    const uppy = new Uppy({
      restrictions: {
        maxNumberOfFiles: 1,
        allowedFileTypes: ["video/*"],
      },
      autoProceed: true,
    });

    setUppyInstance(uppy);

    uppy.on("file-added", async (file) => {
      console.log("📥 File added to Uppy:", file);
      uppy.setFileMeta(file.id, {
        customName: file.name,
      });
    });

    uppy.use(AwsS3, {
      async getUploadParameters(file) {
        console.log("📡 Requesting presigned URL for:", file.name);
        const res = await instance.post("/api/get-presigned-url/", {
          filename: file.name,
          content_type: file.type,
        });

        console.log("✅ Presigned URL received:", res.data);

        return {
          method: "PUT",
          url: res.data.upload_url,
          headers: {
            // 🚫 No "Content-Type" unless signed with it
          },
          uploadURL: res.data.upload_url.split("?")[0],
        };
      },
    });

    uppy.use(ProgressBar, {
      target: "body",
      fixed: true,
      hideAfterFinish: false,
    });

    uppy.on("upload-progress", (file, progressData) => {
      const percent = Math.round((progressData.bytesUploaded / progressData.bytesTotal) * 100);
      setProgress(percent);
      setUploading(true);
      console.log(`📈 Upload progress: ${percent}%`);
    });

    uppy.on("upload-error", (file, error) => {
      console.error("🚨 Uppy error:", error);
    });

    uppy.on("complete", async (result) => {
      console.log("🎉 Uppy upload complete:", result);

      const uploadedFile = result.successful?.[0];

      if (!uploadedFile) {
        console.error("❌ No file in successful uploads:", result);
        alert("Upload failed. No file was uploaded.");
        return;
      }

      const s3Url = uploadedFile.uploadURL;
      const file = uploadedFile.data;
      const title = uploadedFile.meta?.customName || uploadedFile.name;

      if (!s3Url || !file) {
        console.error("❌ Missing s3Url or file:", { s3Url, file });
        alert("Upload failed. Missing file or URL.");
        return;
      }

      try {
        console.log("🖼️ Extracting thumbnail...");
        const thumbnailBlob = await extractThumbnail(file);

        console.log("🧾 Getting presigned URL for thumbnail...");
        const thumbRes = await instance.post("/api/get-presigned-url/", {
          filename: `${file.name.split(".")[0]}-thumbnail.jpg`,
          content_type: "image/jpeg",
        });

        const thumbUploadUrl = thumbRes.data.upload_url;
        const thumbnailS3Url = thumbRes.data.file_url;

        console.log("⬆️ Uploading thumbnail to S3...");
        await fetch(thumbUploadUrl, {
          method: "PUT",
          headers: { "Content-Type": "image/jpeg" },
          body: thumbnailBlob,
        });

        console.log("💾 Saving to backend...");
        const saveRes = await instance.post("/api/save-uploaded-video/", {
          s3_url: s3Url,
          thumbnail_url: thumbnailS3Url,
          title,
        });

        console.log("✅ Upload complete:", saveRes.data);
        if (onUploadDone) onUploadDone(saveRes.data);
        alert("✅ Video and thumbnail uploaded & saved!");
      } catch (err) {
        console.error("❌ Upload completed, but post-processing failed:", err);
        alert("Upload succeeded, but thumbnail or save failed.");
      } finally {
        setUploading(false);
      }
    });

    return () => {
      uppy.close?.();
    };
  }, [onUploadDone]);

  return (
    <div>
      {uppyInstance && <Dashboard uppy={uppyInstance} proudlyDisplayPoweredByUppy={false} height={350} />}
      {uploading && <p style={{ textAlign: "center", marginTop: 10 }}>Uploading... {progress}%</p>}
    </div>
  );
};

export default UppyUploader;

// ✅ Extract thumbnail from the first second
const extractThumbnail = (file) =>
  new Promise((resolve, reject) => {
    const video = document.createElement("video");
    video.preload = "metadata";
    video.src = URL.createObjectURL(file);
    video.muted = true;
    video.playsInline = true;
    video.crossOrigin = "anonymous";

    video.addEventListener("loadeddata", () => {
      video.currentTime = 1;
    });

    video.addEventListener("seeked", () => {
      const canvas = document.createElement("canvas");
      canvas.width = video.videoWidth;
      canvas.height = video.videoHeight;
      const ctx = canvas.getContext("2d");
      ctx.drawImage(video, 0, 0, canvas.width, canvas.height);
      canvas.toBlob((blob) => resolve(blob), "image/jpeg", 0.92);
    });

    video.onerror = (e) => reject("Failed to load video for thumbnail");
  });
