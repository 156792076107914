// import React, { useState, useEffect, useCallback, useRef } from "react";
// import {
//   Search,
//   RefreshCw,
//   Loader2,
//   Bookmark,
//   ThumbsUp,
//   Zap,
//   Users,
//   TrendingUp,
//   ChevronUp,
//   ChevronDown,
//   Send,
//   Edit3,
//   ChevronRight,
//   AlertCircle,
//   X,
//   Pencil,
//   RefreshCcw,
//   Bell,
//   Sparkles,
//   Info,
//   DollarSign,
//   Lock,
//   Lightbulb,
//   ArrowRight,
//   BarChart,
//   Download,
//   FileText,
//   Minimize2,
//   ChevronLeft,
//   Maximize2,
//   Menu,
//   BookOpen,
//   Sun,
//   Open,
//   Moon,
//   Share2,
//   Printer,
//   Briefcase,
//   Clock,
//   NetworkIcon,
//   Network,
//   MessageSquare,
//   Coffee,
//   Target,
//   Brain,
// } from "lucide-react";
// import axios from "./axiosConfig";
// import { motion, AnimatePresence } from "framer-motion";
// import { Dialog, Transition } from "@headlessui/react";
// import DOMPurify from "dompurify";
// import { formatDistanceToNow } from "date-fns";
// import ReactMarkdown from "react-markdown";
// import remarkGfm from "remark-gfm";
// import mermaid from "mermaid";
// import DashboardHeader from "./DashboardHeader";

// function removeCodeBlockMarkdown(content) {
//   return content ? content.replace(/```markdown\n|```/g, "") : "";
// }

// const FeatureIcon = ({ Icon, text }) => (
//   <div className="flex items-center space-x-2">
//     <Icon className="w-5 h-5" />
//     <span className="text-sm">{text}</span>
//   </div>
// );

// const FeatureItem = ({ icon: Icon, text }) => (
//   <div className="flex items-center mb-3">
//     <Icon className="text-green-500 mr-2" size={20} />
//     <span>{text}</span>
//   </div>
// );

// function sanitizeContent(content) {
//   let parsedContent;
//   try {
//     parsedContent = JSON.parse(content);
//     // If parsing succeeds, extract the relevant content
//     content = parsedContent.content || parsedContent;
//   } catch (error) {
//     // If parsing fails, use the original content
//   }

//   return content
//     .replace(/\\n/g, "\n")
//     .replace(/\\t/g, "\t")
//     .replace(/['"]+/g, "")
//     .replace(/[{}]/g, "")
//     .replace(/answer:\s*/g, "")
//     .replace(/^##\s*/, "")
//     .trim();
// }

// const SECTION_ORDER = {
//   ExecutiveSummary: 1,
//   MarketDeepDive: 2,
//   UnmetNeed: 3,
//   CompetitiveIntelligence: 4,
//   CustomerSegmentation: 5,
//   SolutionBlueprint: 6,
//   MarketForecast: 7,
//   GoToMarket: 8,
//   SuccessStories: 9,
//   Conclusion: 10,
// };

// const CustomMarkdownRenderer = ({ content }) => {
//   useEffect(() => {
//     mermaid.contentLoaded();
//   }, [content]);

//   const components = {
//     code({ node, inline, className, children, ...props }) {
//       const match = /language-(\w+)/.exec(className || "");
//       if (match && match[1] === "mermaid") {
//         return <div className="mermaid">{String(children).replace(/\n$/, "")}</div>;
//       }
//       return (
//         <pre className={className} {...props}>
//           <code>{children}</code>
//         </pre>
//       );
//     },
//     li({ node, children, ...props }) {
//       if (node.checked !== undefined) {
//         return (
//           <li {...props} className="flex items-center">
//             <input type="checkbox" checked={node.checked} readOnly className="mr-2" />
//             {children}
//           </li>
//         );
//       }
//       return <li {...props}>{children}</li>;
//     },
//   };

//   return (
//     <ReactMarkdown className="prose" remarkPlugins={[remarkGfm]} components={components}>
//       {content}
//     </ReactMarkdown>
//   );
// };

// const LoadingExperience = ({ insightTitle }) => {
//   const [progress, setProgress] = useState(0);
//   const [currentTip, setCurrentTip] = useState(0);

//   const tips = [
//     "Did you know? Our AI analyzes over 1 million data points to generate each report.",
//     "Pro tip: Save interesting insights to your library for quick access later.",
//     "Fun fact: The average NeedFinderAI user discovers 5 new business opportunities per month.",
//   ];

//   useEffect(() => {
//     const timer = setInterval(() => {
//       setProgress((oldProgress) => {
//         const newProgress = oldProgress + 1;
//         if (newProgress === 100) {
//           clearInterval(timer);
//         }
//         return newProgress;
//       });
//     }, 1800); // 180 seconds (3 minutes) / 100 steps = 1800ms per step

//     return () => clearInterval(timer);
//   }, []);

//   useEffect(() => {
//     const tipTimer = setInterval(() => {
//       setCurrentTip((oldTip) => (oldTip + 1) % tips.length);
//     }, 8000);

//     return () => clearInterval(tipTimer);
//   }, []);

//   return (
//     <div className="fixed inset-0 bg-white z-50 flex flex-col items-center justify-center p-4">
//       <BookOpen className="text-blue-600 mb-4" size={48} />
//       <h2 className="text-2xl font-bold text-gray-800 mb-2">Generating Your Full Report</h2>
//       <p className="text-lg text-gray-600 mb-6">"{insightTitle}"</p>

//       <div className="w-full max-w-md bg-gray-200 rounded-full h-2.5 mb-4">
//         <div className="bg-blue-600 h-2.5 rounded-full" style={{ width: `${progress}%` }}></div>
//       </div>

//       <div className="flex items-center text-gray-600 mb-8">
//         <Clock size={20} className="mr-2" />
//         <span>Estimated time: 2-3 minutes</span>
//       </div>

//       <div className="mt-8">
//         <Loader2 className="animate-spin text-blue-600 mb-2 mx-auto" size={24} />
//         <p className="text-gray-600">Analyzing market trends and opportunities...</p>
//       </div>
//     </div>
//   );
// };

// const FullReportSidebar = ({ isOpen, closeReport, insightId, refreshCredits, setShowUpgradeModal, insightreportCredits }) => {
//   const [activeSection, setActiveSection] = useState("");
//   const [loadedSections, setLoadedSections] = useState({});
//   const [isSidebarOpen, setIsSidebarOpen] = useState(false);
//   const [reportId, setReportId] = useState(null);
//   const [fontSize, setFontSize] = useState("text-lg");
//   const [isLoading, setIsLoading] = useState(true);
//   const [insightTitle, setInsightTitle] = useState("");

//   useEffect(() => {
//     mermaid.initialize({
//       startOnLoad: true,
//       theme: "default",
//       securityLevel: "loose",
//       flowchart: { curve: "basis" },
//       gantt: { axisFormat: "%m/%d/%Y" },
//       sequence: { actorMargin: 50 },
//     });
//   }, []);

//   useEffect(() => {
//     if (loadedSections[activeSection]) {
//       mermaid.init(undefined, document.querySelectorAll(".mermaid"));
//     }
//   }, [activeSection, loadedSections]);

//   useEffect(() => {
//     if (isOpen && insightId) {
//       setIsLoading(true);
//       // Fetch the insight title
//       axios
//         .get(`/fetch-insight-title/${insightId}/`)
//         .then((response) => setInsightTitle(response.data.title))
//         .catch((error) => console.error("Error fetching insight title:", error));

//       axios
//         .post("/generate-report/", { painpoint_analysis_id: insightId })
//         .then((response) => {
//           setReportId(response.data.report_id);
//           const firstSectionName = Object.keys(SECTION_ORDER)[0];
//           setActiveSection(firstSectionName);
//           loadSectionContent(response.data.report_id, firstSectionName);
//         })
//         .catch((error) => console.error("Error generating report:", error));
//     }
//   }, [isOpen, insightId]);

//   const handleClose = () => {
//     closeReport();
//     refreshCredits();
//   };

//   const loadSectionContent = (reportId, sectionName) => {
//     setIsLoading(true); // Show loader before content starts loading
//     if (!loadedSections[sectionName]) {
//       axios
//         .get(`/fetch-section-content/${reportId}/${sectionName}/`)
//         .then((response) => {
//           let content = response.data.content;
//           let extractedContent = sanitizeContent(content);
//           setLoadedSections((prev) => ({
//             ...prev,
//             [sectionName]: extractedContent,
//           }));
//           setActiveSection(sectionName);
//           setIsLoading(false); // Hide loader once content is loaded
//         })
//         .catch((error) => {
//           console.error("Error fetching section content:", error);
//           setIsLoading(false); // Hide loader even if there's an error

//           if (error.response && error.response.status === 403) {
//             // setShowUpgradeModal(true); // Trigger the upgrade modal on 403 error
//             setShowUpgradeModal?.(true);
//           }
//         });
//     } else {
//       setActiveSection(sectionName);
//       setIsLoading(false); // Hide loader if content was already loaded
//     }
//   };

//   const toggleSidebar = () => setIsSidebarOpen(!isSidebarOpen);

//   const changeFontSize = (size) => {
//     setFontSize(size);
//   };

//   if (!isOpen) return null;

//   return (
//     <div className="fixed inset-0 bg-white z-50 overflow-hidden font-serif">
//       {isLoading ? (
//         <LoadingExperience insightTitle={insightTitle} />
//       ) : (
//         <>
//           <header className="fixed top-0 left-0 right-0 h-16 flex justify-between items-center px-4 bg-white border-b border-gray-200 z-10">
//             <button onClick={toggleSidebar} className="p-2 text-gray-600 hover:text-gray-900">
//               <Menu size={24} />
//             </button>
//             <h2 className="text-xl font-semibold text-gray-900 flex items-center">
//               <BookOpen className="mr-2" size={24} />
//               Full Report
//             </h2>
//             <div className="flex items-center space-x-2">
//               <button
//                 onClick={() => changeFontSize("text-base")}
//                 className={`px-2 py-1 rounded ${fontSize === "text-base" ? "bg-gray-200" : "hover:bg-gray-100"}`}
//               >
//                 A
//               </button>
//               <button
//                 onClick={() => changeFontSize("text-lg")}
//                 className={`px-2 py-1 rounded ${fontSize === "text-lg" ? "bg-gray-200" : "hover:bg-gray-100"}`}
//               >
//                 A+
//               </button>
//               <button
//                 onClick={() => changeFontSize("text-xl")}
//                 className={`px-2 py-1 rounded ${fontSize === "text-xl" ? "bg-gray-200" : "hover:bg-gray-100"}`}
//               >
//                 A++
//               </button>
//               <button onClick={handleClose} className="p-2 text-gray-600 hover:text-gray-900">
//                 <X size={24} />
//               </button>
//             </div>
//           </header>

//           {/* <AnimatePresence>
//             {isSidebarOpen && (
//               <motion.nav
//                 initial={{ x: "-100%" }}
//                 animate={{ x: 0 }}
//                 exit={{ x: "-100%" }}
//                 transition={{ type: "tween", duration: 0.3 }}
//                 className="fixed inset-y-0 left-0 w-64 bg-white border-r border-gray-200 pt-16 z-20 overflow-y-auto"
//               >
//                 {Object.entries(SECTION_ORDER).map(([section, index]) => (
//                   <button
//                     key={section}
//                     onClick={() => {
//                       loadSectionContent(reportId, section);
//                       setIsSidebarOpen(false);
//                     }}
//                     className={`w-full text-left py-3 px-6 ${
//                       activeSection === section ? "bg-gray-100 text-gray-900 font-semibold" : "text-gray-600 hover:bg-gray-50"
//                     } transition-colors duration-200`}
//                   >
//                     {section}
//                   </button>
//                 ))}
//               </motion.nav>
//             )}
//           </AnimatePresence> */}
//           <AnimatePresence>
//             {isSidebarOpen && (
//               <motion.nav
//                 initial={{ x: "-100%" }}
//                 animate={{ x: 0 }}
//                 exit={{ x: "-100%" }}
//                 transition={{ type: "tween", duration: 0.3 }}
//                 className="fixed inset-y-0 left-0 w-64 bg-white border-r border-gray-200 pt-16 z-20 overflow-y-auto"
//               >
//                 {Object.entries(SECTION_ORDER).map(([section, index]) => (
//                   <button
//                     key={section}
//                     onClick={() => {
//                       if (section === "ExecutiveSummary" || insightreportCredits > 0) {
//                         // Only load content if it's the first section or if credits are available
//                         loadSectionContent(reportId, section);
//                         setIsSidebarOpen(false);
//                       } else {
//                         // setShowUpgradeModal(true); // Show upgrade modal if section is locked
//                         setShowUpgradeModal?.(true);
//                       }
//                     }}
//                     className={`w-full text-left py-3 px-6 flex items-center ${
//                       activeSection === section ? "bg-gray-100 text-gray-900 font-semibold" : "text-gray-600 hover:bg-gray-50"
//                     } transition-colors duration-200`}
//                   >
//                     {section}
//                     {/* Show lock icon for locked sections, except for "ExecutiveSummary" */}
//                     {section !== "ExecutiveSummary" && insightreportCredits <= 0 && <Lock size={18} className="ml-auto text-gray-400" />}
//                   </button>
//                 ))}
//               </motion.nav>
//             )}
//           </AnimatePresence>

//           <main className="pt-20 pb-24 px-4 sm:px-6 md:px-8 lg:px-0 max-w-2xl mx-auto overflow-y-auto h-full">
//             <AnimatePresence mode="wait">
//               <motion.div
//                 key={activeSection}
//                 initial={{ opacity: 0, y: 20 }}
//                 animate={{ opacity: 1, y: 0 }}
//                 exit={{ opacity: 0, y: -20 }}
//                 transition={{ duration: 0.3 }}
//               >
//                 <div className={`prose prose-lg prose-gray max-w-none ${fontSize}`}>
//                   {loadedSections[activeSection] ? <CustomMarkdownRenderer content={loadedSections[activeSection]} /> : <SkeletonLoader />}
//                 </div>
//               </motion.div>
//             </AnimatePresence>
//           </main>

//           <footer className="fixed bottom-0 left-0 right-0 h-16 flex justify-between items-center px-4 bg-white border-t border-gray-200">
//             <button
//               onClick={() => {
//                 const currentIndex = Object.keys(SECTION_ORDER).indexOf(activeSection);
//                 if (currentIndex > 0) loadSectionContent(reportId, Object.keys(SECTION_ORDER)[currentIndex - 1]);
//               }}
//               disabled={activeSection === Object.keys(SECTION_ORDER)[0]}
//               className="flex items-center text-gray-600 hover:text-gray-900 disabled:text-gray-400 disabled:cursor-not-allowed"
//             >
//               <ChevronLeft size={20} className="mr-1" />
//               <span>Previous</span>
//             </button>

//             <button
//               onClick={() => {
//                 const currentIndex = Object.keys(SECTION_ORDER).indexOf(activeSection);
//                 const nextSection = Object.keys(SECTION_ORDER)[currentIndex + 1];
//                 if (nextSection && (currentIndex === 0 || insightreportCredits > 0)) {
//                   // Load next section only if the user has credits or it's the first section
//                   loadSectionContent(reportId, nextSection);
//                 } else {
//                   //   setShowUpgradeModal(true); // Trigger upgrade modal if the section is locked
//                   setShowUpgradeModal?.(true);
//                   // Trigger upgrade modal if the section is locked
//                 }
//               }}
//               disabled={
//                 activeSection === Object.keys(SECTION_ORDER)[Object.keys(SECTION_ORDER).length - 1] ||
//                 (insightreportCredits <= 0 && activeSection !== Object.keys(SECTION_ORDER)[0])
//               }
//               className="flex items-center text-gray-600 hover:text-gray-900 disabled:text-gray-400 disabled:cursor-not-allowed"
//             >
//               <span>Next</span>
//               <ChevronRight size={20} className="ml-1" />
//               {/* Show lock icon if next section is locked */}
//               {insightreportCredits <= 0 && activeSection !== Object.keys(SECTION_ORDER)[0] && (
//                 <Lock size={18} className="ml-2 text-gray-400" />
//               )}
//             </button>
//           </footer>
//         </>
//       )}
//     </div>
//   );
// };

// const SkeletonLoader = () => (
//   <div className="space-y-4">
//     <div className="h-4 bg-gray-200 rounded w-3/4"></div>
//     <div className="h-4 bg-gray-200 rounded"></div>
//     <div className="h-4 bg-gray-200 rounded"></div>
//     <div className="h-4 bg-gray-200 rounded w-1/2"></div>
//   </div>
// );

// const NeedFinderAIUpgradeTeaser = ({ setActiveTab, currentPlan }) => (
//   <div className="bg-white p-8 rounded-lg shadow-lg max-w-2xl mx-auto text-center">
//     <h2 className="text-3xl font-bold mb-4">Unlock Your AI Co-founder's Full Potential!</h2>
//     <p className="text-xl text-gray-600 mb-6"> Uncover 50x more opportunities and full reports like these EVERY MONTH.</p>

//     <div className="bg-blue-50 p-6 rounded-lg mb-8">
//       <h3 className="text-2xl font-semibold mb-4">With the Innovator Plan, you'll get:</h3>
//       <div className="text-left">
//         <FeatureItem icon={Zap} text="250 AI Insights per month" />
//         <FeatureItem icon={Brain} text="5 Full Business Reports" />
//         <FeatureItem icon={Users} text="10 Feedback Submissions" />
//         <FeatureItem icon={Coffee} text="14-Day Access to Virtual Coworking Space" />
//         <FeatureItem icon={Target} text="50 AI Marketing Credits" />
//       </div>
//     </div>

//     <div className="mb-8">
//       <p className="text-3xl font-bold mb-2">
//         $59<span className="text-xl font-normal">/month</span>
//       </p>
//       <p className="text-green-600 font-semibold">Most popular plan</p>
//     </div>

//     <button
//       onClick={() => setActiveTab("subscriptions")}
//       className="bg-green-500 text-white px-8 py-3 rounded-full text-xl font-semibold hover:bg-green-700 transition duration-300 mb-6"
//     >
//       Upgrade Now
//     </button>

//     <p className="text-sm text-gray-500 mb-4">
//       {" "}
//       Money-back guarantee if you don't find at least one game-changing insight in your first week!
//     </p>
//   </div>
// );

// const AIMarketGapFinder = ({ userDetails, setUserDetails, setShowUpgradeModal, showUpgradeModal, setActiveTab, fetchUserDetails }) => {
//   const [isNewUser, setIsNewUser] = useState(true);
//   const [isEditingKeywords, setIsEditingKeywords] = useState(false);
//   const [monitoringConfig, setMonitoringConfig] = useState({
//     keywords: "",
//     frequency: "daily",
//     sources: [],
//     notifications: true,
//   });
//   const [isGeneratingInsights, setIsGeneratingInsights] = useState(false);
//   const [generationProgress, setGenerationProgress] = useState(0);
//   const [insights, setInsights] = useState([]);
//   const [nextPageUrl, setNextPageUrl] = useState(null);
//   const [loadingMore, setLoadingMore] = useState(false);
//   const [expandedCards, setExpandedCards] = useState([]);
//   const [currentPlan, setCurrentPlan] = useState(userDetails?.current_plan || "Free");
//   const [showKeywordModal, setShowKeywordModal] = useState(false);
//   const [likedPainPoints, setLikedPainPoints] = useState(new Set());
//   const [bookmarkedPainPoints, setBookmarkedPainPoints] = useState(new Set());
//   const [sparkedIdea, setSparkedIdea] = useState(null);
//   const [showSparkIdeaModal, setShowSparkIdeaModal] = useState(false);
//   const [isSparkingIdea, setIsSparkingIdea] = useState(false);
//   const [insightCredits, setInsightCredits] = useState(userDetails?.insight_credits || 0);
//   const [sparkIdeaCredits, setSparkIdeaCredits] = useState(userDetails?.spark_idea_credits || 0);
//   const [insightreportCredits, setInsightreportCredits] = useState(userDetails?.ai_insights_reports || 0);

//   const hasInsights = Array.isArray(insights) && insights.length > 0;
//   const [lastUpdated, setLastUpdated] = useState("");
//   const [suggestedKeywords, setSuggestedKeywords] = useState([]);
//   const [alertsEnabled, setAlertsEnabled] = useState(false);
//   const [reachedFreePreviewEnd, setReachedFreePreviewEnd] = useState(false);
//   const suggestedKeywords1 = ["business idea validation", "remote work tools", "youtube advertising", "real estate developer"];

//   const [showFullReportModal, setShowFullReportModal] = useState(false);
//   const [selectedInsightId, setSelectedInsightId] = useState(null);
//   const [userReports, setUserReports] = useState([]);
//   const [loading, setLoading] = useState(true);
//   const [generatedReports, setGeneratedReports] = useState(new Set());

//   const handleDownloadReport = (insightId) => {
//     // Implement the download functionality here
//     console.log(`Downloading report for insight ${insightId}`);
//     // You might want to call an API endpoint or generate a PDF here
//   };

//   useEffect(() => {
//     axios
//       .get("/fetch-user-reports/")
//       .then((response) => {
//         const generatedReportIds = new Set(response.data.reports.map((report) => report.painpoint_analysis_id));
//         setGeneratedReports(generatedReportIds);
//         setLoading(false);
//       })
//       .catch((error) => {
//         console.error("Error fetching user reports:", error);
//         setLoading(false);
//       });
//   }, []);

//   const handleViewFullReport = (insightId) => {
//     setSelectedInsightId(insightId);
//     setShowFullReportModal(true);
//   };

//   useEffect(() => {
//     // Fetch insights or other relevant data
//     axios
//       .get("insights/")
//       .then((response) => setInsights(response.data))
//       .catch((error) => console.error(error));
//   }, []);

//   const fetchUserInsights = async (url = "fetch-user-insights/") => {
//     try {
//       const response = await axios.get(url);
//       setInsights((prev) => [...prev, ...response.data.results.insights]);
//       setMonitoringConfig((prev) => ({
//         ...prev,
//         keywords: response.data.results.monitored_keywords.join(", "),
//       }));
//       setNextPageUrl(response.data.next);
//       setLoadingMore(false);
//     } catch (error) {
//       console.error("Error fetching user insights:", error);
//       setLoadingMore(false);
//     }
//   };
//   const fetchSuggestedKeywords = async (keywords) => {
//     try {
//       const response = await axios.get(`https://api.datamuse.com/words?ml=${encodeURIComponent(keywords)}`);
//       setSuggestedKeywords(response.data.slice(0, 5));
//     } catch (error) {
//       console.error("Error fetching suggested keywords:", error);
//     }
//   };

//   useEffect(() => {
//     if (monitoringConfig.keywords.trim().length > 2) {
//       fetchSuggestedKeywords(monitoringConfig.keywords);
//     }
//   }, [monitoringConfig.keywords]);

//   const checkAndPerformAction = (currentCredits, actionCallback) => {
//     if (currentCredits <= 0 && userDetails?.current_plan === "Free") {
//       //   setShowUpgradeModal(true);
//       setShowUpgradeModal?.(true);

//       return;
//     }
//     actionCallback();
//   };

//   useEffect(() => {
//     if (insights && insights.length > 0) {
//       // Find the most recent `created_at` timestamp
//       const mostRecentInsight = insights.reduce((latest, insight) => {
//         const insightDate = new Date(insight.created_at);
//         return insightDate > latest ? insightDate : latest;
//       }, new Date(insights[0].created_at));

//       // Format the date to something like "3 minutes ago"
//       const formattedDate = formatDistanceToNow(new Date(mostRecentInsight), { addSuffix: true });
//       setLastUpdated(formattedDate);
//     }
//   }, [insights]);

//   useEffect(() => {
//     if (insights && insights.length > 0 && !loadingMore && !nextPageUrl && userDetails?.current_plan === "Free") {
//       setReachedFreePreviewEnd(true);
//     }
//   }, [insights, loadingMore, nextPageUrl, userDetails]);

//   const handleSparkIdea = (painpointId) => {
//     checkAndPerformAction(sparkIdeaCredits, async () => {
//       setIsSparkingIdea(true);
//       setShowSparkIdeaModal(true);
//       try {
//         const response = await axios.post("/spark-idea/", { painpoint_id: painpointId });
//         setSparkedIdea(response.data.sparked_idea);
//       } catch (error) {
//         console.error("Error sparking idea:", error);
//         // Handle error (e.g., show an error message to the user)
//       } finally {
//         setIsSparkingIdea(false);
//       }
//     });
//   };

//   const handleMonitoringSetup = async (e) => {
//     e.preventDefault();
//     if (insightCredits <= 0 && currentPlan !== "Pioneer") {
//       //   setShowUpgradeModal(true);
//       setShowUpgradeModal?.(true);

//       return;
//     }
//     setIsGeneratingInsights(true);
//     setGenerationProgress(0);
//     const progressInterval = setInterval(() => {
//       setGenerationProgress((prev) => (prev >= 90 ? prev : prev + 1));
//     }, 1500);
//     try {
//       const keywords = monitoringConfig.keywords
//         .split(",")
//         .map((kw) => kw.trim())
//         .filter((kw) => kw.length > 0);
//       if (keywords.length === 0) {
//         setIsGeneratingInsights(false);
//         clearInterval(progressInterval);
//         return alert("Please enter at least one valid keyword.");
//       }
//       const response = await axios.post("generate-insights/", { interests: keywords });
//       if (response.status === 200) {
//         await fetchUserInsights();
//         const creditsResponse = await axios.get("/user-details/");
//         setUserDetails(creditsResponse.data);
//         setInsightCredits(creditsResponse.data.insight_credits);
//         setSparkIdeaCredits(creditsResponse.data.spark_idea_credits);
//         setGenerationProgress(100);
//         setTimeout(() => window.location.reload(), 500);
//       }
//     } catch (error) {
//       console.error("Error generating insights or updating credits:", error);
//       setIsGeneratingInsights(false);
//       clearInterval(progressInterval);
//       alert("Something went wrong. We have been notified.");
//     } finally {
//       clearInterval(progressInterval);
//     }
//   };

//   const handleNewInsight = () => {
//     checkAndPerformAction(insightCredits, async () => {
//       // Existing logic for generating insights
//       setIsGeneratingInsights(true);
//       setGenerationProgress(0);
//       const progressInterval = setInterval(() => {
//         setGenerationProgress((prev) => (prev >= 90 ? prev : prev + 1));
//       }, 1500);
//       try {
//         const keywords = monitoringConfig.keywords
//           .split(",")
//           .map((kw) => kw.trim())
//           .filter((kw) => kw.length > 0);
//         if (keywords.length === 0) {
//           setIsGeneratingInsights(false);
//           clearInterval(progressInterval);
//           return alert("Please enter at least one valid keyword.");
//         }
//         const response = await axios.post("generate-insights/", { interests: keywords });
//         if (response.status === 200) {
//           await fetchUserInsights();
//           const creditsResponse = await axios.get("/user-details/");
//           setUserDetails(creditsResponse.data);
//           setInsightCredits(creditsResponse.data.insight_credits);
//           setSparkIdeaCredits(creditsResponse.data.spark_idea_credits);
//           setGenerationProgress(100);
//           setTimeout(() => window.location.reload(), 500);
//         }
//       } catch (error) {
//         console.error("Error generating insights or updating credits:", error);
//         setIsGeneratingInsights(false);
//         clearInterval(progressInterval);
//         alert("Something went wrong. We have been notified.");
//       } finally {
//         clearInterval(progressInterval);
//       }
//     });
//   };

//   const handleConfigChange = (e) => {
//     const { name, value } = e.target;
//     setMonitoringConfig((prev) => ({ ...prev, [name]: value }));
//   };

//   const toggleExpand = (index) => {
//     setExpandedCards((prev) => (prev.includes(index) ? prev.filter((i) => i !== index) : [...prev, index]));
//   };

//   const loadMoreInsights = useCallback(() => {
//     if (nextPageUrl && !loadingMore) {
//       setLoadingMore(true);
//       fetchUserInsights(nextPageUrl);
//     }
//   }, [nextPageUrl, loadingMore]);

//   useEffect(() => {
//     if (userDetails) {
//       fetchUserInsights();
//       setInsightCredits(userDetails.insight_credits || 0);
//       setSparkIdeaCredits(userDetails.spark_idea_credits || 0);
//       setInsightreportCredits(userDetails.ai_insights_reports || 0);
//       setCurrentPlan(userDetails.current_plan || "Free");

//       // Fetch liked pain points
//       axios
//         .get("/list-liked/")
//         .then((response) => {
//           const likedIds = new Set(response.data.map((item) => item.id));
//           setLikedPainPoints(likedIds);
//         })
//         .catch((error) => console.error("Error fetching liked pain points:", error));

//       // Fetch bookmarked pain points
//       axios
//         .get("/list-bookmarks/")
//         .then((response) => {
//           const bookmarkedIds = new Set(response.data.map((item) => item.id));
//           setBookmarkedPainPoints(bookmarkedIds);
//         })
//         .catch((error) => console.error("Error fetching bookmarked pain points:", error));
//     }
//   }, [userDetails]);

//   const handleToggleLike = async (painpointId) => {
//     try {
//       const response = await axios.post(`/toggle-like/${painpointId}/`);
//       setLikedPainPoints((prev) => {
//         const newSet = new Set(prev);
//         if (response.data.liked) {
//           newSet.add(painpointId);
//         } else {
//           newSet.delete(painpointId);
//         }
//         return newSet;
//       });
//     } catch (error) {
//       console.error("Error toggling like:", error);
//     }
//   };

//   const handleToggleBookmark = async (painpointId) => {
//     try {
//       const response = await axios.post(`/toggle-bookmark/${painpointId}/`);
//       setBookmarkedPainPoints((prev) => {
//         const newSet = new Set(prev);
//         if (response.data.bookmarked) {
//           newSet.add(painpointId);
//         } else {
//           newSet.delete(painpointId);
//         }
//         return newSet;
//       });
//     } catch (error) {
//       console.error("Error toggling bookmark:", error);
//     }
//   };

//   useEffect(() => {
//     const handleScroll = () => {
//       if (window.innerHeight + document.documentElement.scrollTop >= document.documentElement.offsetHeight - 100) {
//         loadMoreInsights();
//       }
//     };
//     window.addEventListener("scroll", handleScroll);
//     return () => window.removeEventListener("scroll", handleScroll);
//   }, [loadMoreInsights]);

//   const KeywordSetupForm = () => {
//     const [localKeywords, setLocalKeywords] = useState(monitoringConfig.keywords);

//     const handleLocalChange = (e) => {
//       setLocalKeywords(e.target.value);
//     };

//     const handleBlur = (e) => {
//       handleConfigChange(e);
//     };

//     return (
//       <motion.div
//         initial={{ opacity: 0, y: 20 }}
//         animate={{ opacity: 1, y: 0 }}
//         exit={{ opacity: 0, y: -20 }}
//         className="bg-white rounded-2xl shadow-lg p-4 sm:p-8 mb-8"
//       >
//         <form onSubmit={handleMonitoringSetup} className="space-y-6">
//           <div>
//             <label htmlFor="keywords" className="block text-sm font-medium text-gray-700 mb-1">
//               Keywords to monitor (min 2 keywords)
//             </label>
//             <input
//               type="text"
//               id="keywords"
//               name="keywords"
//               value={localKeywords}
//               onChange={handleLocalChange}
//               onBlur={handleBlur}
//               placeholder="Eg: Meal delivery service, Food subscription box"
//               className="w-full px-4 py-3 border border-gray-300 rounded-xl focus:outline-none focus:ring-2 focus:ring-blue-500 text-base sm:text-lg"
//             />
//           </div>
//           <button
//             type="submit"
//             className="w-full bg-blue-600 text-white py-3 px-6 rounded-xl hover:bg-blue-700 transition-colors duration-200 text-base sm:text-lg font-semibold flex items-center justify-center"
//           >
//             <Search className="mr-2" size={20} />
//             {isNewUser ? "Start Monitoring" : "Update Keywords"}
//           </button>
//         </form>
//         <div className="mt-6 p-4 bg-blue-50 rounded-xl flex items-start">
//           <AlertCircle className="text-blue-500 mr-2 flex-shrink-0" size={20} />
//           <p className="text-sm text-blue-700">We also monitor related keywords for comprehensive insights.</p>
//         </div>
//       </motion.div>
//     );
//   };
//   const UpgradeModal = ({ show, onClose }) => {
//     if (!show) return null;

//     return (
//       <div className="fixed inset-0 bg-black bg-opacity-50 z-50 flex items-center justify-center p-4">
//         <div className="bg-white rounded-lg p-6 w-full max-w-md">
//           <h3 className="text-lg font-medium leading-6 text-gray-900 mb-4">Upgrade Your Plan</h3>
//           <p className="text-gray-600 mb-4">
//             You've run out of credits. Upgrade your plan to continue generating insights and sparking ideas!
//           </p>
//           <div className="flex justify-end space-x-2">
//             <button
//               onClick={() => {
//                 console.log("Closing modal with Maybe Later");
//                 onClose(); // Close the modal
//               }}
//               className="w-full mt-4 text-gray-600 hover:text-gray-800 transition-colors"
//             >
//               Maybe Later
//             </button>
//             <button
//               onClick={() => {
//                 console.log("Navigating to subscriptions and closing modal");
//                 onClose(); // Close the modal
//                 setActiveTab("subscriptions"); // Navigate to the Subscriptions tab
//               }}
//               className="w-full bg-blue-600 text-white py-3 rounded-xl font-semibold hover:bg-blue-700 transition-colors"
//             >
//               Upgrade Now
//             </button>
//           </div>
//         </div>
//       </div>
//     );
//   };

//   return (
//     <div className="font-sans bg-gradient-to-b from-green-50 to-white min-h-screen">
//       <DashboardHeader />
//       <div className="space-y-8 max-w-4xl mx-auto px-4 sm:px-6 lg:px-8">
//         <AnimatePresence>
//           {isGeneratingInsights && (
//             <motion.div
//               initial={{ opacity: 0 }}
//               animate={{ opacity: 1 }}
//               exit={{ opacity: 0 }}
//               className="fixed inset-0 bg-black bg-opacity-50 z-50 flex items-center justify-center p-4"
//             >
//               <motion.div
//                 initial={{ scale: 0.9, opacity: 0 }}
//                 animate={{ scale: 1, opacity: 1 }}
//                 exit={{ scale: 0.9, opacity: 0 }}
//                 className="bg-white rounded-2xl p-6 sm:p-8 w-full max-w-md shadow-xl"
//               >
//                 <h3 className="text-xl sm:text-2xl font-semibold mb-4 text-gray-900">Generating Premium Insights</h3>
//                 <div className="w-full bg-gray-200 rounded-full h-3 mb-4">
//                   <motion.div
//                     className="bg-blue-600 h-3 rounded-full"
//                     initial={{ width: 0 }}
//                     animate={{ width: `${generationProgress}%` }}
//                     transition={{ duration: 0.5 }}
//                   />
//                 </div>
//                 <p className="text-sm sm:text-base text-gray-600">Analyzing market trends, user needs, and potential opportunities...</p>
//                 <p className="text-sm text-gray-500 mt-2">This may take up to 2-3 minutes. Please don't close this window.</p>
//               </motion.div>
//             </motion.div>
//           )}
//         </AnimatePresence>

//         <div className="max-w-4xl mx-auto px-4 sm:px-6 lg:px-4 py-2 space-y-2">
//           <motion.div
//             initial={{ opacity: 0, y: -20 }}
//             animate={{ opacity: 1, y: 0 }}
//             className="bg-gradient-to-br from-blue-800 via-indigo-900 to-purple-900 rounded-3xl p-8 sm:p-12 text-white shadow-xl transform transition-all duration-500 hover:shadow-2xl hover:scale-[1.02]"
//           >
//             {/* Section Title */}
//             <div className="flex items-center space-x-4 mb-8">
//               <Sparkles className="w-10 h-10 text-yellow-300 animate-pulse" />
//               <h2 className="text-3xl sm:text-3xl font-bold tracking-tight">🚀 Introducing: AI Market Gap Finder</h2>
//             </div>

//             {/* Description */}
//             <p className="text-white/80 text-lg mb-6">
//               ✨ Discover Untapped Opportunities in Real-Time. Leverage the power of AI to analyze live social media conversations and
//               uncover new business ideas, feature requests, unmet needs, and market gaps—all at your fingertips.
//             </p>
//           </motion.div>
//           <div className="bg-white rounded-2xl shadow-lg border border-gray-100 overflow-hidden">
//             <div className="p-6 sm:p-8 space-y-6">
//               <div className="flex flex-col sm:flex-row justify-between items-start sm:items-center space-y-4 sm:space-y-0">
//                 <h2 className="text-2xl sm:text-3xl font-semibold text-gray-900">Keywords Monitoring</h2>
//               </div>

//               <div className="relative z-10">
//                 <input
//                   type="text"
//                   value={monitoringConfig.keywords || "No keywords set"}
//                   readOnly
//                   className="w-full bg-gray-50 border border-gray-200 rounded-xl py-3 px-4 pr-12 text-gray-700 focus:ring-0 focus:ring-blue-100 focus:outline-none transition-all duration-300 cursor-pointer"
//                   onClick={() => setShowKeywordModal(true)}
//                 />
//                 <button
//                   className="absolute right-3 top-1/2 transform -translate-y-1/2 text-gray-400 hover:text-blue-600 transition-all duration-300"
//                   onClick={() => setShowKeywordModal(true)}
//                 >
//                   <Pencil size={18} />
//                 </button>
//               </div>

//               <div className="bg-blue-50 rounded-xl p-4 flex items-start space-x-3">
//                 <Info size={18} className="text-blue-400 flex-shrink-0 mt-0.5" />
//                 <div>
//                   <p className="text-sm text-gray-700 font-medium mb-2">Example Keywords:</p>
//                   <div className="flex flex-wrap gap-2">
//                     {suggestedKeywords1.map((keyword, index) => (
//                       <span key={index} className="bg-blue-100 text-blue-800 px-2 py-1 rounded-full text-xs font-medium">
//                         {keyword}
//                       </span>
//                     ))}
//                   </div>
//                 </div>
//               </div>

//               <button
//                 className="w-full flex items-center justify-center bg-blue-600 hover:bg-blue-700 text-white text-base font-medium py-3 px-6 rounded-xl transition-all duration-300 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2 shadow-md"
//                 onClick={handleNewInsight}
//                 disabled={isGeneratingInsights}
//               >
//                 <Sparkles size={18} className="mr-2" />
//                 {isGeneratingInsights ? "Generating..." : "New Insight"}
//               </button>
//             </div>

//             <div className="bg-gray-50 px-6 sm:px-8 py-4 flex flex-col sm:flex-row justify-between items-start sm:items-center space-y-2 sm:space-y-0 border-t border-gray-200">
//               <div className="text-sm">
//                 <span className="text-gray-500">Insight Credits: </span>
//                 <span className="font-semibold text-gray-900">{insightCredits}</span>
//               </div>
//               {/* <div className="text-sm">
//               <span className="text-gray-500">Spark Idea Credits: </span>
//               <span className="font-semibold text-gray-900">{sparkIdeaCredits}</span>
//             </div> */}
//               <div className="text-sm">
//                 <span className="text-gray-500">Full Report Credits: </span>
//                 <span className="font-semibold text-gray-900">{insightreportCredits}</span>
//               </div>
//             </div>
//           </div>
//         </div>

//         <>
//           {insights && insights.length > 0 ? (
//             <div>
//               <h3 className="text-2xl font-bold text-gray-900 mb-6">Your AI-Discovered Opportunities</h3>
//               {/* {lastUpdated && (
//               <p className="text-gray-500 text-sm">
//                 Last Updated: <span className="font-medium">{lastUpdated}</span>
//               </p>
//             )} */}
//               <div className="space-y-8">
//                 {insights.map((insight, index) => (
//                   <motion.div
//                     key={insight.id}
//                     initial={{ opacity: 0, y: 20 }}
//                     animate={{ opacity: 1, y: 0 }}
//                     transition={{ delay: index * 0.1 }}
//                     className="bg-white rounded-2xl shadow-lg overflow-hidden transition-all duration-300 hover:shadow-xl border border-gray-100"
//                   >
//                     <div className="p-4 sm:p-8">
//                       <div className="flex flex-col sm:flex-row justify-between items-start mb-6">
//                         <h3 className="text-xl sm:text-2xl font-bold text-gray-900 leading-tight pr-4 mb-2 sm:mb-0">
//                           {insight.details?.pain_point || "No Pain Point Provided"}
//                         </h3>

//                         <div className="flex items-center space-x-2">
//                           <span className="text-xs text-gray-500 bg-gray-100 px-2 py-1 rounded-full flex items-center">
//                             <RefreshCw size={12} className="mr-1" />
//                             {formatDistanceToNow(new Date(insight.created_at), { addSuffix: true })}
//                           </span>
//                           <button
//                             className={`p-2 rounded-full ${
//                               bookmarkedPainPoints.has(insight.id) ? "bg-blue-100 text-blue-600" : "bg-gray-100 text-gray-600"
//                             } hover:bg-blue-200 transition-colors duration-200`}
//                             title="Save to My Library"
//                             onClick={() => handleToggleBookmark(insight.id)}
//                           >
//                             <Bookmark size={20} />
//                           </button>
//                         </div>
//                       </div>

//                       <div className="mb-8">
//                         <p className="text-gray-700 bg-gray-50 p-4 sm:p-6 rounded-xl border-l-4 border-blue-500 text-base sm:text-lg italic">
//                           "{insight.details?.problem_synopsis || "No problem synopsis available."}"
//                         </p>
//                       </div>

//                       <div className="grid grid-cols-1 sm:grid-cols-3 gap-4 sm:gap-6 mb-8">
//                         <div className="bg-blue-50 p-4 rounded-xl text-center">
//                           <Zap className="text-blue-500 text-3xl mx-auto mb-2" />
//                           <span className="block text-sm font-medium text-gray-600 mb-1">Market Opportunity</span>
//                           <span className="block text-xl font-bold text-gray-900"> {insight.details.market_opportunity[0] || "N/A"}</span>
//                         </div>
//                         <div className="bg-green-50 p-4 rounded-xl text-center">
//                           <Users className="text-green-500 text-3xl mx-auto mb-2" />
//                           <span className="block text-sm font-medium text-gray-600 mb-1">Users Affected</span>
//                           <span className="block text-xl font-bold text-gray-900">{insight.details?.users_affected || "N/A"}</span>
//                         </div>
//                         {/* <div className="bg-purple-50 p-4 rounded-xl text-center">
//                           <TrendingUp className="text-purple-500 text-3xl mx-auto mb-2" />
//                           <span className="block text-sm font-medium text-gray-600 mb-1">Frequency</span>
//                           <span className="block text-xl font-bold text-gray-900">{insight.details?.frequency || "N/A"}</span>
//                         </div> */}

//                         <div className="bg-purple-50 p-4 rounded-xl text-center">
//                           <TrendingUp className="text-purple-500 text-3xl mx-auto mb-2" />
//                           <span className="block text-sm font-medium text-gray-600 mb-1">Tag</span>
//                           <span className="block text-xl font-bold text-gray-900">{insight.details?.tag || "N/A"}</span>
//                         </div>
//                       </div>

//                       {insight.details?.expert_insights && (
//                         <div className="mb-8">
//                           <h4 className="text-lg font-semibold mb-2 text-gray-800">Expert Insights</h4>
//                           <p className="text-gray-700">{insight.details.expert_insights}</p>
//                         </div>
//                       )}

//                       {/* <div className="grid grid-cols-1 sm:grid-cols-2 gap-8 mb-8">
//                       {insight.details?.market_opportunity && (
//                         <div>
//                           <h4 className="text-lg font-semibold mb-2 text-gray-800">Market Opportunity</h4>
//                           <ul className="list-disc pl-6 text-gray-700 space-y-2">
//                             {insight.details.market_opportunity.map((item, idx) => (
//                               <li key={idx}>{item}</li>
//                             ))}
//                           </ul>
//                         </div>
//                       )}
//                       {insight.details?.revenue_potential && (
//                         <div>
//                           <h4 className="text-lg font-semibold mb-2 text-gray-800">Revenue Potential</h4>
//                           <ul className="list-disc pl-6 text-gray-700 space-y-2">
//                             {insight.details.revenue_potential.map((item, idx) => (
//                               <li key={idx}>{item}</li>
//                             ))}
//                           </ul>
//                         </div>
//                       )}
//                     </div> */}

//                       <div className="flex flex-col sm:flex-row space-y-4 sm:space-y-0 sm:space-x-4 mb-8">
//                         <button
//                           onClick={() => toggleExpand(index)}
//                           className="flex-1 bg-blue-600 text-white py-3 px-6 rounded-xl hover:bg-blue-700 transition-all duration-200 flex items-center justify-center shadow-md text-base sm:text-lg font-semibold"
//                         >
//                           {expandedCards.includes(index) ? (
//                             <>
//                               <ChevronUp size={24} className="mr-2" />
//                               Hide Additional Details
//                             </>
//                           ) : (
//                             <>
//                               <ChevronDown size={24} className="mr-2" />
//                               View Additional Details
//                             </>
//                           )}
//                         </button>
//                         <button
//                           onClick={() => {
//                             if (sparkIdeaCredits <= 0) {
//                               //   setShowUpgradeModal(true);
//                               setShowUpgradeModal?.(true);
//                             } else {
//                               handleSparkIdea(insight.id);
//                             }
//                           }}
//                           className={`flex-1 py-3 px-6 rounded-xl transition-all duration-200 flex items-center justify-center shadow-md text-base sm:text-lg font-semibold ${
//                             sparkIdeaCredits > 0 ? "bg-green-600 text-white hover:bg-green-700" : "bg-gray-400 cursor-not-allowed"
//                           }`}
//                           disabled={sparkIdeaCredits <= 0}
//                         >
//                           <Zap size={24} className="mr-2" /> Spark an Idea
//                         </button>

//                         <button
//                           onClick={() => {
//                             if (insightreportCredits <= 0 && !generatedReports.has(insight.id)) {
//                               // If the user has no credits and hasn't generated the report, show the upgrade modal
//                               //   setShowUpgradeModal(true);
//                               setShowUpgradeModal?.(true);
//                             } else {
//                               // Otherwise, proceed with viewing the full report
//                               handleViewFullReport(insight.id);
//                             }
//                           }}
//                           className={`flex-1 py-3 px-6 rounded-xl transition-all duration-200 flex items-center justify-center shadow-md text-base sm:text-lg font-semibold ${
//                             generatedReports.has(insight.id)
//                               ? "bg-green-600 text-white hover:bg-green-700" // Style for already generated reports
//                               : insightreportCredits > 0
//                               ? "bg-purple-600 text-white hover:bg-purple-700" // Style for generating a new report with available credits
//                               : "bg-gray-400 cursor-not-allowed" // Style for no credits and no report generated
//                           }`}
//                           disabled={insightreportCredits <= 0 && !generatedReports.has(insight.id)} // Disable if no credits and no report generated
//                         >
//                           <FileText size={24} className="mr-2" />
//                           {generatedReports.has(insight.id) ? "View Generated Business Report" : "View Full Business Report"}{" "}
//                           {/* Button text */}
//                         </button>
//                       </div>

//                       <AnimatePresence>
//                         {expandedCards.includes(index) && (
//                           <motion.div
//                             initial={{ opacity: 0, height: 0 }}
//                             animate={{ opacity: 1, height: "auto" }}
//                             exit={{ opacity: 0, height: 0 }}
//                             transition={{ duration: 0.3 }}
//                             className="space-y-8 overflow-hidden"
//                           >
//                             {insight.details?.competitive_landscape && (
//                               <div className="bg-blue-50 p-4 sm:p-6 rounded-xl">
//                                 <h4 className="text-lg font-semibold mb-3 text-gray-800">Competitive Landscape</h4>
//                                 <ul className="list-disc pl-6 text-gray-700 space-y-2">
//                                   {insight.details.competitive_landscape.map((item, idx) => (
//                                     <li key={idx}>{item}</li>
//                                   ))}
//                                 </ul>
//                               </div>
//                             )}
//                             {insight.details?.customer_segmentation && (
//                               <div className="bg-green-50 p-4 sm:p-6 rounded-xl">
//                                 <h4 className="text-lg font-semibold mb-3 text-gray-800">Customer Segmentation</h4>
//                                 <ul className="list-disc pl-6 text-gray-700 space-y-2">
//                                   {insight.details.customer_segmentation.map((item, idx) => (
//                                     <li key={idx}>{item}</li>
//                                   ))}
//                                 </ul>
//                               </div>
//                             )}
//                             {insight.details?.implementation_roadmap && (
//                               <div className="bg-yellow-50 p-4 sm:p-6 rounded-xl">
//                                 <h4 className="text-lg font-semibold mb-3 text-gray-800">Implementation Roadmap</h4>
//                                 <ol className="list-decimal pl-6 text-gray-700 space-y-2">
//                                   {insight.details.implementation_roadmap.map((item, idx) => (
//                                     <li key={idx}>{item}</li>
//                                   ))}
//                                 </ol>
//                               </div>
//                             )}
//                             {insight.details?.risk_assessment && (
//                               <div className="bg-red-50 p-4 sm:p-6 rounded-xl">
//                                 <h4 className="text-lg font-semibold mb-3 text-gray-800">Risk Assessment</h4>
//                                 <ul className="list-disc pl-6 text-gray-700 space-y-2">
//                                   {insight.details.risk_assessment.map((item, idx) => (
//                                     <li key={idx}>{item}</li>
//                                   ))}
//                                 </ul>
//                               </div>
//                             )}
//                             {insight.details?.success_metrics && (
//                               <div className="bg-indigo-50 p-4 sm:p-6 rounded-xl">
//                                 <h4 className="text-lg font-semibold mb-3 text-gray-800">Success Metrics</h4>
//                                 <ul className="list-disc pl-6 text-gray-700 space-y-2">
//                                   {insight.details.success_metrics.map((item, idx) => (
//                                     <li key={idx}>{item}</li>
//                                   ))}
//                                 </ul>
//                               </div>
//                             )}
//                           </motion.div>
//                         )}
//                       </AnimatePresence>
//                     </div>
//                   </motion.div>
//                 ))}
//               </div>
//               {loadingMore && (
//                 <div className="text-center py-4">
//                   <Loader2 className="animate-spin mx-auto" size={24} />
//                   <p className="text-gray-600 mt-2">Loading more insights...</p>
//                 </div>
//               )}
//               {/* {!loadingMore && !nextPageUrl && <p className="text-center text-gray-600 mt-8">That's all the insights for now.</p>} */}
//             </div>
//           ) : (
//             <div className="text-center py-12">
//               <p className="text-gray-600 text-lg">
//                 No insights available at the moment. Please start monitoring a trend or keyword to generate insights.
//               </p>
//             </div>
//           )}
//         </>

//         {showKeywordModal && (
//           <div className="fixed inset-0 bg-black bg-opacity-50 z-50 flex items-center justify-center p-4">
//             <div className="bg-white rounded-lg p-6 w-full max-w-md">
//               <h3 className="text-lg font-medium leading-6 text-gray-900 mb-4">Edit Monitoring Keywords</h3>
//               <input
//                 type="text"
//                 value={monitoringConfig.keywords}
//                 onChange={handleConfigChange}
//                 name="keywords"
//                 className="w-full px-3 py-2 text-gray-700 border rounded-lg focus:outline-none focus:border-blue-500 mb-4"
//                 placeholder="Enter keywords separated by commas"
//               />
//               <div className="flex justify-end space-x-2">
//                 <button
//                   type="button"
//                   className="px-4 py-2 text-sm font-medium text-gray-700 bg-gray-100 rounded-md hover:bg-gray-200 focus:outline-none focus:ring-2 focus-ring-offset-2 focus:ring-blue-500"
//                   onClick={() => setShowKeywordModal(false)}
//                 >
//                   Cancel
//                 </button>
//                 <button
//                   type="button"
//                   className="px-4 py-2 text-sm font-medium text-white bg-blue-600 rounded-md hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
//                   onClick={() => {
//                     handleMonitoringSetup({ preventDefault: () => {} });
//                     setShowKeywordModal(false);
//                   }}
//                 >
//                   Save Changes
//                 </button>
//               </div>
//             </div>
//           </div>
//         )}
//         {/* <UpgradeModal show={insightCredits <= 0 || sparkIdeaCredits <= 0} onClose={() => setShowUpgradeModal(false)} /> */}
//         <UpgradeModal
//           show={showUpgradeModal} // Correctly using showUpgradeModal here
//           onClose={() => {
//             console.log("onClose called, setting showUpgradeModal to false");
//             setShowUpgradeModal(false);
//           }}
//         />
//         {showSparkIdeaModal && (
//           <SparkIdeaModal
//             idea={sparkedIdea}
//             onClose={() => {
//               setShowSparkIdeaModal(false);
//               setSparkedIdea(null);
//               window.location.reload();
//             }}
//             isLoading={isSparkingIdea}
//           />
//         )}

//         {reachedFreePreviewEnd && <NeedFinderAIUpgradeTeaser setActiveTab={setActiveTab} />}

//         {/* <FullReportSidebar isOpen={showFullReportModal} closeReport={() => setShowFullReportModal(false)} insightId={selectedInsightId} /> */}
//         {/* <FullReportSidebar isOpen={showFullReportModal} closeReport={() => setShowFullReportModal(false)} insightId={selectedInsightId} /> */}
//         <FullReportSidebar
//           isOpen={showFullReportModal}
//           closeReport={() => setShowFullReportModal(false)}
//           insightId={selectedInsightId}
//           refreshCredits={() => fetchUserDetails()}
//           setShowUpgradeModal={setShowUpgradeModal}
//           insightreportCredits={insightreportCredits}
//         />
//       </div>
//     </div>
//   );
// };

// const SparkIdeaModal = ({ idea, onClose, isLoading }) => {
//   if (!idea && !isLoading) return null;

//   return (
//     <div className="fixed inset-0 bg-black bg-opacity-80 z-50 flex items-center justify-center p-4 overflow-y-auto">
//       <div className="bg-white rounded-2xl p-6 sm:p-8 w-full max-w-4xl max-h-[90vh] overflow-y-auto">
//         {isLoading ? (
//           <div className="flex flex-col items-center justify-center h-64">
//             <Loader2 className="animate-spin text-blue-600 mb-4" size={48} />
//             <p className="text-lg text-gray-700">Generating your brilliant idea...</p>
//           </div>
//         ) : (
//           <div className="sparked-idea space-y-6 sm:space-y-8">
//             <div>
//               <h1 className="text-3xl sm:text-4xl font-bold mb-2 text-blue-600">{idea.idea_name}</h1>
//               <p className="text-xl text-gray-600 italic">{idea.tagline}</p>
//             </div>

//             <section>
//               <h2 className="text-2xl font-semibold mb-2 text-gray-800">Vision</h2>
//               <p className="text-gray-700">{idea.vision}</p>
//             </section>

//             <section>
//               <h2 className="text-2xl font-semibold mb-2 text-gray-800">Core Concept</h2>
//               <p className="text-gray-700">{idea.core_concept}</p>
//             </section>

//             <section>
//               <h2 className="text-2xl font-semibold mb-2 text-gray-800">Key Features</h2>
//               <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
//                 {idea.key_features.map((feature, index) => (
//                   <div key={index} className="bg-gray-50 p-4 rounded-xl">
//                     <h3 className="text-lg font-semibold text-gray-800 mb-2">{feature.feature_name}</h3>
//                     <p className="text-gray-600">{feature.description}</p>
//                   </div>
//                 ))}
//               </div>
//             </section>

//             <section>
//               <h2 className="text-2xl font-semibold mb-2 text-gray-800">Market Potential</h2>
//               <p className="text-gray-700">{idea.market_potential}</p>
//             </section>

//             <section>
//               <h2 className="text-2xl font-semibold mb-2 text-gray-800">Execution Challenges</h2>
//               {idea.execution_challenges.map((challenge, index) => (
//                 <div key={index} className="mb-4">
//                   <h3 className="text-lg font-semibold text-gray-800">{challenge.challenge_name}</h3>
//                   <p className="text-gray-600">{challenge.description}</p>
//                 </div>
//               ))}
//             </section>

//             <section>
//               <h2 className="text-2xl font-semibold mb-2 text-gray-800">Future Implications</h2>
//               <p className="text-gray-700">{idea.future_implications}</p>
//             </section>

//             <button
//               onClick={onClose}
//               className="mt-8 w-full bg-blue-600 text-white py-3 px-6 rounded-xl hover:bg-blue-700 transition-all duration-200 flex items-center justify-center shadow-sm text-lg font-medium"
//             >
//               Close
//             </button>
//           </div>
//         )}
//       </div>
//     </div>
//   );
// };

// export default AIMarketGapFinder;

// import React from "react";
// import { TrendingUp, ThumbsDown, Timer, ArrowRight } from "lucide-react";

// const MagneticLanding = () => {
//   return (
//     <div className="min-h-screen bg-white">
//       {/* Instant Action Interface */}
//       <div className="min-h-screen flex">
//         {/* Main Prediction Area - Takes most of the screen */}
//         <div className="flex-1 flex flex-col">
//           {/* Super Simple Header */}
//           <div className="p-6">
//             <span className="text-xl font-bold">
//               meme<span className="text-blue-600">time</span>
//             </span>
//           </div>

//           {/* Centered Prediction Interface */}
//           <div className="flex-1 flex items-center justify-center px-6">
//             <div className="w-full max-w-2xl">
//               {/* Large, Prominent Meme Display */}
//               <div className="relative">
//                 <img src="/api/placeholder/800/600" alt="Fresh meme" className="w-full rounded-xl shadow-xl" />

//                 {/* View Counter - Premium Style */}
//                 <div className="absolute top-6 right-6 bg-black/80 backdrop-blur-sm text-white px-4 py-2 rounded-full text-lg font-medium flex items-center">
//                   <Timer className="w-5 h-5 mr-2 text-blue-400" />
//                   892 views
//                 </div>
//               </div>

//               {/* Large, Clear Action Buttons */}
//               <div className="flex gap-4 mt-8">
//                 <button className="flex-1 bg-white border-2 border-gray-200 hover:border-gray-300 text-gray-700 rounded-xl py-6 text-xl font-medium flex items-center justify-center group transition-all">
//                   <ThumbsDown className="w-6 h-6 mr-3 group-hover:scale-110 transition-transform" />
//                   Nah
//                 </button>
//                 <button className="flex-1 bg-blue-600 hover:bg-blue-500 text-white rounded-xl py-6 text-xl font-medium flex items-center justify-center group transition-all shadow-lg hover:shadow-xl">
//                   <TrendingUp className="w-6 h-6 mr-3 group-hover:scale-110 transition-transform" />
//                   Going Viral 🚀
//                 </button>
//               </div>
//             </div>
//           </div>
//         </div>

//         {/* Right Side Info - Takes less space */}
//         <div className="w-96 border-l bg-gray-50 p-8 flex flex-col">
//           <div className="flex-1">
//             <h1 className="text-2xl font-bold mb-4">
//               Be First.
//               <br />
//               Predict Viral Memes.
//             </h1>

//             <div className="space-y-6 mt-8">
//               {/* Live Activity */}
//               <div>
//                 <div className="text-sm text-gray-500 mb-2">Predicting Now</div>
//                 <div className="text-3xl font-bold text-blue-600">2,481</div>
//               </div>

//               {/* Success Rate */}
//               <div>
//                 <div className="text-sm text-gray-500 mb-2">Success Rate</div>
//                 <div className="text-3xl font-bold">89%</div>
//               </div>

//               {/* Quick Stats */}
//               <div className="bg-white rounded-lg p-4">
//                 <div className="flex items-center text-sm mb-2">
//                   <div className="w-2 h-2 bg-green-500 rounded-full mr-2"></div>
//                   <span>@memeking just predicted correctly</span>
//                 </div>
//                 <div className="flex items-center text-sm">
//                   <div className="w-2 h-2 bg-green-500 rounded-full mr-2"></div>
//                   <span>@viralhunter on 5 streak</span>
//                 </div>
//               </div>
//             </div>
//           </div>

//           {/* Chrome Extension CTA */}
//           <div className="bg-blue-600 text-white rounded-xl p-6">
//             <div className="text-lg font-medium mb-2">Get Chrome Extension</div>
//             <p className="text-blue-100 text-sm mb-4">Predict on every new tab. Never miss a viral meme.</p>
//             <button className="w-full bg-white text-blue-600 rounded-lg py-3 font-medium flex items-center justify-center group">
//               Add to Chrome
//               <ArrowRight className="w-5 h-5 ml-2 group-hover:translate-x-1 transition-transform" />
//             </button>
//           </div>
//         </div>
//       </div>
//     </div>
//   );
// };

// export default MagneticLanding;

// import React, { useState } from "react";
// import { TrendingUp, ThumbsDown, Timer, Chrome } from "lucide-react";

// const AddictiveLanding = () => {
//   const [hasVoted, setHasVoted] = useState(false);

//   return (
//     <div className="min-h-screen bg-white flex flex-col">
//       {/* Full-screen Meme View - Like TikTok */}
//       <div className="flex-1 relative overflow-hidden">
//         {/* The Meme */}
//         <div className="absolute inset-0 bg-black">
//           <img src="/api/placeholder/1200/800" alt="Fresh meme" className="w-full h-full object-contain" />
//         </div>

//         {/* Floating Stats - Instagram Style */}
//         <div className="absolute top-6 left-6 bg-black/80 backdrop-blur-md text-white px-4 py-2 rounded-full flex items-center text-sm font-medium">
//           <Timer className="w-4 h-4 mr-2" />
//           892 views • Could be next viral meme
//         </div>

//         {/* Center Message - Only shows before vote */}
//         {!hasVoted && (
//           <div className="absolute inset-0 flex items-center justify-center">
//             <div className="text-white text-center px-4 py-2 rounded-full bg-black/50 backdrop-blur-sm">Swipe up for 🚀 or down for 👎</div>
//           </div>
//         )}

//         {/* Huge Floating Action Buttons */}
//         <div className="absolute bottom-8 inset-x-0 flex justify-center gap-4 px-4">
//           <button
//             onClick={() => setHasVoted(true)}
//             className="flex-1 max-w-xs bg-white/10 backdrop-blur-md text-white border-2 border-white/30 hover:bg-white/20 rounded-2xl py-6 text-xl font-medium flex items-center justify-center group transition-all"
//           >
//             <ThumbsDown className="w-6 h-6 mr-3 group-hover:scale-110 transition-transform" />
//             Nah
//           </button>
//           <button
//             onClick={() => setHasVoted(true)}
//             className="flex-1 max-w-xs bg-blue-600/90 backdrop-blur-md hover:bg-blue-500 text-white rounded-2xl py-6 text-xl font-medium flex items-center justify-center group transition-all"
//           >
//             <TrendingUp className="w-6 h-6 mr-3 group-hover:scale-110 transition-transform" />
//             Going Viral 🚀
//           </button>
//         </div>

//         {/* Chrome Extension Prompt - After Vote */}
//         {hasVoted && (
//           <div className="absolute inset-0 bg-black/80 backdrop-blur-md flex items-center justify-center">
//             <div className="max-w-md px-6 py-8 text-center">
//               <h2 className="text-white text-3xl font-bold mb-4">Want to predict memes on every new tab?</h2>
//               <p className="text-gray-300 mb-6">Join 50K+ spotters finding viral memes first</p>
//               <button className="w-full bg-blue-600 hover:bg-blue-500 text-white rounded-xl py-4 text-lg font-medium flex items-center justify-center group mb-4">
//                 <Chrome className="w-5 h-5 mr-2" />
//                 Add to Chrome - It's Free
//               </button>
//               <button className="text-gray-400 hover:text-white transition-colors">Continue on Web</button>
//             </div>
//           </div>
//         )}
//       </div>

//       {/* Floating Progress Bar - Like Stories */}
//       <div className="absolute top-0 inset-x-0 h-1 bg-gray-800">
//         <div className="h-full bg-blue-500 w-1/2 transition-all duration-300" />
//       </div>

//       {/* Live Activity Feed - Snapchat Style */}
//       <div className="absolute right-6 bottom-32 flex flex-col gap-2">
//         <div className="bg-black/80 backdrop-blur-md text-white px-3 py-2 rounded-full text-sm animate-fade-in">
//           @memeking just predicted correctly 🎯
//         </div>
//         <div className="bg-black/80 backdrop-blur-md text-white px-3 py-2 rounded-full text-sm animate-fade-in delay-100">
//           2,481 predicting now
//         </div>
//       </div>

//       {/* Mini Stats - Subtle */}
//       <div className="absolute left-6 bottom-32 bg-black/80 backdrop-blur-md text-white px-4 py-2 rounded-full">
//         <div className="text-sm">Today's Viral Hits</div>
//         <div className="text-2xl font-bold">89% Success</div>
//       </div>
//     </div>
//   );
// };

// // Add some custom animations
// const styles = `
//   @keyframes fade-in {
//     from { opacity: 0; transform: translateY(10px); }
//     to { opacity: 1; transform: translateY(0); }
//   }
//   .animate-fade-in {
//     animation: fade-in 0.3s ease-out forwards;
//   }
//   .delay-100 {
//     animation-delay: 0.1s;
//   }
// `;

// export default AddictiveLanding;

// import React, { useState } from "react";
// import { TrendingUp, ThumbsDown, Timer, Share2, Bookmark, Eye } from "lucide-react";

// const ViralExperience = () => {
//   const [hasVoted, setHasVoted] = useState(false);
//   const [isSaved, setIsSaved] = useState(false);

//   return (
//     <div className="min-h-screen bg-black flex flex-col">
//       {/* Immersive Meme View */}
//       <div className="flex-1 relative overflow-hidden">
//         {/* The Meme */}
//         <div className="absolute inset-0">
//           <img src="/api/placeholder/1200/800" alt="Fresh meme" className="w-full h-full object-contain" />
//         </div>

//         {/* Floating View Counter - YouTube Style */}
//         <div className="absolute top-6 left-6 bg-black/80 backdrop-blur-md text-white px-4 py-2 rounded-full flex items-center font-medium">
//           <Eye className="w-4 h-4 mr-2 text-blue-400" />
//           892 views • Rising Fast 🔥
//         </div>

//         {/* Vertical Action Bar - TikTok Style */}
//         <div className="absolute right-6 bottom-32 flex flex-col items-center gap-6">
//           {/* Share Button */}
//           <button className="group flex flex-col items-center">
//             <div className="w-12 h-12 bg-black/60 backdrop-blur-md rounded-full flex items-center justify-center mb-1 group-hover:bg-blue-600/60 transition-colors">
//               <Share2 className="w-6 h-6 text-white" />
//             </div>
//             <span className="text-white text-xs">Share</span>
//           </button>

//           {/* Save Button */}
//           <button onClick={() => setIsSaved(!isSaved)} className="group flex flex-col items-center">
//             <div
//               className={`w-12 h-12 backdrop-blur-md rounded-full flex items-center justify-center mb-1 transition-colors ${
//                 isSaved ? "bg-blue-600/60" : "bg-black/60 group-hover:bg-blue-600/60"
//               }`}
//             >
//               <Bookmark className="w-6 h-6 text-white" />
//             </div>
//             <span className="text-white text-xs">{isSaved ? "Saved" : "Save"}</span>
//           </button>

//           {/* Views Milestone */}
//           <div className="bg-black/60 backdrop-blur-md text-white px-3 py-2 rounded-full text-sm text-center">
//             <div className="font-bold">892</div>
//             <div className="text-xs opacity-70">views</div>
//           </div>
//         </div>

//         {/* Main Action Buttons */}
//         <div className="absolute bottom-8 inset-x-0 flex justify-center gap-4 px-4">
//           <button
//             onClick={() => setHasVoted(true)}
//             className="flex-1 max-w-xs bg-white/10 backdrop-blur-md text-white border-2 border-white/30 hover:bg-white/20 rounded-2xl py-6 text-xl font-medium flex items-center justify-center group transition-all"
//           >
//             <ThumbsDown className="w-6 h-6 mr-3 group-hover:scale-110 transition-transform" />
//             Nah
//           </button>
//           <button
//             onClick={() => setHasVoted(true)}
//             className="flex-1 max-w-xs bg-blue-600/90 backdrop-blur-md hover:bg-blue-500 text-white rounded-2xl py-6 text-xl font-medium flex items-center justify-center group transition-all"
//           >
//             <TrendingUp className="w-6 h-6 mr-3 group-hover:scale-110 transition-transform" />
//             Going Viral 🚀
//           </button>
//         </div>

//         {/* Live Activity Pill */}
//         <div className="absolute left-6 bottom-32 bg-black/80 backdrop-blur-md rounded-full overflow-hidden">
//           <div className="px-4 py-3">
//             <div className="text-white text-sm flex items-center">
//               <span className="w-2 h-2 bg-green-500 rounded-full mr-2 animate-pulse" />
//               2,481 predicting now
//             </div>
//           </div>
//         </div>
//       </div>

//       {/* Progress Bar */}
//       <div className="absolute top-0 inset-x-0 h-1 bg-gray-800">
//         <div className="h-full bg-blue-500 w-1/2 transition-all duration-300" />
//       </div>

//       {/* Share Sheet - TikTok Style */}
//       {hasVoted && (
//         <div className="absolute inset-0 bg-black/80 backdrop-blur-md flex items-end justify-center animate-slide-up">
//           <div className="bg-white rounded-t-3xl w-full max-w-lg p-6">
//             <h3 className="text-xl font-bold mb-4">Share your prediction</h3>
//             <div className="grid grid-cols-4 gap-4 mb-6">
//               {["Twitter", "Discord", "WhatsApp", "Copy Link"].map((platform) => (
//                 <button key={platform} className="flex flex-col items-center">
//                   <div className="w-14 h-14 bg-gray-100 rounded-full mb-2" />
//                   <span className="text-sm">{platform}</span>
//                 </button>
//               ))}
//             </div>
//             <button className="w-full bg-blue-600 text-white rounded-xl py-4 font-medium">Add to Chrome & Track Predictions</button>
//           </div>
//         </div>
//       )}
//     </div>
//   );
// };

// // Add custom animations
// const styles = `
//   @keyframes slide-up {
//     from { transform: translateY(100%); }
//     to { transform: translateY(0); }
//   }
//   .animate-slide-up {
//     animation: slide-up 0.3s ease-out forwards;
//   }
// `;

// export default ViralExperience;

// import React, { useState } from "react";
// import { TrendingUp, ThumbsDown, Timer, Share2, Bookmark, Eye, Chrome, ArrowRight } from "lucide-react";

// const PerfectJourney = () => {
//   const [step, setStep] = useState("instant-action"); // instant-action, first-vote, chrome-prompt

//   return (
//     <div className="min-h-screen bg-black flex flex-col">
//       {/* Super Clear New User Guidance - Appears for 2 seconds */}
//       {step === "instant-action" && (
//         <div className="absolute inset-0 bg-black/80 z-50 flex items-center justify-center">
//           <div className="text-center px-4 animate-fade-in">
//             <div className="text-3xl font-bold text-white mb-4">Spot Viral Memes First 🚀</div>
//             <div className="flex justify-center gap-8 text-white/80 mb-8">
//               <div className="text-center">
//                 <div className="text-4xl mb-2">👆</div>
//                 <div>Predict</div>
//               </div>
//               <div className="text-center">
//                 <div className="text-4xl mb-2">🎯</div>
//                 <div>Win Points</div>
//               </div>
//               <div className="text-center">
//                 <div className="text-4xl mb-2">💫</div>
//                 <div>Level Up</div>
//               </div>
//             </div>
//             <button
//               onClick={() => setStep("main")}
//               className="bg-blue-600 text-white px-8 py-3 rounded-full text-lg font-medium hover:bg-blue-500 transition-colors"
//             >
//               Let's Go!
//             </button>
//           </div>
//         </div>
//       )}

//       {/* Main Prediction Interface */}
//       <div className="flex-1 relative overflow-hidden">
//         {/* The Meme */}
//         <div className="absolute inset-0">
//           <img src="/api/placeholder/1200/800" alt="Fresh meme" className="w-full h-full object-contain" />
//         </div>

//         {/* First-Time User Pointer - Shows briefly */}
//         {step === "main" && (
//           <div className="absolute inset-x-0 bottom-32 flex justify-center animate-bounce">
//             <div className="bg-blue-600/90 text-white px-6 py-3 rounded-full text-lg font-medium">Make your first prediction! 👇</div>
//           </div>
//         )}

//         {/* Views + Time */}
//         <div className="absolute top-6 left-6 flex gap-3">
//           <div className="bg-black/80 backdrop-blur-md text-white px-4 py-2 rounded-full flex items-center font-medium">
//             <Eye className="w-4 h-4 mr-2 text-blue-400" />
//             892 views
//           </div>
//           <div className="bg-black/80 backdrop-blur-md text-white px-4 py-2 rounded-full flex items-center font-medium">
//             <Timer className="w-4 h-4 mr-2 text-blue-400" />
//             15s left
//           </div>
//         </div>

//         {/* Right Side Actions */}
//         <div className="absolute right-6 bottom-32 flex flex-col items-center gap-6">
//           <button className="group flex flex-col items-center">
//             <div className="w-12 h-12 bg-black/60 backdrop-blur-md rounded-full flex items-center justify-center mb-1 group-hover:bg-blue-600/60 transition-colors">
//               <Share2 className="w-6 h-6 text-white" />
//             </div>
//             <span className="text-white text-xs">Share</span>
//           </button>

//           <button className="group flex flex-col items-center">
//             <div className="w-12 h-12 bg-black/60 backdrop-blur-md rounded-full flex items-center justify-center mb-1 group-hover:bg-blue-600/60 transition-colors">
//               <Bookmark className="w-6 h-6 text-white" />
//             </div>
//             <span className="text-white text-xs">Save</span>
//           </button>
//         </div>

//         {/* Main Action Buttons */}
//         <div className="absolute bottom-8 inset-x-0 flex justify-center gap-4 px-4">
//           <button
//             onClick={() => setStep("chrome-prompt")}
//             className="flex-1 max-w-xs bg-white/10 backdrop-blur-md text-white border-2 border-white/30 hover:bg-white/20 rounded-2xl py-6 text-xl font-medium flex items-center justify-center group transition-all"
//           >
//             <ThumbsDown className="w-6 h-6 mr-3 group-hover:scale-110 transition-transform" />
//             Nah
//           </button>
//           <button
//             onClick={() => setStep("chrome-prompt")}
//             className="flex-1 max-w-xs bg-blue-600/90 backdrop-blur-md hover:bg-blue-500 text-white rounded-2xl py-6 text-xl font-medium flex items-center justify-center group transition-all"
//           >
//             <TrendingUp className="w-6 h-6 mr-3 group-hover:scale-110 transition-transform" />
//             Going Viral 🚀
//           </button>
//         </div>

//         {/* Live Activity */}
//         <div className="absolute left-6 bottom-32 bg-black/80 backdrop-blur-md rounded-full">
//           <div className="px-4 py-3">
//             <div className="text-white text-sm flex items-center">
//               <span className="w-2 h-2 bg-green-500 rounded-full mr-2 animate-pulse" />
//               2,481 predicting now
//             </div>
//           </div>
//         </div>
//       </div>

//       {/* Chrome Extension Prompt - After First Vote */}
//       {step === "chrome-prompt" && (
//         <div className="absolute inset-0 bg-black/90 backdrop-blur-md flex items-center justify-center p-4">
//           <div className="max-w-md text-center">
//             <div className="bg-blue-600/20 w-16 h-16 rounded-full flex items-center justify-center mx-auto mb-6">
//               <Chrome className="w-8 h-8 text-blue-400" />
//             </div>
//             <h2 className="text-3xl font-bold text-white mb-4">Never Miss a Viral Meme!</h2>
//             <p className="text-gray-300 mb-8">Get instant predictions on every new tab</p>
//             <button className="w-full bg-blue-600 hover:bg-blue-500 text-white rounded-xl py-4 text-lg font-medium flex items-center justify-center group mb-4">
//               Add to Chrome - Free
//               <ArrowRight className="w-5 h-5 ml-2 group-hover:translate-x-1 transition-transform" />
//             </button>
//             <div className="flex justify-center gap-3 text-sm text-gray-400">
//               <span>50K+ users</span>
//               <span>•</span>
//               <span>⭐️ 4.9 rating</span>
//             </div>
//           </div>
//         </div>
//       )}
//     </div>
//   );
// };

// // Add custom animations
// const styles = `
//   @keyframes fade-in {
//     from { opacity: 0; transform: translateY(10px); }
//     to { opacity: 1; transform: translateY(0); }
//   }
//   .animate-fade-in {
//     animation: fade-in 0.3s ease-out forwards;
//   }
// `;

// export default PerfectJourney;

// import React, { useState } from "react";
// import { TrendingUp, ThumbsDown, Eye, Share2, Bookmark, Chrome, ArrowRight, Bell, Trophy } from "lucide-react";

// const CompleteJourney = () => {
//   const [step, setStep] = useState("first-view"); // first-view, prediction, chrome-offer, new-tab

//   return (
//     <div className="min-h-screen bg-white">
//       {/* Pure White Header - Minimal */}
//       <header className="border-b">
//         <div className="max-w-6xl mx-auto py-4 px-6 flex justify-between items-center">
//           <div className="text-xl font-medium">
//             meme<span className="text-blue-600">time</span>
//           </div>
//           <div className="flex items-center gap-4">
//             <div className="text-sm text-gray-500">2,481 predicting now</div>
//             <div className="h-4 w-px bg-gray-200"></div>
//             <div className="text-sm text-gray-500">89% success rate</div>
//           </div>
//         </div>
//       </header>

//       {/* Main Interface with Side Stats */}
//       <div className="flex h-[calc(100vh-73px)]">
//         {/* Main Prediction Area */}
//         <div className="flex-1 flex items-center justify-center p-8 relative">
//           {/* Clean Card Design */}
//           <div className="w-full max-w-2xl">
//             {/* View Counter + Timer */}
//             <div className="flex justify-between items-center mb-6">
//               <div className="flex gap-3">
//                 <div className="bg-gray-50 rounded-full py-2 px-4 flex items-center">
//                   <Eye className="w-4 h-4 text-gray-400 mr-2" />
//                   <span className="text-gray-900 font-medium">892 views</span>
//                 </div>
//                 <div className="bg-gray-50 rounded-full py-2 px-4">
//                   <span className="text-gray-900 font-medium">15s left</span>
//                 </div>
//               </div>
//             </div>

//             {/* The Meme */}
//             <div className="relative">
//               <div className="rounded-2xl overflow-hidden shadow-xl mb-6">
//                 <img src="/api/placeholder/1200/800" alt="Fresh meme" className="w-full" />
//               </div>

//               {/* Share & Save */}
//               <div className="absolute right-4 top-4 flex flex-col gap-3">
//                 <button className="bg-white/90 backdrop-blur-sm w-10 h-10 rounded-full shadow-lg flex items-center justify-center hover:bg-white transition-colors">
//                   <Share2 className="w-5 h-5 text-gray-700" />
//                 </button>
//                 <button className="bg-white/90 backdrop-blur-sm w-10 h-10 rounded-full shadow-lg flex items-center justify-center hover:bg-white transition-colors">
//                   <Bookmark className="w-5 h-5 text-gray-700" />
//                 </button>
//               </div>
//             </div>

//             {/* Action Buttons */}
//             <div className="flex gap-4">
//               <button
//                 onClick={() => setStep("chrome-offer")}
//                 className="flex-1 bg-white border-2 border-gray-100 hover:border-gray-200 rounded-xl py-5 flex items-center justify-center group transition-all"
//               >
//                 <ThumbsDown className="w-5 h-5 text-gray-400 mr-3 group-hover:scale-110 transition-transform" />
//                 <span className="text-gray-700 font-medium">Nah</span>
//               </button>

//               <button
//                 onClick={() => setStep("chrome-offer")}
//                 className="flex-1 bg-black text-white rounded-xl py-5 flex items-center justify-center group transition-colors hover:bg-gray-900"
//               >
//                 <TrendingUp className="w-5 h-5 mr-3 group-hover:scale-110 transition-transform" />
//                 <span className="font-medium">Going Viral 🚀</span>
//               </button>
//             </div>
//           </div>

//           {/* Chrome Extension Offer - Elegant */}
//           {step === "chrome-offer" && (
//             <div className="absolute inset-0 bg-white/95 backdrop-blur-sm flex items-center justify-center">
//               <div className="max-w-md text-center p-6">
//                 <div className="mb-6">
//                   <Chrome className="w-12 h-12 text-blue-600 mx-auto" />
//                 </div>
//                 <h2 className="text-2xl font-bold mb-2">Get Instant Meme Alerts</h2>
//                 <p className="text-gray-600 mb-6">Predict on every new tab. Never miss a viral meme.</p>
//                 <button className="w-full bg-blue-600 text-white rounded-xl py-4 flex items-center justify-center group mb-4 hover:bg-blue-500 transition-colors">
//                   <span className="font-medium">Add to Chrome - Free</span>
//                   <ArrowRight className="w-5 h-5 ml-2 group-hover:translate-x-1 transition-transform" />
//                 </button>
//                 <div className="text-gray-500 text-sm flex justify-center gap-3">
//                   <span>50K+ spotters</span>
//                   <span>•</span>
//                   <span>⭐️ 4.9 rating</span>
//                 </div>
//               </div>
//             </div>
//           )}
//         </div>

//         {/* Right Stats Panel - Clean & Informative */}
//         <div className="w-80 border-l bg-gray-50 p-8 flex flex-col">
//           <div className="flex-1">
//             {/* Your Stats */}
//             <div className="mb-8">
//               <h3 className="text-sm font-medium text-gray-500 mb-4">YOUR STATS</h3>
//               <div className="space-y-4">
//                 <div>
//                   <div className="text-2xl font-bold">Level 4</div>
//                   <div className="text-sm text-gray-500">Meme Spotter</div>
//                 </div>
//                 <div>
//                   <div className="text-2xl font-bold">89%</div>
//                   <div className="text-sm text-gray-500">Success Rate</div>
//                 </div>
//               </div>
//             </div>

//             {/* Live Feed */}
//             <div>
//               <h3 className="text-sm font-medium text-gray-500 mb-4">LIVE FEED</h3>
//               <div className="space-y-3">
//                 <div className="bg-white rounded-lg p-3 shadow-sm">
//                   <div className="flex items-center gap-2 text-sm">
//                     <Trophy className="w-4 h-4 text-yellow-500" />
//                     <span>@memeking spotted viral hit</span>
//                   </div>
//                 </div>
//                 <div className="bg-white rounded-lg p-3 shadow-sm">
//                   <div className="flex items-center gap-2 text-sm">
//                     <Bell className="w-4 h-4 text-blue-500" />
//                     <span>Your prediction hit 10K views!</span>
//                   </div>
//                 </div>
//               </div>
//             </div>
//           </div>

//           {/* Quick Stats */}
//           <div className="border-t pt-6 mt-6">
//             <div className="grid grid-cols-2 gap-4">
//               <div>
//                 <div className="text-2xl font-bold">2,481</div>
//                 <div className="text-sm text-gray-500">Predicting Now</div>
//               </div>
//               <div>
//                 <div className="text-2xl font-bold">50K+</div>
//                 <div className="text-sm text-gray-500">Total Spotters</div>
//               </div>
//             </div>
//           </div>
//         </div>
//       </div>
//     </div>
//   );
// };

// export default CompleteJourney;
// import React from "react";
// import { Heart, Share2, BookmarkPlus, Coffee, Settings, Info } from "lucide-react";

// const MemeFreshTab = () => {
//   const currentTime = new Date().toLocaleTimeString([], {
//     hour: "2-digit",
//     minute: "2-digit",
//   });

//   return (
//     <div className="min-h-screen bg-gray-900 text-white p-8 flex flex-col">
//       {/* Top Bar */}
//       <div className="flex justify-between items-center mb-8">
//         <div className="text-6xl font-light">{currentTime}</div>
//         <div className="flex gap-4">
//           <Settings className="w-6 h-6 text-gray-400 hover:text-white cursor-pointer" />
//           <Coffee className="w-6 h-6 text-gray-400 hover:text-white cursor-pointer" />
//         </div>
//       </div>

//       {/* Main Meme Container */}
//       <div className="max-w-2xl mx-auto w-full bg-gray-800 rounded-lg overflow-hidden shadow-xl">
//         {/* Meme Source */}
//         <div className="p-4 border-b border-gray-700 flex justify-between items-center">
//           <div className="flex items-center gap-2">
//             <div className="w-8 h-8 bg-purple-500 rounded-full flex items-center justify-center">r/</div>
//             <span className="font-medium">r/ProgrammerHumor</span>
//           </div>
//           <Info className="w-5 h-5 text-gray-400 hover:text-white cursor-pointer" />
//         </div>

//         {/* Meme Content */}
//         <div className="p-4">
//           <h2 className="text-xl font-medium mb-4">"When the client asks for one small change to the production database"</h2>
//           <div className="bg-gray-700 rounded-lg p-4 text-center">
//             <img src="/api/placeholder/600/400" alt="Domino effect meme" className="max-w-full rounded mx-auto" />
//           </div>
//         </div>

//         {/* Action Bar */}
//         <div className="p-4 border-t border-gray-700 flex justify-between items-center">
//           <div className="flex gap-4">
//             {/* <button className="flex items-center gap-2 text-gray-400 hover:text-white">
//               <Heart className="w-5 h-5" />
//               <span>4.2k</span>
//             </button> */}
//             <button className="flex items-center gap-2 text-gray-400 hover:text-white">
//               <Share2 className="w-5 h-5" />
//               <span>Share</span>
//             </button>
//             <button className="flex items-center gap-2 text-gray-400 hover:text-white">
//               <BookmarkPlus className="w-5 h-5" />
//               <span>Save</span>
//             </button>
//           </div>
//           <div className="text-sm text-gray-400">Trending on r/ProgrammerHumor</div>
//         </div>
//       </div>

//       {/* Quick Links */}
//       <div className="mt-8 flex justify-center gap-6 text-sm text-gray-400">
//         <a href="#" className="hover:text-white">
//           Gmail
//         </a>
//         <a href="#" className="hover:text-white">
//           Drive
//         </a>
//         <a href="#" className="hover:text-white">
//           Calendar
//         </a>
//         <a href="#" className="hover:text-white">
//           Photos
//         </a>
//       </div>
//     </div>
//   );
// };

// export default MemeFreshTab;

// import React, { useState, useEffect } from "react";
// import { TrendingUp, TrendingDown, Eye, ThumbsUp, Share2 } from "lucide-react";

// const ContentTradingApp = () => {
//   const [points, setPoints] = useState(10000);
//   const [posts, setPosts] = useState([]);
//   const [portfolio, setPortfolio] = useState({});
//   const [liveTrades, setLiveTrades] = useState([
//     { user: "Mike", profit: 240, time: "just now" },
//     { user: "Sarah", profit: 180, time: "5s ago" },
//     { user: "Alex", profit: 320, time: "15s ago" },
//   ]);

//   useEffect(() => {
//     setPosts([
//       {
//         id: 1,
//         creator: "MrBeast",
//         title: "$1,000,000 Hide and Seek",
//         views: 15000,
//         likes: 2800,
//         shares: 450,
//         currentPrice: 100,
//         priceChange: 25,
//         timestamp: "2 minutes ago",
//       },
//       {
//         id: 2,
//         creator: "Logan Paul",
//         title: "I Fought Mike Tyson",
//         views: 8500,
//         likes: 1200,
//         shares: 280,
//         currentPrice: 100,
//         priceChange: 12,
//         timestamp: "5 minutes ago",
//       },
//     ]);
//   }, []);

//   // Simulate real-time updates
//   useEffect(() => {
//     const interval = setInterval(() => {
//       setPosts((currentPosts) =>
//         currentPosts.map((post) => {
//           const viewIncrease = Math.floor(Math.random() * 100);
//           const likeIncrease = Math.floor(Math.random() * 20);
//           const shareIncrease = Math.floor(Math.random() * 5);
//           const priceChange = Math.random() > 0.5 ? 2 : -1;

//           return {
//             ...post,
//             views: post.views + viewIncrease,
//             likes: post.likes + likeIncrease,
//             shares: post.shares + shareIncrease,
//             currentPrice: Math.max(50, post.currentPrice + priceChange),
//             priceChange: priceChange > 0 ? post.priceChange + 1 : post.priceChange - 0.5,
//           };
//         })
//       );

//       // Update live trades
//       setLiveTrades((prev) => {
//         const newTrade = {
//           user: ["Alex", "Sarah", "Mike", "John", "Emma"][Math.floor(Math.random() * 5)],
//           profit: Math.floor(Math.random() * 200) + 100,
//           time: "just now",
//         };

//         return [
//           newTrade,
//           ...prev.slice(0, 2).map((trade) => ({
//             ...trade,
//             time: trade.time === "just now" ? "5s ago" : "15s ago",
//           })),
//         ];
//       });
//     }, 2000);

//     return () => clearInterval(interval);
//   }, []);

//   const buyShares = (postId) => {
//     const post = posts.find((p) => p.id === postId);
//     if (post.currentPrice > points) return;
//     setPoints((prev) => prev - post.currentPrice);
//     setPortfolio((prev) => ({
//       ...prev,
//       [postId]: (prev[postId] || 0) + 1,
//     }));
//   };

//   const sellShares = (postId) => {
//     if (!portfolio[postId]) return;
//     const post = posts.find((p) => p.id === postId);
//     setPoints((prev) => prev + post.currentPrice);
//     setPortfolio((prev) => ({
//       ...prev,
//       [postId]: prev[postId] - 1,
//     }));
//   };

//   return (
//     <div className="min-h-screen bg-gray-50">
//       {/* Live Gains Banner */}
//       <div className="bg-green-50 border-b border-green-100">
//         <div className="max-w-3xl mx-auto px-4 py-2 flex items-center justify-between">
//           <div className="flex items-center gap-6">
//             <div className="flex items-center gap-2">
//               <div className="animate-pulse bg-green-600 h-2 w-2 rounded-full" />
//               <span className="text-sm font-medium">Live Trades</span>
//             </div>
//             <div className="space-x-6">
//               {liveTrades.map((trade, i) => (
//                 <span key={i} className="text-sm inline-flex items-center gap-1">
//                   <span className="text-gray-600">{trade.user}</span>
//                   <span className="text-green-600">+{trade.profit}pts</span>
//                   <span className="text-gray-400 text-xs">({trade.time})</span>
//                 </span>
//               ))}
//             </div>
//           </div>
//           <span className="text-xs text-gray-500">485 traders online</span>
//         </div>
//       </div>

//       {/* Hero Section */}
//       <div className="bg-white border-b border-gray-100">
//         <div className="max-w-3xl mx-auto px-4 py-12">
//           <div className="flex justify-between items-center mb-8">
//             <div>
//               <h1 className="text-4xl font-bold mb-2">
//                 Predict Viral Videos.
//                 <span className="text-green-600"> Make Points.</span>
//               </h1>
//               <p className="text-gray-500">Videos start at 100 points. Price moves with views. Spot trends early.</p>
//             </div>
//             <div className="text-right">
//               <div className="text-sm text-gray-500 mb-1">Your Balance</div>
//               <div className="text-2xl font-semibold">{points.toLocaleString()} points</div>
//             </div>
//           </div>

//           <div className="grid grid-cols-3 gap-6 text-center">
//             <div className="bg-gray-50 rounded-lg p-4">
//               <div className="text-2xl font-bold mb-1">1</div>
//               <div className="text-gray-500 text-sm">Buy at 100 pts</div>
//             </div>
//             <div className="bg-gray-50 rounded-lg p-4">
//               <div className="text-2xl font-bold mb-1">2</div>
//               <div className="text-gray-500 text-sm">Watch it go viral</div>
//             </div>
//             <div className="bg-gray-50 rounded-lg p-4">
//               <div className="text-2xl font-bold mb-1">3</div>
//               <div className="text-gray-500 text-sm">Sell for profit</div>
//             </div>
//           </div>
//         </div>
//       </div>

//       {/* Trading Feed */}
//       <main className="max-w-3xl mx-auto px-4 py-8 space-y-4">
//         <div className="flex justify-between items-center mb-6">
//           <h2 className="text-lg font-semibold">Recent Videos</h2>
//           <div className="text-sm text-gray-500">Live updates every 2s</div>
//         </div>

//         {posts.map((post) => (
//           <div key={post.id} className="bg-white shadow-sm rounded-lg p-6 hover:shadow-md transition-shadow">
//             <div className="flex justify-between items-start mb-6">
//               <div>
//                 <div className="flex items-center gap-2">
//                   <div className="text-sm text-gray-500">{post.creator}</div>
//                   <div className="text-xs text-gray-400">{post.timestamp}</div>
//                 </div>
//                 <div className="font-medium text-gray-900">{post.title}</div>
//               </div>
//               <div className="text-right">
//                 <div className="text-2xl font-medium flex items-center gap-2 justify-end text-gray-900">
//                   {post.currentPrice}
//                   {post.priceChange >= 0 ? (
//                     <TrendingUp className="w-5 h-5 text-green-600" />
//                   ) : (
//                     <TrendingDown className="w-5 h-5 text-red-500" />
//                   )}
//                 </div>
//                 <div className={`text-sm ${post.priceChange >= 0 ? "text-green-600" : "text-red-500"}`}>
//                   {post.priceChange > 0 ? "+" : ""}
//                   {Math.abs(post.priceChange).toFixed(1)}%
//                 </div>
//               </div>
//             </div>

//             <div className="flex gap-12 mb-6 text-gray-500 text-sm">
//               <div className="flex items-center gap-2">
//                 <Eye className="w-4 h-4" />
//                 <span className="tabular-nums">{post.views.toLocaleString()}</span>
//               </div>
//               <div className="flex items-center gap-2">
//                 <ThumbsUp className="w-4 h-4" />
//                 <span className="tabular-nums">{post.likes.toLocaleString()}</span>
//               </div>
//               <div className="flex items-center gap-2">
//                 <Share2 className="w-4 h-4" />
//                 <span className="tabular-nums">{post.shares.toLocaleString()}</span>
//               </div>
//             </div>

//             <div className="flex items-center justify-between">
//               <div className="text-sm">
//                 <span className="text-gray-500">You own: </span>
//                 <span className="font-medium">{portfolio[post.id] || 0}</span>
//                 {portfolio[post.id] > 0 && (
//                   <span className="text-gray-500 ml-2">
//                     Profit:{" "}
//                     <span className={post.currentPrice > 100 ? "text-green-600" : "text-red-500"}>
//                       {((post.currentPrice - 100) * portfolio[post.id]).toFixed(0)} pts
//                     </span>
//                   </span>
//                 )}
//               </div>
//               <div className="flex gap-3">
//                 <button
//                   onClick={() => buyShares(post.id)}
//                   className="px-6 py-2 bg-green-600 hover:bg-green-500 transition-colors rounded-lg font-medium text-white"
//                 >
//                   Buy
//                 </button>
//                 <button
//                   onClick={() => sellShares(post.id)}
//                   disabled={!portfolio[post.id]}
//                   className="px-6 py-2 bg-gray-100 hover:bg-gray-200 transition-colors rounded-lg font-medium text-gray-600 disabled:opacity-50 disabled:hover:bg-gray-100"
//                 >
//                   Sell
//                 </button>
//               </div>
//             </div>
//           </div>
//         ))}
//       </main>
//     </div>
//   );
// };

// export default ContentTradingApp;

// import React, { useState, useEffect } from "react";
// import { TrendingUp, TrendingDown, Eye, ThumbsUp, Share2, Star, Timer } from "lucide-react";

// const ContentTradingApp = () => {
//   const [points, setPoints] = useState(10000);
//   const [posts, setPosts] = useState([]);
//   const [portfolio, setPortfolio] = useState({});

//   useEffect(() => {
//     setPosts([
//       {
//         id: 1,
//         creator: "MrBeast",
//         title: "$1,000,000 Hide and Seek",
//         views: 15000,
//         likes: 2800,
//         shares: 450,
//         currentPrice: 100,
//         priceChange: 25,
//         timeLeft: "4:32",
//         predicted: "92%",
//         trend: "+800% today",
//       },
//       {
//         id: 2,
//         creator: "Logan Paul",
//         title: "I Fought Mike Tyson",
//         views: 8500,
//         likes: 1200,
//         shares: 280,
//         currentPrice: 100,
//         priceChange: 12,
//         timeLeft: "8:15",
//         predicted: "87%",
//         trend: "+450% today",
//       },
//     ]);
//   }, []);

//   useEffect(() => {
//     const interval = setInterval(() => {
//       setPosts((currentPosts) =>
//         currentPosts.map((post) => {
//           const viewIncrease = Math.floor(Math.random() * 500);
//           const likeIncrease = Math.floor(Math.random() * 50);
//           const shareIncrease = Math.floor(Math.random() * 10);
//           const priceChange = Math.random() > 0.3 ? 5 : -2;

//           return {
//             ...post,
//             views: post.views + viewIncrease,
//             likes: post.likes + likeIncrease,
//             shares: post.shares + shareIncrease,
//             currentPrice: Math.max(50, post.currentPrice + priceChange),
//             priceChange: post.priceChange + (priceChange > 0 ? 1 : -0.5),
//           };
//         })
//       );
//     }, 1000);

//     return () => clearInterval(interval);
//   }, []);

//   const buyShares = (postId) => {
//     const post = posts.find((p) => p.id === postId);
//     if (post.currentPrice > points) return;
//     setPoints((prev) => prev - post.currentPrice);
//     setPortfolio((prev) => ({
//       ...prev,
//       [postId]: (prev[postId] || 0) + 1,
//     }));
//   };

//   const sellShares = (postId) => {
//     if (!portfolio[postId]) return;
//     const post = posts.find((p) => p.id === postId);
//     setPoints((prev) => prev + post.currentPrice);
//     setPortfolio((prev) => ({
//       ...prev,
//       [postId]: prev[postId] - 1,
//     }));
//   };

//   return (
//     <div className="min-h-screen bg-black text-white">
//       {/* Stats Bar */}
//       <div className="border-b border-gray-800">
//         <div className="max-w-6xl mx-auto px-4 h-12 flex items-center justify-between text-sm">
//           <div className="flex items-center gap-8">
//             <div className="flex items-center gap-2">
//               <div className="animate-pulse w-1.5 h-1.5 bg-green-500 rounded-full" />
//               <span className="text-green-500">15,485 Trading Now</span>
//             </div>
//             <div>Top Gain: MrBeast +840%</div>
//           </div>
//           <div className="flex items-center gap-2">
//             <Timer className="w-4 h-4 text-gray-500" />
//             <span>Updates live every second</span>
//           </div>
//         </div>
//       </div>

//       {/* Header */}
//       <header className="border-b border-gray-800 bg-black">
//         <div className="max-w-6xl mx-auto px-4 py-6">
//           <div className="flex justify-between items-center">
//             <div>
//               <h1 className="text-5xl font-bold">
//                 Trade <span className="text-transparent bg-clip-text bg-gradient-to-r from-green-400 to-green-600">Viral Content</span>
//               </h1>
//               <div className="mt-2 text-xl text-gray-400">Buy at 100pts → Sell when viral</div>
//             </div>
//             <div className="bg-gray-900 px-6 py-4 rounded-xl">
//               <div className="text-sm text-gray-400 mb-1">Your Points</div>
//               <div className="text-3xl font-bold tabular-nums">{points.toLocaleString()}</div>
//             </div>
//           </div>
//         </div>
//       </header>

//       {/* Trading Feed */}
//       <main className="max-w-6xl mx-auto px-4 py-8">
//         <div className="grid gap-4">
//           {posts.map((post) => (
//             <div key={post.id} className="bg-gray-900 rounded-xl p-6 border border-gray-800 hover:border-green-900 transition-colors">
//               <div className="flex justify-between items-start mb-6">
//                 <div>
//                   <div className="text-sm text-gray-400 mb-1">{post.creator}</div>
//                   <div className="text-xl font-medium mb-2">{post.title}</div>
//                   <div className="flex items-center gap-4 text-sm">
//                     <div className="flex items-center gap-1">
//                       <Star className="w-4 h-4 text-yellow-500" />
//                       <span>Predicted: {post.predicted}</span>
//                     </div>
//                     <div className="text-green-400">{post.trend}</div>
//                     <div className="text-gray-500">Time Left: {post.timeLeft}</div>
//                   </div>
//                 </div>
//                 <div className="text-right">
//                   <div className="text-4xl font-bold flex items-center gap-2 justify-end mb-1">
//                     {post.currentPrice}
//                     {post.priceChange >= 0 ? (
//                       <TrendingUp className="w-8 h-8 text-green-500" />
//                     ) : (
//                       <TrendingDown className="w-8 h-8 text-red-500" />
//                     )}
//                   </div>
//                   <div className={`text-lg ${post.priceChange >= 0 ? "text-green-400" : "text-red-400"}`}>
//                     {post.priceChange > 0 ? "+" : ""}
//                     {Math.abs(post.priceChange).toFixed(1)}%
//                   </div>
//                 </div>
//               </div>

//               <div className="flex gap-12 mb-6">
//                 <div>
//                   <div className="text-sm text-gray-500 mb-1">Views</div>
//                   <div className="text-xl font-medium tabular-nums">{post.views.toLocaleString()}</div>
//                 </div>
//                 <div>
//                   <div className="text-sm text-gray-500 mb-1">Likes</div>
//                   <div className="text-xl font-medium tabular-nums">{post.likes.toLocaleString()}</div>
//                 </div>
//                 <div>
//                   <div className="text-sm text-gray-500 mb-1">Shares</div>
//                   <div className="text-xl font-medium tabular-nums">{post.shares.toLocaleString()}</div>
//                 </div>
//               </div>

//               <div className="flex items-center justify-between">
//                 <div>
//                   <div className="text-sm text-gray-500 mb-1">Your Position</div>
//                   <div className="flex items-center gap-4">
//                     <div className="text-lg">{portfolio[post.id] || 0} shares</div>
//                     {portfolio[post.id] > 0 && (
//                       <div className={post.currentPrice > 100 ? "text-green-400" : "text-red-400"}>
//                         {((post.currentPrice - 100) * portfolio[post.id]).toFixed(0)} pts profit
//                       </div>
//                     )}
//                   </div>
//                 </div>
//                 <div className="flex gap-3">
//                   <button
//                     onClick={() => buyShares(post.id)}
//                     className="px-8 py-3 bg-green-600 hover:bg-green-500 rounded-lg font-medium transition-colors"
//                   >
//                     Buy
//                   </button>
//                   <button
//                     onClick={() => sellShares(post.id)}
//                     disabled={!portfolio[post.id]}
//                     className="px-8 py-3 bg-gray-800 hover:bg-gray-700 rounded-lg font-medium disabled:opacity-50 disabled:hover:bg-gray-800 transition-colors"
//                   >
//                     Sell
//                   </button>
//                 </div>
//               </div>
//             </div>
//           ))}
//         </div>
//       </main>
//     </div>
//   );
// };

// export default ContentTradingApp;

// import React, { useState, useEffect } from "react";
// import { TrendingUp, TrendingDown, Timer, Eye } from "lucide-react";

// const ContentTradingApp = () => {
//   const [points, setPoints] = useState(1000);
//   const [posts, setPosts] = useState([]);
//   const [portfolio, setPortfolio] = useState({});

//   useEffect(() => {
//     setPosts([
//       {
//         id: 1,
//         creator: "MrBeast",
//         title: "New $1,000,000 Challenge",
//         views: 15000,
//         currentPrice: 100,
//         priceChange: 0,
//         timeLeft: "4:32",
//       },
//     ]);
//   }, []);

//   useEffect(() => {
//     const interval = setInterval(() => {
//       setPosts((currentPosts) =>
//         currentPosts.map((post) => {
//           const viewIncrease = Math.floor(Math.random() * 500);
//           const priceChange = Math.random() > 0.2 ? 5 : -2;

//           return {
//             ...post,
//             views: post.views + viewIncrease,
//             currentPrice: Math.max(50, post.currentPrice + priceChange),
//             priceChange: priceChange,
//           };
//         })
//       );
//     }, 1000);

//     return () => clearInterval(interval);
//   }, []);

//   const buyShares = (postId) => {
//     const post = posts.find((p) => p.id === postId);
//     if (post.currentPrice > points) return;
//     setPoints((prev) => prev - post.currentPrice);
//     setPortfolio((prev) => ({
//       ...prev,
//       [postId]: (prev[postId] || 0) + 1,
//     }));
//   };

//   const sellShares = (postId) => {
//     if (!portfolio[postId]) return;
//     const post = posts.find((p) => p.id === postId);
//     setPoints((prev) => prev + post.currentPrice);
//     setPortfolio((prev) => ({
//       ...prev,
//       [postId]: prev[postId] - 1,
//     }));
//   };

//   return (
//     <div className="min-h-screen bg-white">
//       {/* Super Simple Header */}
//       <div className="bg-green-50 border-b border-green-100">
//         <div className="max-w-2xl mx-auto px-4 h-16 flex items-center justify-between">
//           <div className="flex items-center gap-2">
//             <div className="animate-pulse w-2 h-2 bg-green-500 rounded-full" />
//             <span className="font-medium text-green-600">Buy at 100 → Sell when viral</span>
//           </div>
//           <div className="flex items-center gap-2 text-gray-600">
//             <Timer className="w-4 h-4" />
//             <span className="text-sm">Live updates every second</span>
//           </div>
//         </div>
//       </div>

//       {/* Main Trading Area */}
//       <main className="max-w-2xl mx-auto px-4 py-8">
//         {posts.map((post) => (
//           <div
//             key={post.id}
//             className="relative bg-white border-2 border-gray-100 rounded-2xl p-8 hover:border-green-100 transition-colors"
//           >
//             {/* Price Tag - Most prominent */}
//             <div className="absolute top-6 right-6">
//               <div className="text-5xl font-bold flex items-center gap-2">
//                 {post.currentPrice}
//                 {post.priceChange >= 0 ? (
//                   <TrendingUp className="w-8 h-8 text-green-500" />
//                 ) : (
//                   <TrendingDown className="w-8 h-8 text-red-500" />
//                 )}
//               </div>
//               <div className={`text-lg text-right ${post.priceChange >= 0 ? "text-green-500" : "text-red-500"}`}>
//                 {post.priceChange > 0 ? "+" : ""}
//                 {post.priceChange} pts
//               </div>
//             </div>

//             {/* Content Info */}
//             <div className="mb-8 pr-48">
//               <div className="text-2xl font-medium mb-2">{post.title}</div>
//               <div className="text-gray-500">{post.creator}</div>
//               <div className="flex items-center gap-2 mt-2 text-gray-600">
//                 <Eye className="w-4 h-4" />
//                 {post.views.toLocaleString()} views
//               </div>
//             </div>

//             {/* Action Area */}
//             <div className="flex items-center justify-between">
//               <div>
//                 {portfolio[post.id] > 0 && (
//                   <div className="text-lg">
//                     Profit:{" "}
//                     <span className={`font-medium ${post.currentPrice > 100 ? "text-green-500" : "text-red-500"}`}>
//                       {((post.currentPrice - 100) * portfolio[post.id]).toFixed(0)} pts
//                     </span>
//                   </div>
//                 )}
//               </div>
//               <div className="flex gap-3">
//                 <button
//                   onClick={() => buyShares(post.id)}
//                   className="px-12 py-4 bg-black text-white rounded-xl font-medium text-lg hover:bg-gray-800 transition-colors"
//                 >
//                   Buy for 100
//                 </button>
//                 {portfolio[post.id] > 0 && (
//                   <button
//                     onClick={() => sellShares(post.id)}
//                     className="px-12 py-4 bg-gray-100 rounded-xl font-medium text-lg hover:bg-gray-200 transition-colors"
//                   >
//                     Sell for {post.currentPrice}
//                   </button>
//                 )}
//               </div>
//             </div>

//             {/* First Trade Helper */}
//             {!Object.keys(portfolio).length && (
//               <div className="absolute inset-0 bg-black/80 rounded-2xl flex items-center justify-center">
//                 <div className="text-center text-white">
//                   <div className="text-3xl font-bold mb-4">Start with 100 points</div>
//                   <button
//                     onClick={() => buyShares(post.id)}
//                     className="px-12 py-4 bg-green-500 rounded-xl font-medium text-lg hover:bg-green-400 transition-colors"
//                   >
//                     Buy Your First Share
//                   </button>
//                 </div>
//               </div>
//             )}
//           </div>
//         ))}
//       </main>

//       {/* Points Display */}
//       <div className="fixed bottom-8 right-8 bg-black text-white px-6 py-4 rounded-2xl">
//         <div className="text-sm mb-1">Your Points</div>
//         <div className="text-2xl font-bold">{points.toLocaleString()}</div>
//       </div>
//     </div>
//   );
// };

// export default ContentTradingApp;

// import React, { useState, useEffect } from "react";
// import { TrendingUp, TrendingDown, Eye, ThumbsUp, Share2, Timer } from "lucide-react";

// const ContentTradingApp = () => {
//   const [balance, setBalance] = useState(1000.0);
//   const [posts, setPosts] = useState([]);
//   const [portfolio, setPortfolio] = useState({});

//   useEffect(() => {
//     setPosts([
//       {
//         id: 1,
//         creator: "MrBeast",
//         title: "$1,000,000 Hide and Seek",
//         views: 15000,
//         likes: 2800,
//         shares: 450,
//         price: 1.0,
//         priceChange: 0,
//         uploadTime: "2m ago",
//         prediction: "92% viral chance",
//       },
//       {
//         id: 2,
//         creator: "Logan Paul",
//         title: "I Fought Mike Tyson",
//         views: 8500,
//         likes: 1200,
//         shares: 280,
//         price: 1.0,
//         priceChange: 0,
//         uploadTime: "Just now",
//         prediction: "87% viral chance",
//       },
//     ]);

//     const interval = setInterval(() => {
//       setPosts((currentPosts) =>
//         currentPosts.map((post) => {
//           const viewIncrease = Math.floor(Math.random() * 1000);
//           const likeIncrease = Math.floor(Math.random() * 100);
//           const shareIncrease = Math.floor(Math.random() * 20);
//           const priceChange = Math.random() > 0.3 ? 0.05 : -0.02;

//           return {
//             ...post,
//             views: post.views + viewIncrease,
//             likes: post.likes + likeIncrease,
//             shares: post.shares + shareIncrease,
//             price: Math.max(0.1, post.price + priceChange),
//             priceChange: priceChange,
//           };
//         })
//       );
//     }, 1000);

//     return () => clearInterval(interval);
//   }, []);

//   const buyShares = (postId) => {
//     const post = posts.find((p) => p.id === postId);
//     if (post.price > balance) return;
//     setBalance((prev) => prev - post.price);
//     setPortfolio((prev) => ({
//       ...prev,
//       [postId]: (prev[postId] || 0) + 1,
//     }));
//   };

//   const sellShares = (postId) => {
//     const post = posts.find((p) => p.id === postId);
//     if (!portfolio[postId]) return;
//     setBalance((prev) => prev + post.price);
//     setPortfolio((prev) => ({
//       ...prev,
//       [postId]: prev[postId] - 1,
//     }));
//   };

//   return (
//     <div className="min-h-screen bg-black text-white">
//   {/* Live Trading Banner */}
//   <div className="bg-green-500/10 border-b border-green-500/20">
//     <div className="max-w-5xl mx-auto px-6">
//       <div className="h-8 flex items-center justify-between text-sm">
//         <div className="flex items-center gap-6">
//           <div className="flex items-center gap-2">
//             <div className="animate-pulse w-1.5 h-1.5 bg-green-500 rounded-full" />
//             <span className="text-green-500">15,832 Trading Live</span>
//           </div>
//           <span className="text-gray-400">Paper trading • Learn risk-free</span>
//         </div>
//         <div className="text-gray-400">Updates every second</div>
//       </div>
//     </div>
//   </div>

//       {/* Header */}
//       <div className="fixed w-full top-8 bg-black border-b border-gray-800 z-10">
//         <div className="max-w-5xl mx-auto px-6">
//           <div className="h-16 flex items-center justify-between">
//             <div className="text-2xl font-bold">SURGE</div>
//             <div className="flex items-center gap-4">
//               <div className="px-4 py-1.5 bg-gray-900 rounded-lg">${balance.toFixed(2)}</div>
//             </div>
//           </div>
//         </div>
//       </div>

//       {/* Main Content */}
//       <main className="max-w-5xl mx-auto px-6 pt-32 pb-8">
//         {/* Hero */}
//         <div className="mb-8">
//           <h1 className="text-4xl font-bold mb-2">
//             Spot Viral Content.
//             <span className="text-green-400"> Trade Early.</span>
//           </h1>
//           <p className="text-gray-400">Every video starts at $1. Price follows views. Be first.</p>
//         </div>

//         {/* Trading Feed */}
//         <div className="space-y-4">
//           {posts.map((post) => (
//             <div key={post.id} className="bg-gray-900 border border-gray-800 rounded-xl p-6 hover:border-green-900 transition-colors">
//               {/* Content Info */}
//               <div className="flex justify-between items-start mb-6">
//                 <div>
//                   <div className="flex items-center gap-2 text-sm text-gray-400 mb-1">
//                     <span>{post.creator}</span>
//                     <span>•</span>
//                     <span>{post.uploadTime}</span>
//                   </div>
//                   <div className="text-xl font-medium mb-2">{post.title}</div>
//                   <div className="text-green-400 text-sm">{post.prediction}</div>
//                 </div>
//                 <div className="text-right">
//                   <div className="text-3xl font-bold flex items-center gap-2 mb-1">
//                     ${post.price.toFixed(2)}
//                     {post.priceChange >= 0 ? (
//                       <TrendingUp className="w-6 h-6 text-green-500" />
//                     ) : (
//                       <TrendingDown className="w-6 h-6 text-red-500" />
//                     )}
//                   </div>
//                 </div>
//               </div>

//               {/* Stats */}
//               <div className="grid grid-cols-3 gap-12 mb-6 bg-black bg-opacity-50 rounded-lg p-4">
//                 <div>
//                   <div className="text-sm text-gray-500 mb-1">Views</div>
//                   <div className="text-xl font-medium tabular-nums">{post.views.toLocaleString()}</div>
//                 </div>
//                 <div>
//                   <div className="text-sm text-gray-500 mb-1">Likes</div>
//                   <div className="text-xl font-medium tabular-nums">{post.likes.toLocaleString()}</div>
//                 </div>
//                 <div>
//                   <div className="text-sm text-gray-500 mb-1">Shares</div>
//                   <div className="text-xl font-medium tabular-nums">{post.shares.toLocaleString()}</div>
//                 </div>
//               </div>

//               {/* Trading Actions */}
//               <div className="flex items-center justify-between">
//                 <div>
//                   {portfolio[post.id] > 0 && (
//                     <div className="text-gray-400">
//                       Holding {portfolio[post.id]} shares
//                       <span className={post.priceChange >= 0 ? "text-green-400 ml-2" : "text-red-400 ml-2"}>
//                         ({((post.price - 1.0) * portfolio[post.id]).toFixed(2)})
//                       </span>
//                     </div>
//                   )}
//                 </div>
//                 <div className="flex gap-3">
//                   <button
//                     onClick={() => buyShares(post.id)}
//                     className="px-8 py-2 bg-green-600 hover:bg-green-500 rounded-lg font-medium transition-colors"
//                   >
//                     Buy
//                   </button>
//                   {portfolio[post.id] > 0 && (
//                     <button
//                       onClick={() => sellShares(post.id)}
//                       className="px-8 py-2 bg-gray-800 hover:bg-gray-700 rounded-lg font-medium transition-colors"
//                     >
//                       Sell
//                     </button>
//                   )}
//                 </div>
//               </div>
//             </div>
//           ))}
//         </div>
//       </main>
//     </div>
//   );
// };

// export default ContentTradingApp;

// import React, { useState, useEffect } from "react";
// import {
//   TrendingUp,
//   TrendingDown,
//   Trophy,
//   Wallet,
//   Users,
//   ChevronRight,
//   Eye,
//   ThumbsUp,
//   Share2,
//   Timer,
//   Settings,
//   Bell,
//   ExternalLink,
//   BarChart2,
// } from "lucide-react";

// const ContentTradingApp = () => {
//   const [balance, setBalance] = useState(1000.0);
//   const [posts, setPosts] = useState([]);
//   const [portfolio, setPortfolio] = useState({});
//   const [showTutorial, setShowTutorial] = useState(true);
//   const [selectedPost, setSelectedPost] = useState(null);

//   // Initialize with trending posts
//   useEffect(() => {
//     setPosts([
//       {
//         id: 1,
//         creator: "MrBeast",
//         verified: true,
//         title: "$1,000,000 Hide and Seek",
//         views: 15000,
//         likes: 2800,
//         shares: 450,
//         price: 1.0,
//         priceChange: 0,
//         uploadTime: "2m ago",
//         prediction: "92% viral chance",
//         thumbnail: "/api/placeholder/320/180",
//         viewGrowth: "+2.8k/min",
//         holders: 42,
//         volume: "$5,891",
//       },
//       {
//         id: 2,
//         creator: "Logan Paul",
//         verified: true,
//         title: "I Fought Mike Tyson",
//         views: 8500,
//         likes: 1200,
//         shares: 280,
//         price: 1.0,
//         priceChange: 0,
//         uploadTime: "Just now",
//         prediction: "87% viral chance",
//         thumbnail: "/api/placeholder/320/180",
//         viewGrowth: "+1.2k/min",
//         holders: 28,
//         volume: "$2,451",
//       },
//     ]);

//     const interval = setInterval(() => {
//       setPosts((currentPosts) =>
//         currentPosts.map((post) => ({
//           ...post,
//           views: post.views + Math.floor(Math.random() * 1000),
//           likes: post.likes + Math.floor(Math.random() * 100),
//           shares: post.shares + Math.floor(Math.random() * 20),
//           price: Math.max(0.1, post.price + (Math.random() > 0.3 ? 0.05 : -0.02)),
//         }))
//       );
//     }, 1000);

//     return () => clearInterval(interval);
//   }, []);

//   // Top traders data
//   const topTraders = [
//     {
//       id: 1,
//       username: "viral_king",
//       profit: "+$8,942",
//       trades: 24,
//       avatar: "/api/placeholder/32/32",
//       winRate: "92%",
//     },
//     {
//       id: 2,
//       username: "trader_x",
//       profit: "+$5,230",
//       trades: 18,
//       avatar: "/api/placeholder/32/32",
//       winRate: "88%",
//     },
//     {
//       id: 3,
//       username: "crypto_jane",
//       profit: "+$3,891",
//       trades: 15,
//       avatar: "/api/placeholder/32/32",
//       winRate: "85%",
//     },
//   ];

//   // Trading functions
//   const buyShares = (postId, amount = 1) => {
//     const post = posts.find((p) => p.id === postId);
//     if (!post || post.price * amount > balance) return;

//     setBalance((prev) => prev - post.price * amount);
//     setPortfolio((prev) => ({
//       ...prev,
//       [postId]: (prev[postId] || 0) + amount,
//     }));
//   };

//   const sellShares = (postId, amount = 1) => {
//     const post = posts.find((p) => p.id === postId);
//     if (!post || !portfolio[postId] || portfolio[postId] < amount) return;

//     setBalance((prev) => prev + post.price * amount);
//     setPortfolio((prev) => ({
//       ...prev,
//       [postId]: prev[postId] - amount,
//     }));
//   };

//   const TutorialBanner = () => (
//     <div className="bg-gradient-to-r from-blue-500/10 to-purple-500/10 border-b border-blue-500/20">
//       <div className="max-w-6xl mx-auto px-6 py-3">
//         <div className="flex items-center justify-between">
//           <div className="flex items-center gap-8">
//             <span className="font-medium text-blue-400">How it Works</span>
//             <div className="flex items-center gap-8 text-gray-300">
//               <div className="flex items-center gap-2">
//                 <div className="w-5 h-5 rounded-full bg-gray-800 flex items-center justify-center text-xs">1</div>
//                 <span>Start at $1</span>
//               </div>
//               <div className="flex items-center gap-2">
//                 <div className="w-5 h-5 rounded-full bg-gray-800 flex items-center justify-center text-xs">2</div>
//                 <span>Price follows views</span>
//               </div>
//               <div className="flex items-center gap-2">
//                 <div className="w-5 h-5 rounded-full bg-gray-800 flex items-center justify-center text-xs">3</div>
//                 <span>-10% if views stall</span>
//               </div>
//               <div className="flex items-center gap-2">
//                 <div className="w-5 h-5 rounded-full bg-gray-800 flex items-center justify-center text-xs">4</div>
//                 <span>24h trading window</span>
//               </div>
//             </div>
//           </div>
//           <button onClick={() => setShowTutorial(false)} className="text-gray-400 hover:text-white transition-colors">
//             Got it
//           </button>
//         </div>
//       </div>
//     </div>
//   );

//   const LiveBanner = () => (
//     <div className="bg-green-500/10 border-b border-green-500/20">
//       <div className="max-w-6xl mx-auto px-6">
//         <div className="h-8 flex items-center justify-between text-sm">
//           <div className="flex items-center gap-6">
//             <div className="flex items-center gap-2">
//               <div className="animate-pulse w-1.5 h-1.5 bg-green-500 rounded-full" />
//               <span className="text-green-500 font-medium">15,832 Trading Now</span>
//             </div>
//             <span className="text-gray-400">Paper trading • Risk-free</span>
//           </div>
//           <div className="flex items-center gap-4">
//             <span className="text-gray-400">Market closes in 4h 21m</span>
//             <div className="h-4 border-l border-gray-800" />
//             <span className="text-gray-400">Live updates every second</span>
//           </div>
//         </div>
//       </div>
//     </div>
//   );

//   const Header = () => (
//     <div className="fixed w-full top-8 bg-black/95 border-b border-gray-800 backdrop-blur-sm z-10">
//       <div className="max-w-6xl mx-auto px-6">
//         <div className="h-16 flex items-center justify-between">
//           <div className="flex items-center gap-8">
//             <div className="text-2xl font-bold bg-gradient-to-r from-green-400 to-blue-500 bg-clip-text text-transparent">SURGE</div>
//             <nav className="flex items-center gap-6 text-sm">
//               <button className="text-white">Trading</button>
//               <button className="text-gray-400 hover:text-white transition-colors">Portfolio</button>
//               <button className="text-gray-400 hover:text-white transition-colors">Leaderboard</button>
//             </nav>
//           </div>
//           <div className="flex items-center gap-4">
//             <button className="p-2 text-gray-400 hover:text-white transition-colors">
//               <Bell className="w-5 h-5" />
//             </button>
//             <button className="p-2 text-gray-400 hover:text-white transition-colors">
//               <Settings className="w-5 h-5" />
//             </button>
//             <div className="flex items-center gap-2 px-4 py-2 bg-gray-900 rounded-lg">
//               <Wallet className="w-4 h-4 text-green-400" />
//               <span className="font-medium">${balance.toFixed(2)}</span>
//             </div>
//           </div>
//         </div>
//       </div>
//     </div>
//   );

//   const TopSection = () => (
//     <div className="grid grid-cols-2 gap-6 mb-8">
//       {/* Top Traders Card */}
//       <div className="bg-gray-900 border border-gray-800 rounded-xl p-6 hover:border-gray-700 transition-colors">
//         <div className="flex items-center justify-between mb-6">
//           <div className="flex items-center gap-2">
//             <Trophy className="w-5 h-5 text-yellow-500" />
//             <h2 className="font-medium">Top Traders Today</h2>
//           </div>
//           <button className="text-sm text-gray-400 hover:text-white transition-colors flex items-center gap-1">
//             Full Leaderboard
//             <ChevronRight className="w-4 h-4" />
//           </button>
//         </div>
//         <div className="space-y-4">
//           {topTraders.map((trader, i) => (
//             <div key={trader.id} className="flex items-center justify-between">
//               <div className="flex items-center gap-3">
//                 <div className="w-6 text-center font-medium text-gray-400">#{i + 1}</div>
//                 <img src={trader.avatar} className="w-6 h-6 rounded-full" alt={trader.username} />
//                 <span>{trader.username}</span>
//               </div>
//               <div className="flex items-center gap-4">
//                 <span className="text-gray-400">{trader.winRate} wins</span>
//                 <span className="text-green-400 font-medium">{trader.profit}</span>
//               </div>
//             </div>
//           ))}
//         </div>
//       </div>

//       {/* Portfolio Summary Card */}
//       <div className="bg-gray-900 border border-gray-800 rounded-xl p-6 hover:border-gray-700 transition-colors">
//         <div className="flex items-center justify-between mb-6">
//           <div className="flex items-center gap-2">
//             <BarChart2 className="w-5 h-5 text-blue-400" />
//             <h2 className="font-medium">Your Portfolio</h2>
//           </div>
//           <button className="text-sm text-gray-400 hover:text-white transition-colors flex items-center gap-1">
//             View Details
//             <ChevronRight className="w-4 h-4" />
//           </button>
//         </div>
//         <div className="space-y-6">
//           <div className="grid grid-cols-2 gap-6">
//             <div>
//               <div className="text-gray-400 text-sm mb-1">24h Profit/Loss</div>
//               <div className="text-xl font-medium text-green-400">+$142.50</div>
//               <div className="text-sm text-gray-400">+14.25%</div>
//             </div>
//             <div>
//               <div className="text-gray-400 text-sm mb-1">Active Positions</div>
//               <div className="text-xl font-medium">5 posts</div>
//               <div className="text-sm text-gray-400">$891 invested</div>
//             </div>
//           </div>
//           <div>
//             <div className="flex items-center justify-between text-sm mb-2">
//               <span className="text-gray-400">Win Rate</span>
//               <span className="text-green-400">78%</span>
//             </div>
//             <div className="h-2 bg-gray-800 rounded-full overflow-hidden">
//               <div className="h-full w-[78%] bg-gradient-to-r from-green-500 to-blue-500" />
//             </div>
//           </div>
//         </div>
//       </div>
//     </div>
//   );

//   const TradingCard = ({ post }) => {
//     const isGrowing = post.priceChange >= 0;
//     const hasShares = portfolio[post.id] > 0;

//     return (
//       <div className="bg-gray-900 border border-gray-800 rounded-xl p-6 hover:border-gray-700 transition-colors">
//         {/* Content Info */}
//         <div className="flex justify-between items-start mb-6">
//           <div className="flex-1">
//             <div className="flex items-center gap-2 text-sm text-gray-400 mb-1">
//               <span className="font-medium text-gray-300">{post.creator}</span>
//               {post.verified && (
//                 <svg className="w-4 h-4 text-blue-400" viewBox="0 0 24 24" fill="currentColor">
//                   <path d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z" />
//                 </svg>
//               )}
//               <span>•</span>
//               <span>{post.uploadTime}</span>
//             </div>
//             <div className="text-xl font-medium mb-2">{post.title}</div>
//             <div className="flex items-center gap-4 text-sm">
//               <div className="text-green-400">{post.prediction}</div>
//               <div className="text-gray-400">{post.holders} holders</div>
//               <div className="text-gray-400">Vol: {post.volume}</div>
//             </div>
//           </div>
//           <div className="text-right">
//             <div className="text-3xl font-bold flex items-center gap-2 mb-1">
//               ${post.price.toFixed(2)}
//               {isGrowing ? <TrendingUp className="w-6 h-6 text-green-500" /> : <TrendingDown className="w-6 h-6 text-red-500" />}
//             </div>
//             <div className={`text-sm ${isGrowing ? "text-green-400" : "text-red-400"}`}>
//               {isGrowing ? "+" : ""}
//               {(post.priceChange * 100).toFixed(1)}%
//             </div>
//           </div>
//         </div>

//         {/* Stats Grid */}
//         <div className="grid grid-cols-4 gap-6 mb-6 bg-black bg-opacity-50 rounded-lg p-4">
//           <div>
//             <div className="flex items-center gap-2 text-sm text-gray-500 mb-1">
//               <Eye className="w-4 h-4" />
//               <span>Views/min</span>
//             </div>
//             <div className="text-xl font-medium tabular-nums">{post.viewGrowth}</div>
//           </div>
//           <div>
//             <div className="flex items-center gap-2 text-sm text-gray-500 mb-1">
//               <Eye className="w-4 h-4" />
//               <span>Total Views</span>
//             </div>
//             <div className="text-xl font-medium tabular-nums">{post.views.toLocaleString()}</div>
//           </div>
//           <div>
//             <div className="flex items-center gap-2 text-sm text-gray-500 mb-1">
//               <ThumbsUp className="w-4 h-4" />
//               <span>Likes</span>
//             </div>
//             <div className="text-xl font-medium tabular-nums">{post.likes.toLocaleString()}</div>
//           </div>
//           <div>
//             <div className="flex items-center gap-2 text-sm text-gray-500 mb-1">
//               <Share2 className="w-4 h-4" />
//               <span>Shares</span>
//             </div>
//             <div className="text-xl font-medium tabular-nums">{post.shares.toLocaleString()}</div>
//           </div>
//         </div>

//         {/* Trading Actions */}
//         <div className="flex items-center justify-between">
//           <div>
//             {hasShares && (
//               <div className="flex items-center gap-4">
//                 <div className="text-gray-400">Holding {portfolio[post.id]} shares</div>
//                 <div className={isGrowing ? "text-green-400" : "text-red-400"}>
//                   {isGrowing ? "+" : ""}
//                   {((post.price - 1.0) * portfolio[post.id]).toFixed(2)}
//                 </div>
//               </div>
//             )}
//           </div>
//           <div className="flex gap-3">
//             <button
//               onClick={() => buyShares(post.id, 10)}
//               className="px-8 py-2.5 bg-green-500 hover:bg-green-600 rounded-lg font-medium transition-all"
//             >
//               Buy 10 Shares
//             </button>
//             {hasShares && (
//               <button
//                 onClick={() => sellShares(post.id, portfolio[post.id])}
//                 className="px-8 py-2.5 bg-gray-800 hover:bg-gray-700 rounded-lg font-medium transition-all"
//               >
//                 Sell All
//               </button>
//             )}
//           </div>
//         </div>

//         {/* Price Drop Timer */}
//         {post.priceChange <= 0 && (
//           <div className="mt-4 bg-red-500/10 rounded-lg p-4 flex items-center justify-between">
//             <div className="flex items-center gap-2 text-red-400">
//               <Timer className="w-4 h-4" />
//               <span>Price drops in</span>
//             </div>
//             <div className="font-mono text-red-400">4:21</div>
//           </div>
//         )}
//       </div>
//     );
//   };

//   return (
//     <div className="min-h-screen bg-black text-white">
//       {showTutorial && <TutorialBanner />}
//       <LiveBanner />
//       <Header />

//       {/* Main Content */}
//       <main className="max-w-6xl mx-auto px-6 pt-32 pb-8">
//         {/* Hero Section */}
//         <div className="mb-8">
//           <h1 className="text-4xl font-bold mb-2">
//             Spot Viral Content.
//             <span className="bg-gradient-to-r from-green-400 to-blue-500 bg-clip-text text-transparent"> Trade Early.</span>
//           </h1>
//           <p className="text-gray-400">Every post starts at $1. Price follows engagement. Be first.</p>
//         </div>

//         <TopSection />

//         {/* Trading Feed */}
//         <div className="space-y-4">
//           {posts.map((post) => (
//             <TradingCard key={post.id} post={post} />
//           ))}
//         </div>
//       </main>

//       {/* Footer Stats */}
//       <footer className="border-t border-gray-800">
//         <div className="max-w-6xl mx-auto px-6 py-4">
//           <div className="flex items-center justify-between text-sm text-gray-400">
//             <div className="flex items-center gap-8">
//               <div>24h Volume: $891,242</div>
//               <div>Active Posts: 1,242</div>
//               <div>Total Traders: 15,832</div>
//             </div>
//             <div className="flex items-center gap-4">
//               <a href="#" className="hover:text-white transition-colors">
//                 Help
//               </a>
//               <a href="#" className="hover:text-white transition-colors">
//                 Discord
//               </a>
//               <a href="#" className="hover:text-white transition-colors">
//                 Twitter
//               </a>
//             </div>
//           </div>
//         </div>
//       </footer>
//     </div>
//   );
// };

// export default ContentTradingApp;

// import React, { useState, useEffect } from "react";
// import {
//   TrendingUp,
//   TrendingDown,
//   Trophy,
//   Wallet,
//   Users,
//   ChevronRight,
//   Eye,
//   ThumbsUp,
//   Share2,
//   Timer,
//   Settings,
//   Bell,
//   ExternalLink,
//   BarChart2,
//   Sun,
//   Moon,
// } from "lucide-react";

// const ContentTradingApp = () => {
//   const [isDark, setIsDark] = useState(true);
//   const [balance, setBalance] = useState(1000.0);
//   const [posts, setPosts] = useState([]);
//   const [portfolio, setPortfolio] = useState({});

//   // Theme toggle effect
//   useEffect(() => {
//     document.documentElement.classList.toggle("dark", isDark);
//   }, [isDark]);

//   // Initialize with trending posts
//   useEffect(() => {
//     setPosts([
//       {
//         id: 1,
//         creator: "MrBeast",
//         verified: true,
//         title: "$1,000,000 Hide and Seek",
//         views: 15000,
//         likes: 2800,
//         shares: 450,
//         price: 1.0,
//         priceChange: 0,
//         uploadTime: "2m ago",
//         prediction: "92% viral chance",
//         thumbnail: "/api/placeholder/320/180",
//         viewGrowth: "+2.8k/min",
//         holders: 42,
//         volume: "$5,891",
//       },
//       {
//         id: 2,
//         creator: "Logan Paul",
//         verified: true,
//         title: "I Fought Mike Tyson",
//         views: 8500,
//         likes: 1200,
//         shares: 280,
//         price: 1.0,
//         priceChange: 0,
//         uploadTime: "Just now",
//         prediction: "87% viral chance",
//         thumbnail: "/api/placeholder/320/180",
//         viewGrowth: "+1.2k/min",
//         holders: 28,
//         volume: "$2,451",
//       },
//     ]);

//     const interval = setInterval(() => {
//       setPosts((currentPosts) =>
//         currentPosts.map((post) => ({
//           ...post,
//           views: post.views + Math.floor(Math.random() * 1000),
//           likes: post.likes + Math.floor(Math.random() * 100),
//           shares: post.shares + Math.floor(Math.random() * 20),
//           price: Math.max(0.1, post.price + (Math.random() > 0.3 ? 0.05 : -0.02)),
//           priceChange: Math.random() > 0.3 ? 0.05 : -0.02,
//         }))
//       );
//     }, 1000);

//     return () => clearInterval(interval);
//   }, []);

//   const topTraders = [
//     {
//       id: 1,
//       username: "viral_king",
//       profit: "+$8,942",
//       trades: 24,
//       avatar: "/api/placeholder/32/32",
//       winRate: "92%",
//     },
//     {
//       id: 2,
//       username: "trader_x",
//       profit: "+$5,230",
//       trades: 18,
//       avatar: "/api/placeholder/32/32",
//       winRate: "88%",
//     },
//     {
//       id: 3,
//       username: "crypto_jane",
//       profit: "+$3,891",
//       trades: 15,
//       avatar: "/api/placeholder/32/32",
//       winRate: "85%",
//     },
//   ];

//   const buyShares = (postId, amount = 1) => {
//     const post = posts.find((p) => p.id === postId);
//     if (!post || post.price * amount > balance) return;
//     setBalance((prev) => prev - post.price * amount);
//     setPortfolio((prev) => ({
//       ...prev,
//       [postId]: (prev[postId] || 0) + amount,
//     }));
//   };

//   const sellShares = (postId, amount = 1) => {
//     if (!portfolio[postId] || portfolio[postId] < amount) return;
//     const post = posts.find((p) => p.id === postId);
//     setBalance((prev) => prev + post.price * amount);
//     setPortfolio((prev) => ({
//       ...prev,
//       [postId]: prev[postId] - amount,
//     }));
//   };

//   const Header = () => (
//     <div
//       className={`fixed w-full top-0 ${isDark ? "bg-black/95" : "bg-white/95"} backdrop-blur-sm border-b ${
//         isDark ? "border-gray-800" : "border-gray-200"
//       } z-30`}
//     >
//       <div className="max-w-6xl mx-auto px-4 lg:px-6">
//         <div className="h-16 flex items-center justify-between">
//           <div className="flex items-center gap-4 lg:gap-8">
//             <div className="text-2xl font-bold bg-gradient-to-r from-green-400 to-green-500 bg-clip-text text-transparent">SURGE</div>
//             <nav className="hidden md:flex items-center gap-6 text-sm">
//               <button className={isDark ? "text-white" : "text-gray-900"}>Trading</button>
//               <button className={`${isDark ? "text-gray-400 hover:text-white" : "text-gray-500 hover:text-gray-900"} transition-colors`}>
//                 Portfolio
//               </button>
//               <button className={`${isDark ? "text-gray-400 hover:text-white" : "text-gray-500 hover:text-gray-900"} transition-colors`}>
//                 Leaderboard
//               </button>
//             </nav>
//           </div>

//           <div className="flex items-center gap-3">
//             <button
//               onClick={() => setIsDark(!isDark)}
//               className={`p-2 rounded-lg ${
//                 isDark ? "bg-gray-800 text-gray-300" : "bg-gray-100 text-gray-600"
//               } hover:opacity-80 transition-all`}
//             >
//               {isDark ? <Sun className="w-5 h-5" /> : <Moon className="w-5 h-5" />}
//             </button>
//             <button className={`p-2 ${isDark ? "text-gray-400 hover:text-white" : "text-gray-500 hover:text-gray-900"}`}>
//               <Bell className="w-5 h-5" />
//             </button>
//             <button className={`p-2 ${isDark ? "text-gray-400 hover:text-white" : "text-gray-500 hover:text-gray-900"}`}>
//               <Settings className="w-5 h-5" />
//             </button>
//             <div className={`hidden md:flex items-center gap-2 px-4 py-2 ${isDark ? "bg-gray-900" : "bg-gray-100"} rounded-lg`}>
//               <Wallet className="w-4 h-4 text-green-500" />
//               <span className="font-medium">${balance.toFixed(2)}</span>
//             </div>
//           </div>
//         </div>
//       </div>
//     </div>
//   );

//   const HowItWorksBanner = () => (
//     <div
//       className={`sticky top-16 z-20 ${isDark ? "bg-gray-900/95" : "bg-white/95"} backdrop-blur-sm border-b ${
//         isDark ? "border-gray-800" : "border-gray-200"
//       }`}
//     >
//       <div className="max-w-6xl mx-auto px-4 lg:px-6">
//         <div className="py-4 grid grid-cols-2 md:grid-cols-4 gap-4">
//           {[
//             { step: "1", text: "Start at $1" },
//             { step: "2", text: "Price follows views" },
//             { step: "3", text: "-10% if views stall" },
//             { step: "4", text: "24h trading window" },
//           ].map((item) => (
//             <div key={item.step} className="flex items-center gap-3">
//               <div className={`w-6 h-6 rounded-full ${isDark ? "bg-green-500/20" : "bg-green-500/10"} flex items-center justify-center`}>
//                 <span className="text-xs text-green-500 font-medium">{item.step}</span>
//               </div>
//               <span className={isDark ? "text-gray-300" : "text-gray-600"}>{item.text}</span>
//             </div>
//           ))}
//         </div>
//       </div>
//     </div>
//   );

//   const TopSection = () => (
//     <div className="grid md:grid-cols-2 gap-6 mb-8">
//       {/* Top Traders Card */}
//       <div
//         className={`${
//           isDark ? "bg-gray-900 border-gray-800" : "bg-white border-gray-200"
//         } border rounded-xl p-6 hover:border-green-500/50 transition-colors`}
//       >
//         <div className="flex items-center justify-between mb-6">
//           <div className="flex items-center gap-2">
//             <Trophy className="w-5 h-5 text-green-500" />
//             <h2 className="font-medium">Top Traders Today</h2>
//           </div>
//           <button
//             className={`text-sm ${
//               isDark ? "text-gray-400 hover:text-white" : "text-gray-500 hover:text-gray-900"
//             } transition-colors flex items-center gap-1`}
//           >
//             Full Leaderboard
//             <ChevronRight className="w-4 h-4" />
//           </button>
//         </div>
//         <div className="space-y-4">
//           {topTraders.map((trader, i) => (
//             <div key={trader.id} className="flex items-center justify-between">
//               <div className="flex items-center gap-3">
//                 <div className="w-6 text-center font-medium text-gray-400">#{i + 1}</div>
//                 <img src={trader.avatar} className="w-6 h-6 rounded-full" alt={trader.username} />
//                 <span>{trader.username}</span>
//               </div>
//               <div className="flex items-center gap-4">
//                 <span className="text-gray-400">{trader.winRate} wins</span>
//                 <span className="text-green-500 font-medium">{trader.profit}</span>
//               </div>
//             </div>
//           ))}
//         </div>
//       </div>

//       {/* Portfolio Summary Card */}
//       <div
//         className={`${
//           isDark ? "bg-gray-900 border-gray-800" : "bg-white border-gray-200"
//         } border rounded-xl p-6 hover:border-green-500/50 transition-colors`}
//       >
//         <div className="flex items-center justify-between mb-6">
//           <div className="flex items-center gap-2">
//             <BarChart2 className="w-5 h-5 text-green-500" />
//             <h2 className="font-medium">Your Portfolio</h2>
//           </div>
//           <button
//             className={`text-sm ${
//               isDark ? "text-gray-400 hover:text-white" : "text-gray-500 hover:text-gray-900"
//             } transition-colors flex items-center gap-1`}
//           >
//             View Details
//             <ChevronRight className="w-4 h-4" />
//           </button>
//         </div>
//         <div className="space-y-6">
//           <div className="grid grid-cols-2 gap-6">
//             <div>
//               <div className={`text-sm mb-1 ${isDark ? "text-gray-400" : "text-gray-500"}`}>24h Profit/Loss</div>
//               <div className="text-xl font-medium text-green-500">+$142.50</div>
//               <div className={`text-sm ${isDark ? "text-gray-400" : "text-gray-500"}`}>+14.25%</div>
//             </div>
//             <div>
//               <div className={`text-sm mb-1 ${isDark ? "text-gray-400" : "text-gray-500"}`}>Active Positions</div>
//               <div className="text-xl font-medium">5 posts</div>
//               <div className={`text-sm ${isDark ? "text-gray-400" : "text-gray-500"}`}>$891 invested</div>
//             </div>
//           </div>
//           <div>
//             <div className="flex items-center justify-between text-sm mb-2">
//               <span className={isDark ? "text-gray-400" : "text-gray-500"}>Win Rate</span>
//               <span className="text-green-500">78%</span>
//             </div>
//             <div className={`h-2 ${isDark ? "bg-gray-800" : "bg-gray-100"} rounded-full overflow-hidden`}>
//               <div className="h-full w-[78%] bg-gradient-to-r from-green-500 to-green-400" />
//             </div>
//           </div>
//         </div>
//       </div>
//     </div>
//   );

//   const TradingCard = ({ post }) => {
//     const isGrowing = post.priceChange >= 0;
//     const hasShares = portfolio[post.id] > 0;

//     return (
//       <div
//         className={`${
//           isDark ? "bg-gray-900 border-gray-800" : "bg-white border-gray-200"
//         } border rounded-xl p-6 hover:border-green-500/50 transition-colors`}
//       >
//         {/* Content Info */}
//         <div className="flex justify-between items-start mb-6">
//           <div className="flex-1">
//             <div className="flex items-center gap-2 text-sm text-gray-400 mb-1">
//               <span className={isDark ? "text-gray-300" : "text-gray-700"}>{post.creator}</span>
//               {post.verified && (
//                 <svg className="w-4 h-4 text-green-500" viewBox="0 0 24 24" fill="currentColor">
//                   <path d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z" />
//                 </svg>
//               )}
//               <span>•</span>
//               <span>{post.uploadTime}</span>
//             </div>
//             <div className="text-xl font-medium mb-2">{post.title}</div>
//             <div className="flex items-center gap-4 text-sm">
//               <div className="text-green-500">{post.prediction}</div>
//               <div className={isDark ? "text-gray-400" : "text-gray-500"}>{post.holders} holders</div>
//               <div className={isDark ? "text-gray-400" : "text-gray-500"}>Vol: {post.volume}</div>
//             </div>
//           </div>
//           <div className="text-right">
//             <div className="text-3xl font-bold flex items-center gap-2 mb-1">
//               ${post.price.toFixed(2)}
//               {isGrowing ? <TrendingUp className="w-6 h-6 text-green-500" /> : <TrendingDown className="w-6 h-6 text-red-500" />}
//             </div>
//             <div className={`text-sm ${isGrowing ? "text-green-500" : "text-red-500"}`}>
//               {isGrowing ? "+" : ""}
//               {(post.priceChange * 100).toFixed(1)}%
//             </div>
//           </div>
//         </div>

//         {/* Stats Grid */}
//         <div className={`grid grid-cols-2 md:grid-cols-4 gap-4 mb-6 ${isDark ? "bg-black/50" : "bg-gray-50"} rounded-lg p-4`}>
//           <div>
//             <div className="flex items-center gap-2 text-sm text-gray-500 mb-1">
//               <Eye className="w-4 h-4" />
//               <span>Views/min</span>
//             </div>
//             <div className="text-lg font-medium tabular-nums">{post.viewGrowth}</div>
//           </div>
//           <div>
//             <div className="flex items-center gap-2 text-sm text-gray-500 mb-1">
//               <Eye className="w-4 h-4" />
//               <span>Total Views</span>
//             </div>
//             <div className="text-lg font-medium tabular-nums">{post.views.toLocaleString()}</div>
//           </div>
//           <div>
//             <div className="flex items-center gap-2 text-sm text-gray-500 mb-1">
//               <ThumbsUp className="w-4 h-4" />
//               <span>Likes</span>
//             </div>
//             <div className="text-lg font-medium tabular-nums">{post.likes.toLocaleString()}</div>
//           </div>
//           <div>
//             <div className="flex items-center gap-2 text-sm text-gray-500 mb-1">
//               <Share2 className="w-4 h-4" />
//               <span>Shares</span>
//             </div>
//             <div className="text-lg font-medium tabular-nums">{post.shares.toLocaleString()}</div>
//           </div>
//         </div>

//         {/* Trading Actions */}
//         <div className="flex flex-col sm:flex-row items-start sm:items-center justify-between gap-4">
//           <div>
//             {hasShares && (
//               <div className="flex items-center gap-4">
//                 <div className={isDark ? "text-gray-400" : "text-gray-500"}>Holding {portfolio[post.id]} shares</div>
//                 <div className={isGrowing ? "text-green-500" : "text-red-500"}>
//                   {isGrowing ? "+" : ""}${((post.price - 1.0) * portfolio[post.id]).toFixed(2)}
//                 </div>
//               </div>
//             )}
//           </div>
//           <div className="flex gap-3 w-full sm:w-auto">
//             <button
//               onClick={() => buyShares(post.id, 10)}
//               className="flex-1 sm:flex-none px-8 py-2.5 bg-green-500 hover:bg-green-600 text-white rounded-lg font-medium transition-all"
//             >
//               Buy 10 Shares
//             </button>
//             {hasShares && (
//               <button
//                 onClick={() => sellShares(post.id, portfolio[post.id])}
//                 className={`flex-1 sm:flex-none px-8 py-2.5 ${
//                   isDark ? "bg-gray-800 hover:bg-gray-700" : "bg-gray-100 hover:bg-gray-200"
//                 } rounded-lg font-medium transition-all`}
//               >
//                 Sell All
//               </button>
//             )}
//           </div>
//         </div>

//         {/* Price Drop Timer */}
//         {post.priceChange <= 0 && (
//           <div className="mt-4 bg-red-500/10 rounded-lg p-4 flex items-center justify-between">
//             <div className="flex items-center gap-2 text-red-500">
//               <Timer className="w-4 h-4" />
//               <span>Price drops in</span>
//             </div>
//             <div className="font-mono text-red-500">4:21</div>
//           </div>
//         )}
//       </div>
//     );
//   };

//   return (
//     <div className={`min-h-screen ${isDark ? "bg-black text-white" : "bg-white text-gray-900"}`}>
//       <Header />
//       <main className="pt-16">
//         <HowItWorksBanner />

//         {/* Main Content */}
//         <div className="max-w-6xl mx-auto px-4 lg:px-6 py-8">
//           {/* Hero Section */}
//           <div className="mb-8">
//             <h1 className="text-4xl md:text-5xl font-bold mb-2">
//               Spot Viral Content.
//               <span className="bg-gradient-to-r from-green-400 to-green-500 bg-clip-text text-transparent"> Trade Early.</span>
//             </h1>
//             <p className={isDark ? "text-gray-400" : "text-gray-600"}>Every post starts at $1. Price follows engagement. Be first.</p>
//           </div>

//           <TopSection />

//           {/* Trading Feed */}
//           <div className="space-y-4">
//             {posts.map((post) => (
//               <TradingCard key={post.id} post={post} />
//             ))}
//           </div>
//         </div>
//       </main>

//       {/* Footer */}
//       <footer className={`border-t ${isDark ? "border-gray-800" : "border-gray-200"}`}>
//         <div className="max-w-6xl mx-auto px-4 lg:px-6 py-4">
//           <div className="flex flex-col md:flex-row md:items-center justify-between gap-4 text-sm">
//             <div className="flex flex-col md:flex-row md:items-center gap-4 md:gap-8">
//               <div className={isDark ? "text-gray-400" : "text-gray-500"}>24h Volume: $891,242</div>
//               <div className={isDark ? "text-gray-400" : "text-gray-500"}>Active Posts: 1,242</div>
//               <div className={isDark ? "text-gray-400" : "text-gray-500"}>Total Traders: 15,832</div>
//             </div>
//             <div className="flex items-center gap-4">
//               <a
//                 href="#"
//                 className={`${isDark ? "text-gray-400 hover:text-white" : "text-gray-500 hover:text-gray-900"} transition-colors`}
//               >
//                 Help
//               </a>
//               <a
//                 href="#"
//                 className={`${isDark ? "text-gray-400 hover:text-white" : "text-gray-500 hover:text-gray-900"} transition-colors`}
//               >
//                 Discord
//               </a>
//               <a
//                 href="#"
//                 className={`${isDark ? "text-gray-400 hover:text-white" : "text-gray-500 hover:text-gray-900"} transition-colors`}
//               >
//                 Twitter
//               </a>
//             </div>
//           </div>
//         </div>
//       </footer>
//     </div>
//   );
// };

// export default ContentTradingApp;

// import React from "react";
// import { Trophy, Clock, Eye, TrendingUp, PlayCircle } from "lucide-react";

// const VictoryCard = () => {
//   // Mock data for the user's name, photo, and video
//   const userName = "Alex Johnson";
//   const userPhoto =
//     "https://plus.unsplash.com/premium_photo-1689568126014-06fea9d5d341?q=80&w=2670&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D";
//   const videoTitle = "$1,000,000 Hide and Seek";
//   const platform = "YouTube";

//   return (
//     <div className="max-w-sm mx-auto bg-gradient-to-br from-black via-gray-900 to-green-900 text-white p-6 rounded-3xl border border-green-500 shadow-2xl transform hover:scale-105 transition-transform duration-300 ease-out">
//       {/* User Info */}
//       <div className="flex items-center mb-6">
//         <img src={userPhoto} alt="User avatar" className="w-16 h-16 rounded-full border-4 border-green-500 shadow-lg" />
//         <div className="ml-4">
//           <div className="text-xl font-bold">{userName}</div>
//           <div className="text-gray-400 text-sm">Spotted 2 hours ago</div>
//         </div>
//       </div>

//       {/* Video Details */}
//       <div className="relative mb-6">
//         <img
//           src="https://plus.unsplash.com/premium_photo-1682497887063-20b47a4506f4?q=80&w=2670&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
//           alt="Video thumbnail"
//           className="w-full h-48 rounded-xl object-cover shadow-lg"
//         />
//         <div className="absolute top-3 left-3 bg-black/80 px-3 py-1.5 rounded-full text-xs font-medium text-gray-300 flex items-center gap-1 shadow-md">
//           <PlayCircle className="w-4 h-4" />
//           {platform}
//         </div>
//         <div className="absolute bottom-3 right-3 bg-black/80 px-3 py-1.5 rounded-full text-xs font-bold text-white shadow-md">
//           14M views
//         </div>
//       </div>

//       {/* Badge */}
//       <div className="flex items-center justify-between mb-6">
//         <div className="flex items-center gap-2 bg-green-600/10 text-green-500 px-3 py-1.5 rounded-full text-sm font-semibold shadow-inner">
//           <Trophy className="w-4 h-4" />
//           Early Spotter
//         </div>
//         <div className="text-gray-400 text-xs flex items-center gap-1">
//           <Clock className="w-4 h-4" />
//           Caught at just 1,243 views
//         </div>
//       </div>

//       {/* Growth Story */}
//       <div className="mb-6">
//         <h3 className="text-4xl font-bold text-green-400 mb-2">+$2,831 Profit 🚀</h3>
//         <p className="text-lg text-gray-400">Massive growth: Now at 14M views!</p>
//       </div>

//       {/* Growth Timeline */}
//       <div className="mb-6">
//         <div className="h-2 bg-gray-800 rounded-full overflow-hidden shadow-inner">
//           <div className="h-full w-[95%] bg-gradient-to-r from-green-500 to-green-300 animate-expand"></div>
//         </div>
//         <div className="flex justify-between mt-2 text-xs text-gray-500">
//           <span>1.2K views</span>
//           <span>14M views</span>
//         </div>
//       </div>

//       {/* Footer */}
//       <div className="flex items-center justify-between">
//         <div>
//           <div className="text-sm text-gray-400">Shared from</div>
//           <div className="text-2xl font-bold text-green-400">SURGE</div>
//         </div>
//         <div className="bg-green-500/10 p-2 rounded-lg shadow-lg">
//           <TrendingUp className="w-6 h-6 text-green-400" />
//         </div>
//       </div>

//       {/* One-line Slogan */}
//       {/* <div className="text-center text-lg font-bold text-green-400 mt-6">"Spot the trend. Own the moment."</div> */}
//     </div>
//   );
// };

// export default VictoryCard;

// import React from "react";
// import { Trophy, Clock, Eye, TrendingUp, PlayCircle, Share2 } from "lucide-react";

// const VictoryCard = () => {
//   return (
//     <div className="max-w-sm mx-auto bg-gradient-to-br from-black via-gray-900 to-green-900 text-white p-6 rounded-3xl border border-green-500/30 shadow-2xl transform hover:scale-105 transition-transform duration-300 ease-out">
//       {/* User Info with Rank */}
//       <div className="flex items-center mb-6">
//         <div className="relative">
//           <img
//             src="https://images.unsplash.com/photo-1509967419530-da38b4704bc6?q=80&w=2695&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
//             alt="User avatar"
//             className="w-16 h-16 rounded-full border-4 border-green-500 shadow-lg"
//           />
//           <div className="absolute -top-2 -right-2 bg-green-500 rounded-full p-1 shadow-lg">
//             <Trophy className="w-4 h-4 text-black" />
//           </div>
//         </div>
//         <div className="ml-4">
//           <div className="text-xl font-bold">Shweta Pandey</div>
//           <div className="flex items-center gap-2">
//             <span className="text-green-400 text-sm font-medium">Top 1% Predictor</span>
//             <span className="w-1.5 h-1.5 rounded-full bg-green-500"></span>
//             <span className="text-gray-400 text-sm">2h ago</span>
//           </div>
//         </div>
//       </div>

//       {/* Content Preview */}
//       <div className="relative mb-6">
//         <img
//           src="https://plus.unsplash.com/premium_photo-1682497887063-20b47a4506f4?q=80&w=2670&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
//           alt="Content preview"
//           className="w-full h-48 rounded-xl object-cover shadow-lg"
//         />
//         <div className="absolute top-3 left-3 bg-black/80 px-3 py-1.5 rounded-full text-xs font-medium text-gray-300 flex items-center gap-1 shadow-md">
//           <PlayCircle className="w-4 h-4" />
//           TikTok
//         </div>
//         <div className="absolute bottom-3 right-3 bg-black/80 px-3 py-1.5 rounded-full text-xs font-bold text-white shadow-md">
//           14M views
//         </div>
//       </div>

//       {/* Prediction Stats */}
//       <div className="flex items-center justify-between mb-6">
//         <div className="flex items-center gap-2 bg-green-500/10 text-green-400 px-4 py-2 rounded-full text-sm font-bold shadow-inner">
//           <Eye className="w-4 h-4" />
//           Spotted at 1,243 views
//         </div>
//       </div>

//       {/* Growth Story */}
//       <div className="mb-6 bg-black/30 p-4 rounded-xl border border-green-500/20">
//         <div className="flex items-center gap-2 mb-2">
//           <TrendingUp className="w-5 h-5 text-green-400" />
//           <h3 className="text-3xl font-bold text-green-400">11,264x Return</h3>
//         </div>
//         <p className="text-gray-400">From 1.2K to 14M views in 2 hours</p>
//       </div>

//       {/* Growth Timeline */}
//       <div className="mb-6">
//         <div className="h-2 bg-black/50 rounded-full overflow-hidden shadow-inner">
//           <div className="h-full w-[95%] bg-gradient-to-r from-green-600 to-green-400 animate-pulse"></div>
//         </div>
//         <div className="flex justify-between mt-2 text-xs text-gray-500">
//           <span>Initial: 1.2K</span>
//           <span>Current: 14M</span>
//         </div>
//       </div>

//       {/* Footer */}
//       <div className="flex items-center justify-between pt-4 border-t border-green-500/20">
//         <div>
//           <div className="text-2xl font-bold bg-gradient-to-r from-green-400 to-green-600 bg-clip-text text-transparent">SURGE</div>
//           <div className="text-xs text-gray-400">#1 Content Trading Platform</div>
//         </div>
//         <Share2 className="w-6 h-6 text-green-400 cursor-pointer hover:text-green-300 transition-colors" />
//       </div>
//     </div>
//   );
// };

// export default VictoryCard;

// import React from "react";
// import { ChartLineUp, Trophy, Clock, Share2, Rocket } from "lucide-react";

// const SpotterCard = () => {
//   return (
//     <div className="relative w-80 h-[32rem] bg-[#0A2647] rounded-2xl overflow-hidden">
//       {/* Company Branding */}
//       <div className="absolute top-4 left-4 text-xs text-[#2C74B3] font-bold">CONTENTSTOCK</div>

//       {/* Glass Container */}
//       <div className="relative h-full backdrop-blur-sm p-6 flex flex-col">
//         {/* User Info */}
//         <div className="mt-8">
//           <div className="flex items-center gap-4">
//             <img
//               src="/api/placeholder/48/48"
//               className="w-12 h-12 rounded-full ring-2 ring-[#2C74B3] ring-offset-2 ring-offset-[#0A2647]"
//               alt="Alex Chen"
//             />
//             <div>
//               <h3 className="font-bold text-white">Alex Chen</h3>
//               <p className="text-xs text-[#2C74B3]">@tradingmaster · Elite Predictor</p>
//             </div>
//           </div>
//         </div>

//         {/* Content Preview */}
//         <div className="mt-6 p-3 bg-black/30 rounded-xl border border-[#2C74B3]/20">
//           <div className="text-sm text-white/80">
//             <span className="text-[#2C74B3]">#Dance</span> Trending TikTok
//             <div className="text-xs text-white/60 mt-1">"This dance move will be everywhere..."</div>
//           </div>
//         </div>

//         {/* Prediction Stats */}
//         <div className="mt-6 p-4 bg-white/5 backdrop-blur-md rounded-xl border border-white/10">
//           <div className="space-y-4">
//             <div className="flex items-center justify-between">
//               <span className="text-[#2C74B3] text-sm">Spotted at</span>
//               <span className="text-white font-mono text-lg">127 views</span>
//             </div>
//             <div className="flex items-center justify-between">
//               <span className="text-[#2C74B3] text-sm">Current</span>
//               <span className="text-white font-mono text-lg">10.2M views</span>
//             </div>
//             <div className="flex items-center justify-between">
//               <Rocket className="w-5 h-5 text-green-400" />
//               <span className="text-green-400 font-mono text-2xl font-bold">80,314x</span>
//             </div>
//           </div>
//         </div>

//         {/* Time + Achievement */}
//         <div className="mt-auto space-y-4">
//           <div className="flex items-center gap-2 text-[#2C74B3]">
//             <Clock className="w-4 h-4" />
//             <span className="text-sm">2 hours ago</span>
//           </div>

//           <div className="flex items-center justify-between p-4 bg-[#2C74B3]/10 rounded-xl backdrop-blur-sm border border-[#2C74B3]/20">
//             <div className="flex items-center gap-3">
//               <div className="w-8 h-8 rounded-full bg-[#2C74B3] flex items-center justify-center">
//                 <Trophy className="w-4 h-4 text-white" />
//               </div>
//               <div>
//                 <p className="text-white font-bold">First Spotter</p>
//                 <p className="text-xs text-[#2C74B3]">Legendary Prediction</p>
//               </div>
//             </div>
//             <Share2 className="w-5 h-5 text-[#2C74B3]" />
//           </div>
//         </div>
//       </div>
//     </div>
//   );
// };

// export default SpotterCard;

// import React, { useState, useEffect } from "react";
// import { TrendingUp, TrendingDown, Wallet, Sun, Moon, Eye, ThumbsUp, Share2, Timer, Gift } from "lucide-react";

// const ContentTradingApp = () => {
//   const [isDark, setIsDark] = useState(true);
//   const [balance, setBalance] = useState(1000.0);
//   const [posts, setPosts] = useState([]);
//   const [portfolio, setPortfolio] = useState({});
//   const [showWelcome, setShowWelcome] = useState(true);

//   // Theme toggle effect
//   useEffect(() => {
//     document.documentElement.classList.toggle("dark", isDark);
//   }, [isDark]);

//   // Initialize trending posts
//   useEffect(() => {
//     setPosts([
//       {
//         id: 1,
//         creator: "MrBeast",
//         verified: true,
//         title: "$1,000,000 Hide and Seek",
//         views: 15000,
//         likes: 2800,
//         price: 1.0,
//         priceChange: 0,
//         uploadTime: "2m ago",
//         prediction: "92% viral chance",
//         viewGrowth: "+2.8k/min",
//       },
//       {
//         id: 2,
//         creator: "Logan Paul",
//         verified: true,
//         title: "I Fought Mike Tyson",
//         views: 8500,
//         likes: 1200,
//         price: 1.0,
//         priceChange: 0,
//         uploadTime: "Just now",
//         prediction: "87% viral chance",
//         viewGrowth: "+1.2k/min",
//       },
//     ]);

//     const interval = setInterval(() => {
//       setPosts((currentPosts) =>
//         currentPosts.map((post) => ({
//           ...post,
//           views: post.views + Math.floor(Math.random() * 1000),
//           likes: post.likes + Math.floor(Math.random() * 100),
//           price: Math.max(0.1, post.price + (Math.random() > 0.3 ? 0.05 : -0.02)),
//           priceChange: Math.random() > 0.3 ? 0.05 : -0.02,
//         }))
//       );
//     }, 1000);

//     return () => clearInterval(interval);
//   }, []);

//   const buyShares = (postId, amount = 1) => {
//     const post = posts.find((p) => p.id === postId);
//     if (!post || post.price * amount > balance) return;
//     setBalance((prev) => prev - post.price * amount);
//     setPortfolio((prev) => ({
//       ...prev,
//       [postId]: (prev[postId] || 0) + amount,
//     }));
//     setShowWelcome(false);
//   };

//   const sellShares = (postId, amount = 1) => {
//     if (!portfolio[postId] || portfolio[postId] < amount) return;
//     const post = posts.find((p) => p.id === postId);
//     setBalance((prev) => prev + post.price * amount);
//     setPortfolio((prev) => ({
//       ...prev,
//       [postId]: prev[postId] - amount,
//     }));
//   };

//   const PaperTradingBanner = () => (
//     <div className={`fixed w-full z-40 top-0 ${isDark ? "bg-green-500/10" : "bg-green-50"}`}>
//       <div className="max-w-6xl mx-auto px-4 lg:px-6">
//         <div className="h-10 flex items-center justify-center md:justify-between text-sm">
//           <div className="flex items-center gap-2">
//             <div className="animate-pulse w-1.5 h-1.5 bg-green-500 rounded-full" />
//             <span className="text-green-500 font-medium">Practice with $1,000 free. No credit card needed.</span>
//           </div>
//           <div className="hidden md:flex items-center gap-6 text-gray-500">
//             <span>✓ Risk-free paper trading</span>
//             <span>✓ Real-time market data</span>
//             <span>✓ Learn as you trade</span>
//           </div>
//         </div>
//       </div>
//     </div>
//   );

//   const Header = () => (
//     <div
//       className={`fixed w-full top-10 ${isDark ? "bg-black/95" : "bg-white/95"} border-b ${
//         isDark ? "border-gray-800" : "border-gray-200"
//       } backdrop-blur-sm z-30`}
//     >
//       <div className="max-w-6xl mx-auto px-4 lg:px-6">
//         <div className="h-14 flex items-center justify-between">
//           <div className="flex items-center gap-6">
//             <div className="text-2xl font-bold bg-gradient-to-r from-green-400 to-green-500 bg-clip-text text-transparent">SURGE</div>
//             <div className={`hidden md:flex items-center gap-2 px-3 py-1.5 rounded ${isDark ? "bg-gray-900" : "bg-gray-100"}`}>
//               <Wallet className="w-4 h-4 text-green-500" />
//               <span className="font-medium">${balance.toFixed(2)}</span>
//               <Gift className="w-4 h-4 text-green-500 ml-2" />
//             </div>
//           </div>

//           <button
//             onClick={() => setIsDark(!isDark)}
//             className={`p-2 rounded-lg ${
//               isDark ? "bg-gray-900 text-gray-300" : "bg-gray-100 text-gray-600"
//             } hover:opacity-80 transition-all`}
//           >
//             {isDark ? <Sun className="w-5 h-5" /> : <Moon className="w-5 h-5" />}
//           </button>
//         </div>
//       </div>
//     </div>
//   );

//   const WelcomeCard = () =>
//     showWelcome && (
//       <div
//         className={`${isDark ? "bg-gray-900" : "bg-white"} border ${isDark ? "border-gray-800" : "border-gray-200"} rounded-xl p-6 mb-6`}
//       >
//         <div className="flex flex-col md:flex-row items-start md:items-center justify-between gap-4">
//           <div>
//             <h2 className="text-xl font-semibold mb-2">👋 Welcome! Here's how it works:</h2>
//             <div className="flex flex-col md:flex-row gap-6 text-sm">
//               <div className={`flex items-center gap-2 ${isDark ? "text-gray-300" : "text-gray-600"}`}>
//                 <div className="w-5 h-5 rounded-full bg-green-500/20 flex items-center justify-center">
//                   <span className="text-xs text-green-500">1</span>
//                 </div>
//                 Every post starts at $1
//               </div>
//               <div className={`flex items-center gap-2 ${isDark ? "text-gray-300" : "text-gray-600"}`}>
//                 <div className="w-5 h-5 rounded-full bg-green-500/20 flex items-center justify-center">
//                   <span className="text-xs text-green-500">2</span>
//                 </div>
//                 Price rises with views
//               </div>
//               <div className={`flex items-center gap-2 ${isDark ? "text-gray-300" : "text-gray-600"}`}>
//                 <div className="w-5 h-5 rounded-full bg-green-500/20 flex items-center justify-center">
//                   <span className="text-xs text-green-500">3</span>
//                 </div>
//                 Sell anytime within 24h
//               </div>
//             </div>
//           </div>
//           <button onClick={() => setShowWelcome(false)} className="text-sm text-gray-400 hover:text-gray-300 transition-colors">
//             Got it
//           </button>
//         </div>
//       </div>
//     );

//   const TrendingPost = ({ post }) => {
//     const isGrowing = post.priceChange >= 0;
//     const hasShares = portfolio[post.id] > 0;

//     return (
//       <div
//         className={`${isDark ? "bg-gray-900" : "bg-white"} border ${
//           isDark ? "border-gray-800" : "border-gray-200"
//         } rounded-xl p-4 md:p-6 hover:border-green-500/30 transition-all`}
//       >
//         <div className="flex flex-col md:flex-row gap-4 md:gap-6">
//           {/* Content Info */}
//           <div className="flex-1">
//             <div className="flex items-center gap-2 text-sm text-gray-400 mb-1">
//               <span className="font-medium">{post.creator}</span>
//               {post.verified && (
//                 <svg className="w-4 h-4 text-green-500" viewBox="0 0 24 24" fill="currentColor">
//                   <path d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z" />
//                 </svg>
//               )}
//               <span>•</span>
//               <span>{post.uploadTime}</span>
//             </div>
//             <h3 className="text-lg font-medium mb-3">{post.title}</h3>

//             {/* Quick Stats */}
//             <div className="grid grid-cols-3 gap-4 mb-4 text-sm">
//               <div>
//                 <div className="flex items-center gap-1.5 text-gray-400 mb-1">
//                   <Eye className="w-4 h-4" />
//                   Views/min
//                 </div>
//                 <div className="font-medium">{post.viewGrowth}</div>
//               </div>
//               <div>
//                 <div className="flex items-center gap-1.5 text-gray-400 mb-1">
//                   <ThumbsUp className="w-4 h-4" />
//                   Likes
//                 </div>
//                 <div className="font-medium">{post.likes.toLocaleString()}</div>
//               </div>
//               <div>
//                 <div className="flex items-center gap-1.5 text-gray-400 mb-1">
//                   <TrendingUp className="w-4 h-4" />
//                   Prediction
//                 </div>
//                 <div className="font-medium text-green-500">{post.prediction}</div>
//               </div>
//             </div>
//           </div>

//           {/* Trading Actions */}
//           <div className="flex flex-col justify-between gap-4 min-w-[200px]">
//             <div className="text-center">
//               <div className="text-2xl font-bold flex items-center justify-center gap-2 mb-1">
//                 ${post.price.toFixed(2)}
//                 {isGrowing ? <TrendingUp className="w-5 h-5 text-green-500" /> : <TrendingDown className="w-5 h-5 text-red-500" />}
//               </div>
//               <div className={`text-sm ${isGrowing ? "text-green-500" : "text-red-500"}`}>
//                 {isGrowing ? "+" : ""}
//                 {(post.priceChange * 100).toFixed(1)}%
//               </div>
//             </div>

//             <div className="space-y-2">
//               <button
//                 onClick={() => buyShares(post.id, 10)}
//                 className="w-full py-2 bg-green-500 hover:bg-green-600 text-white rounded-lg font-medium transition-all"
//               >
//                 Buy 10 Shares
//               </button>

//               {hasShares && (
//                 <div className="text-center">
//                   <div className={isDark ? "text-gray-400" : "text-gray-500"}>Holding {portfolio[post.id]} shares</div>
//                   <button
//                     onClick={() => sellShares(post.id, portfolio[post.id])}
//                     className={`mt-2 w-full py-2 ${
//                       isDark ? "bg-gray-800 hover:bg-gray-700" : "bg-gray-100 hover:bg-gray-200"
//                     } rounded-lg font-medium transition-all`}
//                   >
//                     Sell All
//                   </button>
//                 </div>
//               )}
//             </div>
//           </div>
//         </div>
//       </div>
//     );
//   };

//   return (
//     <div className={`min-h-screen ${isDark ? "bg-black text-white" : "bg-white text-gray-900"}`}>
//       <PaperTradingBanner />
//       <Header />

//       <main className="max-w-6xl mx-auto px-4 lg:px-6 pt-32 pb-8">
//         {/* Hero Section */}
//         <div className="mb-8 text-center md:text-left">
//           <h1 className="text-3xl md:text-4xl lg:text-5xl font-bold mb-4">
//             Spot Viral Content.
//             <span className="bg-gradient-to-r from-green-400 to-green-500 bg-clip-text text-transparent"> Trade Early.</span>
//           </h1>
//           <p className={`text-lg ${isDark ? "text-gray-400" : "text-gray-600"}`}>
//             Start with $1,000 free credits. Buy low, sell when viral.
//           </p>
//         </div>

//         <WelcomeCard />

//         {/* Trending Posts */}
//         <div className="space-y-4">
//           {posts.map((post) => (
//             <TrendingPost key={post.id} post={post} />
//           ))}
//         </div>
//       </main>
//     </div>
//   );
// };

// export default ContentTradingApp;

// import React, { useState, useEffect } from "react";
// import {
//   TrendingUp,
//   TrendingDown,
//   Wallet,
//   Clock,
//   ChevronUp,
//   ChevronDown,
//   Eye,
//   Share2,
//   ThumbsUp,
//   AlertTriangle,
//   PlayCircle,
//   ChevronRight,
//   Trophy,
//   BarChart2,
// } from "lucide-react";

// const ContentStockApp = () => {
//   const [selectedPost, setSelectedPost] = useState(null);
//   const [posts, setPosts] = useState([]);
//   const [balance, setBalance] = useState(1000.0);
//   const [portfolio, setPortfolio] = useState({});
//   const [showIntro, setShowIntro] = useState(true);
//   const [profits, setProfits] = useState({});

//   // Initialize viral content
//   useEffect(() => {
//     setPosts([
//       {
//         id: 1,
//         creator: "@dancingcat",
//         verified: true,
//         title: "This Cat Can't Stop Dancing 😂",
//         views: 15000,
//         viewsPerMinute: 2800,
//         likes: 2800,
//         shares: 450,
//         price: 16.0, // $1 + ($1 per 1000 views)
//         priceHistory: Array(20)
//           .fill(0)
//           .map((_, i) => 15 + Math.random()),
//         thumbnail: "/api/placeholder/320/180",
//         stallTime: null,
//         uploadTime: "2h ago",
//       },
//       {
//         id: 2,
//         creator: "@gamingmoments",
//         title: "Most Insane Fortnite Play 🎮",
//         views: 8000,
//         viewsPerMinute: 0,
//         likes: 1200,
//         shares: 280,
//         price: 9.0,
//         priceHistory: Array(20)
//           .fill(0)
//           .map((_, i) => 9 + Math.random() * 0.5),
//         thumbnail: "/api/placeholder/320/180",
//         stallTime: "04:23",
//         uploadTime: "1h ago",
//       },
//       // Add more posts...
//     ]);

//     // Update views and prices every second
//     const interval = setInterval(() => {
//       setPosts((currentPosts) =>
//         currentPosts.map((post) => {
//           // Random view increase based on viewsPerMinute
//           const viewIncrease = Math.floor((Math.random() * post.viewsPerMinute) / 60);
//           const newViews = post.views + viewIncrease;

//           // Price calculation: $1 base + $1 per 1000 views
//           const newPrice = 1 + newViews / 1000;

//           // Check if growth has stalled
//           const isStalled = viewIncrease === 0;
//           const finalPrice = isStalled
//             ? newPrice * 0.95 // 5% drop if stalled
//             : newPrice;

//           // Update price history
//           const newPriceHistory = [...post.priceHistory.slice(1), finalPrice];

//           return {
//             ...post,
//             views: newViews,
//             price: finalPrice,
//             priceHistory: newPriceHistory,
//             stallTime: isStalled ? post.stallTime || "00:00" : null,
//           };
//         })
//       );

//       // Update portfolio profits
//       const newProfits = {};
//       Object.entries(portfolio).forEach(([postId, shares]) => {
//         const post = posts.find((p) => p.id === parseInt(postId));
//         if (post) {
//           newProfits[postId] = (post.price - 1.0) * shares;
//         }
//       });
//       setProfits(newProfits);
//     }, 1000);

//     return () => clearInterval(interval);
//   }, []);

//   const buyShares = (postId, amount) => {
//     const post = posts.find((p) => p.id === postId);
//     if (!post || post.price * amount > balance) return;

//     setBalance((prev) => prev - post.price * amount);
//     setPortfolio((prev) => ({
//       ...prev,
//       [postId]: (prev[postId] || 0) + amount,
//     }));
//   };

//   const sellShares = (postId, amount) => {
//     const post = posts.find((p) => p.id === postId);
//     if (!post || !portfolio[postId] || portfolio[postId] < amount) return;

//     setBalance((prev) => prev + post.price * amount);
//     setPortfolio((prev) => ({
//       ...prev,
//       [postId]: prev[postId] - amount,
//     }));
//   };

//   const IntroModal = () =>
//     showIntro && (
//       <div className="fixed inset-0 bg-black/95 flex items-center justify-center z-50 p-4">
//         <div className="max-w-md w-full bg-gray-900 rounded-xl p-6">
//           <div className="text-center mb-8">
//             <h2 className="text-2xl font-bold mb-2">Welcome to ContentStock</h2>
//             <p className="text-gray-400">Trade viral content before it blows up</p>
//           </div>

//           <div className="space-y-6 mb-8">
//             <div className="flex gap-4">
//               <div className="w-8 h-8 bg-green-500/20 rounded-full flex items-center justify-center">
//                 <Wallet className="w-4 h-4 text-green-500" />
//               </div>
//               <div>
//                 <div className="font-medium mb-1">Start with $1,000</div>
//                 <div className="text-sm text-gray-400">Risk-free paper trading</div>
//               </div>
//             </div>

//             <div className="flex gap-4">
//               <div className="w-8 h-8 bg-green-500/20 rounded-full flex items-center justify-center">
//                 <TrendingUp className="w-4 h-4 text-green-500" />
//               </div>
//               <div>
//                 <div className="font-medium mb-1">Price = Views</div>
//                 <div className="text-sm text-gray-400">+$1 per 1000 views</div>
//               </div>
//             </div>

//             <div className="flex gap-4">
//               <div className="w-8 h-8 bg-green-500/20 rounded-full flex items-center justify-center">
//                 <Trophy className="w-4 h-4 text-green-500" />
//               </div>
//               <div>
//                 <div className="font-medium mb-1">Compete & Win</div>
//                 <div className="text-sm text-gray-400">Top traders get real prizes</div>
//               </div>
//             </div>
//           </div>

//           <button
//             onClick={() => setShowIntro(false)}
//             className="w-full py-3 bg-green-500 hover:bg-green-600 rounded-lg font-medium transition-all"
//           >
//             Start Trading
//           </button>
//         </div>
//       </div>
//     );

//   const Header = () => (
//     <div className="fixed w-full top-0 bg-black/95 border-b border-gray-800 backdrop-blur-sm z-40">
//       {/* Always-visible trading banner */}
//       <div className="bg-green-500/10 border-b border-green-500/20">
//         <div className="max-w-6xl mx-auto px-4">
//           <div className="h-10 flex items-center justify-between text-sm">
//             <div className="flex items-center gap-2">
//               <div className="animate-pulse w-1.5 h-1.5 bg-green-500 rounded-full" />
//               <span className="text-green-500 font-medium">Trading with $1,000 free • No credit card needed</span>
//             </div>
//             <div className="hidden md:flex items-center gap-6">
//               <span className="text-gray-400">✓ Paper trading</span>
//               <span className="text-gray-400">✓ Real-time data</span>
//               <span className="text-gray-400">✓ Compete for prizes</span>
//             </div>
//           </div>
//         </div>
//       </div>

//       {/* Main header */}
//       <div className="max-w-6xl mx-auto px-4">
//         <div className="h-16 flex items-center justify-between">
//           <div className="flex items-center gap-8">
//             <div className="text-2xl font-bold bg-gradient-to-r from-green-400 to-green-500 bg-clip-text text-transparent">
//               CONTENTSTOCK
//             </div>
//             <div className="hidden md:flex items-center gap-1">
//               <div className="px-3 py-1.5 bg-gray-900 rounded-lg text-sm">24h Volume: $891,242</div>
//               <div className="px-3 py-1.5 bg-gray-900 rounded-lg text-sm">15,832 Traders</div>
//             </div>
//           </div>

//           <div className="flex items-center gap-4">
//             <div className="flex items-center gap-2 px-4 py-2 bg-gray-900 rounded-lg">
//               <Wallet className="w-4 h-4 text-green-500" />
//               <span className="font-medium">${balance.toFixed(2)}</span>
//             </div>

//             <button className="hidden md:block px-4 py-2 bg-gray-900 rounded-lg">Portfolio</button>
//           </div>
//         </div>
//       </div>
//     </div>
//   );

//   const ContentCard = ({ post, expanded = false }) => {
//     const hasShares = portfolio[post.id] > 0;
//     const profit = profits[post.id] || 0;
//     const priceChange = post.price - post.priceHistory[post.priceHistory.length - 2];
//     const priceChangePercent = (priceChange / post.price) * 100;

//     return (
//       <div
//         onClick={() => !expanded && setSelectedPost(post)}
//         className={`bg-gray-900 border border-gray-800 rounded-xl overflow-hidden hover:border-green-500/30 transition-all cursor-pointer
//           ${expanded ? "col-span-2" : ""}`}
//       >
//         {/* Video Preview */}
//         <div className="relative h-48 bg-black">
//           <img src={post.thumbnail} alt={post.title} className="w-full h-full object-cover opacity-80" />
//           <div className="absolute inset-0 flex items-center justify-center">
//             <PlayCircle className="w-12 h-12 text-white/80" />
//           </div>

//           {/* Live Stats */}
//           <div className="absolute bottom-3 left-3 right-3 flex items-center justify-between">
//             <div className="flex items-center gap-2 bg-black/80 px-3 py-1.5 rounded-full text-sm">
//               <Eye className="w-4 h-4" />
//               <span>{(post.views / 1000).toFixed(1)}K views</span>
//             </div>

//             {post.stallTime && (
//               <div className="flex items-center gap-2 bg-red-500/20 text-red-500 px-3 py-1.5 rounded-full text-sm">
//                 <AlertTriangle className="w-4 h-4" />
//                 <span>Stalled {post.stallTime}</span>
//               </div>
//             )}
//           </div>
//         </div>

//         <div className="p-4">
//           {/* Creator & Title */}
//           <div className="mb-4">
//             <div className="flex items-center gap-2 text-sm text-gray-400 mb-1">
//               <span className="font-medium text-white">{post.creator}</span>
//               {post.verified && (
//                 <svg className="w-4 h-4 text-green-500" viewBox="0 0 24 24" fill="currentColor">
//                   <path d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z" />
//                 </svg>
//               )}
//               <span>•</span>
//               <span>{post.uploadTime}</span>
//             </div>
//             <div className="text-lg font-medium">{post.title}</div>
//           </div>

//           {/* Trading Section */}
//           <div className="flex items-start justify-between gap-4">
//             {/* Price Info */}
//             <div>
//               <div className="text-2xl font-bold flex items-center gap-2">
//                 ${post.price.toFixed(2)}
//                 {priceChange >= 0 ? <ChevronUp className="w-5 h-5 text-green-500" /> : <ChevronDown className="w-5 h-5 text-red-500" />}
//               </div>
//               <div className={`text-sm ${priceChange >= 0 ? "text-green-500" : "text-red-500"}`}>
//                 {priceChange >= 0 ? "+" : ""}
//                 {priceChangePercent.toFixed(1)}%
//               </div>
//             </div>

//             {/* Trading Actions */}
//             <div className="flex-1 min-w-[140px]">
//               {hasShares ? (
//                 <div className="space-y-2">
//                   <div className="flex justify-between text-sm">
//                     <span className="text-gray-400">{portfolio[post.id]} shares</span>
//                     <span className={profit >= 0 ? "text-green-500" : "text-red-500"}>
//                       {profit >= 0 ? "+" : ""}${Math.abs(profit).toFixed(2)}
//                     </span>
//                   </div>
//                   <button
//                     onClick={(e) => {
//                       e.stopPropagation();
//                       sellShares(post.id, portfolio[post.id]);
//                     }}
//                     className="w-full py-2 bg-gray-800 hover:bg-gray-700 rounded-lg font-medium transition-all"
//                   >
//                     Sell All
//                   </button>
//                 </div>
//               ) : (
//                 <button
//                   onClick={(e) => {
//                     e.stopPropagation();
//                     buyShares(post.id, 10);
//                   }}
//                   className="w-full py-2 bg-green-500 hover:bg-green-600 rounded-lg font-medium transition-all"
//                 >
//                   Buy 10 Shares
//                 </button>
//               )}
//             </div>
//           </div>

//           {/* Expanded Stats */}
//           {expanded && (
//             <div className="mt-6 grid grid-cols-3 gap-4 p-4 bg-black/30 rounded-lg">
//               <div>
//                 <div className="text-sm text-gray-400 mb-1">Views/min</div>
//                 <div className="font-medium">{post.viewsPerMinute.toLocaleString()}</div>
//               </div>
//               <div>
//                 <div className="text-sm text-gray-400 mb-1">Total Views</div>
//                 <div className="font-medium">{post.views.toLocaleString()}</div>
//               </div>
//               <div>
//                 <div className="text-sm text-gray-400 mb-1">Shares</div>
//                 <div className="font-medium">{post.shares.toLocaleString()}</div>
//               </div>
//             </div>
//           )}
//         </div>
//       </div>
//     );
//   };

//   const LeaderboardSection = () => (
//     <div className="bg-gray-900 border border-gray-800 rounded-xl p-4">
//       <div className="flex items-center justify-between mb-4">
//         <div className="flex items-center gap-2">
//           <Trophy className="w-5 h-5 text-green-500" />
//           <h2 className="font-medium">Top Traders</h2>
//         </div>
//         <button className="text-sm text-gray-400 hover:text-white transition-colors flex items-center gap-1">
//           View All
//           <ChevronRight className="w-4 h-4" />
//         </button>
//       </div>

//       <div className="space-y-3">
//         {[
//           { username: "viral_king", profit: "+$8,942", trades: 24, winRate: "92%" },
//           { username: "trader_x", profit: "+$5,230", trades: 18, winRate: "88%" },
//           { username: "crypto_jane", profit: "+$3,891", trades: 15, winRate: "85%" },
//         ].map((trader, i) => (
//           <div key={i} className="flex items-center justify-between">
//             <div className="flex items-center gap-3">
//               <div className="w-6 text-center font-medium text-gray-400">#{i + 1}</div>
//               <span>{trader.username}</span>
//             </div>
//             <div className="flex items-center gap-4">
//               <span className="text-gray-400">{trader.winRate} wins</span>
//               <span className="text-green-500 font-medium">{trader.profit}</span>
//             </div>
//           </div>
//         ))}
//       </div>
//     </div>
//   );

//   return (
//     <div className="min-h-screen bg-black text-white">
//       <IntroModal />
//       <Header />

//       <main className="max-w-6xl mx-auto px-4 pt-32 pb-8">
//         {/* Quick Stats */}
//         <div className="grid grid-cols-1 md:grid-cols-3 gap-4 mb-8">
//           <div className="bg-gray-900 border border-gray-800 rounded-xl p-4">
//             <div className="text-sm text-gray-400 mb-1">Your Portfolio Value</div>
//             <div className="text-2xl font-bold">
//               $
//               {(
//                 balance +
//                 Object.entries(portfolio).reduce((acc, [postId, shares]) => {
//                   const post = posts.find((p) => p.id === parseInt(postId));
//                   return acc + (post ? post.price * shares : 0);
//                 }, 0)
//               ).toFixed(2)}
//             </div>
//             <div className="text-sm text-green-500">+142.50 (14.25%)</div>
//           </div>

//           <div className="bg-gray-900 border border-gray-800 rounded-xl p-4">
//             <div className="text-sm text-gray-400 mb-1">24h Trading Volume</div>
//             <div className="text-2xl font-bold">$891,242</div>
//             <div className="text-sm text-gray-400">15,832 active traders</div>
//           </div>

//           <div className="bg-gray-900 border border-gray-800 rounded-xl p-4">
//             <div className="text-sm text-gray-400 mb-1">Your Win Rate</div>
//             <div className="text-2xl font-bold">78%</div>
//             <div className="text-sm text-gray-400">14/18 profitable trades</div>
//           </div>
//         </div>

//         {/* Main Content Grid */}
//         <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
//           {/* Trading Feed */}
//           <div className="col-span-2 space-y-4">
//             {selectedPost ? (
//               <>
//                 <button onClick={() => setSelectedPost(null)} className="text-gray-400 hover:text-white mb-4 flex items-center gap-2">
//                   ← Back to feed
//                 </button>
//                 <ContentCard post={selectedPost} expanded={true} />
//               </>
//             ) : (
//               posts.map((post) => <ContentCard key={post.id} post={post} />)
//             )}
//           </div>

//           {/* Sidebar */}
//           <div className="space-y-4">
//             <LeaderboardSection />

//             {/* Portfolio Summary */}
//             <div className="bg-gray-900 border border-gray-800 rounded-xl p-4">
//               <div className="flex items-center justify-between mb-4">
//                 <div className="flex items-center gap-2">
//                   <BarChart2 className="w-5 h-5 text-green-500" />
//                   <h2 className="font-medium">Your Positions</h2>
//                 </div>
//               </div>

//               <div className="space-y-4">
//                 {Object.entries(portfolio).map(([postId, shares]) => {
//                   const post = posts.find((p) => p.id === parseInt(postId));
//                   if (!post || shares === 0) return null;
//                   return (
//                     <div key={postId} className="flex items-center justify-between">
//                       <div className="text-sm truncate flex-1">{post.title}</div>
//                       <div className="text-sm">
//                         <span className="text-gray-400 mr-2">{shares} shares</span>
//                         <span className={profits[postId] >= 0 ? "text-green-500" : "text-red-500"}>
//                           {profits[postId] >= 0 ? "+" : ""}${Math.abs(profits[postId]).toFixed(2)}
//                         </span>
//                       </div>
//                     </div>
//                   );
//                 })}
//               </div>
//             </div>
//           </div>
//         </div>
//       </main>
//     </div>
//   );
// };

// export default ContentStockApp;

// import React, { useState, useEffect, useCallback } from "react";
// import {
//   TrendingUp,
//   TrendingDown,
//   Trophy,
//   Wallet,
//   Bell,
//   Users,
//   Eye,
//   ThumbsUp,
//   Share2,
//   Timer,
//   X,
//   ChevronRight,
//   BarChart2,
//   PlayCircle,
//   Crown,
//   Flame,
//   Shield,
//   Star,
//   Info,
// } from "lucide-react";
// import confetti from "canvas-confetti";

// const Header = ({ isDark, balance }) => (
//   <div
//     className={`fixed w-full top-0 ${isDark ? "bg-gray-900/95" : "bg-white/95"} backdrop-blur-sm border-b ${
//       isDark ? "border-gray-800" : "border-gray-200"
//     } z-30`}
//   >
//     <div className="max-w-6xl mx-auto px-4 lg:px-6">
//       <div className="h-16 flex items-center justify-between">
//         <div className="flex items-center gap-4 lg:gap-8">
//           <div className="text-2xl font-bold bg-gradient-to-r from-green-400 to-green-500 bg-clip-text text-transparent">SURGEE</div>
//         </div>

//         <div className="flex items-center gap-3">
//           <button className="p-2 text-gray-500 hover:text-gray-900">
//             <Bell className="w-5 h-5" />
//           </button>
//           <div className={`flex items-center gap-2 px-4 py-2 ${isDark ? "bg-gray-800" : "bg-gray-100"} rounded-lg`}>
//             <Wallet className="w-4 h-4 text-green-500" />
//             <span className={`font-medium ${isDark ? "text-white" : "text-gray-900"}`}>${balance.toFixed(2)}</span>
//           </div>
//         </div>
//       </div>
//     </div>
//   </div>
// );

// const ContentTradingApp = () => {
//   const [isDark, setIsDark] = useState(true);
//   const [balance, setBalance] = useState(1000.0);
//   const [posts, setPosts] = useState([]);
//   const [portfolio, setPortfolio] = useState({});
//   const [selectedPost, setSelectedPost] = useState(null);
//   const [isBuyModalOpen, setIsBuyModalOpen] = useState(false);
//   const [isSellModalOpen, setIsSellModalOpen] = useState(false);
//   const [showVictoryCard, setShowVictoryCard] = useState(false);
//   const [notifications, setNotifications] = useState([]);

//   const [newPosts, setNewPosts] = useState([]);
//   const [showNewVideoNotification, setShowNewVideoNotification] = useState(false);
//   const [isGenerating, setIsGenerating] = useState(false);
//   //   const generateMockPost = () => {
//   //     const baseViews = Math.floor(Math.random() * 1000) + 100;
//   //     return {
//   //       id: Date.now(),
//   //       creator: `Creator ${Math.floor(Math.random() * 1000)}`,
//   //       verified: Math.random() > 0.7,
//   //       title: `Viral Video #${Math.floor(Math.random() * 100)}`,
//   //       views: baseViews,
//   //       prevViews: baseViews,
//   //       lastUpdate: Date.now(),
//   //       minutesActive: 0,
//   //       price: 1.0,
//   //       priceChange: 0,
//   //       viewsPerMinute: 0,
//   //       holders: Math.floor(Math.random() * 100),
//   //     };
//   //   };

//   const generateMockPost = () => {
//     const spottedViews = Math.floor(Math.random() * 1000) + 100;
//     return {
//       id: Date.now(),
//       creator: `Creator ${Math.floor(Math.random() * 1000)}`,
//       verified: Math.random() > 0.7,
//       title: `Viral Video #${Math.floor(Math.random() * 100)}`,
//       spottedAt: spottedViews, // Initial views when spotted
//       views: spottedViews, // Current views
//       prevViews: spottedViews, // Previous minute views
//       price: 1.0,
//       priceChange: 0,
//       minutesActive: 0,
//       lastUpdate: Date.now(),
//       viewsVelocity: 0,
//       holders: 0,
//     };
//   };

//   useEffect(() => {
//     const interval = setInterval(() => {
//       setIsGenerating(true);
//       const newPost = generateMockPost();
//       setNewPosts((prev) => [newPost, ...prev]);
//       setShowNewVideoNotification(true);
//       setTimeout(() => setIsGenerating(false), 1000);
//     }, 60000);

//     return () => clearInterval(interval);
//   }, []);

//   // New Video Notification
//   const NewVideoNotification = ({ count, onShowNewPosts }) => (
//     <div className="fixed top-20 left-1/2 transform -translate-x-1/2 z-50">
//       <button
//         onClick={onShowNewPosts}
//         className="flex items-center gap-3 bg-gradient-to-r from-green-500 to-green-600 px-6 py-3 rounded-full shadow-lg hover:shadow-2xl transition-all group"
//       >
//         <div className="relative">
//           <Flame className="w-5 h-5 text-white" />
//           <div className="absolute -top-1 -right-1 w-2 h-2 bg-white rounded-full animate-ping" />
//         </div>
//         <span className="text-white font-medium">
//           {count} New {count === 1 ? "Video" : "Videos"} Available
//         </span>
//         <ChevronRight className="w-5 h-5 text-white group-hover:translate-x-1 transition-transform" />
//       </button>
//     </div>
//   );

//   useEffect(() => {
//     const initialPosts = Array.from({ length: 5 }, generateMockPost);
//     setPosts(initialPosts);
//   }, []);

//   const handleShowNewPosts = () => {
//     setPosts((prev) => [...newPosts, ...prev]); // Add new posts to the main feed
//     setNewPosts([]); // Clear new posts
//     setShowNewVideoNotification(false); // Hide notification
//   };

//   useEffect(() => {
//     const interval = setInterval(() => {
//       setNotifications((prev) => [
//         ...prev.slice(-1), // Keep the last 4 notifications
//         {
//           id: Date.now(),
//           message: "Logan Paul's video you skipped just hit 50x growth!",
//         },
//       ]);
//     }, 60000); // Every minute

//     return () => clearInterval(interval);
//   }, []);

//   const NotificationBar = ({ notifications }) => (
//     <div className="fixed bottom-4 right-4 w-80 space-y-3 z-50">
//       {notifications.map((notification) => (
//         <div key={notification.id} className="bg-green-500 text-white px-4 py-3 rounded-lg shadow-md flex items-center justify-between">
//           <span>{notification.message}</span>
//           <button
//             onClick={() => {
//               setNotifications((prev) => prev.filter((n) => n.id !== notification.id));
//             }}
//             className="ml-3 text-gray-200 hover:text-gray-50"
//           >
//             <X className="w-4 h-4" />
//           </button>
//         </div>
//       ))}
//     </div>
//   );
//   const [topGainers, setTopGainers] = useState([
//     {
//       username: "viral_king",
//       profit: "+$8,942",
//       portfolioLink: "/portfolio/viral_king",
//     },
//     {
//       username: "crypto_queen",
//       profit: "+$5,230",
//       portfolioLink: "/portfolio/crypto_queen",
//     },
//     {
//       username: "trend_tracker",
//       profit: "+$3,891",
//       portfolioLink: "/portfolio/trend_tracker",
//     },
//   ]);

//   const TopGainers = ({ topGainers, isDark }) => (
//     <div className={`${isDark ? "bg-gray-900" : "bg-white"} border rounded-xl p-6`}>
//       <h3 className={`text-xl font-semibold mb-4 ${isDark ? "text-white" : "text-gray-900"}`}>Top Gainers Last Hour</h3>
//       <ul className="space-y-3">
//         {topGainers.map((gainer, index) => (
//           <li key={gainer.username} className="flex items-center justify-between border-b pb-2 last:border-0">
//             <div className="flex items-center gap-4">
//               <span className={`text-lg font-bold ${isDark ? "text-white" : "text-gray-900"}`}>#{index + 1}</span>
//               <div>
//                 <a
//                   href={gainer.portfolioLink}
//                   className={`text-lg font-medium ${
//                     isDark ? "text-green-400 hover:text-green-300" : "text-green-600 hover:text-green-500"
//                   } transition-colors`}
//                 >
//                   {gainer.username}
//                 </a>
//                 <div className={`text-sm ${isDark ? "text-gray-400" : "text-gray-500"}`}>{gainer.profit}</div>
//               </div>
//             </div>
//             <div>
//               <a
//                 href={gainer.portfolioLink}
//                 className={`text-sm font-medium ${isDark ? "text-blue-400 hover:text-blue-300" : "text-blue-600 hover:text-blue-500"}`}
//               >
//                 View Portfolio
//               </a>
//             </div>
//           </li>
//         ))}
//       </ul>
//     </div>
//   );

//   const VictoryCard = () => {
//     const [showShareMenu, setShowShareMenu] = useState(false);

//     const shareOptions = [
//       {
//         name: "Copy Link",
//         action: () => navigator.clipboard.writeText("https://surge.trade/victory/xyz123"),
//       },
//       {
//         name: "Share on Twitter",
//         action: () =>
//           window.open(
//             `https://twitter.com/intent/tweet?text=Just spotted a viral TikTok at 1,243 views and made 11,264x return in 2 hours! 🚀 @surge_trade`,
//             "_blank"
//           ),
//       },
//       {
//         name: "Share to Instagram Story",
//         action: () => {}, // Instagram share
//       },
//       {
//         name: "Add to Story",
//         action: () => {}, // Story feature
//       },
//     ];

//     useEffect(() => {
//       const handleClickOutside = () => setShowShareMenu(false);
//       document.addEventListener("click", handleClickOutside);
//       return () => document.removeEventListener("click", handleClickOutside);
//     }, []);

//     return (
//       <div className="max-w-sm mx-auto bg-gradient-to-br from-black via-gray-900 to-green-900 text-white p-6 rounded-3xl border border-green-500/30 shadow-2xl transform hover:scale-105 transition-transform duration-300 ease-out">
//         {/* User Info with Rank */}
//         <div className="flex items-center mb-6">
//           <div className="relative">
//             <img
//               src="https://images.unsplash.com/photo-1509967419530-da38b4704bc6?q=80&w=2695&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
//               alt="User avatar"
//               className="w-16 h-16 rounded-full border-4 border-green-500 shadow-lg"
//             />
//             <div className="absolute -top-2 -right-2 bg-green-500 rounded-full p-1 shadow-lg">
//               <Trophy className="w-4 h-4 text-black" />
//             </div>
//           </div>
//           <div className="ml-4">
//             <div className="text-md font-normal text-gray-500">Collect Your Card</div>
//             <div className="text-xl font-bold">Shweta Pandey</div>
//             <div className="flex items-center gap-2">
//               <span className="text-green-400 text-sm font-medium">Top 1% Predictor</span>
//               <span className="w-1.5 h-1.5 rounded-full bg-green-500"></span>
//               <span className="text-gray-400 text-sm">2h ago</span>
//             </div>
//           </div>
//         </div>

//         {/* Content Preview */}
//         <div className="relative mb-6">
//           <img
//             src="https://plus.unsplash.com/premium_photo-1682497887063-20b47a4506f4?q=80&w=2670&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
//             alt="Content preview"
//             className="w-full h-48 rounded-xl object-cover shadow-lg"
//           />
//           <div className="absolute top-3 left-3 bg-black/80 px-3 py-1.5 rounded-full text-xs font-medium text-gray-300 flex items-center gap-1 shadow-md">
//             <PlayCircle className="w-4 h-4" />
//             TikTok
//           </div>
//           <div className="absolute bottom-3 right-3 bg-black/80 px-3 py-1.5 rounded-full text-xs font-bold text-white shadow-md">
//             14M views
//           </div>
//         </div>

//         {/* Prediction Stats */}
//         <div className="flex items-center justify-between mb-6">
//           <div className="flex items-center gap-2 bg-green-500/10 text-green-400 px-4 py-2 rounded-full text-sm font-bold shadow-inner">
//             <Eye className="w-4 h-4" />
//             Spotted at 1,243 views
//           </div>
//         </div>

//         {/* Growth Story */}
//         <div className="mb-6 bg-black/30 p-4 rounded-xl border border-green-500/20">
//           <div className="flex items-center gap-2 mb-2">
//             <TrendingUp className="w-5 h-5 text-green-400" />
//             <h3 className="text-3xl font-bold text-green-400">11,264x Return</h3>
//           </div>
//           <p className="text-gray-400">From 1.2K to 14M views in 2 hours</p>
//         </div>

//         {/* Growth Timeline */}
//         <div className="mb-6">
//           <div className="h-2 bg-black/50 rounded-full overflow-hidden shadow-inner">
//             <div className="h-full w-[95%] bg-gradient-to-r from-green-600 to-green-400 "></div>
//           </div>
//           <div className="flex justify-between mt-2 text-xs text-gray-500">
//             <span>Initial: 1.2K</span>
//             <span>Current: 14M</span>
//           </div>
//         </div>

//         {/* Footer */}
//         <div className="flex items-center justify-between pt-4 border-t border-green-500/20">
//           <div>
//             <div className="text-2xl font-bold bg-gradient-to-r from-green-400 to-green-600 bg-clip-text text-transparent">SURGE</div>
//             <div className="text-xs text-gray-400">Added to your profile • Share to flex 💪</div>
//           </div>
//           <div className="relative">
//             <Share2
//               onClick={(e) => {
//                 e.stopPropagation();
//                 setShowShareMenu(!showShareMenu);
//               }}
//               className="w-6 h-6 text-green-400 cursor-pointer hover:text-green-300 transition-colors"
//             />

//             {showShareMenu && (
//               <div className="absolute bottom-full right-0 mb-2 w-48 bg-gray-900 rounded-xl border border-green-500/20 overflow-hidden shadow-xl">
//                 {shareOptions.map((option, i) => (
//                   <button
//                     key={i}
//                     onClick={(e) => {
//                       e.stopPropagation();
//                       option.action();
//                       setShowShareMenu(false);
//                     }}
//                     className="w-full px-4 py-2 text-left hover:bg-green-500/10 transition-colors text-sm"
//                   >
//                     {option.name}
//                   </button>
//                 ))}
//               </div>
//             )}
//           </div>
//         </div>
//       </div>
//     );
//   };

//   const HowItWorksBanner = () => (
//     <div
//       className={`sticky top-16 z-20 ${isDark ? "bg-gray-900/95" : "bg-white/95"} backdrop-blur-sm border-b ${
//         isDark ? "border-gray-800" : "border-gray-200"
//       }`}
//     >
//       <div className="max-w-6xl mx-auto px-4 lg:px-6">
//         <div className="py-4 grid grid-cols-2 md:grid-cols-4 gap-4">
//           {[
//             { step: "1", text: "Start at $1" },
//             { step: "2", text: "Price follows views" },
//             { step: "3", text: "-10% if views stall for 5 min" },
//             { step: "4", text: "24h trading window" },
//           ].map((item) => (
//             <div key={item.step} className="flex items-center gap-3">
//               <div className={`w-6 h-6 rounded-full ${isDark ? "bg-green-500/20" : "bg-green-500/10"} flex items-center justify-center`}>
//                 <span className="text-xs text-green-500 font-medium">{item.step}</span>
//               </div>
//               <span className={isDark ? "text-gray-300" : "text-gray-600"}>{item.text}</span>
//             </div>
//           ))}
//         </div>
//       </div>
//     </div>
//   );

//   useEffect(() => {
//     setPosts([
//       {
//         id: 1,
//         creator: "MrBeast",
//         verified: true,
//         title: "$1,000,000 Hide and Seek",
//         views: 15000,
//         likes: 2800,
//         shares: 450,
//         price: 1.0,
//         priceChange: 0,
//         uploadTime: "2m ago",
//         prediction: "92% viral chance",
//         viewGrowth: "+2.8k/min",
//         holders: 42,
//         volume: "$5,891",
//       },
//       {
//         id: 2,
//         creator: "Logan Paul",
//         verified: true,
//         title: "I Fought Mike Tyson",
//         views: 8500,
//         likes: 1200,
//         shares: 280,
//         price: 1.0,
//         priceChange: 0,
//         uploadTime: "Just now",
//         prediction: "87% viral chance",
//         viewGrowth: "+1.2k/min",
//         holders: 28,
//         volume: "$2,451",
//       },
//     ]);

//     const interval = setInterval(() => {
//       setPosts((currentPosts) =>
//         currentPosts.map((post) => ({
//           ...post,
//           views: post.views + Math.floor(Math.random() * 1000),
//           likes: post.likes + Math.floor(Math.random() * 100),
//           shares: post.shares + Math.floor(Math.random() * 20),
//           price: Math.max(0.1, post.price + (Math.random() > 0.3 ? 0.05 : -0.02)),
//           priceChange: Math.random() > 0.3 ? 0.05 : -0.02,
//         }))
//       );
//     }, 1000);

//     return () => clearInterval(interval);
//   }, []);

//   const celebrateProfit = (profit) => {
//     if (profit > 0) {
//       confetti({
//         particleCount: 100,
//         spread: 70,
//         origin: { y: 0.6 },
//       });

//       // Show Victory Card Modal
//       setShowVictoryCard(true);
//     }
//   };

//   const buyShares = (postId, amount) => {
//     const post = posts.find((p) => p.id === postId);
//     if (!post || post.price * amount > balance) return;
//     setBalance((prev) => prev - post.price * amount);
//     setPortfolio((prev) => ({
//       ...prev,
//       [postId]: (prev[postId] || 0) + amount,
//     }));
//     setIsBuyModalOpen(false);
//   };

//   const sellShares = (postId, amount) => {
//     if (!portfolio[postId] || portfolio[postId] < amount) return;
//     const post = posts.find((p) => p.id === postId);
//     const profit = (post.price - 1.0) * amount;
//     setBalance((prev) => prev + post.price * amount);
//     setPortfolio((prev) => ({
//       ...prev,
//       [postId]: prev[postId] - amount,
//     }));
//     celebrateProfit(profit);
//     setIsSellModalOpen(false);
//   };

//   const BuyModal = ({ post }) => {
//     const [amount, setAmount] = useState(10);
//     const total = post?.price * amount || 0;

//     if (!isBuyModalOpen || !post) return null;

//     return (
//       <div className="fixed inset-0 bg-black/50 backdrop-blur-sm flex items-center justify-center z-50">
//         <div className={`${isDark ? "bg-gray-900" : "bg-white"} rounded-2xl p-6 max-w-md w-full mx-4`}>
//           <div className="flex justify-between items-center mb-6">
//             <h3 className={`text-xl font-semibold ${isDark ? "text-white" : "text-gray-900"}`}>Buy Shares</h3>
//             <button onClick={() => setIsBuyModalOpen(false)} className="text-gray-500 hover:text-gray-700">
//               <X className="w-5 h-5" />
//             </button>
//           </div>

//           <div className="space-y-4">
//             <div>
//               <label className={`block mb-2 text-sm ${isDark ? "text-gray-400" : "text-gray-600"}`}>Amount of shares</label>
//               <div className="flex gap-2">
//                 {[10, 50, 100, 500].map((value) => (
//                   <button
//                     key={value}
//                     onClick={() => setAmount(value)}
//                     className={`flex-1 py-2 rounded-lg border transition-all ${
//                       amount === value
//                         ? "border-green-500 bg-green-500/10 text-green-500"
//                         : `${isDark ? "border-gray-800 text-gray-400" : "border-gray-200 text-gray-600"}`
//                     }`}
//                   >
//                     {value}
//                   </button>
//                 ))}
//               </div>
//             </div>

//             <div className={`${isDark ? "bg-black/50" : "bg-gray-50"} p-4 rounded-lg`}>
//               <div className="flex justify-between mb-2">
//                 <span className={isDark ? "text-gray-400" : "text-gray-600"}>Price per share</span>
//                 <span className={isDark ? "text-white" : "text-gray-900"}>${post.price.toFixed(2)}</span>
//               </div>
//               <div className="flex justify-between">
//                 <span className={isDark ? "text-gray-400" : "text-gray-600"}>Total cost</span>
//                 <span className="text-lg font-semibold text-green-500">${total.toFixed(2)}</span>
//               </div>
//             </div>

//             <button
//               onClick={() => buyShares(post.id, amount)}
//               disabled={total > balance}
//               className={`w-full py-3 rounded-lg font-medium transition-all ${
//                 total > balance ? "bg-gray-500 opacity-50 cursor-not-allowed" : "bg-green-500 hover:bg-green-600 text-white"
//               }`}
//             >
//               {total > balance ? "Insufficient Balance" : "Confirm Purchase"}
//             </button>
//           </div>
//         </div>
//       </div>
//     );
//   };

//   const SellModal = ({ post }) => {
//     const [amount, setAmount] = useState(portfolio[post?.id] || 0);
//     const total = post?.price * amount || 0;
//     const profit = (post?.price - 1.0) * amount || 0;

//     if (!isSellModalOpen || !post) return null;

//     const maxShares = portfolio[post.id] || 0;

//     return (
//       <div className="fixed inset-0 bg-black/50 backdrop-blur-sm flex items-center justify-center z-50">
//         <div className={`${isDark ? "bg-gray-900" : "bg-white"} rounded-2xl p-6 max-w-md w-full mx-4`}>
//           <div className="flex justify-between items-center mb-6">
//             <h3 className={`text-xl font-semibold ${isDark ? "text-white" : "text-gray-900"}`}>Sell Shares</h3>
//             <button onClick={() => setIsSellModalOpen(false)} className="text-gray-500 hover:text-gray-700">
//               <X className="w-5 h-5" />
//             </button>
//           </div>

//           <div className="space-y-4">
//             <div>
//               <label className={`block mb-2 text-sm ${isDark ? "text-gray-400" : "text-gray-600"}`}>
//                 Amount to sell (Max: {maxShares})
//               </label>
//               <div className="flex gap-2">
//                 <button
//                   onClick={() => setAmount(maxShares)}
//                   className={`flex-1 py-2 rounded-lg border transition-all ${
//                     amount === maxShares
//                       ? "border-green-500 bg-green-500/10 text-green-500"
//                       : `${isDark ? "border-gray-800 text-gray-400" : "border-gray-200 text-gray-600"}`
//                   }`}
//                 >
//                   Sell All
//                 </button>
//                 {[10, 50, 100].map((value) => (
//                   <button
//                     key={value}
//                     onClick={() => setAmount(Math.min(value, maxShares))}
//                     disabled={value > maxShares}
//                     className={`flex-1 py-2 rounded-lg border transition-all ${
//                       amount === value
//                         ? "border-green-500 bg-green-500/10 text-green-500"
//                         : value > maxShares
//                         ? "opacity-50 cursor-not-allowed"
//                         : `${isDark ? "border-gray-800 text-gray-400" : "border-gray-200 text-gray-600"}`
//                     }`}
//                   >
//                     {value}
//                   </button>
//                 ))}
//               </div>
//             </div>

//             <div className={`${isDark ? "bg-black/50" : "bg-gray-50"} p-4 rounded-lg`}>
//               <div className="flex justify-between mb-2">
//                 <span className={isDark ? "text-gray-400" : "text-gray-600"}>Current price</span>
//                 <span className={isDark ? "text-white" : "text-gray-900"}>${post.price.toFixed(2)}</span>
//               </div>
//               <div className="flex justify-between mb-2">
//                 <span className={isDark ? "text-gray-400" : "text-gray-600"}>Total return</span>
//                 <span className="text-lg font-semibold text-green-500">${total.toFixed(2)}</span>
//               </div>
//               <div className="flex justify-between">
//                 <span className={isDark ? "text-gray-400" : "text-gray-600"}>Profit</span>
//                 <span className={`text-lg font-semibold ${profit >= 0 ? "text-green-500" : "text-red-500"}`}>
//                   {profit >= 0 ? "+" : ""}
//                   {profit.toFixed(2)}
//                 </span>
//               </div>
//             </div>

//             <button
//               onClick={() => sellShares(post.id, amount)}
//               className="w-full py-3 rounded-lg font-medium bg-green-500 hover:bg-green-600 text-white transition-all"
//             >
//               Confirm Sale
//             </button>
//           </div>
//         </div>
//       </div>
//     );
//   };

//   const TopSection = () => (
//     <div className="grid md:grid-cols-2 gap-6 mb-8">
//       {/* Portfolio Card */}
//       <div className={`${isDark ? "bg-gray-900 border-gray-800" : "bg-white border-gray-200"} border rounded-xl p-6`}>
//         <div className="flex items-center justify-between mb-6">
//           <div className="flex items-center gap-2">
//             <BarChart2 className="w-5 h-5 text-green-500" />
//             <h2 className={`font-medium ${isDark ? "text-white" : "text-gray-900"}`}>Your Portfolio</h2>
//           </div>
//         </div>
//         <div className="space-y-6">
//           <div className="grid grid-cols-2 gap-6">
//             <div>
//               <div className={`text-sm mb-1 ${isDark ? "text-gray-400" : "text-gray-500"}`}>24h Profit/Loss</div>
//               <div className="text-xl font-medium text-green-500">+$142.50</div>
//               <div className={`text-sm ${isDark ? "text-gray-400" : "text-gray-500"}`}>+14.25%</div>
//             </div>
//             <div>
//               <div className={`text-sm mb-1 ${isDark ? "text-gray-400" : "text-gray-500"}`}>Active Positions</div>
//               <div className={`text-xl font-medium ${isDark ? "text-white" : "text-gray-900"}`}>5 posts</div>
//               <div className={`text-sm ${isDark ? "text-gray-400" : "text-gray-500"}`}>$891 invested</div>
//             </div>
//           </div>
//           <div>
//             <div className="flex items-center justify-between text-sm mb-2">
//               <span className={isDark ? "text-gray-400" : "text-gray-500"}>Win Rate</span>
//               <span className="text-green-500">78%</span>
//             </div>
//             <div className={`h-2 ${isDark ? "bg-gray-800" : "bg-gray-100"} rounded-full overflow-hidden`}>
//               <div className="h-full w-[78%] bg-gradient-to-r from-green-500 to-green-400" />
//             </div>
//           </div>
//         </div>
//       </div>

//       {/* Leaderboard Card */}
//       <div className={`${isDark ? "bg-gray-900 border-gray-800" : "bg-white border-gray-200"} border rounded-xl p-6`}>
//         <div className="flex items-center justify-between mb-6">
//           <div className="flex items-center gap-2">
//             <Trophy className="w-5 h-5 text-green-500" />
//             <h2 className={`font-medium ${isDark ? "text-white" : "text-gray-900"}`}>Top Traders Today</h2>
//           </div>
//           <button
//             className={`text-sm ${
//               isDark ? "text-gray-400 hover:text-white" : "text-gray-500 hover:text-gray-900"
//             } transition-colors flex items-center gap-1`}
//           >
//             View All
//             <ChevronRight className="w-4 h-4" />
//           </button>
//         </div>
//         <div className="space-y-4">
//           {[
//             { username: "viral_king", profit: "+$8,942", winRate: "92%", trades: 24 },
//             { username: "trader_x", profit: "+$5,230", winRate: "88%", trades: 18 },
//             { username: "crypto_jane", profit: "+$3,891", winRate: "85%", trades: 15 },
//           ].map((trader, i) => (
//             <div key={trader.username} className="flex items-center justify-between">
//               <div className="flex items-center gap-3">
//                 <div className="w-6 text-center font-medium text-gray-400">#{i + 1}</div>
//                 <span className={isDark ? "text-white" : "text-gray-900"}>{trader.username}</span>
//               </div>
//               <div className="flex items-center gap-4">
//                 <span className={isDark ? "text-gray-400" : "text-gray-500"}>{trader.winRate} wins</span>
//                 <span className="text-green-500 font-medium">{trader.profit}</span>
//               </div>
//             </div>
//           ))}
//         </div>
//       </div>
//     </div>
//   );
//   const handleBuy = useCallback(
//     (postId, amount) => {
//       const post = posts.find((p) => p.id === postId);
//       if (!post || post.price * amount > balance) return;

//       setBalance((prev) => prev - post.price * amount);
//       setPortfolio((prev) => ({
//         ...prev,
//         [postId]: (prev[postId] || 0) + amount,
//       }));
//     },
//     [posts, balance]
//   );

//   const handleSell = useCallback(
//     (postId, amount) => {
//       const post = posts.find((p) => p.id === postId);
//       if (!post || !portfolio[postId]) return;

//       const profit = (post.price - 1.0) * amount;
//       setBalance((prev) => prev + post.price * amount);
//       setPortfolio((prev) => ({
//         ...prev,
//         [postId]: 0,
//       }));

//       if (profit > 0) celebrateProfit(profit);
//     },
//     [posts, portfolio]
//   );
//   //   const TradingCard = ({ post }) => {
//   //     const isGrowing = post.priceChange >= 0;
//   //     const hasShares = portfolio[post.id] > 0;

//   //     return (
//   //       <div className={`${isDark ? "bg-gray-900 border-gray-800" : "bg-white border-gray-200"} border rounded-xl p-6`}>
//   //         {/* Content Info */}
//   //         <div className="flex justify-between items-start mb-6">
//   //           <div className="flex-1">
//   //             <div className="flex items-center gap-2 text-sm text-gray-400 mb-1">
//   //               <span className={isDark ? "text-white" : "text-gray-900"}>{post.creator}</span>
//   //               {post.verified && (
//   //                 <svg className="w-4 h-4 text-green-500" viewBox="0 0 24 24" fill="currentColor">
//   //                   <path d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z" />
//   //                 </svg>
//   //               )}
//   //               <span>•</span>
//   //               <span>{post.uploadTime}</span>
//   //             </div>
//   //             <div className={`text-xl font-medium mb-2 ${isDark ? "text-white" : "text-gray-900"}`}>{post.title}</div>
//   //             <div className="flex items-center gap-4 text-sm">
//   //               <div className="text-green-500">{post.prediction}</div>
//   //               <div className={isDark ? "text-gray-400" : "text-gray-500"}>{post.holders} holders</div>
//   //               <div className={isDark ? "text-gray-400" : "text-gray-500"}>Vol: {post.volume}</div>
//   //             </div>
//   //           </div>
//   //           <div className="text-right">
//   //             <div className={`text-3xl font-bold flex items-center gap-2 mb-1 ${isDark ? "text-white" : "text-gray-900"}`}>
//   //               ${post.price.toFixed(2)}
//   //               {isGrowing ? <TrendingUp className="w-6 h-6 text-green-500" /> : <TrendingDown className="w-6 h-6 text-red-500" />}
//   //             </div>
//   //             <div className={`text-sm ${isGrowing ? "text-green-500" : "text-red-500"}`}>
//   //               {isGrowing ? "+" : ""}
//   //               {(post.priceChange * 100).toFixed(1)}%
//   //             </div>
//   //           </div>
//   //         </div>

//   //         {/* Stats Grid */}
//   //         <div className={`grid grid-cols-4 gap-4 mb-6 ${isDark ? "bg-black/50" : "bg-gray-50"} rounded-lg p-4`}>
//   //           <div>
//   //             <div className="flex items-center gap-2 text-sm text-gray-500 mb-1">
//   //               <Eye className="w-4 h-4" />
//   //               <span>Views/min</span>
//   //             </div>
//   //             <div className={`text-lg font-medium ${isDark ? "text-white" : "text-gray-900"}`}>{post.viewGrowth}</div>
//   //           </div>
//   //           <div>
//   //             <div className="flex items-center gap-2 text-sm text-gray-500 mb-1">
//   //               <Eye className="w-4 h-4" />
//   //               <span>Total Views</span>
//   //             </div>
//   //             <div className={`text-lg font-medium ${isDark ? "text-white" : "text-gray-900"}`}>{post.views.toLocaleString()}</div>
//   //           </div>
//   //           <div>
//   //             <div className="flex items-center gap-2 text-sm text-gray-500 mb-1">
//   //               <ThumbsUp className="w-4 h-4" />
//   //               <span>Likes</span>
//   //             </div>
//   //             <div className={`text-lg font-medium ${isDark ? "text-white" : "text-gray-900"}`}>{post.likes.toLocaleString()}</div>
//   //           </div>
//   //           <div>
//   //             <div className="flex items-center gap-2 text-sm text-gray-500 mb-1">
//   //               <Share2 className="w-4 h-4" />
//   //               <span>Shares</span>
//   //             </div>
//   //             <div className={`text-lg font-medium ${isDark ? "text-white" : "text-gray-900"}`}>{post.shares.toLocaleString()}</div>
//   //           </div>
//   //         </div>

//   //         {/* Trading Actions */}
//   //         <div className="flex items-center justify-between">
//   //           {hasShares && (
//   //             <div className="flex items-center gap-4">
//   //               <div className={isDark ? "text-gray-400" : "text-gray-500"}>Holding {portfolio[post.id]} shares</div>
//   //               <div className={isGrowing ? "text-green-500" : "text-red-500"}>
//   //                 {isGrowing ? "+" : ""}
//   //                 {((post.price - 1.0) * portfolio[post.id]).toFixed(2)}
//   //               </div>
//   //             </div>
//   //           )}
//   //           <div className={`flex gap-3 ${!hasShares ? "ml-auto" : ""}`}>
//   //             <button
//   //               onClick={() => {
//   //                 setSelectedPost(post);
//   //                 setIsBuyModalOpen(true);
//   //               }}
//   //               className="px-8 py-2.5 bg-green-500 hover:bg-green-600 text-white rounded-lg font-medium transition-all"
//   //             >
//   //               Buy
//   //             </button>
//   //             {hasShares && (
//   //               <button
//   //                 onClick={() => {
//   //                   setSelectedPost(post);
//   //                   setIsSellModalOpen(true);
//   //                 }}
//   //                 className={`px-8 py-2.5 ${
//   //                   isDark ? "bg-gray-800 hover:bg-gray-700" : "bg-gray-100 hover:bg-gray-200"
//   //                 } rounded-lg font-medium transition-all`}
//   //               >
//   //                 Sell
//   //               </button>
//   //             )}
//   //           </div>
//   //         </div>

//   //         {/* Price Drop Timer */}
//   //         {post.priceChange < 0 && (
//   //           <div className="mt-4 bg-red-500/10 rounded-lg p-4 flex items-center justify-between">
//   //             <div className="flex items-center gap-2 text-red-500">
//   //               <Timer className="w-4 h-4" />
//   //               <span>Price drops in</span>
//   //             </div>
//   //             <div className="font-mono text-red-500">4:21</div>
//   //           </div>
//   //         )}
//   //       </div>
//   //     );
//   //   };

//   //   const TradingCard = ({ post }) => {
//   //     const isGrowing = (post?.priceChange || 0) >= 0;
//   //     const hasShares = portfolio[post?.id] > 0;
//   //     const timeRemaining = 60 - (post?.minutesActive || 0);
//   //     const isExpiring = timeRemaining <= 5;
//   //     const viewsPerMinute = Math.max(0, (post?.views || 0) - (post?.prevViews || 0));

//   //     return (
//   //       <div className={`${isDark ? "bg-gray-900 border-gray-800" : "bg-white border-gray-200"} border rounded-xl p-6`}>
//   //         {/* Header with Timer */}
//   //         <div className="flex justify-between items-start mb-6">
//   //           <div className="flex-1">
//   //             <div className="flex items-center gap-2 text-sm text-gray-400">
//   //               <span className={isDark ? "text-white" : "text-gray-900"}>{post?.creator}</span>
//   //               {post?.verified && <Shield className="w-4 h-4 text-green-500" />}
//   //               <span>•</span>
//   //               <div className={`flex items-center gap-1 ${isExpiring ? "text-red-500" : "text-green-500"}`}>
//   //                 <Timer className="w-4 h-4" />
//   //                 <span>{timeRemaining}m</span>
//   //               </div>
//   //             </div>
//   //             <h3 className={`text-xl font-medium mt-2 ${isDark ? "text-white" : "text-gray-900"}`}>{post?.title}</h3>
//   //           </div>

//   //           {/* Price Display */}
//   //           <div className="text-right">
//   //             <div className={`text-3xl font-bold flex items-center gap-2 ${isDark ? "text-white" : "text-gray-900"}`}>
//   //               ${(post?.price || 0).toFixed(2)}
//   //               {isGrowing ? <TrendingUp className="w-6 h-6 text-green-500" /> : <TrendingDown className="w-6 h-6 text-red-500" />}
//   //             </div>
//   //             <div className={`text-sm ${isGrowing ? "text-green-500" : "text-red-500"}`}>
//   //               {isGrowing ? "+" : ""}
//   //               {((post?.priceChange || 0) * 100).toFixed(1)}%
//   //             </div>
//   //           </div>
//   //         </div>

//   //         {/* Stats Grid */}
//   //         <div className={`grid grid-cols-3 gap-4 mb-6 ${isDark ? "bg-black/50" : "bg-gray-50"} rounded-lg p-4`}>
//   //           <div>
//   //             <div className="flex items-center gap-2 text-sm text-gray-500 mb-1">
//   //               <Eye className="w-4 h-4" />
//   //               <span>Views/min</span>
//   //             </div>
//   //             <div className={`text-lg font-medium ${isDark ? "text-white" : "text-gray-900"}`}>+{viewsPerMinute.toLocaleString()}</div>
//   //           </div>
//   //           <div>
//   //             <div className="flex items-center gap-2 text-sm text-gray-500 mb-1">
//   //               <Eye className="w-4 h-4" />
//   //               <span>Total Views</span>
//   //             </div>
//   //             <div className={`text-lg font-medium ${isDark ? "text-white" : "text-gray-900"}`}>{(post?.views || 0).toLocaleString()}</div>
//   //           </div>
//   //           <div>
//   //             <div className="flex items-center gap-2 text-sm text-gray-500 mb-1">
//   //               <TrendingUp className="w-4 h-4" />
//   //               <span>Growth</span>
//   //             </div>
//   //             <div className={`text-lg font-medium ${viewsPerMinute > 0 ? "text-green-500" : "text-red-500"}`}>
//   //               {((viewsPerMinute / (post?.prevViews || 1)) * 100).toFixed(1)}%
//   //             </div>
//   //           </div>
//   //         </div>

//   //         {/* Trading Actions */}
//   //         <div className="flex items-center justify-between">
//   //           {hasShares && (
//   //             <div className="flex items-center gap-4">
//   //               <div className={isDark ? "text-gray-400" : "text-gray-500"}>Holding {portfolio[post.id]} shares</div>
//   //               <div className={isGrowing ? "text-green-500" : "text-red-500"}>
//   //                 {isGrowing ? "+" : ""}
//   //                 {((post?.price || 0) - 1.0).toFixed(2)}
//   //               </div>
//   //             </div>
//   //           )}
//   //           <div className={`flex gap-3 ${!hasShares ? "ml-auto" : ""}`}>
//   //             <button
//   //               onClick={() => {
//   //                 setSelectedPost(post);
//   //                 setIsBuyModalOpen(true);
//   //               }}
//   //               disabled={timeRemaining <= 0}
//   //               className={`px-8 py-2.5 ${
//   //                 timeRemaining <= 0 ? "bg-gray-500 cursor-not-allowed" : "bg-green-500 hover:bg-green-600"
//   //               } text-white rounded-lg font-medium transition-all`}
//   //             >
//   //               {timeRemaining <= 0 ? "Expired" : "Buy"}
//   //             </button>
//   //             {hasShares && (
//   //               <button
//   //                 onClick={() => {
//   //                   setSelectedPost(post);
//   //                   setIsSellModalOpen(true);
//   //                 }}
//   //                 className="px-8 py-2.5 bg-gray-800 hover:bg-gray-700 rounded-lg font-medium transition-all text-white"
//   //               >
//   //                 Sell
//   //               </button>
//   //             )}
//   //           </div>
//   //         </div>

//   //         {/* Price Update Indicator */}
//   //         {post?.priceChange !== 0 && (
//   //           <div className={`mt-4 ${isGrowing ? "bg-green-500/10" : "bg-red-500/10"} rounded-lg p-4 flex items-center justify-between`}>
//   //             <div className={`flex items-center gap-2 ${isGrowing ? "text-green-500" : "text-red-500"}`}>
//   //               {isGrowing ? <TrendingUp className="w-4 h-4" /> : <TrendingDown className="w-4 h-4" />}
//   //               <span className="font-medium">{isGrowing ? "Price surging" : "Price dropping"}</span>
//   //             </div>
//   //             <div className="font-mono">Next update in {60 - Math.floor((Date.now() - (post?.lastUpdate || Date.now())) / 1000)}s</div>
//   //           </div>
//   //         )}
//   //       </div>
//   //     );
//   //   };

//   const mockPost = {
//     id: "1",
//     creator: "viral_king",
//     verified: true,
//     title: "This Trend Will Break TikTok",
//     views: 1243,
//     prevViews: 100,
//     price: 1.0,
//     priceChange: 0.05,
//     minutesActive: 2,
//     lastUpdate: Date.now() - 30000,
//     viewsVelocity: 85,
//   };

//   const TradingCard = ({ post, portfolio, balance, buyShares, sellShares }) => {
//     const {
//       creator = "Unknown",
//       verified = false,
//       title = "Untitled Video",
//       views = 0,
//       prevViews = 0,
//       price = 1.0,
//       priceChange = 0,
//       minutesActive = 0,
//       lastUpdate = Date.now(),
//       viewsVelocity = 0,
//     } = post || {};
//     const [currentShares, setCurrentShares] = useState(1000);
//     const [lastShareUpdate, setLastShareUpdate] = useState(Date.now());

//     useEffect(() => {
//       const timer = setInterval(() => {
//         setCurrentShares((prev) => {
//           const decrease = Math.floor(Math.random() * 10) + 1;
//           return Math.max(0, prev - decrease);
//         });
//         setLastShareUpdate(Date.now());
//       }, 5000);

//       return () => clearInterval(timer);
//     }, []);

//     const isGrowing = priceChange >= 0;
//     const timeLeft = 60 - minutesActive;
//     const isExpiring = timeLeft <= 5;
//     const viewsPerMinute = views - prevViews;
//     const growthRate = ((viewsPerMinute / (prevViews || 1)) * 100).toFixed(1);

//     return (
//       <div className="bg-black border border-green-500/20 rounded-xl p-6 hover:border-green-500/40 transition-all">
//         <div className="flex justify-between items-start gap-4">
//           <div className="flex items-center gap-3">
//             <div
//               className={`flex items-center gap-2 px-3 py-1.5 rounded-full ${
//                 isExpiring ? "bg-red-500/20 text-red-500" : "bg-green-500/20 text-green-500"
//               }`}
//             >
//               <Timer className="w-4 h-4" />
//               <span className="font-mono font-medium">{timeLeft}m</span>
//             </div>
//             <div className="flex items-center gap-1 text-gray-400">
//               <span className="text-white font-medium">{creator}</span>
//               {verified && <Shield className="w-4 h-4 text-green-500" />}
//             </div>
//           </div>

//           <div className="text-right">
//             <div className="text-3xl font-bold flex items-center gap-2 text-white">
//               ${price.toFixed(2)}
//               {isGrowing ? <TrendingUp className="w-6 h-6 text-green-500" /> : <TrendingDown className="w-6 h-6 text-red-500" />}
//             </div>
//             <div className={isGrowing ? "text-green-500" : "text-red-500"}>
//               {isGrowing ? "+" : ""}
//               {(priceChange * 100).toFixed(1)}%
//             </div>
//           </div>
//         </div>

//         <h3 className="text-xl font-medium my-4 text-white">{title}</h3>

//         <div className="grid grid-cols-3 gap-4 bg-white/5 rounded-lg p-4 backdrop-blur-sm">
//           <div>
//             <div className="flex items-center gap-2 text-gray-400 text-sm mb-1">
//               <Users className="w-4 h-4" />
//               <span>Shares Left</span>
//             </div>
//             <div className="text-lg font-medium text-white">{currentShares} shares</div>
//           </div>
//           <div>
//             <div className="flex items-center gap-2 text-gray-400 text-sm mb-1">
//               <TrendingUp className="w-4 h-4" />
//               <span>Current</span>
//             </div>
//             <div className="text-lg font-medium text-white">{views.toLocaleString()} views</div>
//           </div>
//           <div>
//             <div className="flex items-center gap-2 text-gray-400 text-sm mb-1">
//               <BarChart2 className="w-4 h-4" />
//               <span>Growth</span>
//             </div>
//             <div className={`text-lg font-medium ${Number(growthRate) > 0 ? "text-green-500" : "text-red-500"}`}>{growthRate}%</div>
//           </div>
//         </div>

//         <div className="flex gap-3 mt-6">
//           <button
//             onClick={() => buyShares(post.id, 10)}
//             disabled={timeLeft <= 0 || currentShares < 10}
//             className={`flex-1 py-3 px-6 rounded-lg font-medium transition-all ${
//               timeLeft <= 0 || currentShares < 10 ? "bg-gray-800 text-gray-400" : "bg-green-500 hover:bg-green-600 text-white"
//             }`}
//           >
//             {timeLeft <= 0 ? "Expired" : currentShares < 10 ? "Sold Out" : "Buy $1"}
//           </button>
//           <button
//             onClick={() => sellShares(post.id, portfolio[post.id] || 0)}
//             className="flex-1 py-3 px-6 bg-gray-800 hover:bg-gray-700 text-white rounded-lg font-medium transition-all"
//           >
//             Sell
//           </button>
//         </div>

//         <div className={`mt-4 ${isGrowing ? "bg-green-500/10" : "bg-red-500/10"} rounded-lg p-4 flex items-center justify-between`}>
//           <div className={`flex items-center gap-2 ${isGrowing ? "text-green-500" : "text-red-500"}`}>
//             {isGrowing ? <TrendingUp className="w-4 h-4" /> : <TrendingDown className="w-4 h-4" />}
//             <span className="font-medium">{viewsVelocity}% view velocity</span>
//           </div>
//           <div className="font-mono text-gray-400">Next update in {60 - Math.floor((Date.now() - lastUpdate) / 1000)}s</div>
//         </div>
//       </div>
//     );
//   };
//   return (
//     <div className={`min-h-screen ${isDark ? "bg-black text-white" : "bg-gray-50 text-gray-900"}`}>
//       <Header isDark={isDark} balance={balance} />
//       <main className="pt-16">
//         <div className="max-w-6xl mx-auto px-4 lg:px-6 py-8">
//           {/* Apple-style Hero */}
//           {/* Main Content */}

//           {/* Hero Section */}
//           <div className="relative mb-12">
//             {/* Background effect */}
//             <div className="absolute inset-0 bg-gradient-to-br from-green-500/5 via-transparent to-transparent blur-3xl" />

//             <div className="relative text-center mb-16">
//               <h1 className="text-6xl md:text-7xl font-semibold tracking-tight mb-4">
//                 Spot Viral Content.
//                 <br />
//                 <span className="bg-gradient-to-r from-green-400 to-green-500 bg-clip-text text-transparent">Trade Early.</span>
//               </h1>

//               {/* Subheadline */}
//               <p className="text-xl text-gray-400 mb-8 max-w-2xl mx-auto">
//                 The world's first social prediction market. Trade virtual shares in viral content before it blows up.
//               </p>

//               {/* Status Pills */}
//               <div className="inline-flex items-center gap-3 text-sm bg-black/20 backdrop-blur-sm px-6 py-3 rounded-full">
//                 <span className="text-green-500 font-medium">Paper trading</span>
//                 <span className="w-1 h-1 bg-gray-600 rounded-full" />
//                 <span className="text-gray-400">Learn risk-free</span>
//                 <span className="w-1 h-1 bg-gray-600 rounded-full" />
//                 <span className="text-gray-400">Updates every 5 minutes</span>
//               </div>
//             </div>

//             {/* Trading Power Banner */}

//             <div className="relative mb-12 overflow-hidden rounded-2xl bg-gradient-to-br from-green-500/10 via-green-500/5 to-black/50 backdrop-blur-sm border border-green-500/20 p-6">
//               <div className="absolute top-0 right-0 w-96 h-96 bg-green-500/5 blur-3xl -translate-y-1/2 translate-x-1/2" />

//               <div className="relative flex items-start gap-4">
//                 <div className="w-12 h-12 rounded-full bg-green-500/10 flex items-center justify-center flex-shrink-0">
//                   <TrendingUp className="w-6 h-6 text-green-500" />
//                 </div>
//                 <div>
//                   <h3 className="text-2xl font-semibold text-green-500 mb-2">Portfolio = Trading Power</h3>
//                   <div className="space-y-4">
//                     <p className="text-gray-400 leading-relaxed">
//                       Your portfolio value is your superpower. Example: Start with $10k → spot a viral hit → grow to $50k → you can now buy
//                       50k shares, drop to 2k you drops to 2k max shares. You loose -10% share price when views stall for 5 minutes.
//                     </p>
//                   </div>
//                 </div>
//               </div>
//             </div>

//             {/* Feature Grid */}
//             <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
//               {/* Trading Card */}
//               <div className="group relative overflow-hidden rounded-2xl bg-gradient-to-br from-black to-gray-900 border border-gray-800 hover:border-green-500/30 transition-all duration-300">
//                 <div className="absolute inset-0 bg-gradient-to-br from-green-500/10 to-transparent opacity-0 group-hover:opacity-100 transition-opacity duration-300" />

//                 <div className="relative p-6 space-y-6">
//                   <div className="flex items-center gap-3">
//                     <div className="w-12 h-12 rounded-full bg-green-500/10 flex items-center justify-center">
//                       <Wallet className="w-6 h-6 text-green-500" />
//                     </div>
//                     <h3 className="text-2xl font-semibold">Trade</h3>
//                   </div>

//                   <div className="space-y-4">
//                     <div className="flex items-center gap-3">
//                       <span className="text-3xl font-semibold">$10k</span>
//                       <span className="text-sm text-gray-400">Starting Balance</span>
//                     </div>
//                     <div className="flex items-center gap-3">
//                       <span className="text-3xl font-semibold">1000</span>
//                       <span className="text-sm text-gray-400">Shares per Post</span>
//                     </div>
//                     <div className="flex items-center gap-3">
//                       <span className="text-2xl font-semibold text-green-500">+$1</span>
//                       <span className="text-sm text-gray-400">per 1k Views</span>
//                     </div>
//                   </div>
//                 </div>
//               </div>

//               {/* Protection Card */}
//               <div className="group relative overflow-hidden rounded-2xl bg-gradient-to-br from-black to-gray-900 border border-gray-800 hover:border-blue-500/30 transition-all duration-300">
//                 <div className="absolute inset-0 bg-gradient-to-br from-blue-500/10 to-transparent opacity-0 group-hover:opacity-100 transition-opacity duration-300" />

//                 <div className="relative p-6 space-y-6">
//                   <div className="flex items-center gap-3">
//                     <div className="w-12 h-12 rounded-full bg-blue-500/10 flex items-center justify-center">
//                       <Shield className="w-6 h-6 text-blue-500" />
//                     </div>
//                     <h3 className="text-2xl font-semibold">Protected</h3>
//                   </div>

//                   <div className="space-y-4">
//                     <div className="bg-black/40 rounded-xl p-4">
//                       <div className="text-blue-400 font-medium mb-1">First 5 Trades Safe</div>
//                       <p className="text-sm text-gray-400">Full refund on losses</p>
//                     </div>
//                     <div className="bg-black/40 rounded-xl p-4">
//                       <div className="text-purple-400 font-medium mb-1">Monthly Boost</div>
//                       <p className="text-sm text-gray-400">Trade at peak power</p>
//                     </div>
//                     <div className="bg-black/40 rounded-xl p-4">
//                       <div className="text-red-400 font-medium mb-1">5min Stall Alert</div>
//                       <p className="text-sm text-gray-400">-10% price drop warning</p>
//                     </div>
//                   </div>
//                 </div>
//               </div>

//               {/* Victory Card */}
//               {/* Victory Card */}
//               <div className="group relative overflow-hidden rounded-2xl bg-gradient-to-br from-black to-gray-900 border border-gray-800 hover:border-yellow-500/30 transition-all duration-300">
//                 <div className="absolute inset-0 bg-gradient-to-br from-yellow-500/10 to-transparent opacity-0 group-hover:opacity-100 transition-opacity duration-300" />

//                 <div className="relative p-6 space-y-6">
//                   <div className="flex items-center gap-3">
//                     <div className="w-12 h-12 rounded-full bg-yellow-500/10 flex items-center justify-center">
//                       <Trophy className="w-6 h-6 text-yellow-500" />
//                     </div>
//                     <div>
//                       <h3 className="text-2xl font-semibold">Victory Cards</h3>
//                       <p className="text-sm text-gray-400">Flex your viral predictions</p>
//                     </div>
//                   </div>

//                   <div className="space-y-4">
//                     <div className="relative overflow-hidden bg-black/40 rounded-xl p-4 hover:bg-black/60 transition-colors group/card">
//                       <div className="flex items-center justify-between mb-2">
//                         <div className="flex items-center gap-2">
//                           <Star className="w-4 h-4 text-blue-400" />
//                           <span className="font-medium">Rare Spotter</span>
//                         </div>
//                         <span className="font-bold text-blue-400">10x</span>
//                       </div>
//                       <p className="text-sm text-gray-400">Found at 2k views → 20k views</p>
//                     </div>

//                     <div className="relative overflow-hidden bg-black/40 rounded-xl p-4 hover:bg-black/60 transition-colors">
//                       <div className="flex items-center justify-between mb-2">
//                         <div className="flex items-center gap-2">
//                           <Star className="w-4 h-4 text-purple-400" />
//                           <span className="font-medium">Epic Predictor</span>
//                         </div>
//                         <span className="font-bold text-purple-400">100x</span>
//                       </div>
//                       <p className="text-sm text-gray-400">Found at 1k views → 100k views</p>
//                     </div>

//                     <div className="relative overflow-hidden bg-black/40 rounded-xl p-4 hover:bg-black/60 transition-colors">
//                       <div className="flex items-center justify-between mb-2">
//                         <div className="flex items-center gap-2">
//                           <Star className="w-4 h-4 text-yellow-400" />
//                           <span className="font-medium">Legendary Scout</span>
//                         </div>
//                         <span className="font-bold text-yellow-400">1000x</span>
//                       </div>
//                       <p className="text-sm text-gray-400">Found at 500 views → 500k views</p>
//                       <div className="absolute top-0 right-0 -translate-y-1/2 translate-x-1/2 w-24 h-24 bg-yellow-500/10 blur-2xl"></div>
//                     </div>
//                   </div>
//                 </div>
//               </div>
//             </div>
//           </div>

//           <TopSection />
//           {/* <div className="grid md:grid-cols-3 gap-6">
//             <div className="col-span-1">
//               <TopGainers topGainers={topGainers} isDark={isDark} />
//             </div>
//           </div> */}

//           {/* <NewVideoNotification onShowNewPosts={handleShowNewPosts} /> */}
//           {/* Trading Feed */}
//           <div className="space-y-4">
//             {posts.map((post) => (
//               <TradingCard
//                 key={post.id}
//                 post={post}
//                 portfolio={portfolio}
//                 balance={balance}
//                 buyShares={buyShares}
//                 sellShares={sellShares}
//               />
//               //   <TradingCard key={post.id} post={post} />
//             ))}
//           </div>
//         </div>

//         {showVictoryCard && (
//           <div
//             className="fixed inset-0 bg-black/50 backdrop-blur-sm flex items-center justify-center z-50"
//             onClick={(e) => {
//               if (e.target === e.currentTarget) {
//                 setShowVictoryCard(false);
//               }
//             }}
//           >
//             <VictoryCard profit={(11, 287)} views={120000} entryViews={1243} timestamp={2} onClose={() => setShowVictoryCard(false)} />
//           </div>
//         )}
//       </main>
//       {/* <NotificationBar notifications={notifications} /> */}

//       <BuyModal post={selectedPost} />
//       <SellModal post={selectedPost} />
//     </div>
//   );
// };

// export default ContentTradingApp;

// import React, { useState } from "react";
// import { BarChart3, Github, Strikethrough, Video, Check, AlertCircle, TrendingUp, Users, DollarSign, Server } from "lucide-react";

// const FounderProofDashboard = () => {
//   const [activeTab, setActiveTab] = useState("dashboard");

//   return (
//     <div className="min-h-screen bg-gray-50">
//       {/* Top Navigation */}
//       <nav className="bg-white border-b border-gray-200">
//         <div className="px-8 mx-auto">
//           <div className="flex items-center justify-between h-16">
//             <div className="flex items-center">
//               <h1 className="text-xl font-bold text-gray-900">FOUNDERPROOF</h1>
//             </div>
//             <div className="flex items-center space-x-4">
//               <span className="px-3 py-1 text-sm font-medium text-green-700 bg-green-100 rounded-full">Verified</span>
//               <div className="w-8 h-8 bg-gray-200 rounded-full"></div>
//             </div>
//           </div>
//         </div>
//       </nav>

//       <div className="grid grid-cols-12 gap-6 p-8">
//         {/* Sidebar */}
//         <div className="col-span-2">
//           <div className="space-y-1">
//             <button
//               onClick={() => setActiveTab("dashboard")}
//               className={`w-full flex items-center px-4 py-2 text-sm font-medium rounded-lg ${
//                 activeTab === "dashboard" ? "bg-blue-50 text-blue-700" : "text-gray-700 hover:bg-gray-100"
//               }`}
//             >
//               <BarChart3 className="w-4 h-4 mr-3" />
//               Dashboard
//             </button>
//             <button
//               onClick={() => setActiveTab("updates")}
//               className={`w-full flex items-center px-4 py-2 text-sm font-medium rounded-lg ${
//                 activeTab === "updates" ? "bg-blue-50 text-blue-700" : "text-gray-700 hover:bg-gray-100"
//               }`}
//             >
//               <Video className="w-4 h-4 mr-3" />
//               Weekly Updates
//             </button>
//           </div>
//         </div>

//         {/* Main Content */}
//         <div className="col-span-10">
//           {/* Stats Overview */}
//           <div className="grid grid-cols-4 gap-6 mb-8">
//             <StatsCard title="MRR Growth" value="47%" trend="up" icon={<TrendingUp className="w-5 h-5 text-green-500" />} />
//             <StatsCard title="Active Users" value="2,847" trend="up" icon={<Users className="w-5 h-5 text-blue-500" />} />
//             <StatsCard title="Burn Rate" value="$24.5K" trend="down" icon={<DollarSign className="w-5 h-5 text-red-500" />} />
//             <StatsCard title="Server Cost" value="$1.2K" trend="stable" icon={<Server className="w-5 h-5 text-gray-500" />} />
//           </div>

//           {/* Verification Status */}
//           <div className="p-6 mb-6 bg-white rounded-lg shadow">
//             <h2 className="mb-4 text-lg font-medium">Verification Status</h2>
//             <div className="space-y-4">
//               <VerificationItem title="GitHub Activity" status="verified" icon={<Github />} lastUpdate="Updated 2h ago" />
//               <VerificationItem title="Stripe Revenue" status="verified" icon={<Strikethrough />} lastUpdate="Updated 1h ago" />
//               <VerificationItem title="Weekly Update" status="pending" icon={<Video />} lastUpdate="Due in 2 days" />
//             </div>
//           </div>

//           {/* Recent Activity */}
//           <div className="p-6 bg-white rounded-lg shadow">
//             <h2 className="mb-4 text-lg font-medium">Recent Activity</h2>
//             <div className="space-y-4">
//               <ActivityItem title="Weekly Update Verified" description="AI verification passed for week 23 update" time="2 hours ago" />
//               <ActivityItem title="New VC View" description="Sequoia Capital viewed your metrics" time="5 hours ago" />
//               <ActivityItem title="Metrics Updated" description="GitHub and Stripe data refreshed" time="1 day ago" />
//             </div>
//           </div>
//         </div>
//       </div>
//     </div>
//   );
// };

// const StatsCard = ({ title, value, trend, icon }) => (
//   <div className="p-6 bg-white rounded-lg shadow">
//     <div className="flex items-center justify-between mb-2">
//       <span className="text-sm font-medium text-gray-500">{title}</span>
//       {icon}
//     </div>
//     <div className="flex items-end space-x-2">
//       <span className="text-2xl font-bold text-gray-900">{value}</span>
//       <span className={`text-sm ${trend === "up" ? "text-green-500" : trend === "down" ? "text-red-500" : "text-gray-500"}`}>
//         {trend === "up" ? "↑" : trend === "down" ? "↓" : "→"}
//       </span>
//     </div>
//   </div>
// );

// const VerificationItem = ({ title, status, icon, lastUpdate }) => (
//   <div className="flex items-center justify-between p-4 border rounded-lg">
//     <div className="flex items-center space-x-4">
//       <div className="p-2 bg-gray-100 rounded-lg">{icon}</div>
//       <div>
//         <h3 className="font-medium text-gray-900">{title}</h3>
//         <p className="text-sm text-gray-500">{lastUpdate}</p>
//       </div>
//     </div>
//     {status === "verified" ? (
//       <span className="flex items-center text-green-600">
//         <Check className="w-5 h-5 mr-1" />
//         Verified
//       </span>
//     ) : (
//       <span className="flex items-center text-yellow-600">
//         <AlertCircle className="w-5 h-5 mr-1" />
//         Pending
//       </span>
//     )}
//   </div>
// );

// const ActivityItem = ({ title, description, time }) => (
//   <div className="flex items-start space-x-4">
//     <div className="w-2 h-2 mt-2 bg-blue-500 rounded-full"></div>
//     <div>
//       <h3 className="font-medium text-gray-900">{title}</h3>
//       <p className="text-sm text-gray-500">{description}</p>
//       <span className="text-xs text-gray-400">{time}</span>
//     </div>
//   </div>
// );

// export default FounderProofDashboard;

// import React, { useState } from "react";
// import { Search, Filter, TrendingUp, Users, DollarSign, Zap, Star, ChevronRight, ArrowUpRight, Bell } from "lucide-react";

// const VCDashboard = () => {
//   const [activeFilter, setActiveFilter] = useState("all");

//   return (
//     <div className="min-h-screen bg-gray-50">
//       {/* Top Navigation */}
//       <nav className="bg-white border-b border-gray-200">
//         <div className="px-8 mx-auto">
//           <div className="flex items-center justify-between h-16">
//             <div className="flex items-center">
//               <h1 className="text-xl font-bold text-gray-900">FOUNDERPROOF</h1>
//             </div>
//             <div className="flex items-center space-x-6">
//               <div className="relative">
//                 <Bell className="w-5 h-5 text-gray-500" />
//                 <span className="absolute top-0 right-0 w-2 h-2 bg-red-500 rounded-full"></span>
//               </div>
//               <span className="text-sm font-medium text-gray-700">Sequoia Capital</span>
//               <div className="w-8 h-8 bg-gray-200 rounded-full"></div>
//             </div>
//           </div>
//         </div>
//       </nav>

//       <div className="p-8">
//         {/* Search and Filters */}
//         <div className="flex items-center justify-between mb-8">
//           <div className="flex items-center w-1/2 px-4 py-2 bg-white border rounded-lg">
//             <Search className="w-5 h-5 text-gray-400" />
//             <input
//               type="text"
//               placeholder="Search verified startups..."
//               className="w-full ml-3 text-sm bg-transparent focus:outline-none"
//             />
//           </div>
//           <button className="flex items-center px-4 py-2 text-sm font-medium text-gray-600 bg-white border rounded-lg">
//             <Filter className="w-4 h-4 mr-2" />
//             Add Filters
//           </button>
//         </div>

//         {/* Filter Pills */}
//         <div className="flex space-x-2 mb-8">
//           {["All Startups", "B2B SaaS", "AI/ML", "Fintech", "Enterprise"].map((filter) => (
//             <button
//               key={filter}
//               className={`px-4 py-2 text-sm font-medium rounded-full ${
//                 activeFilter === filter.toLowerCase() ? "bg-blue-100 text-blue-700" : "bg-white text-gray-600 hover:bg-gray-50"
//               }`}
//               onClick={() => setActiveFilter(filter.toLowerCase())}
//             >
//               {filter}
//             </button>
//           ))}
//         </div>

//         {/* Stats Overview */}
//         <div className="grid grid-cols-4 gap-6 mb-8">
//           <StatsCard title="Verified Startups" value="247" change="+12 this week" positive />
//           <StatsCard title="Avg MRR Growth" value="34%" change="+5% vs last month" positive />
//           <StatsCard title="Total Deal Value" value="$42M" change="+$8M this month" positive />
//           <StatsCard title="Portfolio Updates" value="18" change="4 pending review" neutral />
//         </div>

//         {/* Startup List */}
//         <div className="bg-white rounded-lg shadow">
//           <div className="px-6 py-4 border-b">
//             <h2 className="text-lg font-medium">Verified Startups</h2>
//           </div>
//           <div className="divide-y">
//             <StartupItem
//               name="TechCorp AI"
//               description="Enterprise AI Platform"
//               mrr="$127K"
//               growth="+42%"
//               users="1.2K"
//               verified={true}
//               hot={true}
//             />
//             <StartupItem
//               name="CloudScale"
//               description="DevOps Automation"
//               mrr="$89K"
//               growth="+28%"
//               users="842"
//               verified={true}
//               hot={false}
//             />
//             <StartupItem
//               name="DataFlow"
//               description="Data Pipeline Platform"
//               mrr="$245K"
//               growth="+67%"
//               users="3.4K"
//               verified={true}
//               hot={true}
//             />
//           </div>
//         </div>
//       </div>
//     </div>
//   );
// };

// const StatsCard = ({ title, value, change, positive, neutral }) => (
//   <div className="p-6 bg-white rounded-lg shadow">
//     <h3 className="text-sm font-medium text-gray-500">{title}</h3>
//     <p className="mt-2 text-2xl font-bold">{value}</p>
//     <p className={`mt-2 text-sm ${positive ? "text-green-600" : neutral ? "text-gray-500" : "text-red-600"}`}>{change}</p>
//   </div>
// );

// const StartupItem = ({ name, description, mrr, growth, users, verified, hot }) => (
//   <div className="p-6 hover:bg-gray-50 cursor-pointer">
//     <div className="flex items-center justify-between">
//       <div className="flex items-center space-x-4">
//         <div className="w-12 h-12 bg-gray-200 rounded-lg"></div>
//         <div>
//           <div className="flex items-center space-x-2">
//             <h3 className="font-medium">{name}</h3>
//             {verified && <span className="px-2 py-1 text-xs font-medium text-green-700 bg-green-100 rounded-full">Verified</span>}
//             {hot && (
//               <span className="flex items-center px-2 py-1 text-xs font-medium text-orange-700 bg-orange-100 rounded-full">
//                 <Zap className="w-3 h-3 mr-1" />
//                 Hot
//               </span>
//             )}
//           </div>
//           <p className="text-sm text-gray-500">{description}</p>
//         </div>
//       </div>
//       <div className="flex items-center space-x-8">
//         <MetricItem icon={<DollarSign className="w-4 h-4" />} label="MRR" value={mrr} />
//         <MetricItem icon={<TrendingUp className="w-4 h-4" />} label="Growth" value={growth} />
//         <MetricItem icon={<Users className="w-4 h-4" />} label="Users" value={users} />
//         <ChevronRight className="w-5 h-5 text-gray-400" />
//       </div>
//     </div>
//   </div>
// );

// const MetricItem = ({ icon, label, value }) => (
//   <div className="text-center">
//     <div className="flex items-center text-gray-500 mb-1">
//       {icon}
//       <span className="ml-1 text-xs">{label}</span>
//     </div>
//     <p className="text-sm font-medium">{value}</p>
//   </div>
// );

// export default VCDashboard;

// import React, { useState } from "react";
// import {
//   ArrowLeft,
//   TrendingUp,
//   Users,
//   DollarSign,
//   Server,
//   Video,
//   Calendar,
//   GithubIcon,
//   Bookmark,
//   Share2,
//   Star,
//   PlayCircle,
//   ChevronRight,
//   Activity,
// } from "lucide-react";
// import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer } from "recharts";

// const StartupDetailView = () => {
//   const [activeTab, setActiveTab] = useState("overview");

//   const mockData = Array(6)
//     .fill()
//     .map((_, i) => ({
//       month: `Week ${i + 1}`,
//       mrr: 100000 + i * 15000 + Math.random() * 5000,
//       users: 1000 + i * 200 + Math.random() * 100,
//     }));

//   return (
//     <div className="min-h-screen bg-gray-50">
//       {/* Header */}
//       <div className="bg-white border-b">
//         <div className="px-8 py-6">
//           <div className="flex items-center mb-4">
//             <button className="flex items-center text-gray-600 hover:text-gray-900">
//               <ArrowLeft className="w-5 h-5 mr-2" />
//               Back to Dashboard
//             </button>
//           </div>
//           <div className="flex items-center justify-between">
//             <div className="flex items-center space-x-4">
//               <div className="w-16 h-16 bg-gray-200 rounded-lg"></div>
//               <div>
//                 <div className="flex items-center space-x-2">
//                   <h1 className="text-2xl font-bold">TechCorp AI</h1>
//                   <span className="px-2 py-1 text-sm font-medium text-green-700 bg-green-100 rounded-full">Verified</span>
//                 </div>
//                 <p className="text-gray-600">Enterprise AI Platform • Series A</p>
//               </div>
//             </div>
//             <div className="flex items-center space-x-4">
//               <button className="flex items-center px-4 py-2 text-gray-700 bg-white border rounded-lg hover:bg-gray-50">
//                 <Bookmark className="w-4 h-4 mr-2" />
//                 Save
//               </button>
//               <button className="flex items-center px-4 py-2 text-gray-700 bg-white border rounded-lg hover:bg-gray-50">
//                 <Share2 className="w-4 h-4 mr-2" />
//                 Share
//               </button>
//               <button className="px-4 py-2 text-white bg-blue-600 rounded-lg hover:bg-blue-700">Request Intro</button>
//             </div>
//           </div>

//           {/* Tabs */}
//           <div className="flex space-x-6 mt-8">
//             {["Overview", "Metrics", "Updates", "Team", "Documents"].map((tab) => (
//               <button
//                 key={tab}
//                 className={`pb-3 px-1 text-sm font-medium border-b-2 ${
//                   activeTab === tab.toLowerCase() ? "border-blue-600 text-blue-600" : "border-transparent text-gray-500 hover:text-gray-700"
//                 }`}
//                 onClick={() => setActiveTab(tab.toLowerCase())}
//               >
//                 {tab}
//               </button>
//             ))}
//           </div>
//         </div>
//       </div>

//       <div className="p-8">
//         {/* Key Metrics */}
//         <div className="grid grid-cols-4 gap-6 mb-8">
//           <MetricCard title="Monthly Revenue" value="$127,000" change="+42%" positive={true} icon={<DollarSign />} />
//           <MetricCard title="Active Users" value="1,247" change="+18%" positive={true} icon={<Users />} />
//           <MetricCard title="Unit Economics" value="$842" change="+24%" positive={true} icon={<TrendingUp />} />
//           <MetricCard title="Burn Rate" value="$45,000" change="-12%" positive={false} icon={<Activity />} />
//         </div>

//         <div className="grid grid-cols-3 gap-6">
//           {/* Main Metrics */}
//           <div className="col-span-2 space-y-6">
//             {/* Growth Chart */}
//             <div className="p-6 bg-white rounded-lg shadow">
//               <h2 className="mb-4 text-lg font-medium">Revenue Growth</h2>
//               <div className="h-64">
//                 <ResponsiveContainer width="100%" height="100%">
//                   <LineChart data={mockData}>
//                     <CartesianGrid strokeDasharray="3 3" />
//                     <XAxis dataKey="month" />
//                     <YAxis />
//                     <Tooltip />
//                     <Line type="monotone" dataKey="mrr" stroke="#2563eb" strokeWidth={2} />
//                   </LineChart>
//                 </ResponsiveContainer>
//               </div>
//             </div>

//             {/* Weekly Updates */}
//             <div className="p-6 bg-white rounded-lg shadow">
//               <h2 className="mb-4 text-lg font-medium">Weekly Updates</h2>
//               {[1, 2, 3].map((week) => (
//                 <div key={week} className="flex items-start space-x-4 mb-6 last:mb-0">
//                   <div className="flex-shrink-0">
//                     <div className="p-2 bg-blue-100 rounded-lg">
//                       <Video className="w-6 h-6 text-blue-600" />
//                     </div>
//                   </div>
//                   <div className="flex-grow">
//                     <div className="flex items-center justify-between">
//                       <h3 className="font-medium">Week {week} Update</h3>
//                       <span className="text-sm text-gray-500">2 days ago</span>
//                     </div>
//                     <p className="mt-1 text-sm text-gray-600">Growth metrics and product updates for week {week}</p>
//                     <button className="flex items-center mt-2 text-sm text-blue-600 hover:text-blue-700">
//                       <PlayCircle className="w-4 h-4 mr-1" />
//                       Watch Update
//                     </button>
//                   </div>
//                 </div>
//               ))}
//             </div>
//           </div>

//           {/* Verification Sidebar */}
//           <div className="space-y-6">
//             <div className="p-6 bg-white rounded-lg shadow">
//               <h2 className="mb-4 text-lg font-medium">Verification Status</h2>
//               <div className="space-y-4">
//                 <VerificationItem title="Revenue Data" source="Stripe" status="Verified" lastUpdate="2 hours ago" />
//                 <VerificationItem title="User Metrics" source="Analytics" status="Verified" lastUpdate="1 hour ago" />
//                 <VerificationItem title="GitHub Activity" source="GitHub" status="Verified" lastUpdate="30 mins ago" />
//                 <VerificationItem title="AWS Costs" source="AWS" status="Verified" lastUpdate="1 hour ago" />
//               </div>
//             </div>

//             <div className="p-6 bg-white rounded-lg shadow">
//               <h2 className="mb-4 text-lg font-medium">Company Info</h2>
//               <div className="space-y-4">
//                 <InfoItem label="Founded" value="2022" />
//                 <InfoItem label="Location" value="San Francisco, CA" />
//                 <InfoItem label="Team Size" value="24 employees" />
//                 <InfoItem label="Industry" value="Enterprise Software" />
//                 <InfoItem label="Last Funding" value="$4M Seed" />
//               </div>
//             </div>
//           </div>
//         </div>
//       </div>
//     </div>
//   );
// };

// const MetricCard = ({ title, value, change, positive, icon }) => (
//   <div className="p-6 bg-white rounded-lg shadow">
//     <div className="flex items-center justify-between mb-2">
//       <span className="text-sm font-medium text-gray-500">{title}</span>
//       <div className="p-2 bg-gray-100 rounded-full">{icon}</div>
//     </div>
//     <div className="flex items-baseline space-x-2">
//       <span className="text-2xl font-bold">{value}</span>
//       <span className={`text-sm ${positive ? "text-green-600" : "text-red-600"}`}>{change}</span>
//     </div>
//   </div>
// );

// const VerificationItem = ({ title, source, status, lastUpdate }) => (
//   <div className="flex items-center justify-between">
//     <div>
//       <h3 className="font-medium">{title}</h3>
//       <p className="text-sm text-gray-500">via {source}</p>
//     </div>
//     <div className="text-right">
//       <span className="text-sm font-medium text-green-600">{status}</span>
//       <p className="text-xs text-gray-500">{lastUpdate}</p>
//     </div>
//   </div>
// );

// const InfoItem = ({ label, value }) => (
//   <div className="flex items-center justify-between">
//     <span className="text-sm text-gray-500">{label}</span>
//     <span className="text-sm font-medium">{value}</span>
//   </div>
// );

// export default StartupDetailView;

// import React from "react";
// import { TrendingUp, Users, DollarSign, ArrowUpRight, Video } from "lucide-react";

// const VCDashboard = () => {
//   return (
//     <div className="min-h-screen bg-gray-50">
//       {/* Simple Nav */}
//       <nav className="bg-white border-b">
//         <div className="px-8 py-4 flex items-center justify-between">
//           <span className="font-bold text-xl">FOUNDERPROOF</span>
//           <span className="text-sm">Sequoia Capital</span>
//         </div>
//       </nav>

//       <div className="p-8 max-w-6xl mx-auto">
//         {/* Hottest Verified Startups */}
//         <div className="bg-white rounded-lg shadow mb-8">
//           <div className="p-6 border-b">
//             <h2 className="font-semibold text-lg">🔥 Top Growing Startups (Verified)</h2>
//           </div>

//           <div className="divide-y">
//             {/* Startup Row */}
//             {[
//               {
//                 name: "TechCorp AI",
//                 desc: "AI Infrastructure",
//                 mrr: "$125K",
//                 growth: "42%",
//                 users: "1.2K",
//               },
//               {
//                 name: "DataFlow",
//                 desc: "Data Pipeline",
//                 mrr: "$89K",
//                 growth: "38%",
//                 users: "842",
//               },
//               {
//                 name: "CloudScale",
//                 desc: "DevOps Platform",
//                 mrr: "$245K",
//                 growth: "67%",
//                 users: "3.4K",
//               },
//             ].map((startup) => (
//               <div key={startup.name} className="p-6 hover:bg-blue-50 cursor-pointer transition-colors">
//                 <div className="flex items-center justify-between">
//                   {/* Company Info */}
//                   <div className="flex items-center space-x-4">
//                     <div className="w-12 h-12 bg-gray-100 rounded flex items-center justify-center font-bold text-gray-500">
//                       {startup.name.charAt(0)}
//                     </div>
//                     <div>
//                       <div className="flex items-center space-x-2">
//                         <h3 className="font-semibold">{startup.name}</h3>
//                         <span className="px-2 py-0.5 bg-green-100 text-green-700 rounded-full text-xs font-medium">Verified</span>
//                       </div>
//                       <p className="text-sm text-gray-600">{startup.desc}</p>
//                     </div>
//                   </div>

//                   {/* Key Metrics */}
//                   <div className="flex items-center space-x-12">
//                     {/* MRR */}
//                     <div className="text-center">
//                       <div className="flex items-center text-gray-500 mb-1">
//                         <DollarSign className="w-4 h-4 mr-1" />
//                         <span className="text-xs">MRR</span>
//                       </div>
//                       <p className="font-medium">{startup.mrr}</p>
//                     </div>

//                     {/* Growth */}
//                     <div className="text-center">
//                       <div className="flex items-center text-gray-500 mb-1">
//                         <TrendingUp className="w-4 h-4 mr-1" />
//                         <span className="text-xs">Growth</span>
//                       </div>
//                       <p className="font-medium text-green-600">+{startup.growth}</p>
//                     </div>

//                     {/* Users */}
//                     <div className="text-center">
//                       <div className="flex items-center text-gray-500 mb-1">
//                         <Users className="w-4 h-4 mr-1" />
//                         <span className="text-xs">Users</span>
//                       </div>
//                       <p className="font-medium">{startup.users}</p>
//                     </div>

//                     {/* Latest Update */}
//                     <button className="flex items-center space-x-2 text-blue-600 hover:text-blue-700">
//                       <Video className="w-4 h-4" />
//                       <span className="text-sm">Latest Update</span>
//                     </button>
//                   </div>
//                 </div>
//               </div>
//             ))}
//           </div>
//         </div>

//         {/* Recent Updates */}
//         <div className="bg-white rounded-lg shadow">
//           <div className="p-6 border-b">
//             <h2 className="font-semibold text-lg">📹 Latest Verified Updates</h2>
//           </div>

//           <div className="grid grid-cols-3 gap-6 p-6">
//             {[1, 2, 3].map((i) => (
//               <div key={i} className="bg-gray-50 rounded-lg p-4 hover:bg-gray-100 cursor-pointer">
//                 <div className="flex justify-between items-start mb-3">
//                   <div>
//                     <h3 className="font-medium">TechCorp AI</h3>
//                     <p className="text-sm text-gray-600">Week {i} Update</p>
//                   </div>
//                   <span className="text-xs text-gray-500">2h ago</span>
//                 </div>
//                 <div className="flex items-center text-sm text-gray-600">
//                   <Video className="w-4 h-4 mr-2" />
//                   <span>2 min update</span>
//                 </div>
//               </div>
//             ))}
//           </div>
//         </div>
//       </div>
//     </div>
//   );
// };

// export default VCDashboard;

// import React from "react";
// import { CheckCircle, TrendingUp, Video, GithubIcon, BarChart2, Clock, Shield, ArrowRight, Star, ChevronRight } from "lucide-react";

// const LandingPage = () => {
//   return (
//     <div className="min-h-screen bg-white">
//       {/* Nav */}
//       <nav className="border-b">
//         <div className="max-w-6xl mx-auto px-6 py-4 flex justify-between items-center">
//           <div className="text-2xl font-bold">PROOV</div>
//           <button className="px-4 py-2 bg-black text-white rounded-lg hover:bg-gray-800">Get Early Access</button>
//         </div>
//       </nav>

//       {/* Hero */}
//       <div className="pt-20 pb-16 bg-gradient-to-b from-gray-50">
//         <div className="max-w-4xl mx-auto px-6 text-center">
//           <div className="inline-flex items-center bg-gray-900 text-white px-4 py-2 rounded-full text-sm mb-6">
//             <Star className="w-4 h-4 mr-2" /> Now in private beta with top VCs
//           </div>

//           <h1 className="text-5xl font-bold mb-6 bg-clip-text text-transparent bg-gradient-to-r from-gray-900 to-gray-600">
//             The Trust Layer for Startup Growth
//           </h1>

//           <p className="text-xl text-gray-600 mb-10">
//             Weekly video updates + automatic metric verification. Build trust with VCs through consistent, verified growth.
//           </p>

//           <div className="flex justify-center space-x-4 mb-16">
//             <button className="px-6 py-3 bg-black text-white rounded-lg hover:bg-gray-800 flex items-center">
//               Request Access <ArrowRight className="w-4 h-4 ml-2" />
//             </button>
//             <button className="px-6 py-3 border border-gray-300 rounded-lg hover:bg-gray-50 flex items-center">
//               VC? Schedule Demo <ChevronRight className="w-4 h-4 ml-2" />
//             </button>
//           </div>

//           <div className="flex justify-center space-x-8 text-sm text-gray-500">
//             <div className="flex items-center">
//               <CheckCircle className="w-4 h-4 mr-2" /> Connects in 2 minutes
//             </div>
//             <div className="flex items-center">
//               <CheckCircle className="w-4 h-4 mr-2" /> Always free for founders
//             </div>
//             <div className="flex items-center">
//               <CheckCircle className="w-4 h-4 mr-2" /> Cancel anytime
//             </div>
//           </div>
//         </div>
//       </div>

//       {/* How it Works */}
//       <div className="py-20 bg-white">
//         <div className="max-w-6xl mx-auto px-6">
//           <h2 className="text-3xl font-bold text-center mb-16">Build Trust Through Verified Growth</h2>

//           <div className="grid md:grid-cols-3 gap-12">
//             <div className="text-center">
//               <div className="w-16 h-16 bg-blue-50 rounded-2xl flex items-center justify-center mx-auto mb-6">
//                 <Video className="w-8 h-8 text-blue-600" />
//               </div>
//               <h3 className="font-semibold mb-3">2-Min Weekly Updates</h3>
//               <p className="text-gray-600">Share progress in quick video updates. No endless pitch meetings.</p>
//             </div>

//             <div className="text-center">
//               <div className="w-16 h-16 bg-green-50 rounded-2xl flex items-center justify-center mx-auto mb-6">
//                 <Shield className="w-8 h-8 text-green-600" />
//               </div>
//               <h3 className="font-semibold mb-3">Automatic Verification</h3>
//               <p className="text-gray-600">Connect GitHub & analytics. We verify your claims automatically.</p>
//             </div>

//             <div className="text-center">
//               <div className="w-16 h-16 bg-purple-50 rounded-2xl flex items-center justify-center mx-auto mb-6">
//                 <TrendingUp className="w-8 h-8 text-purple-600" />
//               </div>
//               <h3 className="font-semibold mb-3">VC Visibility</h3>
//               <p className="text-gray-600">VCs see your verified growth. Get noticed for real traction.</p>
//             </div>
//           </div>
//         </div>
//       </div>

//       {/* Social Proof */}
//       <div className="py-20 bg-gray-50">
//         <div className="max-w-6xl mx-auto px-6">
//           <div className="text-center mb-16">
//             <h2 className="text-3xl font-bold mb-4">Trusted by Growing Startups</h2>
//             <p className="text-gray-600 text-xl">Join hundreds of founders building trust with verified growth</p>
//           </div>

//           <div className="grid md:grid-cols-2 gap-8">
//             <div className="bg-white p-8 rounded-xl shadow-sm">
//               <div className="flex items-start mb-4">
//                 <div className="w-12 h-12 bg-gray-100 rounded-full mr-4"></div>
//                 <div>
//                   <h4 className="font-semibold">Sarah Chen</h4>
//                   <p className="text-gray-600">Founder, TechCorp</p>
//                 </div>
//               </div>
//               <p className="text-gray-600">
//                 "PROOV has completely changed how we share progress with investors. Weekly updates are easy, and the automatic verification
//                 gives us instant credibility."
//               </p>
//             </div>

//             <div className="bg-white p-8 rounded-xl shadow-sm">
//               <div className="flex items-start mb-4">
//                 <div className="w-12 h-12 bg-gray-100 rounded-full mr-4"></div>
//                 <div>
//                   <h4 className="font-semibold">Michael Zhang</h4>
//                   <p className="text-gray-600">Partner, Sequoia</p>
//                 </div>
//               </div>
//               <p className="text-gray-600">
//                 "Finally, a way to see real startup progress without the fluff. The weekly format and verified metrics save us hours of due
//                 diligence."
//               </p>
//             </div>
//           </div>
//         </div>
//       </div>

//       {/* CTA */}
//       <div className="py-20 bg-black text-white">
//         <div className="max-w-4xl mx-auto px-6 text-center">
//           <h2 className="text-3xl font-bold mb-6">Start Building Trust Today</h2>
//           <p className="text-gray-400 text-xl mb-10">Join the waitlist to get early access. Free for founders.</p>
//           <button className="px-8 py-4 bg-white text-black rounded-lg hover:bg-gray-100 font-medium">Request Access</button>
//         </div>
//       </div>

//       {/* Footer */}
//       <footer className="py-12 bg-white">
//         <div className="max-w-6xl mx-auto px-6 flex justify-between items-center">
//           <div className="text-2xl font-bold">PROOV</div>
//           <div className="text-sm text-gray-500">© 2025 PROOV. All rights reserved.</div>
//         </div>
//       </footer>
//     </div>
//   );
// };

// export default LandingPage;

// import React, { useState, useEffect } from "react";
// import {
//   ArrowRight,
//   ChartBar,
//   Video,
//   Check,
//   Brain,
//   Users,
//   Target,
//   Award,
//   Zap,
//   Clock,
//   Shield,
//   LineChart,
//   Play,
//   ChevronRight,
//   Star,
//   Lightning,
//   Sparkles,
//   ArrowUpRight,
//   Timer,
//   BarChart2,
//   Globe,
//   Lock,
// } from "lucide-react";

// // Animated gradient button with hover effect
// const GradientButton = ({ children, primary, className = "", onClick }) => (
//   <button
//     onClick={onClick}
//     className={`
//       relative overflow-hidden group px-8 py-4 rounded-xl font-bold text-lg
//       transition-all duration-300 transform hover:scale-105
//       ${
//         primary
//           ? `
//         bg-gradient-to-r from-indigo-600 to-purple-600
//         hover:from-indigo-700 hover:to-purple-700 text-white
//         shadow-lg hover:shadow-2xl
//       `
//           : `
//         bg-white text-indigo-600 border-2 border-indigo-600
//         hover:bg-indigo-50
//       `
//       }
//       ${className}
//     `}
//   >
//     <div className="relative flex items-center justify-center gap-2">
//       {children}
//       <div className="w-6 h-6 flex items-center justify-center">
//         <ArrowRight
//           className={`
//           w-5 h-5 transform transition-transform duration-300
//           group-hover:translate-x-1
//         `}
//         />
//       </div>
//     </div>
//   </button>
// );

// // Animated metric counter
// const MetricCounter = ({ value, label }) => {
//   const [count, setCount] = useState(0);

//   useEffect(() => {
//     const duration = 2000; // 2 seconds animation
//     const steps = 60;
//     const increment = value / steps;
//     let current = 0;

//     const timer = setInterval(() => {
//       current += increment;
//       if (current >= value) {
//         setCount(value);
//         clearInterval(timer);
//       } else {
//         setCount(Math.floor(current));
//       }
//     }, duration / steps);

//     return () => clearInterval(timer);
//   }, [value]);

//   return (
//     <div className="text-center">
//       <div className="text-4xl lg:text-5xl font-bold bg-gradient-to-r from-indigo-600 to-purple-600 bg-clip-text text-transparent">
//         {count}+
//       </div>
//       <div className="text-gray-600 mt-2">{label}</div>
//     </div>
//   );
// };

// // Feature card with hover animations
// const FeatureCard = ({ icon: Icon, title, description, className = "" }) => (
//   <div
//     className={`
//     relative p-8 rounded-2xl bg-white group
//     transition-all duration-300 hover:scale-105
//     border border-gray-100 hover:border-indigo-200
//     shadow-lg hover:shadow-2xl
//     ${className}
//   `}
//   >
//     <div className="absolute top-0 left-0 w-full h-1 bg-gradient-to-r from-indigo-600 to-purple-600 rounded-t-2xl opacity-0 group-hover:opacity-100 transition-opacity duration-300" />

//     <div className="flex items-center gap-4 mb-4">
//       <div className="p-3 rounded-xl bg-indigo-50 group-hover:bg-indigo-100 transition-colors duration-300">
//         <Icon className="w-6 h-6 text-indigo-600" />
//       </div>
//       <h3 className="text-xl font-bold text-gray-800">{title}</h3>
//     </div>

//     <p className="text-gray-600 leading-relaxed">{description}</p>
//   </div>
// );

// // Modern step component with animation
// const StepCard = ({ number, title, items }) => (
//   <div className="relative group">
//     <div className="absolute inset-0 bg-gradient-to-r from-indigo-600/5 to-purple-600/5 rounded-2xl transition-all duration-300 group-hover:scale-105" />
//     <div className="relative p-8 rounded-2xl border border-gray-200 hover:border-indigo-200 transition-all duration-300">
//       <div className="flex items-center gap-4 mb-6">
//         <div className="w-12 h-12 rounded-xl bg-gradient-to-r from-indigo-600 to-purple-600 flex items-center justify-center text-white font-bold text-lg">
//           {number}
//         </div>
//         <h3 className="text-xl font-bold text-gray-800">{title}</h3>
//       </div>
//       <ul className="space-y-3">
//         {items.map((item, idx) => (
//           <li key={idx} className="flex items-center gap-3 text-gray-600">
//             <Check className="w-5 h-5 text-green-500 flex-shrink-0" />
//             {item}
//           </li>
//         ))}
//       </ul>
//     </div>
//   </div>
// );

// // Premium testimonial card
// const TestimonialCard = ({ quote, author, company, raised, image }) => (
//   <div className="relative group">
//     <div className="absolute inset-0 bg-gradient-to-r from-indigo-600/5 to-purple-600/5 rounded-2xl transition-all duration-300 group-hover:scale-105" />
//     <div className="relative p-8 rounded-2xl bg-white border border-gray-200 hover:border-indigo-200 transition-all duration-300">
//       <div className="flex items-center gap-4 mb-6">
//         <img src={image || "/api/placeholder/64/64"} alt={author} className="w-16 h-16 rounded-full" />
//         <div>
//           <h4 className="font-bold text-gray-800">{author}</h4>
//           <p className="text-gray-600">{company}</p>
//         </div>
//       </div>
//       <p className="text-gray-700 italic mb-4">"{quote}"</p>
//       <div className="flex items-center gap-2 text-indigo-600 font-semibold">
//         <Award className="w-5 h-5" />
//         {raised}
//       </div>
//     </div>
//   </div>
// );

// // Modern pricing card
// const PricingCard = ({ title, price, features, icon: Icon, highlighted = false }) => (
//   <div
//     className={`
//     relative rounded-2xl p-8
//     ${highlighted ? "bg-gradient-to-b from-indigo-600 to-purple-700 text-white" : "bg-white"}
//     transition-all duration-300 hover:scale-105
//     border-2 ${highlighted ? "border-transparent" : "border-gray-100 hover:border-indigo-200"}
//     shadow-xl hover:shadow-2xl
//   `}
//   >
//     <div className="flex items-center gap-3 mb-6">
//       <Icon className={`w-8 h-8 ${highlighted ? "text-white" : "text-indigo-600"}`} />
//       <h3 className="text-2xl font-bold">{title}</h3>
//     </div>

//     <div className="mb-8">
//       <div className="text-3xl font-bold mb-2">{price}</div>
//     </div>

//     <ul className="space-y-4">
//       {features.map((feature, idx) => (
//         <li key={idx} className="flex items-start gap-3">
//           <Check className={`w-5 h-5 flex-shrink-0 ${highlighted ? "text-white" : "text-green-500"}`} />
//           <span className={highlighted ? "text-white/90" : "text-gray-600"}>{feature}</span>
//         </li>
//       ))}
//     </ul>
//   </div>
// );

// // FAQ Accordion
// const FAQItem = ({ question, answer }) => {
//   const [isOpen, setIsOpen] = useState(false);

//   return (
//     <div className="border-b border-gray-200 last:border-0">
//       <button onClick={() => setIsOpen(!isOpen)} className="w-full py-6 flex items-center justify-between text-left">
//         <span className="text-lg font-semibold text-gray-800">{question}</span>
//         <ChevronRight
//           className={`
//           w-5 h-5 text-gray-500 transform transition-transform duration-300
//           ${isOpen ? "rotate-90" : ""}
//         `}
//         />
//       </button>
//       <div
//         className={`
//         overflow-hidden transition-all duration-300
//         ${isOpen ? "max-h-96 pb-6" : "max-h-0"}
//       `}
//       >
//         <p className="text-gray-600">{answer}</p>
//       </div>
//     </div>
//   );
// };

// export default function LandingPage() {
//   return (
//     <div className="min-h-screen bg-gray-50">
//       {/* Hero Section */}
//       <div className="relative overflow-hidden">
//         <div className="absolute inset-0 bg-gradient-to-br from-indigo-600/10 to-purple-600/10" />
//         <div className="absolute inset-0">
//           <div className="absolute inset-0 bg-[linear-gradient(40deg,#4f46e580,#8b5cf680,#4f46e580)] opacity-10" />
//         </div>

//         <header className="relative container mx-auto px-4 py-24 lg:py-32">
//           <div className="max-w-4xl mx-auto text-center">
//             <div className="inline-flex items-center gap-2 px-4 py-2 rounded-full bg-white/90 backdrop-blur-sm shadow-lg mb-8">
//               <Sparkles className="w-5 h-5 text-indigo-600" />
//               <span className="text-sm font-medium text-gray-800">Turn Weekly Updates Into Term Sheets</span>
//             </div>

//             <h1 className="text-5xl lg:text-7xl font-bold mb-8 bg-gradient-to-r from-indigo-600 to-purple-600 bg-clip-text text-transparent">
//               Stop Chasing VCs.
//               <br />
//               Let Them Chase You.
//             </h1>

//             <p className="text-xl lg:text-2xl text-gray-600 mb-12 max-w-3xl mx-auto">
//               One 2-minute weekly video + verified metrics = multiple investors reaching out to you. Stay focused on building while your
//               real progress attracts the right funding.
//             </p>

//             <div className="flex flex-col sm:flex-row gap-4 justify-center">
//               <GradientButton primary>Start Free</GradientButton>
//               <GradientButton>See How It Works</GradientButton>
//             </div>
//           </div>
//         </header>
//       </div>

//       {/* Metrics Section */}
//       <section className="container mx-auto px-4 py-16">
//         <div className="grid md:grid-cols-3 gap-8 max-w-4xl mx-auto">
//           <MetricCounter value={85} label="Average Days to Term Sheet" />
//           <MetricCounter value={240} label="Active Startups" />
//           <MetricCounter value={50} label="Partner VCs" />
//         </div>
//       </section>

//       {/* Features Grid */}
//       <section className="container mx-auto px-4 py-16">
//         <div className="text-center mb-16">
//           <h2 className="text-4xl lg:text-5xl font-bold mb-6 bg-gradient-to-r from-indigo-600 to-purple-600 bg-clip-text text-transparent">
//             Why Top Founders Choose Proov
//           </h2>
//           <p className="text-xl text-gray-600 max-w-3xl mx-auto">
//             We've reimagined fundraising to let you focus on what matters most: building your product.
//           </p>
//         </div>

//         <div className="grid md:grid-cols-2 gap-8 max-w-5xl mx-auto">
//           <FeatureCard
//             icon={Award}
//             title="One Update, Multiple Term Sheets"
//             description="Record a quick weekly video, connect your real metrics, and watch investors line up. No more pitching the same story 50 times."
//           />
//           <FeatureCard
//             icon={Target}
//             title="Raise Money Before You Need It"
//             description="Stop the last-minute fundraising scramble. Proov helps you attract investors early through consistent, verified growth."
//           />
//           <FeatureCard
//             icon={Brain}
//             title="Save Your Mental Energy"
//             description="Replace months of pitch deck revisions and endless calls with one simple weekly update. Keep your focus where it belongs."
//           />
//           <FeatureCard
//             icon={Users}
//             title="Keep Customers First"
//             description="While others waste months on pitch decks, you'll stay laser-focused on what matters: building features your users love."
//           />
//         </div>
//       </section>

//       {/* How It Works */}
//       <section className="relative overflow-hidden py-24 bg-gradient-to-br from-indigo-600/5 to-purple-600/5">
//         <div className="container mx-auto px-4">
//           <div className="text-center mb-16">
//             <h2 className="text-4xl lg:text-5xl font-bold mb-6">
//               How It Works
//               <span className="bg-gradient-to-r from-indigo-600 to-purple-600 bg-clip-text text-transparent">
//                 {" "}
//                 (It's Really This Simple)
//               </span>
//             </h2>
//           </div>

//           <div className="grid md:grid-cols-2 gap-8 max-w-5xl mx-auto">
//             <StepCard
//               number="1"
//               title="Create Your Free Account"
//               items={["No credit card needed", "No complicated setup", "60-second onboarding"]}
//             />
//             <StepCard
//               number="2"
//               title="Connect Your Growth Metrics"
//               items={["GitHub commits", "Stripe revenue", "Analytics data", "Choose what to share"]}
//             />
//             <StepCard
//               number="3"
//               title="Record Weekly 2-Min Update"
//               items={["Every Monday", "Share wins & challenges", "Highlight what's next", "Keep it real – no fancy production"]}
//             />
//             <StepCard
//               number="4"
//               title="Get Verified & Attract Investors"
//               items={[
//                 "Earn 'Proov Verified' badge",
//                 "Appear in our exclusive VC portal",
//                 "Receive inbound investor interest",
//                 "Get matched with the right investors",
//               ]}
//             />
//           </div>
//         </div>
//       </section>

//       {/* Testimonials */}
//       <section className="container mx-auto px-4 py-24">
//         <div className="text-center mb-16">
//           <h2 className="text-4xl lg:text-5xl font-bold mb-6">
//             Real Results from
//             <span className="bg-gradient-to-r from-indigo-600 to-purple-600 bg-clip-text text-transparent"> Real Founders</span>
//           </h2>
//           <p className="text-xl text-gray-600 max-w-3xl mx-auto">
//             Join hundreds of founders who've transformed their fundraising journey with Proov.
//           </p>
//         </div>

//         <div className="grid md:grid-cols-2 gap-8 max-w-5xl mx-auto">
//           <TestimonialCard
//             quote="Proov completely flipped fundraising for me. Instead of chasing VCs, I had three term sheets in my inbox after just 4 weeks of updates. The best part? I spent those weeks building, not pitching."
//             author="Sarah K."
//             company="Founder @ DataFlow"
//             raised="Raised $2.2M seed round"
//           />
//           <TestimonialCard
//             quote="As a technical founder, I dreaded the fundraising dance. Proov let me stay in builder mode while still attracting serious investors. Closed our round in half the expected time."
//             author="Marcus L."
//             company="CTO @ CloudStack"
//             raised="Raised $3.5M seed round"
//           />
//         </div>
//       </section>

//       {/* Pricing Section */}
//       <section className="relative overflow-hidden py-24 bg-gradient-to-br from-indigo-600/5 to-purple-600/5">
//         <div className="container mx-auto px-4">
//           <div className="text-center mb-16">
//             <h2 className="text-4xl lg:text-5xl font-bold mb-6">
//               Pricing That
//               <span className="bg-gradient-to-r from-indigo-600 to-purple-600 bg-clip-text text-transparent"> Makes Sense</span>
//             </h2>
//             <p className="text-xl text-gray-600 max-w-3xl mx-auto">Simple, transparent pricing aligned with your success.</p>
//           </div>

//           <div className="grid md:grid-cols-3 gap-8 max-w-6xl mx-auto">
//             <PricingCard
//               title="Founders"
//               price="FREE FOREVER"
//               icon={Users}
//               features={[
//                 "Unlimited weekly updates",
//                 "All core integrations",
//                 "Verification badge",
//                 "Investor portal visibility",
//                 "Basic analytics dashboard",
//               ]}
//             />
//             <PricingCard
//               title="Investors"
//               price="$500/month"
//               icon={ChartBar}
//               highlighted={true}
//               features={[
//                 "Access to verified startups",
//                 "Real-time growth metrics",
//                 "Direct founder contact",
//                 "Custom deal flow filters",
//                 "Advanced search capabilities",
//               ]}
//             />
//             <PricingCard
//               title="Enterprise"
//               price="Custom pricing"
//               icon={Globe}
//               features={["Multi-startup monitoring", "Advanced analytics", "API access", "White-label options", "Dedicated support"]}
//             />
//           </div>
//         </div>
//       </section>

//       {/* FAQ Section */}
//       <section className="container mx-auto px-4 py-24">
//         <div className="text-center mb-16">
//           <h2 className="text-4xl lg:text-5xl font-bold mb-6">
//             Frequently Asked
//             <span className="bg-gradient-to-r from-indigo-600 to-purple-600 bg-clip-text text-transparent"> Questions</span>
//           </h2>
//         </div>

//         <div className="max-w-3xl mx-auto">
//           <FAQItem
//             question="Is a 2-minute update really enough?"
//             answer="Yes! When backed by verified metrics, a concise update is actually more effective. Investors see your real progress, not a polished pitch."
//           />
//           <FAQItem
//             question="What if we have a slow week?"
//             answer="Transparency builds trust. Show how you handle challenges – investors value honest founders who can navigate both ups and downs."
//           />
//           <FAQItem
//             question="We're pre-revenue. Will this work for us?"
//             answer="Absolutely. Connect GitHub to show development velocity, track user signups, or showcase engagement metrics. As you grow, your metrics evolve too."
//           />
//           <FAQItem
//             question="Can we control what data we share?"
//             answer="100%. You choose which integrations to connect and what metrics to display. We only verify what you explicitly share."
//           />
//         </div>
//       </section>

//       {/* Final CTA */}
//       <section className="relative overflow-hidden py-24 bg-gradient-to-br from-indigo-600 to-purple-600 text-white">
//         <div className="absolute inset-0">
//           <div className="absolute inset-0 bg-[linear-gradient(40deg,#4f46e580,#8b5cf680,#4f46e580)] opacity-30" />
//         </div>

//         <div className="relative container mx-auto px-4 text-center">
//           <h2 className="text-4xl lg:text-5xl font-bold mb-8">Stop Pitching. Start Building.</h2>
//           <p className="text-xl text-white/90 mb-12 max-w-3xl mx-auto">
//             You're already doing the hard work – building something people want. Let Proov turn that work into funding opportunities,
//             automatically.
//           </p>

//           <div className="inline-flex flex-col sm:flex-row gap-4 justify-center">
//             <button className="px-8 py-4 rounded-xl font-bold text-lg bg-white text-indigo-600 hover:bg-opacity-90 transition-all duration-300 transform hover:scale-105">
//               Create Your Free Account
//               <ArrowRight className="inline-block ml-2 w-5 h-5" />
//             </button>
//           </div>

//           <div className="mt-12 flex flex-wrap justify-center gap-8">
//             <div className="flex items-center gap-2">
//               <Check className="w-5 h-5" />
//               <span>One weekly update</span>
//             </div>
//             <div className="flex items-center gap-2">
//               <Check className="w-5 h-5" />
//               <span>Verified metrics</span>
//             </div>
//             <div className="flex items-center gap-2">
//               <Check className="w-5 h-5" />
//               <span>Multiple investor eyes</span>
//             </div>
//             <div className="flex items-center gap-2">
//               <Check className="w-5 h-5" />
//               <span>Earlier funding</span>
//             </div>
//           </div>
//         </div>
//       </section>

//       {/* Footer */}
//       <footer className="bg-gray-900 text-white py-16">
//         <div className="container mx-auto px-4">
//           <div className="text-center">
//             <div className="text-2xl font-bold mb-4">Proov</div>
//             <p className="text-gray-400">Where Weekly Progress Becomes Early Funding</p>
//           </div>
//         </div>
//       </footer>
//     </div>
//   );
// }

// import React, { useState } from "react";
// import { ChevronRight, Github, BarChart, Shield, Clock, CheckCircle, Zap, ArrowRight } from "lucide-react";

// const ComingSoon = () => {
//   const [email, setEmail] = useState("");
//   const [role, setRole] = useState("founder");

//   return (
//     <div className="min-h-screen bg-gradient-to-br from-violet-600 via-indigo-700 to-purple-800 text-white">
//       {/* Grid Background */}
//       <div className="absolute inset-0 bg-[linear-gradient(to_right,#4f4f4f2e_1px,transparent_1px),linear-gradient(to_bottom,#4f4f4f2e_1px,transparent_1px)] bg-[size:14px_24px]"></div>

//       <div className="relative max-w-6xl mx-auto px-4 pt-16 pb-32">
//         {/* Navigation */}
//         <nav className="flex items-center justify-between mb-20">
//           <h1 className="text-4xl font-bold bg-clip-text text-transparent bg-gradient-to-r from-white to-white/80">PROOV</h1>
//           <div className="flex items-center gap-3">
//             <div className="w-2 h-2 bg-green-400 rounded-full animate-pulse"></div>
//             <span className="text-white/80">Now Selecting Founding Batch</span>
//           </div>
//         </nav>

//         {/* Hero Section */}
//         <div className="space-y-8 text-center mb-20">
//           <h2 className="text-7xl font-bold text-white leading-tight max-w-4xl mx-auto">Proof-of-Work for Startups</h2>
//           <p className="text-3xl text-white/80">The End of Fake Metrics</p>
//           <div className="flex justify-center gap-2 text-white/60">
//             <Shield className="w-5 h-5" />
//             <span>Every claim verified. Every metric validated.</span>
//           </div>
//         </div>

//         {/* Feature Cards */}
//         <div className="grid md:grid-cols-3 gap-8 max-w-4xl mx-auto mb-24">
//           <div className="bg-white/10 backdrop-blur-xl rounded-xl p-8 border border-white/20 transform hover:scale-105 transition-all">
//             <div className="flex items-center gap-3 mb-4">
//               <Clock className="w-6 h-6" />
//               <span className="font-medium">Every Monday</span>
//             </div>
//             <p className="text-white/80">Founders drop 2-min video update + Auto-verified metrics</p>
//           </div>

//           <div className="bg-white/10 backdrop-blur-xl rounded-xl p-8 border border-white/20 transform hover:scale-105 transition-all">
//             <div className="flex items-center gap-3 mb-4">
//               <BarChart className="w-6 h-6" />
//               <span className="font-medium">Every Tuesday</span>
//             </div>
//             <p className="text-white/80">VCs see only verified growth + Real traction data</p>
//           </div>

//           <div className="bg-white/10 backdrop-blur-xl rounded-xl p-8 border border-white/20 transform hover:scale-105 transition-all">
//             <div className="flex items-center gap-3 mb-4">
//               <Zap className="w-6 h-6" />
//               <span className="font-medium">Every Week</span>
//             </div>
//             <p className="text-white/80">Real deals happen based on verified metrics</p>
//           </div>
//         </div>

//         {/* Early Access Section */}
//         <div className="max-w-xl mx-auto text-center space-y-8">
//           <div className="space-y-3">
//             <h3 className="text-2xl font-semibold">Join the Revolution</h3>
//             <p className="text-white/80">Limited spots for founding members</p>
//           </div>

//           <div className="space-y-4">
//             <input
//               type="email"
//               placeholder="Enter your email"
//               className="w-full px-4 py-3 rounded-xl bg-white/10 border border-white/20 focus:border-white/40 outline-none transition-all placeholder:text-white/60"
//               value={email}
//               onChange={(e) => setEmail(e.target.value)}
//             />

//             <div className="flex gap-3">
//               <button
//                 className={`flex-1 px-4 py-3 rounded-xl transition-all font-medium ${
//                   role === "founder" ? "bg-white text-violet-700" : "bg-white/10 hover:bg-white/20"
//                 }`}
//                 onClick={() => setRole("founder")}
//               >
//                 Founder Access
//               </button>
//               <button
//                 className={`flex-1 px-4 py-3 rounded-xl transition-all font-medium ${
//                   role === "vc" ? "bg-white text-violet-700" : "bg-white/10 hover:bg-white/20"
//                 }`}
//                 onClick={() => setRole("vc")}
//               >
//                 VC Access
//               </button>
//             </div>

//             <button className="w-full px-4 py-3 rounded-xl bg-white hover:bg-white/90 text-violet-700 transition-all flex items-center justify-center gap-2 font-medium group">
//               Request Early Access
//               <ArrowRight className="w-4 h-4 group-hover:translate-x-1 transition-transform" />
//             </button>

//             <div className="pt-2">
//               <p className="text-white/60 text-sm">
//                 {role === "founder" ? "First 50 startups only. 12 spots left." : "Limited to 10 funds. 3 spots left."}
//               </p>
//             </div>
//           </div>
//         </div>

//         {/* Bottom Stats */}
//         <div className="mt-24 border-t border-white/10 pt-8">
//           <div className="flex justify-center gap-8 text-white/60 text-sm">
//             <div className="flex items-center gap-2">
//               <Github className="w-4 h-4" />
//               <span>Verified Commits</span>
//             </div>
//             <div className="flex items-center gap-2">
//               <Shield className="w-4 h-4" />
//               <span>Real Metrics</span>
//             </div>
//             <div className="flex items-center gap-2">
//               <CheckCircle className="w-4 h-4" />
//               <span>Weekly Growth</span>
//             </div>
//           </div>

//           <div className="text-center mt-6 text-white/60 text-sm">Changing how startups raise capital. Forever.</div>
//         </div>
//       </div>
//     </div>
//   );
// };

// export default ComingSoon;

// import React, { useState } from "react";
// import { Shield, ArrowRight, CheckCircle2, Lock } from "lucide-react";

// const ComingSoon = () => {
//   const [email, setEmail] = useState("");
//   const [role, setRole] = useState("founder");

//   return (
//     <div className="min-h-screen bg-white">
//       {/* Clean, minimal header */}
//       <div className="max-w-6xl mx-auto px-4 pt-8">
//         <nav className="flex items-center justify-between py-6">
//           <div className="flex items-center gap-2">
//             <Shield className="w-6 h-6 text-blue-600" />
//             <span className="text-2xl font-bold text-gray-900">PROOV</span>
//           </div>
//           <div className="flex items-center gap-2">
//             <span className="w-2 h-2 bg-green-500 rounded-full animate-pulse" />
//             <span className="text-gray-500 text-sm">Now Selecting Batch #1</span>
//           </div>
//         </nav>

//         {/* Hero - Clean, Bold, Clear */}
//         <div className="py-24 text-center max-w-4xl mx-auto">
//           <div className="mb-8 inline-flex items-center gap-2 bg-blue-50 text-blue-600 px-4 py-2 rounded-full">
//             <Lock className="w-4 h-4" />
//             <span className="text-sm font-medium">The Verification Layer</span>
//           </div>

//           <h1 className="text-6xl font-bold text-gray-900 mb-8 leading-tight">Proof-of-Work for Startups</h1>

//           <p className="text-2xl text-gray-600 mb-12">
//             Every claim verified. Every metric validated.
//             <br />
//             One update, all VCs.
//           </p>

//           {/* Key Benefits - Clean, Three Column */}
//           <div className="grid md:grid-cols-3 gap-8 mb-16">
//             {[
//               { title: "Drop Video", desc: "2-min weekly update" },
//               { title: "Auto-Verify", desc: "Instant validation" },
//               { title: "Raise Faster", desc: "Real metrics win" },
//             ].map((item, i) => (
//               <div key={i} className="text-center">
//                 <div className="text-lg font-semibold text-gray-900">{item.title}</div>
//                 <div className="text-gray-500">{item.desc}</div>
//               </div>
//             ))}
//           </div>

//           {/* Core CTA Section */}
//           <div className="max-w-md mx-auto bg-gray-50 p-8 rounded-2xl">
//             <div className="text-center mb-6">
//               <h3 className="text-xl font-semibold text-gray-900">Join the First Batch</h3>
//               <p className="text-gray-500">Limited to 50 startups · 10 VCs</p>
//             </div>

//             <div className="space-y-4">
//               <input
//                 type="email"
//                 placeholder="Enter your email"
//                 className="w-full px-4 py-3 rounded-xl border border-gray-200 focus:border-blue-500 outline-none"
//                 value={email}
//                 onChange={(e) => setEmail(e.target.value)}
//               />

//               <div className="flex gap-2">
//                 <button
//                   className={`flex-1 px-4 py-3 rounded-xl transition-colors ${
//                     role === "founder" ? "bg-blue-600 text-white" : "bg-gray-100 text-gray-600 hover:bg-gray-200"
//                   }`}
//                   onClick={() => setRole("founder")}
//                 >
//                   Founder
//                 </button>
//                 <button
//                   className={`flex-1 px-4 py-3 rounded-xl transition-colors ${
//                     role === "vc" ? "bg-blue-600 text-white" : "bg-gray-100 text-gray-600 hover:bg-gray-200"
//                   }`}
//                   onClick={() => setRole("vc")}
//                 >
//                   VC
//                 </button>
//               </div>

//               <button className="w-full bg-blue-600 text-white px-6 py-3 rounded-xl hover:bg-blue-700 transition-colors flex items-center justify-center gap-2 group">
//                 Request Early Access
//                 <ArrowRight className="w-4 h-4 group-hover:translate-x-1 transition-transform" />
//               </button>

//               <p className="text-sm text-gray-500 text-center">
//                 {role === "founder" ? "First 50 startups only · 12 spots left" : "Limited to 10 funds · 3 spots remaining"}
//               </p>
//             </div>
//           </div>
//         </div>

//         {/* Trust Footer */}
//         <div className="border-t border-gray-100 py-8">
//           <div className="flex justify-center gap-8">
//             {["GitHub Verified", "Real Metrics", "Weekly Updates"].map((item, i) => (
//               <div key={i} className="flex items-center gap-2 text-gray-500">
//                 <CheckCircle2 className="w-4 h-4 text-blue-600" />
//                 <span>{item}</span>
//               </div>
//             ))}
//           </div>
//         </div>
//       </div>
//     </div>
//   );
// };

// export default ComingSoon;

// import React, { useState } from "react";
// import { ArrowRight, Shield } from "lucide-react";

// const ComingSoon = () => {
//   const [email, setEmail] = useState("");
//   const [role, setRole] = useState("");

//   return (
//     <div className="min-h-screen bg-black text-white selection:bg-blue-500/30">
//       {/* Subtle gradient background */}
//       <div className="absolute inset-0 bg-[radial-gradient(ellipse_at_top_right,#1E40AF20,transparent_50%)]" />

//       <div className="relative max-w-[1200px] mx-auto px-6 pt-24 pb-20">
//         {/* Minimal Header */}
//         <header className="flex items-center justify-between mb-32">
//           <div className="flex items-center gap-2">
//             <Shield className="w-6 h-6 text-blue-500" />
//             <span className="text-2xl font-bold">PROOV</span>
//           </div>
//           <div className="flex items-center gap-2">
//             <span className="h-2 w-2 rounded-full bg-blue-500 animate-pulse" />
//             <span className="text-sm text-blue-500">Early Access Open</span>
//           </div>
//         </header>

//         {/* Hero - Stark, Clear, Revolutionary */}
//         <div className="max-w-4xl mx-auto mb-32">
//           <h1 className="text-7xl font-bold leading-tight mb-8">
//             Verification
//             <br />
//             is the New Pitch
//           </h1>

//           <div className="space-y-6 text-2xl text-gray-400 max-w-2xl">
//             <p>Founders waste months pitching.</p>
//             <p>VCs waste millions on fake metrics.</p>
//             <p>Both sides need truth.</p>
//           </div>
//         </div>

//         {/* Three Core Points - Simple, Clear */}
//         <div className="grid md:grid-cols-4 gap-12 mb-32 max-w-4xl mx-auto">
//           {[
//             "Connect your metrics (Github, Google Analytics, Stripe, Quickbooks)",
//             "AI monitors every metric,and auto detects any significant progress(+12% github commit this week) auto creates your weekly progress and posts on your timeline",
//             "Every significant progress is broadcasted to relevant investors",
//             "Prove your traction. Get Funded.",
//             // "VCs see only truth",
//           ].map((text, i) => (
//             /* {["Drop 2-min weekly video", "AI verifies every metric", "VCs see only truth"].map((text, i) => ( */
//             <div key={i} className="border-t border-blue-500/20 pt-4">
//               <p className="text-xl">{text}</p>
//             </div>
//           ))}
//         </div>

//         {/* CTA - Clean, Premium */}
//         <div className="max-w-md mx-auto">
//           <div className="text-center mb-12">
//             <div className="inline-flex items-center gap-2 bg-blue-500/10 border border-blue-500/20 rounded-full px-4 py-2 text-sm text-blue-400 mb-4">
//               <span>Limited Early Access</span>
//             </div>
//             <p className="text-gray-400">First 50 founders. First 10 VCs.</p>
//           </div>

//           <div className="space-y-4">
//             <input
//               type="email"
//               placeholder="Your email"
//               className="w-full px-4 py-3 bg-white/5 border border-white/10 rounded-lg focus:border-blue-500 outline-none transition-all text-white placeholder:text-white/40"
//               value={email}
//               onChange={(e) => setEmail(e.target.value)}
//             />

//             <div className="flex gap-3">
//               <button
//                 onClick={() => setRole("founder")}
//                 className={`flex-1 px-4 py-3 rounded-lg transition-all ${
//                   role === "founder" ? "bg-blue-500 text-white" : "bg-white/5 hover:bg-white/10 text-white/80"
//                 }`}
//               >
//                 Founder
//               </button>
//               <button
//                 onClick={() => setRole("vc")}
//                 className={`flex-1 px-4 py-3 rounded-lg transition-all ${
//                   role === "vc" ? "bg-blue-500 text-white" : "bg-white/5 hover:bg-white/10 text-white/80"
//                 }`}
//               >
//                 VC
//               </button>
//             </div>

//             <button className="w-full bg-blue-500 hover:bg-blue-600 text-white px-4 py-3 rounded-lg flex items-center justify-center gap-2 group">
//               Request Early Access
//               <ArrowRight className="w-4 h-4 group-hover:translate-x-1 transition-transform" />
//             </button>
//           </div>
//         </div>

//         {/* Minimal Footer */}
//         <div className="mt-32 text-center">
//           <p className="text-gray-500 text-sm">Changing how startups raise capital. Forever.</p>
//         </div>
//       </div>
//     </div>
//   );
// };

// export default ComingSoon;

// import React from "react";
// import { ArrowRight, Shield, Mail } from "lucide-react";

// const ComingSoon = () => {
//   return (
//     <div className="min-h-screen bg-black text-white selection:bg-blue-500/30">
//       {/* Announcement Banner */}
//       <div className="bg-blue-500/10 border-b border-blue-500/20">
//         <div className="max-w-[1200px] mx-auto px-6 py-3 text-center text-sm">
//           <span className="text-blue-400">Skip the pitch deck. Prove your traction with verified metrics. Early access starts Jan 24.</span>
//         </div>
//       </div>

//       <div className="absolute inset-0 bg-[radial-gradient(ellipse_at_top_right,#1E40AF20,transparent_50%)]" />

//       <div className="relative max-w-[1200px] mx-auto px-6 pt-24 pb-20">
//         <header className="flex items-center justify-between mb-32">
//           <div className="flex items-center gap-2">
//             <Shield className="w-6 h-6 text-blue-500" />
//             <span className="text-2xl font-bold">PROOV</span>
//           </div>
//           <div className="flex items-center gap-2">
//             <span className="h-2 w-2 rounded-full bg-blue-500 animate-pulse" />
//             <span className="text-sm text-blue-500">Limited spots available</span>
//           </div>
//         </header>

//         <div className="max-w-4xl mx-auto mb-32">
//           <h1 className="text-7xl font-bold leading-tight mb-8">
//             Verification
//             <br />
//             is the New Pitch
//           </h1>

//           <div className="space-y-6 text-2xl text-gray-400 max-w-2xl">
//             <p>Founders: Stop wasting time on decks.</p>
//             <p>Investors: Stop relying on outdated metrics.</p>
//             <p>PROOV: Verify traction. Get funded.</p>
//           </div>
//         </div>

//         <div className="grid md:grid-cols-4 gap-12 mb-32 max-w-4xl mx-auto">
//           {[
//             "Connect your key metrics (Stripe, Github, Analytics)",
//             "AI monitors growth and auto-detects progress",
//             "Verified updates are shared with relevant investors",
//             "Prove your traction. Secure your funding.",
//           ].map((text, i) => (
//             <div key={i} className="border-t border-blue-500/20 pt-4">
//               <p className="text-xl">{text}</p>
//             </div>
//           ))}
//         </div>

//         <div className="max-w-md mx-auto">
//           <div className="text-center mb-12">
//             <div className="inline-flex items-center gap-2 bg-blue-500/10 border border-blue-500/20 rounded-full px-4 py-2 text-sm text-blue-400 mb-4">
//               <span>Early Access Starts Jan 24</span>
//             </div>
//             <p className="text-gray-400">Don't miss out. Secure your spot.</p>
//           </div>

//           <div className="space-y-6 text-center">
//             <a
//               href="mailto:business@feedbackbyai.com"
//               className="inline-flex items-center gap-3 bg-blue-500 hover:bg-blue-600 text-white px-6 py-4 rounded-lg group transition-colors"
//             >
//               <Mail className="w-5 h-5" />
//               <span>business@feedbackbyai.com</span>
//               <ArrowRight className="w-5 h-5 group-hover:translate-x-1 transition-transform" />
//             </a>
//             <p className="text-gray-400">From the team behind FeedbackbyAI (10k+ users)</p>
//           </div>
//         </div>

//         <div className="mt-32 text-center">
//           <p className="text-gray-500 text-sm">The future of fundraising is verified.</p>
//         </div>
//       </div>
//     </div>
//   );
// };

// export default ComingSoon;

// import React from "react";
// import { ArrowRight, Shield, Mail } from "lucide-react";

// const ComingSoon = () => {
//   return (
//     <div className="min-h-screen bg-black text-white selection:bg-blue-500/30">
//       {/* Announcement Banner */}
//       <div className="bg-blue-500/10 border-b border-blue-500/20">
//         <div className="max-w-[1200px] mx-auto px-6 py-3 text-center text-sm">
//           <span className="text-blue-400">
//             From the founder of FeedbackbyAI (10k+ users). Verified metrics are replacing pitch decks. Early access Jan 24.
//           </span>
//         </div>
//       </div>

//       <div className="absolute inset-0 bg-[radial-gradient(ellipse_at_top_right,#1E40AF20,transparent_50%)]" />

//       <div className="relative max-w-[1200px] mx-auto px-6 pt-24 pb-20">
//         <header className="flex items-center justify-between mb-32">
//           <div className="flex items-center gap-2">
//             <Shield className="w-6 h-6 text-blue-500" />
//             <span className="text-2xl font-bold">PROOV</span>
//           </div>
//           <div className="flex items-center gap-2">
//             <span className="h-2 w-2 rounded-full bg-blue-500 animate-pulse" />
//             <span className="text-sm text-blue-500">First 50 founders. First 10 VCs.</span>
//           </div>
//         </header>

//         <div className="max-w-4xl mx-auto mb-32">
//           <h1 className="text-7xl font-bold leading-tight mb-8">
//             Prove Growth.
//             <br />
//             Skip Pitching.
//           </h1>

//           <div className="space-y-6 text-2xl text-gray-400 max-w-2xl">
//             <p>Founders: Stop wasting months on decks.</p>
//             <p>VCs: Stop gambling on vanity metrics.</p>
//             <p>Let verified data close your round.</p>
//           </div>
//         </div>

//         <div className="grid md:grid-cols-3 gap-12 mb-32 max-w-4xl mx-auto">
//           {[
//             "Auto-verify your metrics (Stripe, GitHub, Analytics) weekly",
//             "AI detects significant growth & alerts relevant VCs",
//             "Get funded based on real traction, not storytelling",
//           ].map((text, i) => (
//             <div key={i} className="border-t border-blue-500/20 pt-4">
//               <p className="text-xl">{text}</p>
//             </div>
//           ))}
//         </div>

//         <div className="max-w-2xl mx-auto">
//           <div className="text-center mb-12 space-y-8">
//             <div className="space-y-4">
//               <div className="inline-flex items-center gap-2 bg-blue-500/10 border border-blue-500/20 rounded-full px-4 py-2 text-sm text-blue-400">
//                 <span>Early Access Instructions</span>
//               </div>

//               <div className="bg-blue-500/5 border border-blue-500/10 rounded-lg p-6 space-y-6">
//                 <div>
//                   <h3 className="text-lg font-semibold mb-2">For Founders:</h3>
//                   <p className="text-gray-400">Email us with subject "PROOV Founder" and include:</p>
//                   <ul className="text-gray-400 list-disc list-inside mt-2">
//                     <li>Current MRR/key metrics</li>
//                     <li>Growth rate</li>
//                     <li>Fundraising timeline</li>
//                   </ul>
//                 </div>

//                 <div>
//                   <h3 className="text-lg font-semibold mb-2">For Investors:</h3>
//                   <p className="text-gray-400">Email us with subject "PROOV Investor" and include:</p>
//                   <ul className="text-gray-400 list-disc list-inside mt-2">
//                     <li>Fund/angel details</li>
//                     <li>Check size range</li>
//                     <li>Investment focus</li>
//                   </ul>
//                 </div>
//               </div>
//             </div>

//             <a
//               href="mailto:business@feedbackbyai.com"
//               className="inline-flex items-center gap-3 bg-blue-500 hover:bg-blue-600 text-white px-6 py-4 rounded-lg group transition-colors"
//             >
//               <Mail className="w-5 h-5" />
//               <span>business@feedbackbyai.com</span>
//               <ArrowRight className="w-5 h-5 group-hover:translate-x-1 transition-transform" />
//             </a>

//             <div className="space-y-2">
//               <p className="text-blue-400 font-medium">What happens next?</p>
//               <p className="text-gray-400">We'll review your application within 24 hours and send access details if selected.</p>
//               <p className="text-gray-400">First 50 founders and 10 VCs only.</p>
//             </div>
//           </div>
//         </div>

//         <div className="mt-32 text-center">
//           <p className="text-gray-500 text-sm">Stop pitching. Start proving.</p>
//         </div>
//       </div>
//     </div>
//   );
// };

// export default ComingSoon;

// import React from "react";
// import { ArrowRight, Shield, Mail } from "lucide-react";

// const ComingSoon = () => {
//   return (
//     <div className="min-h-screen bg-black text-white selection:bg-blue-500/30">
//       <div className="bg-blue-500/10 border-b border-blue-500/20">
//         <div className="max-w-[1200px] mx-auto px-6 py-3 text-center text-sm">
//           <span className="text-blue-400">
//             Tired of outdated fundraising? PROOV verifies your traction, so you can skip the pitch and get funded faster. Early access
//             starts Jan 24.
//           </span>
//         </div>
//       </div>

//       <div className="absolute inset-0 bg-[radial-gradient(ellipse_at_top_right,#1E40AF20,transparent_50%)]" />

//       <div className="relative max-w-[1200px] mx-auto px-6 pt-24 pb-20">
//         <header className="flex items-center justify-between mb-32">
//           <div className="flex items-center gap-2">
//             <Shield className="w-6 h-6 text-blue-500" />
//             <span className="text-2xl font-bold">PROOV</span>
//           </div>
//           <div className="flex items-center gap-2">
//             <span className="h-2 w-2 rounded-full bg-blue-500 animate-pulse" />
//             <span className="text-sm text-blue-500">Limited spots available</span>
//           </div>
//         </header>

//         <div className="max-w-4xl mx-auto mb-32">
//           <h1 className="text-7xl font-bold leading-tight mb-8">
//             Verification
//             <br />
//             is the New Pitch
//           </h1>

//           <div className="space-y-6 text-2xl text-gray-400 max-w-2xl">
//             <p>Founders: Stop wasting time on decks. Prove traction. Get funded.</p>
//             <p>Investors: Access verified metrics. Find rockets. Invest with confidence.</p>
//           </div>
//         </div>

//         <div className="grid md:grid-cols-3 gap-12 mb-32 max-w-4xl mx-auto">
//           {[
//             "Connect your metrics (Stripe, Github, Analytics)",
//             "AI verifies growth and auto-detects progress",
//             "Get funded based on real traction, not pitch decks",
//           ].map((text, i) => (
//             <div key={i} className="border-t border-blue-500/20 pt-4">
//               <p className="text-xl">{text}</p>
//             </div>
//           ))}
//         </div>

//         <div className="max-w-md mx-auto">
//           <div className="text-center mb-12">
//             <div className="inline-flex items-center gap-2 bg-blue-500/10 border border-blue-500/20 rounded-full px-4 py-2 text-sm text-blue-400 mb-4">
//               <span>Early Access Starts Jan 24</span>
//             </div>
//             <p className="text-gray-400">Be one of the first to experience the future of fundraising.</p>
//           </div>

//           <div className="space-y-4 text-center">
//             <a
//               href="mailto:business@feedbackbyai.com?subject=Founder - Requesting Early Access to PROOV"
//               className="inline-flex items-center gap-3 bg-blue-500 hover:bg-blue-600 text-white px-6 py-4 rounded-lg group transition-colors w-full"
//             >
//               <Mail className="w-5 h-5" />
//               <span>Founders: Request Early Access</span>
//               <ArrowRight className="w-5 h-5 group-hover:translate-x-1 transition-transform" />
//             </a>
//             <a
//               href="mailto:business@feedbackbyai.com?subject=Investor - Requesting Early Access to PROOV"
//               className="inline-flex items-center gap-3 bg-green-500 hover:bg-green-600 text-white px-6 py-4 rounded-lg group transition-colors w-full"
//             >
//               <Mail className="w-5 h-5" />
//               <span>Investors: Request Early Access</span>
//               <ArrowRight className="w-5 h-5 group-hover:translate-x-1 transition-transform" />
//             </a>
//             <p className="text-gray-400">From the team behind FeedbackbyAI (10k+ users)</p>
//           </div>
//         </div>

//         <div className="mt-32 text-center">
//           <p className="text-gray-500 text-sm">The future of fundraising is verified.</p>
//         </div>
//       </div>
//     </div>
//   );
// };

// export default ComingSoon;

// import React from "react";
// import { ArrowRight, Shield, Mail, Rocket, Building } from "lucide-react";

// const ComingSoon = () => {
//   const founderMailto = `mailto:business@feedbackbyai.com?subject=PROOV Founder Access&body=Current MRR:%0D%0AGrowth Rate:%0D%0AFundraising Timeline:`;

//   const investorMailto = `mailto:business@feedbackbyai.com?subject=PROOV Investor Access&body=Investment Focus:%0D%0ACheck Size:%0D%0APortfolio Type:`;

//   return (
//     <div className="min-h-screen bg-black text-white selection:bg-blue-500/30">
//       <div className="bg-blue-500/10 border-b border-blue-500/20">
//         <div className="max-w-[1200px] mx-auto px-6 py-3 text-center text-sm">
//           <span className="text-blue-400">From the founder of FeedbackbyAI (10k+ users) → Changing fundraising forever.</span>
//         </div>
//       </div>

//       <div className="absolute inset-0 bg-[radial-gradient(ellipse_at_top_right,#1E40AF20,transparent_50%)]" />

//       <div className="relative max-w-[1200px] mx-auto px-6 pt-24 pb-20">
//         <header className="flex items-center justify-between mb-32">
//           <div className="flex items-center gap-2">
//             <Shield className="w-6 h-6 text-blue-500" />
//             <span className="text-2xl font-bold">PROOV</span>
//           </div>
//           <div className="flex items-center gap-2">
//             <span className="h-2 w-2 rounded-full bg-blue-500 animate-pulse" />
//             <span className="text-sm text-blue-500">Launching Jan 24</span>
//           </div>
//         </header>

//         <div className="max-w-4xl mx-auto mb-20">
//           <h1 className="text-7xl font-bold leading-tight mb-12">
//             Verification
//             <br />
//             is the New Pitch
//           </h1>

//           <div className="bg-blue-500/5 border border-blue-500/10 rounded-lg p-8 space-y-6">
//             <div className="space-y-4">
//               <h2 className="text-2xl font-semibold text-blue-400">The Problem:</h2>
//               <div className="grid md:grid-cols-2 gap-6">
//                 <p className="text-xl text-gray-400">Founders waste months on pitch decks instead of building</p>
//                 <p className="text-xl text-gray-400">VCs waste millions on fake metrics and endless meetings</p>
//               </div>
//             </div>

//             <div className="space-y-4">
//               <h2 className="text-2xl font-semibold text-blue-400">Our Way:</h2>
//               <div className="grid md:grid-cols-2 gap-6">
//                 <p className="text-xl text-gray-400">Auto-verify your metrics (Stripe, GitHub, Analytics)</p>
//                 <p className="text-xl text-gray-400">AI broadcasts growth to relevant investors instantly</p>
//               </div>
//             </div>
//           </div>
//         </div>

//         <div className="max-w-4xl mx-auto mb-20">
//           <div className="grid md:grid-cols-2 gap-8">
//             <a
//               href={founderMailto}
//               className="group p-8 bg-blue-500/5 hover:bg-blue-500/10 border border-blue-500/20 rounded-lg transition-all"
//             >
//               <Rocket className="w-10 h-10 text-blue-500 mb-4" />
//               <h3 className="text-2xl font-semibold mb-4">Founders</h3>
//               <p className="text-gray-400 mb-6">Stop pitching. Let your verified metrics speak.</p>
//               <div className="flex items-center text-blue-400 group-hover:text-blue-300">
//                 Get Early Access <ArrowRight className="w-5 h-5 ml-2 group-hover:translate-x-1 transition-transform" />
//               </div>
//             </a>

//             <a
//               href={investorMailto}
//               className="group p-8 bg-blue-500/5 hover:bg-blue-500/10 border border-blue-500/20 rounded-lg transition-all"
//             >
//               <Building className="w-10 h-10 text-blue-500 mb-4" />
//               <h3 className="text-2xl font-semibold mb-4">Investors</h3>
//               <p className="text-gray-400 mb-6">Stop gambling. Get verified growth alerts.</p>
//               <div className="flex items-center text-blue-400 group-hover:text-blue-300">
//                 Request Access <ArrowRight className="w-5 h-5 ml-2 group-hover:translate-x-1 transition-transform" />
//               </div>
//             </a>
//           </div>
//         </div>

//         <div className="max-w-2xl mx-auto text-center mb-20">
//           <h2 className="text-2xl font-semibold mb-8">Who's Getting Early Access?</h2>
//           <div className="grid md:grid-cols-2 gap-8">
//             <div className="p-6 bg-blue-500/5 border border-blue-500/10 rounded-lg">
//               <p className="text-xl mb-2">50 Founders</p>
//               <p className="text-gray-400">With proven MRR growth</p>
//             </div>
//             <div className="p-6 bg-blue-500/5 border border-blue-500/10 rounded-lg">
//               <p className="text-xl mb-2">10 VCs</p>
//               <p className="text-gray-400">Actively investing in SaaS</p>
//             </div>
//           </div>
//         </div>

//         <div className="mt-32 text-center">
//           <p className="text-blue-400">By the team behind FeedbackbyAI (10k+ users)</p>
//         </div>
//       </div>
//     </div>
//   );
// };

// export default ComingSoon;

// import React from "react";
// import { ArrowRight, Shield, Mail, Rocket, Building } from "lucide-react";

// const ComingSoon = () => {
//   const founderMailto = `mailto:business@feedbackbyai.com?subject=PROOV Founder Access&body=Current MRR:%0D%0AGrowth Rate:%0D%0AFundraising Timeline:`;

//   const investorMailto = `mailto:business@feedbackbyai.com?subject=PROOV Investor Access&body=Fund Details:%0D%0ACheck Size:%0D%0AInvestment Focus:`;

//   return (
//     <div className="min-h-screen bg-black text-white selection:bg-blue-500/30">
//       <div className="bg-blue-500/10 border-b border-blue-500/20">
//         <div className="max-w-[1200px] mx-auto px-6 py-3 text-center text-sm">
//           <span className="text-blue-400">From the founder of FeedbackbyAI (10k+ users) → Verified metrics are replacing pitch decks.</span>
//         </div>
//       </div>

//       <div className="absolute inset-0 bg-[radial-gradient(ellipse_at_top_right,#1E40AF20,transparent_50%)]" />

//       <div className="relative max-w-[1200px] mx-auto px-6 pt-24 pb-20">
//         <header className="flex items-center justify-between mb-24">
//           <div className="flex items-center gap-2">
//             <Shield className="w-6 h-6 text-blue-500" />
//             <span className="text-2xl font-bold">PROOV</span>
//           </div>
//           <div className="flex items-center gap-2">
//             <span className="h-2 w-2 rounded-full bg-blue-500 animate-pulse" />
//             <span className="text-sm text-blue-500">First 50 founders. First 10 VCs.</span>
//           </div>
//         </header>

//         <div className="max-w-4xl mx-auto mb-20">
//           <h1 className="text-7xl font-bold leading-tight mb-8">
//             Verification
//             <br />
//             is the New Pitch
//           </h1>

//           <div className="space-y-6 text-2xl text-gray-400 max-w-2xl">
//             <p>VCs spend millions on fake metrics.</p>
//             <p>Founders waste months on endless meetings and pitch decks.</p>
//             <p>Let's fix fundraising forever.</p>
//           </div>
//         </div>

//         <div className="max-w-4xl mx-auto mb-24">
//           <div className="grid md:grid-cols-3 gap-8">
//             {["Connect Stripe & GitHub. Share truth.", "AI verifies growth weekly.", "VCs request access to you."].map((text, i) => (
//               <div key={i} className="border-t border-blue-500/20 pt-4">
//                 <p className="text-xl text-gray-300">{text}</p>
//               </div>
//             ))}
//           </div>
//         </div>

//         <div className="max-w-4xl mx-auto mb-20">
//           <div className="grid md:grid-cols-2 gap-8">
//             <a
//               href={founderMailto}
//               className="group p-8 bg-blue-500/5 hover:bg-blue-500/10 border border-blue-500/20 rounded-lg transition-all"
//             >
//               <Rocket className="w-10 h-10 text-blue-500 mb-4" />
//               <h3 className="text-2xl font-semibold mb-4">For Founders</h3>
//               <p className="text-gray-400 mb-6">Click to request access. Share metrics. Get funded.</p>
//               <div className="flex items-center text-blue-400 group-hover:text-blue-300">
//                 Apply Now <ArrowRight className="w-5 h-5 ml-2 group-hover:translate-x-1 transition-transform" />
//               </div>
//             </a>

//             <a
//               href={investorMailto}
//               className="group p-8 bg-blue-500/5 hover:bg-blue-500/10 border border-blue-500/20 rounded-lg transition-all"
//             >
//               <Building className="w-10 h-10 text-blue-500 mb-4" />
//               <h3 className="text-2xl font-semibold mb-4">For Investors</h3>
//               <p className="text-gray-400 mb-6">Click to request access. Get verified metrics weekly.</p>
//               <div className="flex items-center text-blue-400 group-hover:text-blue-300">
//                 Get Access <ArrowRight className="w-5 h-5 ml-2 group-hover:translate-x-1 transition-transform" />
//               </div>
//             </a>
//           </div>
//         </div>

//         <div className="max-w-2xl mx-auto text-center space-y-8">
//           <div>
//             <p className="text-blue-400 font-medium">What happens next?</p>
//             <p className="text-gray-400">24-hour response. Early access starts Jan 24.</p>
//           </div>

//           <div className="inline-flex items-center gap-2 bg-blue-500/10 border border-blue-500/20 rounded-full px-4 py-2">
//             <span className="text-sm text-blue-400">Built by the FeedbackbyAI team (10k+ users)</span>
//           </div>
//         </div>
//       </div>
//     </div>
//   );
// };

// export default ComingSoon;

// import React from "react";
// import { ArrowRight, Shield, Mail } from "lucide-react";

// const ComingSoon = () => {
//   return (
//     <div className="min-h-screen bg-black text-white selection:bg-blue-500/30">
//       <div className="bg-blue-500/10 border-b border-blue-500/20">
//         <div className="max-w-[1200px] mx-auto px-6 py-3 text-center text-sm">
//           <span className="text-blue-400">
//             Tired of outdated fundraising? PROOV verifies your traction, so you can skip the pitch and get funded faster. Early access
//             starts Jan 24.
//           </span>
//         </div>
//       </div>

//       <div className="absolute inset-0 bg-[radial-gradient(ellipse_at_top_right,#1E40AF20,transparent_50%)]" />

//       <div className="relative max-w-[1200px] mx-auto px-6 pt-24 pb-20">
//         <header className="flex items-center justify-between mb-32">
//           <div className="flex items-center gap-2">
//             <Shield className="w-6 h-6 text-blue-500" />
//             <span className="text-2xl font-bold">PROOV</span>
//           </div>
//           <div className="flex items-center gap-2">
//             <span className="h-2 w-2 rounded-full bg-blue-500 animate-pulse" />
//             <span className="text-sm text-blue-500">Limited spots available</span>
//           </div>
//         </header>

//         <div className="max-w-4xl mx-auto mb-32">
//           <h1 className="text-7xl font-bold leading-tight mb-8">
//             Verification
//             <br />
//             is the New Pitch
//           </h1>

//           <div className="space-y-6 text-2xl text-gray-400 max-w-2xl">
//             <p>Founders: Stop wasting time on endless meetings and decks. Prove traction. Get funded.</p>
//             <p>Investors: Access verified metrics. Find early rockets. Invest with confidence.</p>
//           </div>
//         </div>

//         <div className="grid md:grid-cols-3 gap-12 mb-32 max-w-4xl mx-auto">
//           {[
//             "Connect your metrics (Stripe, Github, Analytics)",
//             "AI verifies growth and auto-detects progress",
//             "Get funded based on real traction, not pitch decks",
//           ].map((text, i) => (
//             <div key={i} className="border-t border-blue-500/20 pt-4">
//               <p className="text-xl">{text}</p>
//             </div>
//           ))}
//         </div>

//         <div className="max-w-md mx-auto">
//           <div className="text-center mb-12">
//             <div className="inline-flex items-center gap-2 bg-blue-500/10 border border-blue-500/20 rounded-full px-4 py-2 text-sm text-blue-400 mb-4">
//               <span>Early Access Starts Jan 24</span>
//             </div>
//             <p className="text-gray-400">Be one of the first to experience the future of fundraising.</p>
//           </div>

//           <div className="space-y-4 text-center">
//             <a
//               href="mailto:business@feedbackbyai.com?subject=Founder - Requesting Early Access to PROOV"
//               className="inline-flex items-center gap-3 bg-blue-500 hover:bg-blue-600 text-white px-6 py-4 rounded-lg group transition-colors w-full"
//             >
//               <Mail className="w-5 h-5" />
//               <span>Founders: Request Early Access</span>
//               <ArrowRight className="w-5 h-5 group-hover:translate-x-1 transition-transform" />
//             </a>
//             <a
//               href="mailto:business@feedbackbyai.com?subject=Investor - Requesting Early Access to PROOV"
//               className="inline-flex items-center gap-3 bg-green-500 hover:bg-green-600 text-white px-6 py-4 rounded-lg group transition-colors w-full"
//             >
//               <Mail className="w-5 h-5" />
//               <span>Investors: Request Early Access</span>
//               <ArrowRight className="w-5 h-5 group-hover:translate-x-1 transition-transform" />
//             </a>
//             <p className="text-gray-400">From the team behind FeedbackbyAI (10k+ users)</p>
//           </div>
//         </div>

//         <div className="mt-32 text-center">
//           <p className="text-gray-500 text-sm">The future of fundraising is verified.</p>
//         </div>
//       </div>
//     </div>
//   );
// };

// export default ComingSoon;

// import React from "react";
// import { ArrowRight, Shield, Mail } from "lucide-react";

// const ComingSoon = () => {
//   return (
//     <div className="min-h-screen bg-black text-white selection:bg-blue-500/30">
//       {/* Announcement Banner */}
//       <div className="bg-blue-500/10 border-b border-blue-500/20">
//         <div className="max-w-[1200px] mx-auto px-6 py-3 text-center text-sm">
//           <span className="text-blue-400">Skip the pitch deck. Prove your traction with verified metrics. Early access starts Jan 24.</span>
//         </div>
//       </div>

//       <div className="absolute inset-0 bg-[radial-gradient(ellipse_at_top_right,#1E40AF20,transparent_50%)]" />

//       <div className="relative max-w-[1200px] mx-auto px-6 pt-24 pb-20">
//         <header className="flex items-center justify-between mb-32">
//           <div className="flex items-center gap-2">
//             <Shield className="w-6 h-6 text-blue-500" />
//             <span className="text-2xl font-bold">PROOV</span>
//           </div>
//           <div className="flex items-center gap-2">
//             <span className="h-2 w-2 rounded-full bg-blue-500 animate-pulse" />
//             <span className="text-sm text-blue-500">Limited spots available</span>
//           </div>
//         </header>

//         <div className="max-w-4xl mx-auto mb-32">
//           <h1 className="text-7xl font-bold leading-tight mb-8">
//             Verification
//             <br />
//             is the New Pitch
//           </h1>

//           <div className="space-y-6 text-2xl text-gray-400 max-w-2xl">
//             <p>Founders: Stop wasting time on decks.</p>
//             <p>Investors: Stop relying on outdated metrics.</p>
//             <p>PROOV: Verify traction. Get funded.</p>
//           </div>
//         </div>

//         <div className="grid md:grid-cols-4 gap-12 mb-32 max-w-4xl mx-auto">
//           {[
//             "Connect your key metrics (Stripe, Github, Analytics)",
//             "AI monitors growth and auto-detects progress",
//             "Verified updates are shared with relevant investors",
//             "Prove your traction. Secure your funding.",
//           ].map((text, i) => (
//             <div key={i} className="border-t border-blue-500/20 pt-4">
//               <p className="text-xl">{text}</p>
//             </div>
//           ))}
//         </div>

//         <div className="max-w-md mx-auto">
//           <div className="text-center mb-12">
//             <div className="inline-flex items-center gap-2 bg-blue-500/10 border border-blue-500/20 rounded-full px-4 py-2 text-sm text-blue-400 mb-4">
//               <span>Early Access Starts Jan 24</span>
//             </div>
//             <p className="text-gray-400">Don't miss out. Secure your spot.</p>
//           </div>

//           <div className="space-y-6 text-center">
//             <a
//               href="mailto:business@feedbackbyai.com"
//               className="inline-flex items-center gap-3 bg-blue-500 hover:bg-blue-600 text-white px-6 py-4 rounded-lg group transition-colors"
//             >
//               <Mail className="w-5 h-5" />
//               <span>business@feedbackbyai.com</span>
//               <ArrowRight className="w-5 h-5 group-hover:translate-x-1 transition-transform" />
//             </a>
//             <p className="text-gray-400">From the team behind FeedbackbyAI (10k+ users)</p>
//           </div>
//         </div>

//         <div className="mt-32 text-center">
//           <p className="text-gray-500 text-sm">The future of fundraising is verified.</p>
//         </div>
//       </div>
//     </div>
//   );
// };

// export default ComingSoon;

// import React from "react";
// import { ArrowRight, Shield, Mail } from "lucide-react";

// const ComingSoon = () => {
//   return (
//     <div className="min-h-screen bg-black text-white selection:bg-blue-500/30">
//       {/* Announcement Banner */}
//       <div className="bg-blue-500/10 border-b border-blue-500/20">
//         <div className="max-w-[1200px] mx-auto px-6 py-3 text-center text-sm">
//           <span className="text-blue-400">
//             Tired of endless fundraising meetings? PROOV verifies your traction so you can get funded faster. Early access starts Jan 24.
//           </span>
//         </div>
//       </div>

//       <div className="absolute inset-0 bg-[radial-gradient(ellipse_at_top_right,#1E40AF20,transparent_50%)]" />

//       <div className="relative max-w-[1200px] mx-auto px-6 pt-24 pb-20">
//         <header className="flex items-center justify-between mb-32">
//           <div className="flex items-center gap-2">
//             <Shield className="w-6 h-6 text-blue-500" />
//             <span className="text-2xl font-bold">PROOV</span>
//           </div>
//           <div className="flex items-center gap-2">
//             <span className="h-2 w-2 rounded-full bg-blue-500 animate-pulse" />
//             <span className="text-sm text-blue-500">Limited spots available</span>
//           </div>
//         </header>

//         <div className="max-w-4xl mx-auto mb-32">
//           <h1 className="text-7xl font-bold leading-tight mb-8">
//             Verification
//             <br />
//             is the New Pitch
//           </h1>

//           <div className="space-y-6 text-2xl text-gray-400 max-w-2xl">
//             <p>Founders: Stop wasting time on decks. Prove traction. Get funded.</p>
//             <p>Investors: Access verified metrics. Find rockets. Invest with confidence.</p>
//           </div>
//         </div>

//         <div className="grid md:grid-cols-4 gap-12 mb-32 max-w-4xl mx-auto">
//           {[
//             "Connect your key metrics (Stripe, Github, Analytics)",
//             "AI monitors growth and auto-detects progress",
//             "Verified updates are shared with relevant investors",
//             "Prove your traction. Secure your funding.",
//           ].map((text, i) => (
//             <div key={i} className="border-t border-blue-500/20 pt-4">
//               <p className="text-xl">{text}</p>
//             </div>
//           ))}
//         </div>

//         <div className="max-w-md mx-auto">
//           <div className="text-center mb-12">
//             <div className="inline-flex items-center gap-2 bg-blue-500/10 border border-blue-500/20 rounded-full px-4 py-2 text-sm text-blue-400 mb-4">
//               <span>Early Access Starts Jan 24</span>
//             </div>
//             <p className="text-gray-400">Don't miss out. Secure your spot.</p>
//           </div>

//           <div className="space-y-4 text-center">
//             <a
//               href="mailto:business@feedbackbyai.com?subject=Founder - I'm interested in PROOV!"
//               className="inline-flex items-center gap-3 bg-blue-500 hover:bg-blue-600 text-white px-6 py-4 rounded-lg group transition-colors w-full"
//             >
//               <Mail className="w-5 h-5" />
//               <span>Founders: Get Early Access</span>
//               <ArrowRight className="w-5 h-5 group-hover:translate-x-1 transition-transform" />
//             </a>
//             <a
//               href="mailto:business@feedbackbyai.com?subject=Investor - I want to know more about PROOV!"
//               className="inline-flex items-center gap-3 bg-green-500 hover:bg-green-600 text-white px-6 py-4 rounded-lg group transition-colors w-full"
//             >
//               <Mail className="w-5 h-5" />
//               <span>Investors: Learn More</span>
//               <ArrowRight className="w-5 h-5 group-hover:translate-x-1 transition-transform" />
//             </a>
//             <p className="text-gray-400">From the team behind FeedbackbyAI (10k+ users)</p>
//           </div>
//         </div>

//         <div className="mt-32 text-center">
//           <p className="text-gray-500 text-sm">The future of fundraising is verified.</p>
//         </div>
//       </div>
//     </div>
//   );
// };

// export default ComingSoon;

// import React from "react";
// import { ArrowRight, Shield, Mail, ChartBar, Rocket } from "lucide-react";

// const ComingSoon = () => {
//   const founderMailto = `mailto:business@feedbackbyai.com?subject=PROOV Founder Access&body=Current MRR:%0D%0AGrowth Rate:%0D%0AFundraising Timeline:`;

//   const investorMailto = `mailto:business@feedbackbyai.com?subject=PROOV Investor Access&body=Fund Details:%0D%0ACheck Size:%0D%0AInvestment Focus:`;

//   return (
//     <div className="min-h-screen bg-black text-white selection:bg-blue-500/30">
//       {/* Announcement Banner */}
//       <div className="bg-blue-500/10 border-b border-blue-500/20">
//         <div className="max-w-[1200px] mx-auto px-6 py-3 text-center text-sm">
//           <span className="text-blue-400">
//             From FeedbackbyAI founder (10k+ users) → Tired of endless fundraising meetings? PROOV verifies your traction so you can get
//             funded faster.
//           </span>
//         </div>
//       </div>

//       <div className="absolute inset-0 bg-[radial-gradient(ellipse_at_top_right,#1E40AF20,transparent_50%)]" />

//       <div className="relative max-w-[1200px] mx-auto px-6 pt-24 pb-20">
//         <header className="flex items-center justify-between mb-32">
//           <div className="flex items-center gap-2">
//             <Shield className="w-6 h-6 text-blue-500" />
//             <span className="text-2xl font-bold">PROOV</span>
//           </div>
//           <div className="flex items-center gap-2">
//             <span className="h-2 w-2 rounded-full bg-blue-500 animate-pulse" />
//             <span className="text-sm text-blue-500">First 50 founders. First 10 VCs.</span>
//           </div>
//         </header>

//         <div className="max-w-4xl mx-auto mb-32">
//           <h1 className="text-7xl font-bold leading-tight mb-8">
//             Verification
//             <br />
//             is the New Pitch
//           </h1>

//           <div className="space-y-6 text-2xl text-gray-400 max-w-2xl">
//             <p>Founders: Stop wasting time on decks. Prove traction. Get funded.</p>
//             <p>Investors: Access verified metrics. Find rockets. Invest with confidence.</p>
//           </div>
//         </div>

//         <div className="grid md:grid-cols-4 gap-12 mb-32 max-w-4xl mx-auto">
//           {[
//             "Connect your key metrics (Stripe, Github, Analytics)",
//             "AI monitors growth and auto-detects progress",
//             "Verified updates are shared with relevant investors",
//             "Prove your traction. Secure your funding.",
//           ].map((text, i) => (
//             <div key={i} className="border-t border-blue-500/20 pt-4">
//               <p className="text-xl text-gray-300">{text}</p>
//             </div>
//           ))}
//         </div>

//         <div className="max-w-2xl mx-auto">
//           <div className="text-center mb-12">
//             <div className="inline-flex items-center gap-2 bg-blue-500/10 border border-blue-500/20 rounded-full px-4 py-2 text-sm text-blue-400 mb-4">
//               <span>Early Access Opens January 24</span>
//             </div>
//             <p className="text-gray-400 text-xl">Reserve your spot now.</p>
//           </div>

//           <div className="grid md:grid-cols-2 gap-6 mb-8">
//             <a
//               href={founderMailto}
//               className="group p-8 bg-blue-500/5 hover:bg-blue-500/10 border border-blue-500/20 rounded-lg transition-all text-center"
//             >
//               <Rocket className="w-8 h-8 text-blue-500 mb-4 mx-auto" />
//               <h3 className="text-xl font-semibold mb-3">Founders</h3>
//               <p className="text-gray-400 mb-4">Share metrics. Skip pitching.</p>
//               <div className="flex items-center justify-center text-blue-400 group-hover:text-blue-300">
//                 Get Early Access <ArrowRight className="w-5 h-5 ml-2 group-hover:translate-x-1 transition-transform" />
//               </div>
//             </a>

//             <a
//               href={investorMailto}
//               className="group p-8 bg-blue-500/5 hover:bg-blue-500/10 border border-blue-500/20 rounded-lg transition-all text-center"
//             >
//               <ChartBar className="w-8 h-8 text-blue-500 mb-4 mx-auto" />
//               <h3 className="text-xl font-semibold mb-3">Investors</h3>
//               <p className="text-gray-400 mb-4">Get verified metrics weekly.</p>
//               <div className="flex items-center justify-center text-blue-400 group-hover:text-blue-300">
//                 Request Access <ArrowRight className="w-5 h-5 ml-2 group-hover:translate-x-1 transition-transform" />
//               </div>
//             </a>
//           </div>

//           <div className="text-center space-y-3">
//             <p className="text-blue-400">What happens next?</p>
//             <p className="text-gray-400">Response within 24 hours if selected.</p>
//             <p className="text-gray-400">Limited to first 50 founders & 10 VCs who qualify.</p>
//           </div>
//         </div>

//         <div className="mt-32 text-center">
//           <p className="text-gray-400 mb-2">By the team behind FeedbackbyAI (10k+ users)</p>
//           <p className="text-blue-500">The future of fundraising is verified.</p>
//         </div>
//       </div>
//     </div>
//   );
// };

// export default ComingSoon;

// import React from "react";
// import { ArrowRight, Shield, Mail } from "lucide-react";

// const ComingSoon = () => {
//   return (
//     <div className="min-h-screen bg-black text-white selection:bg-blue-500/30">
//       <div className="bg-blue-500/10 border-b border-blue-500/20">
//         <div className="max-w-[1200px] mx-auto px-6 py-3 text-center text-sm">
//           <span className="text-blue-400">
//             Tired of endless fundraising meetings? PROOV verifies your traction so you can get funded faster. Early access starts Jan 24.
//           </span>
//         </div>
//       </div>

//       <div className="absolute inset-0 bg-[radial-gradient(ellipse_at_top_right,#1E40AF20,transparent_50%)]" />

//       <div className="relative max-w-[1200px] mx-auto px-6 pt-24 pb-20">
//         <header className="flex items-center justify-between mb-32">
//           <div className="flex items-center gap-2">
//             <Shield className="w-6 h-6 text-blue-500" />
//             <span className="text-2xl font-bold">PROOV</span>
//           </div>
//           <div className="flex items-center gap-2">
//             <span className="h-2 w-2 rounded-full bg-blue-500 animate-pulse" />
//             <span className="text-sm text-blue-500">Limited spots available</span>
//           </div>
//         </header>

//         <div className="max-w-4xl mx-auto mb-32">
//           <h1 className="text-7xl font-bold leading-tight mb-8">
//             Verification
//             <br />
//             is the New Pitch
//           </h1>

//           <div className="space-y-6 text-2xl text-gray-400 max-w-2xl">
//             <p>Founders: Stop wasting time on decks. Prove traction. Get funded.</p>
//             <p>Investors: Access verified metrics. Find rockets. Invest with confidence.</p>
//           </div>
//         </div>

//         <div className="grid md:grid-cols-5 gap-12 mb-32 max-w-4xl mx-auto">
//           {[
//             "Connect your key metrics (Stripe, Github, Analytics)",
//             "AI monitors growth and auto-detects progress",
//             "Verified updates are shared with relevant investors",
//             "Investors discover and connect with you",
//             "Prove your traction. Secure your funding.",
//           ].map((text, i) => (
//             <div key={i} className="border-t border-blue-500/20 pt-4">
//               <p className="text-xl">{text}</p>
//             </div>
//           ))}
//         </div>

//         <div className="max-w-md mx-auto">
//           <div className="text-center mb-12">
//             <div className="inline-flex items-center gap-2 bg-blue-500/10 border border-blue-500/20 rounded-full px-4 py-2 text-sm text-blue-400 mb-4">
//               <span>Early Access Starts Jan 24</span>
//             </div>
//             <p className="text-gray-400">Don't miss out. Secure your spot.</p>
//           </div>

//           <div className="space-y-4 text-center">
//             <a
//               href="mailto:business@feedbackbyai.com?subject=Founder - I'm interested in PROOV!"
//               className="inline-flex items-center gap-3 bg-blue-500 hover:bg-blue-600 text-white px-6 py-4 rounded-lg group transition-colors w-full"
//             >
//               <Mail className="w-5 h-5" />
//               <span>Founders: Get Early Access</span>
//               <ArrowRight className="w-5 h-5 group-hover:translate-x-1 transition-transform" />
//             </a>
//             <a
//               href="mailto:business@feedbackbyai.com?subject=Investor - I want to know more about PROOV!"
//               className="inline-flex items-center gap-3 bg-green-500 hover:bg-green-600 text-white px-6 py-4 rounded-lg group transition-colors w-full"
//             >
//               <Mail className="w-5 h-5" />
//               <span>Investors: Learn More</span>
//               <ArrowRight className="w-5 h-5 group-hover:translate-x-1 transition-transform" />
//             </a>
//             <p className="text-gray-400">From the team behind FeedbackbyAI (10k+ users)</p>
//           </div>
//         </div>

//         <div className="mt-32 text-center">
//           <p className="text-gray-500 text-sm">The future of fundraising is verified.</p>
//         </div>
//       </div>
//     </div>
//   );
// };

// export default ComingSoon;

// import React from "react";
// import { ArrowRight, Shield, Mail, Rocket, ChartBar } from "lucide-react";

// const ComingSoon = () => {
//   const founderMailto = `mailto:business@feedbackbyai.com?subject=PROOV Founder Access&body=Current MRR:%0D%0AGrowth Rate:%0D%0AFundraising Timeline:`;

//   const investorMailto = `mailto:business@feedbackbyai.com?subject=PROOV Investor Access&body=Fund Details:%0D%0ACheck Size:%0D%0AInvestment Focus:`;

//   return (
//     <div className="min-h-screen bg-black text-white selection:bg-blue-500/30">
//       <div className="bg-blue-500/10 border-b border-blue-500/20">
//         <div className="max-w-[1200px] mx-auto px-6 py-3 text-center text-sm">
//           <span className="text-blue-400">
//             Tired of endless fundraising meetings? PROOV verifies your traction so you can get funded faster. Early access starts Jan 24.
//           </span>
//         </div>
//       </div>

//       <div className="absolute inset-0 bg-[radial-gradient(ellipse_at_top_right,#1E40AF20,transparent_50%)]" />

//       <div className="relative max-w-[1200px] mx-auto px-6 pt-24 pb-20">
//         <header className="flex items-center justify-between mb-32">
//           <div className="flex items-center gap-2">
//             <Shield className="w-6 h-6 text-blue-500" />
//             <span className="text-2xl font-bold">PROOV</span>
//           </div>
//           <div className="flex items-center gap-2">
//             <span className="h-2 w-2 rounded-full bg-blue-500 animate-pulse" />
//             <span className="text-sm text-blue-500">First 50 founders. First 10 VCs.</span>
//           </div>
//         </header>

//         <div className="max-w-4xl mx-auto mb-32">
//           <h1 className="text-7xl font-bold leading-tight mb-8">
//             Verification
//             <br />
//             is the New Pitch
//           </h1>

//           <div className="space-y-6 text-2xl text-gray-400 max-w-2xl">
//             {/* <p>Founders: Stop wasting time on decks. Prove traction. Get funded.</p> */}
//             <p>Founders are drowning in pitch decks and pointless meetings, struggling to get noticed by investors.</p>
//             <p>
//               Investors are sifting through mountains of hype and inflated metrics, wasting time on due diligence for companies that are
//               going nowhere.
//             </p>
//             <p>PROOV changes fundraising forever.</p>
//             {/* <p>Investors: Access verified metrics. Find rockets. Invest with confidence.</p> */}
//           </div>
//         </div>

//         <div className="grid md:grid-cols-5 gap-12 mb-32 max-w-4xl mx-auto">
//           {[
//             "Connect your GitHub ,Google Analytics,Stripe or Mixpanel",
//             "PROOV AI automatically tracks your progress and showcases your REAL traction to investors every week.",
//             // "AI monitors growth and auto-detects progress",
//             "Investors get instant access to a curated feed of startups with weekly verified metrics.",
//             "They spot the winners early.Make data-driven investment decisions faster",
//             // "Verified updates are shared with relevant investors",
//             // "Investors discover and connect with you",
//             // "Prove your traction. Secure your funding.",
//           ].map((text, i) => (
//             <div key={i} className="border-t border-blue-500/20 pt-4">
//               <p className="text-xl text-gray-300">{text}</p>
//             </div>
//           ))}
//         </div>

//         <div className="max-w-2xl mx-auto">
//           <div className="text-center mb-12">
//             <div className="inline-flex items-center gap-2 bg-blue-500/10 border border-blue-500/20 rounded-full px-4 py-2 text-sm text-blue-400 mb-4">
//               <span>Limited Early Access</span>
//             </div>
//             <p className="text-gray-400 text-xl">Reserve your spot before Jan 24.</p>
//           </div>

//           <div className="grid md:grid-cols-2 gap-6 mb-8">
//             <a
//               href={founderMailto}
//               className="group flex flex-col items-center p-8 bg-blue-500 hover:bg-blue-600 rounded-lg transition-all text-center"
//             >
//               <Rocket className="w-8 h-8 mb-4" />
//               <h3 className="text-xl font-semibold mb-3">Founders</h3>
//               <p className="text-gray-100 mb-4">Share metrics. Skip pitching.</p>
//               <div className="flex items-center justify-center group-hover:text-blue-300">
//                 Get Early Access <ArrowRight className="w-5 h-5 ml-2 group-hover:translate-x-1 transition-transform" />
//               </div>
//             </a>

//             <a
//               href={investorMailto}
//               className="group flex flex-col items-center p-8 bg-blue-500 hover:bg-blue-600 rounded-lg transition-all text-center"
//             >
//               <ChartBar className="w-8 h-8 mb-4" />
//               <h3 className="text-xl font-semibold mb-3">Investors</h3>
//               <p className="text-gray-100 mb-4">Get verified metrics weekly.</p>
//               <div className="flex items-center justify-center group-hover:text-blue-300">
//                 Request Access <ArrowRight className="w-5 h-5 ml-2 group-hover:translate-x-1 transition-transform" />
//               </div>
//             </a>
//           </div>

//           <div className="text-center space-y-3 mt-8">
//             <p className="text-gray-400">From the team behind FeedbackbyAI (10k+ users)</p>
//             <p className="text-sm text-gray-500">Selected founders & VCs get access within 24 hours</p>
//           </div>
//         </div>

//         <div className="mt-32 text-center">
//           <p className="text-blue-400">The future of fundraising is verified.</p>
//         </div>
//       </div>
//     </div>
//   );
// };

// export default ComingSoon;

// import React from "react";
// import { ArrowRight, Shield, Mail, Rocket, ChartBar, GitBranch, BarChart2, LineChart } from "lucide-react";

// const ComingSoon = () => {
//   const founderMailto = `mailto:business@feedbackbyai.com?subject=PROOV Founder Access&body=Current MRR:%0D%0AGrowth Rate:%0D%0AFundraising Timeline:`;

//   const investorMailto = `mailto:business@feedbackbyai.com?subject=PROOV Investor Access&body=Fund Details:%0D%0ACheck Size:%0D%0AInvestment Focus:`;

//   return (
//     <div className="min-h-screen bg-black text-white selection:bg-blue-500/30">
//       <div className="bg-blue-500/10 border-b border-blue-500/20">
//         <div className="max-w-[1200px] mx-auto px-6 py-3 text-center text-sm">
//           <span className="text-blue-400">
//             Tired of endless fundraising meetings? PROOV verifies your traction so you can get funded faster. Early access starts Jan 24.
//           </span>
//         </div>
//       </div>

//       <div className="absolute inset-0 bg-[radial-gradient(ellipse_at_top_right,#1E40AF20,transparent_50%)]" />

//       <div className="relative max-w-[1200px] mx-auto px-6 pt-24 pb-20">
//         <header className="flex items-center justify-between mb-32">
//           <div className="flex items-center gap-2">
//             <Shield className="w-6 h-6 text-blue-500" />
//             <span className="text-2xl font-bold">PROOV</span>
//           </div>
//           <div className="flex items-center gap-2">
//             <span className="h-2 w-2 rounded-full bg-blue-500 animate-pulse" />
//             <span className="text-sm text-blue-500">First 50 founders. First 10 VCs.</span>
//           </div>
//         </header>

//         <div className="max-w-4xl mx-auto mb-24">
//           <h1 className="text-7xl font-bold leading-tight mb-12">
//             Verification
//             <br />
//             is the New Pitch
//           </h1>

//           <div className="bg-blue-500/5 border border-blue-500/10 rounded-lg p-8 space-y-8">
//             <p className="text-2xl text-gray-400">
//               Founders are drowning in pitch decks and pointless meetings, struggling to get noticed by investors.
//             </p>
//             <p className="text-2xl text-gray-400">
//               Investors are sifting through mountains of hype and inflated metrics, wasting time on due diligence for companies that are
//               going nowhere.
//             </p>
//             <p className="text-2xl text-blue-400">PROOV changes fundraising forever.</p>
//           </div>
//         </div>

//         <div className="max-w-4xl mx-auto mb-24">
//           <div className="grid md:grid-cols-2 gap-8 mb-8">
//             <div className="space-y-6">
//               <div className="flex items-center gap-4">
//                 <div className="flex gap-3">
//                   <GitBranch className="w-6 h-6 text-blue-500" />
//                   <BarChart2 className="w-6 h-6 text-blue-500" />
//                   <LineChart className="w-6 h-6 text-blue-500" />
//                 </div>
//                 <p className="text-xl text-gray-300">Connect your GitHub, Analytics, Stripe or Mixpanel</p>
//               </div>
//               <p className="text-xl text-gray-300">
//                 PROOV AI automatically tracks your progress and showcases your REAL traction to investors every week.
//               </p>
//             </div>
//             <div className="space-y-6">
//               <p className="text-xl text-gray-300">
//                 Investors get instant access to a curated feed of startups with weekly verified metrics.
//               </p>
//               <p className="text-xl text-gray-300">They spot the winners early. Make data-driven investment decisions faster.</p>
//             </div>
//           </div>
//         </div>

//         <div className="max-w-2xl mx-auto">
//           <div className="text-center mb-12">
//             <div className="inline-flex items-center gap-2 bg-blue-500/10 border border-blue-500/20 rounded-full px-4 py-2 text-sm text-blue-400 mb-4">
//               <span>Limited Early Access</span>
//             </div>
//             <p className="text-gray-400 text-xl">Reserve your spot before Jan 24.</p>
//           </div>

//           <div className="grid md:grid-cols-2 gap-6 mb-8">
//             <a
//               href={founderMailto}
//               className="group flex flex-col items-center p-8 bg-blue-500 hover:bg-blue-600 rounded-lg transition-all text-center"
//             >
//               <Rocket className="w-8 h-8 mb-4" />
//               <h3 className="text-xl font-semibold mb-3">Founders</h3>
//               <p className="text-gray-100 mb-4">Share metrics. Skip pitching.</p>
//               <div className="flex items-center justify-center group-hover:text-blue-300">
//                 Get Early Access <ArrowRight className="w-5 h-5 ml-2 group-hover:translate-x-1 transition-transform" />
//               </div>
//             </a>

//             <a
//               href={investorMailto}
//               className="group flex flex-col items-center p-8 bg-blue-500 hover:bg-blue-600 rounded-lg transition-all text-center"
//             >
//               <ChartBar className="w-8 h-8 mb-4" />
//               <h3 className="text-xl font-semibold mb-3">Investors</h3>
//               <p className="text-gray-100 mb-4">Get verified metrics weekly.</p>
//               <div className="flex items-center justify-center group-hover:text-blue-300">
//                 Request Access <ArrowRight className="w-5 h-5 ml-2 group-hover:translate-x-1 transition-transform" />
//               </div>
//             </a>
//           </div>

//           <div className="text-center space-y-3 mt-8">
//             <p className="text-gray-400">From the team behind FeedbackbyAI (10k+ users)</p>
//             <p className="text-sm text-gray-500">Selected founders & VCs get access within 24 hours</p>
//           </div>
//         </div>

//         <div className="mt-32 text-center">
//           <p className="text-blue-400">The future of fundraising is verified.</p>
//         </div>
//       </div>
//     </div>
//   );
// };

// export default ComingSoon;

// import React, { useState } from "react";
// import { ArrowRight, Shield, Lock } from "lucide-react";

// const ComingSoon = () => {
//   const [email, setEmail] = useState("");
//   const [role, setRole] = useState("");

//   return (
//     <div className="min-h-screen bg-black text-white selection:bg-blue-500/30">
//       {/* Minimal gradient */}
//       <div className="absolute inset-0 bg-[radial-gradient(ellipse_at_top_right,#1E40AF20,transparent_50%)]" />

//       <div className="relative max-w-[1200px] mx-auto px-6 pt-24 pb-20">
//         {/* Ultra Minimal Header */}
//         <header className="flex items-center justify-between mb-40">
//           <div className="flex items-center gap-2">
//             <Shield className="w-6 h-6 text-blue-500" />
//             <span className="text-2xl font-bold">PROOV</span>
//           </div>
//           <div className="flex items-center gap-2">
//             <span className="h-2 w-2 rounded-full bg-blue-500 animate-pulse" />
//             <span className="text-sm text-blue-500">Batch #1 Selection</span>
//           </div>
//         </header>

//         {/* Teaser Hero - Create Mystery */}
//         <div className="max-w-3xl mx-auto text-center mb-32">
//           <div className="inline-flex items-center gap-2 bg-blue-500/10 border border-blue-500/20 rounded-full px-4 py-2 text-blue-400 text-sm mb-8">
//             <Lock className="w-4 h-4" />
//             <span>The Trust Protocol for Startups</span>
//           </div>

//           <h1 className="text-7xl font-bold leading-tight mb-8">
//             2-Min Video.
//             <br />
//             Not 200 Meetings.
//           </h1>

//           <p className="text-xl text-gray-400">
//             We're changing how startups raise capital.
//             <br />
//             First batch: 50 founders. 10 VCs.
//           </p>
//         </div>

//         {/* Minimal Teaser Points */}
//         <div className="grid md:grid-cols-3 gap-16 mb-32 max-w-4xl mx-auto">
//           {["Auto-verified metrics", "Real-time validation", "Instant VC access"].map((text, i) => (
//             <div key={i} className="text-center">
//               <p className="text-gray-400">{text}</p>
//             </div>
//           ))}
//         </div>

//         {/* Strong CTA */}
//         <div className="max-w-md mx-auto">
//           <div className="bg-white/5 border border-white/10 rounded-xl p-8">
//             <div className="text-center mb-8">
//               <h3 className="text-xl font-semibold mb-2">Join Batch #1</h3>
//               <p className="text-gray-400 text-sm">Limited spots available</p>
//             </div>

//             <div className="space-y-4">
//               <input
//                 type="email"
//                 placeholder="Your email"
//                 className="w-full px-4 py-3 bg-white/5 border border-white/10 rounded-lg focus:border-blue-500 outline-none transition-all placeholder:text-white/40"
//                 value={email}
//                 onChange={(e) => setEmail(e.target.value)}
//               />

//               <div className="flex gap-3">
//                 <button
//                   onClick={() => setRole("founder")}
//                   className={`flex-1 px-4 py-3 rounded-lg transition-all ${
//                     role === "founder" ? "bg-blue-500 text-white" : "bg-white/5 hover:bg-white/10 text-white/80"
//                   }`}
//                 >
//                   Founder
//                 </button>
//                 <button
//                   onClick={() => setRole("vc")}
//                   className={`flex-1 px-4 py-3 rounded-lg transition-all ${
//                     role === "vc" ? "bg-blue-500 text-white" : "bg-white/5 hover:bg-white/10 text-white/80"
//                   }`}
//                 >
//                   VC
//                 </button>
//               </div>

//               <button className="w-full bg-blue-500 hover:bg-blue-600 text-white px-4 py-3 rounded-lg flex items-center justify-center gap-2 group">
//                 Get Access
//                 <ArrowRight className="w-4 h-4 group-hover:translate-x-1 transition-transform" />
//               </button>
//             </div>

//             <p className="text-center text-gray-500 text-sm mt-6">
//               {role === "founder" ? "First 50 founders only" : "Limited to 10 funds"}
//             </p>
//           </div>
//         </div>

//         {/* Super Minimal Footer */}
//         <div className="mt-32 text-center">
//           <p className="text-xs text-gray-600">By invitation only</p>
//         </div>
//       </div>
//     </div>
//   );
// };

// export default ComingSoon;

// import React, { useState } from "react";
// import { ArrowRight, Shield, Zap, BarChart2 } from "lucide-react";

// const ComingSoon = () => {
//   const [email, setEmail] = useState("");
//   const [role, setRole] = useState("");

//   return (
//     <div className="min-h-screen bg-black text-white selection:bg-blue-500/30">
//       {/* Dynamic gradient background */}
//       <div className="absolute inset-0 bg-gradient-to-br from-blue-900/20 via-violet-900/10 to-transparent" />

//       <div className="relative max-w-[1200px] mx-auto px-6 pt-24 pb-20">
//         {/* Header */}
//         <header className="flex items-center justify-between mb-32">
//           <div className="flex items-center gap-2">
//             <Shield className="w-6 h-6 text-blue-500" />
//             <span className="text-2xl font-bold">PROOV</span>
//           </div>
//           <div className="flex items-center gap-2">
//             <span className="h-2 w-2 rounded-full bg-blue-500 animate-pulse" />
//             <span className="text-sm text-blue-500">First Batch Open</span>
//           </div>
//         </header>

//         {/* Hero - Clear Value + Intrigue */}
//         <div className="max-w-4xl mx-auto text-center mb-24">
//           <h1 className="text-7xl font-bold leading-tight mb-8">
//             The End of
//             <br />
//             Endless Pitching
//           </h1>

//           <p className="text-2xl text-gray-300 mb-8">
//             One 2-min weekly video update.
//             <br />
//             Every metric verified.
//             <br />
//             Every investor watching.
//           </p>

//           <div className="inline-flex items-center gap-2 bg-blue-500/10 border border-blue-500/20 rounded-full px-4 py-2 text-blue-400 text-sm">
//             First 50 founders · First 10 VCs
//           </div>
//         </div>

//         {/* How It Works - Clear but Intriguing */}
//         <div className="grid md:grid-cols-3 gap-8 mb-24 max-w-4xl mx-auto">
//           <div className="bg-white/5 border border-white/10 rounded-xl p-8 hover:border-blue-500/30 transition-colors">
//             <Zap className="w-6 h-6 text-blue-500 mb-4" />
//             <h3 className="text-xl font-semibold mb-2">Quick Updates</h3>
//             <p className="text-gray-400">Drop a 2-min video. Share with all VCs at once. No more pitch spam.</p>
//           </div>

//           <div className="bg-white/5 border border-white/10 rounded-xl p-8 hover:border-blue-500/30 transition-colors">
//             <Shield className="w-6 h-6 text-blue-500 mb-4" />
//             <h3 className="text-xl font-semibold mb-2">Auto-Verified</h3>
//             <p className="text-gray-400">AI validates your metrics. Real-time verification. Build trust instantly.</p>
//           </div>

//           <div className="bg-white/5 border border-white/10 rounded-xl p-8 hover:border-blue-500/30 transition-colors">
//             <BarChart2 className="w-6 h-6 text-blue-500 mb-4" />
//             <h3 className="text-xl font-semibold mb-2">Deal Flow</h3>
//             <p className="text-gray-400">VCs see verified growth. Meetings happen faster. Close deals quicker.</p>
//           </div>
//         </div>

//         {/* CTA Section */}
//         <div className="max-w-md mx-auto">
//           <div className="bg-gradient-to-b from-white/5 to-transparent p-8 rounded-xl border border-white/10">
//             <div className="text-center mb-8">
//               <h3 className="text-2xl font-semibold mb-2">Join First Batch</h3>
//               <p className="text-gray-400">Limited early access spots available</p>
//             </div>

//             <div className="space-y-4">
//               <input
//                 type="email"
//                 placeholder="Your email"
//                 className="w-full px-4 py-3 bg-black/50 border border-white/10 rounded-lg focus:border-blue-500 outline-none transition-all placeholder:text-white/40"
//                 value={email}
//                 onChange={(e) => setEmail(e.target.value)}
//               />

//               <div className="flex gap-3">
//                 <button
//                   onClick={() => setRole("founder")}
//                   className={`flex-1 px-4 py-3 rounded-lg transition-all ${
//                     role === "founder" ? "bg-blue-500 text-white" : "bg-white/5 hover:bg-white/10 text-white/80"
//                   }`}
//                 >
//                   Founder
//                 </button>
//                 <button
//                   onClick={() => setRole("vc")}
//                   className={`flex-1 px-4 py-3 rounded-lg transition-all ${
//                     role === "vc" ? "bg-blue-500 text-white" : "bg-white/5 hover:bg-white/10 text-white/80"
//                   }`}
//                 >
//                   VC
//                 </button>
//               </div>

//               <button className="w-full bg-blue-500 hover:bg-blue-600 text-white px-4 py-3 rounded-lg flex items-center justify-center gap-2 group">
//                 Request Access
//                 <ArrowRight className="w-4 h-4 group-hover:translate-x-1 transition-transform" />
//               </button>

//               <p className="text-center text-gray-500 text-sm">
//                 {role === "founder" ? "First 50 founders only · Filling fast" : "Limited to 10 funds · 3 spots left"}
//               </p>
//             </div>
//           </div>
//         </div>

//         {/* Footer Status */}
//         <div className="mt-24 text-center space-y-2">
//           <p className="text-blue-500/80 font-medium">The Verification Layer for Startups</p>
//           <p className="text-gray-600 text-sm">Changing how startups raise capital</p>
//         </div>
//       </div>
//     </div>
//   );
// };

// export default ComingSoon;

// import React, { useState } from "react";
// import { Shield, ArrowRight, Clock, Zap } from "lucide-react";

// const ComingSoon = () => {
//   const [email, setEmail] = useState("");
//   const [role, setRole] = useState("");

//   return (
//     <div className="min-h-screen bg-black text-white selection:bg-indigo-500/30">
//       {/* Premium gradient */}
//       <div className="absolute inset-0 bg-[radial-gradient(ellipse_at_center,#312e81_0%,transparent_50%)] opacity-30" />

//       <div className="relative max-w-[1200px] mx-auto px-6 pt-24 pb-20">
//         {/* Header */}
//         <header className="flex items-center justify-between mb-40">
//           <div className="flex items-center gap-3">
//             <Shield className="w-7 h-7 text-indigo-500" />
//             <span className="text-3xl font-bold">PROOV</span>
//           </div>
//           <div className="flex items-center gap-2">
//             <div className="h-2 w-2 rounded-full bg-indigo-500 animate-pulse" />
//             <span className="text-indigo-400 text-sm">First Batch Open</span>
//           </div>
//         </header>

//         {/* Hero - Maximum Impact */}
//         <div className="max-w-4xl mx-auto mb-32">
//           <h1 className="text-8xl font-bold leading-tight mb-12 tracking-tight">
//             Verification
//             <br />
//             is the New Pitch
//           </h1>

//           <div className="space-y-3 text-2xl text-gray-400 max-w-2xl">
//             <p>Drop 2-min weekly videos.</p>
//             <p>AI verifies every metric.</p>
//             <p>VCs see only truth.</p>
//           </div>
//         </div>

//         {/* Problem/Solution Grid */}
//         <div className="grid md:grid-cols-2 gap-16 mb-32 max-w-4xl mx-auto">
//           <div className="space-y-8">
//             <div className="text-lg text-indigo-400">THE PROBLEM</div>
//             <div className="space-y-4 text-xl text-gray-400">
//               <p>Founders waste months pitching.</p>
//               <p>VCs waste millions on fake metrics.</p>
//             </div>
//           </div>
//           <div className="space-y-8">
//             <div className="text-lg text-indigo-400">THE SOLUTION</div>
//             <div className="space-y-4 text-xl text-gray-400">
//               <p>Weekly verified updates.</p>
//               <p>Real deals happen faster.</p>
//             </div>
//           </div>
//         </div>

//         {/* How It Works */}
//         <div className="grid md:grid-cols-3 gap-8 mb-32 max-w-4xl mx-auto">
//           <div className="bg-white/5 backdrop-blur-sm border border-white/10 rounded-2xl p-8">
//             <Clock className="w-6 h-6 text-indigo-500 mb-4" />
//             <p className="text-lg">One update reaches all VCs</p>
//           </div>
//           <div className="bg-white/5 backdrop-blur-sm border border-white/10 rounded-2xl p-8">
//             <Shield className="w-6 h-6 text-indigo-500 mb-4" />
//             <p className="text-lg">Every metric verified</p>
//           </div>
//           <div className="bg-white/5 backdrop-blur-sm border border-white/10 rounded-2xl p-8">
//             <Zap className="w-6 h-6 text-indigo-500 mb-4" />
//             <p className="text-lg">Instant VC attention</p>
//           </div>
//         </div>

//         {/* CTA Section */}
//         <div className="max-w-md mx-auto">
//           <div className="bg-gradient-to-b from-white/5 to-transparent rounded-2xl p-8 border border-white/10">
//             <div className="text-center mb-8">
//               <div className="inline-flex items-center gap-2 px-4 py-2 rounded-full bg-indigo-500/10 border border-indigo-500/20 text-sm text-indigo-400 mb-4">
//                 Limited Early Access
//               </div>
//               <p className="text-gray-400">First 50 founders · First 10 VCs</p>
//             </div>

//             <div className="space-y-4">
//               <input
//                 type="email"
//                 placeholder="Your email"
//                 className="w-full px-4 py-3 bg-black/50 border border-white/10 rounded-xl focus:border-indigo-500 outline-none transition-all text-white placeholder:text-white/40"
//                 value={email}
//                 onChange={(e) => setEmail(e.target.value)}
//               />

//               <div className="flex gap-3">
//                 <button
//                   onClick={() => setRole("founder")}
//                   className={`flex-1 px-4 py-3 rounded-xl transition-all ${
//                     role === "founder" ? "bg-indigo-500 text-white" : "bg-white/5 hover:bg-white/10 text-white/80"
//                   }`}
//                 >
//                   Founder
//                 </button>
//                 <button
//                   onClick={() => setRole("vc")}
//                   className={`flex-1 px-4 py-3 rounded-xl transition-all ${
//                     role === "vc" ? "bg-indigo-500 text-white" : "bg-white/5 hover:bg-white/10 text-white/80"
//                   }`}
//                 >
//                   VC
//                 </button>
//               </div>

//               <button className="w-full bg-indigo-500 hover:bg-indigo-600 text-white px-4 py-3 rounded-xl flex items-center justify-center gap-2 group transition-all">
//                 Request Early Access
//                 <ArrowRight className="w-4 h-4 group-hover:translate-x-1 transition-transform" />
//               </button>
//             </div>

//             <p className="text-center text-gray-500 text-sm mt-6">
//               {role === "founder" ? "First 50 founders only · Filling fast" : "Limited to 10 funds · 3 spots left"}
//             </p>
//           </div>
//         </div>

//         {/* Footer */}
//         <div className="mt-32 text-center">
//           <p className="text-gray-600">Changing how startups raise capital. Forever.</p>
//         </div>
//       </div>
//     </div>
//   );
// };

// export default ComingSoon;

// import React, { useState, useEffect } from "react";
// import { Github, BarChart2, Video, TrendingUp, Check, Play, Eye, Star, X, ArrowRight, ChevronRight } from "lucide-react";

// const ProovFlow = () => {
//   const [step, setStep] = useState("signup");
//   const [loading, setLoading] = useState(false);
//   const [connecting, setConnecting] = useState(false);
//   const [connected, setConnected] = useState(false);
//   const [recordingStatus, setRecordingStatus] = useState("ready"); // ready, recording, preview
//   const [timer, setTimer] = useState(60);
//   const [showVerification, setShowVerification] = useState(false);
//   const [verificationStage, setVerificationStage] = useState(0);

//   useEffect(() => {
//     if (recordingStatus === "recording" && timer > 0) {
//       const interval = setInterval(() => setTimer((t) => t - 1), 1000);
//       return () => clearInterval(interval);
//     }
//   }, [recordingStatus, timer]);

//   // Simulated data
//   const MOCK_DATA = {
//     metrics: {
//       commits: 47,
//       growth: 12,
//       weekNumber: 23,
//       trend: [32, 41, 47],
//     },
//     vcInterest: [
//       { name: "Sarah Chen", firm: "Sequoia Capital", time: "2h ago" },
//       { name: "Mike Adams", firm: "Accel", time: "5h ago", saved: true },
//     ],
//   };

//   return (
//     <div className="min-h-screen bg-gray-50">
//       {/* Navigation */}
//       <nav className="bg-white border-b fixed w-full z-40">
//         <div className="max-w-6xl mx-auto px-6 py-4">
//           <div className="text-xl font-bold">PROOV</div>
//         </div>
//       </nav>

//       {/* Signup Screen */}
//       {step === "signup" && (
//         <div className="pt-24 max-w-md mx-auto px-6 text-center">
//           <h1 className="text-4xl font-bold mb-4">Build Trust With VCs</h1>
//           <p className="text-xl text-gray-600 mb-12">Verify your progress. Get investor attention.</p>

//           <button
//             onClick={() => setStep("connect")}
//             className="w-full h-12 bg-white border rounded-xl text-gray-700 font-medium flex items-center justify-center hover:bg-gray-50 relative overflow-hidden group"
//           >
//             <span className="absolute inset-0 w-3 bg-gradient-to-r from-blue-400 to-blue-500 transition-all duration-[400ms] -left-full group-hover:left-full" />
//             <span className="relative flex items-center">Continue with Google</span>
//           </button>
//         </div>
//       )}

//       {/* Connect Metrics Screen */}
//       {step === "connect" && (
//         <div className="pt-24 max-w-2xl mx-auto px-6">
//           <h2 className="text-3xl font-bold mb-3 text-center">Choose Your Core Metric</h2>
//           <p className="text-gray-600 mb-12 text-center text-lg">Pick one metric that best shows your progress</p>

//           <div className="grid md:grid-cols-3 gap-6">
//             {/* GitHub Option */}
//             <button
//               onClick={() => {
//                 setConnecting(true);
//                 setTimeout(() => {
//                   setConnecting(false);
//                   setConnected(true);
//                   setTimeout(() => setStep("record"), 1000);
//                 }, 2000);
//               }}
//               className="p-6 bg-white rounded-xl shadow-sm hover:shadow-md transition-all border-2 border-transparent hover:border-blue-500 text-left group"
//             >
//               <div className="w-12 h-12 bg-gray-100 rounded-xl flex items-center justify-center mb-4 group-hover:scale-110 transition-transform">
//                 <Github className="w-6 h-6 text-gray-600" />
//               </div>
//               <h3 className="font-semibold mb-2">GitHub Activity</h3>
//               <p className="text-sm text-gray-600">Perfect for technical startups showing development progress</p>
//             </button>

//             {/* Other options similar structure */}
//             {/* Analytics Option */}
//             <button className="p-6 bg-white rounded-xl shadow-sm hover:shadow-md transition-shadow text-left">
//               <div className="w-12 h-12 bg-gray-100 rounded-xl flex items-center justify-center mb-4">
//                 <BarChart2 className="w-6 h-6 text-gray-600" />
//               </div>
//               <h3 className="font-semibold mb-2">Google Analytics </h3>
//               <p className="text-sm text-gray-600">
//                 Perfect for consumer startups showing user growth
//                 {/* startups tracking user adoption */}
//               </p>
//             </button>

//             {/* Revenue Option */}
//             <button className="p-6 bg-white rounded-xl shadow-sm hover:shadow-md transition-shadow text-left">
//               <div className="w-12 h-12 bg-gray-100 rounded-xl flex items-center justify-center mb-4">
//                 <TrendingUp className="w-6 h-6 text-gray-600" />
//               </div>
//               <h3 className="font-semibold mb-2">Stripe Revenue</h3>
//               <p className="text-sm text-gray-600">Ideal for launched products with paying customers</p>
//             </button>
//           </div>
//         </div>
//       )}

//       {/* Connection Animation */}
//       {connecting && (
//         <div className="fixed inset-0 bg-white/80 backdrop-blur-sm flex items-center justify-center z-50">
//           <div className="max-w-md w-full p-8 text-center">
//             <div className="mb-6">
//               <div className="relative">
//                 <div className="w-16 h-16 mx-auto mb-4 relative">
//                   <Github className="w-16 h-16 text-gray-600" />
//                   <div className="absolute inset-0 border-4 border-blue-500 border-t-transparent rounded-full animate-spin" />
//                 </div>
//                 <h3 className="text-lg font-semibold mb-2">Connecting to GitHub</h3>
//                 <p className="text-gray-600">Fetching your development metrics...</p>
//               </div>
//             </div>
//           </div>
//         </div>
//       )}

//       {/* Connected Success */}
//       {/* {connected && (
//         <div className="fixed inset-0 bg-white/80 backdrop-blur-sm flex items-center justify-center z-50">
//           <div className="max-w-md w-full p-8 text-center">
//             <div className="scale-up">
//               <div className="w-16 h-16 mx-auto mb-4 text-green-500">
//                 <Check className="w-16 h-16" />
//               </div>
//               <h3 className="text-lg font-semibold mb-2">Successfully Connected!</h3>
//               <p className="text-gray-600">47 commits found this week</p>
//             </div>
//           </div>
//         </div>
//       )} */}

//       {/* First Update Screen */}
//       {step === "record" && (
//         <div className="pt-24 max-w-3xl mx-auto px-6">
//           <div className="bg-white rounded-xl shadow-sm">
//             <div className="p-6 border-b">
//               <h2 className="text-xl font-bold mb-2">Record Your First Update</h2>
//               <p className="text-gray-600">Share your progress with verified metrics</p>
//             </div>

//             <div className="p-6">
//               {/* Current Metrics */}
//               <div className="mb-8">
//                 <h3 className="text-sm font-medium text-gray-500 mb-4">This Week's Metrics</h3>
//                 <div className="p-4 bg-gray-50 rounded-xl">
//                   <div className="flex justify-between items-center mb-2">
//                     <div className="flex items-center">
//                       <Github className="w-5 h-5 text-gray-400 mr-2" />
//                       <span className="font-medium">GitHub Activity</span>
//                     </div>
//                     <div className="flex items-center text-green-600">
//                       <TrendingUp className="w-4 h-4 mr-1" />
//                       +12%
//                     </div>
//                   </div>
//                   <div className="text-3xl font-bold">47 commits</div>
//                 </div>
//               </div>

//               {/* Recording Interface */}
//               <div className="aspect-video bg-gray-900 rounded-xl relative overflow-hidden">
//                 {recordingStatus === "ready" && (
//                   <div className="absolute inset-0 flex items-center justify-center">
//                     <div className="text-center">
//                       <div className="w-16 h-16 bg-white/10 rounded-full flex items-center justify-center mx-auto mb-4">
//                         <Video className="w-8 h-8 text-white" />
//                       </div>
//                       <button
//                         onClick={() => setRecordingStatus("recording")}
//                         className="px-6 py-3 bg-blue-600 text-white rounded-lg hover:bg-blue-700 flex items-center mx-auto"
//                       >
//                         Start Recording
//                         <ArrowRight className="w-4 h-4 ml-2" />
//                       </button>
//                     </div>
//                   </div>
//                 )}

//                 {recordingStatus === "recording" && (
//                   <div className="absolute inset-0">
//                     <div className="absolute top-6 left-6 right-6 flex items-center justify-between">
//                       <div className="flex items-center space-x-4">
//                         <div className="flex items-center space-x-2">
//                           <div className="w-3 h-3 bg-red-500 rounded-full animate-pulse" />
//                           <span className="text-white text-sm font-medium">Recording</span>
//                         </div>
//                         <span className="text-white/60 text-sm">{timer}s</span>
//                       </div>

//                       <div className="flex items-center space-x-3">
//                         <button
//                           onClick={() => {
//                             setRecordingStatus("ready");
//                             setTimer(60);
//                           }}
//                           className="px-3 py-1.5 bg-white/10 text-white text-sm rounded-lg hover:bg-white/20"
//                         >
//                           Cancel
//                         </button>
//                         <button
//                           onClick={() => setRecordingStatus("preview")}
//                           className="px-3 py-1.5 bg-white text-gray-900 text-sm rounded-lg hover:bg-white/90"
//                         >
//                           Finish
//                         </button>
//                       </div>
//                     </div>

//                     <div className="absolute bottom-6 left-6 right-6 bg-white/10 backdrop-blur-sm rounded-lg p-4">
//                       <div className="flex items-center justify-between text-white">
//                         <div className="flex items-center space-x-3">
//                           <Github className="w-5 h-5" />
//                           <span className="font-medium">47 commits this week</span>
//                         </div>
//                         <div className="flex items-center text-green-400">
//                           <TrendingUp className="w-4 h-4 mr-1" />
//                           <span>+12%</span>
//                         </div>
//                       </div>
//                     </div>
//                   </div>
//                 )}

//                 {recordingStatus === "preview" && (
//                   <div className="absolute inset-0">
//                     <div className="absolute inset-0 flex items-center justify-center">
//                       <button className="w-16 h-16 bg-white/10 rounded-full flex items-center justify-center hover:bg-white/20">
//                         <Play className="w-8 h-8 text-white" />
//                       </button>
//                     </div>

//                     <div className="absolute bottom-6 left-6 right-6 flex justify-center space-x-3">
//                       <button
//                         onClick={() => {
//                           setRecordingStatus("recording");
//                           setTimer(60);
//                         }}
//                         className="px-4 py-2 bg-white/10 text-white rounded-lg hover:bg-white/20"
//                       >
//                         Record Again
//                       </button>
//                       <button
//                         onClick={() => {
//                           setShowVerification(true);
//                           // Start verification process
//                           const interval = setInterval(() => {
//                             setVerificationStage((s) => {
//                               if (s === 3) {
//                                 clearInterval(interval);
//                                 setTimeout(() => {
//                                   setShowVerification(false);
//                                   setStep("dashboard");
//                                 }, 1500);
//                               }
//                               return s < 3 ? s + 1 : s;
//                             });
//                           }, 1500);
//                         }}
//                         className="px-4 py-2 bg-blue-600 text-white rounded-lg hover:bg-blue-700"
//                       >
//                         Submit Update
//                       </button>
//                     </div>
//                   </div>
//                 )}
//               </div>

//               {/* Recording Tips */}
//               <div className="mt-6 bg-blue-50 rounded-xl p-4">
//                 <h3 className="font-medium text-blue-700 mb-2">Quick Tips</h3>
//                 <ul className="text-sm text-blue-600 space-y-1">
//                   <li>• Share your key metric progress</li>
//                   <li>• Highlight your main achievement</li>
//                   <li>• Mention next week's focus</li>
//                 </ul>
//               </div>
//             </div>
//           </div>
//         </div>
//       )}

//       {/* AI Verification */}
//       {showVerification && (
//         <div className="fixed inset-0 bg-white/80 backdrop-blur-sm flex items-center justify-center z-50">
//           <div className="max-w-md w-full p-8">
//             <div className="bg-white rounded-xl shadow-sm p-6">
//               <div className="space-y-6">
//                 {[
//                   "Analyzing video content...",
//                   "Verifying metrics claims...",
//                   "Matching with GitHub data...",
//                   "Verification complete!",
//                 ].map((text, index) => (
//                   <div
//                     key={index}
//                     className={`flex items-center space-x-3 transition-all duration-500 ${
//                       index > verificationStage ? "opacity-30" : "opacity-100"
//                     }`}
//                   >
//                     <div
//                       className={`w-6 h-6 rounded-full flex items-center justify-center ${
//                         index === verificationStage ? "bg-blue-100" : index < verificationStage ? "bg-green-100" : "bg-gray-100"
//                       }`}
//                     >
//                       {index < verificationStage ? (
//                         <Check className={`w-4 h-4 text-green-600`} />
//                       ) : index === verificationStage ? (
//                         <div className="w-3 h-3 bg-blue-500 rounded-full animate-pulse" />
//                       ) : (
//                         <div className="w-3 h-3 bg-gray-300 rounded-full" />
//                       )}
//                     </div>
//                     <span
//                       className={`text-sm ${
//                         index === verificationStage
//                           ? "text-blue-600 font-medium"
//                           : index < verificationStage
//                           ? "text-green-600"
//                           : "text-gray-400"
//                       }`}
//                     >
//                       {text}
//                     </span>
//                   </div>
//                 ))}
//               </div>

//               {verificationStage === 3 && (
//                 <div className="mt-8 text-center scale-up">
//                   <div className="w-16 h-16 mx-auto mb-4 bg-green-100 rounded-full flex items-center justify-center">
//                     <Check className="w-8 h-8 text-green-600" />
//                   </div>
//                   <h3 className="text-lg font-semibold mb-2">All Claims Verified!</h3>
//                   <p className="text-sm text-gray-600 mb-4">Your update is ready for VCs</p>
//                   <button
//                     onClick={() => {
//                       setShowVerification(false);
//                       setStep("dashboard");
//                     }}
//                     className="px-4 py-2 bg-blue-600 text-white rounded-lg hover:bg-blue-700"
//                   >
//                     View Dashboard
//                   </button>
//                 </div>
//               )}
//             </div>
//           </div>
//         </div>
//       )}

//       {/* Dashboard */}
//       {step === "dashboard" && (
//         <div className="pt-24 max-w-6xl mx-auto px-6">
//           {/* Weekly Update Card */}
//           <div className="bg-white rounded-xl shadow-sm mb-8">
//             <div className="p-6 border-b flex justify-between items-center">
//               <div>
//                 <div className="flex items-center space-x-2 mb-1">
//                   <h2 className="text-xl font-bold">Week 23 Update</h2>
//                   <span className="px-2 py-0.5 bg-green-100 text-green-700 rounded-full text-xs font-medium">Verified</span>
//                 </div>
//                 <p className="text-sm text-gray-600">Next update due Monday</p>
//               </div>
//               <button
//                 onClick={() => {
//                   setRecordingStatus("ready");
//                   setTimer(60);
//                   setStep("record");
//                 }}
//                 className="px-4 py-2 bg-blue-600 text-white rounded-lg hover:bg-blue-700 flex items-center"
//               >
//                 <Video className="w-4 h-4 mr-2" />
//                 Record Update
//               </button>
//             </div>

//             <div className="p-6">
//               <div className="grid md:grid-cols-2 gap-8">
//                 {/* Current Metrics */}
//                 <div>
//                   <h3 className="text-sm font-medium text-gray-500 mb-4">Current Metrics</h3>
//                   <div className="p-4 bg-gray-50 rounded-xl">
//                     <div className="flex justify-between items-start mb-2">
//                       <div className="flex items-center">
//                         <Github className="w-5 h-5 text-gray-400 mr-2" />
//                         <span className="font-medium">GitHub Activity</span>
//                       </div>
//                       <div className="flex items-center text-green-600">
//                         <TrendingUp className="w-4 h-4 mr-1" />
//                         +12%
//                       </div>
//                     </div>
//                     <div className="text-3xl font-bold">47 commits</div>
//                   </div>
//                 </div>

//                 {/* VC Interest */}
//                 <div>
//                   <h3 className="text-sm font-medium text-gray-500 mb-4">VC Activity</h3>
//                   <div className="space-y-3">
//                     <div className="p-4 bg-gray-50 rounded-xl">
//                       <div className="flex justify-between items-center mb-2">
//                         <div className="font-medium">Sequoia Capital</div>
//                         <div className="text-sm text-gray-500">2h ago</div>
//                       </div>
//                       <div className="flex items-center text-gray-600">
//                         <Eye className="w-4 h-4 mr-1" />
//                         <span className="text-sm">Viewed your update</span>
//                       </div>
//                     </div>

//                     <div className="p-4 bg-gray-50 rounded-xl">
//                       <div className="flex justify-between items-center mb-2">
//                         <div className="font-medium">Accel</div>
//                         <div className="text-sm text-gray-500">5h ago</div>
//                       </div>
//                       <div className="flex items-center text-amber-600">
//                         <Star className="w-4 h-4 mr-1" />
//                         <span className="text-sm">Saved your profile</span>
//                       </div>
//                     </div>
//                   </div>
//                 </div>
//               </div>
//             </div>
//           </div>

//           {/* Update History */}
//           <div className="bg-white rounded-xl shadow-sm">
//             <div className="p-6 border-b">
//               <h2 className="text-xl font-bold">Previous Updates</h2>
//             </div>
//             <div className="divide-y">
//               {[1, 2, 3].map((week) => (
//                 <div key={week} className="p-6 hover:bg-gray-50">
//                   <div className="flex items-center justify-between">
//                     <div className="flex items-center space-x-4">
//                       <div className="w-10 h-10 bg-blue-50 rounded-xl flex items-center justify-center">
//                         <Play className="w-5 h-5 text-blue-600" />
//                       </div>
//                       <div>
//                         <h3 className="font-medium">Week {23 - week} Update</h3>
//                         <p className="text-sm text-gray-500">{week === 1 ? "7 days ago" : week === 2 ? "14 days ago" : "21 days ago"}</p>
//                       </div>
//                     </div>
//                     <div className="flex items-center space-x-8">
//                       <div className="text-center">
//                         <div className="text-sm text-gray-500 mb-1">Commits</div>
//                         <div className="font-medium">{47 - week * 5}</div>
//                       </div>
//                       <div className="text-center">
//                         <div className="text-sm text-gray-500 mb-1">VC Views</div>
//                         <div className="font-medium">{4 - week}</div>
//                       </div>
//                     </div>
//                   </div>
//                 </div>
//               ))}
//             </div>
//           </div>

//           {/* Intro Request Notification */}
//           <div className="fixed bottom-4 right-4">
//             <div className="bg-white rounded-xl shadow-lg p-4 w-96">
//               <div className="flex items-start justify-between mb-3">
//                 <div>
//                   <h3 className="font-medium">New Intro Request</h3>
//                   <p className="text-sm text-gray-500">Just now</p>
//                 </div>
//                 <div className="px-2 py-1 bg-green-100 text-green-700 rounded-full text-xs">Verified VC</div>
//               </div>

//               <div className="mb-4">
//                 <div className="font-medium">Sequoia Capital</div>
//                 <p className="text-sm text-gray-600">$1M-5M Investment Range</p>
//                 <p className="text-sm text-gray-600 mt-2">"Impressed by your growth. Would love to connect and learn more."</p>
//               </div>

//               <div className="flex space-x-3">
//                 <button className="flex-1 px-3 py-2 bg-blue-600 text-white rounded-lg hover:bg-blue-700 text-sm">Share Calendly</button>
//                 <button className="flex-1 px-3 py-2 border rounded-lg hover:bg-gray-50 text-sm">Decline</button>
//               </div>
//             </div>
//           </div>
//         </div>
//       )}
//     </div>
//   );
// };

// export default ProovFlow;

// import React, { useState, useEffect } from "react";
// import {
//   Briefcase,
//   Search,
//   TrendingUp,
//   Star,
//   Calendar,
//   Play,
//   Check,
//   ChevronRight,
//   Github,
//   ArrowRight,
//   Filter,
//   Clock,
//   Building,
//   Mail,
//   MapPin,
//   BookmarkPlus,
// } from "lucide-react";

// const ProovVCFlow = () => {
//   const [step, setStep] = useState("signup");
//   const [loading, setLoading] = useState(false);
//   const [verifying, setVerifying] = useState(false);
//   const [notifications, setNotifications] = useState([]);
//   const [selectedFounder, setSelectedFounder] = useState(null);
//   const [showIntroModal, setShowIntroModal] = useState(false);
//   const [trackedCompanies, setTrackedCompanies] = useState([]);

//   const mockFounders = [
//     {
//       id: 1,
//       name: "BuildBetter",
//       description: "AI-powered DevTools platform for engineering productivity",
//       location: "San Francisco, USA",
//       batch: "YC W23",
//       metric: "GitHub Activity",
//       current: "47 commits",
//       growth: "+12%",
//       lastUpdate: "2h ago",
//       focus: "DevTools",
//       status: "Raising Seed",
//       verified: true,
//       companySize: "10-50",
//       fundingStage: "Seed",
//       seeking: "$2M-5M",
//     },
//     {
//       id: 2,
//       name: "MetricFlow",
//       description: "Real-time analytics infrastructure for enterprise data teams",
//       location: "New York, USA",
//       batch: "YC W23",
//       metric: "MRR Growth",
//       current: "$52K MRR",
//       growth: "+28%",
//       lastUpdate: "5h ago",
//       focus: "Analytics",
//       status: "Raising Series A",
//       verified: true,
//       companySize: "20-50",
//       fundingStage: "Series A",
//       seeking: "$8M-12M",
//     },
//   ];

//   const toggleTrackCompany = (companyId) => {
//     setTrackedCompanies((prev) => {
//       if (prev.includes(companyId)) {
//         return prev.filter((id) => id !== companyId);
//       }
//       return [...prev, companyId];
//     });
//     addNotification("success", "Company tracking status updated");
//   };

//   const addNotification = (type, message) => {
//     const newNotification = { id: Date.now(), type, message };
//     setNotifications((prev) => [...prev, newNotification]);
//     setTimeout(() => {
//       setNotifications((prev) => prev.filter((n) => n.id !== newNotification.id));
//     }, 5000);
//   };

//   const handleVerification = async () => {
//     setVerifying(true);
//     try {
//       await new Promise((resolve) => setTimeout(resolve, 2000));
//       setStep("feed");
//       addNotification("success", "VC status verified! Welcome to PROOV.");
//     } catch (error) {
//       addNotification("error", "Verification failed. Please try again.");
//     } finally {
//       setVerifying(false);
//     }
//   };

//   return (
//     <div className="min-h-screen bg-gray-50">
//       {/* Navigation */}
//       <nav className="bg-white border-b fixed w-full z-40">
//         <div className="max-w-6xl mx-auto px-6 py-4">
//           <div className="flex items-center justify-between">
//             <div className="text-xl font-bold">PROOV</div>
//             {step === "feed" && (
//               <div className="flex items-center space-x-6">
//                 <a href="#" className="text-gray-600 hover:text-gray-900">
//                   Feed
//                 </a>
//                 <a href="#" className="text-gray-600 hover:text-gray-900">
//                   Tracked ({trackedCompanies.length})
//                 </a>
//                 <a href="#" className="text-gray-600 hover:text-gray-900">
//                   Profile
//                 </a>
//               </div>
//             )}
//           </div>
//         </div>
//       </nav>

//       {/* VC Signup */}
//       {step === "signup" && (
//         <div className="pt-24 max-w-md mx-auto px-6 text-center">
//           <h1 className="text-4xl font-bold mb-4">Verified Founder Updates</h1>
//           <p className="text-xl text-gray-600 mb-12">Weekly growth metrics from vetted startups.</p>

//           <div className="space-y-4">
//             <button
//               onClick={handleVerification}
//               className="w-full h-12 bg-white border rounded-xl text-gray-700 font-medium flex items-center justify-center hover:bg-gray-50 transition-all relative overflow-hidden group"
//             >
//               <span className="absolute inset-0 w-3 bg-gradient-to-r from-blue-400 to-blue-500 transition-all duration-[400ms] -left-full group-hover:left-full" />
//               <span className="relative flex items-center">Continue with Google</span>
//             </button>
//             {/* <button
//               onClick={() => setStep("connect")}
//               className="w-full h-12 bg-white border rounded-xl text-gray-700 font-medium flex items-center justify-center hover:bg-gray-50 relative overflow-hidden group"
//             >
//               <span className="absolute inset-0 w-3 bg-gradient-to-r from-blue-400 to-blue-500 transition-all duration-[400ms] -left-full group-hover:left-full" />
//               <span className="relative flex items-center">Continue with Google</span>
//             </button> */}

//             <div className="text-sm text-gray-500">Verified VCs only. We check your work email.</div>
//           </div>
//         </div>
//       )}

//       {/* Main Feed */}
//       {step === "feed" && (
//         <div className="pt-24 max-w-6xl mx-auto px-6">
//           {/* Header Controls */}
//           <div className="bg-white rounded-xl shadow-sm mb-8">
//             <div className="p-6">
//               <div className="flex items-center justify-between mb-6">
//                 <h2 className="text-xl font-bold">Verified Updates</h2>
//                 <div className="flex items-center space-x-4">
//                   <button className="px-4 py-2 bg-gray-100 rounded-lg hover:bg-gray-200 flex items-center">
//                     <Filter className="w-4 h-4 mr-2" />
//                     Filter
//                   </button>
//                   <button className="px-4 py-2 bg-gray-100 rounded-lg hover:bg-gray-200 flex items-center">
//                     <Clock className="w-4 h-4 mr-2" />
//                     Recent
//                   </button>
//                 </div>
//               </div>

//               <div className="flex space-x-4">
//                 {["All", "YC W23", "B2B", "AI/ML", "DevTools"].map((filter) => (
//                   <button
//                     key={filter}
//                     className={`px-4 py-2 rounded-lg text-sm ${
//                       filter === "All" ? "bg-blue-600 text-white" : "bg-gray-100 text-gray-700 hover:bg-gray-200"
//                     }`}
//                   >
//                     {filter}
//                   </button>
//                 ))}
//               </div>
//             </div>
//           </div>

//           {/* Tracked Companies Section */}
//           {trackedCompanies.length > 0 && (
//             <div className="mb-8">
//               <h3 className="text-lg font-semibold mb-4">Tracked Companies</h3>
//               <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
//                 {mockFounders
//                   .filter((founder) => trackedCompanies.includes(founder.id))
//                   .map((founder) => (
//                     <div key={`tracked-${founder.id}`} className="bg-white rounded-xl p-4 shadow-sm">
//                       <div className="flex items-center justify-between mb-2">
//                         <span className="font-medium">{founder.name}</span>
//                         <span className="text-sm text-gray-500">{founder.lastUpdate}</span>
//                       </div>
//                       <div className="text-sm text-gray-600 mb-2">{founder.description}</div>
//                       <div className="flex items-center text-green-600">
//                         <TrendingUp className="w-4 h-4 mr-1" />
//                         {founder.growth} in {founder.metric}
//                       </div>
//                     </div>
//                   ))}
//               </div>
//             </div>
//           )}

//           {/* Founder Updates */}
//           <div className="space-y-6">
//             {mockFounders.map((founder) => (
//               <div key={founder.id} className="bg-white rounded-xl shadow-sm hover:shadow-md transition-all">
//                 <div className="p-6">
//                   <div className="flex items-start justify-between mb-4">
//                     <div className="space-y-2">
//                       <div className="flex items-center space-x-2">
//                         <h3 className="text-lg font-bold">{founder.name}</h3>
//                         <span className="px-2 py-0.5 bg-purple-100 text-purple-700 rounded-full text-xs font-medium">{founder.batch}</span>
//                         {founder.verified && (
//                           <span className="px-2 py-0.5 bg-green-100 text-green-700 rounded-full text-xs font-medium">Verified</span>
//                         )}
//                       </div>

//                       <div className="flex items-center space-x-4 text-sm text-gray-500">
//                         <span className="flex items-center">
//                           <MapPin className="w-4 h-4 mr-1" />
//                           {founder.location}
//                         </span>
//                         <span className="flex items-center">
//                           <Building className="w-4 h-4 mr-1" />
//                           {founder.companySize} employees
//                         </span>
//                         <span className="flex items-center">
//                           <Briefcase className="w-4 h-4 mr-1" />
//                           Seeking {founder.seeking}
//                         </span>
//                       </div>
//                     </div>
//                     <div className="flex items-center space-x-3">
//                       <button
//                         onClick={() => toggleTrackCompany(founder.id)}
//                         className={`px-4 py-2 rounded-lg flex items-center ${
//                           trackedCompanies.includes(founder.id) ? "bg-blue-50 text-blue-600" : "bg-gray-100 text-gray-700 hover:bg-gray-200"
//                         }`}
//                       >
//                         <BookmarkPlus className="w-4 h-4 mr-2" />
//                         {trackedCompanies.includes(founder.id) ? "Tracked" : "Track"}
//                       </button>
//                       <button
//                         onClick={() => {
//                           setSelectedFounder(founder);
//                           setShowIntroModal(true);
//                         }}
//                         className="px-4 py-2 bg-blue-600 text-white rounded-lg hover:bg-blue-700 flex items-center"
//                       >
//                         Request Intro
//                         <ArrowRight className="w-4 h-4 ml-2" />
//                       </button>
//                     </div>
//                   </div>

//                   <div className="grid md:grid-cols-2 gap-6">
//                     {/* Metrics */}
//                     <div className="p-4 bg-gray-50 rounded-xl">
//                       <div className="flex justify-between items-center mb-2">
//                         <div className="flex items-center">
//                           <Github className="w-5 h-5 text-gray-400 mr-2" />
//                           <span className="font-medium">{founder.metric}</span>
//                         </div>
//                         <div className="flex items-center text-green-600">
//                           <TrendingUp className="w-4 h-4 mr-1" />
//                           {founder.growth}
//                         </div>
//                       </div>
//                       <div className="text-2xl font-bold">{founder.current}</div>
//                     </div>

//                     {/* Video Update */}
//                     <div className="bg-gray-900 rounded-xl aspect-video relative overflow-hidden">
//                       <div className="absolute inset-0 flex items-center justify-center">
//                         <button className="w-12 h-12 bg-white/10 rounded-full flex items-center justify-center hover:bg-white/20">
//                           <Play className="w-6 h-6 text-white" />
//                         </button>
//                       </div>
//                       <div className="absolute bottom-3 left-3 right-3">
//                         <div className="text-white text-sm bg-black/50 px-3 py-1.5 rounded-lg inline-block">
//                           Weekly Update • {founder.lastUpdate}
//                         </div>
//                       </div>
//                     </div>
//                   </div>
//                 </div>
//               </div>
//             ))}
//           </div>
//         </div>
//       )}

//       {/* Intro Request Modal */}
//       {showIntroModal && selectedFounder && (
//         <div className="fixed inset-0 bg-black/50 backdrop-blur-sm flex items-center justify-center z-50">
//           <div className="bg-white rounded-xl shadow-lg max-w-lg w-full mx-4 p-6">
//             <div className="mb-6">
//               <h3 className="text-xl font-bold mb-2">Request Intro to {selectedFounder.name}</h3>
//               <p className="text-gray-600">Share why you'd like to connect with the founder.</p>
//             </div>

//             <div className="space-y-4 mb-6">
//               <div>
//                 <label className="block text-sm font-medium text-gray-700 mb-1">Investment Range</label>
//                 <select className="w-full p-2 border rounded-lg">
//                   <option>$1M - $5M</option>
//                   <option>$5M - $10M</option>
//                   <option>$10M+</option>
//                 </select>
//               </div>

//               <div>
//                 <label className="block text-sm font-medium text-gray-700 mb-1">Message to Founder</label>
//                 <textarea
//                   className="w-full p-3 border rounded-lg h-32"
//                   placeholder="What interests you about their startup? Why would you be a good investor partner?"
//                 />
//               </div>
//             </div>

//             <div className="flex space-x-3">
//               <button
//                 onClick={() => {
//                   addNotification("success", "Intro request sent! You'll be notified when the founder responds.");
//                   setShowIntroModal(false);
//                 }}
//                 className="flex-1 px-4 py-2 bg-blue-600 text-white rounded-lg hover:bg-blue-700"
//               >
//                 Send Request
//               </button>
//               <button onClick={() => setShowIntroModal(false)} className="flex-1 px-4 py-2 border rounded-lg hover:bg-gray-50">
//                 Cancel
//               </button>
//             </div>
//           </div>
//         </div>
//       )}

//       {/* Notifications */}
//       <div className="fixed bottom-4 right-4 space-y-2">
//         {notifications.map((notification) => (
//           <div key={notification.id} className="bg-white rounded-xl shadow-lg p-4 animate-slideIn max-w-md">
//             <div className="flex items-center justify-between">
//               <div className="flex items-center space-x-2">
//                 {notification.type === "success" && <Check className="w-5 h-5 text-green-600" />}
//                 <span className="text-sm">{notification.message}</span>
//               </div>
//               <button
//                 onClick={() => setNotifications((prev) => prev.filter((n) => n.id !== notification.id))}
//                 className="text-gray-400 hover:text-gray-600"
//               >
//                 <ChevronRight className="w-4 h-4" />
//               </button>
//             </div>
//           </div>
//         ))}
//       </div>
//     </div>
//   );
// };

// // Add animation keyframes for notifications
// const style = document.createElement("style");
// style.textContent = `
// @keyframes slideIn {
//   from {
//     opacity: 0;
//     transform: translateX(100%);
//   }
//   to {
//     opacity: 1;
//     transform: translateX(0);
//   }
// }

// .animate-slideIn {
//   animation: slideIn 0.3s ease-out;
// }
// `;
// document.head.appendChild(style);

// export default ProovVCFlow;

// import React, { useState, useEffect } from "react";
// import { TrendingUp, Github, DollarSign, Play, Star, Search, Zap, ArrowRight, Calendar } from "lucide-react";

// const VCFlow = () => {
//   const [step, setStep] = useState("login");
//   const [selectedStartup, setSelectedStartup] = useState(null);
//   const [showVideo, setShowVideo] = useState(false);

//   return (
//     <div className="min-h-screen bg-gray-50">
//       {/* Clean Nav */}
//       {step !== "login" && (
//         <nav className="bg-white/80 backdrop-blur-sm border-b fixed w-full z-50">
//           <div className="max-w-6xl mx-auto px-6 py-4 flex justify-between items-center">
//             <span className="text-xl font-bold">PROOV</span>
//             {step !== "onboarding" && (
//               <div className="flex items-center space-x-3">
//                 <div className="px-3 py-1 bg-blue-100 text-blue-700 rounded-full text-sm font-medium">Early Access</div>
//                 <div className="flex items-center space-x-2">
//                   <span className="text-sm text-gray-600">Sequoia Capital</span>
//                   <div className="h-8 w-8 bg-blue-100 rounded-full flex items-center justify-center text-blue-600 font-medium">S</div>
//                 </div>
//               </div>
//             )}
//           </div>
//         </nav>
//       )}

//       {/* Login */}
//       {step === "login" && (
//         <div className="min-h-screen flex flex-col">
//           <div className="flex-1 flex items-center justify-center p-6">
//             <div className="max-w-md w-full text-center">
//               <h1 className="text-4xl font-bold mb-4">Verified Deal Flow</h1>
//               <p className="text-xl text-gray-600 mb-12">See real startup growth. Make faster decisions.</p>

//               <button
//                 onClick={() => setStep("onboarding")}
//                 className="w-full h-12 bg-white border rounded-xl text-gray-700 font-medium flex items-center justify-center hover:bg-gray-50 relative group overflow-hidden"
//               >
//                 <div className="absolute inset-0 w-3 bg-gradient-to-r from-blue-400 to-blue-500 transition-all duration-[400ms] -left-full group-hover:left-full" />
//                 <span className="relative flex items-center">Continue with Google</span>
//               </button>
//             </div>
//           </div>
//         </div>
//       )}

//       {/* Quick Onboarding */}
//       {step === "onboarding" && (
//         <div className="pt-24 min-h-screen">
//           <div className="max-w-2xl mx-auto p-6">
//             <div className="text-center mb-12">
//               <h2 className="text-3xl font-bold mb-4">Welcome to PROOV</h2>
//               <p className="text-gray-600 text-lg">Let's personalize your deal flow</p>
//             </div>

//             <div className="space-y-8">
//               <div>
//                 <label className="block text-sm font-medium mb-4">What do you invest in?</label>
//                 <div className="grid grid-cols-2 gap-3">
//                   {[
//                     {
//                       title: "B2B/SaaS",
//                       desc: "Enterprise software, tools, platforms",
//                     },
//                     {
//                       title: "Developer Tools",
//                       desc: "APIs, infrastructure, dev platforms",
//                     },
//                     {
//                       title: "AI/ML",
//                       desc: "Machine learning, AI applications",
//                     },
//                     {
//                       title: "Fintech",
//                       desc: "Financial technology, crypto, DeFi",
//                     },
//                   ].map((focus) => (
//                     <button
//                       key={focus.title}
//                       className="p-4 border-2 border-transparent hover:border-blue-500 rounded-xl text-left transition-colors"
//                     >
//                       <h3 className="font-medium mb-1">{focus.title}</h3>
//                       <p className="text-sm text-gray-500">{focus.desc}</p>
//                     </button>
//                   ))}
//                 </div>
//               </div>

//               <div>
//                 <label className="block text-sm font-medium mb-4">Investment Range</label>
//                 <div className="grid grid-cols-4 gap-3">
//                   {["$100K-500K", "$500K-1M", "$1M-5M", "$5M+"].map((size) => (
//                     <button
//                       key={size}
//                       className="p-3 border-2 border-transparent hover:border-blue-500 rounded-xl text-center transition-colors"
//                     >
//                       {size}
//                     </button>
//                   ))}
//                 </div>
//               </div>

//               <button
//                 onClick={() => setStep("feed")}
//                 className="w-full py-3 bg-blue-600 text-white rounded-xl hover:bg-blue-700 flex items-center justify-center group"
//               >
//                 <span>See Verified Startups</span>
//                 <ArrowRight className="w-5 h-5 ml-2 group-hover:translate-x-1 transition-transform" />
//               </button>
//             </div>
//           </div>
//         </div>
//       )}

//       {/* Main Feed */}
//       {step === "feed" && (
//         <div className="pt-24 pb-12">
//           <div className="max-w-6xl mx-auto px-6">
//             {/* Smart Search */}
//             <div className="bg-white rounded-xl shadow-sm p-6 mb-8">
//               <div className="max-w-2xl">
//                 <div className="relative">
//                   <Search className="w-5 h-5 text-gray-400 absolute left-4 top-3.5" />
//                   <input
//                     type="text"
//                     placeholder="Find startups by metrics, tech stack, or growth..."
//                     className="w-full pl-12 pr-4 py-3 bg-gray-50 rounded-xl text-lg"
//                   />
//                 </div>

//                 <div className="mt-4 flex items-center space-x-2 overflow-x-auto pb-2">
//                   <button className="px-3 py-1.5 bg-blue-50 text-blue-600 rounded-lg text-sm whitespace-nowrap hover:bg-blue-100">
//                     +40% MoM Growth
//                   </button>
//                   <button className="px-3 py-1.5 bg-blue-50 text-blue-600 rounded-lg text-sm whitespace-nowrap hover:bg-blue-100">
//                     $100K+ MRR
//                   </button>
//                   <button className="px-3 py-1.5 bg-green-50 text-green-600 rounded-lg text-sm whitespace-nowrap hover:bg-green-100">
//                     High GitHub Activity
//                   </button>
//                   <button className="px-3 py-1.5 bg-purple-50 text-purple-600 rounded-lg text-sm whitespace-nowrap hover:bg-purple-100">
//                     AI/ML Startups
//                   </button>
//                 </div>
//               </div>
//             </div>

//             {/* Growth Feed */}
//             <div className="grid grid-cols-2 gap-6">
//               {Array(6)
//                 .fill(0)
//                 .map((_, i) => (
//                   <StartupCard key={i} onClick={() => setSelectedStartup(true)} onPlay={() => setShowVideo(true)} />
//                 ))}
//             </div>
//           </div>
//         </div>
//       )}

//       {/* Startup Modal */}
//       {selectedStartup && <StartupProfile onClose={() => setSelectedStartup(null)} />}

//       {/* Video Modal */}
//       {showVideo && <VideoUpdate onClose={() => setShowVideo(false)} />}
//     </div>
//   );
// };

// const StartupCard = ({ onClick, onPlay }) => {
//   // Simulated growth data
//   const growth = Array(12)
//     .fill(0)
//     .map(() => Math.floor(Math.random() * 100));

//   return (
//     <div onClick={onClick} className="bg-white rounded-xl shadow-sm hover:shadow-md transition-shadow cursor-pointer group">
//       <div className="p-6">
//         <div className="flex justify-between items-start mb-6">
//           <div>
//             <div className="flex items-center space-x-2 mb-1">
//               <h3 className="font-medium">B2B AI Platform</h3>
//               <span className="px-2 py-0.5 bg-green-100 text-green-700 rounded-full text-xs">Verified</span>
//             </div>
//             <p className="text-sm text-gray-600">AI-powered workflow automation</p>
//           </div>
//           <button className="text-gray-400 hover:text-amber-500">
//             <Star className="w-5 h-5" />
//           </button>
//         </div>

//         {/* Growth Chart */}
//         <div className="h-24 flex items-end mb-6 space-x-1">
//           {growth.map((h, i) => (
//             <div key={i} className="flex-1 bg-blue-100 rounded-t transition-all group-hover:bg-blue-200" style={{ height: `${h}%` }} />
//           ))}
//         </div>

//         <div className="grid grid-cols-3 gap-4 mb-6">
//           <MetricPill icon={<DollarSign />} label="MRR" value="$125K" change="+42%" />
//           <MetricPill icon={<TrendingUp />} label="Users" value="1,247" change="+38%" />
//           <MetricPill icon={<Github />} label="Activity" value="47" change="+12%" />
//         </div>

//         <div className="flex items-center justify-between">
//           <button
//             onClick={(e) => {
//               e.stopPropagation();
//               onPlay();
//             }}
//             className="flex items-center space-x-2 text-blue-600 hover:text-blue-700"
//           >
//             <Play className="w-4 h-4" />
//             <span className="text-sm">Watch Update</span>
//           </button>

//           <button className="px-4 py-2 bg-blue-600 text-white rounded-lg hover:bg-blue-700">Request Intro</button>
//         </div>
//       </div>
//     </div>
//   );
// };

// const MetricPill = ({ icon, label, value, change }) => (
//   <div className="text-center">
//     <div className="flex items-center justify-center text-gray-400 mb-1">
//       {React.cloneElement(icon, { className: "w-4 h-4 mr-1" })}
//       <span className="text-xs">{label}</span>
//     </div>
//     <div className="font-medium">{value}</div>
//     <div className="text-xs text-green-600">{change}</div>
//   </div>
// );

// const StartupProfile = ({ onClose }) => (
//   <div className="fixed inset-0 bg-black/50 z-50 overflow-y-auto">
//     <div className="min-h-screen px-4 pt-24">
//       <div className="max-w-4xl mx-auto bg-white rounded-xl shadow-lg">
//         <div className="p-6 flex justify-between items-start border-b">
//           <div>
//             <div className="flex items-center space-x-3 mb-1">
//               <h2 className="text-xl font-bold">B2B AI Platform</h2>
//               <span className="px-2 py-0.5 bg-green-100 text-green-700 rounded-full text-xs font-medium">Verified Growth</span>
//             </div>
//             <p className="text-gray-600">AI-powered workflow automation</p>
//           </div>
//           <button onClick={onClose} className="text-gray-400 hover:text-gray-600">
//             Close
//           </button>
//         </div>

//         <div className="p-6">
//           {/* Growth Dashboard */}
//           <div className="grid grid-cols-3 gap-6 mb-8">
//             <MetricCard title="Monthly Revenue" value="$125K" change="+42%" trend="up" />
//             <MetricCard title="Active Users" value="1,247" change="+38%" trend="up" />
//             <MetricCard title="GitHub Activity" value="47 commits" change="+12%" trend="up" />
//           </div>

//           {/* Weekly Updates */}
//           <div className="space-y-4">
//             <h3 className="font-medium">Weekly Updates</h3>
//             <div className="space-y-3">
//               {[0, 1, 2].map((i) => (
//                 <div key={i} className="p-4 bg-gray-50 rounded-xl">
//                   <div className="flex items-center justify-between mb-3">
//                     <div>
//                       <h4 className="font-medium">Week {23 - i}</h4>
//                       <p className="text-sm text-gray-500">{i === 0 ? "2 days ago" : i === 1 ? "9 days ago" : "16 days ago"}</p>
//                     </div>
//                     <button className="flex items-center space-x-2 text-blue-600 hover:text-blue-700">
//                       <Play className="w-4 h-4" />
//                       <span className="text-sm">Watch</span>
//                     </button>
//                   </div>

//                   <div className="grid grid-cols-3 gap-4">
//                     <div className="text-center">
//                       <div className="text-sm text-gray-500 mb-1">MRR</div>
//                       <div className="font-medium">${125 - i * 10}K</div>
//                     </div>
//                     <div className="text-center">
//                       <div className="text-sm text-gray-500 mb-1">Users</div>
//                       <div className="font-medium">{47 - i * 5}</div>
//                     </div>
//                   </div>
//                 </div>
//               ))}
//             </div>
//           </div>

//           {/* Quick Actions */}
//           <div className="mt-8 flex space-x-4">
//             <button className="flex-1 py-2 bg-blue-600 text-white rounded-lg hover:bg-blue-700 flex items-center justify-center">
//               <Calendar className="w-4 h-4 mr-2" />
//               Request Intro
//             </button>
//             <button className="flex-1 py-2 border rounded-lg hover:bg-gray-50 flex items-center justify-center">
//               <Star className="w-4 h-4 mr-2" />
//               Save Profile
//             </button>
//           </div>
//         </div>
//       </div>
//     </div>
//   </div>
// );

// const MetricCard = ({ title, value, change, trend }) => (
//   <div className="p-4 bg-gray-50 rounded-xl">
//     <h4 className="text-sm text-gray-500 mb-1">{title}</h4>
//     <div className="text-2xl font-bold mb-1">{value}</div>
//     <div className={`text-sm ${trend === "up" ? "text-green-600" : "text-red-600"}`}>{change}</div>
//   </div>
// );

// const VideoUpdate = ({ onClose }) => (
//   <div className="fixed inset-0 bg-black/90 z-50 flex items-center justify-center">
//     <div className="max-w-4xl w-full mx-auto p-6">
//       <div className="flex justify-between items-start mb-6">
//         <div className="text-white">
//           <h3 className="font-medium">Week 23 Update</h3>
//           <p className="text-sm text-gray-400">2 days ago</p>
//         </div>
//         <button onClick={onClose} className="text-white/50 hover:text-white">
//           Close
//         </button>
//       </div>

//       <div className="aspect-video bg-gray-800 rounded-xl flex items-center justify-center">
//         <Play className="w-16 h-16 text-white/50" />
//       </div>

//       <div className="mt-6 bg-white/10 rounded-xl p-4">
//         <div className="grid grid-cols-3 gap-4">
//           <div className="text-center">
//             <div className="text-white/50 text-sm mb-1">MRR</div>
//             <div className="text-white font-medium">$125K</div>
//             <div className="text-green-400 text-sm">+42%</div>
//           </div>
//           <div className="text-center">
//             <div className="text-white/50 text-sm mb-1">Users</div>
//             <div className="text-white font-medium">1,247</div>
//             <div className="text-green-400 text-sm">+38%</div>
//           </div>
//           <div className="text-center">
//             <div className="text-white/50 text-sm mb-1">Activity</div>
//             <div className="text-white font-medium">47 commits</div>
//             <div className="text-green-400 text-sm">+12%</div>
//           </div>
//         </div>
//       </div>
//     </div>
//   </div>
// );

// export default VCFlow;

// import React, { useState, useEffect } from "react";
// import {
//   TrendingUp,
//   Star,
//   Play,
//   ChevronRight,
//   ArrowRight,
//   Filter,
//   Clock,
//   Building,
//   BookmarkPlus,
//   Bell,
//   Mail,
//   Search,
//   MapPin,
//   Github,
//   Plus,
//   Check,
// } from "lucide-react";

// const ProovFlow = () => {
//   // Core state management
//   const [currentStep, setCurrentStep] = useState("auth");
//   const [user, setUser] = useState(null);
//   const [loading, setLoading] = useState(false);
//   const [notifications, setNotifications] = useState([]);
//   const [trackedStartups, setTrackedStartups] = useState([]);
//   const [selectedStartup, setSelectedStartup] = useState(null);
//   const [showIntroModal, setShowIntroModal] = useState(false);
//   const [activeFilter, setActiveFilter] = useState("All");

//   // Mock data
//   const mockStartups = [
//     {
//       id: 1,
//       industry: "AI/ML",
//       focus: "Developer Productivity",
//       description: "Building next-gen AI tools for engineering teams",
//       metric: "Weekly Active Users",
//       current: "47,892",
//       growth: "+32%",
//       lastUpdate: "2h ago",
//       status: "Actively fundraising",
//       stats: {
//         mrr: "$124K",
//         growth: "32% MoM",
//         retention: "94%",
//       },
//       fundingStage: "Seed",
//       location: "SF Bay Area",
//       seeking: "$5M",
//       //   team: "Ex-Google, Stanford",
//       highlights: ["3x MoM growth", "95% gross margin", "250+ waitlist"],
//     },
//     {
//       id: 2,
//       industry: "FinTech",
//       focus: "Payment Infrastructure",
//       description: "Real-time payment settlement for global commerce",
//       metric: "Processing Volume",
//       current: "$12.4M",
//       growth: "+28%",
//       lastUpdate: "5h ago",
//       status: "Term sheet stage",
//       stats: {
//         mrr: "$280K",
//         growth: "28% MoM",
//         retention: "96%",
//       },
//       fundingStage: "Series A",
//       location: "NYC",
//       seeking: "$15M",
//       //   team: "Ex-Stripe, MIT",
//       highlights: ["$150M ARR", "40% QoQ growth", "Enterprise ready"],
//     },
//   ];

//   // Auth functions
//   const handleGoogleAuth = async () => {
//     setLoading(true);
//     try {
//       await new Promise((resolve) => setTimeout(resolve, 1500));
//       setUser({
//         name: "John Doe",
//         email: "john@vc.com",
//         firm: "Accel",
//         avatar: "JD",
//       });
//       setCurrentStep("feed");
//       addNotification("success", "Welcome to Proov! Your VC status has been verified.");
//     } catch (error) {
//       addNotification("error", "Authentication failed. Please try again.");
//     } finally {
//       setLoading(false);
//     }
//   };

//   // Notification management
//   const addNotification = (type, message) => {
//     const notification = { id: Date.now(), type, message };
//     setNotifications((prev) => [...prev, notification]);
//     setTimeout(() => {
//       setNotifications((prev) => prev.filter((n) => n.id !== notification.id));
//     }, 5000);
//   };

//   // Startup tracking
//   const toggleTrackStartup = (startupId) => {
//     setTrackedStartups((prev) => {
//       const isTracked = prev.includes(startupId);
//       const message = isTracked ? "Startup removed from tracking." : "Startup added to tracking. You'll receive weekly updates.";

//       addNotification("success", message);
//       return isTracked ? prev.filter((id) => id !== startupId) : [...prev, startupId];
//     });
//   };

//   // Intro request handling
//   const handleIntroRequest = (startup, data) => {
//     addNotification("success", `Intro request sent to ${startup.focus}. They typically respond within 48 hours.`);
//     setShowIntroModal(false);
//   };

//   // Auth Screen
//   if (currentStep === "auth") {
//     return (
//       <div className="min-h-screen bg-gray-50 flex items-center justify-center">
//         <div className="max-w-md w-full px-6">
//           <div className="text-center mb-8">
//             <h1 className="text-3xl font-bold mb-2 bg-gradient-to-r from-blue-600 to-indigo-600 bg-clip-text text-transparent">proov</h1>
//             <p className="text-gray-600">Verified deal flow for professional investors</p>
//           </div>

//           <div className="bg-white rounded-2xl shadow-sm p-6">
//             <div className="space-y-4">
//               <button
//                 onClick={handleGoogleAuth}
//                 disabled={loading}
//                 className="w-full h-12 bg-white border rounded-xl text-gray-700 font-medium flex items-center justify-center hover:bg-gray-50 transition-all relative overflow-hidden group disabled:opacity-50"
//               >
//                 {loading ? (
//                   <div className="w-5 h-5 border-2 border-gray-600 border-t-transparent rounded-full animate-spin" />
//                 ) : (
//                   <>
//                     <span className="absolute inset-0 w-3 bg-gradient-to-r from-blue-400 to-blue-500 transition-all duration-[400ms] -left-full group-hover:left-full" />
//                     <span className="relative flex items-center">Continue with Google</span>
//                   </>
//                 )}
//               </button>

//               <div className="text-sm text-gray-500 text-center">Verified VCs only. We check your work email.</div>
//             </div>
//           </div>
//         </div>
//       </div>
//     );
//   }

//   // Main Feed
//   return (
//     <div className="min-h-screen bg-gray-50">
//       {/* Navigation */}
//       <nav className="bg-white border-b fixed w-full z-40">
//         <div className="max-w-6xl mx-auto px-6 py-4">
//           <div className="flex items-center justify-between">
//             <div className="flex items-center space-x-12">
//               <div className="text-xl font-bold bg-gradient-to-r from-blue-600 to-indigo-600 bg-clip-text text-transparent">proov</div>
//               <div className="flex space-x-8 text-sm">
//                 <a href="#" className="text-gray-900 font-medium">
//                   Feed
//                 </a>
//                 <a href="#" className="text-gray-500 hover:text-gray-900">
//                   Tracked ({trackedStartups.length})
//                 </a>
//                 <a href="#" className="text-gray-500 hover:text-gray-900">
//                   Analytics
//                 </a>
//               </div>
//             </div>
//             <div className="flex items-center space-x-4">
//               <button className="p-2 text-gray-400 hover:text-gray-600 relative">
//                 <Bell className="w-5 h-5" />
//                 {notifications.length > 0 && <span className="absolute top-0 right-0 w-2 h-2 bg-blue-600 rounded-full" />}
//               </button>
//               <div className="h-8 w-8 bg-blue-100 rounded-full flex items-center justify-center">
//                 <span className="text-sm font-medium text-blue-600">{user?.avatar}</span>
//               </div>
//             </div>
//           </div>
//         </div>
//       </nav>

//       {/* Main Content */}
//       <main className="pt-24 pb-12 max-w-6xl mx-auto px-6">
//         <div className="flex items-center justify-between mb-8">
//           <div>
//             <h1 className="text-2xl font-bold text-gray-900">Startup Feed</h1>
//             <p className="text-gray-500">Track high-growth companies raising capital</p>
//           </div>
//           <div className="flex items-center space-x-3">
//             <button className="px-4 py-2 bg-white border rounded-xl text-gray-700 hover:bg-gray-50 flex items-center">
//               <Filter className="w-4 h-4 mr-2" />
//               Filter
//             </button>
//             <button className="px-4 py-2 bg-white border rounded-xl text-gray-700 hover:bg-gray-50 flex items-center">
//               <Clock className="w-4 h-4 mr-2" />
//               Recent
//             </button>
//           </div>
//         </div>

//         <div className="flex items-center space-x-3 mb-8 overflow-x-auto pb-2">
//           {["All", "AI/ML", "FinTech", "Enterprise", "Consumer", "Healthcare"].map((filter) => (
//             <button
//               key={filter}
//               onClick={() => setActiveFilter(filter)}
//               className={`px-4 py-2 rounded-xl text-sm whitespace-nowrap ${
//                 filter === activeFilter ? "bg-blue-600 text-white" : "bg-white border text-gray-700 hover:bg-gray-50"
//               }`}
//             >
//               {filter}
//             </button>
//           ))}
//         </div>

//         <div className="grid gap-6">
//           {mockStartups.map((startup) => (
//             <div key={startup.id} className="bg-white rounded-2xl shadow-sm hover:shadow-md transition-all">
//               <div className="p-6">
//                 <div className="flex items-start justify-between mb-6">
//                   <div className="space-y-3">
//                     <div className="flex items-center space-x-3 mb-2">
//                       <span className="px-3 py-1 bg-blue-50 text-blue-700 rounded-lg text-sm font-medium">{startup.industry}</span>
//                       <div className="h-1 w-1 bg-gray-300 rounded-full" />
//                       <span className="text-gray-500">{startup.focus}</span>
//                     </div>

//                     <p className="text-gray-600">{startup.description}</p>

//                     <div className="flex items-center space-x-6 text-sm">
//                       <span className="flex items-center text-gray-500">
//                         <MapPin className="w-4 h-4 mr-1.5" />
//                         {startup.location}
//                       </span>
//                       <span className="text-gray-500">
//                         {startup.fundingStage} • Seeking {startup.seeking}
//                       </span>
//                       <span className="text-gray-500">{startup.team}</span>
//                     </div>

//                     {/* <div className="flex items-center space-x-2">
//                       {startup.highlights.map((highlight, index) => (
//                         <span key={index} className="px-2 py-1 bg-gray-100 text-gray-600 rounded text-sm">
//                           {highlight}
//                         </span>
//                       ))}
//                     </div> */}
//                   </div>

//                   <div className="flex items-center space-x-3">
//                     <button
//                       onClick={() => toggleTrackStartup(startup.id)}
//                       className={`px-4 py-2 rounded-xl flex items-center ${
//                         trackedStartups.includes(startup.id) ? "bg-blue-50 text-blue-600" : "bg-gray-50 text-gray-700 hover:bg-gray-100"
//                       }`}
//                     >
//                       <BookmarkPlus className="w-4 h-4 mr-2" />
//                       {trackedStartups.includes(startup.id) ? "Tracked" : "Track"}
//                     </button>
//                     <button
//                       onClick={() => {
//                         setSelectedStartup(startup);
//                         setShowIntroModal(true);
//                       }}
//                       className="px-4 py-2 bg-blue-600 text-white rounded-xl hover:bg-blue-700 flex items-center"
//                     >
//                       Connect
//                       <ArrowRight className="w-4 h-4 ml-2" />
//                     </button>
//                   </div>
//                 </div>

//                 <div className="grid md:grid-cols-2 gap-6">
//                   <div className="space-y-6">
//                     <div className="p-4 bg-gray-50 rounded-xl">
//                       <div className="flex justify-between items-center mb-2">
//                         <span className="font-medium">{startup.metric}</span>
//                         <div className="flex items-center text-green-600">
//                           <TrendingUp className="w-4 h-4 mr-1" />
//                           {startup.growth}
//                         </div>
//                       </div>
//                       <div className="text-2xl font-bold">{startup.current}</div>
//                     </div>

//                     {/* <div className="grid grid-cols-3 gap-4">
//                       <div className="p-3 bg-gray-50 rounded-xl">
//                         <div className="text-sm text-gray-500 mb-1">MRR</div>
//                         <div className="font-bold">{startup.stats.mrr}</div>
//                       </div>
//                       <div className="p-3 bg-gray-50 rounded-xl">
//                         <div className="text-sm text-gray-500 mb-1">Growth</div>
//                         <div className="font-bold">{startup.stats.growth}</div>
//                       </div>
//                       <div className="p-3 bg-gray-50 rounded-xl">
//                         <div className="text-sm text-gray-500 mb-1">Retention</div>
//                         <div className="font-bold">{startup.stats.retention}</div>
//                       </div>
//                     </div> */}
//                   </div>

//                   <div className="bg-gray-900 rounded-xl aspect-video relative overflow-hidden group">
//                     <div className="absolute inset-0 flex items-center justify-center">
//                       <button className="w-14 h-14 bg-white/10 rounded-full flex items-center justify-center group-hover:bg-white/20 transition-all">
//                         <Play className="w-6 h-6 text-white" />
//                       </button>
//                     </div>
//                     <div className="absolute inset-x-0 bottom-0 bg-gradient-to-t from-black/60 to-transparent p-4">
//                       <div className="text-white space-y-1">
//                         <div className="text-sm font-medium">Weekly Update</div>
//                         <div className="text-sm text-gray-300 flex items-center">
//                           <Clock className="w-4 h-4 mr-2" />
//                           {startup.lastUpdate}
//                         </div>
//                       </div>
//                     </div>
//                   </div>
//                 </div>
//               </div>
//             </div>
//           ))}
//         </div>
//       </main>

//       {/* Intro Request Modal */}
//       {showIntroModal && selectedStartup && (
//         <div className="fixed inset-0 bg-black/50 backdrop-blur-sm flex items-center justify-center z-50">
//           <div className="bg-white rounded-xl shadow-lg max-w-lg w-full mx-4 p-6">
//             <div className="mb-6">
//               <h3 className="text-xl font-bold mb-2">Connect with {selectedStartup.focus}</h3>
//               <p className="text-gray-600">Share your interest and investment thesis.</p>
//             </div>

//             <div className="space-y-4 mb-6">
//               <div>
//                 <label className="block text-sm font-medium text-gray-700 mb-1">Investment Range</label>
//                 <select className="w-full p-2 border rounded-lg">
//                   <option>$1M - $5M</option>
//                   <option>$5M - $10M</option>
//                   <option>$10M+</option>
//                 </select>
//               </div>

//               <div>
//                 <label className="block text-sm font-medium text-gray-700 mb-1">Message</label>
//                 <textarea
//                   className="w-full p-3 border rounded-lg h-32"
//                   placeholder="What interests you about their startup? Why would you be a good investor partner?"
//                 />
//               </div>
//             </div>

//             <div className="flex space-x-3">
//               <button
//                 onClick={() => handleIntroRequest(selectedStartup, {})}
//                 className="flex-1 px-4 py-2 bg-blue-600 text-white rounded-lg hover:bg-blue-700"
//               >
//                 Send Request
//               </button>
//               <button onClick={() => setShowIntroModal(false)} className="flex-1 px-4 py-2 border rounded-lg hover:bg-gray-50">
//                 Cancel
//               </button>
//             </div>
//           </div>
//         </div>
//       )}

//       {/* Notifications */}
//       <div className="fixed bottom-4 right-4 space-y-2 z-50">
//         {notifications.map((notification) => (
//           <div key={notification.id} className="bg-white rounded-xl shadow-lg p-4 animate-slideIn max-w-md">
//             <div className="flex items-center justify-between">
//               <div className="flex items-center space-x-2">
//                 <Check className="w-5 h-5 text-green-600" />
//                 <span className="text-sm">{notification.message}</span>
//               </div>
//               <button
//                 onClick={() => setNotifications((prev) => prev.filter((n) => n.id !== notification.id))}
//                 className="text-gray-400 hover:text-gray-600"
//               >
//                 <ChevronRight className="w-4 h-4" />
//               </button>
//             </div>
//           </div>
//         ))}
//       </div>
//     </div>
//   );
// };

// // Add keyframe animations for notifications
// const style = document.createElement("style");
// style.textContent = `
//   @keyframes slideIn {
//     from {
//       opacity: 0;
//       transform: translateX(100%);
//     }
//     to {
//       opacity: 1;
//       transform: translateX(0);
//     }
//   }

//   .animate-slideIn {
//     animation: slideIn 0.3s ease-out;
//   }
// `;
// document.head.appendChild(style);

// export default ProovFlow;

// import React, { useState } from "react";
// import {
//   ArrowUpRight,
//   TrendingUp,
//   Star,
//   Play,
//   Check,
//   Search,
//   BookmarkPlus,
//   Filter,
//   Calendar,
//   Building,
//   MapPin,
//   LineChart,
//   Bell,
// } from "lucide-react";

// const InvestorFlow = () => {
//   const [step, setStep] = useState("verify"); // verify, onboard, feed
//   const [loading, setLoading] = useState(false);
//   const [trackedCompanies, setTrackedCompanies] = useState([]);
//   const [showRequestModal, setShowRequestModal] = useState(false);
//   const [selectedCompany, setSelectedCompany] = useState(null);

//   const mockCompanies = [
//     {
//       id: 1,
//       metric: "Weekly Active Users",
//       current: "47,892",
//       growth: "+32%",
//       focus: "AI/ML",
//       status: "Raising Seed",
//       seeking: "$2-5M",
//       location: "SF Bay Area",
//       traction: [
//         { label: "MRR", value: "$124K" },
//         { label: "Growth", value: "32% MoM" },
//         { label: "Retention", value: "94%" },
//       ],
//       verifiedStreak: 12,
//       lastUpdate: "2h ago",
//     },
//     {
//       id: 2,
//       metric: "Processing Volume",
//       current: "$12.4M",
//       growth: "+28%",
//       focus: "FinTech",
//       status: "Series A",
//       seeking: "$8-12M",
//       location: "NYC",
//       traction: [
//         { label: "MRR", value: "$280K" },
//         { label: "Growth", value: "28% MoM" },
//         { label: "Retention", value: "96%" },
//       ],
//       verifiedStreak: 8,
//       lastUpdate: "5h ago",
//     },
//   ];

//   const handleGoogleAuth = async () => {
//     setLoading(true);
//     await new Promise((r) => setTimeout(r, 1500));
//     setStep("onboard");
//     setLoading(false);
//   };

//   const handleProfileComplete = () => {
//     setStep("feed");
//   };

//   const toggleTrack = (id) => {
//     setTrackedCompanies((prev) => (prev.includes(id) ? prev.filter((i) => i !== id) : [...prev, id]));
//   };

//   // Google Sign-in & Verification
//   if (step === "verify") {
//     return (
//       <div className="h-screen bg-black text-white flex items-center justify-center p-6">
//         <div className="max-w-md w-full space-y-8">
//           <div className="text-center">
//             <h1 className="text-3xl font-medium mb-3 bg-gradient-to-r from-blue-400 to-indigo-400 bg-clip-text text-transparent">proov</h1>
//             <p className="text-white/60">Verified deal flow for professional investors</p>
//           </div>

//           <button
//             onClick={handleGoogleAuth}
//             disabled={loading}
//             className="w-full h-12 bg-white/5 rounded-xl flex items-center justify-center text-sm font-medium hover:bg-white/10 transition-all relative overflow-hidden disabled:opacity-50"
//           >
//             {loading ? (
//               <div className="w-5 h-5 border-2 border-white/60 border-t-transparent rounded-full animate-spin" />
//             ) : (
//               "Continue with Google"
//             )}
//           </button>

//           <p className="text-sm text-white/40 text-center">Verified VCs only. We check your work email.</p>
//         </div>
//       </div>
//     );
//   }

//   // Profile Setup
//   if (step === "onboard") {
//     return (
//       <div className="min-h-screen bg-black text-white p-6">
//         <div className="max-w-xl mx-auto pt-12">
//           <div className="mb-12">
//             <h1 className="text-3xl font-medium mb-3">Complete Your Profile</h1>
//             <p className="text-white/60">Help us show you relevant opportunities</p>
//           </div>

//           <div className="bg-white/5 rounded-2xl p-6 space-y-6">
//             <div>
//               <label className="block text-sm font-medium mb-2">Investment Stage</label>
//               <div className="grid grid-cols-2 gap-3">
//                 {["Pre-seed", "Seed", "Series A", "Series B+"].map((stage) => (
//                   <button key={stage} className="h-12 bg-white/5 rounded-xl hover:bg-white/10 text-sm font-medium">
//                     {stage}
//                   </button>
//                 ))}
//               </div>
//             </div>

//             <div>
//               <label className="block text-sm font-medium mb-2">Check Size</label>
//               <select className="w-full h-12 bg-black/20 rounded-xl px-4 border border-white/10">
//                 <option>$100k - $500k</option>
//                 <option>$500k - $1M</option>
//                 <option>$1M - $5M</option>
//                 <option>$5M+</option>
//               </select>
//             </div>

//             <div>
//               <label className="block text-sm font-medium mb-2">Focus Areas</label>
//               <div className="flex flex-wrap gap-2">
//                 {["AI/ML", "FinTech", "DevTools", "Enterprise", "Consumer"].map((area) => (
//                   <button key={area} className="px-4 h-8 bg-white/5 rounded-full text-sm hover:bg-white/10">
//                     {area}
//                   </button>
//                 ))}
//               </div>
//             </div>

//             <button onClick={handleProfileComplete} className="w-full h-12 bg-blue-500 rounded-xl font-medium hover:bg-blue-600">
//               Start Exploring
//             </button>
//           </div>
//         </div>
//       </div>
//     );
//   }

//   // Main Feed
//   return (
//     <div className="min-h-screen bg-black text-white">
//       <nav className="fixed top-0 inset-x-0 bg-black/50 backdrop-blur-xl z-50 border-b border-white/10">
//         <div className="max-w-6xl mx-auto px-6">
//           <div className="h-16 flex items-center justify-between">
//             <div className="text-2xl font-medium bg-gradient-to-r from-blue-400 to-indigo-400 bg-clip-text text-transparent">proov</div>

//             <div className="flex items-center space-x-6">
//               <div className="flex items-center space-x-1">
//                 <button className="h-8 px-4 rounded-full bg-white/5 hover:bg-white/10 text-sm font-medium">Feed</button>
//                 <button className="h-8 px-4 rounded-full hover:bg-white/5 text-sm font-medium text-white/60">
//                   Tracked ({trackedCompanies.length})
//                 </button>
//               </div>

//               <div className="h-8 w-8 rounded-full bg-white/5 flex items-center justify-center">
//                 <span className="text-sm font-medium">S</span>
//               </div>
//             </div>
//           </div>
//         </div>
//       </nav>

//       <main className="pt-24 pb-12 max-w-6xl mx-auto px-6">
//         <div className="flex items-center justify-between mb-8">
//           <div>
//             <h1 className="text-2xl font-medium mb-1">Weekly Updates</h1>
//             <p className="text-white/60">Verified metrics from high-growth companies</p>
//           </div>

//           <div className="flex items-center space-x-3">
//             <button className="h-10 px-4 bg-white/5 rounded-xl hover:bg-white/10 text-sm font-medium flex items-center">
//               <Filter className="w-4 h-4 mr-2" />
//               Filter
//             </button>
//             <button className="h-10 px-4 bg-white/5 rounded-xl hover:bg-white/10 text-sm font-medium flex items-center">
//               <Calendar className="w-4 h-4 mr-2" />
//               This Week
//             </button>
//           </div>
//         </div>

//         <div className="flex items-center space-x-3 mb-8 overflow-x-auto pb-2">
//           {["All", "AI/ML", "FinTech", "Enterprise", "DevTools"].map((filter) => (
//             <button
//               key={filter}
//               className={`h-9 px-5 rounded-full text-sm font-medium whitespace-nowrap ${
//                 filter === "All" ? "bg-white text-black" : "bg-white/5 text-white/80 hover:bg-white/10"
//               }`}
//             >
//               {filter}
//             </button>
//           ))}
//         </div>

//         <div className="grid gap-6">
//           {mockCompanies.map((company) => (
//             <div key={company.id} className="bg-white/5 rounded-2xl p-6 hover:bg-white/[0.07] transition-all group">
//               <div className="flex items-start justify-between mb-6">
//                 <div className="space-y-2">
//                   <div className="flex items-center space-x-3">
//                     <span className="px-3 py-1 bg-blue-500/10 text-blue-400 rounded-full text-sm font-medium">{company.focus}</span>
//                     <div className="flex items-center text-white/40 text-sm">
//                       <span>{company.status}</span>
//                       <div className="w-1 h-1 bg-white/20 rounded-full mx-2" />
//                       <span>Seeking {company.seeking}</span>
//                     </div>
//                   </div>

//                   <div className="flex items-center space-x-4 text-sm text-white/40">
//                     <span className="flex items-center">
//                       <MapPin className="w-4 h-4 mr-1.5" />
//                       {company.location}
//                     </span>
//                     <span className="flex items-center">
//                       <Check className="w-4 h-4 mr-1.5 text-green-400" />
//                       {company.verifiedStreak} week streak
//                     </span>
//                   </div>
//                 </div>

//                 <div className="flex items-center space-x-3">
//                   <button
//                     onClick={() => toggleTrack(company.id)}
//                     className={`h-10 px-4 rounded-xl flex items-center text-sm font-medium ${
//                       trackedCompanies.includes(company.id) ? "bg-blue-500/10 text-blue-400" : "bg-white/5 hover:bg-white/10"
//                     }`}
//                   >
//                     <BookmarkPlus className="w-4 h-4 mr-2" />
//                     {trackedCompanies.includes(company.id) ? "Tracked" : "Track"}
//                   </button>

//                   <button
//                     onClick={() => {
//                       setSelectedCompany(company);
//                       setShowRequestModal(true);
//                     }}
//                     className="h-10 px-4 bg-blue-500 rounded-xl text-sm font-medium hover:bg-blue-600 flex items-center"
//                   >
//                     Connect
//                     <ArrowUpRight className="w-4 h-4 ml-2" />
//                   </button>
//                 </div>
//               </div>

//               <div className="grid md:grid-cols-2 gap-6">
//                 <div className="space-y-6">
//                   <div className="p-4 bg-white/5 rounded-xl">
//                     <div className="flex justify-between items-center mb-2">
//                       <span className="text-white/60">{company.metric}</span>
//                       <div className="flex items-center text-green-400">
//                         <TrendingUp className="w-4 h-4 mr-1" />
//                         {company.growth}
//                       </div>
//                     </div>
//                     <div className="text-2xl font-medium">{company.current}</div>
//                   </div>

//                   <div className="grid grid-cols-3 gap-4">
//                     {company.traction.map((metric, idx) => (
//                       <div key={idx} className="p-3 bg-white/5 rounded-xl">
//                         <div className="text-sm text-white/40 mb-1">{metric.label}</div>
//                         <div className="font-medium">{metric.value}</div>
//                       </div>
//                     ))}
//                   </div>
//                 </div>

//                 <div className="aspect-[4/3] rounded-xl overflow-hidden relative group">
//                   <div className="absolute inset-0 bg-gradient-to-b from-black/20 to-black/60" />
//                   <div className="absolute inset-0 flex items-center justify-center">
//                     <button className="w-16 h-16 rounded-full bg-white/10 flex items-center justify-center group-hover:bg-white/20 transition-all">
//                       <Play className="w-6 h-6 text-white" />
//                     </button>
//                   </div>
//                   <div className="absolute inset-x-0 bottom-0 p-4">
//                     <div className="flex items-center justify-between">
//                       <span className="text-sm text-white/60">Weekly Update • {company.lastUpdate}</span>
//                     </div>
//                   </div>
//                 </div>
//               </div>
//             </div>
//           ))}
//         </div>
//       </main>

//       {/* Connection Request Modal */}
//       {showRequestModal && selectedCompany && (
//         <div className="fixed inset-0 bg-black/80 backdrop-blur-sm flex items-center justify-center z-50 p-6">
//           <div className="bg-white/10 rounded-2xl max-w-lg w-full p-6">
//             <div className="mb-6">
//               <h3 className="text-xl font-medium mb-2">Connect with {selectedCompany.focus} Startup</h3>
//               <p className="text-white/60">Share your interest and investment thesis</p>
//             </div>
//             <div className="space-y-4 mb-6">
//               <div>
//                 <label className="block text-sm font-medium mb-2">Check Size</label>
//                 <select className="w-full h-12 bg-black/20 rounded-xl px-4 border border-white/10">
//                   <option>$1M - $5M</option>
//                   <option>$5M - $10M</option>
//                   <option>$10M+</option>
//                 </select>
//               </div>

//               <div>
//                 <label className="block text-sm font-medium mb-2">Message</label>
//                 <textarea
//                   className="w-full h-32 bg-black/20 rounded-xl p-4 border border-white/10 resize-none"
//                   placeholder="What interests you about their startup? Why would you be a good investor partner?"
//                 />
//               </div>
//             </div>

//             <div className="flex space-x-3">
//               <button
//                 onClick={() => setShowRequestModal(false)}
//                 className="h-12 flex-1 bg-blue-500 rounded-xl font-medium hover:bg-blue-600"
//               >
//                 Send Request
//               </button>
//               <button
//                 onClick={() => setShowRequestModal(false)}
//                 className="h-12 flex-1 bg-white/5 rounded-xl font-medium hover:bg-white/10"
//               >
//                 Cancel
//               </button>
//             </div>
//           </div>
//         </div>
//       )}
//     </div>
//   );
// };

// // Add animations for modals
// const style = document.createElement("style");
// style.textContent = `
//   @keyframes slideIn {
//     from {
//       opacity: 0;
//       transform: translateY(10px);
//     }
//     to {
//       opacity: 1;
//       transform: translateY(0);
//     }
//   }

//   .animate-slideIn {
//     animation: slideIn 0.2s ease-out;
//   }
// `;
// document.head.appendChild(style);

// export default InvestorFlow;

// import React, { useState } from "react";
// import { Github, BarChart2, ArrowRight, Chart, MapPin, Building, TrendingUp, Star, Eye, MessageSquare, X, Check } from "lucide-react";

// const ProovFlow = () => {
//   const [step, setStep] = useState("landing");
//   const [loading, setLoading] = useState(false);

//   return (
//     <div className="min-h-screen bg-gradient-to-br from-gray-50 to-white">
//       {/* Landing */}
//       {step === "landing" && (
//         <div className="min-h-screen flex items-center justify-center px-6">
//           <div className="max-w-xl w-full text-center">
//             <h1 className="text-5xl font-bold mb-4">Prove your traction. Get funded.</h1>
//             <p className="text-xl text-gray-600 mb-12">Effortlessly showcase your startup's growth to investors.</p>

//             <button
//               onClick={() => setStep("onboarding")}
//               className="h-14 px-8 bg-blue-600 text-white rounded-xl hover:bg-blue-700 transition-all flex items-center justify-center mx-auto text-lg font-medium group"
//             >
//               Sign Up Free
//               <ArrowRight className="w-5 h-5 ml-2 group-hover:translate-x-1 transition-transform" />
//             </button>
//           </div>
//         </div>
//       )}

//       {/* Onboarding */}
//       {step === "onboarding" && (
//         <div className="min-h-screen flex items-center justify-center p-6">
//           <div className="max-w-2xl w-full bg-white rounded-2xl shadow-sm">
//             <div className="p-8">
//               {/* Google Sign In */}
//               <button
//                 onClick={() => {
//                   setLoading(true);
//                   setTimeout(() => setLoading(false), 1500);
//                 }}
//                 disabled={loading}
//                 className="w-full h-12 bg-white border rounded-xl hover:bg-gray-50 flex items-center justify-center mb-12 relative group overflow-hidden"
//               >
//                 <div className="absolute inset-0 w-3 bg-gradient-to-r from-blue-400 to-blue-500 transition-all duration-500 -left-full group-hover:left-full" />
//                 {loading ? (
//                   <div className="w-5 h-5 border-2 border-gray-600 border-t-transparent rounded-full animate-spin" />
//                 ) : (
//                   <span className="relative">Sign in with Google</span>
//                 )}
//               </button>

//               <div className="space-y-8">
//                 {/* Key Metric */}
//                 <div>
//                   <label className="block text-lg font-medium mb-4">
//                     Connect Your Key Traction Metric
//                     <span className="block text-sm text-gray-500 font-normal mt-1">This is how investors see your progress on PROOV</span>
//                   </label>
//                   <div className="grid grid-cols-2 gap-4">
//                     <MetricCard icon={<Github />} title="GitHub" desc="Show development velocity" />
//                     <MetricCard icon={<BarChart2 />} title="Google Analytics" desc="Show user growth" />
//                   </div>
//                 </div>

//                 {/* Company Details */}
//                 <div className="grid grid-cols-2 gap-6">
//                   <div>
//                     <label className="block text-sm font-medium mb-2">Company Stage</label>
//                     <select className="w-full p-3 bg-gray-50 rounded-xl">
//                       <option>Select stage</option>
//                       <option>Pre-seed</option>
//                       <option>Seed</option>
//                       <option>Series A</option>
//                     </select>
//                   </div>

//                   <div>
//                     <label className="block text-sm font-medium mb-2">Industry/Sector</label>
//                     <input type="text" placeholder="e.g. B2B SaaS, AI/ML" className="w-full p-3 bg-gray-50 rounded-xl" />
//                   </div>
//                 </div>

//                 <div>
//                   <label className="block text-sm font-medium mb-2">One-liner Description</label>
//                   <input type="text" placeholder="Describe your startup in one line" className="w-full p-3 bg-gray-50 rounded-xl" />
//                 </div>

//                 <div>
//                   <label className="block text-sm font-medium mb-2">Location</label>
//                   <input type="text" placeholder="City, Country" className="w-full p-3 bg-gray-50 rounded-xl" />
//                 </div>

//                 <div>
//                   <label className="block text-sm font-medium mb-2">Recent Achievement (Optional)</label>
//                   <input type="text" placeholder="Share your latest milestone" className="w-full p-3 bg-gray-50 rounded-xl" />
//                 </div>

//                 <div>
//                   <label className="block text-sm font-medium mb-2">Problem</label>
//                   <textarea placeholder="What problem are you solving?" className="w-full p-3 bg-gray-50 rounded-xl h-24" />
//                 </div>

//                 <div>
//                   <label className="block text-sm font-medium mb-2">Solution</label>
//                   <textarea placeholder="How are you solving it?" className="w-full p-3 bg-gray-50 rounded-xl h-24" />
//                 </div>
//               </div>
//             </div>

//             {/* Footer */}
//             <div className="p-8 bg-gray-50 rounded-b-2xl border-t">
//               <button
//                 onClick={() => setStep("profile")}
//                 className="w-full py-3 bg-blue-600 text-white rounded-xl hover:bg-blue-700 flex items-center justify-center group font-medium"
//               >
//                 <span>Complete Profile</span>
//                 <ArrowRight className="w-5 h-5 ml-2 group-hover:translate-x-1 transition-transform" />
//               </button>
//             </div>
//           </div>
//         </div>
//       )}

//       {/* Startup Profile */}
//       {step === "profile" && (
//         <div className="min-h-screen py-12 px-6">
//           <div className="max-w-3xl mx-auto">
//             <div className="bg-white rounded-2xl shadow-sm">
//               {/* Header */}
//               <div className="p-8 border-b">
//                 <div className="flex justify-between items-start mb-6">
//                   <div>
//                     <div className="flex items-center space-x-3 mb-2">
//                       <h1 className="text-2xl font-bold">TaskFlow AI</h1>
//                       <span className="px-3 py-1 bg-blue-100 text-blue-700 rounded-full text-sm font-medium">Pre-seed</span>
//                     </div>
//                     <p className="text-gray-600 mb-4">AI-powered task management that 10x team productivity</p>
//                     <div className="flex items-center space-x-4 text-sm text-gray-500">
//                       <span className="flex items-center">
//                         <MapPin className="w-4 h-4 mr-1" />
//                         San Francisco
//                       </span>
//                       <span className="flex items-center">
//                         <Building className="w-4 h-4 mr-1" />
//                         B2B SaaS
//                       </span>
//                     </div>
//                   </div>
//                   <button className="px-4 py-2 border rounded-lg hover:bg-gray-50">Edit Profile</button>
//                 </div>

//                 <div className="grid grid-cols-2 gap-6">
//                   <div>
//                     <h3 className="font-medium mb-2">Problem</h3>
//                     <p className="text-gray-600">Teams are overwhelmed by tasks and lack intelligent prioritization</p>
//                   </div>
//                   <div>
//                     <h3 className="font-medium mb-2">Solution</h3>
//                     <p className="text-gray-600">AI-powered task manager that automatically prioritizes and assigns work</p>
//                   </div>
//                 </div>
//               </div>

//               {/* Timeline */}
//               <div className="p-8">
//                 <h2 className="text-lg font-bold mb-6">Progress Timeline</h2>
//                 <div className="space-y-6">
//                   <TimelineItem metric="+15% Active Users" description="Launched new feature, great response!" time="2d ago" type="users" />
//                   <TimelineItem metric="+30% GitHub Commits" description="Increased development velocity" time="5d ago" type="github" />
//                   <TimelineItem metric="Manual Update" description="Just closed our first major client!" time="1w ago" type="manual" />
//                 </div>

//                 {/* VC Activity */}
//                 <div className="mt-8 space-y-4">
//                   <VCActivity name="Accel" action="viewed your profile" time="2h ago" />
//                   <VCActivity name="Sequoia" action="added you to watchlist" time="5h ago" />
//                 </div>

//                 {/* Connect More CTA */}
//                 <div className="mt-8 p-6 bg-blue-50 rounded-xl">
//                   <h3 className="font-medium text-blue-900 mb-2">Want to showcase your full potential?</h3>
//                   <p className="text-blue-700 mb-4">Connect more metrics to show investors your complete growth story.</p>
//                   <button className="px-4 py-2 bg-blue-600 text-white rounded-lg hover:bg-blue-700">Connect More Metrics</button>
//                 </div>

//                 {/* Intro Requests */}
//                 <div className="mt-8">
//                   <h3 className="font-medium mb-4">Intro Requests</h3>
//                   <IntroRequest
//                     name="Maria Chen"
//                     firm="Sequoia Capital"
//                     focus="SaaS, AI"
//                     checkSize="$100k-$1M"
//                     message="Love your traction. Let's connect!"
//                   />
//                 </div>
//               </div>
//             </div>
//           </div>
//         </div>
//       )}
//     </div>
//   );
// };

// const MetricCard = ({ icon, title, desc }) => (
//   <button className="p-4 border-2 border-transparent hover:border-blue-500 rounded-xl text-left transition-all">
//     <div className="w-12 h-12 bg-gray-100 rounded-xl flex items-center justify-center mb-3">
//       {React.cloneElement(icon, { className: "w-6 h-6 text-gray-600" })}
//     </div>
//     <h3 className="font-medium mb-1">{title}</h3>
//     <p className="text-sm text-gray-500">{desc}</p>
//   </button>
// );

// const TimelineItem = ({ metric, description, time, type }) => (
//   <div className="flex items-start space-x-3">
//     <div
//       className={`w-8 h-8 rounded-full flex items-center justify-center ${
//         type === "users" ? "bg-green-100" : type === "github" ? "bg-purple-100" : "bg-blue-100"
//       }`}
//     >
//       {type === "users" ? "📈" : type === "github" ? "💻" : "📝"}
//     </div>
//     <div>
//       <div className="font-medium mb-1">{metric}</div>
//       <p className="text-gray-600 mb-1">{description}</p>
//       <span className="text-sm text-gray-500">{time}</span>
//     </div>
//   </div>
// );

// const VCActivity = ({ name, action, time }) => (
//   <div className="flex items-center justify-between p-3 bg-gray-50 rounded-lg">
//     <div className="flex items-center space-x-2">
//       <span className="font-medium">{name}</span>
//       <span className="text-gray-600">{action}</span>
//     </div>
//     <span className="text-sm text-gray-500">{time}</span>
//   </div>
// );

// const IntroRequest = ({ name, firm, focus, checkSize, message }) => (
//   <div className="p-6 bg-gray-50 rounded-xl">
//     <div className="flex justify-between items-start mb-4">
//       <div>
//         <div className="flex items-center space-x-2 mb-1">
//           <span className="font-medium">{name}</span>
//           <span className="text-gray-600">from</span>
//           <span className="font-medium">{firm}</span>
//         </div>
//         <div className="text-sm text-gray-500">
//           Focus: {focus} | Check Size: {checkSize}
//         </div>
//       </div>
//       <div className="flex space-x-2">
//         <button className="px-4 py-2 bg-blue-600 text-white rounded-lg hover:bg-blue-700">Accept</button>
//         <button className="px-4 py-2 border rounded-lg hover:bg-gray-50">Decline</button>
//       </div>
//     </div>
//     <p className="text-gray-600">"{message}"</p>
//   </div>
// );

// export default ProovFlow;

// import React, { useState } from "react";
// import {
//   GitBranch,
//   Users,
//   DollarSign,
//   CheckCircle,
//   Plus,
//   ChevronDown,
//   Link as LinkIcon,
//   FileText,
//   Image as ImageIcon,
//   Video,
// } from "lucide-react";

// const ProgressWall = () => {
//   const [updates] = useState([
//     {
//       id: 1,
//       date: "2024-01-12",
//       type: "development",
//       content: "Shipped new user authentication system with OAuth support",
//       proofs: [
//         { type: "github", text: "15 commits, 3 PRs merged" },
//         { type: "image", text: "Screenshot attached" },
//       ],
//       verified: true,
//     },
//     {
//       id: 2,
//       date: "2024-01-10",
//       type: "growth",
//       content: "Reached 1,000 daily active users milestone",
//       proofs: [
//         { type: "analytics", text: "Google Analytics data" },
//         { type: "image", text: "Dashboard screenshot" },
//       ],
//       verified: true,
//     },
//     {
//       id: 3,
//       date: "2024-01-08",
//       type: "revenue",
//       content: "First $10K MRR milestone reached",
//       proofs: [{ type: "stripe", text: "Stripe metrics attached" }],
//       verified: true,
//     },
//   ]);

//   const stats = {
//     commits: 156,
//     users: 1000,
//     mrr: 10000,
//   };

//   return (
//     <div className="max-w-6xl mx-auto p-6">
//       {/* Header */}
//       <div className="flex justify-between items-center mb-8">
//         <div>
//           <h1 className="text-2xl font-bold mb-2">Progress Wall</h1>
//           <p className="text-gray-600">Startup: ProovAI</p>
//         </div>
//         <button className="flex items-center gap-2 bg-blue-600 text-white px-4 py-2 rounded-lg">
//           <Plus size={20} />
//           New Update
//         </button>
//       </div>

//       {/* Stats */}
//       <div className="grid grid-cols-3 gap-4 mb-8">
//         <div className="bg-white p-6 rounded-lg border border-gray-200">
//           <div className="flex items-center gap-2 mb-2">
//             <GitBranch className="text-blue-600" />
//             <h3 className="font-semibold">Development</h3>
//           </div>
//           <p className="text-2xl font-bold">{stats.commits} commits</p>
//         </div>

//         <div className="bg-white p-6 rounded-lg border border-gray-200">
//           <div className="flex items-center gap-2 mb-2">
//             <Users className="text-green-600" />
//             <h3 className="font-semibold">Users</h3>
//           </div>
//           <p className="text-2xl font-bold">{stats.users} DAU</p>
//         </div>

//         <div className="bg-white p-6 rounded-lg border border-gray-200">
//           <div className="flex items-center gap-2 mb-2">
//             <DollarSign className="text-purple-600" />
//             <h3 className="font-semibold">Revenue</h3>
//           </div>
//           <p className="text-2xl font-bold">${stats.mrr} MRR</p>
//         </div>
//       </div>

//       {/* Updates */}
//       <div className="space-y-4">
//         {updates.map((update) => (
//           <div key={update.id} className="bg-white p-6 rounded-lg border border-gray-200">
//             <div className="flex justify-between items-start mb-4">
//               <div className="flex items-center gap-2">
//                 <span className="text-sm text-gray-600">{update.date}</span>
//                 {update.verified && (
//                   <span className="flex items-center gap-1 text-sm text-green-600">
//                     <CheckCircle size={16} />
//                     Verified
//                   </span>
//                 )}
//               </div>
//               <button className="text-gray-400 hover:text-gray-600">
//                 <ChevronDown size={20} />
//               </button>
//             </div>

//             <p className="text-gray-800 mb-4">{update.content}</p>

//             <div className="border-t pt-4">
//               <h4 className="text-sm text-gray-600 mb-2">Attached Proofs:</h4>
//               <div className="space-y-2">
//                 {update.proofs.map((proof, idx) => (
//                   <div key={idx} className="flex items-center gap-2 text-sm text-gray-600">
//                     {proof.type === "github" && <GitBranch size={16} />}
//                     {proof.type === "analytics" && <Users size={16} />}
//                     {proof.type === "stripe" && <DollarSign size={16} />}
//                     {proof.type === "image" && <ImageIcon size={16} />}
//                     {proof.type === "video" && <Video size={16} />}
//                     {proof.type === "link" && <LinkIcon size={16} />}
//                     {proof.type === "document" && <FileText size={16} />}
//                     <span>{proof.text}</span>
//                   </div>
//                 ))}
//               </div>
//             </div>
//           </div>
//         ))}
//       </div>
//     </div>
//   );
// };

// export default ProgressWall;

// import React, { useState } from "react";
// import { GithubIcon, LineChart, DollarSign, Link, Camera } from "lucide-react";

// const ProgressTracker = () => {
//   const [updateType, setUpdateType] = useState(null);

//   const updateTypes = [
//     {
//       id: "dev",
//       icon: GithubIcon,
//       label: "Development",
//       autoProof: "Recent GitHub activity will be auto-attached",
//     },
//     {
//       id: "growth",
//       icon: LineChart,
//       label: "Growth",
//     },
//     {
//       id: "revenue",
//       icon: DollarSign,
//       label: "Revenue",
//     },
//   ];

//   return (
//     <div className="max-w-2xl mx-auto p-4">
//       {/* Simple Update Form */}
//       <div className="bg-white rounded-lg border p-4">
//         {/* Step 1: Select Type */}
//         {!updateType && (
//           <div className="grid grid-cols-3 gap-3 mb-4">
//             {updateTypes.map((type) => (
//               <button
//                 key={type.id}
//                 onClick={() => setUpdateType(type)}
//                 className="flex flex-col items-center p-4 border rounded hover:bg-gray-50"
//               >
//                 <type.icon size={24} />
//                 <span className="mt-2 text-sm">{type.label}</span>
//               </button>
//             ))}
//           </div>
//         )}

//         {/* Step 2: Update Details */}
//         {updateType && (
//           <>
//             <div className="flex items-center gap-2 mb-4">
//               <updateType.icon size={20} />
//               <span className="font-medium">{updateType.label} Update</span>
//               <button onClick={() => setUpdateType(null)} className="text-sm text-blue-500 ml-auto">
//                 Change
//               </button>
//             </div>

//             <textarea className="w-full border rounded p-3 mb-4" placeholder="What did you achieve?" rows={3} />

//             {/* GitHub Auto-Proof */}
//             {updateType.id === "dev" && (
//               <div className="bg-gray-50 border rounded p-3 mb-4 text-sm">
//                 <div className="flex items-center gap-2 text-gray-600">
//                   <GithubIcon size={16} />
//                   {updateType.autoProof}
//                 </div>
//               </div>
//             )}

//             {/* Proof Attachment */}
//             <div className="space-y-3">
//               <div className="text-sm text-gray-600">Attach proof:</div>

//               <div className="flex gap-2">
//                 {/* URL Input */}
//                 <input type="text" placeholder="Paste working URL" className="flex-1 border rounded px-3 py-2" />

//                 {/* Screenshot Button */}
//                 <button className="flex items-center gap-2 border rounded px-3 py-2 hover:bg-gray-50">
//                   <Camera size={18} />
//                   Screenshot
//                 </button>
//               </div>
//             </div>
//           </>
//         )}

//         {/* Post Button */}
//         <button className="w-full bg-blue-600 text-white rounded py-2 mt-4">Post Update</button>
//       </div>

//       {/* Simple Timeline */}
//       <div className="mt-6 space-y-4">
//         {/* Development Update Example */}
//         <div className="bg-white rounded-lg border p-4">
//           <div className="flex items-center gap-2 mb-3">
//             <GithubIcon size={16} />
//             <span className="font-medium">Auth System Launched</span>
//           </div>

//           <div className="space-y-3">
//             {/* Auto-attached Proof */}
//             <div className="bg-gray-50 rounded p-2 text-sm">
//               <div>✓ 5 commits merged</div>
//               <div>✓ 2 PRs completed</div>
//             </div>

//             {/* Founder-attached Proof */}
//             <div className="border rounded p-2">
//               <div className="flex items-center gap-2 text-sm mb-2">
//                 <Link size={14} />
//                 <a href="#" className="text-blue-500 hover:underline">
//                   auth.demo.com/login
//                 </a>
//               </div>
//               <img src="/api/placeholder/400/200" alt="Feature screenshot" className="rounded" />
//             </div>
//           </div>
//         </div>

//         {/* Growth Update Example */}
//         <div className="bg-white rounded-lg border p-4">
//           <div className="flex items-center gap-2 mb-3">
//             <LineChart size={16} />
//             <span className="font-medium">1,000 DAU Milestone</span>
//           </div>

//           <div className="border rounded p-2">
//             <img src="/api/placeholder/400/200" alt="Analytics proof" className="rounded" />
//           </div>
//         </div>
//       </div>
//     </div>
//   );
// };

// export default ProgressTracker;

// import React, { useState } from "react";
// import { GithubIcon, LineChart, DollarSign, Link as LinkIcon, Camera, Video, Plus, ExternalLink, Check } from "lucide-react";

// const ProgressTracker = () => {
//   const [updateType, setUpdateType] = useState(null);
//   const [proofType, setProofType] = useState(null);

//   const updateTypes = [
//     {
//       id: "dev",
//       icon: GithubIcon,
//       label: "Development",
//       autoProof: "Recent GitHub activity will be auto-attached",
//     },
//     {
//       id: "growth",
//       icon: LineChart,
//       label: "Growth",
//     },
//     {
//       id: "revenue",
//       icon: DollarSign,
//       label: "Revenue",
//     },
//     {
//       id: "custom",
//       icon: Plus,
//       label: "Custom Update",
//     },
//   ];

//   const renderProofInput = () => {
//     if (!proofType) {
//       return (
//         <div className="grid grid-cols-3 gap-2">
//           <button onClick={() => setProofType("url")} className="flex flex-col items-center p-3 border rounded hover:bg-gray-50">
//             <ExternalLink size={20} />
//             <span className="text-sm mt-1">Add URL</span>
//           </button>

//           <button onClick={() => setProofType("screenshot")} className="flex flex-col items-center p-3 border rounded hover:bg-gray-50">
//             <Camera size={20} />
//             <span className="text-sm mt-1">Screenshot</span>
//           </button>

//           <button onClick={() => setProofType("recording")} className="flex flex-col items-center p-3 border rounded hover:bg-gray-50">
//             <Video size={20} />
//             <span className="text-sm mt-1">Record</span>
//           </button>
//         </div>
//       );
//     }

//     return (
//       <div className="space-y-3">
//         {proofType === "url" && (
//           <div className="flex gap-2">
//             <input type="text" placeholder="Paste any URL (feature/dashboard/metrics)" className="flex-1 border rounded px-3 py-2" />
//             <button className="border rounded px-3 py-2 text-blue-500 hover:bg-gray-50">Verify</button>
//           </div>
//         )}

//         {proofType === "screenshot" && (
//           <div className="border-2 border-dashed rounded-lg p-6 text-center">
//             <Camera size={24} className="mx-auto mb-2 text-gray-400" />
//             <div className="text-sm text-gray-600">Click to capture or drag image here</div>
//           </div>
//         )}

//         {proofType === "recording" && (
//           <div className="border-2 border-dashed rounded-lg p-6 text-center">
//             <Video size={24} className="mx-auto mb-2 text-gray-400" />
//             <div className="text-sm text-gray-600">Click to start screen recording</div>
//           </div>
//         )}

//         <button onClick={() => setProofType(null)} className="text-sm text-blue-500 hover:underline">
//           Change proof type
//         </button>
//       </div>
//     );
//   };

//   return (
//     <div className="max-w-2xl mx-auto p-4">
//       {/* Update Form */}
//       <div className="bg-white rounded-lg border p-4">
//         {/* Step 1: Select Type */}
//         {!updateType && (
//           <div className="grid grid-cols-4 gap-3 mb-4">
//             {updateTypes.map((type) => (
//               <button
//                 key={type.id}
//                 onClick={() => setUpdateType(type)}
//                 className="flex flex-col items-center p-4 border rounded hover:bg-gray-50"
//               >
//                 <type.icon size={24} />
//                 <span className="mt-2 text-sm">{type.label}</span>
//               </button>
//             ))}
//           </div>
//         )}

//         {/* Step 2: Update Details */}
//         {updateType && (
//           <>
//             <div className="flex items-center gap-2 mb-4">
//               <updateType.icon size={20} />
//               <span className="font-medium">{updateType.label}</span>
//               <button onClick={() => setUpdateType(null)} className="text-sm text-blue-500 ml-auto">
//                 Change
//               </button>
//             </div>

//             <textarea className="w-full border rounded p-3 mb-4" placeholder="What did you achieve? Share your progress..." rows={3} />

//             {/* Auto-Proof Section */}
//             {updateType.id === "dev" && (
//               <div className="bg-gray-50 border rounded p-3 mb-4">
//                 <div className="flex items-center gap-2 text-sm text-gray-600">
//                   <Check size={16} className="text-green-500" />
//                   Recent GitHub activity auto-attached
//                 </div>
//               </div>
//             )}

//             {/* Proof Attachment */}
//             <div className="space-y-3">
//               <div className="flex items-center justify-between">
//                 <div className="text-sm text-gray-600">Add proof:</div>
//                 {proofType && <span className="text-xs text-gray-500">Investors will see this first</span>}
//               </div>
//               {renderProofInput()}
//             </div>
//           </>
//         )}

//         {/* Post Button */}
//         <button className="w-full bg-blue-600 text-white rounded py-2 mt-4">Share Progress</button>
//       </div>

//       {/* Timeline */}
//       <div className="mt-6 space-y-4">
//         {/* Development Update */}
//         <div className="bg-white rounded-lg border p-4">
//           <div className="flex items-center gap-2 mb-3">
//             <GithubIcon size={16} />
//             <span className="font-medium">Auth System Launched</span>
//           </div>

//           <div className="space-y-3">
//             {/* Founder's Proof (Shown First) */}
//             <div className="border rounded p-2">
//               <div className="flex items-center gap-2 text-sm mb-2">
//                 <LinkIcon size={14} />
//                 <a href="#" className="text-blue-500 hover:underline">
//                   auth.demo.com/login
//                 </a>
//               </div>
//               <img src="/api/placeholder/400/200" alt="Feature screenshot" className="rounded w-full" />
//             </div>

//             {/* Auto-attached Proof */}
//             <div className="bg-gray-50 rounded p-2 text-sm">
//               <div>✓ 5 commits merged</div>
//               <div>✓ 2 PRs completed</div>
//             </div>
//           </div>
//         </div>

//         {/* Custom Update Example */}
//         <div className="bg-white rounded-lg border p-4">
//           <div className="flex items-center gap-2 mb-3">
//             <Plus size={16} />
//             <span className="font-medium">Team Milestone</span>
//           </div>

//           <p className="mb-3">Completed first successful user interview series!</p>

//           <div className="border rounded p-2">
//             <Video size={16} className="mb-2" />
//             <img src="/api/placeholder/400/200" alt="Interview recording" className="rounded w-full" />
//           </div>
//         </div>
//       </div>
//     </div>
//   );
// };

// export default ProgressTracker;

import React, { useState } from "react";
import {
  GithubIcon,
  LineChart,
  DollarSign,
  TrendingUp,
  Users,
  Calendar,
  Star,
  ArrowUpRight,
  MessageSquare,
  ExternalLink,
  MapPin,
  Eye,
  BookmarkPlus,
  Building,
} from "lucide-react";

// Enhanced Investor View
const InvestorView = () => {
  return (
    <div className="max-w-3xl mx-auto p-4">
      {/* Anonymous Startup Header */}
      <div className="bg-white rounded-lg border p-6 mb-6">
        <div className="flex justify-between items-start">
          <div>
            <div className="text-lg font-bold mb-1">B2B SaaS Startup</div>
            <div className="text-sm text-gray-600">Developer Productivity Tool</div>
            <div className="flex items-center gap-2 text-sm text-gray-600 mt-2">
              <MapPin size={14} />
              <span>SF Bay Area</span>
              <span className="text-gray-300">•</span>
              <span>24 investors tracking</span>
            </div>
          </div>
          <div className="bg-green-50 text-green-600 px-3 py-1 rounded-full text-sm">Active Builder</div>
        </div>

        {/* Problem/Solution/Market */}
        <div className="mt-6 space-y-3 pb-6 border-b">
          <div>
            <div className="text-sm font-medium mb-1">Problem</div>
            <p className="text-sm text-gray-600">Code reviews take 5+ hours per week per developer, causing significant delays</p>
          </div>
          <div>
            <div className="text-sm font-medium mb-1">Solution</div>
            <p className="text-sm text-gray-600">AI-powered code review automation that reduces review time by 80%</p>
          </div>
          <div>
            <div className="text-sm font-medium mb-1">Market Size</div>
            <p className="text-sm text-gray-600">$4.2B TAM • 28M developers globally</p>
          </div>
        </div>

        {/* Quick Stats */}
        <div className="grid grid-cols-3 gap-4 mt-6">
          <div className="border rounded-lg p-3">
            <div className="text-sm text-gray-600 mb-1">MRR</div>
            <div className="text-lg font-semibold">$12.4K</div>
            <div className="text-xs text-green-600 flex items-center">
              <TrendingUp size={12} className="mr-1" />
              +23% MoM
            </div>
          </div>
          <div className="border rounded-lg p-3">
            <div className="text-sm text-gray-600 mb-1">Users</div>
            <div className="text-lg font-semibold">847</div>
            <div className="text-xs text-green-600 flex items-center">
              <TrendingUp size={12} className="mr-1" />
              +15% MoM
            </div>
          </div>
          <div className="border rounded-lg p-3">
            <div className="text-sm text-gray-600 mb-1">Build Streak</div>
            <div className="text-lg font-semibold">38 days</div>
            <div className="text-xs text-blue-600">Consistent Builder</div>
          </div>
        </div>

        {/* About Founders */}
        <div className="mt-6 text-sm text-gray-600">
          <strong className="text-gray-700">About the Founders: </strong>
          Ex-Google Tech Lead and AI Researcher from Stanford. Previously built developer tools used by 100K+ developers.
        </div>
      </div>

      {/* Key Updates Only */}
      <div className="space-y-4">
        <div className="bg-white rounded-lg border p-4">
          <div className="flex items-center gap-2 text-sm text-gray-500 mb-3">
            <Star size={14} className="text-yellow-400" />
            <span>Key Achievement</span>
            <span className="text-gray-400">•</span>
            <Calendar size={14} />
            <span>Today</span>
          </div>

          <div className="space-y-3">
            <p className="font-medium">Launched AI Review Feature + Hit $12K MRR</p>
            <img src="/api/placeholder/500/300" alt="Feature demo" className="rounded w-full" />
            <div className="text-sm text-gray-600">✓ Verified via GitHub & Stripe</div>
          </div>
        </div>
      </div>
    </div>
  );
};

// Enhanced Founder View with Investor Activity
const FounderView = () => {
  return (
    <div className="max-w-4xl mx-auto p-4">
      <div className="grid grid-cols-3 gap-6">
        {/* Main Content */}
        <div className="col-span-2 space-y-4">
          {/* Startup Header */}
          <div className="bg-white rounded-lg border p-6">
            <div className="flex justify-between items-start">
              <div>
                <h1 className="text-xl font-bold mb-1">CodeReviewAI</h1>
                <p className="text-gray-600">Making code reviews 10x faster</p>
              </div>
              <button className="bg-blue-50 text-blue-600 px-3 py-1 rounded text-sm">Share Progress</button>
            </div>

            {/* Stats Grid */}
            <div className="grid grid-cols-2 gap-4 mt-6">
              <div className="border rounded-lg p-3">
                <div className="text-sm text-gray-600">Monthly Revenue</div>
                <div className="text-lg font-semibold">$12,431</div>
                <div className="text-xs text-green-600 flex items-center">
                  <TrendingUp size={12} className="mr-1" />
                  +$2,845 from last month
                </div>
              </div>

              <div className="border rounded-lg p-3">
                <div className="text-sm text-gray-600">Build Streak</div>
                <div className="text-lg font-semibold">38 days</div>
                <div className="text-xs text-gray-600">Last update: 2h ago</div>
              </div>
            </div>
          </div>

          {/* Timeline */}
          {/* ... (existing timeline code) ... */}

          {/* Detailed Timeline */}
          <div className="space-y-4">
            {/* Today's Update */}
            <div className="bg-white rounded-lg border p-4">
              <div className="flex items-center justify-between mb-3">
                <div className="flex items-center gap-2">
                  <Calendar size={14} />
                  <span className="text-sm text-gray-500">Today at 2:34 PM</span>
                </div>
                <div className="flex items-center gap-2">
                  <Star size={14} className="text-yellow-400" />
                  <span className="text-sm text-yellow-600">Key Update</span>
                  <button className="text-sm text-blue-500">Edit</button>
                </div>
              </div>

              <div className="flex items-start gap-3">
                <GithubIcon size={16} className="mt-1" />
                <div className="flex-1">
                  <p className="font-medium mb-2">Launched AI-powered code review feature</p>

                  {/* Main Proof */}
                  <div className="bg-gray-50 rounded p-3 mb-3 space-y-2">
                    <div className="flex items-center gap-2 text-sm">
                      <ExternalLink size={14} />
                      <a href="#" className="text-blue-500 hover:underline">
                        app.demo.com/ai-review
                      </a>
                    </div>
                    <img src="/api/placeholder/500/300" alt="Feature demo" className="rounded w-full" />
                  </div>

                  {/* Additional Details */}
                  <div className="bg-gray-50 rounded p-3 text-sm space-y-1">
                    <div className="flex items-center gap-2">
                      <GithubIcon size={14} />
                      <span>12 commits in main branch</span>
                    </div>
                    <div className="flex items-center gap-2">
                      <Users size={14} />
                      <span>23 users already using the feature</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {/* More updates... */}
          </div>
        </div>

        {/* Investor Activity Sidebar */}
        <div className="space-y-4">
          {/* Activity Summary */}
          <div className="bg-white rounded-lg border p-4">
            <h3 className="font-medium mb-3">Last 7 Days</h3>
            <div className="space-y-3">
              <div className="flex items-center justify-between">
                <div className="flex items-center gap-2">
                  <Eye size={16} />
                  <span className="text-sm">Profile Views</span>
                </div>
                <span className="font-medium">32</span>
              </div>
              <div className="flex items-center justify-between">
                <div className="flex items-center gap-2">
                  <BookmarkPlus size={16} />
                  <span className="text-sm">Watchlist Adds</span>
                </div>
                <span className="font-medium">8</span>
              </div>
              <div className="flex items-center justify-between">
                <div className="flex items-center gap-2">
                  <MessageSquare size={16} />
                  <span className="text-sm">Meeting Requests</span>
                </div>
                <span className="font-medium">3</span>
              </div>
            </div>
          </div>

          {/* Recent Activity */}
          <div className="bg-white rounded-lg border p-4">
            <h3 className="font-medium mb-3">Recent Activity</h3>
            <div className="space-y-3">
              <div className="border-l-2 border-blue-500 pl-3">
                <div className="flex items-center justify-between">
                  <div className="text-sm font-medium">Sequoia Capital</div>
                  <button className="text-xs text-blue-500">View Profile</button>
                </div>
                <div className="text-xs text-gray-600">Added to watchlist</div>
                <div className="text-xs text-gray-400">2 hours ago</div>
              </div>
              <div className="border-l-2 border-green-500 pl-3">
                <div className="flex items-center justify-between">
                  <div className="text-sm font-medium">YC</div>
                  <button className="text-xs text-blue-500">View Profile</button>
                </div>
                <div className="text-xs text-gray-600">Requested meeting</div>
                <div className="text-xs text-gray-400">5 hours ago</div>
              </div>
            </div>
          </div>

          {/* Meeting Requests */}
          <div className="bg-white rounded-lg border p-4">
            <h3 className="font-medium mb-3">Pending Requests</h3>
            <div className="space-y-3">
              <div className="flex items-center justify-between p-2 bg-gray-50 rounded">
                <div>
                  <div className="text-sm font-medium">YC Partner</div>
                  <div className="text-xs text-gray-600">30 min intro call</div>
                </div>
                <button className="text-sm text-blue-500">Accept</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

// New Investor Profile Modal
const InvestorProfile = ({ onClose }) => {
  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center">
      <div className="bg-white rounded-lg p-6 max-w-xl w-full">
        <div className="flex justify-between items-start mb-6">
          <div>
            <h2 className="text-xl font-bold">Sequoia Capital</h2>
            <div className="flex items-center gap-2 text-sm text-gray-600 mt-1">
              <MapPin size={14} />
              <span>SF Bay Area</span>
              <span className="text-gray-300">•</span>
              <span>Early to Growth Stage</span>
            </div>
          </div>
          <button onClick={onClose} className="text-gray-400">
            ×
          </button>
        </div>

        <div className="grid grid-cols-2 gap-4 mb-6">
          <div className="border rounded-lg p-3">
            <div className="text-sm text-gray-600">Check Size</div>
            <div className="text-lg font-semibold">$1M - $10M</div>
            <div className="text-xs text-gray-600">Series Seed - B</div>
          </div>
          <div className="border rounded-lg p-3">
            <div className="text-sm text-gray-600">Activity</div>
            <div className="text-lg font-semibold">43 startups</div>
            <div className="text-xs text-gray-600">Actively tracking</div>
          </div>
        </div>

        <div className="space-y-4">
          <div>
            <h3 className="text-sm font-medium mb-2">Focus Areas</h3>
            <div className="flex flex-wrap gap-2">
              <span className="px-3 py-1 bg-gray-100 rounded text-sm">AI/ML</span>
              <span className="px-3 py-1 bg-gray-100 rounded text-sm">SaaS</span>
              <span className="px-3 py-1 bg-gray-100 rounded text-sm">DevTools</span>
            </div>
          </div>

          <div>
            <h3 className="text-sm font-medium mb-2">Recent Investments</h3>
            <div className="text-sm text-gray-600">12 investments in last 12 months in similar stage companies</div>
          </div>

          <button className="w-full bg-blue-600 text-white rounded-lg py-2">Request Intro</button>
        </div>
      </div>
    </div>
  );
};

// Main Component with View Toggle
const ProgressWall = () => {
  const [view, setView] = useState("investor");
  const [showInvestorProfile, setShowInvestorProfile] = useState(false);

  return (
    <div>
      <div className="mb-4 flex justify-center gap-4">
        <button
          onClick={() => setView("investor")}
          className={`px-4 py-2 rounded ${view === "investor" ? "bg-blue-500 text-white" : "bg-gray-100"}`}
        >
          Investor View
        </button>
        <button
          onClick={() => setView("founder")}
          className={`px-4 py-2 rounded ${view === "founder" ? "bg-blue-500 text-white" : "bg-gray-100"}`}
        >
          Founder View
        </button>
      </div>

      {view === "investor" ? <InvestorView /> : <FounderView />}

      {showInvestorProfile && <InvestorProfile onClose={() => setShowInvestorProfile(false)} />}
    </div>
  );
};

export default ProgressWall;

// import React, { useState } from "react";
// import {
//   TrendingUp,
//   Search,
//   Filter,
//   Star,
//   BookmarkPlus,
//   MessageSquare,
//   Eye,
//   ArrowUpRight,
//   GithubIcon,
//   LineChart,
//   DollarSign,
//   MapPin,
//   Calendar,
//   Users,
//   Bell,
//   Settings,
//   Shield,
//   Zap,
//   Table,
// } from "lucide-react";

// // Mock data array
// const startups = [
//   {
//     id: 1,
//     name: "AI Code Review Platform",
//     category: "Developer Productivity Tool",
//     tag: "Active Builder",
//     location: "SF Bay Area",
//     tracking: 24,
//     problem: "Code reviews take 5+ hours per week per developer",
//     solution: "AI-powered code review automation reducing review time by 80%",
//     metrics: {
//       mrr: "12.4K",
//       mrrGrowth: "+23%",
//       users: "847",
//       retention: "89%",
//       buildStreak: "38",
//       tam: "4.2B",
//     },
//     update: {
//       type: "github",
//       icon: GithubIcon,
//       title: "Launched AI-powered code review feature",
//       proof: "12 commits verified • Released to production",
//       timestamp: "Today",
//     },
//     engagement: {
//       views: "32",
//       requests: "3",
//     },
//   },
//   {
//     id: 2,
//     name: "Enterprise Sales Intelligence",
//     category: "Revenue Optimization Platform",
//     tag: "High Growth",
//     location: "New York",
//     tracking: 38,
//     problem: "Enterprise sales teams lose 40% time on manual research",
//     solution: "AI-driven sales intelligence automation with 95% accuracy",
//     metrics: {
//       mrr: "89K",
//       mrrGrowth: "+41%",
//       users: "122",
//       retention: "94%",
//       buildStreak: "45",
//       tam: "7.8B",
//     },
//     update: {
//       type: "revenue",
//       icon: DollarSign,
//       title: "Closed 3 Fortune 500 deals this month",
//       proof: "Contract value verified • $1.2M ARR impact",
//       timestamp: "2 hours ago",
//     },
//     engagement: {
//       views: "45",
//       requests: "5",
//     },
//   },
//   {
//     id: 3,
//     name: "AI Infrastructure API",
//     category: "Developer Infrastructure",
//     tag: "Rapid Growth",
//     location: "Seattle",
//     tracking: 31,
//     problem: "ML deployment takes weeks and costs 5x more than needed",
//     solution: "1-click ML infrastructure deployment with 10x cost reduction",
//     metrics: {
//       mrr: "45K",
//       mrrGrowth: "+38%",
//       users: "2800",
//       retention: "91%",
//       buildStreak: "52",
//       tam: "12B",
//     },
//     update: {
//       type: "technical",
//       icon: Zap,
//       title: "Achieved 10x performance improvement",
//       proof: "Benchmarks verified • 3rd party validated",
//       timestamp: "Yesterday",
//     },
//     engagement: {
//       views: "28",
//       requests: "4",
//     },
//   },
//   {
//     id: 4,
//     name: "No-Code Testing Platform",
//     category: "QA Automation Tool",
//     tag: "Product Launch",
//     location: "Austin",
//     tracking: 27,
//     problem: "QA testing requires expensive engineering resources",
//     solution: "No-code test automation for non-technical teams",
//     metrics: {
//       mrr: "28K",
//       mrrGrowth: "+28%",
//       users: "312",
//       retention: "88%",
//       buildStreak: "33",
//       tam: "5.6B",
//     },
//     update: {
//       type: "product",
//       icon: Table,
//       title: "Launched enterprise feature suite",
//       proof: "15 enterprise features • 3 pilot customers",
//       timestamp: "2 days ago",
//     },
//     engagement: {
//       views: "24",
//       requests: "2",
//     },
//   },
//   {
//     id: 5,
//     name: "Security Compliance Suite",
//     category: "Enterprise Security",
//     tag: "Enterprise Ready",
//     location: "Boston",
//     tracking: 42,
//     problem: "Security compliance takes 6+ months and $100K+",
//     solution: "Automated security compliance with 90% time reduction",
//     metrics: {
//       mrr: "67K",
//       mrrGrowth: "+32%",
//       users: "89",
//       retention: "96%",
//       buildStreak: "41",
//       tam: "8.4B",
//     },
//     update: {
//       type: "security",
//       icon: Shield,
//       title: "Completed SOC2 certification platform",
//       proof: "Certification verified • 5 enterprise deployments",
//       timestamp: "3 days ago",
//     },
//     engagement: {
//       views: "36",
//       requests: "4",
//     },
//   },
// ];

// const InvestorDashboard = () => {
//   const [activeTab, setActiveTab] = useState("feed");
//   const [selectedFilter, setSelectedFilter] = useState("all");

//   return (
//     <div className="min-h-screen bg-gray-50">
//       {/* Top Navigation */}
//       <nav className="bg-white border-b px-6 py-3">
//         <div className="max-w-6xl mx-auto flex items-center justify-between">
//           <div className="flex items-center gap-8">
//             <h1 className="font-bold text-xl">PROOV</h1>
//             <div className="flex gap-4">
//               <button
//                 onClick={() => setActiveTab("feed")}
//                 className={`px-3 py-1 rounded ${activeTab === "feed" ? "bg-blue-50 text-blue-600" : "text-gray-600"}`}
//               >
//                 Feed
//               </button>
//               <button
//                 onClick={() => setActiveTab("tracking")}
//                 className={`px-3 py-1 rounded ${activeTab === "tracking" ? "bg-blue-50 text-blue-600" : "text-gray-600"}`}
//               >
//                 Tracking (24)
//               </button>
//               <button
//                 onClick={() => setActiveTab("meetings")}
//                 className={`px-3 py-1 rounded ${activeTab === "meetings" ? "bg-blue-50 text-blue-600" : "text-gray-600"}`}
//               >
//                 Meetings (3)
//               </button>
//             </div>
//           </div>

//           <div className="flex items-center gap-4">
//             <Bell size={20} className="text-gray-600" />
//             <Settings size={20} className="text-gray-600" />
//           </div>
//         </div>
//       </nav>

//       <main className="max-w-6xl mx-auto p-6">
//         {/* Search & Filters */}
//         <div className="mb-6 flex gap-4">
//           <div className="flex-1 relative">
//             <Search size={20} className="absolute left-3 top-2.5 text-gray-400" />
//             <input
//               type="text"
//               placeholder="Search startups by problem space, tech stack, metrics..."
//               className="w-full pl-10 pr-4 py-2 border rounded-lg"
//             />
//           </div>
//           <div className="flex gap-2">
//             <button className="flex items-center gap-2 px-4 py-2 border rounded-lg hover:bg-gray-50">
//               <Filter size={16} />
//               Filters
//             </button>
//             <select className="px-4 py-2 border rounded-lg">
//               <option>Sort: Recent Updates</option>
//               <option>Highest Growth</option>
//               <option>Most Active</option>
//             </select>
//           </div>
//         </div>

//         {/* Quick Filters */}
//         <div className="flex gap-2 mb-6">
//           {["All", "AI/ML", "SaaS", "Developer Tools", "Enterprise"].map((filter) => (
//             <button
//               key={filter}
//               onClick={() => setSelectedFilter(filter.toLowerCase())}
//               className={`px-3 py-1 rounded-full text-sm ${
//                 selectedFilter === filter.toLowerCase() ? "bg-blue-600 text-white" : "bg-gray-100 text-gray-600"
//               }`}
//             >
//               {filter}
//             </button>
//           ))}
//         </div>

//         {/* Startup Feed */}
//         <div className="space-y-6">
//           {startups.map((startup) => (
//             <div key={startup.id} className="bg-white rounded-lg border p-6">
//               {/* Header */}
//               <div className="flex justify-between items-start mb-4">
//                 <div>
//                   <div className="flex items-center gap-3 mb-1">
//                     <h2 className="text-lg font-bold">{startup.name}</h2>
//                     <span className="bg-green-50 text-green-600 px-2 py-0.5 rounded-full text-sm">{startup.tag}</span>
//                   </div>
//                   <div className="text-sm text-gray-600">{startup.category}</div>
//                   <div className="flex items-center gap-2 text-sm text-gray-600 mt-1">
//                     <MapPin size={14} />
//                     <span>{startup.location}</span>
//                     <span className="text-gray-300">•</span>
//                     <Users size={14} />
//                     <span>
//                       <b>{startup.tracking} investors tracking</b>
//                     </span>
//                   </div>
//                 </div>

//                 <div className="flex gap-2">
//                   <button className="flex items-center gap-1 px-3 py-1.5 border rounded hover:bg-gray-50">
//                     <BookmarkPlus size={16} />
//                     Track
//                   </button>
//                   <button className="flex items-center gap-1 px-3 py-1.5 bg-blue-600 text-white rounded hover:bg-blue-700">
//                     <MessageSquare size={16} />
//                     Request Intro
//                   </button>
//                 </div>
//               </div>

//               {/* Problem/Solution */}
//               <div className="grid grid-cols-2 gap-4 mb-6 pb-6 border-b">
//                 <div>
//                   <div className="text-sm font-medium mb-1">Problem</div>
//                   <p className="text-sm text-gray-600">{startup.problem}</p>
//                 </div>
//                 <div>
//                   <div className="text-sm font-medium mb-1">Solution</div>
//                   <p className="text-sm text-gray-600">{startup.solution}</p>
//                 </div>
//               </div>

//               {/* Metrics */}
//               <div className="grid grid-cols-4 gap-4 mb-6">
//                 <div className="border rounded-lg p-3">
//                   <div className="text-sm text-gray-600">MRR</div>
//                   <div className="text-lg font-semibold">${startup.metrics.mrr}</div>
//                   <div className="text-xs text-green-600 flex items-center">
//                     <TrendingUp size={12} className="mr-1" />
//                     {startup.metrics.mrrGrowth} MoM
//                   </div>
//                 </div>
//                 <div className="border rounded-lg p-3">
//                   <div className="text-sm text-gray-600">Users</div>
//                   <div className="text-lg font-semibold">{startup.metrics.users}</div>
//                   <div className="text-xs text-green-600">{startup.metrics.retention}% retention</div>
//                 </div>
//                 <div className="border rounded-lg p-3">
//                   <div className="text-sm text-gray-600">Build Streak</div>
//                   <div className="text-lg font-semibold">{startup.metrics.buildStreak} days</div>
//                   <div className="text-xs text-blue-600">Very Active</div>
//                 </div>
//                 <div className="border rounded-lg p-3">
//                   <div className="text-sm text-gray-600">Market Size</div>
//                   <div className="text-lg font-semibold">${startup.metrics.tam}B</div>
//                   <div className="text-xs text-gray-600">TAM</div>
//                 </div>
//               </div>

//               {/* Latest Update */}
//               <div className="bg-gray-50 rounded-lg p-4">
//                 <div className="flex items-center gap-2 text-sm text-gray-500 mb-3">
//                   <Star size={14} className="text-yellow-400" />
//                   <span>Latest Achievement</span>
//                   <span className="text-gray-400">•</span>
//                   <Calendar size={14} />
//                   <span>{startup.update.timestamp}</span>
//                 </div>

//                 <div className="flex items-start gap-3">
//                   <startup.update.icon size={16} className="mt-1" />
//                   <div>
//                     <p className="font-medium mb-2">{startup.update.title}</p>
//                     <div className="space-y-2">
//                       <img src="/api/placeholder/500/200" alt="Update proof" className="rounded w-full" />
//                       <div className="flex items-center gap-2 text-sm text-gray-600">{startup.update.proof}</div>
//                     </div>
//                   </div>
//                 </div>
//               </div>

//               {/* Quick Actions */}
//               <div className="flex items-center justify-between mt-4 pt-4 border-t">
//                 <div className="flex items-center gap-4 text-sm text-gray-600">
//                   <div className="flex items-center gap-1">
//                     <Eye size={14} />
//                     <span>{startup.engagement.views} views this week</span>
//                   </div>
//                   <div className="flex items-center gap-1">
//                     <MessageSquare size={14} />
//                     <span>{startup.engagement.requests} intro requests</span>
//                   </div>
//                 </div>
//                 <button className="text-blue-600 text-sm hover:underline">View Full Profile</button>
//               </div>
//             </div>
//           ))}
//         </div>
//       </main>
//     </div>
//   );
// };

// export default InvestorDashboard;

// import React, { useState } from "react";
// import { GitBranch, LineChart, DollarSign, Camera, Link as LinkIcon, Upload, CheckCircle, X, ArrowRight, Plus } from "lucide-react";

// const UpdateFlow = () => {
//   const [step, setStep] = useState("type");
//   const [updateType, setUpdateType] = useState(null);
//   const [proofType, setProofType] = useState(null);

//   const updateTypes = [
//     {
//       id: "development",
//       icon: GitBranch,
//       label: "Development Update",
//       subtext: "Launched features, improvements",
//       autoProof: "GitHub activity",
//     },
//     {
//       id: "growth",
//       icon: LineChart,
//       label: "Growth Update",
//       subtext: "Users, engagement, retention",
//       autoProof: "Analytics data",
//     },
//     {
//       id: "revenue",
//       icon: DollarSign,
//       label: "Revenue Update",
//       subtext: "MRR, deals, customers",
//       autoProof: "Stripe metrics",
//     },
//   ];

//   return (
//     <div className="min-h-screen bg-gray-50 py-8">
//       <div className="max-w-2xl mx-auto">
//         {/* Clean Header */}
//         <div className="text-center mb-8">
//           <h1 className="text-2xl font-semibold mb-2">Share Progress</h1>
//           <p className="text-gray-600">Keep your progress wall updated and verified</p>
//         </div>

//         {/* Main Card */}
//         <div className="bg-white rounded-2xl border shadow-sm">
//           {step === "type" && (
//             <div className="p-6">
//               <div className="space-y-4">
//                 {updateTypes.map((type) => (
//                   <button
//                     key={type.id}
//                     onClick={() => {
//                       setUpdateType(type);
//                       setStep("content");
//                     }}
//                     className="w-full flex items-center gap-4 p-4 border rounded-xl hover:bg-gray-50 text-left group transition-all"
//                   >
//                     <div className={`p-3 rounded-lg bg-emerald-50 text-emerald-600 group-hover:bg-emerald-100`}>
//                       <type.icon size={24} />
//                     </div>
//                     <div>
//                       <div className="font-medium">{type.label}</div>
//                       <div className="text-sm text-gray-600">{type.subtext}</div>
//                     </div>
//                     <ArrowRight size={20} className="ml-auto text-gray-400" />
//                   </button>
//                 ))}

//                 {/* Custom Update Option */}
//                 <button
//                   onClick={() => {
//                     setUpdateType({ id: "custom", label: "Custom Update" });
//                     setStep("content");
//                   }}
//                   className="w-full flex items-center gap-4 p-4 border rounded-xl hover:bg-gray-50 text-left group"
//                 >
//                   <div className="p-3 rounded-lg bg-gray-50 text-gray-600 group-hover:bg-gray-100">
//                     <Plus size={24} />
//                   </div>
//                   <div>
//                     <div className="font-medium">Custom Update</div>
//                     <div className="text-sm text-gray-600">Other achievements or progress</div>
//                   </div>
//                   <ArrowRight size={20} className="ml-auto text-gray-400" />
//                 </button>
//               </div>
//             </div>
//           )}

//           {step === "content" && updateType && (
//             <div className="p-6">
//               {/* Back Button */}
//               <button onClick={() => setStep("type")} className="flex items-center gap-2 text-sm text-gray-600 mb-6 hover:text-gray-900">
//                 <X size={16} />
//                 Change update type
//               </button>

//               {/* Update Content */}
//               <div className="space-y-6">
//                 <textarea
//                   placeholder="What did you achieve?"
//                   className="w-full p-4 border rounded-xl text-lg resize-none focus:outline-none focus:ring-2 focus:ring-emerald-500/20 focus:border-emerald-500"
//                   rows={3}
//                 />

//                 {/* Auto-attached Proof */}
//                 {updateType.autoProof && (
//                   <div className="bg-emerald-50 rounded-lg p-4">
//                     <div className="flex items-center gap-2 text-emerald-700">
//                       <CheckCircle size={16} />
//                       <span className="text-sm">We'll automatically attach your {updateType.autoProof}</span>
//                     </div>
//                   </div>
//                 )}

//                 {/* Proof Attachment */}
//                 <div>
//                   <div className="text-sm text-gray-600 mb-3">Add proof to verify your progress:</div>

//                   <div className="grid grid-cols-2 gap-3">
//                     <button className="flex flex-col items-center gap-2 p-6 border rounded-xl hover:bg-gray-50">
//                       <Camera size={24} className="text-gray-600" />
//                       <span className="text-sm font-medium">Take Screenshot</span>
//                     </button>

//                     <div className="relative">
//                       <input type="text" placeholder="Paste any URL" className="w-full h-full px-4 py-6 border rounded-xl text-center" />
//                       <LinkIcon size={20} className="absolute right-4 top-1/2 -translate-y-1/2 text-gray-400" />
//                     </div>
//                   </div>
//                 </div>

//                 {/* Post Button */}
//                 <button className="w-full bg-emerald-500 text-white rounded-xl py-3 font-medium hover:bg-emerald-600 transition-colors">
//                   Post Update
//                 </button>
//               </div>
//             </div>
//           )}

//           {/* Success State (show after posting) */}
//           <div className="hidden p-8 text-center">
//             <div className="inline-flex items-center justify-center w-12 h-12 bg-emerald-100 rounded-full mb-4">
//               <CheckCircle className="text-emerald-500" size={24} />
//             </div>
//             <h3 className="text-lg font-medium mb-2">Update Posted!</h3>
//             <p className="text-gray-600 mb-6">Your progress has been verified and shared</p>
//             <button className="text-emerald-600 font-medium hover:text-emerald-700">View on Progress Wall</button>
//           </div>
//         </div>
//       </div>
//     </div>
//   );
// };

// export default UpdateFlow;
