// import React, { useState } from "react";
// import {
//   Search,
//   Users,
//   Filter,
//   UserCheck,
//   MessageSquare,
//   ChevronDown,
//   Download,
//   ExternalLink,
//   Mail,
//   Twitter,
//   Linkedin,
//   Calendar,
//   ArrowRight,
//   Target,
//   Clock,
//   Star,
//   CheckCircle,
//   ThumbsUp,
//   Clipboard,
//   AlertCircle,
// } from "lucide-react";
// import { Link } from "react-router-dom";

// const LeadFinder = () => {
//   const [activeTab, setActiveTab] = useState("leads");
//   const [selectedLeads, setSelectedLeads] = useState([]);
//   const [filterOpen, setFilterOpen] = useState(false);

//   // Mock data for leads
//   const potentialLeads = [
//     {
//       id: 1,
//       name: "Sarah Johnson",
//       title: "Marketing Director",
//       company: "GrowthWave",
//       matchScore: 92,
//       activity: "Looking for a project management tool that integrates with our marketing stack",
//       source: "twitter",
//       date: "2 days ago",
//       location: "New York, USA",
//       contact: {
//         email: true,
//         twitter: "@sarahj_marketing",
//         linkedin: true,
//       },
//       contacted: false,
//     },
//     {
//       id: 2,
//       name: "Michael Chen",
//       title: "Product Manager",
//       company: "TechSolutions Inc.",
//       matchScore: 87,
//       activity: "Frustrated with current task management options. Need something more intuitive",
//       source: "reddit",
//       date: "3 days ago",
//       location: "San Francisco, USA",
//       contact: {
//         email: true,
//         twitter: false,
//         linkedin: true,
//       },
//       contacted: false,
//     },
//     {
//       id: 3,
//       name: "James Wilson",
//       title: "Founder & CEO",
//       company: "RemoteTeams",
//       matchScore: 83,
//       activity: "Our team is growing and we're struggling with collaboration. Looking for suggestions",
//       source: "linkedin",
//       date: "1 week ago",
//       location: "London, UK",
//       contact: {
//         email: true,
//         twitter: "@jameswilson",
//         linkedin: true,
//       },
//       contacted: true,
//     },
//     {
//       id: 4,
//       name: "Priya Sharma",
//       title: "Operations Lead",
//       company: "Flowspace",
//       matchScore: 79,
//       activity: "Need a better solution for tracking team productivity and managing client projects",
//       source: "twitter",
//       date: "4 days ago",
//       location: "Mumbai, India",
//       contact: {
//         email: false,
//         twitter: "@priyaops",
//         linkedin: true,
//       },
//       contacted: false,
//     },
//     {
//       id: 5,
//       name: "David Reynolds",
//       title: "IT Director",
//       company: "HealthTech Solutions",
//       matchScore: 76,
//       activity: "Looking for a secure task management solution that meets healthcare compliance",
//       source: "linkedin",
//       date: "5 days ago",
//       location: "Chicago, USA",
//       contact: {
//         email: true,
//         twitter: false,
//         linkedin: true,
//       },
//       contacted: false,
//     },
//   ];

//   const campaigns = [
//     {
//       id: 1,
//       name: "Project Management Software Leads",
//       leadCount: 42,
//       matchCriteria: "Project Management, Task Management, Team Collaboration",
//       lastUpdated: "Today",
//       status: "active",
//     },
//     {
//       id: 2,
//       name: "Remote Work Solutions",
//       leadCount: 27,
//       matchCriteria: "Remote Work, Virtual Teams, Collaboration Software",
//       lastUpdated: "2 days ago",
//       status: "active",
//     },
//     {
//       id: 3,
//       name: "Healthcare IT Decision Makers",
//       leadCount: 15,
//       matchCriteria: "Healthcare IT, Medical Software, HIPAA Compliance",
//       lastUpdated: "1 week ago",
//       status: "paused",
//     },
//   ];

//   const toggleLeadSelection = (id) => {
//     if (selectedLeads.includes(id)) {
//       setSelectedLeads(selectedLeads.filter((leadId) => leadId !== id));
//     } else {
//       setSelectedLeads([...selectedLeads, id]);
//     }
//   };

//   const selectAllLeads = () => {
//     if (selectedLeads.length === potentialLeads.length) {
//       setSelectedLeads([]);
//     } else {
//       setSelectedLeads(potentialLeads.map((lead) => lead.id));
//     }
//   };

//   return (
//     <div className="min-h-screen bg-gray-50">
//       {/* Header (assuming similar to existing app) */}
//       <header className="h-16 border-b border-gray-100 flex items-center px-8">
//         <div className="max-w-6xl w-full mx-auto flex justify-between">
//           <span className="font-medium text-xl text-green-600">FeedbackbyAI</span>
//           <Link to="/dashboard/" className="text-gray-500 hover:text-gray-700">
//             Back to Dashboard
//           </Link>
//         </div>
//       </header>

//       {/* Main Content */}
//       <main className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-8">
//         <div className="mb-6">
//           <h1 className="text-2xl font-bold text-gray-900 mb-2">Lead Finder</h1>
//           <p className="text-gray-600">Discover potential customers actively looking for solutions like yours</p>
//         </div>

//         {/* Campaign Selector */}
//         <div className="bg-white rounded-lg shadow-sm border border-gray-200 p-5 mb-8">
//           <div className="flex flex-col md:flex-row md:items-center justify-between gap-4">
//             <div>
//               <label htmlFor="campaign" className="block text-sm font-medium text-gray-700 mb-1">
//                 Active Campaign
//               </label>
//               <div className="relative w-full md:w-80">
//                 <select
//                   id="campaign"
//                   className="block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-red-500 focus:border-red-500 rounded-md"
//                   defaultValue={campaigns[0].id}
//                 >
//                   {campaigns.map((campaign) => (
//                     <option key={campaign.id} value={campaign.id}>
//                       {campaign.name} ({campaign.leadCount} leads)
//                     </option>
//                   ))}
//                   <option value="new">+ Create New Campaign</option>
//                 </select>
//                 <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
//                   <ChevronDown className="h-4 w-4" />
//                 </div>
//               </div>
//             </div>

//             <div className="flex flex-wrap gap-2">
//               <button className="inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-red-600 hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500">
//                 <Target className="mr-2 h-4 w-4" />
//                 Find New Leads
//               </button>

//               <button className="inline-flex items-center px-4 py-2 border border-gray-300 shadow-sm text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500">
//                 <Download className="mr-2 h-4 w-4" />
//                 Export
//               </button>

//               <button
//                 className="inline-flex items-center px-4 py-2 border border-gray-300 shadow-sm text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500"
//                 onClick={() => setFilterOpen(!filterOpen)}
//               >
//                 <Filter className="mr-2 h-4 w-4" />
//                 Filter
//               </button>
//             </div>
//           </div>

//           {/* Advanced Filter Section */}
//           {filterOpen && (
//             <div className="mt-5 pt-5 border-t border-gray-200 grid grid-cols-1 md:grid-cols-3 gap-4">
//               <div>
//                 <label htmlFor="source" className="block text-sm font-medium text-gray-700 mb-1">
//                   Source
//                 </label>
//                 <select
//                   id="source"
//                   className="block w-full pl-3 pr-10 py-2 text-sm border-gray-300 focus:outline-none focus:ring-red-500 focus:border-red-500 rounded-md"
//                   defaultValue="all"
//                 >
//                   <option value="all">All Sources</option>
//                   <option value="twitter">Twitter</option>
//                   <option value="linkedin">LinkedIn</option>
//                   <option value="reddit">Reddit</option>
//                 </select>
//               </div>

//               <div>
//                 <label htmlFor="score" className="block text-sm font-medium text-gray-700 mb-1">
//                   Match Score
//                 </label>
//                 <select
//                   id="score"
//                   className="block w-full pl-3 pr-10 py-2 text-sm border-gray-300 focus:outline-none focus:ring-red-500 focus:border-red-500 rounded-md"
//                   defaultValue="70"
//                 >
//                   <option value="90">90% and above</option>
//                   <option value="80">80% and above</option>
//                   <option value="70">70% and above</option>
//                   <option value="all">All scores</option>
//                 </select>
//               </div>

//               <div>
//                 <label htmlFor="activity" className="block text-sm font-medium text-gray-700 mb-1">
//                   Last Activity
//                 </label>
//                 <select
//                   id="activity"
//                   className="block w-full pl-3 pr-10 py-2 text-sm border-gray-300 focus:outline-none focus:ring-red-500 focus:border-red-500 rounded-md"
//                   defaultValue="7"
//                 >
//                   <option value="1">Last 24 hours</option>
//                   <option value="7">Last 7 days</option>
//                   <option value="30">Last 30 days</option>
//                   <option value="all">All time</option>
//                 </select>
//               </div>

//               <div className="md:col-span-3 flex justify-end">
//                 <button className="inline-flex items-center px-4 py-2 border border-gray-300 shadow-sm text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 mr-2">
//                   Reset Filters
//                 </button>
//                 <button className="inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-red-600 hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500">
//                   Apply Filters
//                 </button>
//               </div>
//             </div>
//           )}

//           {/* Campaign Stats */}
//           <div className="mt-5 pt-5 border-t border-gray-200">
//             <div className="grid grid-cols-2 md:grid-cols-4 gap-4">
//               <div>
//                 <span className="block text-sm font-medium text-gray-500">Match Criteria</span>
//                 <span className="block text-sm text-gray-900 mt-1">{campaigns[0].matchCriteria}</span>
//               </div>
//               <div>
//                 <span className="block text-sm font-medium text-gray-500">Last Updated</span>
//                 <span className="block text-sm text-gray-900 mt-1">{campaigns[0].lastUpdated}</span>
//               </div>
//               <div>
//                 <span className="block text-sm font-medium text-gray-500">Lead Credits Used</span>
//                 <span className="block text-sm text-gray-900 mt-1">42 of 100</span>
//               </div>
//               <div>
//                 <span className="block text-sm font-medium text-gray-500">Status</span>
//                 <span className="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-green-100 text-green-800 mt-1">
//                   <CheckCircle className="mr-1 h-3 w-3" />
//                   Active
//                 </span>
//               </div>
//             </div>
//           </div>
//         </div>

//         {/* Tabs */}
//         <div className="border-b border-gray-200 mb-5">
//           <nav className="flex space-x-8">
//             <button
//               onClick={() => setActiveTab("leads")}
//               className={`pb-4 px-1 font-medium text-sm focus:outline-none ${
//                 activeTab === "leads" ? "text-red-600 border-b-2 border-red-600" : "text-gray-500 hover:text-gray-700"
//               }`}
//             >
//               <div className="flex items-center">
//                 <UserCheck className="h-4 w-4 mr-2" />
//                 Potential Leads ({potentialLeads.length})
//               </div>
//             </button>

//             <button
//               onClick={() => setActiveTab("contacted")}
//               className={`pb-4 px-1 font-medium text-sm focus:outline-none ${
//                 activeTab === "contacted" ? "text-red-600 border-b-2 border-red-600" : "text-gray-500 hover:text-gray-700"
//               }`}
//             >
//               <div className="flex items-center">
//                 <MessageSquare className="h-4 w-4 mr-2" />
//                 Contacted Leads (1)
//               </div>
//             </button>
//           </nav>
//         </div>

//         {/* Lead Table */}
//         {activeTab === "leads" && (
//           <>
//             <div className="bg-white overflow-hidden shadow-sm rounded-lg border border-gray-200 mb-8">
//               <div className="flex items-center justify-between px-6 py-4 border-b border-gray-200">
//                 <div className="flex items-center">
//                   <input
//                     type="checkbox"
//                     className="h-4 w-4 text-red-600 focus:ring-red-500 border-gray-300 rounded"
//                     checked={selectedLeads.length === potentialLeads.length && potentialLeads.length > 0}
//                     onChange={selectAllLeads}
//                   />
//                   <span className="ml-3 text-sm text-gray-700">{selectedLeads.length} selected</span>
//                 </div>

//                 <div className="flex items-center">
//                   <div className="relative mx-2">
//                     <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
//                       <Search className="h-4 w-4 text-gray-400" />
//                     </div>
//                     <input
//                       type="text"
//                       placeholder="Search leads..."
//                       className="block w-full pl-9 pr-3 py-2 border border-gray-300 rounded-md text-sm focus:outline-none focus:ring-red-500 focus:border-red-500"
//                     />
//                   </div>

//                   <select className="ml-2 block py-2 pl-3 pr-10 text-sm border-gray-300 focus:outline-none focus:ring-red-500 focus:border-red-500 rounded-md">
//                     <option>Sort by: Match Score</option>
//                     <option>Recent Activity</option>
//                     <option>Company Name</option>
//                   </select>
//                 </div>
//               </div>

//               <div className="divide-y divide-gray-200">
//                 {potentialLeads.map((lead) => (
//                   <div key={lead.id} className={`px-6 py-4 ${selectedLeads.includes(lead.id) ? "bg-red-50" : ""}`}>
//                     <div className="flex items-start">
//                       <div className="flex-shrink-0 pt-1">
//                         <input
//                           type="checkbox"
//                           className="h-4 w-4 text-red-600 focus:ring-red-500 border-gray-300 rounded"
//                           checked={selectedLeads.includes(lead.id)}
//                           onChange={() => toggleLeadSelection(lead.id)}
//                         />
//                       </div>

//                       <div className="ml-3 flex-1">
//                         <div className="flex justify-between">
//                           <div>
//                             <h3 className="text-base font-medium text-gray-900">{lead.name}</h3>
//                             <p className="text-sm text-gray-600">
//                               {lead.title} at {lead.company}
//                             </p>
//                           </div>

//                           <div className="flex flex-col items-end">
//                             <div className="flex items-center mb-1">
//                               <div className="bg-red-100 text-red-800 text-xs font-medium px-2.5 py-0.5 rounded-full flex items-center">
//                                 <Target className="h-3 w-3 mr-1" />
//                                 {lead.matchScore}% Match
//                               </div>
//                             </div>
//                             <div className="text-xs text-gray-500 flex items-center">
//                               <Clock className="h-3 w-3 mr-1" />
//                               {lead.date}
//                             </div>
//                           </div>
//                         </div>

//                         <div className="mt-2 text-sm text-gray-700">
//                           <p>"{lead.activity}"</p>
//                         </div>

//                         <div className="mt-2 flex flex-wrap items-center justify-between">
//                           <div className="flex items-center space-x-3 text-sm text-gray-500">
//                             <span className="flex items-center">
//                               <span
//                                 className={`h-2 w-2 rounded-full mr-1 ${
//                                   lead.source === "twitter" ? "bg-blue-400" : lead.source === "reddit" ? "bg-orange-400" : "bg-blue-700"
//                                 }`}
//                               ></span>
//                               {lead.source === "twitter" ? "Twitter" : lead.source === "reddit" ? "Reddit" : "LinkedIn"}
//                             </span>
//                             <span>{lead.location}</span>
//                           </div>

//                           <div className="flex space-x-2 mt-2 sm:mt-0">
//                             {lead.contact.email && (
//                               <button className="inline-flex items-center p-1 border border-transparent rounded-full shadow-sm text-white bg-red-600 hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500">
//                                 <Mail className="h-4 w-4" />
//                               </button>
//                             )}

//                             {lead.contact.twitter && (
//                               <button className="inline-flex items-center p-1 border border-transparent rounded-full shadow-sm text-white bg-blue-400 hover:bg-blue-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-400">
//                                 <Twitter className="h-4 w-4" />
//                               </button>
//                             )}

//                             {lead.contact.linkedin && (
//                               <button className="inline-flex items-center p-1 border border-transparent rounded-full shadow-sm text-white bg-blue-700 hover:bg-blue-800 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-700">
//                                 <Linkedin className="h-4 w-4" />
//                               </button>
//                             )}
//                           </div>
//                         </div>
//                       </div>
//                     </div>

//                     <div className="mt-3 ml-7 flex space-x-2 justify-between">
//                       <div className="flex space-x-2">
//                         <button className="inline-flex items-center px-2.5 py-1.5 border border-gray-300 text-xs font-medium rounded text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500">
//                           View Profile
//                         </button>
//                         <button className="inline-flex items-center px-2.5 py-1.5 border border-gray-300 text-xs font-medium rounded text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500">
//                           <MessageSquare className="mr-1 h-3 w-3" />
//                           Message
//                         </button>
//                         <button className="inline-flex items-center px-2.5 py-1.5 border border-gray-300 text-xs font-medium rounded text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500">
//                           <Clipboard className="mr-1 h-3 w-3" />
//                           Copy Outreach Template
//                         </button>
//                       </div>

//                       <button className="inline-flex items-center text-gray-400 hover:text-gray-500">
//                         <ThumbsUp className="h-4 w-4" />
//                       </button>
//                     </div>
//                   </div>
//                 ))}
//               </div>

//               <div className="px-6 py-4 border-t border-gray-200 flex items-center justify-between">
//                 <div className="text-sm text-gray-700">
//                   Showing <span className="font-medium">5</span> of <span className="font-medium">42</span> leads
//                 </div>

//                 <div className="flex space-x-2">
//                   <button className="inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500">
//                     Previous
//                   </button>
//                   <button className="inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500">
//                     Next
//                   </button>
//                 </div>
//               </div>
//             </div>

//             <div className="bg-white overflow-hidden shadow-sm rounded-lg border border-gray-200 mb-8 p-6">
//               <div className="flex items-center justify-between mb-4">
//                 <h3 className="text-lg font-medium text-gray-900">Need more leads?</h3>
//                 <button className="inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-red-600 hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500">
//                   Upgrade Plan
//                   <ArrowRight className="ml-2 h-4 w-4" />
//                 </button>
//               </div>

//               <div className="flex items-center">
//                 <div className="w-full bg-gray-200 rounded-full h-2.5">
//                   <div className="bg-red-600 h-2.5 rounded-full" style={{ width: "42%" }}></div>
//                 </div>
//                 <span className="ml-4 text-sm font-medium text-gray-700">42 of 100 lead credits used</span>
//               </div>
//             </div>
//           </>
//         )}

//         {/* Contacted Leads Tab */}
//         {activeTab === "contacted" && (
//           <div className="bg-white overflow-hidden shadow-sm rounded-lg border border-gray-200 mb-8 p-6">
//             <div className="flex items-center justify-between mb-6">
//               <h3 className="text-lg font-medium text-gray-900">Contacted Leads</h3>
//               <button className="inline-flex items-center px-3 py-1.5 border border-gray-300 shadow-sm text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500">
//                 <Download className="mr-2 h-4 w-4" />
//                 Export
//               </button>
//             </div>

//             <div className="border border-gray-200 rounded-md">
//               <div className="px-6 py-4">
//                 <div className="flex items-start">
//                   <div className="flex-shrink-0 pt-1">
//                     <div className="h-10 w-10 rounded-full bg-blue-100 flex items-center justify-center text-blue-700 font-medium">JW</div>
//                   </div>

//                   <div className="ml-3 flex-1">
//                     <div className="flex justify-between">
//                       <div>
//                         <h3 className="text-base font-medium text-gray-900">James Wilson</h3>
//                         <p className="text-sm text-gray-600">Founder & CEO at RemoteTeams</p>
//                       </div>

//                       <div className="flex items-center">
//                         <div className="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-green-100 text-green-800">
//                           <MessageSquare className="mr-1 h-3 w-3" />
//                           Contacted
//                         </div>
//                       </div>
//                     </div>

//                     <div className="mt-2 text-sm text-gray-700">
//                       <p>"Our team is growing and we're struggling with collaboration. Looking for suggestions"</p>
//                     </div>

//                     <div className="mt-3 flex flex-wrap items-center justify-between">
//                       <div className="flex flex-col sm:flex-row sm:items-center sm:space-x-4 text-xs text-gray-500">
//                         <span className="flex items-center">
//                           <Calendar className="h-3 w-3 mr-1" />
//                           Contacted on: Mar 2, 2025
//                         </span>
//                         <span className="flex items-center mt-1 sm:mt-0">
//                           <Mail className="h-3 w-3 mr-1" />
//                           Via: Email
//                         </span>
//                       </div>

//                       <div className="flex space-x-2 mt-2 sm:mt-0">
//                         <button className="inline-flex items-center px-2.5 py-1.5 border border-gray-300 text-xs font-medium rounded text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500">
//                           View History
//                         </button>
//                         <button className="inline-flex items-center px-2.5 py-1.5 border border-transparent text-xs font-medium rounded shadow-sm text-white bg-red-600 hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500">
//                           Follow Up
//                         </button>
//                       </div>
//                     </div>
//                   </div>
//                 </div>
//               </div>
//             </div>

//             <div className="flex items-center justify-center mt-6">
//               <AlertCircle className="h-5 w-5 text-gray-400 mr-2" />
//               <span className="text-sm text-gray-500">Contact more leads to see them here</span>
//             </div>
//           </div>
//         )}
//       </main>
//     </div>
//   );
// };

// export default LeadFinder;

import React, { useState, useEffect } from "react";
import axios from "axios";
import {
  Search,
  MessageSquare,
  ExternalLink,
  Target,
  Clock,
  Clipboard,
  CheckCircle,
  AlertCircle,
  Edit,
  Save,
  Link as LinkIcon,
  Hash,
  FileText,
  Zap,
  ArrowUp,
  Globe,
  Settings,
  HelpCircle,
  X,
  RefreshCw,
  Info,
} from "lucide-react";
import { Link, useNavigate } from "react-router-dom";
import instance from "./axiosConfig";

const LeadFinder = () => {
  const navigate = useNavigate();
  // State for first-time user experience
  const [isFirstVisit, setIsFirstVisit] = useState(true);
  const [showTutorial, setShowTutorial] = useState(false);
  const [currentTutorialStep, setCurrentTutorialStep] = useState(0);
  // Main application state
  const [activeTab, setActiveTab] = useState("leads");
  const [selectedLeads, setSelectedLeads] = useState([]);
  const [isEditing, setIsEditing] = useState(false);
  const [showUpdateMessage, setShowUpdateMessage] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  // Data states
  const [projects, setProjects] = useState([]);
  const [activeProject, setActiveProject] = useState(null);
  const [leads, setLeads] = useState([]);
  const [sources, setSources] = useState([]);
  const [userCredits, setUserCredits] = useState({
    lead_credits: 0,
    lead_credits_used: 0,
    plan_lead_credits: 0,
  });
  const [searchQuery, setSearchQuery] = useState("");
  const [sortBy, setSortBy] = useState("-match_score");
  const [paginationData, setPaginationData] = useState({
    count: 0,
    next: null,
    previous: null,
    total_pages: 0,
  });
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [minScore, setMinScore] = useState("");
  const [maxScore, setMaxScore] = useState("");
  const [selectedSource, setSelectedSource] = useState("");
  const [dateFrom, setDateFrom] = useState("");
  const [dateTo, setDateTo] = useState("");
  // Form data state for editing
  const [formData, setFormData] = useState({});

  // Check if it's the user's first visit
  useEffect(() => {
    const hasVisitedBefore = localStorage.getItem("hasVisitedLeadFinder");
    if (!hasVisitedBefore) {
      setIsFirstVisit(true);
      setShowTutorial(true);
      localStorage.setItem("hasVisitedLeadFinder", "true");
    } else {
      setIsFirstVisit(false);
    }
  }, []);

  // Fetch projects, sources, and user credits on component mount
  useEffect(() => {
    fetchProjects();
    fetchSources();
    fetchUserCredits();
  }, []);

  useEffect(() => {
    if (activeProject) {
      fetchLeads();
    }
  }, [activeProject]);

  useEffect(() => {
    if (activeProject) {
      // Set loading first
      setIsLoading(true);
      // Clear leads first to avoid showing previous tab's data
      setLeads([]);
      setCurrentPage(1);
      // Slight delay to ensure UI updates
      setTimeout(() => fetchLeads(), 50);
    }
  }, [activeTab]);

  // Handle sort changes
  useEffect(() => {
    if (activeProject) {
      fetchLeads();
    }
  }, [sortBy]);

  // API calls
  const fetchProjects = async () => {
    try {
      setIsLoading(true);
      const response = await instance.get("/projects/");
      if (response.data.length > 0) {
        setProjects(response.data);
        setActiveProject(response.data[0]);
        setFormData(response.data[0]);
      } else {
        // Create demo project for new users
        setActiveProject({
          id: "demo",
          name: "Project Management Software Leads",
          description: "Finding users looking for project management tools and collaboration solutions",
          url: "https://yourproduct.com",
          keywords: "Project Management, Task Management, Team Collaboration",
          created_at: new Date().toISOString(),
          updated_at: new Date().toISOString(),
          lead_count: 0,
          contacted_lead_count: 0,
          credits_used: 0,
        });
        setFormData({
          name: "Project Management Software Leads",
          description: "Finding users looking for project management tools and collaboration solutions",
          url: "https://yourproduct.com",
          keywords: "Project Management, Task Management, Team Collaboration",
        });
      }
      setIsLoading(false);
    } catch (err) {
      console.error("Error fetching projects:", err);
      setError("Failed to load projects. Please try again later.");
      setIsLoading(false);
      // Set demo data for errors or new users
      setActiveProject({
        id: "demo",
        name: "Project Management Software Leads",
        description: "Finding users looking for project management tools and collaboration solutions",
        url: "https://yourproduct.com",
        keywords: "Project Management, Task Management, Team Collaboration",
        created_at: new Date().toISOString(),
        updated_at: new Date().toISOString(),
        lead_count: 0,
        contacted_lead_count: 0,
        credits_used: 0,
      });
      setFormData({
        name: "Project Management Software Leads",
        description: "Finding users looking for project management tools and collaboration solutions",
        url: "https://yourproduct.com",
        keywords: "Project Management, Task Management, Team Collaboration",
      });
    }
  };

  const fetchLeads = async () => {
    try {
      setIsLoading(true);

      // Prepare query parameters
      const params = {
        project: activeProject?.id,
        contacted: activeTab === "contacted" ? "true" : "false", // Send as string for backend
        search: searchQuery,
        sort: sortBy,
        page: currentPage,
        page_size: pageSize,
      };

      // Add optional filters if they're set
      if (minScore) params.min_score = minScore;
      if (maxScore) params.max_score = maxScore;
      if (selectedSource) params.source = selectedSource;
      if (dateFrom) params.date_from = dateFrom;
      if (dateTo) params.date_to = dateTo;

      // Only make API call if we have a real project ID
      if (activeProject?.id && activeProject.id !== "demo") {
        const response = await instance.get("/leads/", { params });

        // Update leads with the data
        setLeads(response.data.results || []);

        // Update pagination data
        setPaginationData({
          count: response.data.count || 0,
          next: response.data.next || null,
          previous: response.data.previous || null,
          total_pages: Math.ceil((response.data.count || 0) / pageSize),
        });
      } else {
        // Demo data for new users or when there's no active project
        const demoLeads = [
          {
            id: 1,
            name: "Sarah Johnson",
            title: "Marketing Director at GrowthWave",
            match_score: 92,
            activity: "Looking for a project management tool that integrates with our marketing stack",
            source: { id: 1, name: "twitter", color_code: "#1DA1F2" },
            discovered_at: "2023-03-10T10:30:00Z",
            location: "New York, USA",
            contacted: activeTab === "contacted",
          },
          {
            id: 2,
            name: "Michael Chen",
            title: "Product Manager at TechSolutions Inc.",
            match_score: 87,
            activity: "Frustrated with current task management options. Need something more intuitive",
            source: { id: 2, name: "reddit", color_code: "#FF4500" },
            discovered_at: "2023-03-09T14:20:00Z",
            location: "San Francisco, USA",
            contacted: activeTab === "contacted",
          },
          {
            id: 3,
            name: "James Wilson",
            title: "Founder & CEO at RemoteTeams",
            match_score: 83,
            activity: "Our team is growing and we're struggling with collaboration. Looking for suggestions",
            source: { id: 3, name: "linkedin", color_code: "#0077B5" },
            discovered_at: "2023-03-05T08:45:00Z",
            location: "London, UK",
            contacted: activeTab === "contacted",
          },
        ];

        // Apply the same filters to demo data to simulate backend filtering
        let filteredDemoLeads = demoLeads.filter((lead) => lead.contacted === (activeTab === "contacted"));

        // Apply search filter if provided
        if (searchQuery) {
          const searchLower = searchQuery.toLowerCase();
          filteredDemoLeads = filteredDemoLeads.filter(
            (lead) =>
              lead.name.toLowerCase().includes(searchLower) ||
              lead.activity.toLowerCase().includes(searchLower) ||
              (lead.title && lead.title.toLowerCase().includes(searchLower)) ||
              (lead.company && lead.company.toLowerCase().includes(searchLower))
          );
        }

        // Apply score filters if provided
        if (minScore) {
          filteredDemoLeads = filteredDemoLeads.filter((lead) => lead.match_score >= parseInt(minScore));
        }
        if (maxScore) {
          filteredDemoLeads = filteredDemoLeads.filter((lead) => lead.match_score <= parseInt(maxScore));
        }

        // Apply source filter if provided
        if (selectedSource) {
          filteredDemoLeads = filteredDemoLeads.filter((lead) => lead.source.id === parseInt(selectedSource));
        }

        // Apply sorting
        if (sortBy) {
          // Handle multiple sort fields
          const sortFields = sortBy.split(",");
          filteredDemoLeads.sort((a, b) => {
            for (const field of sortFields) {
              const isDesc = field.startsWith("-");
              const actualField = isDesc ? field.substring(1) : field;

              // Get values to compare based on field
              let valueA = a[actualField];
              let valueB = b[actualField];

              // Skip if values are equal
              if (valueA === valueB) continue;

              // Handle special cases for nested fields
              if (actualField.includes(".")) {
                const parts = actualField.split(".");
                valueA = parts.reduce((obj, part) => obj && obj[part], a);
                valueB = parts.reduce((obj, part) => obj && obj[part], b);
              }

              // Compare values
              if (typeof valueA === "string") {
                const comparison = valueA.localeCompare(valueB);
                return isDesc ? -comparison : comparison;
              } else {
                return isDesc ? valueB - valueA : valueA - valueB;
              }
            }
            return 0;
          });
        }

        // Apply pagination to demo data
        const startIndex = (currentPage - 1) * pageSize;
        const endIndex = startIndex + pageSize;
        const paginatedLeads = filteredDemoLeads.slice(startIndex, endIndex);

        setLeads(paginatedLeads);
        setPaginationData({
          count: filteredDemoLeads.length,
          next: endIndex < filteredDemoLeads.length ? true : null,
          previous: currentPage > 1 ? true : null,
          total_pages: Math.ceil(filteredDemoLeads.length / pageSize),
        });
      }

      setIsLoading(false);
    } catch (err) {
      console.error("Error fetching leads:", err);
      setError("Failed to load leads. Please try again later.");
      setIsLoading(false);

      // Set demo leads for error case
      setLeads([
        {
          id: 1,
          name: "Demo Lead (Error Fallback)",
          title: "This appears when there's an error loading data",
          match_score: 90,
          activity: "Please check your network connection or try again later.",
          source: { id: 1, name: "demo", color_code: "#cccccc" },
          discovered_at: new Date().toISOString(),
          location: "Demo Location",
          contacted: activeTab === "contacted",
        },
      ]);

      // Set basic pagination for error state
      setPaginationData({
        count: 1,
        next: null,
        previous: null,
        total_pages: 1,
      });
    }
  };

  const fetchSources = async () => {
    try {
      const response = await instance.get("/sources/");
      setSources(response.data);
    } catch (err) {
      console.error("Error fetching sources:", err);
      // Set demo sources
      setSources([
        { id: 1, name: "twitter", color_code: "#1DA1F2" },
        { id: 2, name: "reddit", color_code: "#FF4500" },
        { id: 3, name: "linkedin", color_code: "#0077B5" },
      ]);
    }
  };

  const fetchUserCredits = async () => {
    try {
      const response = await instance.get("/credits/");
      setUserCredits(response.data);
    } catch (err) {
      console.error("Error fetching user credits:", err);
      // Set default values in case of error
      setUserCredits({
        lead_credits: 0,
        lead_credits_used: 0,
        plan_lead_credits: 0,
      });
    }
  };

  const saveProject = async () => {
    try {
      if (activeProject.id && activeProject.id !== "demo") {
        // Update existing project
        const response = await instance.put(`/projects/${activeProject.id}/`, formData);
        setActiveProject(response.data);
      } else {
        // Create new project
        const response = await instance.post("/projects/", formData);
        setActiveProject(response.data);
        setProjects([...projects, response.data]);
      }
      setShowUpdateMessage(true);
      setTimeout(() => setShowUpdateMessage(false), 5000);
    } catch (err) {
      console.error("Error saving project:", err);
      alert("Could not save project. Please try again.");
      // For demo, just simulate successful update
      setActiveProject({
        ...activeProject,
        ...formData,
        updated_at: new Date().toISOString(),
      });
      setShowUpdateMessage(true);
      setTimeout(() => setShowUpdateMessage(false), 5000);
    }
    setIsEditing(false);
  };

  const markLeadAsContacted = async (leadId) => {
    try {
      if (leadId && typeof leadId === "number") {
        await instance.post(`/leads/${leadId}/mark_contacted/`);
      }

      // Update lead list immediately for UI
      setLeads(leads.map((lead) => (lead.id === leadId ? { ...lead, contacted: true } : lead)));

      // Refetch data to ensure sync with server
      fetchProjects();
      // Wait a moment before refetching leads to ensure server has updated
      setTimeout(() => fetchLeads(), 300);
    } catch (err) {
      console.error("Error marking lead as contacted:", err);
      alert("Could not update lead status. Please try again.");

      // For demo, update the UI anyway
      setLeads(leads.map((lead) => (lead.id === leadId ? { ...lead, contacted: true } : lead)));
    }
  };

  const initiateLeadScan = async () => {
    try {
      if (activeProject.id && activeProject.id !== "demo") {
        const response = await instance.post("/scan/", {
          project_id: activeProject.id,
        });

        if (response.status === 201) {
          alert("Lead scan initiated successfully! Check back tomorrow for new leads.Updates will be processed overnight.");
          // Refresh user credits after scan
          fetchUserCredits();
        }
      } else {
        alert("Please save your project first before scanning for leads.");
      }
    } catch (err) {
      console.error("Error initiating lead scan:", err);

      if (err.response && err.response.status === 402) {
        if (alert("Not enough lead credits. Would you like to upgrade your plan?")) {
          navigate("/credits/");
        }
      } else {
        alert("Could not start lead scan. Please try again later.");
      }
    }
  };

  //   const getSuggestedComment = async (leadId) => {
  //     try {
  //       const response = await instance.get(`/leads/${leadId}/suggest_comment/`);

  //       // Copy to clipboard
  //       navigator.clipboard
  //         .writeText(response.data.suggested_comment)
  //         .then(() => alert("Comment copied to clipboard!"))
  //         .catch((err) => {
  //           console.error("Could not copy to clipboard:", err);
  //           alert("Comment template: " + response.data.suggested_comment);
  //         });
  //     } catch (err) {
  //       console.error("Error getting suggested comment:", err);

  //       // Demo comment
  //       const demoComment = `Hi [Name], I noticed you're looking for a project management solution. Our tool might be a great fit for your needs. Would you be open to a quick demo?`;

  //       navigator.clipboard
  //         .writeText(demoComment)
  //         .then(() => alert("Demo comment copied to clipboard!"))
  //         .catch(() => alert("Demo comment template: " + demoComment));
  //     }
  //   };

  // Form handling functions

  const getSuggestedComment = async (leadId) => {
    try {
      const response = await instance.get(`/leads/${leadId}/suggest_comment/`);

      // Copy to clipboard
      navigator.clipboard
        .writeText(response.data.suggested_comment)
        .then(() => alert("Comment copied to clipboard!"))
        .catch((err) => {
          console.error("Could not copy to clipboard:", err);
          alert("Comment template: " + response.data.suggested_comment);
        });
    } catch (err) {
      console.error("Error getting suggested comment:", err);

      // Fallback comment
      const fallbackComment = `Hi, I noticed you're looking for a solution. Would you be open to a quick demo?`;

      navigator.clipboard
        .writeText(fallbackComment)
        .then(() => alert("Fallback comment copied to clipboard!"))
        .catch(() => alert("Fallback comment template: " + fallbackComment));
    }
  };

  const handleFormChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const enterEditMode = () => {
    setFormData({ ...activeProject });
    setIsEditing(true);
  };

  const cancelEdit = () => {
    setFormData({ ...activeProject });
    setIsEditing(false);
  };

  const toggleLeadSelection = (id) => {
    if (selectedLeads.includes(id)) {
      setSelectedLeads(selectedLeads.filter((leadId) => leadId !== id));
    } else {
      setSelectedLeads([...selectedLeads, id]);
    }
  };

  const selectAllLeads = () => {
    const nonContactedLeads = leads.filter((lead) => !lead.contacted);
    if (selectedLeads.length === nonContactedLeads.length) {
      setSelectedLeads([]);
    } else {
      setSelectedLeads(nonContactedLeads.map((lead) => lead.id));
    }
  };

  const getSourceColor = (source) => {
    if (!source) return "bg-gray-400";

    if (typeof source === "object") {
      if (source.color_code) {
        // Use Tailwind classes instead of dynamic colors
        switch (source.name.toLowerCase()) {
          case "twitter":
            return "bg-blue-400";
          case "reddit":
            return "bg-orange-400";
          case "linkedin":
            return "bg-blue-700";
          default:
            return "bg-gray-400";
        }
      }

      switch (source.name.toLowerCase()) {
        case "twitter":
          return "bg-blue-400";
        case "reddit":
          return "bg-orange-400";
        case "linkedin":
          return "bg-blue-700";
        default:
          return "bg-gray-400";
      }
    }

    switch (source.toLowerCase()) {
      case "twitter":
        return "bg-blue-400";
      case "reddit":
        return "bg-orange-400";
      case "linkedin":
        return "bg-blue-700";
      default:
        return "bg-gray-400";
    }
  };

  const getSourceName = (source) => {
    if (!source) return "Unknown";

    if (typeof source === "object") {
      return source.name.charAt(0).toUpperCase() + source.name.slice(1);
    }

    return source.charAt(0).toUpperCase() + source.slice(1);
  };

  // Tutorial navigation
  const nextTutorialStep = () => {
    if (currentTutorialStep < tutorialSteps.length - 1) {
      setCurrentTutorialStep(currentTutorialStep + 1);
    } else {
      setShowTutorial(false);
    }
  };

  const prevTutorialStep = () => {
    if (currentTutorialStep > 0) {
      setCurrentTutorialStep(currentTutorialStep - 1);
    }
  };

  const closeTutorial = () => {
    setShowTutorial(false);
  };

  const showTutorialAgain = () => {
    setCurrentTutorialStep(0);
    setShowTutorial(true);
  };

  // Tutorial steps
  const tutorialSteps = [
    {
      title: "Welcome to Lead Finder!",
      description: "This tool helps you discover potential customers who are actively looking for products like yours.",
      target: "welcome",
    },
    {
      title: "Project Setup",
      description: "Your product information is pre-populated. Edit anytime to improve lead matching.",
      target: "project-setup",
    },
    {
      title: "Lead List",
      description:
        "Here you'll find potential customers who mentioned needing a solution like yours. Each card shows what they said and how good a match they are.",
      target: "lead-list",
    },
    {
      title: "Actions",
      description: "For each lead, you can view their post, copy a personalized outreach message, or mark them as contacted.",
      target: "lead-actions",
    },
  ];

  // Format date for display
  const formatDate = (dateString) => {
    if (!dateString) return "Unknown date";

    const date = new Date(dateString);
    const now = new Date();
    const diffTime = Math.abs(now - date);
    const diffDays = Math.floor(diffTime / (1000 * 60 * 60 * 24));

    if (diffDays === 0) {
      return "Today";
    } else if (diffDays === 1) {
      return "Yesterday";
    } else if (diffDays < 7) {
      return `${diffDays} days ago`;
    } else if (diffDays < 30) {
      return `${Math.floor(diffDays / 7)} weeks ago`;
    } else {
      return date.toLocaleDateString();
    }
  };

  // Check if user is low on credits
  const isLowOnCredits = () => {
    return userCredits.lead_credits < 5;
  };

  // Loading screen
  if (isLoading && !activeProject) {
    return (
      <div className="flex items-center justify-center h-screen">
        <div className="text-center">
          <div className="animate-spin rounded-full h-12 w-12 border-b-2 border-green-500 mx-auto"></div>
          <p className="mt-3 text-gray-600">Loading your leads...</p>
        </div>
      </div>
    );
  }

  return (
    <div className="min-h-screen bg-gray-50">
      {/* Tutorial Overlay */}
      {showTutorial && (
        <div className="fixed inset-0 bg-black bg-opacity-50 z-50 flex items-center justify-center">
          <div className="bg-white rounded-lg shadow-xl p-6 max-w-md">
            <div className="flex justify-between items-center mb-4">
              <h3 className="text-xl font-bold text-gray-900">{tutorialSteps[currentTutorialStep].title}</h3>
              <button onClick={closeTutorial} className="text-gray-400 hover:text-gray-500">
                <X className="h-5 w-5" />
              </button>
            </div>
            <p className="text-gray-600 mb-6">{tutorialSteps[currentTutorialStep].description}</p>
            <div className="flex justify-between">
              <button
                onClick={prevTutorialStep}
                disabled={currentTutorialStep === 0}
                className={`px-4 py-2 rounded-md ${
                  currentTutorialStep === 0 ? "bg-gray-100 text-gray-400 cursor-not-allowed" : "bg-gray-100 text-gray-700 hover:bg-gray-200"
                }`}
              >
                Previous
              </button>
              <button onClick={nextTutorialStep} className="px-4 py-2 bg-green-600 text-white rounded-md hover:bg-green-700">
                {currentTutorialStep < tutorialSteps.length - 1 ? "Next" : "Get Started"}
              </button>
            </div>
          </div>
        </div>
      )}

      {/* Header */}
      <header className="h-16 border-b border-gray-100 bg-white shadow-sm flex items-center px-8 sticky top-0 z-10">
        <div className="max-w-6xl w-full mx-auto flex justify-between items-center">
          <span className="font-bold text-xl text-green-600">FeedbackbyAI</span>
          <div className="flex items-center space-x-4">
            <button onClick={showTutorialAgain} className="text-gray-500 hover:text-gray-700" title="Show tutorial">
              <HelpCircle className="h-5 w-5" />
            </button>
            <Link to="/dashboard/" className="text-gray-500 hover:text-gray-700 font-medium">
              Dashboard
            </Link>
          </div>
        </div>
      </header>

      {/* Main Content */}
      <main className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-8">
        <div id="welcome" className="mb-6 flex justify-between items-center">
          <div>
            <h1 className="text-3xl font-bold text-gray-900 mb-2">Lead Finder</h1>
            <p className="text-gray-600">Discover potential customers actively looking for solutions like yours</p>
          </div>
          <div className="flex items-center space-x-2">
            <span className="text-sm text-gray-500 font-medium">
              Last scan: {activeProject ? formatDate(activeProject.updated_at) : "Today"}
            </span>
          </div>
        </div>

        {/* Update Message */}
        {showUpdateMessage && (
          <div className="bg-green-50 border border-green-100 rounded-lg p-4 mb-6 flex items-center justify-between">
            <div className="flex items-center">
              <RefreshCw className="h-5 w-5 text-green-500 mr-2" />
              <p className="text-green-800 text-sm">
                <span className="font-medium">Your product information has been updated!</span> Your leads dashboard will be refreshed with
                better-matched leads by tomorrow morning.
              </p>
            </div>
            <button onClick={() => setShowUpdateMessage(false)} className="text-green-500 hover:text-green-700">
              <X className="h-4 w-4" />
            </button>
          </div>
        )}

        {/* Quick Help Banner for returning users */}
        {!isFirstVisit && !showTutorial && !showUpdateMessage && (
          <div className="bg-blue-50 border border-blue-100 rounded-lg p-4 mb-6 flex items-center justify-between">
            <div className="flex items-center">
              <Info className="h-5 w-5 text-blue-500 mr-2" />
              <p className="text-blue-800 text-sm">
                <span className="font-medium">Edit your product details</span> anytime to improve lead matching. Updates will be processed
                overnight.
              </p>
            </div>
            <button onClick={(e) => e.target.closest(".bg-blue-50").classList.add("hidden")} className="text-blue-500 hover:text-blue-700">
              <X className="h-4 w-4" />
            </button>
          </div>
        )}

        {/* Low Credits Warning */}
        {isLowOnCredits() && (
          <div className="bg-yellow-50 border border-yellow-100 rounded-lg p-4 mb-6 flex items-center justify-between">
            <div className="flex items-center">
              <AlertCircle className="h-5 w-5 text-yellow-500 mr-2" />
              <p className="text-yellow-800 text-sm">
                <span className="font-medium">You're running low on lead credits!</span> Add more credits to continue finding potential
                customers.
              </p>
            </div>
            <Link
              to="/credits/"
              className="inline-flex items-center px-3 py-1.5 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-yellow-600 hover:bg-yellow-700"
            >
              <Zap className="mr-1.5 h-4 w-4" />
              Add Credits
            </Link>
          </div>
        )}

        {/* Active Project Overview with Edit Mode */}
        <div id="project-setup" className="bg-white rounded-lg shadow-sm border border-gray-200 p-6 mb-8 relative overflow-hidden">
          {/* Project Header */}
          <div className="flex justify-between items-center mb-4">
            <div>
              <h2 className="text-xl font-bold text-gray-900">Product Information</h2>
              <p className="text-sm text-gray-500">Last updated: {activeProject ? formatDate(activeProject.updated_at) : "Today"}</p>
            </div>
            <button
              id="edit-button-special"
              type="button"
              className="flex items-center px-3 py-1.5 bg-green-500 text-white rounded-md hover:bg-green-600 transition-colors"
              style={{
                position: "relative",
                zIndex: 100,
              }}
              onClick={() => {
                if (isEditing) {
                  saveProject();
                } else {
                  enterEditMode();
                }
              }}
            >
              {isEditing ? <Save className="h-4 w-4 mr-1" /> : <Edit className="h-4 w-4 mr-1" />}
              {isEditing ? "Save" : "Edit"}
            </button>
          </div>

          {/* Decorative Element */}
          <div className="absolute -top-10 -right-10 w-40 h-40 bg-green-50 rounded-full opacity-50"></div>

          {isEditing ? (
            /* Edit Mode */
            <div className="grid grid-cols-1 md:grid-cols-2 gap-6 relative z-10">
              <div className="space-y-4"></div>
              <div>
                <label htmlFor="name" className="block text-sm font-medium text-gray-700 mb-1">
                  Product Name
                </label>
                <input
                  id="name"
                  name="name"
                  type="text"
                  className="w-full p-2 border border-gray-300 rounded-md focus:ring-green-500 focus:border-green-500"
                  value={formData.name || ""}
                  onChange={handleFormChange}
                />
              </div>
              <div>
                <label htmlFor="description" className="block text-sm font-medium text-gray-700 mb-1">
                  Product Description
                </label>
                <textarea
                  id="description"
                  name="description"
                  rows="3"
                  className="w-full p-2 border border-gray-300 rounded-md focus:ring-green-500 focus:border-green-500"
                  value={formData.description || ""}
                  onChange={handleFormChange}
                ></textarea>
              </div>

              <div className="space-y-4">
                <div>
                  <label htmlFor="url" className="block text-sm font-medium text-gray-700 mb-1">
                    Product URL
                  </label>
                  <div className="flex">
                    <span className="inline-flex items-center px-3 rounded-l-md border border-r-0 border-gray-300 bg-gray-50 text-gray-500">
                      <LinkIcon className="h-4 w-4" />
                    </span>
                    <input
                      id="url"
                      name="url"
                      type="text"
                      className="flex-1 p-2 border border-gray-300 rounded-r-md focus:ring-green-500 focus:border-green-500"
                      value={formData.url || ""}
                      onChange={handleFormChange}
                    />
                  </div>
                </div>
                <div>
                  <label htmlFor="keywords" className="block text-sm font-medium text-gray-700 mb-1">
                    Keywords (separated by commas)
                  </label>
                  <div className="flex">
                    <span className="inline-flex items-center px-3 rounded-l-md border border-r-0 border-gray-300 bg-gray-50 text-gray-500">
                      <Hash className="h-4 w-4" />
                    </span>
                    <input
                      id="keywords"
                      name="keywords"
                      type="text"
                      className="flex-1 p-2 border border-gray-300 rounded-r-md focus:ring-green-500 focus:border-green-500"
                      value={formData.keywords || ""}
                      onChange={handleFormChange}
                    />
                  </div>
                </div>
                <div className="flex justify-end pt-2 space-x-3">
                  <button
                    onClick={cancelEdit}
                    className="inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-md shadow-sm text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500"
                  >
                    Cancel
                  </button>
                  <button
                    type="button"
                    onClick={saveProject}
                    className="inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-green-600 hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500"
                  >
                    Save Changes
                  </button>
                </div>
              </div>
              <div className="md:col-span-2 bg-blue-50 p-4 rounded-md border border-blue-100">
                <div className="flex items-start">
                  <Info className="h-5 w-5 text-blue-500 mr-2 mt-0.5" />
                  <p className="text-sm text-blue-800">
                    When you save changes to your product information, our AI will analyze it overnight to find better-matched leads for
                    you. Your dashboard will update with new leads by tomorrow morning.
                  </p>
                </div>
              </div>
            </div>
          ) : (
            /* View Mode */
            <div className="relative z-10">
              <div className="grid grid-cols-1 md:grid-cols-3 gap-6 mb-6">
                <div className="bg-green-50 p-4 rounded-lg border border-green-100">
                  <div className="flex items-center mb-2">
                    <FileText className="h-5 w-5 text-green-600 mr-2" />
                    <h3 className="font-medium text-green-800">Product Name</h3>
                  </div>
                  <p className="text-gray-800">{activeProject ? activeProject.name : "Loading..."}</p>
                </div>
                <div className="bg-green-50 p-4 rounded-lg border border-green-100">
                  <div className="flex items-center mb-2">
                    <LinkIcon className="h-5 w-5 text-green-600 mr-2" />
                    <h3 className="font-medium text-green-800">Product URL</h3>
                  </div>

                  <a
                    href={activeProject ? activeProject.url : "#"}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="text-green-600 hover:underline truncate block"
                  >
                    {activeProject ? activeProject.url : "https://yourproduct.com"}
                  </a>
                </div>
                <div className="bg-green-50 p-4 rounded-lg border border-green-100">
                  <div className="flex items-center mb-2">
                    <Hash className="h-5 w-5 text-green-600 mr-2" />
                    <h3 className="font-medium text-green-800">Keywords</h3>
                  </div>
                  <p className="text-gray-800">{activeProject ? activeProject.keywords : "Loading..."}</p>
                </div>
              </div>
              <div className="bg-gray-50 p-4 rounded-lg border border-gray-100">
                <div className="flex items-center mb-2">
                  <FileText className="h-5 w-5 text-gray-600 mr-2" />
                  <h3 className="font-medium text-gray-800">Description</h3>
                </div>
                <p className="text-gray-700">{activeProject ? activeProject.description : "Loading..."}</p>
              </div>
              {/* Credits Overview */}
              <div className="mt-6 pt-6 border-t border-gray-200">
                <div className="flex flex-wrap items-center justify-between gap-4">
                  <div>
                    <h3 className="text-lg font-medium text-gray-900 mb-1">Lead Credits</h3>
                    <div className="flex items-center">
                      <div className="w-64 bg-gray-200 rounded-full h-2.5 mr-4">
                        <div
                          className="bg-green-600 h-2.5 rounded-full"
                          style={{
                            width: `${((userCredits.lead_credits_used || 0) / (userCredits.plan_lead_credits || 1)) * 100}%`,
                          }}
                        ></div>
                      </div>
                      <span className="text-sm font-medium text-gray-700">
                        {userCredits.lead_credits_used || 0} of {userCredits.plan_lead_credits || 0} used
                      </span>
                    </div>
                  </div>
                  <button
                    onClick={initiateLeadScan}
                    className="inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-green-600 hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500"
                    disabled={isLowOnCredits()}
                  >
                    <Target className="mr-2 h-4 w-4" />
                    Find New Leads
                  </button>
                </div>
              </div>
            </div>
          )}
        </div>

        {/* Leads Section */}
        <div id="lead-list" className="bg-white rounded-lg shadow-sm border border-gray-200 mb-8 overflow-hidden">
          <div className="border-b border-gray-200">
            <nav className="flex">
              <button
                onClick={() => {
                  setActiveTab("leads");
                  setCurrentPage(1); // Reset page when changing tabs
                  setTimeout(() => fetchLeads(), 0); // Use setTimeout to ensure state updates first
                }}
                className={`px-6 py-4 font-medium text-sm focus:outline-none flex-1 ${
                  activeTab === "leads"
                    ? "text-green-600 border-b-2 border-green-600 bg-green-50"
                    : "text-gray-500 hover:text-gray-700 hover:bg-gray-50"
                }`}
              >
                <div className="flex items-center justify-center">
                  <Target className="h-4 w-4 mr-2" />
                  Potential Leads ({activeProject ? activeProject.lead_count - activeProject.contacted_lead_count : 0})
                </div>
              </button>
              <button
                onClick={() => {
                  setActiveTab("contacted");
                  setCurrentPage(1); // Reset page when changing tabs
                  setTimeout(() => fetchLeads(), 0); // Use setTimeout to ensure state updates first
                }}
                className={`px-6 py-4 font-medium text-sm focus:outline-none flex-1 ${
                  activeTab === "contacted"
                    ? "text-green-600 border-b-2 border-green-600 bg-green-50"
                    : "text-gray-500 hover:text-gray-700 hover:bg-gray-50"
                }`}
              >
                <div className="flex items-center justify-center">
                  <MessageSquare className="h-4 w-4 mr-2" />
                  Contacted Leads ({activeProject ? activeProject.contacted_lead_count : 0})
                </div>
              </button>
            </nav>
          </div>

          {/* Lead Search and Sort */}
          <div className="flex items-center justify-between px-6 py-4 border-b border-gray-200 bg-gray-50">
            <div className="flex items-center">
              <input
                type="checkbox"
                className="h-4 w-4 text-green-600 focus:ring-green-500 border-gray-300 rounded"
                checked={
                  selectedLeads.length === leads.filter((lead) => !lead.contacted).length &&
                  leads.filter((lead) => !lead.contacted).length > 0
                }
                onChange={selectAllLeads}
              />
              <span className="ml-3 text-sm text-gray-700">{selectedLeads.length} selected</span>
            </div>
            {/* <div className="flex items-center">
              <div className="relative mx-2">
                <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                  <Search className="h-4 w-4 text-gray-400" />
                </div>
                <input
                  type="text"
                  placeholder="Search leads..."
                  className="block w-full pl-9 pr-3 py-2 border border-gray-300 rounded-md text-sm focus:outline-none focus:ring-green-500 focus:border-green-500"
                  value={searchQuery}
                  onChange={(e) => setSearchQuery(e.target.value)}
                  onKeyDown={(e) => {
                    if (e.key === "Enter") {
                      setCurrentPage(1);
                      fetchLeads();
                    }
                  }}
                />
              </div>
              <select
                className="ml-2 block py-2 pl-3 pr-10 text-sm border-gray-300 focus:outline-none focus:ring-green-500 focus:border-green-500 rounded-md"
                value={sortBy}
                onChange={(e) => {
                  setSortBy(e.target.value);
                  setCurrentPage(1);
                  setTimeout(() => fetchLeads(), 0);
                }}
              >
                <option value="-match_score">Sort by: Match Score</option>
                <option value="-discovered_at">Most Recent</option>
                <option value="discovered_at">Oldest First</option>
                <option value="name">Name (A-Z)</option>
              </select>
            </div> */}
          </div>

          {/* Loading State */}
          {isLoading && (
            <div className="flex items-center justify-center py-20">
              <div className="animate-spin rounded-full h-10 w-10 border-b-2 border-green-500"></div>
              <span className="ml-3 text-gray-600">Loading leads...</span>
            </div>
          )}

          {/* Error State */}
          {error && !isLoading && (
            <div className="flex flex-col items-center justify-center py-16">
              <div className="w-16 h-16 bg-red-100 rounded-full flex items-center justify-center mb-4">
                <AlertCircle className="h-8 w-8 text-red-500" />
              </div>
              <h3 className="text-lg font-medium text-gray-900 mb-1">Error loading leads</h3>
              <p className="text-sm text-gray-500 mb-4">{error}</p>
              <button
                onClick={() => {
                  setError(null);
                  fetchLeads();
                }}
                className="inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-green-600 hover:bg-green-700"
              >
                <RefreshCw className="mr-2 h-4 w-4" />
                Try Again
              </button>
            </div>
          )}

          {/* Empty State */}
          {!isLoading && !error && leads.length === 0 && (
            <div className="flex flex-col items-center justify-center py-16">
              <div className="w-16 h-16 bg-gray-100 rounded-full flex items-center justify-center mb-4">
                <AlertCircle className="h-8 w-8 text-gray-400" />
              </div>
              <h3 className="text-lg font-medium text-gray-900 mb-1">
                {activeTab === "leads" ? "No potential leads found" : "No contacted leads yet"}
              </h3>
              <p className="text-sm text-gray-500 mb-4">
                {activeTab === "leads"
                  ? "Edit your product details or initiate a new lead scan to find potential customers"
                  : "Start contacting potential leads to see them here"}
              </p>
              {activeTab === "leads" && !isLowOnCredits() && (
                <button
                  onClick={initiateLeadScan}
                  className="inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-green-600 hover:bg-green-700"
                >
                  <Target className="mr-2 h-4 w-4" />
                  Find New Leads
                </button>
              )}
              {activeTab === "leads" && isLowOnCredits() && (
                <Link
                  to="/credits/"
                  className="inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-yellow-600 hover:bg-yellow-700"
                >
                  <Zap className="mr-2 h-4 w-4" />
                  Add More Credits
                </Link>
              )}
            </div>
          )}

          {/* Lead Cards */}
          {!isLoading && !error && leads.length > 0 && activeTab === "leads" && (
            <div className="divide-y divide-gray-200">
              {leads
                .filter((lead) => !lead.contacted)
                .map((lead, index) => (
                  <div
                    key={lead.id}
                    className={`px-6 py-5 ${selectedLeads.includes(lead.id) ? "bg-green-50" : "hover:bg-gray-50"}`}
                    id={index === 0 ? "lead-actions" : ""}
                  >
                    <div className="flex items-start">
                      <div className="flex-shrink-0 pt-1">
                        <input
                          type="checkbox"
                          className="h-4 w-4 text-green-600 focus:ring-green-500 border-gray-300 rounded"
                          checked={selectedLeads.includes(lead.id)}
                          onChange={() => toggleLeadSelection(lead.id)}
                        />
                      </div>
                      <div className="ml-3 flex-1">
                        <div className="flex justify-between">
                          <div>
                            <h3 className="text-base font-semibold text-gray-900">{lead.name}</h3>
                            {lead.title && <p className="text-sm text-gray-600">{lead.title}</p>}
                          </div>
                          <div className="flex flex-col items-end">
                            <div className="flex items-center mb-1">
                              <div
                                className={`bg-green-100 text-green-800 text-xs font-medium px-2.5 py-1 rounded-full flex items-center ${
                                  lead.match_score > 85 ? "bg-green-200 text-green-900" : ""
                                }`}
                              >
                                <Target className="h-3 w-3 mr-1" />
                                {lead.match_score}% Match
                              </div>
                            </div>
                            <div className="text-xs text-gray-500 flex items-center">
                              <Clock className="h-3 w-3 mr-1" />
                              {formatDate(lead.discovered_at)}
                            </div>
                          </div>
                        </div>
                        <div className="mt-2 text-sm text-gray-700 p-3 bg-gray-50 rounded-md border border-gray-100 italic">
                          "{lead.activity}"
                        </div>
                        <div className="mt-3 flex flex-wrap items-center justify-between">
                          <div className="flex items-center space-x-3 text-sm text-gray-500">
                            <span className="flex items-center">
                              <span className={`h-2 w-2 rounded-full mr-1 ${getSourceColor(lead.source)}`}></span>
                              {getSourceName(lead.source)}
                            </span>
                            {lead.location && (
                              <span className="flex items-center">
                                <Globe className="h-3 w-3 mr-1 text-gray-400" />
                                {lead.location}
                              </span>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="mt-4 ml-7 flex space-x-3">
                      <button
                        className="inline-flex items-center px-3 py-1.5 border border-gray-300 text-xs font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500 shadow-sm"
                        onClick={() => {
                          if (lead.original_post_url) {
                            window.open(lead.original_post_url, "_blank");
                          } else {
                            alert("Original post URL not available for this lead.");
                          }
                        }}
                      >
                        <ExternalLink className="mr-1.5 h-3 w-3" />
                        View Post/Profile
                      </button>
                      <button
                        className="inline-flex items-center px-3 py-1.5 border border-gray-300 text-xs font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500 shadow-sm"
                        onClick={() => getSuggestedComment(lead.id)}
                      >
                        <Clipboard className="mr-1.5 h-3 w-3" />
                        Copy Outreach Template
                      </button>
                      <button
                        className="inline-flex items-center px-3 py-1.5 border border-transparent text-xs font-medium rounded-md shadow-sm text-white bg-green-600 hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500"
                        onClick={() => markLeadAsContacted(lead.id)}
                      >
                        <CheckCircle className="mr-1.5 h-3 w-3" />
                        Mark as Contacted
                      </button>
                    </div>
                  </div>
                ))}
            </div>
          )}

          {/* Contacted Leads Tab */}
          {!isLoading && !error && leads.length > 0 && activeTab === "contacted" && (
            <div className="divide-y divide-gray-200">
              {leads
                .filter((lead) => lead.contacted)
                .map((lead) => (
                  <div key={lead.id} className="px-6 py-5 hover:bg-gray-50">
                    <div className="flex items-start">
                      <div className="h-10 w-10 rounded-full bg-green-100 flex items-center justify-center text-green-700 font-semibold mr-3">
                        {lead.name
                          .split(" ")
                          .map((name) => name[0])
                          .join("")}
                      </div>
                      <div className="flex-1">
                        <div className="flex justify-between">
                          <div>
                            <h3 className="text-base font-semibold text-gray-900">{lead.name}</h3>
                            {lead.title && <p className="text-sm text-gray-600">{lead.title}</p>}
                          </div>
                          <div className="flex items-center">
                            <div className="inline-flex items-center px-2.5 py-1 rounded-full text-xs font-medium bg-green-100 text-green-800">
                              <MessageSquare className="mr-1 h-3 w-3" />
                              Contacted
                            </div>
                          </div>
                        </div>
                        <div className="mt-2 text-sm text-gray-700 p-3 bg-gray-50 rounded-md border border-gray-100 italic">
                          "{lead.activity}"
                        </div>
                        <div className="mt-3 flex items-center justify-between">
                          <div className="flex items-center space-x-3 text-sm text-gray-500">
                            <span className="flex items-center">
                              <span className={`h-2 w-2 rounded-full mr-1 ${getSourceColor(lead.source)}`}></span>
                              {getSourceName(lead.source)}
                            </span>
                            {lead.location && (
                              <span className="flex items-center">
                                <Globe className="h-3 w-3 mr-1 text-gray-400" />
                                {lead.location}
                              </span>
                            )}
                          </div>
                          <button
                            className="inline-flex items-center px-3 py-1.5 border border-gray-300 text-xs font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500 shadow-sm"
                            onClick={() => {
                              if (lead.original_post_url) {
                                window.open(lead.original_post_url, "_blank");
                              } else {
                                alert("Original post URL not available for this lead.");
                              }
                            }}
                          >
                            <ExternalLink className="mr-1.5 h-3 w-3" />
                            View Post/Profile
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
            </div>
          )}

          {/* Pagination */}
          {!isLoading && !error && leads.length > 0 && (
            <div className="px-6 py-4 border-t border-gray-200 flex items-center justify-between bg-gray-50">
              <div className="text-sm text-gray-700">
                Showing <span className="font-medium">{leads.length}</span> of{" "}
                <span className="font-medium">{paginationData.count || 0}</span> leads
              </div>
              <div className="flex space-x-2">
                <button
                  className={`inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500 shadow-sm ${
                    !paginationData.previous ? "opacity-50 cursor-not-allowed" : ""
                  }`}
                  onClick={() => {
                    if (paginationData.previous) {
                      setCurrentPage(currentPage - 1);
                      fetchLeads();
                    }
                  }}
                  disabled={!paginationData.previous}
                >
                  Previous
                </button>
                <button
                  className={`inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500 shadow-sm ${
                    !paginationData.next ? "opacity-50 cursor-not-allowed" : ""
                  }`}
                  onClick={() => {
                    if (paginationData.next) {
                      setCurrentPage(currentPage + 1);
                      fetchLeads();
                    }
                  }}
                  disabled={!paginationData.next}
                >
                  Next
                </button>
              </div>
            </div>
          )}
        </div>
      </main>
    </div>
  );
};

export default LeadFinder;
