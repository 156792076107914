import { useState } from "react";
import { motion, AnimatePresence } from "framer-motion";
import { formatDistanceToNow } from "date-fns";
import {
  Sparkles,
  Clock,
  Bookmark,
  MessageSquare,
  ChevronDown,
  ChevronUp,
  Tag, // For category tag
  TrendingUp, // For potential score
  Tags, // For keyword tags
  AlertTriangle, // For Core Problem (Example)
  Target, // For Underserved Gap (Example)
  BarChart, // For Problem Evidence (Example)
  Lightbulb, // For Solution Concept (Example)
  Compass, // For Strategic Angle (Example)
  Info, // For AI Overall Assessment (Example)
  Disc, // For default bullet points
} from "lucide-react"; // Assuming you use lucide-react for icons

// Helper function for styling category tags (example)
const getTagStyle = (tag) => {
  switch (tag?.toLowerCase()) {
    case "unmet need":
      return "bg-blue-100 text-blue-700";
    case "feature request":
      return "bg-purple-100 text-purple-700";
    case "competitor alert":
      return "bg-red-100 text-red-700";
    case "product idea":
      return "bg-yellow-100 text-yellow-700";
    case "new trend":
      return "bg-teal-100 text-teal-700";
    default:
      return "bg-gray-100 text-gray-700";
  }
};

// Helper function for styling potential score (example)
const getScoreStyle = (score) => {
  if (!score) return "text-gray-500";
  const numericScore = parseInt(score.split("/")[0], 10);
  if (numericScore >= 8) return "text-green-600 font-semibold";
  if (numericScore >= 5) return "text-yellow-600 font-semibold";
  return "text-red-600 font-semibold";
};

export function InsightCard({ insight, index, expandedCards, toggleExpand, bookmarkedPainPoints, handleToggleBookmark }) {
  // Assuming insights data structure matches the final prompt output

  // Define icons for sections (customize as needed)
  const sectionIcons = {
    core_problem: <AlertTriangle size={18} className="text-blue-600" />,
    underserved_gap: <Target size={18} className="text-emerald-600" />,
    problem_evidence: <BarChart size={18} className="text-amber-600" />,
    solution_concept: <Lightbulb size={18} className="text-purple-600" />,
    strategic_angle: <Compass size={18} className="text-indigo-600" />,
    ai_overall_assessment: <Info size={18} className="text-gray-600" />,
  };

  const sectionTextColors = {
    core_problem: "text-blue-900",
    underserved_gap: "text-emerald-900",
    problem_evidence: "text-amber-900",
    solution_concept: "text-purple-900",
    strategic_angle: "text-indigo-900",
    ai_overall_assessment: "text-gray-900",
  };

  return (
    <motion.div
      key={insight.id}
      initial={{ opacity: 0, y: 20 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ delay: index * 0.1, duration: 0.4 }}
      className="bg-white rounded-2xl overflow-hidden transition-all duration-300 hover:shadow-xl border border-gray-100 group hover:border-emerald-200" // Use consistent theme color for hover border
    >
      {/* Top status bar */}
      <div className="h-1.5 bg-gradient-to-r from-emerald-400 to-emerald-600 w-full"></div>

      <div className="p-6 sm:p-8">
        {/* Header with bookmark and timestamp */}
        <div className="flex justify-between items-start mb-4">
          {/* Left side: Icon, Timestamp, Category, Score */}
          <div className="flex items-center flex-wrap gap-x-3 gap-y-1">
            <div className="w-8 h-8 rounded-full bg-emerald-100 flex items-center justify-center flex-shrink-0">
              <Sparkles size={16} className="text-emerald-600" />
            </div>
            <span className="text-xs text-gray-500 bg-gray-50 px-2 py-1 rounded-full flex items-center">
              <Clock size={12} className="mr-1.5 flex-shrink-0" />
              Discovered: {formatDistanceToNow(new Date(insight.created_at), { addSuffix: true })}
            </span>
            {/* --- NEW: Category Tag --- */}
            {insight.details?.category_tag && (
              <span
                className={`text-xs font-medium px-2.5 py-1 rounded-full flex items-center ${getTagStyle(insight.details.category_tag)}`}
              >
                <Tag size={12} className="mr-1.5 flex-shrink-0" />
                {insight.details.category_tag}
              </span>
            )}
            {/* --- NEW: Potential Score --- */}
            {insight.details?.potential_score && (
              <span className="text-xs text-gray-500 bg-gray-50 px-2 py-1 rounded-full flex items-center">
                <TrendingUp size={12} className="mr-1.5 flex-shrink-0" />
                <span className={getScoreStyle(insight.details.potential_score)}>{insight.details.potential_score}</span>
                <span className="ml-1">Potential</span>
              </span>
            )}
          </div>

          {/* Right side: Bookmark */}
          <div className="flex items-center space-x-2 flex-shrink-0 pl-2">
            <button
              className={`p-2 rounded-full transition-all duration-200 ${
                bookmarkedPainPoints.has(insight.id)
                  ? "bg-emerald-100 text-emerald-600 hover:bg-emerald-200" // Use theme color
                  : "bg-gray-50 text-gray-400 hover:bg-gray-100 hover:text-gray-600"
              }`}
              title={bookmarkedPainPoints.has(insight.id) ? "Remove from Bookmarks" : "Save to Bookmarks"}
              onClick={() => handleToggleBookmark(insight.id)}
            >
              <Bookmark size={18} className={bookmarkedPainPoints.has(insight.id) ? "fill-emerald-600" : ""} />
            </button>
          </div>
        </div>

        {/* Title Section */}
        <h3 className="text-xl sm:text-2xl font-bold text-gray-900 leading-tight mb-3 group-hover:text-emerald-700 transition-colors duration-300">
          {insight.details?.validated_idea || "No Idea Provided"}
        </h3>

        {/* --- NEW: AI Summary --- */}
        {insight.details?.ai_summary && <p className="text-gray-600 text-base mb-5">{insight.details.ai_summary}</p>}

        {/* Customer Voice Quote */}
        <div className="mb-6 relative">
          <div className="absolute -left-1 top-0 bottom-0 w-1.5 bg-gradient-to-b from-emerald-400 to-emerald-600 rounded-full"></div>
          {/* Subtle background, stronger border */}
          <div className="bg-gray-50/50 p-5 sm:p-6 rounded-xl border border-gray-200">
            <div className="flex mb-3 items-center">
              <MessageSquare size={16} className="text-emerald-600 mr-2 flex-shrink-0" />
              <span className="text-sm font-medium text-emerald-700">Voice of Customer</span>
            </div>
            <p className="text-gray-700 text-base sm:text-lg italic">
              "{insight.details?.voice_of_customer || "No customer feedback available."}"
            </p>
          </div>
        </div>

        {/* Action Button */}
        <button
          onClick={() => toggleExpand(index)}
          // Slightly more subtle button style example
          className="w-full group bg-white border border-gray-300 hover:border-emerald-500 hover:bg-emerald-50 text-emerald-700 py-3 px-6 rounded-xl transition-all duration-300 flex items-center justify-center font-medium focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-emerald-500"
        >
          {expandedCards.includes(index) ? (
            <>
              <ChevronUp size={20} className="mr-2 transition-transform duration-300" />
              <span>Hide Analysis Details</span>
            </>
          ) : (
            <>
              <ChevronDown size={20} className="mr-2 transition-transform duration-300" />
              <span>View Complete Analysis</span>
            </>
          )}
        </button>

        {/* Expandable Content */}
        <AnimatePresence>
          {expandedCards.includes(index) && (
            <motion.div
              initial={{ opacity: 0, height: 0 }}
              animate={{ opacity: 1, height: "auto" }}
              exit={{ opacity: 0, height: 0 }}
              transition={{ duration: 0.4, ease: "easeInOut" }}
              className="mt-6 pt-6 border-t border-gray-100 space-y-6 overflow-hidden" // Added top border for separation
            >
              {/* --- NEW: Keyword Tags --- */}
              {insight.details?.keyword_tags && insight.details.keyword_tags.length > 0 && (
                <div className="flex flex-wrap gap-2">
                  <span className="text-sm font-medium text-gray-600 mr-1 flex items-center">
                    <Tags size={14} className="mr-1.5" /> Keywords:
                  </span>
                  {insight.details.keyword_tags.map((tag, idx) => (
                    <span key={idx} className="text-xs bg-gray-100 text-gray-700 px-2.5 py-1 rounded-full">
                      {tag}
                    </span>
                  ))}
                </div>
              )}

              {/* --- REVISED: Sections using map for consistency --- */}
              {[
                { key: "core_problem", title: "Core Problem" },
                { key: "underserved_gap", title: "Underserved Gap" },
                { key: "problem_evidence", title: "Problem Evidence" },
                { key: "solution_concept", title: "Solution Concept" },
                { key: "strategic_angle", title: "Strategic Angle" },
                { key: "ai_overall_assessment", title: "AI Overall Assessment" }, // New section added
              ].map(
                (section) =>
                  insight.details?.[section.key] && (
                    // Cleaner section styling: subtle border, white bg, themed icon/text
                    <div key={section.key} className="bg-white p-5 sm:p-6 rounded-xl border border-gray-100">
                      <div className="flex items-center mb-4">
                        {sectionIcons[section.key] || <Info size={18} className="text-gray-600" />}
                        <h4 className={`ml-3 text-lg font-semibold ${sectionTextColors[section.key] || "text-gray-900"}`}>
                          {section.title}
                        </h4>
                      </div>
                      {/* Render as list or paragraph based on data type */}
                      {Array.isArray(insight.details[section.key]) ? (
                        <ul className="space-y-3 pl-1">
                          {insight.details[section.key].map((item, idx) => (
                            <li key={idx} className="flex items-start">
                              {/* Simple bullet point or themed icon */}
                              <span className={`mr-3 mt-1 ${sectionTextColors[section.key] || "text-gray-600"}`}>
                                <Disc size={8} className="fill-current" />
                              </span>
                              <span className="text-gray-700">{item}</span>
                            </li>
                          ))}
                        </ul>
                      ) : (
                        <p className="text-gray-700 pl-1">{insight.details[section.key]}</p>
                      )}
                    </div>
                  )
              )}
            </motion.div>
          )}
        </AnimatePresence>
      </div>
    </motion.div>
  );
}
