import React, { useState } from "react";
import { Link } from "react-router-dom";

export const metadata = {
  title: "Reset Password - Simple",
  description: "Page description",
};

export default function ForgotPassword() {
  const [email, setEmail] = useState("");
  const [feedbackMsg, setFeedbackMsg] = useState("");
  const [isError, setIsError] = useState(false);

  const handleResetPassword = async (e) => {
    e.preventDefault();

    const res = await fetch(`${process.env.REACT_APP_API_BASE_URL}forgot-password/`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        email,
      }),
    });

    const data = await res.json();

    if (res.status === 200) {
      setIsError(false);
      setFeedbackMsg(data.message);
    } else {
      setIsError(true);
      setFeedbackMsg(data.error || "Something went wrong.");
    }
  };

  return (
    <section className="bg-gradient-to-b from-gray-100 to-white">
      <div className="max-w-6xl mx-auto px-4 sm:px-6">
        <nav className=" py-4 mb-8 ">
          <div className="container mx-auto">
            <div className="flex justify-between items-center">
              {/* Navigation text to go to homepage */}
              <Link to="/" className="text-2xl font-bold text-gray-800">
                FeedbackbyAI
              </Link>
              {/* (Add other nav items here if needed in the future) */}
            </div>
          </div>
        </nav>
        <div className="pt-32 pb-12 md:pt-40 md:pb-20">
          <div className="max-w-3xl mx-auto text-center pb-12 md:pb-20">
            <h1 className="h1 mb-4">Let's get you back up on your feet</h1>
            <p className="text-xl text-gray-600">
              Enter the email address you used when you signed up for your account, and we'll email you a link to reset your password.
            </p>
          </div>

          <div className="max-w-sm mx-auto">
            <form onSubmit={handleResetPassword}>
              <div className="flex flex-wrap -mx-3 mb-4">
                <div className="w-full px-3">
                  {feedbackMsg && <p className={`text-sm mt-2 ${isError ? "text-red-500" : "text-green-500"}`}>{feedbackMsg}</p>}
                  <label className="block text-gray-800 text-sm font-medium mb-1" htmlFor="email">
                    Email <span className="text-red-600">*</span>
                  </label>
                  <input
                    id="email"
                    type="email"
                    className="form-input w-full text-gray-800"
                    placeholder="Enter your email address"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    required
                  />
                </div>
              </div>
              <div className="flex flex-wrap -mx-3 mt-6">
                <div className="w-full px-3">
                  <button className="btn text-white bg-green-600 hover:bg-green-700 w-full">Send reset link</button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </section>
  );
}
