// import React, { useState, useEffect, useRef } from "react";
// import { Link } from "react-router-dom";
// import instance from "./axiosConfig";
// import { Play, FileVideo, Download, X, Search, RefreshCw, Plus, Share2, Loader2, AlertCircle, Clock, CheckCircle } from "lucide-react";

// const VideoLibrary = () => {
//   const [videos, setVideos] = useState([]);
//   const [loading, setLoading] = useState(true);
//   const [searchTerm, setSearchTerm] = useState("");
//   const [playingVideo, setPlayingVideo] = useState(null);

//   // Use useRef instead of useState for video refs to prevent infinite loop
//   const videoRefs = useRef({});

//   const checkIndividualVideoStatus = async (videoId) => {
//     try {
//       const response = await instance.get(`api/video-status/${videoId}/`, {
//         params: { force: "true" },
//       });

//       console.log("Individual status check:", response.data);

//       if (response.data.is_processed) {
//         // Refresh all videos to get the latest data
//         fetchVideos();
//         showNotification("Video Ready", "Your video has finished processing!", "success");
//         return true;
//       } else if (response.data.error) {
//         showNotification("Processing Error", response.data.error, "error");
//         return false;
//       } else {
//         showNotification("Still Processing", "Your video is still being processed. This typically takes 2-5 minutes.", "info");
//         return false;
//       }
//     } catch (error) {
//       console.error("Error checking video status:", error);
//       showNotification("Connection Error", "Couldn't check video status", "error");
//       return false;
//     }
//   };
//   // Fetch all videos (both processing and completed)

//   const fetchVideos = async () => {
//     setLoading(true);
//     try {
//       // Always force refresh to get the latest status
//       const response = await instance.get("api/all-user-videos/", {
//         params: { force: "true" },
//       });

//       console.log("Fetched videos:", response.data);

//       // Sort by newest first
//       const sortedVideos = response.data.sort((a, b) => new Date(b.created_at) - new Date(a.created_at));

//       setVideos(sortedVideos);

//       // Check if any pending videos completed since last check
//       const newlyCompleted = sortedVideos.filter((video) => video.is_processed && !videos.find((v) => v.id === video.id && v.is_processed));

//       // Notify user if videos completed
//       if (newlyCompleted.length > 0) {
//         showNotification(
//           "Videos Ready!",
//           `${newlyCompleted.length} video${newlyCompleted.length > 1 ? "s" : ""} finished processing.`,
//           "success"
//         );
//       }
//     } catch (error) {
//       console.error("Failed to fetch videos:", error);
//       showNotification("Error", "Failed to fetch your videos", "error");
//     } finally {
//       setLoading(false);
//     }
//   };

//   // const fetchVideos = async () => {
//   //   setLoading(true);
//   //   try {
//   //     // const response = await instance.get("api/all-user-videos/");
//   //     const response = await instance.get("api/all-user-videos/", {
//   //       params: { force: "true" }, // <--- this
//   //     });

//   //     console.log("Fetched videos:", response.data);

//   //     // Sort by newest first
//   //     const sortedVideos = response.data.sort((a, b) => new Date(b.created_at) - new Date(a.created_at));
//   //     setVideos(sortedVideos);
//   //   } catch (error) {
//   //     console.error("Failed to fetch videos:", error);
//   //     showNotification("Error", "Failed to fetch your videos", "error");
//   //   } finally {
//   //     setLoading(false);
//   //   }
//   // };

//   // Load videos on mount
//   useEffect(() => {
//     fetchVideos();

//     // Set up periodic refresh (every 30 seconds)
//     const refreshInterval = setInterval(() => {
//       fetchVideos();
//     }, 30000);

//     return () => clearInterval(refreshInterval);
//   }, []);

//   // Format date for display
//   const formatDate = (dateString) => {
//     return new Date(dateString).toLocaleDateString(undefined, {
//       year: "numeric",
//       month: "short",
//       day: "numeric",
//     });
//   };

//   // Time since function
//   const getTimeSince = (dateString) => {
//     const now = new Date();
//     const date = new Date(dateString);
//     const diffSeconds = Math.floor((now - date) / 1000);

//     if (diffSeconds < 60) return "Just now";
//     if (diffSeconds < 3600) return `${Math.floor(diffSeconds / 60)} minutes ago`;
//     if (diffSeconds < 86400) return `${Math.floor(diffSeconds / 3600)} hours ago`;
//     if (diffSeconds < 604800) return `${Math.floor(diffSeconds / 86400)} days ago`;
//     return formatDate(dateString);
//   };

//   // Notification function
//   const showNotification = (title, message, type = "info") => {
//     // Create notification container if it doesn't exist
//     let container = document.getElementById("notification-container");
//     if (!container) {
//       container = document.createElement("div");
//       container.id = "notification-container";
//       container.className = "fixed top-4 right-4 z-50 flex flex-col gap-2";
//       document.body.appendChild(container);
//     }

//     // Create notification element
//     const notification = document.createElement("div");
//     notification.className = `max-w-md p-4 mb-4 rounded-lg shadow-lg flex items-start gap-3 transform translate-x-0 transition-all duration-500 ${
//       type === "error"
//         ? "bg-red-50 border-l-4 border-red-500"
//         : type === "success"
//         ? "bg-green-50 border-l-4 border-green-500"
//         : type === "warning"
//         ? "bg-amber-50 border-l-4 border-amber-500"
//         : "bg-blue-50 border-l-4 border-blue-500"
//     }`;

//     const iconColor =
//       type === "error" ? "text-red-500" : type === "success" ? "text-green-500" : type === "warning" ? "text-amber-500" : "text-blue-500";

//     // Add content
//     notification.innerHTML = `
//       <div className="flex-shrink-0">
//         ${
//           type === "error"
//             ? `<svg class="w-5 h-5 ${iconColor}" fill="none" viewBox="0 0 24 24" stroke="currentColor">
//             <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 8v4m0 4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
//           </svg>`
//             : type === "success"
//             ? `<svg class="w-5 h-5 ${iconColor}" fill="none" viewBox="0 0 24 24" stroke="currentColor">
//             <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z" />
//           </svg>`
//             : type === "warning"
//             ? `<svg class="w-5 h-5 ${iconColor}" fill="none" viewBox="0 0 24 24" stroke="currentColor">
//             <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 9v2m0 4h.01m-6.938 4h13.856c1.54 0 2.502-1.667 1.732-3L13.732 4c-.77-1.333-2.694-1.333-3.464 0L3.34 16c-.77 1.333.192 3 1.732 3z" />
//           </svg>`
//             : `<svg class="w-5 h-5 ${iconColor}" fill="none" viewBox="0 0 24 24" stroke="currentColor">
//             <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
//           </svg>`
//         }
//       </div>
//       <div class="flex-1">
//         <h3 class="font-medium text-gray-900">${title}</h3>
//         <p class="text-sm text-gray-600">${message}</p>
//       </div>
//       <button class="flex-shrink-0 text-gray-400 hover:text-gray-600" onclick="this.parentElement.remove()">
//         <svg class="w-4 h-4" fill="none" viewBox="0 0 24 24" stroke="currentColor">
//           <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12" />
//         </svg>
//       </button>
//     `;

//     // Add to container
//     container.appendChild(notification);

//     // Auto remove after 6 seconds
//     setTimeout(() => {
//       notification.style.opacity = "0";
//       notification.style.transform = "translateX(100%)";
//       setTimeout(() => {
//         if (notification.parentElement) {
//           notification.remove();
//         }
//       }, 300);
//     }, 6000);
//   };

//   // Filter videos by search term
//   const filteredVideos = videos.filter((video) => {
//     if (!searchTerm) return true;

//     // Search in caption text
//     const caption = video.hook_caption?.text || "";
//     return caption.toLowerCase().includes(searchTerm.toLowerCase());
//   });

//   // Video hover handlers
//   // const handleMouseEnter = (videoId) => {
//   //   const videoElement = videoRefs.current[videoId];
//   //   if (videoElement && videoElement.readyState >= 2) {
//   //     videoElement.play().catch((err) => {
//   //       console.log("Preview playback prevented:", err);
//   //     });
//   //   }
//   // };

//   const handleMouseEnter = (videoId) => {
//     const videoElement = videoRefs.current[videoId];
//     if (videoElement && videoElement.readyState >= 2) {
//       videoElement.currentTime = 0;
//       videoElement.load(); // ensures proper buffer
//       videoElement.play().catch((err) => {
//         console.log("Preview playback prevented:", err);
//       });
//     }
//   };

//   const handleMouseLeave = (videoId) => {
//     const videoElement = videoRefs.current[videoId];
//     if (videoElement) {
//       videoElement.pause();
//       videoElement.currentTime = 0;
//     }
//   };

//   // Process status for display
//   const getStatusInfo = (video) => {
//     if (video.is_processed) {
//       return {
//         label: "Ready",
//         color: "bg-green-100 text-green-800",
//         icon: <CheckCircle className="w-3 h-3" />,
//       };
//     } else if (video.processing_error) {
//       return {
//         label: "Error",
//         color: "bg-red-100 text-red-800",
//         icon: <AlertCircle className="w-3 h-3" />,
//       };
//     } else {
//       return {
//         label: "Processing",
//         color: "bg-blue-100 text-blue-800",
//         icon: <Clock className="w-3 h-3" />,
//       };
//     }
//   };

//   // Format time display (MM:SS)
//   const formatTime = (time) => {
//     const minutes = Math.floor(time / 60);
//     const seconds = Math.floor(time % 60);
//     return `${minutes}:${seconds.toString().padStart(2, "0")}`;
//   };

//   // Handle video timeline scrubbing
//   const handleTimelineScrub = (e, progressRef, videoElement) => {
//     if (!videoElement || !progressRef) return;

//     const rect = progressRef.getBoundingClientRect();
//     const clickPosition = e.clientX - rect.left;
//     const percentage = clickPosition / rect.width;

//     videoElement.currentTime = percentage * videoElement.duration;
//   };

//   // Toggle video playback
//   const togglePlayback = (videoElement) => {
//     if (!videoElement) return;

//     if (videoElement.paused) {
//       videoElement.play().catch((error) => {
//         console.error("Video playback failed:", error);
//       });
//     } else {
//       videoElement.pause();
//     }
//   };

//   // Inline Video Player component

//   // Improved InlineVideoPlayer component for VideoLibrary.jsx
//   const InlineVideoPlayer = ({ video, onClose }) => {
//     const videoRef = useRef(null);
//     const progressRef = useRef(null);
//     const [isPlaying, setIsPlaying] = useState(false);
//     const [currentTime, setCurrentTime] = useState(0);
//     const [duration, setDuration] = useState(0);
//     const [isLoading, setIsLoading] = useState(true);
//     const [hasError, setHasError] = useState(false);

//     useEffect(() => {
//       const videoEl = videoRef.current;
//       if (videoEl) {
//         // Set up event listeners with error handling
//         const updatePlayingState = () => setIsPlaying(!videoEl.paused);

//         const updateTime = () => {
//           // Safety check to prevent errors with currentTime
//           if (videoEl.currentTime <= videoEl.duration) {
//             setCurrentTime(videoEl.currentTime);
//           }
//         };

//         const setInitialDuration = () => {
//           setDuration(videoEl.duration);
//           setIsLoading(false);
//         };

//         const handleError = (e) => {
//           console.error("Video error:", e);
//           setHasError(true);
//           setIsLoading(false);
//         };

//         const handleLoadStart = () => {
//           setIsLoading(true);
//           setHasError(false);
//         };

//         videoEl.addEventListener("play", updatePlayingState);
//         videoEl.addEventListener("pause", updatePlayingState);
//         videoEl.addEventListener("timeupdate", updateTime);
//         videoEl.addEventListener("loadedmetadata", setInitialDuration);
//         videoEl.addEventListener("error", handleError);
//         videoEl.addEventListener("loadstart", handleLoadStart);

//         // Clean up
//         return () => {
//           videoEl.removeEventListener("play", updatePlayingState);
//           videoEl.removeEventListener("pause", updatePlayingState);
//           videoEl.removeEventListener("timeupdate", updateTime);
//           videoEl.removeEventListener("loadedmetadata", setInitialDuration);
//           videoEl.removeEventListener("error", handleError);
//           videoEl.removeEventListener("loadstart", handleLoadStart);
//         };
//       }
//     }, []);

//     // Improved seeking function to prevent jumping issues
//     const handleTimelineScrub = (e) => {
//       if (!videoRef.current || !progressRef.current) return;

//       const rect = progressRef.current.getBoundingClientRect();
//       const clickPosition = Math.max(0, Math.min(e.clientX - rect.left, rect.width));
//       const percentage = clickPosition / rect.width;

//       // Set the time with validation
//       const newTime = percentage * (videoRef.current.duration || 0);
//       if (isFinite(newTime) && newTime >= 0 && newTime <= videoRef.current.duration) {
//         videoRef.current.currentTime = newTime;
//         setCurrentTime(newTime);
//       }
//     };

//     // Format time display (MM:SS)
//     const formatTime = (timeInSeconds) => {
//       if (!isFinite(timeInSeconds)) return "0:00";
//       const minutes = Math.floor(timeInSeconds / 60);
//       const seconds = Math.floor(timeInSeconds % 60);
//       return `${minutes}:${seconds.toString().padStart(2, "0")}`;
//     };

//     // Toggle video playback with better error handling
//     const togglePlayback = () => {
//       const videoEl = videoRef.current;
//       if (!videoEl) return;

//       if (videoEl.paused) {
//         videoEl
//           .play()
//           .then(() => setIsPlaying(true))
//           .catch((error) => {
//             console.error("Playback failed:", error);
//             setHasError(true);
//           });
//       } else {
//         videoEl.pause();
//         setIsPlaying(false);
//       }
//     };

//     return (
//       <div className="w-full max-w-xs mx-auto scale-100 relative">
//         <div className="relative aspect-[9/16] bg-black rounded-lg overflow-hidden shadow-lg">
//           {isLoading && (
//             <div className="absolute inset-0 flex items-center justify-center bg-black/50 z-10">
//               <div className="flex flex-col items-center">
//                 <div className="w-8 h-8 border-2 border-white border-t-transparent rounded-full animate-spin mb-2"></div>
//                 <span className="text-white text-sm">Loading video...</span>
//               </div>
//             </div>
//           )}

//           {hasError && (
//             <div className="absolute inset-0 flex items-center justify-center bg-black/80 z-10">
//               <div className="flex flex-col items-center p-4 text-center">
//                 <div className="w-12 h-12 bg-red-500/20 rounded-full flex items-center justify-center mb-2">
//                   <AlertCircle className="w-6 h-6 text-red-500" />
//                 </div>
//                 <p className="text-white font-medium mb-1">Video playback error</p>
//                 <p className="text-white/70 text-sm mb-3">The video couldn't be played</p>
//                 <a
//                   href={video.processed_video_url}
//                   download={`video-${video.id}.mp4`}
//                   className="px-3 py-1.5 bg-blue-600 text-white rounded-lg hover:bg-blue-700 text-sm"
//                 >
//                   Download Instead
//                 </a>
//               </div>
//             </div>
//           )}
//           {/*
//           <video
//             ref={videoRef}
//             src={video.processed_video_url}
//             className="w-full h-full object-cover"
//             playsInline
//             autoPlay
//             onError={() => setHasError(true)}
//           /> */}
//           <video
//             ref={videoRef}
//             src={video.processed_video_url}
//             className="w-full h-full object-cover"
//             autoPlay
//             onError={(e) => {
//               console.error("Error playing video:", e);
//               setHasError(true);
//             }}
//             // Add this to prevent auto-restart
//             onEnded={(e) => {
//               e.target.pause();
//               e.target.currentTime = 0;
//             }}
//           />

//           {/* Improved Video Controls */}
//           <div className="absolute bottom-0 left-0 right-0 p-3 bg-gradient-to-t from-black/90 to-transparent">
//             <div
//               ref={progressRef}
//               onClick={handleTimelineScrub}
//               className="w-full h-1.5 bg-white/30 rounded-full mb-3 cursor-pointer relative"
//             >
//               <div
//                 className="absolute inset-y-0 left-0 bg-green-400 rounded-full transition-all duration-100"
//                 style={{ width: `${(currentTime / (duration || 1)) * 100}%` }}
//               />
//               {/* Add a handle for better UX */}
//               <div
//                 className="absolute top-1/2 -translate-y-1/2 w-3 h-3 bg-white rounded-full shadow-md transition-all duration-100 opacity-0 hover:opacity-100"
//                 style={{ left: `calc(${(currentTime / (duration || 1)) * 100}% - 6px)` }}
//               />
//             </div>

//             <div className="flex items-center justify-between">
//               <span className="text-xs text-white select-none">
//                 {formatTime(currentTime)} / {formatTime(duration)}
//               </span>
//               <div className="flex items-center gap-2">
//                 <button onClick={togglePlayback} className="p-1.5 rounded-full bg-white/20 hover:bg-white/30 transition-colors">
//                   {isPlaying ? (
//                     <svg className="w-5 h-5 text-white" fill="none" viewBox="0 0 24 24" stroke="currentColor">
//                       <path
//                         strokeLinecap="round"
//                         strokeLinejoin="round"
//                         strokeWidth={2}
//                         d="M10 9v6m4-6v6M5 20h14a2 2 0 002-2V6a2 2 0 00-2-2H5a2 2 0 00-2 2v12a2 2 0 002 2z"
//                       />
//                     </svg>
//                   ) : (
//                     <svg className="w-5 h-5 text-white" fill="none" viewBox="0 0 24 24" stroke="currentColor">
//                       <path
//                         strokeLinecap="round"
//                         strokeLinejoin="round"
//                         strokeWidth={2}
//                         d="M14.752 11.168l-3.197-2.132A1 1 0 0010 9.87v4.263a1 1 0 001.555.832l3.197-2.132a1 1 0 000-1.664z"
//                       />
//                     </svg>
//                   )}
//                 </button>
//                 <button
//                   onClick={() => {
//                     if (videoRef.current) videoRef.current.pause();
//                     onClose();
//                   }}
//                   className="p-1.5 rounded-full bg-white/20 hover:bg-white/30 transition-colors"
//                 >
//                   <X className="w-5 h-5 text-white" />
//                 </button>
//               </div>
//             </div>
//           </div>
//         </div>

//         <div className="mt-4 flex justify-between items-center">
//           <h3 className="text-lg font-medium text-gray-900 line-clamp-1">{video.hook_caption?.text || `Video ${video.id}`}</h3>
//           <div className="flex gap-2">
//             <a
//               href={video.processed_video_url}
//               download={`video-${video.id}.mp4`}
//               className="px-3 py-1.5 bg-green-600 text-white rounded-lg hover:bg-green-700 text-sm flex items-center gap-1"
//             >
//               <Download className="w-4 h-4" /> Download
//             </a>
//           </div>
//         </div>
//       </div>
//     );
//   };
//   // const InlineVideoPlayer = ({ video, onClose }) => {
//   //   const videoRef = useRef(null);
//   //   const progressRef = useRef(null);
//   //   const [isPlaying, setIsPlaying] = useState(false);
//   //   const [currentTime, setCurrentTime] = useState(0);
//   //   const [duration, setDuration] = useState(0);

//   //   useEffect(() => {
//   //     const videoEl = videoRef.current;
//   //     if (videoEl) {
//   //       const updatePlayingState = () => setIsPlaying(!videoEl.paused);
//   //       const updateTime = () => setCurrentTime(videoEl.currentTime);
//   //       const setInitialDuration = () => setDuration(videoEl.duration);

//   //       videoEl.addEventListener("play", updatePlayingState);
//   //       videoEl.addEventListener("pause", updatePlayingState);
//   //       videoEl.addEventListener("timeupdate", updateTime);
//   //       videoEl.addEventListener("loadedmetadata", setInitialDuration);

//   //       return () => {
//   //         videoEl.removeEventListener("play", updatePlayingState);
//   //         videoEl.removeEventListener("pause", updatePlayingState);
//   //         videoEl.removeEventListener("timeupdate", updateTime);
//   //         videoEl.removeEventListener("loadedmetadata", setInitialDuration);
//   //       };
//   //     }
//   //   }, []);

//   //   return (
//   //     // <div className="w-full max-w-md mx-auto">
//   //     <div className="w-full max-w-xs mx-auto scale-90">
//   //       <div className="relative aspect-[9/16] bg-black rounded-lg overflow-hidden">
//   //         <video
//   //           ref={videoRef}
//   //           src={video.processed_video_url}
//   //           className="w-full h-full object-cover"
//   //           autoPlay
//   //           onError={(e) => {
//   //             console.error("Error playing video:", e);
//   //             showNotification("Error", "Unable to play this video. Try downloading instead.", "error");
//   //           }}
//   //         />

//   //         {/* Video Controls */}
//   //         <div className="absolute bottom-0 left-0 right-0 p-3 bg-gradient-to-t from-black/80 to-transparent">
//   //           <div
//   //             ref={progressRef}
//   //             onClick={(e) => handleTimelineScrub(e, progressRef.current, videoRef.current)}
//   //             className="w-full h-1 bg-white/30 rounded-full mb-3 cursor-pointer relative"
//   //           >
//   //             <div
//   //               className="absolute inset-y-0 left-0 bg-green-400 rounded-full"
//   //               style={{ width: `${(currentTime / (duration || 1)) * 100}%` }}
//   //             />
//   //           </div>
//   //           <div className="flex items-center justify-between">
//   //             <span className="text-xs text-white">
//   //               {formatTime(currentTime)} / {formatTime(duration)}
//   //             </span>
//   //             <div className="flex items-center gap-2">
//   //               <button
//   //                 onClick={() => togglePlayback(videoRef.current)}
//   //                 className="p-1.5 rounded-full bg-white/20 hover:bg-white/30 transition-colors"
//   //               >
//   //                 {isPlaying ? (
//   //                   <svg className="w-4 h-4 text-white" fill="none" viewBox="0 0 24 24" stroke="currentColor">
//   //                     <path
//   //                       strokeLinecap="round"
//   //                       strokeLinejoin="round"
//   //                       strokeWidth={2}
//   //                       d="M10 9v6m4-6v6M5 20h14a2 2 0 002-2V6a2 2 0 00-2-2H5a2 2 0 00-2 2v12a2 2 0 002 2z"
//   //                     />
//   //                   </svg>
//   //                 ) : (
//   //                   <svg className="w-4 h-4 text-white" fill="none" viewBox="0 0 24 24" stroke="currentColor">
//   //                     <path
//   //                       strokeLinecap="round"
//   //                       strokeLinejoin="round"
//   //                       strokeWidth={2}
//   //                       d="M14.752 11.168l-3.197-2.132A1 1 0 0010 9.87v4.263a1 1 0 001.555.832l3.197-2.132a1 1 0 000-1.664z"
//   //                     />
//   //                   </svg>
//   //                 )}
//   //               </button>
//   //               <button onClick={onClose} className="p-1.5 rounded-full bg-white/20 hover:bg-white/30 transition-colors">
//   //                 <X className="w-4 h-4 text-white" />
//   //               </button>
//   //             </div>
//   //           </div>
//   //         </div>
//   //       </div>
//   //       <div className="mt-4 flex justify-between items-center">
//   //         <h3 className="text-lg font-medium text-gray-900">{video.hook_caption?.text || `Video ${video.id}`}</h3>
//   //         <div className="flex gap-2">
//   //           <a
//   //             href={video.processed_video_url}
//   //             download={`video-${video.id}.mp4`}
//   //             className="px-3 py-1.5 bg-green-600 text-white rounded-lg hover:bg-green-700 text-sm flex items-center gap-1"
//   //           >
//   //             <Download className="w-4 h-4" /> Download
//   //           </a>
//   //         </div>
//   //       </div>
//   //     </div>
//   //   );
//   // };

//   return (
//     <div className="min-h-screen bg-gray-50">
//       {/* Header */}
//       <header className="h-16 border-b border-gray-100 flex items-center px-8 sticky top-0 bg-white/95 backdrop-blur-sm z-40">
//         <div className="max-w-6xl w-full mx-auto flex justify-between">
//           <span className="font-semibold text-xl text-green-600">FeedbackbyAI</span>
//           <div className="flex items-center gap-4">
//             <Link to="/video" className="px-4 py-2 bg-green-600 text-white rounded-lg hover:bg-green-700 flex items-center gap-2">
//               <Plus className="w-4 h-4" /> Create New Video
//             </Link>
//             <Link to="/dashboard" className="text-gray-500 hover:text-gray-700">
//               Back to Dashboard
//             </Link>
//           </div>
//         </div>
//       </header>

//       <main className="max-w-6xl mx-auto px-6 py-8">
//         <div className="mb-6">
//           <h1 className="text-2xl font-bold text-gray-900 mb-2">Your Video Library</h1>
//           <p className="text-gray-600">All your videos in one place - both processing and completed</p>
//         </div>

//         {/* Search and Refresh */}
//         <div className="mb-6 flex flex-wrap items-center justify-between gap-4">
//           <div className="relative max-w-md w-full">
//             <input
//               type="text"
//               placeholder="Search videos..."
//               value={searchTerm}
//               onChange={(e) => setSearchTerm(e.target.value)}
//               className="w-full pl-10 pr-4 py-2 bg-white border border-gray-200 rounded-lg focus:ring-2 focus:ring-green-500 focus:border-transparent"
//             />
//             <Search className="w-5 h-5 text-gray-400 absolute left-3 top-1/2 transform -translate-y-1/2" />
//           </div>

//           <button
//             onClick={fetchVideos}
//             className="px-4 py-2 bg-white border border-gray-200 rounded-lg text-gray-700 hover:bg-gray-50 flex items-center gap-2"
//           >
//             <RefreshCw className="w-4 h-4" />
//             Refresh Status
//           </button>
//         </div>

//         {/* Videos Grid with improved handling */}
//         {loading ? (
//           <div className="flex justify-center py-20">
//             <div className="flex flex-col items-center">
//               <Loader2 className="w-10 h-10 text-green-500 animate-spin mb-4" />
//               <p className="text-gray-600">Loading your videos...</p>
//             </div>
//           </div>
//         ) : filteredVideos.length === 0 ? (
//           <div className="flex justify-center py-20">
//             <div className="flex flex-col items-center">
//               <FileVideo className="w-16 h-16 text-gray-300 mb-4" />
//               <h2 className="text-xl font-semibold text-gray-800 mb-2">No videos found</h2>
//               <p className="text-gray-600 mb-6">{searchTerm ? "Try a different search term" : "You haven't created any videos yet"}</p>
//               <Link to="/video" className="px-4 py-2 bg-green-600 text-white rounded-lg hover:bg-green-700 flex items-center gap-2">
//                 <Plus className="w-4 h-4" /> Create Your First Video
//               </Link>
//             </div>
//           </div>
//         ) : (
//           <div className="grid grid-cols-2 sm:grid-cols-3 md:grid-cols-4 lg:grid-cols-5 gap-4">
//             {filteredVideos.map((video) => {
//               const statusInfo = getStatusInfo(video);

//               return (
//                 <div
//                   key={video.id}
//                   className="bg-white rounded-lg shadow-sm border border-gray-200 overflow-hidden hover:shadow-md transition-shadow group"
//                 >
//                   <div className="aspect-[9/16] relative bg-gray-100">
//                     {/* Status indicator */}
//                     <div
//                       className={`absolute top-2 right-2 z-10 px-2 py-1 rounded-full ${statusInfo.color} text-xs font-medium flex items-center gap-1`}
//                     >
//                       {statusInfo.icon}
//                       {statusInfo.label}
//                       {!video.is_processed && !video.processing_error && (
//                         <button
//                           onClick={(e) => {
//                             e.stopPropagation();
//                             instance
//                               .get(`api/video-status/${video.id}/`, { params: { force: "true" } })
//                               .then((response) => {
//                                 console.log("Status check:", response.data);
//                                 if (response.data.is_processed) {
//                                   fetchVideos();
//                                   showNotification("Video Ready", "Your video has finished processing!", "success");
//                                 } else {
//                                   showNotification(
//                                     "Still Processing",
//                                     "Your video is still being processed. This typically takes 2-5 minutes.",
//                                     "info"
//                                   );
//                                 }
//                               })
//                               .catch((err) => {
//                                 console.error("Error checking status:", err);
//                                 showNotification("Error", "Couldn't check video status", "error");
//                               });
//                           }}
//                           className="text-xs ml-1 underline"
//                           title="Check current status"
//                         >
//                           Check
//                         </button>
//                       )}
//                     </div>

//                     {/* For completed videos */}
//                     {video.is_processed ? (
//                       <>
//                         {/* Thumbnail image or placeholder */}
//                         {video.thumbnail_url ? (
//                           <img
//                             src={video.thumbnail_url}
//                             alt={video.hook_caption?.text || `Video ${video.id}`}
//                             className="w-full h-full object-cover group-hover:opacity-0 transition-opacity"
//                             onError={(e) => {
//                               e.target.style.display = "none";
//                               // Show placeholder
//                               if (e.target.parentNode.querySelector(".placeholder")) {
//                                 e.target.parentNode.querySelector(".placeholder").style.display = "flex";
//                               }
//                             }}
//                           />
//                         ) : (
//                           <div className="absolute inset-0 flex items-center justify-center bg-gray-200 group-hover:opacity-0 transition-opacity">
//                             <FileVideo className="w-8 h-8 text-gray-400" />
//                           </div>
//                         )}

//                         {/* Placeholder for image errors */}
//                         <div className="placeholder absolute inset-0 hidden items-center justify-center bg-gray-200 group-hover:opacity-0 transition-opacity">
//                           <FileVideo className="w-8 h-8 text-gray-400" />
//                         </div>

//                         {/* Video element - preload="none" for performance */}
//                         {/* <video
//                           ref={(el) => {
//                             if (el) videoRefs.current[video.id] = el;
//                           }}
//                           src={video.processed_video_url}
//                           className="absolute inset-0 w-full h-full object-cover opacity-0 group-hover:opacity-100 transition-opacity"
//                           muted
//                           loop
//                           preload="none"
//                           onClick={() => setPlayingVideo(video)}
//                           onMouseEnter={() => handleMouseEnter(video.id)}
//                           onMouseLeave={() => handleMouseLeave(video.id)}
//                         /> */}
//                         <video
//                           ref={(el) => {
//                             if (el) videoRefs.current[video.id] = el;
//                           }}
//                           src={video.processed_video_url}
//                           className="absolute inset-0 w-full h-full object-cover opacity-0 group-hover:opacity-100 transition-opacity"
//                           muted
//                           loop={false} // Changed from loop to loop={false}
//                           preload="none"
//                           onClick={() => setPlayingVideo(video)}
//                           onMouseEnter={() => handleMouseEnter(video.id)}
//                           onMouseLeave={() => handleMouseLeave(video.id)}
//                           // Add this to prevent restart issues
//                           onEnded={(e) => {
//                             e.target.pause();
//                             e.target.currentTime = 0;
//                           }}
//                         />

//                         {/* Play button overlay */}
//                         <div className="absolute inset-0 flex items-center justify-center group-hover:opacity-0 transition-opacity">
//                           <button
//                             onClick={() => setPlayingVideo(video)}
//                             className="p-2 rounded-full bg-black/50 text-white hover:bg-black/70 transition-colors"
//                           >
//                             <Play className="w-5 h-5" />
//                           </button>
//                         </div>
//                       </>
//                     ) : (
//                       // For processing videos
//                       <div className="absolute inset-0 flex flex-col items-center justify-center bg-gray-100">
//                         <div className="w-12 h-12 rounded-full bg-blue-50 flex items-center justify-center mb-3">
//                           {video.processing_error ? (
//                             <AlertCircle className="w-6 h-6 text-red-500" />
//                           ) : (
//                             // <Loader2 className="w-6 h-6 text-blue-500 animate-spin" />
//                             console.log("processing!!")
//                           )}
//                         </div>
//                         <p className="text-gray-700 font-medium px-4 text-center text-sm">
//                           {video.processing_error ? "Processing Error" : video.status || "Processing..."}
//                         </p>
//                         <p className="text-xs text-gray-500 mt-1 px-2 text-center">{!video.processing_error && "This takes 2-5 minutes"}</p>
//                         <button
//                           onClick={(e) => {
//                             e.stopPropagation();
//                             instance
//                               .get(`api/video-status/${video.id}/`, { params: { force: "true" } })
//                               .then((response) => {
//                                 console.log("Status check:", response.data);
//                                 if (response.data.is_processed) {
//                                   fetchVideos();
//                                   showNotification("Video Ready", "Your video has finished processing!", "success");
//                                 } else {
//                                   showNotification(
//                                     "Still Processing",
//                                     "Your video is still being processed. This typically takes 2-5 minutes.",
//                                     "info"
//                                   );
//                                 }
//                               })
//                               .catch((err) => {
//                                 console.error("Error checking status:", err);
//                               });
//                           }}
//                           className="mt-2 px-3 py-1 bg-gray-200 hover:bg-gray-300 rounded-lg text-sm flex items-center gap-1"
//                         >
//                           <RefreshCw className="w-3 h-3" /> Check Status
//                         </button>
//                       </div>
//                     )}
//                   </div>

//                   <div className="p-3">
//                     <h3 className="font-medium text-gray-900 mb-1 text-sm line-clamp-1">
//                       {video.hook_caption?.text || `Video ${video.id}`}
//                     </h3>
//                     <p className="text-xs text-gray-500 mb-2">{getTimeSince(video.created_at)}</p>

//                     {video.is_processed ? (
//                       <div className="flex justify-between">
//                         <a
//                           href={video.processed_video_url}
//                           download
//                           className="px-2 py-1 bg-green-600 text-white rounded-lg hover:bg-green-700 text-xs flex items-center gap-1"
//                         >
//                           <Download className="w-3 h-3" /> Download
//                         </a>
//                         <button
//                           onClick={() => {
//                             if (navigator.share) {
//                               navigator.share({
//                                 title: video.hook_caption?.text || "My Video",
//                                 text: "Check out this video I created!",
//                                 url: video.processed_video_url,
//                               });
//                             } else {
//                               navigator.clipboard.writeText(video.processed_video_url);
//                               showNotification("Copied!", "Video link copied to clipboard!", "success");
//                             }
//                           }}
//                           className="p-1 text-gray-500 hover:text-gray-700 rounded-lg"
//                           title="Share"
//                         >
//                           <Share2 className="w-4 h-4" />
//                         </button>
//                       </div>
//                     ) : (
//                       <div className="flex justify-between items-center">
//                         <div className="text-xs text-gray-500">
//                           {video.processing_error ? (
//                             <span className="text-red-500">{video.processing_error.substring(0, 30)}...</span>
//                           ) : (
//                             "Processing..."
//                           )}
//                         </div>
//                       </div>
//                     )}
//                   </div>
//                 </div>
//               );
//             })}
//           </div>
//         )}
//       </main>

//       {/* Inline Video Player */}
//       {playingVideo && (
//         <div
//           className="fixed inset-0 z-50 flex items-center justify-center p-4 bg-black/80 backdrop-blur-sm"
//           onClick={() => setPlayingVideo(null)}
//         >
//           <div className="relative w-full max-w-md" onClick={(e) => e.stopPropagation()}>
//             <InlineVideoPlayer video={playingVideo} onClose={() => setPlayingVideo(null)} />
//           </div>
//         </div>
//       )}
//     </div>
//   );
// };

// export default VideoLibrary;

import React, { useState, useEffect, useRef } from "react";
import { Link } from "react-router-dom";
import instance from "./axiosConfig";
import {
  Play,
  FileVideo,
  Download,
  X,
  Search,
  RefreshCw,
  Plus,
  Share2,
  Loader2,
  AlertCircle,
  Clock,
  CheckCircle,
  Video,
  ChevronRight,
  ExternalLink,
  MoreHorizontal,
  Info,
  Copy,
} from "lucide-react";
import TikTokPhonePreview from "./TikTokPhonePreview";

const VideoLibrary = () => {
  // State management
  const [videos, setVideos] = useState([]);
  const [loading, setLoading] = useState(true);
  const [searchTerm, setSearchTerm] = useState("");
  const [playingVideo, setPlayingVideo] = useState(null);
  const [showFilterMenu, setShowFilterMenu] = useState(false);
  const [filter, setFilter] = useState("all"); // "all", "completed", "processing", "failed"
  const [sortBy, setSortBy] = useState("newest"); // "newest", "oldest"
  const [refreshing, setRefreshing] = useState(false);
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [hasNext, setHasNext] = useState(false);
  const [hasPrevious, setHasPrevious] = useState(false);

  // For video preview
  const videoRefs = useRef({});

  // Notification system
  const showNotification = (title, message, type = "info") => {
    // Create notification container if it doesn't exist
    let container = document.getElementById("notification-container");
    if (!container) {
      container = document.createElement("div");
      container.id = "notification-container";
      container.className = "fixed top-4 right-4 z-50 flex flex-col gap-2";
      document.body.appendChild(container);
    }

    // Create notification element
    const notification = document.createElement("div");
    notification.className = `max-w-md p-4 mb-4 rounded-lg shadow-lg flex items-start gap-3 transform translate-x-0 transition-all duration-500 ${
      type === "error"
        ? "bg-red-50 border-l-4 border-red-500"
        : type === "success"
        ? "bg-green-50 border-l-4 border-green-500"
        : type === "warning"
        ? "bg-amber-50 border-l-4 border-amber-500"
        : "bg-blue-50 border-l-4 border-blue-500"
    }`;

    const iconColor =
      type === "error" ? "text-red-500" : type === "success" ? "text-green-500" : type === "warning" ? "text-amber-500" : "text-blue-500";

    // Add content
    notification.innerHTML = `
      <div class="flex-shrink-0">
        ${
          type === "error"
            ? `<svg class="w-5 h-5 ${iconColor}" fill="none" viewBox="0 0 24 24" stroke="currentColor">
            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 8v4m0 4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
          </svg>`
            : type === "success"
            ? `<svg class="w-5 h-5 ${iconColor}" fill="none" viewBox="0 0 24 24" stroke="currentColor">
            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z" />
          </svg>`
            : type === "warning"
            ? `<svg class="w-5 h-5 ${iconColor}" fill="none" viewBox="0 0 24 24" stroke="currentColor">
            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 9v2m0 4h.01m-6.938 4h13.856c1.54 0 2.502-1.667 1.732-3L13.732 4c-.77-1.333-2.694-1.333-3.464 0L3.34 16c-.77 1.333.192 3 1.732 3z" />
          </svg>`
            : `<svg class="w-5 h-5 ${iconColor}" fill="none" viewBox="0 0 24 24" stroke="currentColor">
            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
          </svg>`
        }
      </div>
      <div class="flex-1">
        <h3 class="font-medium text-gray-900">${title}</h3>
        <p class="text-sm text-gray-600">${message}</p>
      </div>
      <button class="flex-shrink-0 text-gray-400 hover:text-gray-600" onclick="this.parentElement.remove()">
        <svg class="w-4 h-4" fill="none" viewBox="0 0 24 24" stroke="currentColor">
          <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12" />
        </svg>
      </button>
    `;

    // Add to container
    container.appendChild(notification);

    // Auto remove after 6 seconds
    setTimeout(() => {
      notification.style.opacity = "0";
      notification.style.transform = "translateX(100%)";
      setTimeout(() => {
        if (notification.parentElement) {
          notification.remove();
        }
      }, 300);
    }, 6000);
  };

  // Fetch all videos (both processing and completed)

  const fetchVideos = async (overridePage = page) => {
    setRefreshing(true);
    try {
      const response = await instance.get("api/all-user-videos/", {
        params: { page: overridePage, page_size: 10, force: "true" },
      });

      const sortedVideos = response.data.results;
      setVideos(sortedVideos);
      setPage(response.data.page);
      setTotalPages(response.data.total_pages);
      setHasPrevious(response.data.has_previous);
      setHasNext(response.data.has_next);
    } catch (error) {
      console.error("Failed to fetch videos:", error);
      showNotification("Error", "Failed to fetch your videos", "error");
    } finally {
      setLoading(false);
      setRefreshing(false);
    }
  };

  const goToNextPage = () => {
    if (hasNext) fetchVideos(page + 1);
  };

  const goToPreviousPage = () => {
    if (hasPrevious) fetchVideos(page - 1);
  };
  // const fetchVideos = async () => {
  //   setRefreshing(true);
  //   try {
  //     // Always force refresh to get latest status
  //     const response = await instance.get("api/all-user-videos/", {
  //       params: { force: "true" },
  //     });

  //     console.log("Fetched videos:", response.data);

  //     // Sort by newest first (or as selected)
  //     let sortedVideos = [...response.data];
  //     if (sortBy === "newest") {
  //       sortedVideos = sortedVideos.sort((a, b) => new Date(b.created_at) - new Date(a.created_at));
  //     } else if (sortBy === "oldest") {
  //       sortedVideos = sortedVideos.sort((a, b) => new Date(a.created_at) - new Date(b.created_at));
  //     }

  //     setVideos(sortedVideos);

  //     // Check if any pending videos completed since last check
  //     const newlyCompleted = sortedVideos.filter((video) => video.is_processed && !videos.find((v) => v.id === video.id && v.is_processed));

  //     // Notify user if videos completed
  //     if (newlyCompleted.length > 0) {
  //       showNotification(
  //         "Videos Ready!",
  //         `${newlyCompleted.length} video${newlyCompleted.length > 1 ? "s" : ""} finished processing.`,
  //         "success"
  //       );
  //     }
  //   } catch (error) {
  //     console.error("Failed to fetch videos:", error);
  //     showNotification("Error", "Failed to fetch your videos", "error");
  //   } finally {
  //     setLoading(false);
  //     setRefreshing(false);
  //   }
  // };

  // Load videos on mount and set up refresh interval
  useEffect(() => {
    fetchVideos();

    // Set up periodic refresh - more frequent if there are processing videos
    const hasProcessingVideos = videos.some((v) => !v.is_processed && !v.processing_error);
    const refreshInterval = setInterval(
      fetchVideos,
      hasProcessingVideos ? 20000 : 60000 // 20 seconds if processing, otherwise 60 seconds
    );

    return () => clearInterval(refreshInterval);
  }, []);

  // Retry video processing
  const retryVideoProcessing = async (videoId) => {
    try {
      // Show loading state
      setVideos((prev) => prev.map((video) => (video.id === videoId ? { ...video, retrying: true } : video)));

      const response = await instance.post(`api/retry-video/${videoId}/`);

      if (response.data && response.data.status === "success") {
        showNotification("Retry Initiated", "Video processing has been restarted. This typically takes 2-5 minutes.", "success");

        // Update the video entry with retry status
        setVideos((prev) =>
          prev.map((video) =>
            video.id === videoId
              ? {
                  ...video,
                  retrying: false,
                  status: "Pending retry",
                  processing_error: null,
                  retry_count: response.data.retry_count,
                }
              : video
          )
        );

        // Refresh all videos after a short delay
        setTimeout(() => fetchVideos(), 2000);
      } else {
        throw new Error(response.data?.message || "Unknown error");
      }
    } catch (error) {
      console.error("Failed to retry video:", error);
      showNotification("Retry Failed", error.response?.data?.message || error.message || "Something went wrong", "error");

      // Reset retrying state
      setVideos((prev) => prev.map((video) => (video.id === videoId ? { ...video, retrying: false } : video)));
    }
  };

  // Check individual video status
  const checkVideoStatus = async (videoId) => {
    try {
      const response = await instance.get(`api/video-status/${videoId}/`, {
        params: { force: "true" },
      });

      console.log("Status check:", response.data);

      // Refresh videos to get updated status
      fetchVideos();

      if (response.data.is_processed) {
        showNotification("Video Ready", "Your video has finished processing!", "success");
        return true;
      } else if (response.data.error) {
        showNotification("Processing Error", response.data.error, "error");
        return false;
      } else {
        showNotification("Still Processing", "Your video is still being processed. This typically takes 2-5 minutes.", "info");
        return false;
      }
    } catch (error) {
      console.error("Error checking status:", error);
      showNotification("Connection Error", "Couldn't check video status", "error");
      return false;
    }
  };

  // Format date for display
  const formatDate = (dateString) => {
    return new Date(dateString).toLocaleDateString(undefined, {
      year: "numeric",
      month: "short",
      day: "numeric",
    });
  };

  // Time since function
  const getTimeSince = (dateString) => {
    const now = new Date();
    const date = new Date(dateString);
    const diffSeconds = Math.floor((now - date) / 1000);

    if (diffSeconds < 60) return "Just now";
    if (diffSeconds < 3600) return `${Math.floor(diffSeconds / 60)} minutes ago`;
    if (diffSeconds < 86400) return `${Math.floor(diffSeconds / 3600)} hours ago`;
    if (diffSeconds < 604800) return `${Math.floor(diffSeconds / 86400)} days ago`;
    return formatDate(dateString);
  };

  // Format time display (MM:SS)
  const formatTime = (time) => {
    if (!time) return "0:00";
    const minutes = Math.floor(time / 60);
    const seconds = Math.floor(time % 60);
    return `${minutes}:${seconds.toString().padStart(2, "0")}`;
  };

  // Video hover handlers
  const handleMouseEnter = (videoId) => {
    const videoElement = videoRefs.current[videoId];
    if (videoElement && videoElement.readyState >= 2) {
      videoElement.currentTime = 0;
      videoElement.play().catch((err) => {
        console.log("Preview playback prevented:", err);
      });
    }
  };

  const handleMouseLeave = (videoId) => {
    const videoElement = videoRefs.current[videoId];
    if (videoElement) {
      videoElement.pause();
      videoElement.currentTime = 0;
    }
  };

  // Process status for display
  const getStatusInfo = (video) => {
    if (video.retrying) {
      return {
        label: "Retrying",
        color: "bg-blue-100 text-blue-800",
        icon: <RefreshCw className="w-3 h-3 animate-spin" />,
      };
    } else if (video.is_processed) {
      return {
        label: "Ready",
        color: "bg-green-100 text-green-800",
        icon: <CheckCircle className="w-3 h-3" />,
      };
    } else if (video.processing_error) {
      return {
        label: "Error",
        color: "bg-red-100 text-red-800",
        icon: <AlertCircle className="w-3 h-3" />,
      };
    } else {
      return {
        label: "Processing",
        color: "bg-blue-100 text-blue-800",
        icon: <Clock className="w-3 h-3" />,
      };
    }
  };

  // Copy video URL to clipboard
  const copyVideoUrl = (url) => {
    navigator.clipboard.writeText(url);
    showNotification("Copied!", "Video URL copied to clipboard", "success");
  };

  // Filter videos by search term and status
  const filteredVideos = videos.filter((video) => {
    // First filter by search term
    const matchesSearch = !searchTerm ? true : (video.hook_caption?.text || "").toLowerCase().includes(searchTerm.toLowerCase());

    // Then filter by status
    let matchesFilter = true;
    if (filter === "completed") {
      matchesFilter = video.is_processed;
    } else if (filter === "processing") {
      matchesFilter = !video.is_processed && !video.processing_error;
    } else if (filter === "failed") {
      matchesFilter = !!video.processing_error;
    }

    return matchesSearch && matchesFilter;
  });

  // Inline Video Player component
  const InlineVideoPlayer = ({ video, onClose }) => {
    const videoRef = useRef(null);
    const progressRef = useRef(null);
    const [isPlaying, setIsPlaying] = useState(false);
    const [currentTime, setCurrentTime] = useState(0);
    const [duration, setDuration] = useState(0);
    const [isLoading, setIsLoading] = useState(true);
    const [hasError, setHasError] = useState(false);

    useEffect(() => {
      const videoEl = videoRef.current;
      if (videoEl) {
        // Set up event listeners with error handling
        const updatePlayingState = () => setIsPlaying(!videoEl.paused);
        const updateTime = () => {
          if (videoEl.currentTime <= videoEl.duration) {
            setCurrentTime(videoEl.currentTime);
          }
        };
        const setInitialDuration = () => {
          setDuration(videoEl.duration);
          setIsLoading(false);
        };
        const handleError = (e) => {
          console.error("Video error:", e);
          setHasError(true);
          setIsLoading(false);
        };
        const handleLoadStart = () => {
          setIsLoading(true);
          setHasError(false);
        };

        videoEl.addEventListener("play", updatePlayingState);
        videoEl.addEventListener("pause", updatePlayingState);
        videoEl.addEventListener("timeupdate", updateTime);
        videoEl.addEventListener("loadedmetadata", setInitialDuration);
        videoEl.addEventListener("error", handleError);
        videoEl.addEventListener("loadstart", handleLoadStart);

        // Clean up
        return () => {
          videoEl.removeEventListener("play", updatePlayingState);
          videoEl.removeEventListener("pause", updatePlayingState);
          videoEl.removeEventListener("timeupdate", updateTime);
          videoEl.removeEventListener("loadedmetadata", setInitialDuration);
          videoEl.removeEventListener("error", handleError);
          videoEl.removeEventListener("loadstart", handleLoadStart);
        };
      }
    }, []);

    // Handle timeline scrubbing
    const handleTimelineScrub = (e) => {
      if (!videoRef.current || !progressRef.current) return;
      const rect = progressRef.current.getBoundingClientRect();
      const clickPosition = Math.max(0, Math.min(e.clientX - rect.left, rect.width));
      const percentage = clickPosition / rect.width;

      // Set the time with validation
      const newTime = percentage * (videoRef.current.duration || 0);
      if (isFinite(newTime) && newTime >= 0 && newTime <= videoRef.current.duration) {
        videoRef.current.currentTime = newTime;
        setCurrentTime(newTime);
      }
    };

    // Toggle video playback
    const togglePlayback = () => {
      const videoEl = videoRef.current;
      if (!videoEl) return;

      if (videoEl.paused) {
        videoEl
          .play()
          .then(() => setIsPlaying(true))
          .catch((error) => {
            console.error("Playback failed:", error);
            setHasError(true);
          });
      } else {
        videoEl.pause();
        setIsPlaying(false);
      }
    };

    return (
      <div className="w-full max-w-xs mx-auto scale-100 relative">
        <div className="relative aspect-[9/16] bg-black rounded-lg overflow-hidden shadow-lg">
          {isLoading && (
            <div className="absolute inset-0 flex items-center justify-center bg-black/50 z-10">
              <div className="flex flex-col items-center">
                <div className="w-8 h-8 border-2 border-white border-t-transparent rounded-full animate-spin mb-2"></div>
                <span className="text-white text-sm">Loading video...</span>
              </div>
            </div>
          )}

          {hasError && (
            <div className="absolute inset-0 flex items-center justify-center bg-black/80 z-10">
              <div className="flex flex-col items-center p-4 text-center">
                <div className="w-12 h-12 bg-red-500/20 rounded-full flex items-center justify-center mb-2">
                  <AlertCircle className="w-6 h-6 text-red-500" />
                </div>
                <p className="text-white font-medium mb-1">Video playback error</p>
                <p className="text-white/70 text-sm mb-3">The video couldn't be played</p>
                <div className="flex gap-2">
                  <a
                    href={video.processed_video_url}
                    download={`video-${video.id}.mp4`}
                    className="px-3 py-1.5 bg-blue-600 text-white rounded-lg hover:bg-blue-700 text-sm"
                  >
                    Download Instead
                  </a>
                  <button
                    onClick={() => window.open(video.processed_video_url, "_blank")}
                    className="px-3 py-1.5 bg-gray-600 text-white rounded-lg hover:bg-gray-700 text-sm"
                  >
                    Open Directly
                  </button>
                </div>
              </div>
            </div>
          )}

          <video
            ref={videoRef}
            src={video.processed_video_url}
            className="w-full h-full object-cover"
            autoPlay
            onError={(e) => {
              console.error("Error playing video:", e);
              setHasError(true);
            }}
            onEnded={(e) => {
              e.target.pause();
              e.target.currentTime = 0;
            }}
          />

          {/* Video Controls */}
          <div className="absolute bottom-0 left-0 right-0 p-3 bg-gradient-to-t from-black/90 to-transparent">
            <div
              ref={progressRef}
              onClick={handleTimelineScrub}
              className="w-full h-1.5 bg-white/30 rounded-full mb-3 cursor-pointer relative"
            >
              <div
                className="absolute inset-y-0 left-0 bg-green-400 rounded-full transition-all duration-100"
                style={{ width: `${(currentTime / (duration || 1)) * 100}%` }}
              />
              <div
                className="absolute top-1/2 -translate-y-1/2 w-3 h-3 bg-white rounded-full shadow-md transition-all duration-100 opacity-0 hover:opacity-100"
                style={{ left: `calc(${(currentTime / (duration || 1)) * 100}% - 6px)` }}
              />
            </div>

            <div className="flex items-center justify-between">
              <span className="text-xs text-white select-none">
                {formatTime(currentTime)} / {formatTime(duration)}
              </span>
              <div className="flex items-center gap-2">
                <button onClick={togglePlayback} className="p-1.5 rounded-full bg-white/20 hover:bg-white/30 transition-colors">
                  {isPlaying ? (
                    <svg className="w-5 h-5 text-white" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth={2}
                        d="M10 9v6m4-6v6M5 20h14a2 2 0 002-2V6a2 2 0 00-2-2H5a2 2 0 00-2 2v12a2 2 0 002 2z"
                      />
                    </svg>
                  ) : (
                    <svg className="w-5 h-5 text-white" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth={2}
                        d="M14.752 11.168l-3.197-2.132A1 1 0 0010 9.87v4.263a1 1 0 001.555.832l3.197-2.132a1 1 0 000-1.664z"
                      />
                    </svg>
                  )}
                </button>
                <button
                  onClick={() => {
                    if (videoRef.current) videoRef.current.pause();
                    onClose();
                  }}
                  className="p-1.5 rounded-full bg-white/20 hover:bg-white/30 transition-colors"
                >
                  <X className="w-5 h-5 text-white" />
                </button>
              </div>
            </div>
          </div>
        </div>

        <div className="mt-4 flex justify-between items-center">
          <h3 className="text-lg font-medium text-gray-900 line-clamp-1">{video.hook_caption?.text || `Video ${video.id}`}</h3>
          <div className="flex gap-2">
            <a
              href={video.processed_video_url}
              download={`video-${video.id}.mp4`}
              className="px-3 py-1.5 bg-green-600 text-white rounded-lg hover:bg-green-700 text-sm flex items-center gap-1"
            >
              <Download className="w-4 h-4" /> Download
            </a>
          </div>
        </div>
      </div>
    );
  };

  return (
    <div className="min-h-screen bg-gray-50">
      {/* Header */}
      <header className="h-16 border-b border-gray-100 flex items-center px-8 sticky top-0 bg-white/95 backdrop-blur-sm z-40">
        <div className="max-w-6xl w-full mx-auto flex justify-between">
          <span className="font-semibold text-xl text-green-600">FeedbackbyAI</span>
          <div className="flex items-center gap-4">
            <Link to="/video" className="px-4 py-2 bg-green-600 text-white rounded-lg hover:bg-green-700 flex items-center gap-2">
              <Plus className="w-4 h-4" /> Create New Video
            </Link>
            <Link to="/dashboard" className="text-gray-500 hover:text-gray-700">
              Back to Dashboard
            </Link>
          </div>
        </div>
      </header>

      <main className="max-w-6xl mx-auto px-6 py-8">
        <div className="mb-6">
          <h1 className="text-2xl font-bold text-gray-900 mb-2">Your Video Library</h1>
          <p className="text-gray-600">All your videos in one place - both processing and completed</p>
        </div>

        {/* Search and filters */}
        <div className="mb-6 flex flex-wrap items-center justify-between gap-4">
          <div className="relative max-w-md w-full">
            <input
              type="text"
              placeholder="Search videos..."
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
              className="w-full pl-10 pr-4 py-2 bg-white border border-gray-200 rounded-lg focus:ring-2 focus:ring-green-500 focus:border-transparent"
            />
            <Search className="w-5 h-5 text-gray-400 absolute left-3 top-1/2 transform -translate-y-1/2" />
          </div>

          <div className="flex items-center gap-3">
            {/* Filter dropdown */}
            <div className="relative">
              <button
                onClick={() => setShowFilterMenu(!showFilterMenu)}
                className="px-3 py-2 bg-white border border-gray-200 rounded-lg text-gray-700 hover:bg-gray-50 flex items-center gap-2"
              >
                <span>
                  {filter === "all"
                    ? "All Videos"
                    : filter === "completed"
                    ? "Completed"
                    : filter === "processing"
                    ? "Processing"
                    : "Failed"}
                </span>
                <ChevronRight className={`w-4 h-4 transition-transform ${showFilterMenu ? "rotate-90" : ""}`} />
              </button>

              {showFilterMenu && (
                <div className="absolute right-0 mt-1 w-40 bg-white rounded-lg shadow-lg overflow-hidden z-10 border border-gray-200">
                  <div className="p-1">
                    {[
                      { id: "all", label: "All Videos" },
                      { id: "completed", label: "Completed" },
                      { id: "processing", label: "Processing" },
                      { id: "failed", label: "Failed" },
                    ].map((option) => (
                      <button
                        key={option.id}
                        onClick={() => {
                          setFilter(option.id);
                          setShowFilterMenu(false);
                        }}
                        className={`w-full px-3 py-1.5 text-left rounded-md ${
                          filter === option.id ? "bg-green-50 text-green-700" : "hover:bg-gray-50 text-gray-700"
                        }`}
                      >
                        {option.label}
                      </button>
                    ))}
                  </div>
                </div>
              )}
            </div>

            {/* Refresh button */}
            <button
              onClick={fetchVideos}
              disabled={refreshing}
              className={`px-4 py-2 bg-white border border-gray-200 rounded-lg text-gray-700 hover:bg-gray-50 flex items-center gap-2 ${
                refreshing ? "opacity-50 cursor-not-allowed" : ""
              }`}
            >
              <RefreshCw className={`w-4 h-4 ${refreshing ? "animate-spin" : ""}`} />
              {refreshing ? "Refreshing..." : "Refresh"}
            </button>
          </div>
        </div>

        {/* Videos Grid */}
        {loading ? (
          <div className="flex justify-center py-20">
            <div className="flex flex-col items-center">
              <Loader2 className="w-10 h-10 text-green-500 animate-spin mb-4" />
              <p className="text-gray-600">Loading your videos...</p>
            </div>
          </div>
        ) : filteredVideos.length === 0 ? (
          <div className="flex justify-center py-20">
            <div className="flex flex-col items-center">
              <FileVideo className="w-16 h-16 text-gray-300 mb-4" />
              <h2 className="text-xl font-semibold text-gray-800 mb-2">No videos found</h2>
              <p className="text-gray-600 mb-6">
                {searchTerm
                  ? "Try a different search term"
                  : filter !== "all"
                  ? `No ${filter} videos found`
                  : "You haven't created any videos yet"}
              </p>

              {!searchTerm && filter === "all" && (
                <Link to="/video" className="px-4 py-2 bg-green-600 text-white rounded-lg hover:bg-green-700 flex items-center gap-2">
                  <Plus className="w-4 h-4" /> Create Your First Video
                </Link>
              )}
            </div>
          </div>
        ) : (
          <div className="grid grid-cols-2 sm:grid-cols-3 md:grid-cols-4 lg:grid-cols-5 gap-4">
            {filteredVideos.map((video) => {
              const statusInfo = getStatusInfo(video);
              return (
                <div
                  key={video.id}
                  className="bg-white rounded-lg shadow-sm border border-gray-200 overflow-hidden hover:shadow-md transition-shadow group"
                >
                  <div className="aspect-[9/16] relative bg-gray-100">
                    {/* Status indicator */}
                    <div
                      className={`absolute top-2 right-2 z-10 px-2 py-1 rounded-full ${statusInfo.color} text-xs font-medium flex items-center gap-1`}
                    >
                      {statusInfo.icon}
                      {statusInfo.label}
                      {video.retry_count > 0 && <span className="ml-1 bg-white/30 px-1 rounded-full text-[10px]">{video.retry_count}</span>}
                    </div>

                    {/* For completed videos */}
                    {video.is_processed ? (
                      <>
                        {/* Thumbnail image */}
                        {video.thumbnail_url ? (
                          <img
                            src={video.thumbnail_url}
                            alt={video.hook_caption?.text || `Video ${video.id}`}
                            className="w-full h-full object-cover group-hover:opacity-0 transition-opacity"
                            onError={(e) => {
                              e.target.style.display = "none";
                              // Show placeholder
                              if (e.target.parentNode.querySelector(".placeholder")) {
                                e.target.parentNode.querySelector(".placeholder").style.display = "flex";
                              }
                            }}
                          />
                        ) : (
                          <div className="absolute inset-0 flex items-center justify-center bg-gray-200 group-hover:opacity-0 transition-opacity">
                            <FileVideo className="w-8 h-8 text-gray-400" />
                          </div>
                        )}

                        {/* Placeholder for image errors */}
                        <div className="placeholder absolute inset-0 hidden items-center justify-center bg-gray-200 group-hover:opacity-0 transition-opacity">
                          <FileVideo className="w-8 h-8 text-gray-400" />
                        </div>

                        {/* Video element - preload="none" for performance */}
                        <video
                          ref={(el) => {
                            if (el) videoRefs.current[video.id] = el;
                          }}
                          src={video.processed_video_url}
                          className="absolute inset-0 w-full h-full object-cover opacity-0 group-hover:opacity-100 transition-opacity"
                          muted
                          loop={false}
                          preload="none"
                          onClick={() => setPlayingVideo(video)}
                          onMouseEnter={() => handleMouseEnter(video.id)}
                          onMouseLeave={() => handleMouseLeave(video.id)}
                          onEnded={(e) => {
                            e.target.pause();
                            e.target.currentTime = 0;
                          }}
                        />

                        {/* Play button overlay */}
                        <div className="absolute inset-0 flex items-center justify-center group-hover:opacity-0 transition-opacity">
                          <button
                            onClick={() => setPlayingVideo(video)}
                            className="p-2 rounded-full bg-black/50 text-white hover:bg-black/70 transition-colors"
                          >
                            <Play className="w-5 h-5" />
                          </button>
                        </div>
                      </>
                    ) : (
                      // For processing videos
                      <div className="absolute inset-0 flex flex-col items-center justify-center bg-gray-100">
                        <div className="w-12 h-12 rounded-full bg-blue-50 flex items-center justify-center mb-3">
                          {video.retrying ? (
                            <Loader2 className="w-6 h-6 text-green-500 animate-spin" />
                          ) : video.processing_error ? (
                            <AlertCircle className="w-6 h-6 text-red-500" />
                          ) : (
                            // <Loader2 className="w-6 h-6 text-blue-500 animate-spin" />
                            console.log("loading")
                          )}
                        </div>
                        <p className="text-gray-700 font-medium px-4 text-center text-sm">
                          {video.retrying ? "Retrying..." : video.processing_error ? "Processing Error" : video.status || "Processing..."}
                        </p>
                        {video.retry_count > 0 && !video.retrying && <p className="text-xs text-gray-500">Retry #{video.retry_count}</p>}
                        <p className="text-xs text-gray-500 mt-1 px-2 text-center">
                          {!video.processing_error && !video.retrying && "This takes 2-5 minutes"}
                        </p>

                        {/* Check status button */}
                        <button
                          onClick={(e) => {
                            e.stopPropagation();
                            checkVideoStatus(video.id);
                          }}
                          className="mt-2 px-3 py-1 bg-gray-200 hover:bg-gray-300 rounded-lg text-sm flex items-center gap-1"
                          disabled={video.retrying}
                        >
                          <RefreshCw className="w-3 h-3" /> Check Status
                        </button>

                        {/* Retry button for failed videos */}
                        {video.processing_error && !video.retrying && (
                          <button
                            onClick={(e) => {
                              e.stopPropagation();
                              retryVideoProcessing(video.id);
                            }}
                            className="mt-2 px-3 py-1 bg-blue-600 text-white rounded-lg hover:bg-blue-700 text-sm flex items-center gap-1"
                          >
                            <RefreshCw className="w-3 h-3" /> Retry Processing
                          </button>
                        )}
                      </div>
                    )}
                  </div>

                  {/* Video card footer */}
                  <div className="p-3">
                    <h3 className="font-medium text-gray-900 mb-1 text-sm line-clamp-1">
                      {video.hook_caption?.text || `Video ${video.id}`}
                    </h3>
                    <p className="text-xs text-gray-500 mb-2">{getTimeSince(video.created_at)}</p>

                    {video.is_processed ? (
                      <div className="flex justify-between">
                        <a
                          href={video.processed_video_url}
                          download={`video-${video.id}.mp4`}
                          className="px-2 py-1 bg-green-600 text-white rounded-lg hover:bg-green-700 text-xs flex items-center gap-1"
                        >
                          <Download className="w-3 h-3" /> Download
                        </a>

                        <div className="flex gap-1">
                          {/* Share button */}
                          <button
                            onClick={() => {
                              if (navigator.share) {
                                navigator.share({
                                  title: video.hook_caption?.text || "My Video",
                                  text: "Check out this video I created!",
                                  url: video.processed_video_url,
                                });
                              } else {
                                copyVideoUrl(video.processed_video_url);
                              }
                            }}
                            className="p-1 text-gray-500 hover:text-gray-700 rounded-lg"
                            title="Share"
                          >
                            <Share2 className="w-4 h-4" />
                          </button>

                          {/* Open in new tab button */}
                          <button
                            onClick={() => window.open(video.processed_video_url, "_blank")}
                            className="p-1 text-gray-500 hover:text-gray-700 rounded-lg"
                            title="Open in new tab"
                          >
                            <ExternalLink className="w-4 h-4" />
                          </button>
                        </div>
                      </div>
                    ) : (
                      <div className="flex justify-between items-center">
                        <div className="text-xs text-gray-500">
                          {video.processing_error ? (
                            <span className="text-red-500">{(video.processing_error || "").substring(0, 30)}...</span>
                          ) : (
                            "Processing..."
                          )}
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              );
            })}
          </div>
        )}

        {/* Pagination placeholder (if needed) */}
        {!loading && (
          <div className="mt-8 flex justify-center">
            <div className="flex items-center gap-2">
              <button
                onClick={goToPreviousPage}
                disabled={!hasPrevious}
                className={`px-3 py-1 border rounded-md ${!hasPrevious ? "opacity-40 cursor-not-allowed" : "hover:bg-gray-50"}`}
              >
                Previous
              </button>
              <span className="text-sm text-gray-600">
                Page {page} of {totalPages}
              </span>
              <button
                onClick={goToNextPage}
                disabled={!hasNext}
                className={`px-3 py-1 border rounded-md ${!hasNext ? "opacity-40 cursor-not-allowed" : "hover:bg-gray-50"}`}
              >
                Next
              </button>
            </div>
          </div>
        )}
      </main>

      {/* Inline Video Player Modal */}
      {/* {playingVideo && (
        <div
          className="fixed inset-0 z-50 flex items-center justify-center p-4 bg-black/80 backdrop-blur-sm"
          onClick={() => setPlayingVideo(null)}
        >
          <div className="relative w-full max-w-md" onClick={(e) => e.stopPropagation()}>
            <InlineVideoPlayer video={playingVideo} onClose={() => setPlayingVideo(null)} />
          </div>
        </div>
      )} */}

      {playingVideo && (
        <div
          className="fixed inset-0 z-50 flex items-center justify-center p-4 bg-black/80 backdrop-blur-sm"
          onClick={() => setPlayingVideo(null)}
        >
          <div className="relative" onClick={(e) => e.stopPropagation()}>
            <TikTokPhonePreview
              videoUrl={playingVideo.processed_video_url}
              // caption={playingVideo.hook_caption?.text}
              onClose={() => setPlayingVideo(null)}
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default VideoLibrary;
