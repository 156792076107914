import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { motion } from "framer-motion";
import jwtDecode from "jwt-decode";
import api from "./axiosConfig";
import {
  LogOut,
  ChevronRight,
  CreditCard,
  User,
  MessageSquare,
  FileText,
  Video,
  Bell,
  ArrowRight,
  Zap,
  Target,
  TrendingUp,
} from "lucide-react";

const CoreDashboard = () => {
  // State management
  const [user, setUser] = useState(null);
  const [userCredits, setUserCredits] = useState({
    question_credits: 0,
    report_credits: 0,
    video_credits: 0,
    ideation_credits: 0,
    lead_credits: 0,
    plan_name: "Free",
    subscription_active: false,
    first_name: "Guest",
    email: "",
  });
  const [isLoading, setIsLoading] = useState(true);
  const [dropdownOpen, setDropdownOpen] = useState(false);

  // Close dropdown when clicking outside
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownOpen && !event.target.closest(".user-dropdown")) {
        setDropdownOpen(false);
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => document.removeEventListener("mousedown", handleClickOutside);
  }, [dropdownOpen]);

  // Check user authentication
  useEffect(() => {
    const token = localStorage.getItem("access");
    if (!token) {
      window.location.href = "/signin/";
      return;
    }

    try {
      const decodedToken = jwtDecode(token);
      const currentTimestamp = Math.floor(Date.now() / 1000);

      if (decodedToken.exp && decodedToken.exp < currentTimestamp) {
        localStorage.removeItem("access");
        window.location.href = "/signin/";
      } else {
        setUser(decodedToken);
      }
    } catch (error) {
      console.error("Failed to decode the token:", error);
      window.location.href = "/signin/";
    }
  }, []);

  // Fetch user credits
  useEffect(() => {
    const fetchUserCredits = async () => {
      try {
        const response = await api.get("get-user-credits/");
        if (response.data) {
          setUserCredits(response.data);
        }
      } catch (error) {
        console.error("Error fetching user credits:", error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchUserCredits();
  }, []);

  const handleLogout = () => {
    localStorage.clear();
    window.location.href = "/signin/";
  };

  if (isLoading) {
    return (
      <div className="min-h-screen bg-white flex items-center justify-center">
        <div className="w-12 h-12 border-t-3 border-green-600 border-solid rounded-full animate-spin"></div>
      </div>
    );
  }
  return (
    <div className="min-h-screen bg-white">
      {/* Header */}
      <header className="h-16 border-b border-gray-100 flex items-center px-8">
        <div className="max-w-7xl mx-auto flex justify-between w-full">
          <span className="font-medium text-xl text-green-600">FeedbackbyAI</span>
          <div className="relative user-dropdown">
            <button className="flex items-center space-x-2 p-1.5" onClick={() => setDropdownOpen(!dropdownOpen)}>
              <div className="h-8 w-8 rounded-full bg-green-100 flex items-center justify-center">
                <User className="w-5 h-5 text-green-600" />
              </div>
            </button>
            {dropdownOpen && (
              <div className="absolute right-0 mt-2 w-56 bg-white rounded-lg shadow-lg py-1 z-10 border border-gray-200">
                <div className="px-4 py-2 border-b border-gray-100">
                  <p className="text-sm font-medium">{userCredits.first_name}</p>
                </div>
                <Link to="/credits/" className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100">
                  Manage Credits
                </Link>
                <button onClick={handleLogout} className="block w-full text-left px-4 py-2 text-sm text-red-600 hover:bg-gray-100">
                  Sign Out
                </button>
              </div>
            )}
          </div>
        </div>
      </header>

      <main className="max-w-7xl mx-auto px-6 py-12">
        {/* Page Header with Icon - Matching ReportDashboard style */}
        <div className="flex flex-col items-center justify-center mb-12">
          <div className="w-20 h-20 bg-green-50 rounded-full flex items-center justify-center mb-6">
            <User className="w-10 h-10 text-green-600" />
          </div>
          <motion.h1
            initial={{ opacity: 0, y: -20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.5 }}
            className="text-3xl font-bold text-gray-900 text-center mb-2"
          >
            Welcome, {userCredits.first_name}
          </motion.h1>
          <p className="text-center text-gray-600 max-w-2xl mx-auto">What would you like to do today?</p>
        </div>

        {/* Action Cards - All 5 options in one grid */}
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-5 gap-6 mb-12">
          <Link to="/ideate/" className="group">
            <div className="bg-white rounded-2xl shadow-xl border border-gray-100 p-6 hover:shadow-2xl transition-all duration-300 h-full flex flex-col">
              <div className="w-16 h-16 rounded-full bg-purple-50 flex items-center justify-center mb-4">
                <Zap className="w-8 h-8 text-purple-600" />
              </div>
              <h2 className="text-xl font-semibold mb-3 text-gray-900">Ideate</h2>
              <p className="text-gray-600 mb-6 ">Discover winning ideas from social media conversations and market research.</p>
              <div className="mt-auto">
                <span className="inline-flex items-center text-purple-600 font-medium hover:text-purple-800 transition-colors">
                  Find Ideas
                  <ChevronRight className="ml-1 w-4 h-4" />
                </span>
                <div className="text-xs text-gray-500 mt-2">Uses ideation credits ({userCredits.ideation_credits || 0} available)</div>
              </div>
            </div>
          </Link>

          {/* <Link to="/dashboard/" className="group">
            <div className="bg-white rounded-2xl shadow-xl border border-gray-100 p-6 hover:shadow-2xl transition-all duration-300 h-full flex flex-col">
              <div className="w-16 h-16 rounded-full bg-purple-50 flex items-center justify-center mb-4">
                <Zap className="w-8 h-8 text-purple-600" />
              </div>
              <h2 className="text-xl font-semibold mb-3 text-gray-900">Ideate</h2>
              <p className="text-gray-600 mb-6 ">Discover winning ideas from social media conversations and market research.</p>
              <div className="mt-auto">
                <span className="inline-flex items-center text-purple-600 font-medium hover:text-purple-800 transition-colors">
                  Find Ideas
                  <ChevronRight className="ml-1 w-4 h-4" />
                </span>
                <div className="text-xs text-gray-500 mt-2">Uses ideation credits ({userCredits.ideation_credits || 0} available)</div>
              </div>
            </div>
          </Link> */}

          <Link to="/report/" className="group">
            <div className="bg-white rounded-2xl shadow-xl border border-gray-100 p-6 hover:shadow-2xl transition-all duration-300 h-full flex flex-col">
              <div className="w-16 h-16 rounded-full bg-green-50 flex items-center justify-center mb-4">
                <FileText className="w-8 h-8 text-green-600" />
              </div>
              <h2 className="text-xl font-semibold mb-3 text-gray-900">Validate</h2>
              <p className="text-gray-600 mb-6">Generate a comprehensive 100+ page business plan to validate your concept.</p>
              <div className="mt-auto">
                <span className="inline-flex items-center text-green-600 font-medium hover:text-green-800 transition-colors">
                  Start Validation
                  <ChevronRight className="ml-1 w-4 h-4" />
                </span>
                <div className="text-xs text-gray-500 mt-2">Uses report credits ({userCredits.report_credits || 0} available)</div>
              </div>
            </div>
          </Link>

          <Link to="/feedback/" className="group">
            <div className="bg-white rounded-2xl shadow-xl border border-gray-100 p-6 hover:shadow-2xl transition-all duration-300 h-full flex flex-col">
              <div className="w-16 h-16 rounded-full bg-blue-50 flex items-center justify-center mb-4">
                <MessageSquare className="w-8 h-8 text-blue-600" />
              </div>
              <h2 className="text-xl font-semibold mb-3 text-gray-900">User Interviews</h2>
              <p className="text-gray-600 mb-6">Run AI character interviews to get honest feedback on your product or idea.</p>
              <div className="mt-auto">
                <span className="inline-flex items-center text-blue-600 font-medium hover:text-blue-800 transition-colors">
                  Start Interviews
                  <ChevronRight className="ml-1 w-4 h-4" />
                </span>
                <div className="text-xs text-gray-500 mt-2">Uses question credits ({userCredits.question_credits || 0} available)</div>
              </div>
            </div>
          </Link>

          <Link to="/video/" className="group">
            <div className="bg-white rounded-2xl shadow-xl border border-gray-100 p-6 hover:shadow-2xl transition-all duration-300 h-full flex flex-col">
              <div className="w-16 h-16 rounded-full bg-orange-50 flex items-center justify-center mb-4">
                <Video className="w-8 h-8 text-orange-600" />
              </div>
              <h2 className="text-xl font-semibold mb-3 text-gray-900">Promote</h2>
              <p className="text-gray-600 mb-6">Create automated videos to drive traffic to your website and convert visitors.</p>
              <div className="mt-auto">
                <span className="inline-flex items-center text-orange-600 font-medium hover:text-orange-800 transition-colors">
                  Start Promotion
                  <ChevronRight className="ml-1 w-4 h-4" />
                </span>
                <div className="text-xs text-gray-500 mt-2">Uses video credits ({userCredits.video_credits || 0} available)</div>
              </div>
            </div>
          </Link>

          <Link to="/leadfinder/" className="group">
            <div className="bg-white rounded-2xl shadow-xl border border-gray-100 p-6 hover:shadow-2xl transition-all duration-300 h-full flex flex-col">
              <div className="w-16 h-16 rounded-full bg-red-50 flex items-center justify-center mb-4">
                <Target className="w-8 h-8 text-red-600" />
              </div>
              <h2 className="text-xl font-semibold mb-3 text-gray-900">Lead Finder</h2>
              <p className="text-gray-600 mb-6">Find qualified leads that are ready to buy your product or service.</p>
              <div className="mt-auto">
                <span className="inline-flex items-center text-red-600 font-medium hover:text-red-800 transition-colors">
                  Find Leads
                  <ChevronRight className="ml-1 w-4 h-4" />
                </span>
                <div className="text-xs text-gray-500 mt-2">Uses lead credits ({userCredits.lead_credits || 0} available)</div>
              </div>
            </div>
          </Link>
        </div>

        {/* Subscription Status Card */}
        <div className="bg-white rounded-2xl shadow-lg border border-gray-100 p-8">
          <h2 className="text-xl font-bold text-gray-800 mb-6">Your Subscription</h2>
          <div className="flex items-center mb-4">
            <span
              className={`inline-block w-3 h-3 rounded-full mr-2 ${userCredits.subscription_active ? "bg-green-500" : "bg-gray-400"}`}
            ></span>
            <span className="text-lg font-medium text-gray-700">
              {userCredits.plan_name} Plan {!userCredits.subscription_active && userCredits.plan_name !== "Free" && "(Inactive)"}
            </span>
          </div>
          <div className="grid grid-cols-2 md:grid-cols-5 gap-4 mb-6">
            <div className="bg-gray-50 p-3 rounded-lg text-center">
              <span className="text-purple-600 font-semibold text-xl">{userCredits.ideation_credits || 0}</span>
              <p className="text-xs text-gray-500">Ideation Credits</p>
            </div>
            <div className="bg-gray-50 p-3 rounded-lg text-center">
              <span className="text-green-600 font-semibold text-xl">{userCredits.report_credits || 0}</span>
              <p className="text-xs text-gray-500">Report Credits</p>
            </div>
            <div className="bg-gray-50 p-3 rounded-lg text-center">
              <span className="text-blue-600 font-semibold text-xl">{userCredits.question_credits || 0}</span>
              <p className="text-xs text-gray-500">Question Credits</p>
            </div>
            <div className="bg-gray-50 p-3 rounded-lg text-center">
              <span className="text-orange-600 font-semibold text-xl">{userCredits.video_credits || 0}</span>
              <p className="text-xs text-gray-500">Video Credits</p>
            </div>
            <div className="bg-gray-50 p-3 rounded-lg text-center">
              <span className="text-red-600 font-semibold text-xl">{userCredits.lead_credits || 0}</span>
              <p className="text-xs text-gray-500">Lead Credits</p>
            </div>
          </div>
          <Link
            to="/credits/"
            className="inline-flex items-center bg-green-600 hover:bg-green-700 text-white text-lg font-semibold py-3 px-6 rounded-lg focus:outline-none transition-all duration-300"
          >
            {userCredits.plan_name === "Free" ? "Upgrade Plan" : "Manage Subscription"}
            <ChevronRight className="ml-1 w-5 h-5" />
          </Link>
        </div>
      </main>
    </div>
  );
};

export default CoreDashboard;
