// import React, { useState } from "react";
// import { ArrowUp, Users, Sparkles, Globe, Send, ChevronRight, DollarSign } from "lucide-react";

// const FeatureVotingPage = () => {
//   const [email, setEmail] = useState("");
//   const [suggestion, setSuggestion] = useState("");
//   const [priceVote, setPriceVote] = useState("");

//   const [features, setFeatures] = useState([
//     { id: 1, text: "Auto-update from GitHub activity", votes: 124, isVoted: false },
//     { id: 2, text: "AI writing style customization", votes: 89, isVoted: false },
//     { id: 3, text: "Project showcase generator", votes: 67, isVoted: false },
//   ]);

//   const [priceStats, setPriceStats] = useState({
//     averagePrice: 49,
//     votes: 234,
//     distribution: [
//       { price: "0-25", votes: 45 },
//       { price: "26-50", votes: 89 },
//       { price: "51-100", votes: 67 },
//       { price: "100+", votes: 33 },
//     ],
//   });

//   const handleVote = (id) => {
//     setFeatures(
//       features.map((feature) =>
//         feature.id === id
//           ? { ...feature, votes: feature.isVoted ? feature.votes - 1 : feature.votes + 1, isVoted: !feature.isVoted }
//           : feature
//       )
//     );
//   };

//   const handlePriceVote = (e) => {
//     e.preventDefault();
//     // Add price voting logic
//   };

//   return (
//     <div className="min-h-screen bg-gradient-to-br from-indigo-50 to-pink-50 dark:from-gray-900 dark:to-gray-800 p-4">
//       <div className="max-w-4xl mx-auto pt-20 space-y-12">
//         {/* Header */}
//         <div className="text-center space-y-6">
//           <h1 className="text-5xl font-bold bg-gradient-to-r from-indigo-600 to-pink-600 text-transparent bg-clip-text">AIPortfolio.dev</h1>
//           <p className="text-xl text-gray-600 dark:text-gray-300">
//             Your AI-powered portfolio that writes itself. Never update your resume again.
//           </p>
//           <p className="text-md text-gray-500 dark:text-gray-400 max-w-2xl mx-auto">
//             We're building an AI that tracks your work, automatically updates your portfolio, and showcases your achievements in real-time.
//           </p>

//           {/* Stats */}
//           <div className="flex justify-center gap-8 text-gray-600 dark:text-gray-400">
//             <div className="flex items-center gap-2">
//               <Users className="w-5 h-5" />
//               <span>1,234 interested</span>
//             </div>
//             <div className="flex items-center gap-2">
//               <Globe className="w-5 h-5" />
//               <span>28 countries</span>
//             </div>
//             <div className="flex items-center gap-2">
//               <Sparkles className="w-5 h-5" />
//               <span>89 features suggested</span>
//             </div>
//           </div>
//         </div>

//         {/* Waitlist Signup */}
//         <div className="max-w-md mx-auto">
//           <form className="flex gap-2">
//             <input
//               type="email"
//               value={email}
//               onChange={(e) => setEmail(e.target.value)}
//               placeholder="Enter your email"
//               className="flex-1 px-4 py-3 rounded-lg bg-white dark:bg-gray-800 border border-gray-200 dark:border-gray-700 focus:ring-2 focus:ring-indigo-500 outline-none"
//             />
//             <button
//               type="submit"
//               className="px-6 py-3 bg-indigo-600 text-white rounded-lg font-medium hover:bg-indigo-700 flex items-center gap-2"
//             >
//               Join Waitlist
//               <ChevronRight className="w-4 h-4" />
//             </button>
//           </form>
//         </div>

//         {/* Feature Voting */}
//         <div className="bg-white dark:bg-gray-800 rounded-xl shadow-xl p-6">
//           <div className="space-y-6">
//             <h2 className="text-2xl font-semibold text-gray-900 dark:text-white">Help us build the right features</h2>

//             {/* Feature List */}
//             <div className="space-y-4">
//               {features.map((feature) => (
//                 <div
//                   key={feature.id}
//                   className="flex items-center gap-4 p-4 rounded-lg hover:bg-gray-50 dark:hover:bg-gray-700 transition-colors"
//                 >
//                   <button
//                     onClick={() => handleVote(feature.id)}
//                     className={`flex flex-col items-center gap-1 p-2 rounded-lg transition-colors ${
//                       feature.isVoted
//                         ? "text-indigo-600 dark:text-indigo-400"
//                         : "text-gray-400 hover:text-indigo-600 dark:hover:text-indigo-400"
//                     }`}
//                   >
//                     <ArrowUp className="w-5 h-5" />
//                     <span className="text-sm font-medium">{feature.votes}</span>
//                   </button>
//                   <span className="flex-1 text-gray-700 dark:text-gray-300">{feature.text}</span>
//                 </div>
//               ))}
//             </div>

//             {/* Suggest Feature */}
//             <div className="pt-4 border-t dark:border-gray-700">
//               <form className="flex gap-2">
//                 <input
//                   type="text"
//                   value={suggestion}
//                   onChange={(e) => setSuggestion(e.target.value)}
//                   placeholder="Suggest a feature..."
//                   className="flex-1 px-4 py-3 rounded-lg bg-gray-50 dark:bg-gray-900 border border-gray-200 dark:border-gray-700 focus:ring-2 focus:ring-indigo-500 outline-none"
//                 />
//                 <button
//                   type="submit"
//                   className="px-6 py-3 bg-gray-100 dark:bg-gray-700 text-gray-700 dark:text-gray-300 rounded-lg font-medium hover:bg-gray-200 dark:hover:bg-gray-600 flex items-center gap-2"
//                 >
//                   <Send className="w-4 h-4" />
//                   Suggest
//                 </button>
//               </form>
//             </div>
//           </div>
//         </div>

//         {/* Pricing Feedback */}
//         <div className="bg-white dark:bg-gray-800 rounded-xl shadow-xl p-6">
//           <div className="space-y-6">
//             <h2 className="text-2xl font-semibold text-gray-900 dark:text-white flex items-center gap-2">
//               <DollarSign className="w-6 h-6 text-indigo-600" />
//               What would you pay for this?
//             </h2>

//             <div className="grid grid-cols-4 gap-4">
//               {priceStats.distribution.map((bracket, idx) => (
//                 <div key={idx} className="text-center">
//                   <div className="h-24 bg-indigo-100 dark:bg-indigo-900/30 rounded-lg relative">
//                     <div
//                       className="absolute bottom-0 w-full bg-indigo-500/20 rounded-lg transition-all duration-500"
//                       style={{ height: `${(bracket.votes / Math.max(...priceStats.distribution.map((d) => d.votes))) * 100}%` }}
//                     />
//                   </div>
//                   <p className="mt-2 text-sm text-gray-600 dark:text-gray-400">${bracket.price}</p>
//                   <p className="text-xs text-gray-500">{bracket.votes} votes</p>
//                 </div>
//               ))}
//             </div>

//             <form onSubmit={handlePriceVote} className="flex gap-2 mt-4">
//               <div className="relative flex-1">
//                 <span className="absolute left-4 top-3.5 text-gray-500">$</span>
//                 <input
//                   type="number"
//                   value={priceVote}
//                   onChange={(e) => setPriceVote(e.target.value)}
//                   placeholder="What would you pay monthly?"
//                   className="w-full pl-8 pr-4 py-3 rounded-lg bg-gray-50 dark:bg-gray-900 border border-gray-200 dark:border-gray-700 focus:ring-2 focus:ring-indigo-500 outline-none"
//                 />
//               </div>
//               <button type="submit" className="px-6 py-3 bg-indigo-600 text-white rounded-lg font-medium hover:bg-indigo-700">
//                 Vote
//               </button>
//             </form>
//           </div>
//         </div>

//         {/* Live Activity */}
//         <div className="fixed bottom-4 left-4 p-4 bg-white dark:bg-gray-800 rounded-lg shadow-lg max-w-sm animate-fade-in">
//           <div className="flex items-center gap-3">
//             <div className="w-2 h-2 bg-green-500 rounded-full animate-pulse" />
//             <span className="text-sm text-gray-600 dark:text-gray-400">John from San Francisco just suggested a feature</span>
//           </div>
//         </div>
//       </div>
//     </div>
//   );
// };

// export default FeatureVotingPage;

import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom"; // Import useParams
import instance from "./axiosConfig";
import { ArrowUp, Users, Sparkles, Globe, Send, ChevronRight, DollarSign } from "lucide-react";

const FeatureVotingPage = () => {
  const { website_id } = useParams(); // Capture website_id from URL
  const [email, setEmail] = useState("");
  const [suggestion, setSuggestion] = useState("");
  const [priceVote, setPriceVote] = useState("");

  const [priceStats, setPriceStats] = useState({});
  const [liveActivity, setLiveActivity] = useState([]);
  const [features, setFeatures] = useState([]);
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [priceFeedback, setPriceFeedback] = useState([]);

  //   // Fetch website details on mount
  //   useEffect(() => {
  //     const fetchWebsiteDetails = async () => {
  //       try {
  //         const response = await instance.get(`websites/${website_id}/`);
  //         const { website_data } = response.data;
  //         setFeatures(website_data.features || []);
  //         setPriceStats(website_data.price_feedback || {});
  //       } catch (error) {
  //         console.error("Error fetching website details:", error);
  //       }
  //     };
  //     fetchWebsiteDetails();
  //   }, [website_id]);

  useEffect(() => {
    const fetchWebsiteDetails = async () => {
      try {
        const response = await instance.get(`websites/${website_id}/`);
        const { website_data } = response.data;

        // Update states with API data
        setFeatures(website_data.features || []);
        setTitle(website_data.title || "");
        setDescription(website_data.description || "");
        setPriceFeedback(website_data.price_feedback || []);
      } catch (error) {
        console.error("Error fetching website details:", error);
      }
    };
    fetchWebsiteDetails();
  }, [website_id]);

  // Fetch live activity periodically
  //   useEffect(() => {
  //     const fetchLiveActivity = async () => {
  //       try {
  //         const response = await instance.get(`leads/recent/`, {
  //           params: { website_id },
  //         });
  //         setLiveActivity(response.data);
  //       } catch (error) {
  //         console.error("Error fetching live activity:", error);
  //       }
  //     };

  //     const interval = setInterval(fetchLiveActivity, 5000);
  //     return () => clearInterval(interval);
  //   }, [website_id]);

  // Handle feature vote
  const handleVote = async (id) => {
    const feature = features.find((f) => f.id === id);
    try {
      await instance.post(`feature-votes/`, {
        website_id,
        feature_data: {
          ...feature,
          votes: feature.isVoted ? feature.votes - 1 : feature.votes + 1,
        },
      });
      setFeatures(
        features.map((feature) =>
          feature.id === id
            ? { ...feature, votes: feature.isVoted ? feature.votes - 1 : feature.votes + 1, isVoted: !feature.isVoted }
            : feature
        )
      );
    } catch (error) {
      console.error("Error voting on feature:", error);
    }
  };

  // Handle feature suggestion
  const handleSuggestFeature = async (e) => {
    e.preventDefault();
    if (!suggestion.trim()) {
      alert("Feature suggestion cannot be empty.");
      return;
    }

    try {
      await instance.post(`feature-votes/`, {
        website_id,
        feature_data: { text: suggestion, votes: 0 },
      });
      setFeatures([...features, { id: features.length + 1, text: suggestion, votes: 0, isVoted: false }]);
      setSuggestion(""); // Clear the input field
      alert("Feature suggested successfully!");
    } catch (error) {
      console.error("Error suggesting feature:", error);
      alert("Failed to suggest feature. Please try again.");
    }
  };

  // Handle join waitlist
  const handleJoinWaitlist = async (e) => {
    e.preventDefault();
    try {
      await instance.post(`leads/`, {
        website_id,
        lead_data: { email },
      });
      setEmail("");
      alert("You've successfully joined the waitlist!");
    } catch (error) {
      console.error("Error joining waitlist:", error);
    }
  };

  // Handle price vote
  const handlePriceVote = async (e) => {
    e.preventDefault();

    if (!priceVote) {
      alert("Please select a valid price range.");
      return;
    }

    try {
      await instance.post(`price-feedback/`, {
        website_id,
        price_data: { range: priceVote },
      });

      // Update the local priceFeedback state to reflect the new vote
      setPriceFeedback(
        priceFeedback.map((feedback) => (feedback.range === priceVote ? { ...feedback, votes: feedback.votes + 1 } : feedback))
      );

      setPriceVote(""); // Clear the input
      alert("Price feedback submitted successfully!");
    } catch (error) {
      console.error("Error submitting price feedback:", error);
      alert("Failed to submit price feedback. Please try again.");
    }
  };

  return (
    <div className="min-h-screen bg-gradient-to-br from-indigo-50 to-pink-50 dark:from-gray-900 dark:to-gray-800 p-4">
      <div className="max-w-4xl mx-auto pt-20 space-y-12">
        {/* Header */}
        <div className="text-center space-y-6">
          <h1 className="text-5xl font-bold bg-gradient-to-r from-indigo-600 to-pink-600 text-transparent bg-clip-text">{title}</h1>
          <p className="text-xl text-gray-600 dark:text-gray-300">{description}</p>
        </div>

        {/* Waitlist Signup */}
        <form onSubmit={handleJoinWaitlist} className="max-w-md mx-auto flex gap-2">
          <input
            type="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            placeholder="Enter your email"
            className="flex-1 px-4 py-3 rounded-lg bg-white dark:bg-gray-800 border border-gray-200 dark:border-gray-700 focus:ring-2 focus:ring-indigo-500 outline-none"
          />
          <button
            type="submit"
            className="px-6 py-3 bg-indigo-600 text-white rounded-lg font-medium hover:bg-indigo-700 flex items-center gap-2"
          >
            Join Waitlist
            <ChevronRight className="w-4 h-4" />
          </button>
        </form>

        {/* Feature Voting */}

        <div className="space-y-4 mt-4">
          {features.map((feature) => (
            <div key={feature.id} className="flex items-center gap-4 p-4 rounded-lg hover:bg-gray-50 dark:hover:bg-gray-700">
              <button
                onClick={() => handleVote(feature.id)}
                className={`flex items-center gap-2 ${feature.isVoted ? "text-indigo-600" : "text-gray-400"}`}
              >
                <ArrowUp />
                <span>{feature.votes}</span>
              </button>
              <span>{feature.text}</span>
            </div>
          ))}

          {/* Suggest Feature Form */}
          <div className="pt-6 border-t border-gray-200 dark:border-gray-700">
            <h3 className="text-xl font-semibold text-gray-900 dark:text-white">Suggest a Feature</h3>
            <form onSubmit={handleSuggestFeature} className="flex gap-2 mt-4">
              <input
                type="text"
                value={suggestion}
                onChange={(e) => setSuggestion(e.target.value)}
                placeholder="Suggest a new feature..."
                className="flex-1 px-4 py-3 rounded-lg bg-gray-50 dark:bg-gray-900 border border-gray-200 dark:border-gray-700 focus:ring-2 focus:ring-indigo-500 outline-none"
              />
              <button
                type="submit"
                className="px-6 py-3 bg-indigo-600 text-white rounded-lg font-medium hover:bg-indigo-700 flex items-center gap-2"
              >
                Submit
              </button>
            </form>
          </div>
        </div>

        {/* Pricing Feedback */}
        {/* Pricing Feedback */}
        <div className="bg-white dark:bg-gray-800 rounded-xl shadow-xl p-6">
          <h2 className="text-2xl font-semibold text-gray-900 dark:text-white">Pricing Feedback</h2>

          {/* Chart Representation */}
          <div className="mt-4">
            <h3 className="text-lg font-medium text-gray-700 dark:text-gray-300">Vote Distribution</h3>
            <div className="grid grid-cols-4 gap-4 mt-4">
              {priceFeedback.map((feedback, idx) => (
                <div key={idx} className="text-center">
                  <div className="relative h-24 bg-indigo-100 dark:bg-indigo-900/30 rounded-lg">
                    <div
                      className="absolute bottom-0 w-full bg-indigo-600 dark:bg-indigo-400 transition-all duration-500"
                      style={{
                        height: `${(feedback.votes / Math.max(...priceFeedback.map((f) => f.votes || 1))) * 100}%`, // Scale heights based on max votes
                      }}
                    />
                  </div>
                  <p className="mt-2 text-sm text-gray-600 dark:text-gray-400">{feedback.range}</p>
                  <p className="text-xs text-gray-500">{feedback.votes} votes</p>
                </div>
              ))}
            </div>
          </div>

          {/* Divider */}
          <div className="flex items-center my-6">
            <div className="border-t border-gray-300 flex-grow"></div>
            <span className="mx-4 text-gray-500 dark:text-gray-400">Vote your price</span>
            <div className="border-t border-gray-300 flex-grow"></div>
          </div>

          {/* Custom Price Suggestion Form */}
          <form onSubmit={handlePriceVote} className="flex gap-2 mt-4">
            <div className="relative flex-1">
              <span className="absolute left-4 top-3.5 text-gray-500">$</span>
              <input
                type="number"
                value={priceVote}
                onChange={(e) => setPriceVote(e.target.value)}
                placeholder="Suggest a price"
                className="w-full pl-8 pr-4 py-3 rounded-lg bg-gray-50 dark:bg-gray-900 border border-gray-200 dark:border-gray-700 focus:ring-2 focus:ring-indigo-500 outline-none"
              />
            </div>
            <button
              type="submit"
              className={`px-6 py-3 rounded-lg font-medium text-white ${
                priceVote ? "bg-indigo-600 hover:bg-indigo-700" : "bg-gray-400 cursor-not-allowed"
              }`}
              disabled={!priceVote} // Disable when empty
            >
              Submit
            </button>
          </form>
        </div>

        {/* Live Activity */}
        <div>
          {liveActivity.map((activity, idx) => (
            <p key={idx}>{activity.message}</p>
          ))}
        </div>
      </div>
    </div>
  );
};

export default FeatureVotingPage;
