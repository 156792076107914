import React, { useState, useRef, useEffect, useMemo } from "react";
import { Link } from "react-router-dom";
import instance from "./axiosConfig";
import axios from "axios";
import {
  Play,
  Pause,
  CheckCircle2,
  Video,
  Music,
  ArrowUp,
  ArrowDown,
  AlignCenter,
  Plus,
  X,
  Download,
  Zap,
  FileVideo,
  Clock,
  Share2,
  MessageSquare,
  ArrowRight,
  Eye,
  Loader2,
  Settings,
  ChevronRight,
  UploadCloud,
  Check,
  RefreshCw,
  Search,
  Info,
  Filter,
  Signal,
  Wifi,
  Battery,
  Heart,
  MessageCircle,
  Bookmark,
  Share,
} from "lucide-react";
import UppyUploader from "./UppyUploader";

const VideoCreator = () => {
  // Main flow state

  const [pollingInterval, setPollingInterval] = useState(null);
  const [userProcessedVideos, setUserProcessedVideos] = useState([]);
  const [showLibrary, setShowLibrary] = useState(false);
  const [currentStep, setCurrentStep] = useState(1);
  const [processing, setProcessing] = useState(false);
  const [showFinalVideo, setShowFinalVideo] = useState(false);

  const [projectId, setProjectId] = useState(null);
  const [processingStep, setProcessingStep] = useState("");
  const [processingProgress, setProcessingProgress] = useState(0);
  const [TaskId, setTaskId] = useState(null);

  // Template selection state (Step 1)
  const [templates, setTemplates] = useState([]);
  const [selectedTemplate, setSelectedTemplate] = useState(null);
  const [templateLoading, setTemplateLoading] = useState(true);
  const [previewingTemplate, setPreviewingTemplate] = useState(null);

  // Hook caption state (Step 2)
  const [hookCaption, setHookCaption] = useState("");
  const [captionPosition, setCaptionPosition] = useState("top");

  // Demo video state (Step 3)
  //   const [includeDemoVideo, setIncludeDemoVideo] = useState(false);
  const [includeDemoVideo, setIncludeDemoVideo] = useState(true);
  const [demoVideo, setDemoVideo] = useState(null);
  const [uploadingDemo, setUploadingDemo] = useState(false);
  const [timelineCaptions, setTimelineCaptions] = useState([]);
  const [uploadProgress, setUploadProgress] = useState(0);

  // Audio selection
  const [backgroundMusic, setBackgroundMusic] = useState(null);
  const [musicTracks, setMusicTracks] = useState([]);

  // Video playback state
  const [isPlaying, setIsPlaying] = useState(false);
  const [currentTime, setCurrentTime] = useState(0);
  const [duration, setDuration] = useState(0);

  // Final output
  const [finalVideoUrl, setFinalVideoUrl] = useState(null);
  const [showCustomTemplateModal, setShowCustomTemplateModal] = useState(false);
  const [userDemoVideos, setUserDemoVideos] = useState([]);
  const [videoUrl, setVideoUrl] = useState(null);
  const [captionBg, setCaptionBg] = useState("black");

  const [selectedCategory, setSelectedCategory] = useState("people_expression");
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);

  // User data
  const [userCredits, setUserCredits] = useState({
    video_credits: 0,
    hasPreviewAccess: false,
  });

  // Refs
  const videoRef = useRef(null);
  const canvasRef = useRef(null);
  const progressBarRef = useRef(null);
  const [demoVideoLoading, setDemoVideoLoading] = useState(false);
  const [selectedFont, setSelectedFont] = useState("montserrat");
  const [fontSize, setFontSize] = useState("small");

  const categories = [
    // { value: "", label: "All Categories" },
    { value: "people_expression", label: "People Expression" },
    { value: "avatar_cartoon", label: "Avatar & Cartoon Style" },
    { value: "cinematic", label: "Cinematic Snippets" },
    { value: "icon_text", label: "Icon / Illustration + Text" },

    { value: "meme_remix", label: "Meme Remix" },
    { value: "minimalist_text", label: "Minimalist Text & Background" },
  ];

  const fetchUserProcessedVideos = async () => {
    try {
      const response = await instance.get("api/processed-videos/");
      setUserProcessedVideos(response.data);
    } catch (error) {
      console.error("Failed to fetch processed videos:", error);
    }
  };

  // 4. Add useEffect to load processed videos when component mounts
  useEffect(() => {
    fetchUserProcessedVideos();
  }, []);

  useEffect(() => {
    const fetchUserCredits = async () => {
      try {
        const response = await instance.get("get-user-credits/");

        // Store credit information
        setUserCredits({
          video_credits: response.data.video_credits,
          hasPreviewAccess: !response.data.accessed_video_free_preview,
          plan_name: response.data.plan_name,
          subscription_active: response.data.subscription_active,
        });

        console.log("📊 User credits loaded:", response.data);
      } catch (error) {
        console.error("❌ Failed to fetch user credits:", error);
        showNotification("Error", "Failed to load your credit information. Please refresh the page.", "error");
      }
    };

    fetchUserCredits();
  }, []);

  const showNotification = (title, message, type = "info") => {
    // Create notification container if it doesn't exist
    let container = document.getElementById("notification-container");
    if (!container) {
      container = document.createElement("div");
      container.id = "notification-container";
      container.className = "fixed top-4 right-4 z-50 flex flex-col gap-2";
      document.body.appendChild(container);
    }

    // Create notification element
    const notification = document.createElement("div");
    notification.className = `max-w-md p-4 mb-4 rounded-lg shadow-lg flex items-start gap-3 transform translate-x-0 transition-all duration-500 ${
      type === "error"
        ? "bg-red-50 border-l-4 border-red-500"
        : type === "success"
        ? "bg-green-50 border-l-4 border-green-500"
        : type === "warning"
        ? "bg-amber-50 border-l-4 border-amber-500"
        : "bg-blue-50 border-l-4 border-blue-500"
    }`;

    const iconColor =
      type === "error" ? "text-red-500" : type === "success" ? "text-green-500" : type === "warning" ? "text-amber-500" : "text-blue-500";

    // Add content
    notification.innerHTML = `
      <div className="flex-shrink-0">
        ${
          type === "error"
            ? `<svg class="w-5 h-5 ${iconColor}" fill="none" viewBox="0 0 24 24" stroke="currentColor">
            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 8v4m0 4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
          </svg>`
            : type === "success"
            ? `<svg class="w-5 h-5 ${iconColor}" fill="none" viewBox="0 0 24 24" stroke="currentColor">
            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z" />
          </svg>`
            : type === "warning"
            ? `<svg class="w-5 h-5 ${iconColor}" fill="none" viewBox="0 0 24 24" stroke="currentColor">
            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 9v2m0 4h.01m-6.938 4h13.856c1.54 0 2.502-1.667 1.732-3L13.732 4c-.77-1.333-2.694-1.333-3.464 0L3.34 16c-.77 1.333.192 3 1.732 3z" />
          </svg>`
            : `<svg class="w-5 h-5 ${iconColor}" fill="none" viewBox="0 0 24 24" stroke="currentColor">
            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
          </svg>`
        }
      </div>
      <div class="flex-1">
        <h3 class="font-medium text-gray-900">${title}</h3>
        <p class="text-sm text-gray-600">${message}</p>
      </div>
      <button class="flex-shrink-0 text-gray-400 hover:text-gray-600" onclick="this.parentElement.remove()">
        <svg class="w-4 h-4" fill="none" viewBox="0 0 24 24" stroke="currentColor">
          <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12" />
        </svg>
      </button>
    `;

    // Add to container
    container.appendChild(notification);

    // Auto remove after 6 seconds
    setTimeout(() => {
      notification.style.opacity = "0";
      notification.style.transform = "translateX(100%)";
      setTimeout(() => {
        if (notification.parentElement) {
          notification.remove();
        }
      }, 300);
    }, 6000);
  };

  //  useEffect to fetch the user's videos:

  const fetchDemoVideos = async () => {
    try {
      console.log("Fetching user demo videos...");
      const response = await instance.get("api/user-demo-videos/");
      console.log("User demo videos response:", response.data);
      setUserDemoVideos(response.data || []);
    } catch (error) {
      console.error("Failed to fetch user demo videos:", error);
      setUserDemoVideos([]);
    }
  };

  useEffect(() => {
    if (currentStep === 3) {
      fetchDemoVideos();
    }
  }, [currentStep]);

  // Fetch user credits from API
  useEffect(() => {
    const fetchUserCredits = async () => {
      try {
        const response = await instance.get("get-user-credits/");
        setUserCredits({
          video_credits: response.data.video_credits,
          hasPreviewAccess: !response.data.accessed_video_free_preview,
        });
      } catch (error) {
        console.error("Failed to fetch user credits:", error);
      }
    };

    fetchUserCredits();
  }, []);

  // Add this useEffect for the final video check
  useEffect(() => {
    // Function to do one final check for the processed video
    const finalVideoCheck = async () => {
      if (!projectId || !processing || finalVideoUrl) return;

      console.log("Performing final video check for project:", projectId);

      try {
        const response = await instance.get(`api/video-status/${projectId}/`);

        if (response.data.is_processed && response.data.processed_video_url) {
          const videoUrl = response.data.processed_video_url;

          if (videoUrl && videoUrl.trim() !== "") {
            console.log("Final check found video URL:", videoUrl);
            setFinalVideoUrl(videoUrl);

            // Show final video
            setTimeout(() => {
              setShowFinalVideo(true);
              setProcessing(false);

              // Also refresh the user's library
              fetchUserProcessedVideos();
            }, 1000);
          }
        }
      } catch (error) {
        console.error("Error in final video check:", error);
      }
    };

    // Set up a special timeout check for when processing has been happening for a long time
    // This helps catch cases where the backend is done but the frontend missed the notification
    let finalCheckTimeout;

    if (processing && projectId) {
      finalCheckTimeout = setTimeout(finalVideoCheck, 60000); // Extra check after 1 minute
    }

    return () => {
      if (finalCheckTimeout) clearTimeout(finalCheckTimeout);
    };
  }, [processing, projectId, finalVideoUrl]);

  // Fetch video templates from API
  // useEffect(() => {
  //   const fetchTemplates = async () => {
  //     setTemplateLoading(true);
  //     try {
  //       const response = await instance.get("api/hook-videos/");
  //       setTemplates(response.data);
  //       // Select first template by default if available
  //       if (response.data.length > 0) {
  //         setSelectedTemplate(response.data[0]);
  //       }
  //     } catch (error) {
  //       console.error("Failed to fetch templates:", error);
  //     } finally {
  //       setTemplateLoading(false);
  //     }
  //   };

  //   fetchTemplates();
  // }, []);

  useEffect(() => {
    const fetchTemplates = async () => {
      setTemplateLoading(true);
      try {
        const response = await instance.get("api/hook-videos/", {
          params: {
            category: selectedCategory,
            page: currentPage,
            page_size: 12,
          },
        });
        setTemplates(response.data.results);
        setTotalPages(response.data.total_pages);
      } catch (error) {
        console.error("Failed to fetch templates:", error);
      } finally {
        setTemplateLoading(false);
      }
    };

    fetchTemplates();
  }, [selectedCategory, currentPage]);

  // Fetch music tracks from API
  useEffect(() => {
    const fetchMusicTracks = async () => {
      try {
        const response = await instance.get("api/background-music/");
        setMusicTracks(response.data);
        // Select first track by default if available
        if (response.data.length > 0) {
          setBackgroundMusic(response.data[0]);
        }
      } catch (error) {
        console.error("Failed to fetch music tracks:", error);
      }
    };

    fetchMusicTracks();
  }, []);

  const handleTemplatePreview = (template) => {
    setPreviewingTemplate(template);
    setSelectedTemplate(template);

    // Pre-populate hook caption based on template metadata if available
    if (template.metadata && template.metadata.suggested_caption) {
      setHookCaption(template.metadata.suggested_caption);
    } else {
      // setHookCaption("✨ Make Your Product Stand Out!");
      setHookCaption("I think i might have found the ... app everyone is trying to hide");
    }

    // Set up video preview
    if (videoRef.current) {
      videoRef.current.src = template.s3_url;
      videoRef.current.load();

      // Play the video after it's loaded
      videoRef.current.onloadeddata = () => {
        videoRef.current
          .play()
          .then(() => setIsPlaying(true))
          .catch((error) => console.error("Video playback failed:", error));
      };
    }
  };

  // Handle template selection

  const handleTemplateSelect = (template) => {
    // Just call the preview function which now also selects the template
    handleTemplatePreview(template);
  };

  const handleDemoUpload = async (e) => {
    const file = e.target.files[0];
    if (!file) return;

    setUploadingDemo(true);

    // Create FormData for upload
    const formData = new FormData();
    formData.append("demo_video", file);

    try {
      // Add progress tracking
      const response = await instance.post("api/upload-demo-video/", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },

        // For handleDemoUpload function, right after setting progress:
        onUploadProgress: (progressEvent) => {
          const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
          // Update upload progress
          setUploadProgress(percentCompleted);
          console.log("Upload progress:", percentCompleted);
        },
      });

      // Set the demo video information
      setDemoVideo(response.data);

      // Enable demo video if it was disabled
      if (!includeDemoVideo) {
        setIncludeDemoVideo(true);
      }

      // If we have a video ref, load the new video
      if (videoRef.current && currentStep === 3) {
        videoRef.current.src = response.data.s3_url;
        videoRef.current.load();
      }
    } catch (error) {
      console.error("Failed to upload demo video:", error);
      alert("Failed to upload demo video. Please try again with a smaller file or different format.");
    } finally {
      setUploadingDemo(false);
      setUploadProgress(0);
    }
  };

  // const addTimelineCaption = () => {
  //   if (!videoRef.current) return;

  //   // Create modal dialog for caption entry
  //   const dialog = document.createElement("div");
  //   dialog.className = "fixed inset-0 bg-black/20 backdrop-blur-sm flex items-center justify-center z-50";
  //   dialog.innerHTML = `
  //     <div class="bg-white rounded-2xl p-6 shadow-2xl w-full max-w-md transform transition-all">
  //       <h3 class="text-lg font-medium text-gray-900 mb-3">Add Timeline Caption</h3>
  //       <input
  //         type="text"
  //         placeholder="What's happening in this moment?"
  //         class="w-full px-4 py-3 rounded-xl bg-gray-50 border-0 focus:ring-2 focus:ring-green-500"
  //         autofocus
  //       />
  //       <div class="mt-4 flex items-center justify-center gap-4">
  //         <button data-position="top" class="p-2 rounded-lg hover:bg-green-50 transition-colors">
  //           <svg width="20" height="20" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
  //             <path d="m5 12 7-7 7 7"></path>
  //             <path d="M12 19V5"></path>
  //           </svg>
  //         </button>
  //         <button data-position="center" class="p-2 rounded-lg bg-green-50 hover:bg-green-100 transition-colors">
  //           <svg width="20" height="20" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
  //             <line x1="21" x2="3" y1="6" y2="6"></line>
  //             <line x1="21" x2="3" y1="12" y2="12"></line>
  //             <line x1="21" x2="3" y1="18" y2="18"></line>
  //           </svg>
  //         </button>
  //         <button data-position="bottom" class="p-2 rounded-lg hover:bg-green-50 transition-colors">
  //           <svg width="20" height="20" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
  //             <path d="m19 12-7 7-7-7"></path>
  //             <path d="M12 5v14"></path>
  //           </svg>
  //         </button>
  //       </div>
  //       <div class="mt-6 flex justify-end gap-3">
  //         <button id="cancel" class="px-4 py-2 text-sm text-gray-600 hover:text-gray-900">Cancel</button>
  //         <button id="confirm" class="px-4 py-2 text-sm bg-green-500 text-white rounded-lg hover:bg-green-600">
  //           Add Caption
  //         </button>
  //       </div>
  //     </div>
  //   `;

  //   document.body.appendChild(dialog);
  //   const input = dialog.querySelector("input");
  //   let position = "center"; // Default position

  //   // Handle position buttons
  //   dialog.querySelectorAll("[data-position]").forEach((btn) => {
  //     btn.onclick = () => {
  //       position = btn.dataset.position;
  //       dialog.querySelectorAll("[data-position]").forEach((b) => b.classList.remove("bg-green-50"));
  //       btn.classList.add("bg-green-50");
  //     };
  //   });

  //   dialog.querySelector("#cancel").onclick = () => dialog.remove();
  //   dialog.querySelector("#confirm").onclick = () => {
  //     if (input.value.trim()) {
  //       const newCaption = {
  //         time: videoRef.current.currentTime,
  //         text: input.value.trim(),
  //         position: position,
  //       };
  //       setTimelineCaptions((prev) => [...prev, newCaption].sort((a, b) => a.time - b.time));
  //     }
  //     dialog.remove();
  //   };
  // };

  const addTimelineCaption = () => {
    if (!videoRef.current) return;

    // Create modal dialog for caption entry
    const dialog = document.createElement("div");
    dialog.className = "fixed inset-0 bg-black/20 backdrop-blur-sm flex items-center justify-center z-50";
    dialog.innerHTML = `
      <div class="bg-white rounded-2xl p-6 shadow-2xl w-full max-w-md transform transition-all">
        <h3 class="text-lg font-medium text-gray-900 mb-3">Add Timeline Caption</h3>
        <input 
          type="text"
          placeholder="What's happening in this moment?"
          class="w-full px-4 py-3 rounded-xl bg-gray-50 border-0 focus:ring-2 focus:ring-green-500"
          autofocus
        />
        
        <div class="mt-4">
          <label class="block text-sm font-medium text-gray-700 mb-2">Caption Position</label>
          <div class="flex items-center justify-center gap-4">
            <button data-position="top" class="p-2 rounded-lg hover:bg-green-50 transition-colors">
              <svg width="20" height="20" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
                <path d="m5 12 7-7 7 7"></path>
                <path d="M12 19V5"></path>
              </svg>
            </button>
            <button data-position="center" class="p-2 rounded-lg bg-green-50 hover:bg-green-100 transition-colors">
              <svg width="20" height="20" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
                <line x1="21" x2="3" y1="6" y2="6"></line>
                <line x1="21" x2="3" y1="12" y2="12"></line>
                <line x1="21" x2="3" y1="18" y2="18"></line>
              </svg>
            </button>
            <button data-position="bottom" class="p-2 rounded-lg hover:bg-green-50 transition-colors">
              <svg width="20" height="20" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
                <path d="m19 12-7 7-7-7"></path>
                <path d="M12 5v14"></path>
              </svg>
            </button>
          </div>
        </div>
        
        <div class="mt-6 flex justify-end gap-3">
          <button id="cancel" class="px-4 py-2 text-sm text-gray-600 hover:text-gray-900">Cancel</button>
          <button id="confirm" class="px-4 py-2 text-sm bg-green-500 text-white rounded-lg hover:bg-green-600">
            Add Caption
          </button>
        </div>
      </div>
    `;

    document.body.appendChild(dialog);
    const input = dialog.querySelector("input");
    let position = "center"; // Default position

    // Handle position buttons
    dialog.querySelectorAll("[data-position]").forEach((btn) => {
      btn.onclick = () => {
        position = btn.dataset.position;
        dialog.querySelectorAll("[data-position]").forEach((b) => b.classList.remove("bg-green-50"));
        btn.classList.add("bg-green-50");
      };
    });

    dialog.querySelector("#cancel").onclick = () => dialog.remove();
    dialog.querySelector("#confirm").onclick = () => {
      if (input.value.trim()) {
        const newCaption = {
          time: videoRef.current.currentTime,
          text: input.value.trim(),
          position: position,
        };
        setTimelineCaptions((prev) => [...prev, newCaption].sort((a, b) => a.time - b.time));
      }
      dialog.remove();
    };
  };

  // Add this function to your component to preview captions with styling
  const renderTimelineCaptionPreview = (caption) => {
    if (!caption) return null;

    // Use the same styling as the hook caption
    const style = {
      fontFamily:
        selectedFont === "bebas"
          ? "'Bebas Neue', sans-serif"
          : selectedFont === "anton"
          ? "'Anton', sans-serif"
          : selectedFont === "montserrat"
          ? "'Montserrat', sans-serif"
          : "'Inter', sans-serif",
      fontSize: fontSize === "small" ? "1rem" : fontSize === "large" ? "1.75rem" : "1.25rem",
      backgroundColor: captionBg !== "transparent" ? captionBg : undefined,
      color: "white",
      padding: "0.25rem 0.5rem",
      borderRadius: "0.25rem",
      textShadow: "0 1px 3px rgba(0,0,0,0.8)",
    };

    return <p style={style}>{caption.text}</p>;
  };
  // Format time display (MM:SS)
  const formatTime = (time) => {
    const minutes = Math.floor(time / 60);
    const seconds = Math.floor(time % 60);
    return `${minutes}:${seconds.toString().padStart(2, "0")}`;
  };

  // Handle custom template upload
  const handleCustomTemplateUpload = async (e) => {
    const file = e.target.files[0];
    if (!file) return;

    // Show loading state
    setTemplateLoading(true);

    // Create FormData for upload
    const formData = new FormData();
    formData.append("hook_video", file);

    try {
      // Make API call to upload custom template
      const response = await instance.post("api/upload-hook-video/", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });

      // Create template object from response
      const customTemplate = {
        id: response.data.id,
        title: file.name.replace(/\.[^/.]+$/, "") || "My Custom Template",
        s3_url: response.data.s3_url,
        thumbnails: response.data.thumbnails,
        description: "Custom uploaded template",
        category: "Custom",
        isCustom: true,
      };

      // Add to templates list and select it
      setTemplates((prev) => [customTemplate, ...prev]);
      handleTemplateSelect(customTemplate);
    } catch (error) {
      console.error("Failed to upload custom template:", error);
      alert("Failed to upload video. Please try again with a smaller file or different format.");
    } finally {
      setTemplateLoading(false);
    }
  };

  // Toggle video playback
  const togglePlayback = () => {
    if (!videoRef.current) return;

    if (videoRef.current.paused) {
      videoRef.current
        .play()
        .then(() => {
          setIsPlaying(true);
        })
        .catch((error) => {
          console.error("Video playback failed:", error);
        });
    } else {
      videoRef.current.pause();
      setIsPlaying(false);
    }
  };
  // Add to your useEffect hooks
  useEffect(() => {
    // Recover task/project IDs from localStorage if available
    const savedTaskId = localStorage.getItem("videoTaskId");
    const savedProjectId = localStorage.getItem("videoProjectId");

    if (savedTaskId && savedProjectId) {
      setTaskId(savedTaskId);
      setProjectId(savedProjectId);

      // Check if the task is still processing
      instance
        .get(`api/video-status/${savedProjectId}/`)
        .then((response) => {
          if (!response.data.is_processed) {
            // Resume UI for in-progress video
            setProcessing(true);
            simulateProcessingSteps();
            setTimeout(checkVideoStatus, 1000);
          } else if (response.data.processed_video_url) {
            // Video is already done
            setFinalVideoUrl(response.data.processed_video_url);
          }
        })
        .catch((error) => {
          console.error("Error checking saved task:", error);
          // Clear invalid storage
          localStorage.removeItem("videoTaskId");
          localStorage.removeItem("videoProjectId");
        });
    }
  }, []);

  // Add this function to your VideoCreator component
  const checkVideoStatus = async (projectId) => {
    if (!projectId) return;

    console.log("Checking status for project:", projectId);

    try {
      // Force a fresh check from the server
      const response = await instance.get(`api/video-status/${projectId}/`, {
        params: { force: "true" }, // Add force parameter to ensure fresh check
      });

      console.log("Status check response:", response.data);

      // Update UI with current status
      if (response.data.status) {
        setProcessingStep(response.data.status);
      }

      // Calculate progress based on status
      if (response.data.status) {
        const progressMappings = {
          Preparing: 10,
          "Adding hook": 30,
          "Processing demo": 50,
          "Adding captions": 70,
          "Adding music": 85,
          Finalizing: 95,
          Complete: 100,
        };

        // Set progress based on status text
        for (const [keyword, progress] of Object.entries(progressMappings)) {
          if (response.data.status.includes(keyword)) {
            setProcessingProgress(progress);
            break;
          }
        }
      }

      // Handle completed video
      if (response.data.is_processed && response.data.processed_video_url) {
        setFinalVideoUrl(response.data.processed_video_url);
        setProcessingProgress(100);
        setProcessingStep("Complete! Your video is ready.");

        // Update thumbnail if available
        if (response.data.thumbnail_url) {
          console.log("Thumbnail available:", response.data.thumbnail_url);
        }

        setTimeout(() => {
          setShowFinalVideo(true);
          setProcessing(false);

          // Refresh video library
          fetchUserProcessedVideos();
        }, 1000);

        return true;
      }
      // Handle error
      else if (response.data.error) {
        console.error("Processing error:", response.data.error);
        showNotification("Error", "Error processing video: " + response.data.error, "error");
        setProcessing(false);
        return false;
      }
      // Still processing
      else {
        return false;
      }
    } catch (error) {
      console.error("Error checking status:", error);
      showNotification("Connection Error", "Failed to check video status. Please try again.", "error");
      return false;
    }
  };

  const simulateProcessingSteps = () => {
    const steps = [
      { progress: 10, message: "Preparing video files..." },
      { progress: 25, message: "Adding hook caption..." },
      { progress: 40, message: "Processing demo video..." },
      { progress: 60, message: "Adding timeline captions..." },
      { progress: 75, message: "Adding background music..." },
      { progress: 90, message: "Finalizing your video..." },
    ];

    let currentStep = 0;

    const updateStep = () => {
      if (currentStep < steps.length && !showFinalVideo) {
        setProcessingStep(steps[currentStep].message);
        setProcessingProgress(steps[currentStep].progress);
        currentStep++;
        setTimeout(updateStep, 5000 + Math.random() * 3000); // Random time between 5-8 seconds
      }
    };

    updateStep();
  };

  // Handle timeline scrubbing
  const handleTimelineScrub = (e) => {
    if (!videoRef.current || !progressBarRef.current) return;

    const rect = progressBarRef.current.getBoundingClientRect();
    const clickPosition = e.clientX - rect.left;
    const percentage = clickPosition / rect.width;

    videoRef.current.currentTime = percentage * videoRef.current.duration;
  };

  // Fixed showProcessingSuccessModal function to handle DOM properly
  const showProcessingSuccessModal = (projectId) => {
    // Create modal element
    const modalEl = document.createElement("div");
    modalEl.className = "fixed inset-0 z-50 flex items-center justify-center";
    modalEl.innerHTML = `
      <div class="absolute inset-0 bg-black/60 backdrop-blur-sm"></div>
      <div class="relative bg-white rounded-2xl w-full max-w-md mx-4 shadow-xl p-6">
        <div class="text-center">
          <div class="w-16 h-16 bg-green-100 rounded-full flex items-center justify-center mx-auto mb-4">
            <svg xmlns="http://www.w3.org/2000/svg" class="h-8 w-8 text-green-600" fill="none" viewBox="0 0 24 24" stroke="currentColor">
              <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 13l4 4L19 7" />
            </svg>
          </div>
          <h3 class="text-xl font-semibold text-gray-900 mb-2">Video Processing Started!</h3>
          <p class="text-gray-600 mb-6">Your video has been sent for processing. This typically takes 2-5 minutes to complete.</p>
          
          <div class="space-y-4">
            <div class="bg-blue-50 rounded-lg p-4 border border-blue-100 text-left">
              <p class="text-blue-800">Your video will appear in your library once it's ready. You can continue working on other tasks while it processes.</p>
            </div>
            
            <div class="flex flex-col sm:flex-row gap-3 justify-center">
              <a href="/video-library" class="px-4 py-2 bg-green-600 text-white rounded-lg hover:bg-green-700 text-center flex items-center justify-center gap-2">
                <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                  <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M15 10l4.553-2.276A1 1 0 0121 8.618v6.764a1 1 0 01-1.447.894L15 14M5 18h8a2 2 0 002-2V8a2 2 0 00-2-2H5a2 2 0 00-2 2v8a2 2 0 002 2z" />
                </svg>
                Go to My Videos
              </a>
              <button id="create-another-btn" class="px-4 py-2 border border-gray-200 bg-white text-gray-700 rounded-lg hover:bg-gray-50 text-center">
                Create Another Video
              </button>
            </div>
          </div>
        </div>
      </div>
    `;

    document.body.appendChild(modalEl);

    // Add event listeners after appending to DOM
    const createAnotherBtn = modalEl.querySelector("#create-another-btn");
    if (createAnotherBtn) {
      createAnotherBtn.addEventListener("click", () => {
        // Reset the form
        setCurrentStep(1);
        setProcessing(false);
        setShowFinalVideo(false);
        setTimelineCaptions([]);
        setDemoVideo(null);

        // Remove the modal
        modalEl.remove();
      });
    }

    // Auto remove after 30 seconds
    setTimeout(() => {
      if (document.body.contains(modalEl)) {
        modalEl.remove();
      }
    }, 30000);

    // Reset processing UI state
    setProcessing(false);
  };

  // const handleCreateVideo = async () => {
  //   // Validation checks
  //   if (!selectedTemplate) {
  //     showNotification("Missing Template", "Please select a template", "error");
  //     return;
  //   }
  //   if (!hookCaption.trim()) {
  //     showNotification("Missing Caption", "Please add a hook caption", "error");
  //     return;
  //   }
  //   if (!backgroundMusic) {
  //     showNotification("Missing Music", "Please select background music", "error");
  //     return;
  //   }

  //   // Check if user has credits - frontend check only
  //   if (userCredits.video_credits <= 0 && !userCredits.hasPreviewAccess) {
  //     showNotification("No Credits", "You don't have any video credits left. Please upgrade your plan.", "error");
  //     return;
  //   }

  //   // Reset any previous final video URL to prevent showing old videos
  //   setFinalVideoUrl(null);
  //   setShowFinalVideo(false);

  //   // Show processing state
  //   setProcessing(true);
  //   simulateProcessingSteps(); // Show immediate feedback

  //   try {
  //     // Create a properly structured caption object with complete styling
  //     const captionStyle = {
  //       text: hookCaption,
  //       position: captionPosition,
  //       font: selectedFont,
  //       fontSize: fontSize,
  //       background: captionBg,
  //     };

  //     // Apply the same styling options to each timeline caption
  //     const styledTimelineCaptions = timelineCaptions.map((caption) => ({
  //       ...caption,
  //       // Keep the caption's original position and text
  //       // But ensure it inherits the font and background styling from the hook caption
  //       font: selectedFont,
  //       fontSize: fontSize,
  //       background: captionBg,
  //     }));

  //     const payload = {
  //       // Hook video with caption and styling
  //       hook_video_url: selectedTemplate.s3_url,
  //       hook_caption: captionStyle,
  //       // Demo video with timeline captions (if included)
  //       demo_video_url: includeDemoVideo && demoVideo ? demoVideo.s3_url : null,
  //       timeline_captions: includeDemoVideo ? styledTimelineCaptions : [],
  //       // Selected background music
  //       background_music_url: backgroundMusic.url,
  //     };

  //     console.log("Sending video creation request with payload:", JSON.stringify(payload));

  //     // Call the API endpoint
  //     const response = await instance.post("api/process-video/", payload);
  //     console.log("Received video creation response:", response.data);

  //     if (response.data && response.data.project_id) {
  //       // Store the project ID
  //       setProjectId(response.data.project_id);

  //       // Update state based on response
  //       if (response.data.task_id) {
  //         console.log(`Task started with ID: ${response.data.task_id}`);
  //         setTaskId(response.data.task_id);

  //         // Store in localStorage for recovery
  //         // localStorage.setItem("videoTaskId", response.data.task_id);
  //         // localStorage.setItem("videoProjectId", response.data.project_id);
  //       }

  //       // Show success modal
  //       showProcessingSuccessModal(response.data.project_id);
  //     } else {
  //       // Handle unexpected response format
  //       console.error("Unexpected response format:", response.data);
  //       throw new Error("Received invalid response from server");
  //     }
  //   } catch (error) {
  //     console.error("Failed to create video:", error);
  //     setProcessing(false);

  //     // Provide more detailed error messages based on the error
  //     if (error.response) {
  //       console.error("Server response error:", error.response.data);
  //       showNotification("Server Error", error.response.data.message || "Unknown server error", "error");
  //     } else if (error.request) {
  //       console.error("No response received:", error.request);
  //       showNotification("Connection Error", "Server not responding. Please try again later.", "error");
  //     } else {
  //       showNotification("Error", "Failed to create video: " + error.message, "error");
  //     }
  //   }
  // };

  const CreditsBadge = ({ credits }) => {
    return (
      <div className="relative group">
        <div
          className={`bg-${credits > 0 ? "green" : "red"}-100 text-${
            credits > 0 ? "green" : "red"
          }-800 py-1 px-3 rounded-full text-sm flex items-center`}
        >
          <Video className="w-4 h-4 mr-1" />
          <span>
            {credits} credit{credits !== 1 ? "s" : ""}
          </span>
        </div>

        {/* Tooltip */}
        <div
          className="absolute z-50 w-64 p-3 bg-white rounded-lg shadow-xl border border-gray-200 text-sm text-gray-700 
                        opacity-0 invisible group-hover:opacity-100 group-hover:visible 
                        transition-all duration-200 bottom-full mb-2 left-1/2 transform -translate-x-1/2"
        >
          {credits > 0 ? (
            <>
              <p className="font-medium">
                You have {credits} video credit{credits !== 1 ? "s" : ""} available
              </p>
              <p className="text-xs mt-1">Each credit allows you to create one video</p>
            </>
          ) : userCredits.hasPreviewAccess ? (
            <>
              <p className="font-medium">You can create one free preview video</p>
              <p className="text-xs mt-1">Upgrade your plan for more credits</p>
            </>
          ) : (
            <>
              <p className="font-medium">You're out of credits</p>
              <p className="text-xs mt-1">Upgrade your plan to create more videos</p>
            </>
          )}
          <div
            className="absolute w-3 h-3 bg-white border-t border-l border-gray-200 transform rotate-45 
                          left-1/2 -bottom-1.5 -ml-1.5"
          ></div>
        </div>
      </div>
    );
  };

  // 2. Enhanced handleCreateVideo function with improved credit & font handling
  const handleCreateVideo = async () => {
    // Validation checks
    if (!selectedTemplate) {
      showNotification("Missing Template", "Please select a template", "error");
      return;
    }

    if (!hookCaption.trim()) {
      showNotification("Missing Caption", "Please add a hook caption", "error");
      return;
    }

    if (!backgroundMusic) {
      showNotification("Missing Music", "Please select background music", "error");
      return;
    }

    // Credit check - Show user-friendly message if no credits
    if (userCredits.video_credits <= 0 && !userCredits.hasPreviewAccess) {
      if (userCredits.subscription_active) {
        showNotification(
          "No Credits",
          `You've used all your video credits for this billing cycle. Your plan (${userCredits.plan_name}) will refresh credits on your next billing date.`,
          "error"
        );
      } else {
        showNotification("No Credits", "You don't have any video credits left. Please upgrade your plan to create more videos.", "error");
      }
      return;
    }

    // Reset any previous final video URL to prevent showing old videos
    // setFinalVideoUrl(null);
    // setShowFinalVideo(false);

    // Show processing state
    // setProcessing(true);
    // simulateProcessingSteps(); // Show immediate feedback

    try {
      // Create a properly structured caption object with complete styling
      const captionStyle = {
        text: hookCaption,
        position: captionPosition,
        font: selectedFont, // Important: Include font selection
        fontSize: fontSize,
        background: captionBg,
      };

      // Apply the same styling options to each timeline caption
      const styledTimelineCaptions = timelineCaptions.map((caption) => ({
        ...caption,
        // Keep the caption's original position and text
        // But ensure it inherits the font and background styling from the hook caption
        font: selectedFont, // Important: Include font selection
        fontSize: fontSize,
        background: captionBg,
      }));

      const payload = {
        // Hook video with caption and styling
        hook_video_url: selectedTemplate.s3_url,
        hook_caption: captionStyle,
        // Demo video with timeline captions (if included)
        demo_video_url: includeDemoVideo && demoVideo ? demoVideo.s3_url : null,
        timeline_captions: includeDemoVideo ? styledTimelineCaptions : [],
        // Selected background music
        background_music_url: backgroundMusic.url,
      };

      console.log("Sending video creation request with payload:", JSON.stringify(payload));

      // Call the API endpoint
      const response = await instance.post("api/process-video/", payload);
      console.log("Received video creation response:", response.data);

      if (response.data && response.data.project_id) {
        // Store the project ID
        setProjectId(response.data.project_id);

        // Update state based on response
        if (response.data.task_id) {
          console.log(`Task started with ID: ${response.data.task_id}`);
          setTaskId(response.data.task_id);

          // Store in localStorage for recovery
          localStorage.setItem("videoTaskId", response.data.task_id);
          localStorage.setItem("videoProjectId", response.data.project_id);
        }

        // Immediately update displayed credits (optimistic update)
        setUserCredits((prev) => ({
          ...prev,
          video_credits: Math.max(0, prev.video_credits - 1),
          hasPreviewAccess: false, // Mark preview as used
        }));

        // Show success modal
        showProcessingSuccessModal(response.data.project_id);
      } else {
        // Handle unexpected response format
        console.error("Unexpected response format:", response.data);
        throw new Error("Received invalid response from server");
      }
    } catch (error) {
      console.error("Failed to create video:", error);
      setProcessing(false);

      // Provide more detailed error messages based on the error
      if (error.response) {
        console.error("Server response error:", error.response.data);

        // Custom handling for credit-related errors
        if (error.response.data?.message?.includes("no video credits") || error.response.status === 402) {
          showNotification(
            "No Credits Available",
            "You don't have any video credits left. Please upgrade your plan to create more videos.",
            "error"
          );
        } else {
          showNotification("Server Error", error.response.data.message || "Unknown server error", "error");
        }
      } else if (error.request) {
        console.error("No response received:", error.request);
        showNotification("Connection Error", "Server not responding. Please try again later.", "error");
      } else {
        showNotification("Error", "Failed to create video: " + error.message, "error");
      }
    }
  };

  // 3. Enhanced styling for Caption component in Step 2
  // Fix the font selection issue with proper styling application
  // Add this to the Step 2 component return JSX:

  const renderHookCaption = () => {
    // Convert font name to CSS font-family
    const getFontFamily = () => {
      switch (selectedFont) {
        case "bebas":
          return "'Bebas Neue', sans-serif";
        case "anton":
          return "'Anton', sans-serif";
        case "montserrat":
          return "'Montserrat', sans-serif";
        default:
          return "'Inter', sans-serif";
      }
    };

    // Get font size CSS value
    const getFontSize = () => {
      switch (fontSize) {
        case "small":
          return "1rem";
        case "large":
          return "1.75rem";
        default:
          return "1.25rem";
      }
    };

    return (
      <div
        className={`absolute inset-x-0 ${
          captionPosition === "top" ? "top-8" : captionPosition === "center" ? "top-1/2 -translate-y-1/2" : "bottom-8"
        } px-6 text-center z-20`}
      >
        <p
          className="text-white font-normal px-3 py-1 inline-block rounded-md"
          style={{
            backgroundColor: captionBg,
            fontFamily: getFontFamily(),
            fontSize: getFontSize(),
          }}
        >
          {hookCaption || "Add your caption"}
        </p>
      </div>
    );
  };

  useEffect(() => {
    // If we're back at step 1, we should clear any final video state
    if (currentStep === 1) {
      setShowFinalVideo(false);
      setFinalVideoUrl(null);
      setProcessing(false);
    }
  }, [currentStep]);

  // Then modify your session recovery function to be more selective
  const recoverVideoSession = async () => {
    // Only try to recover session if we're at step 1 (initial state)
    if (currentStep !== 1) {
      return;
    }

    const savedTaskId = localStorage.getItem("videoTaskId");
    const savedProjectId = localStorage.getItem("videoProjectId");

    if (savedTaskId && savedProjectId) {
      try {
        const response = await instance.get(`api/video-status/${savedProjectId}/`);

        // Only recover and show completed video if it's actually complete
        if (response.data.is_processed && response.data.processed_video_url) {
          setTaskId(savedTaskId);
          setProjectId(savedProjectId);
          setFinalVideoUrl(response.data.processed_video_url);

          // Show notification instead of modal
          showNotification("Video Ready", "Your recently processed video is ready to view.", "success");

          // Don't automatically show the modal - let user continue their new video
          // setShowFinalVideo(true);

          // Show button to view previous video
          setTimeout(() => {
            // Create a floating button to view the previous video
            const viewBtn = document.createElement("div");
            viewBtn.className = "fixed bottom-4 right-4 z-40";
            viewBtn.innerHTML = `
              <button id="view-last-video" class="px-4 py-2 bg-green-600 text-white rounded-lg shadow-lg hover:bg-green-700 flex items-center gap-2">
                <svg class="w-5 h-5" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                  <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M14.752 11.168l-3.197-2.132A1 1 0 0010 9.87v4.263a1 1 0 001.555.832l3.197-2.132a1 1 0 000-1.664z" />
                  <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
                </svg>
                View Your Last Video
              </button>
            `;

            document.body.appendChild(viewBtn);

            // Add click handler
            document.getElementById("view-last-video").addEventListener("click", () => {
              setShowFinalVideo(true);
              viewBtn.remove();
            });

            // Auto remove after 15 seconds
            setTimeout(() => {
              if (document.body.contains(viewBtn)) {
                viewBtn.remove();
              }
            }, 15000);
          }, 1000);
        } else {
          // If video processing was started but not completed, just clear the localStorage
          localStorage.removeItem("videoTaskId");
          localStorage.removeItem("videoProjectId");
        }
      } catch (error) {
        console.error("Failed to recover session:", error);
        // Clear invalid storage
        localStorage.removeItem("videoTaskId");
        localStorage.removeItem("videoProjectId");
      }
    }
  };

  // Call this in useEffect as before
  // useEffect(() => {
  //   recoverVideoSession();
  // }, []);

  const renderStepContent = () => {
    switch (currentStep) {
      // Step 1: Choose Template
      case 1:
        return (
          <div className="space-y-8">
            <div className="bg-white rounded-2xl p-8 shadow-lg border border-gray-100">
              <div className="grid grid-cols-1 lg:grid-cols-12 gap-8">
                {/* Left Side: Template Grid - 8 columns */}
                <div className="lg:col-span-8">
                  <div className="flex flex-col sm:flex-row sm:items-center justify-between gap-4 mb-6">
                    <h3 className="text-xl font-semibold text-gray-900 tracking-tight">Choose a Template</h3>
                    <div className="relative group">
                      <select
                        value={selectedCategory}
                        onChange={(e) => {
                          setSelectedCategory(e.target.value);
                          setCurrentPage(1);
                        }}
                        className="w-full md:w-72 px-4 py-3 rounded-xl border-2 border-gray-200 bg-white hover:border-green-400 focus:border-green-500 focus:ring-4 focus:ring-green-100 transition-all appearance-none text-base font-medium"
                      >
                        {categories.map((cat) => (
                          <option key={cat.value} value={cat.value}>
                            {cat.label}
                          </option>
                        ))}
                      </select>
                      <div className="absolute right-3 top-1/2 -translate-y-1/2 p-1.5 rounded-full bg-green-100 text-green-600 pointer-events-none group-hover:bg-green-500 group-hover:text-white transition-colors">
                        <Filter className="w-4 h-4" />
                      </div>
                    </div>
                  </div>

                  {templateLoading ? (
                    <div className="py-16 flex flex-col items-center justify-center bg-gray-50 rounded-2xl">
                      <Loader2 className="w-10 h-10 animate-spin text-green-500 mb-4" />
                      <p className="text-gray-600 font-medium">Loading templates...</p>
                    </div>
                  ) : (
                    <div className="grid grid-cols-2 sm:grid-cols-3 md:grid-cols-4 gap-4">
                      {templates.map((template) => (
                        <div
                          key={template.id}
                          onClick={() => {
                            setSelectedTemplate(template);
                            setHookCaption(
                              template.metadata?.suggested_caption || "I think i might have found the ... app everyone is trying to hide"
                            );
                            if (videoRef.current) {
                              videoRef.current.src = template.s3_url;
                              videoRef.current.load();
                              videoRef.current
                                .play()
                                .then(() => setIsPlaying(true))
                                .catch((error) => console.error("Video playback failed:", error));
                            }
                          }}
                          className={`group relative rounded-xl overflow-hidden border-2 transition-all duration-200 cursor-pointer transform hover:scale-[1.02]
                            ${
                              selectedTemplate?.id === template.id
                                ? "ring-2 ring-green-500 border-green-500"
                                : "border-gray-100 hover:border-green-300"
                            }`}
                          onMouseEnter={() => {
                            if (videoRef.current && !isPlaying) {
                              videoRef.current.src = template.s3_url;
                              videoRef.current.load();
                              videoRef.current
                                .play()
                                .then(() => setIsPlaying(true))
                                .catch((error) => console.error("Video preview failed:", error));
                            }
                          }}
                        >
                          <div className="aspect-[9/16] relative">
                            <img
                              src={template.thumbnails?.mobile || "https://placehold.co/300x533/gray/white"}
                              alt={template.title || "Video template"}
                              className="w-full h-full object-cover"
                            />

                            <div className="absolute inset-0 bg-black/40 opacity-0 group-hover:opacity-100 transition-opacity duration-200">
                              <div className="absolute inset-0 flex items-center justify-center">
                                <div className="p-3 rounded-full bg-white/90 text-green-600 transform translate-y-2 group-hover:translate-y-0 transition-transform duration-200">
                                  <Play className="w-6 h-6" />
                                </div>
                              </div>
                            </div>

                            {selectedTemplate?.id === template.id && (
                              <div className="absolute top-3 right-3 w-7 h-7 bg-green-500 rounded-full flex items-center justify-center">
                                <CheckCircle2 className="w-5 h-5 text-white" />
                              </div>
                            )}
                          </div>

                          <div className="p-3">
                            <h4 className="font-medium text-gray-900 text-sm truncate">{template.title || "Template"}</h4>
                          </div>
                        </div>
                      ))}
                    </div>
                  )}

                  <div className="flex justify-center mt-8 gap-4">
                    <button
                      onClick={() => setCurrentPage((prev) => Math.max(prev - 1, 1))}
                      disabled={currentPage === 1}
                      className="px-5 py-2.5 rounded-lg bg-gray-50 hover:bg-gray-100 disabled:opacity-50 disabled:hover:bg-gray-50 transition-colors font-medium text-gray-700"
                    >
                      Previous
                    </button>

                    <span className="flex items-center text-sm text-gray-600 font-medium">
                      Page {currentPage} of {totalPages}
                    </span>

                    <button
                      onClick={() => setCurrentPage((prev) => Math.min(prev + 1, totalPages))}
                      disabled={currentPage === totalPages}
                      className="px-5 py-2.5 rounded-lg bg-gray-50 hover:bg-gray-100 disabled:opacity-50 disabled:hover:bg-gray-50 transition-colors font-medium text-gray-700"
                    >
                      Next
                    </button>
                  </div>

                  {/* Music Selection */}
                  <div className="mt-10">
                    <div className="flex items-center justify-between mb-6">
                      <h3 className="text-xl font-semibold text-gray-900 tracking-tight">Background Music</h3>
                      {backgroundMusic && (
                        <div className="flex items-center gap-3">
                          <audio id="music-preview" src={backgroundMusic.url} className="hidden" />
                          <button
                            onClick={(e) => {
                              e.stopPropagation();
                              const audio = document.getElementById("music-preview");
                              if (audio.paused) {
                                audio
                                  .play()
                                  .then(() => e.currentTarget.classList.add("animate-pulse"))
                                  .catch((err) => console.error("Error playing audio:", err));
                              } else {
                                audio.pause();
                                audio.currentTime = 0;
                                e.currentTarget.classList.remove("animate-pulse");
                              }
                            }}
                            className="p-2.5 rounded-full bg-green-50 text-green-600 hover:bg-green-100 transition-colors"
                          >
                            <Play className="w-4 h-4" />
                          </button>
                          <span className="text-green-600 text-sm font-medium">{backgroundMusic.title}</span>
                        </div>
                      )}
                    </div>

                    <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-3">
                      {musicTracks.map((track) => (
                        <button
                          key={track.id}
                          onClick={() => {
                            setBackgroundMusic(track);
                            const audio = document.getElementById("music-preview");
                            if (audio) {
                              audio.pause();
                              audio.src = track.url;
                              audio.currentTime = 0;
                              audio.play().catch((e) => console.error("Failed to play audio:", e));
                            }
                          }}
                          className={`group flex items-center gap-4 p-4 rounded-xl border-2 transition-all duration-200 ${
                            backgroundMusic?.id === track.id ? "border-green-500 bg-green-50" : "border-gray-100 hover:border-green-200"
                          }`}
                        >
                          <div
                            className={`p-2.5 rounded-full transition-colors ${
                              backgroundMusic?.id === track.id
                                ? "bg-green-500 text-white"
                                : "bg-gray-100 text-gray-500 group-hover:bg-green-100 group-hover:text-green-600"
                            }`}
                          >
                            <Music className="w-4 h-4" />
                          </div>
                          <div className="flex-1 text-left">
                            <p className="font-medium text-gray-900 text-sm">{track.title}</p>
                            <p className="text-xs text-gray-500 mt-0.5">{track.duration || "0:30"}</p>
                          </div>
                          {backgroundMusic?.id === track.id && <CheckCircle2 className="w-5 h-5 text-green-500" />}
                        </button>
                      ))}
                    </div>
                  </div>
                </div>

                {/* Right side: TikTok-style Preview */}
                <div className="lg:col-span-4 flex flex-col items-center sticky top-24 self-start">
                  <div className="w-[320px] h-[580px] bg-black rounded-[2.5rem] shadow-xl overflow-hidden relative">
                    {/* Status Bar */}
                    {/* <div className="absolute top-0 left-0 right-0 h-6 bg-black z-10 flex items-center justify-between px-5 py-5">
                      <div className="text-white text-xs">9:41</div>
                      <div className="flex items-center gap-1.5">
                        <Signal className="w-3.5 h-3.5 text-white" />
                        <Wifi className="w-3.5 h-3.5 text-white" />
                        <Battery className="w-4 h-4 text-white" />
                      </div>
                    </div> */}

                    <video
                      ref={videoRef}
                      className="w-full h-full object-cover"
                      preload="auto"
                      loop
                      muted
                      playsInline
                      onTimeUpdate={(e) => {
                        setCurrentTime(e.target.currentTime);
                        setDuration(e.target.duration);
                      }}
                      onLoadedData={(e) => {
                        setDuration(e.target.duration);
                        e.target.play().catch((err) => console.log("Auto-play prevented:", err));
                      }}
                      onEnded={(e) => {
                        e.target.currentTime = 0;
                        e.target.play().catch((err) => console.log("Replay prevented:", err));
                      }}
                    />

                    {!selectedTemplate && !isPlaying && (
                      <div className="absolute inset-0 flex items-center justify-center flex-col p-6 text-center bg-black/20 backdrop-blur-sm">
                        <div className="w-20 h-20 rounded-full bg-white/20 backdrop-blur flex items-center justify-center mb-4">
                          <Video className="w-10 h-10 text-white" />
                        </div>
                        <p className="text-white font-medium text-lg">Hover over templates to preview</p>
                      </div>
                    )}

                    {/* TikTok-style Interface Elements */}
                    <div className="absolute inset-y-0 right-4 flex flex-col items-center justify-end gap-4 pb-20">
                      {/* Interaction Buttons */}
                      <button className="p-3 flex flex-col items-center">
                        <div className="w-12 h-12 flex items-center justify-center rounded-full bg-black/40 backdrop-blur-sm mb-1">
                          <Heart className="w-6 h-6 text-white/50" />
                        </div>
                        <span className="text-white/50 text-xs font-medium">87.5K</span>
                      </button>

                      <button className="p-3 flex flex-col items-center">
                        <div className="w-12 h-12 flex items-center justify-center rounded-full bg-gray-900/60 backdrop-blur-md mb-1">
                          <MessageCircle className="w-7 h-7 text-white/90" />
                        </div>
                        <span className="text-white/90 text-xs font-medium">2,091</span>
                      </button>

                      <button className="p-3 flex flex-col items-center">
                        <div className="w-12 h-12 flex items-center justify-center rounded-full bg-gray-900/60 backdrop-blur-md mb-1">
                          <Bookmark className="w-7 h-7 text-white" />
                        </div>
                        <span className="text-white text-xs font-medium">23.1K</span>
                      </button>

                      <button className="p-3 flex flex-col items-center">
                        <div className="w-12 h-12 flex items-center justify-center rounded-full bg-gray-900/60 backdrop-blur-md mb-1">
                          <Share className="w-7 h-7 text-white" />
                        </div>
                        <span className="text-white text-xs font-medium">Share</span>
                      </button>
                    </div>

                    {/* Top Caption Area - Main Hook */}
                    {selectedTemplate && (
                      <div className="absolute top-12 inset-x-0 px-4 text-center z-10">
                        <p className="text-white text-lg font-bold" style={{ textShadow: "0 2px 4px rgba(0,0,0,0.8)" }}>
                          {hookCaption || "I think i might have found the ... app everyone is trying to hide"}
                        </p>
                      </div>
                    )}

                    {/* Bottom Caption Area - User Info */}
                    {selectedTemplate && (
                      <div className="absolute bottom-0 left-0 right-14 p-4">
                        <div className="flex items-center gap-3 mb-3">
                          <div className="w-11 h-11 rounded-full bg-gray-600 flex-shrink-0" />
                          <span className="text-white font-semibold">@username</span>
                        </div>
                        <p className="text-white text-sm leading-5 mb-4">
                          <span className="text-gray-300">#fyp #viral #trending</span>
                        </p>
                      </div>
                    )}

                    {/* Progress Bar */}
                    {isPlaying && (
                      <div className="absolute bottom-0 left-0 right-0">
                        <div ref={progressBarRef} onClick={handleTimelineScrub} className="w-full h-0.5 bg-gray-200/30 cursor-pointer">
                          <div
                            className="absolute inset-y-0 left-0 bg-white transition-all duration-150"
                            style={{ width: `${(currentTime / duration) * 100}%` }}
                          />
                        </div>
                      </div>
                    )}
                  </div>

                  {selectedTemplate && (
                    <div className="w-full mt-6 space-y-3">
                      <button
                        onClick={() => setCurrentStep(2)}
                        className="w-full py-3 bg-green-600 text-white rounded-xl hover:bg-green-700 transform hover:translate-y-[-1px] transition-all duration-200 flex items-center justify-center gap-2 font-medium text-lg shadow-lg shadow-green-200"
                      >
                        Continue <ArrowRight className="w-5 h-5" />
                      </button>
                      <p className="text-sm text-gray-500 text-center">You can customize more in the next step</p>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        );

      case 2:
        return (
          <div className="grid grid-cols-1 lg:grid-cols-2 gap-8">
            {/* Left Column - Hook Preview */}
            <div className="flex flex-col items-center">
              <div className="relative group mb-6">
                {/* Canvas Preview with Caption */}
                <div className="lg:col-span-4 flex flex-col items-center sticky top-24 self-start">
                  <div className="w-[300px] h-[533px] rounded-2xl bg-gray-100 overflow-hidden shadow-lg border border-gray-200 relative">
                    {selectedTemplate && (
                      <>
                        <video
                          ref={videoRef}
                          className="w-full h-full object-cover"
                          src={selectedTemplate.s3_url}
                          preload="auto"
                          loop
                          onTimeUpdate={(e) => {
                            setCurrentTime(e.target.currentTime);
                            setDuration(e.target.duration);
                          }}
                        />

                        {/* Caption Overlay */}
                        {/* <div
                          className={`absolute inset-x-0 ${
                            captionPosition === "top" ? "top-8" : captionPosition === "center" ? "top-1/2 -translate-y-1/2" : "bottom-8"
                          } px-6 text-center z-20`}
                        >
                          <p
                            className="text-white font-normal px-3 py-1 inline-block rounded-md"
                            style={{
                              backgroundColor: captionBg,
                              fontFamily: "Montserrat, sans-serif",
                              fontSize: fontSize === "small" ? "1rem" : fontSize === "large" ? "1.75rem" : "1.25rem",
                            }}
                          >
                            {hookCaption || "Add your caption"}
                          </p>
                        </div> */}

                        {renderHookCaption()}

                        {/* TikTok UI Elements */}
                        <div className="absolute inset-y-0 right-4 flex flex-col items-center justify-end gap-4 pb-20 z-10">
                          <button className="p-3 flex flex-col items-center">
                            <div className="w-12 h-12 flex items-center justify-center rounded-full bg-black/40 backdrop-blur-sm mb-1">
                              <Heart className="w-6 h-6 text-white/50" />
                            </div>
                            <span className="text-white/50 text-xs font-medium">87.5K</span>
                          </button>

                          <button className="p-3 flex flex-col items-center">
                            <div className="w-12 h-12 flex items-center justify-center rounded-full bg-black/40 backdrop-blur-sm mb-1">
                              <MessageCircle className="w-6 h-6 text-white/50" />
                            </div>
                            <span className="text-white/50 text-xs font-medium">2,091</span>
                          </button>

                          <button className="p-3 flex flex-col items-center">
                            <div className="w-12 h-12 flex items-center justify-center rounded-full bg-black/40 backdrop-blur-sm mb-1">
                              <Bookmark className="w-6 h-6 text-white/50" />
                            </div>
                            <span className="text-white/50 text-xs font-medium">23.1K</span>
                          </button>

                          <button className="p-3 flex flex-col items-center">
                            <div className="w-12 h-12 flex items-center justify-center rounded-full bg-black/40 backdrop-blur-sm mb-1">
                              <Share className="w-6 h-6 text-white/50" />
                            </div>
                            <span className="text-white/50 text-xs font-medium">Share</span>
                          </button>
                        </div>

                        {/* Play/Pause Button Overlay */}
                        {!isPlaying && (
                          <div
                            className="absolute inset-0 bg-black/30 flex items-center justify-center cursor-pointer z-30"
                            onClick={togglePlayback}
                          >
                            <div className="w-16 h-16 rounded-full bg-white/20 backdrop-blur-sm flex items-center justify-center">
                              <Play className="w-8 h-8 text-white" />
                            </div>
                          </div>
                        )}

                        {/* Video Controls */}
                        <div className="absolute bottom-0 left-0 right-0 p-4 bg-gradient-to-t from-black/80 to-transparent opacity-0 group-hover:opacity-100 transition-opacity z-20">
                          <div
                            ref={progressBarRef}
                            onClick={handleTimelineScrub}
                            className="w-full h-1 bg-white/30 rounded-full mb-4 cursor-pointer relative"
                          >
                            <div
                              className="absolute inset-y-0 left-0 bg-green-400 rounded-full"
                              style={{ width: `${(currentTime / duration) * 100}%` }}
                            />
                          </div>
                          <div className="flex items-center justify-between gap-4">
                            <span className="text-sm text-white">
                              {formatTime(currentTime)} / {formatTime(duration)}
                            </span>
                            <button onClick={togglePlayback} className="p-2 rounded-full bg-white/20 hover:bg-white/30 transition-colors">
                              {isPlaying ? <Pause className="w-5 h-5 text-white" /> : <Play className="w-5 h-5 text-white" />}
                            </button>
                          </div>
                        </div>

                        {/* User Info */}
                        <div className="absolute bottom-0 left-0 right-14 p-4 z-10">
                          <div className="flex items-center gap-3 mb-3">
                            <div className="w-11 h-11 rounded-full bg-gray-600/40 flex-shrink-0" />
                            <span className="text-white/90 font-medium">@username</span>
                          </div>
                          <p className="text-white/75 text-sm leading-5 mb-4">
                            <span className="text-white/60">#fyp #viral #trending</span>
                          </p>
                        </div>
                      </>
                    )}
                  </div>

                  {/* {selectedTemplate && (
                    <div className="w-full mt-6 space-y-3">
                      <button
                        onClick={() => setCurrentStep(2)}
                        className="w-full py-3 bg-green-600 text-white rounded-xl hover:bg-green-700 transform hover:translate-y-[-1px] transition-all duration-200 flex items-center justify-center gap-2 font-medium text-lg shadow-lg shadow-green-200"
                      >
                        Continue <ArrowRight className="w-5 h-5" />
                      </button>
                      <p className="text-sm text-gray-500 text-center">You can customize more in the next step</p>
                    </div>
                  )} */}
                </div>
              </div>
            </div>

            {/* Right Column - Caption Editor */}
            <div className="space-y-6">
              <div className="bg-white rounded-2xl p-6 shadow-sm border border-gray-200">
                <div className="flex items-center justify-between mb-4">
                  <h3 className="text-lg font-semibold text-gray-900">Hook Caption</h3>
                  <div className="flex items-center gap-1">
                    {[
                      { icon: ArrowUp, value: "top", label: "Top" },
                      { icon: AlignCenter, value: "center", label: "Center" },
                      { icon: ArrowDown, value: "bottom", label: "Bottom" },
                    ].map(({ icon: Icon, value, label }) => (
                      <button
                        key={value}
                        onClick={() => setCaptionPosition(value)}
                        className={`p-2 rounded-lg transition-colors flex items-center gap-1 ${
                          captionPosition === value ? "bg-green-100 text-green-800" : "hover:bg-green-50 text-gray-700"
                        }`}
                        title={`Position caption at ${label}`}
                      >
                        <Icon className="w-4 h-4" />
                        <span className="text-xs hidden md:inline">{label}</span>
                      </button>
                    ))}
                  </div>
                </div>
                <textarea
                  value={hookCaption}
                  onChange={(e) => setHookCaption(e.target.value)}
                  className="w-full h-32 bg-gray-50 rounded-xl p-4 resize-none border border-gray-200 focus:border-green-500 focus:ring-1 focus:ring-green-500 transition-colors"
                  placeholder="Enter your hook caption..."
                />

                {/* Caption Style Options */}
                {/* <div className="mt-6">
                  <h4 className="text-sm font-medium text-gray-700 mb-3">Text Style</h4>
                  <div className="grid grid-cols-2 gap-3">
                    <div className="space-y-2">
                      <label className="text-xs text-gray-500">Font</label>

                      <select
                        className="w-full px-3 py-2 bg-gray-50 border border-gray-200 rounded-lg focus:ring-2 focus:ring-green-500 focus:border-transparent"
                        value={selectedFont}
                        onChange={(e) => setSelectedFont(e.target.value)}
                      >
                        <option value="inter">Default (Inter)</option>
                        <option value="bebas">Bebas Neue</option>
                        <option value="anton">Anton</option>
                        <option value="montserrat">Montserrat</option>
                      </select>
                    </div>
                    <div className="space-y-2">
                      <label className="text-xs text-gray-500">Size</label>
                      <select
                        className="w-full px-3 py-2 bg-gray-50 border border-gray-200 rounded-lg focus:ring-2 focus:ring-green-500 focus:border-transparent"
                        value={fontSize}
                        onChange={(e) => setFontSize(e.target.value)}
                      >
                        <option value="small">Small</option>
                        <option value="medium">Medium</option>
                        <option value="large">Large</option>
                      </select>
                    </div>

                    <div className="space-y-2">
                      <label className="text-xs text-gray-500">Background</label>
                      <select
                        value={captionBg}
                        onChange={(e) => setCaptionBg(e.target.value)}
                        className="w-full px-3 py-2 bg-gray-50 border border-gray-200 rounded-lg focus:ring-2 focus:ring-green-500 focus:border-transparent"
                      >
                        <option value="#000000">Black (Default)</option>
                        <option value="#FFFFFFBF">White</option>

                        <option value="#FFE600">Yellow (TikTok Style)</option>
                        <option value="#FE2C55">TikTok Red</option>

                        <option value="#22c55e">Tailwind Green</option>
                        <option value="transparent">No Background</option>
                      </select>
                    </div>
                  </div>
                </div> */}
                {/* Caption Style Options */}
                <div className="mt-6">
                  <h4 className="text-sm font-medium text-gray-700 mb-3">Text Style</h4>
                  <div className="grid grid-cols-2 gap-3">
                    <div className="space-y-2">
                      <label className="text-xs text-gray-500">Font</label>
                      {/* <select
                        className="w-full px-3 py-2 bg-gray-50 border border-gray-200 rounded-lg focus:ring-2 focus:ring-green-500 focus:border-transparent"
                        value={selectedFont}
                        onChange={(e) => setSelectedFont(e.target.value)}
                      >
                        <option value="inter">Default (Inter)</option>
                        <option value="bebas">Bebas Neue</option>
                        <option value="anton">Anton</option>
                        <option value="montserrat">Montserrat</option>
                      </select> */}

                      <select
                        className="w-full px-3 py-2 bg-gray-50 border border-gray-200 rounded-lg focus:ring-2 focus:ring-green-500 focus:border-transparent"
                        value={selectedFont}
                        onChange={(e) => {
                          setSelectedFont(e.target.value);
                          console.log("Font changed to:", e.target.value); // Debug log
                        }}
                      >
                        <option value="montserrat">Default (Montserrat)</option>
                        <option value="inter">Inter</option>
                        <option value="bebas">Bebas Neue</option>
                        <option value="anton">Anton</option>
                      </select>
                    </div>
                    <div className="space-y-2">
                      <label className="text-xs text-gray-500">Size</label>
                      <select
                        className="w-full px-3 py-2 bg-gray-50 border border-gray-200 rounded-lg focus:ring-2 focus:ring-green-500 focus:border-transparent"
                        value={fontSize}
                        onChange={(e) => setFontSize(e.target.value)}
                      >
                        <option value="small">Small</option>
                        <option value="medium">Medium</option>
                        <option value="large">Large</option>
                      </select>
                    </div>

                    {/* <div className="space-y-2">
                      <label className="text-xs text-gray-500">Background</label>
                      <select
                        value={captionBg}
                        onChange={(e) => setCaptionBg(e.target.value)}
                        className="w-full px-3 py-2 bg-gray-50 border border-gray-200 rounded-lg focus:ring-2 focus:ring-green-500 focus:border-transparent"
                      >
                        <option value="#000000">Black</option>
                        <option value="#FFFFFF">White</option>
                        <option value="#FFE600">Yellow</option>
                        <option value="#FE2C55">Red</option>
                        <option value="#22c55e">Green</option>
                        <option value="transparent">No Background</option>
                      </select>
                    </div> */}
                    {/* <div className="space-y-2">
                      <label className="text-xs text-gray-500">Custom Color</label>
                      <input
                        type="color"
                        value={captionBg !== "transparent" ? captionBg : "#000000"}
                        onChange={(e) => setCaptionBg(e.target.value)}
                        disabled={captionBg === "transparent"}
                        className="w-full h-8 cursor-pointer rounded border border-gray-200"
                      />
                    </div> */}
                  </div>

                  <p className="text-xs text-gray-400 mt-2">These styles will apply to all captions in your video</p>
                </div>
              </div>
            </div>
          </div>
        );

      case 3:
        return (
          <div className="grid grid-cols-1 lg:grid-cols-2 gap-8">
            {/* Left Column - Demo Preview */}
            {/* Left Column - Demo Preview */}
            <div className="flex flex-col items-center">
              {/* Demo Toggle Section - Keep this */}
              <div className="w-full bg-white rounded-xl p-6 shadow-sm border border-gray-200 mb-6">
                <div className="flex items-center justify-between">
                  <h3 className="text-lg font-semibold text-gray-900">Demo Video</h3>
                  <div className="flex items-center gap-2">
                    <p className={`text-sm ${includeDemoVideo ? "text-gray-700" : "text-gray-500"}`}>
                      {includeDemoVideo ? "Include demo video" : "Skip demo video"}
                    </p>
                    <label className="relative inline-flex items-center cursor-pointer">
                      <input
                        type="checkbox"
                        checked={includeDemoVideo}
                        onChange={(e) => setIncludeDemoVideo(e.target.checked)}
                        className="sr-only peer"
                      />
                      <div className="w-11 h-6 bg-gray-200 peer-focus:outline-none peer-focus:ring-2 peer-focus:ring-green-300 rounded-full peer peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:start-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all peer-checked:bg-green-600"></div>
                    </label>
                  </div>
                </div>
                {!includeDemoVideo && (
                  <div className="mt-4 p-4 bg-blue-50 rounded-lg text-blue-800 text-sm">
                    <div className="flex items-start gap-3">
                      <Info className="w-5 h-5 text-blue-500 flex-shrink-0 mt-0.5" />
                      <div>
                        <p className="font-medium mb-1">Demo video is optional</p>
                        <p>Your video will be created using only the hook video. This is a good option for short, impactful videos.</p>
                        <button
                          onClick={() => setIncludeDemoVideo(true)}
                          className="mt-3 text-green-600 hover:text-green-700 font-medium flex items-center gap-1"
                        >
                          <Plus className="w-4 h-4" /> Add a demo video
                        </button>
                      </div>
                    </div>
                  </div>
                )}
              </div>

              {/* Video Preview */}
              <div className="w-[300px] h-[533px] rounded-2xl bg-gray-100 overflow-hidden shadow-lg border border-gray-200 relative">
                {includeDemoVideo && demoVideo ? (
                  <>
                    <video
                      ref={videoRef}
                      className="w-full h-full object-cover"
                      src={demoVideo.s3_url}
                      preload="auto"
                      loop
                      onTimeUpdate={(e) => {
                        setCurrentTime(e.target.currentTime);
                        setDuration(e.target.duration);
                      }}
                      onLoadedData={(e) => {
                        setDuration(e.target.duration);
                        videoRef.current.pause();
                        setIsPlaying(false);
                      }}
                    />

                    {/* TikTok UI Elements */}
                    <div className="absolute inset-y-0 right-4 flex flex-col items-center justify-end gap-4 pb-20 z-10">
                      <button className="p-3 flex flex-col items-center">
                        <div className="w-12 h-12 flex items-center justify-center rounded-full bg-black/40 backdrop-blur-sm mb-1">
                          <Heart className="w-6 h-6 text-white/50" />
                        </div>
                        <span className="text-white/50 text-xs font-medium">87.5K</span>
                      </button>

                      <button className="p-3 flex flex-col items-center">
                        <div className="w-12 h-12 flex items-center justify-center rounded-full bg-black/40 backdrop-blur-sm mb-1">
                          <MessageCircle className="w-6 h-6 text-white/50" />
                        </div>
                        <span className="text-white/50 text-xs font-medium">2,091</span>
                      </button>

                      <button className="p-3 flex flex-col items-center">
                        <div className="w-12 h-12 flex items-center justify-center rounded-full bg-black/40 backdrop-blur-sm mb-1">
                          <Bookmark className="w-6 h-6 text-white/50" />
                        </div>
                        <span className="text-white/50 text-xs font-medium">23.1K</span>
                      </button>

                      <button className="p-3 flex flex-col items-center">
                        <div className="w-12 h-12 flex items-center justify-center rounded-full bg-black/40 backdrop-blur-sm mb-1">
                          <Share className="w-6 h-6 text-white/50" />
                        </div>
                        <span className="text-white/50 text-xs font-medium">Share</span>
                      </button>
                    </div>

                    {/* Timeline Captions */}
                    {timelineCaptions.length > 0 &&
                      timelineCaptions
                        .filter((caption) => Math.abs(caption.time - currentTime) < 0.5)
                        .map((caption, i) => (
                          <div
                            key={i}
                            className={`absolute inset-x-0 ${
                              caption.position === "top" ? "top-8" : caption.position === "center" ? "top-1/2 -translate-y-1/2" : "bottom-8"
                            } px-6 text-center z-20`}
                          >
                            <p
                              className="text-white font-normal px-3 py-1 inline-block rounded-md"
                              style={{
                                backgroundColor: captionBg,
                                fontFamily: "Montserrat, sans-serif",
                                fontSize: fontSize === "small" ? "1rem" : fontSize === "large" ? "1.75rem" : "1.25rem",
                              }}
                            >
                              {caption.text}
                            </p>
                          </div>
                        ))}

                    {/* Play/Pause Button Overlay */}
                    {!isPlaying && (
                      <div
                        className="absolute inset-0 bg-black/30 flex items-center justify-center cursor-pointer z-30"
                        onClick={togglePlayback}
                      >
                        <div className="w-16 h-16 rounded-full bg-white/20 backdrop-blur-sm flex items-center justify-center">
                          <Play className="w-8 h-8 text-white" />
                        </div>
                      </div>
                    )}

                    {/* Video Controls */}
                    <div className="absolute bottom-0 left-0 right-0 p-4 bg-gradient-to-t from-black/80 to-transparent opacity-0 group-hover:opacity-100 transition-opacity z-20">
                      <div
                        ref={progressBarRef}
                        onClick={handleTimelineScrub}
                        className="w-full h-1 bg-white/30 rounded-full mb-4 cursor-pointer relative"
                      >
                        <div
                          className="absolute inset-y-0 left-0 bg-green-400 rounded-full"
                          style={{ width: `${(currentTime / duration) * 100}%` }}
                        />
                      </div>
                      <div className="flex items-center justify-between gap-4">
                        <span className="text-sm text-white">
                          {formatTime(currentTime)} / {formatTime(duration)}
                        </span>
                        <button onClick={togglePlayback} className="p-2 rounded-full bg-white/20 hover:bg-white/30 transition-colors">
                          {isPlaying ? <Pause className="w-5 h-5 text-white" /> : <Play className="w-5 h-5 text-white" />}
                        </button>
                      </div>
                    </div>

                    {/* User Info */}
                    <div className="absolute bottom-0 left-0 right-14 p-4 z-10">
                      <div className="flex items-center gap-3 mb-3">
                        <div className="w-11 h-11 rounded-full bg-gray-600/40 flex-shrink-0" />
                        <span className="text-white/90 font-medium">@username</span>
                      </div>
                      <p className="text-white/75 text-sm leading-5 mb-4">
                        <span className="text-white/60">#fyp #viral #trending</span>
                      </p>
                    </div>
                  </>
                ) : (
                  // Show the hook video if no demo video is selected
                  <video
                    ref={videoRef}
                    className="w-full h-full object-cover"
                    src={selectedTemplate?.s3_url}
                    preload="auto"
                    loop
                    onTimeUpdate={(e) => {
                      setCurrentTime(e.target.currentTime);
                      setDuration(e.target.duration);
                    }}
                  />
                )}
              </div>
            </div>
            {/* Right Column - Demo Video & Timeline Captions */}
            <div className="space-y-6">
              {includeDemoVideo && (
                <div className="bg-white rounded-2xl p-6 shadow-sm border border-gray-200">
                  <div className="flex items-center justify-between mb-6">
                    <h3 className="text-lg font-semibold text-gray-900">Demo Video</h3>
                    {/* Show different actions based on whether we already have a demo video */}
                    {demoVideo ? (
                      <div className="flex items-center gap-2">
                        <button
                          onClick={addTimelineCaption}
                          className="px-4 py-2 rounded-lg bg-green-600 text-white hover:bg-green-700 transition-colors flex items-center gap-2"
                        >
                          <Plus className="w-4 h-4" /> Add Caption
                        </button>
                        <button
                          onClick={() => setDemoVideo(null)}
                          className="p-2 rounded-lg text-gray-500 hover:bg-gray-100"
                          title="Change video"
                        >
                          <X className="w-4 h-4" />
                        </button>
                      </div>
                    ) : (
                      <div className="flex items-center gap-2">
                        {/* <input type="file" id="demo-video-upload" accept="video/*" className="hidden" onChange={handleDemoUpload} /> */}
                        {/* <label
                          htmlFor="demo-video-upload"
                          className="px-4 py-2 rounded-lg bg-green-600 text-white hover:bg-green-700 transition-colors flex items-center gap-2 cursor-pointer"
                        >
                          <UploadCloud className="w-4 h-4" /> Upload New
                        </label> */}
                      </div>
                    )}
                  </div>

                  {/* Demo video selection/upload UI */}
                  {!demoVideo && !uploadingDemo ? (
                    <div className="space-y-6">
                      {/* Previously uploaded videos section */}
                      {userDemoVideos.length > 0 && (
                        <div>
                          <div className="flex items-center justify-between mb-4">
                            <h4 className="text-sm font-medium text-gray-700">Select from your videos</h4>
                            <button
                              onClick={() => {
                                // Show loading state
                                setUserDemoVideos([]);
                                setDemoVideoLoading(true);
                                // Manually refresh the list
                                instance
                                  .get("api/user-demo-videos/")
                                  .then((response) => {
                                    console.log("Refreshed videos:", response.data);
                                    setUserDemoVideos(response.data || []);
                                  })
                                  .catch((error) => console.error("Failed to fetch videos:", error))
                                  .finally(() => setDemoVideoLoading(false));
                              }}
                              className="p-1.5 text-gray-500 hover:text-gray-700 rounded-full hover:bg-gray-100"
                              title="Refresh video list"
                            >
                              <RefreshCw className="w-4 h-4" />
                            </button>
                          </div>
                          {demoVideoLoading ? (
                            <div className="p-8 text-center">
                              <Loader2 className="w-6 h-6 animate-spin text-green-500 mx-auto mb-2" />
                              <p className="text-gray-500 text-sm">Loading your videos...</p>
                            </div>
                          ) : userDemoVideos.length > 0 ? (
                            <div className="grid grid-cols-2 sm:grid-cols-3 gap-4">
                              {userDemoVideos.map((video) => (
                                <div
                                  key={video.id}
                                  onClick={() => {
                                    setDemoVideo(video);
                                    if (videoRef.current) {
                                      videoRef.current.src = video.s3_url;
                                      videoRef.current.load();
                                    }
                                  }}
                                  className="cursor-pointer border border-gray-200 hover:border-green-300 rounded-lg overflow-hidden transition-colors"
                                >
                                  <div className="aspect-video bg-gray-100 relative">
                                    {video.thumbnails?.mobile ? (
                                      <img src={video.thumbnails.mobile} alt="" className="w-full h-full object-cover" />
                                    ) : (
                                      <div className="w-full h-full flex items-center justify-center bg-gray-100">
                                        <FileVideo className="w-10 h-10 text-gray-400" />
                                      </div>
                                    )}
                                    <div className="absolute inset-0 flex items-center justify-center bg-black/30 opacity-0 hover:opacity-100 transition-opacity">
                                      <div className="w-12 h-12 rounded-full bg-white/20 backdrop-blur-sm flex items-center justify-center">
                                        <Play className="w-6 h-6 text-white" />
                                      </div>
                                    </div>
                                  </div>
                                  <div className="p-2">
                                    <p className="text-sm font-medium text-gray-800 truncate">{video.title || `Video ${video.id}`}</p>
                                    <p className="text-xs text-gray-500 flex items-center gap-1">
                                      <Clock className="w-3 h-3" />
                                      {video.metadata?.duration ? formatTime(video.metadata.duration) : "0:30"}
                                    </p>
                                  </div>
                                </div>
                              ))}
                            </div>
                          ) : (
                            <div className="p-6 bg-gray-50 rounded-lg text-center mb-6">
                              <div className="w-12 h-12 bg-gray-100 rounded-full flex items-center justify-center mx-auto mb-3">
                                <FileVideo className="w-6 h-6 text-gray-400" />
                              </div>
                              <p className="text-gray-500">No previously uploaded demo videos found.</p>
                              <p className="text-sm text-gray-400 mt-1">Upload a new video below.</p>
                            </div>
                          )}
                        </div>
                      )}

                      {/* Separator between sections */}
                      {userDemoVideos.length > 0 && (
                        <div className="relative my-8">
                          <div className="absolute inset-0 flex items-center">
                            <div className="w-full border-t border-gray-200"></div>
                          </div>
                          <div className="relative flex justify-center">
                            <span className="px-3 bg-white text-sm text-gray-500">or</span>
                          </div>
                        </div>
                      )}

                      {/* Upload new demo video */}
                      <div className="border-2 border-dashed border-gray-200 rounded-xl p-8 text-center">
                        <div className="mx-auto w-16 h-16 bg-gray-100 rounded-full flex items-center justify-center mb-4">
                          <UploadCloud className="w-8 h-8 text-gray-400" />
                        </div>
                        <h3 className="text-lg font-medium text-gray-700 mb-2">Upload Demo Video</h3>
                        <p className="text-gray-500 mb-6">Add a video showing your product in action</p>

                        <UppyUploader
                          onUploadDone={(data) => {
                            setDemoVideo(data);
                            setIncludeDemoVideo(true);
                            fetchDemoVideos(); // 👈 refresh the list explicitly
                          }}
                        />

                        <p className="text-xs text-gray-500 mt-3">MP4, MOV or WebM formats • max 30MB • 16:9 aspect ratio recommended</p>
                      </div>
                    </div>
                  ) : null}

                  {/* Upload progress indicator */}
                  {uploadingDemo && (
                    <div className="py-10 text-center">
                      <Loader2 className="w-8 h-8 animate-spin text-green-500 mx-auto mb-3" />
                      <p className="text-gray-500 mb-2">Uploading video... {uploadProgress}%</p>
                      <div className="w-full h-2 bg-gray-200 rounded-full overflow-hidden">
                        <div className="h-full bg-green-500 transition-all" style={{ width: `${uploadProgress}%` }} />
                      </div>
                    </div>
                  )}

                  {/* Timeline captions section - Only show when a demo video is selected */}
                  {demoVideo && !uploadingDemo && (
                    <div>
                      <h4 className="text-sm font-medium text-gray-700 mb-4 mt-6">Timeline Captions</h4>
                      {/* Timeline visualization */}
                      <div className="relative w-full h-16 bg-gray-50 rounded-lg mb-6 overflow-hidden">
                        {/* Playback position indicator */}
                        <div
                          className="absolute top-0 bottom-0 w-px bg-red-500 z-10"
                          style={{ left: `${(currentTime / duration) * 100}%` }}
                        />
                        {/* Timeline markers */}
                        <div className="absolute inset-0">
                          {timelineCaptions.map((caption, i) => (
                            <div
                              key={i}
                              className="absolute h-full w-1 bg-green-500 cursor-pointer group"
                              style={{ left: `${(caption.time / duration) * 100}%` }}
                              onClick={() => {
                                if (videoRef.current) videoRef.current.currentTime = caption.time;
                              }}
                            >
                              <div className="absolute bottom-full mb-2 left-1/2 -translate-x-1/2 bg-white px-2 py-1 rounded shadow-lg opacity-0 group-hover:opacity-100 transition-opacity whitespace-nowrap text-sm z-20">
                                {caption.text}
                              </div>
                            </div>
                          ))}
                        </div>
                      </div>

                      {/* Caption list */}
                      <div className="space-y-3 max-h-[300px] overflow-y-auto pr-1">
                        {timelineCaptions.length > 0 ? (
                          timelineCaptions.map((caption, i) => (
                            <div
                              key={i}
                              className="flex items-center gap-4 p-4 bg-gray-50 rounded-xl border border-gray-100 group hover:border-green-200"
                            >
                              <span className="text-sm text-gray-500 min-w-16">{formatTime(caption.time)}</span>
                              <p className="flex-1 text-gray-900">{caption.text}</p>
                              <div className="flex items-center gap-1">
                                <button
                                  onClick={() => {
                                    if (videoRef.current) videoRef.current.currentTime = caption.time;
                                  }}
                                  className="p-1.5 rounded-lg hover:bg-gray-200 text-gray-600 transition-all"
                                  title="Jump to this point"
                                >
                                  <Play className="w-4 h-4" />
                                </button>
                                <button
                                  onClick={() => {
                                    const newCaptions = [...timelineCaptions];
                                    newCaptions.splice(i, 1);
                                    setTimelineCaptions(newCaptions);
                                  }}
                                  className="p-1.5 rounded-lg opacity-0 group-hover:opacity-100 hover:bg-red-50 text-red-500 transition-all"
                                  title="Remove caption"
                                >
                                  <X className="w-4 h-4" />
                                </button>
                              </div>
                            </div>
                          ))
                        ) : (
                          <div className="py-8 text-center">
                            <div className="mx-auto w-14 h-14 bg-gray-100 rounded-full flex items-center justify-center mb-3">
                              <MessageSquare className="w-7 h-7 text-gray-400" />
                            </div>
                            <p className="text-gray-500">No captions added yet</p>
                            <button
                              onClick={addTimelineCaption}
                              className="mt-3 text-green-600 hover:text-green-700 font-medium flex items-center gap-1 mx-auto"
                            >
                              <Plus className="w-4 h-4" /> Add your first caption
                            </button>
                          </div>
                        )}
                      </div>
                    </div>
                  )}
                </div>
              )}

              {/* Video Summary */}
              {/* <div className="bg-white rounded-2xl p-6 shadow-sm border border-gray-200">
                <h3 className="text-lg font-semibold text-gray-900 mb-4">Video Summary</h3>
                <div className="space-y-4">
                  <div className="flex items-center justify-between py-3 border-b border-gray-100">
                    <span className="text-gray-600">Template</span>
                    <span className="font-medium text-gray-900">{selectedTemplate?.title || "None selected"}</span>
                  </div>
                  <div className="flex items-center justify-between py-3 border-b border-gray-100">
                    <span className="text-gray-600">Hook Caption</span>
                    <span className="font-medium text-gray-900">
                      {hookCaption ? (hookCaption.length > 20 ? hookCaption.substring(0, 20) + "..." : hookCaption) : "None"}
                    </span>
                  </div>
                  <div className="flex items-center justify-between py-3 border-b border-gray-100">
                    <span className="text-gray-600">Demo Video</span>
                    <span className="font-medium text-gray-900">
                      {includeDemoVideo ? (demoVideo ? "Included" : "Not uploaded") : "Not included"}
                    </span>
                  </div>
                  <div className="flex items-center justify-between py-3 border-b border-gray-100">
                    <span className="text-gray-600">Timeline Captions</span>
                    <span className="font-medium text-gray-900">{timelineCaptions.length}</span>
                  </div>
                  <div className="flex items-center justify-between py-3">
                    <span className="text-gray-600">Background Music</span>
                    <span className="font-medium text-gray-900">{backgroundMusic?.title || "None selected"}</span>
                  </div>
                </div>
              </div> */}
            </div>
          </div>
        );

      default:
        return null;
    }
  };

  return (
    <div className="min-h-screen bg-gray-50">
      {/* Header */}
      <header className="h-16 border-b border-gray-100 flex items-center px-8 sticky top-0 bg-white/95 backdrop-blur-sm z-40">
        <div className="max-w-6xl w-full mx-auto flex justify-between">
          <span className="font-semibold text-xl text-green-600">FeedbackbyAI</span>
          <div className="flex items-center gap-4">
            {/* <div className="bg-green-100 text-green-800 py-1 px-3 rounded-full text-sm flex items-center">
              <Video className="w-4 h-4 mr-1" />
              <span>{userCredits.video_credits} credits</span>
            </div> */}
            <CreditsBadge credits={userCredits.video_credits} />
            <Link to="/video-library" className="text-gray-500 hover:text-gray-700 flex items-center gap-1">
              <FileVideo className="w-4 h-4" />
              <span>My Videos</span>
            </Link>
            <Link to="/dashboard" className="text-gray-500 hover:text-gray-700">
              Back to Dashboard
            </Link>
          </div>
        </div>
      </header>

      <main className="max-w-6xl mx-auto px-6 py-12">
        {/* Page Header */}
        <div className="flex flex-col items-center justify-center mb-10">
          <div className="w-20 h-20 bg-green-50 rounded-full flex items-center justify-center mb-6">
            <Video className="w-10 h-10 text-green-600" />
          </div>
          <h1 className="text-3xl font-bold text-gray-900 text-center mb-2">Video Creator</h1>
          <p className="text-center text-gray-600 max-w-2xl mx-auto">
            Create engaging product videos with beautiful templates and captions
          </p>
        </div>

        {/* Step Indicator */}
        <div className="mb-8">
          <div className="flex items-center justify-between max-w-xl mx-auto">
            {[
              { num: 1, label: "Choose Template" },
              { num: 2, label: "Add Hook Caption" },
              { num: 3, label: "Demo & Timeline" },
            ].map(({ num, label }) => (
              <React.Fragment key={num}>
                {num > 1 && (
                  <div className="flex-1 h-1 bg-gray-200 relative">
                    <div
                      className="absolute inset-0 bg-green-500 transition-all duration-300"
                      style={{
                        transform: currentStep >= num ? "scaleX(1)" : "scaleX(0)",
                        transformOrigin: "left",
                      }}
                    ></div>
                  </div>
                )}
                <button
                  onClick={() => num <= currentStep && setCurrentStep(num)}
                  className={`flex flex-col items-center group ${num > currentStep ? "cursor-default" : "cursor-pointer"}`}
                >
                  <div
                    className={`w-10 h-10 rounded-full flex items-center justify-center mb-2 transition-all ${
                      currentStep > num
                        ? "bg-green-500 text-white"
                        : currentStep === num
                        ? "bg-green-600 text-white"
                        : "bg-gray-100 text-gray-400"
                    }`}
                  >
                    {currentStep > num ? <CheckCircle2 className="w-5 h-5" /> : num}
                  </div>
                  <span className={`text-sm font-medium ${currentStep >= num ? "text-gray-900" : "text-gray-500"}`}>{label}</span>
                </button>
              </React.Fragment>
            ))}
          </div>
        </div>

        {/* Main Content */}
        <div className="mb-12">{renderStepContent()}</div>

        {/* Action Buttons */}
        {/* <div className="flex justify-end gap-3 max-w-6xl mx-auto">
          {currentStep > 1 && (
            <button
              onClick={() => setCurrentStep(currentStep - 1)}
              className="px-6 py-2.5 rounded-lg border border-gray-300 text-gray-700 font-medium hover:bg-gray-50"
            >
              Back
            </button>
          )}

          {currentStep < 3 ? (
            <button
              onClick={() => {
                // Validation checks
                if (currentStep === 1 && !selectedTemplate) {
                  alert("Please select a template");
                  return;
                }

                if (currentStep === 2 && !hookCaption.trim()) {
                  alert("Please add a hook caption");
                  return;
                }

                setCurrentStep(currentStep + 1);
              }}
              className="px-6 py-2.5 rounded-lg bg-green-600 text-white font-medium hover:bg-green-700 flex items-center gap-2"
            >
              Next <ArrowRight className="w-4 h-4" />
            </button>
          ) : (
            <button
              onClick={handleCreateVideo}
              disabled={processing}
              className={`px-6 py-2.5 rounded-lg font-medium flex items-center gap-2 ${
                processing ? "bg-gray-200 text-gray-500 cursor-not-allowed" : "bg-green-600 text-white hover:bg-green-700"
              }`}
            >
              {processing ? (
                <>
                  <Loader2 className="w-4 h-4 animate-spin" />
                  Processing...
                </>
              ) : (
                <>
                  <Zap className="w-4 h-4" />
                  Create Video
                </>
              )}
            </button>
          )}
        </div> */}
        {/* Action Buttons */}
        <div className="flex justify-end gap-3 max-w-6xl mx-auto">
          {currentStep > 1 && (
            <button
              onClick={() => setCurrentStep(currentStep - 1)}
              className="px-6 py-2.5 rounded-lg border border-gray-300 text-gray-700 font-medium hover:bg-gray-50"
            >
              Back
            </button>
          )}

          {currentStep < 3 ? (
            <button
              onClick={() => {
                // Validation checks
                if (currentStep === 1 && !selectedTemplate) {
                  showNotification("Missing Template", "Please select a template", "error");
                  return;
                }

                if (currentStep === 2 && !hookCaption.trim()) {
                  showNotification("Missing Caption", "Please add a hook caption", "error");
                  return;
                }

                setCurrentStep(currentStep + 1);
              }}
              className="px-6 py-2.5 rounded-lg bg-green-600 text-white font-medium hover:bg-green-700 flex items-center gap-2"
            >
              Next <ArrowRight className="w-4 h-4" />
            </button>
          ) : (
            <button
              onClick={handleCreateVideo}
              disabled={processing}
              className={`px-6 py-2.5 rounded-lg font-medium flex items-center gap-2 ${
                processing ? "bg-gray-200 text-gray-500 cursor-not-allowed" : "bg-green-600 text-white hover:bg-green-700"
              }`}
            >
              {processing ? (
                <>
                  <Loader2 className="w-4 h-4 animate-spin" />
                  Processing...
                </>
              ) : (
                <>
                  <Zap className="w-4 h-4" />
                  Create Video
                </>
              )}
            </button>
          )}
        </div>
      </main>

      {/* Custom Template Upload Modal */}
      {showCustomTemplateModal && (
        <div className="fixed inset-0 z-50 flex items-center justify-center">
          <div className="absolute inset-0 bg-black/60 backdrop-blur-sm" onClick={() => setShowCustomTemplateModal(false)} />
          <div className="relative bg-white rounded-2xl w-full max-w-md mx-4 shadow-xl">
            <div className="p-6">
              <div className="flex items-center justify-between mb-6">
                <h3 className="text-xl font-semibold text-gray-900">Create Custom Template</h3>
                <button onClick={() => setShowCustomTemplateModal(false)} className="p-2 hover:bg-gray-100 rounded-lg transition-colors">
                  <X className="w-5 h-5" />
                </button>
              </div>

              <div className="space-y-6">
                <div>
                  <label className="block text-sm font-medium text-gray-700 mb-2">Template Name</label>
                  <input
                    type="text"
                    placeholder="My Custom Template"
                    className="w-full px-4 py-3 rounded-xl bg-gray-50 border border-gray-200 focus:ring-2 focus:ring-green-500 focus:border-transparent"
                  />
                </div>

                <div>
                  <label className="block text-sm font-medium text-gray-700 mb-2">Upload Video</label>
                  <div className="border-2 border-dashed border-gray-300 rounded-xl p-6 text-center">
                    <div className="mx-auto w-16 h-16 bg-gray-100 rounded-full flex items-center justify-center mb-3">
                      <UploadCloud className="w-8 h-8 text-gray-400" />
                    </div>
                    <p className="text-gray-500 mb-3">Drag and drop your video here, or click to browse</p>
                    <button className="px-4 py-2 bg-green-600 text-white rounded-lg hover:bg-green-700 inline-flex items-center gap-2">
                      <UploadCloud className="w-4 h-4" /> Select Video
                    </button>
                    <p className="text-xs text-gray-500 mt-3">Recommended: 9:16 aspect ratio, max 30 seconds</p>
                  </div>
                </div>

                <div>
                  <label className="block text-sm font-medium text-gray-700 mb-2">Description (optional)</label>
                  <textarea
                    placeholder="Describe your template"
                    className="w-full h-24 px-4 py-3 rounded-xl bg-gray-50 border border-gray-200 focus:ring-2 focus:ring-green-500 focus:border-transparent resize-none"
                  ></textarea>
                </div>

                <div className="flex justify-end gap-3">
                  <button
                    onClick={() => setShowCustomTemplateModal(false)}
                    className="px-4 py-2 rounded-lg border border-gray-300 text-gray-700"
                  >
                    Cancel
                  </button>
                  <button className="px-4 py-2 rounded-lg bg-green-600 text-white">Upload & Create</button>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}

      {/* Processing Modal */}
      {processing && (
        <div className="fixed inset-0 z-50 flex items-center justify-center">
          <div className="absolute inset-0 bg-black/60 backdrop-blur-sm" />
          <div className="relative bg-white rounded-2xl w-full max-w-md mx-4 shadow-xl p-6">
            {/* Preview/Thumbnail */}
            <div className="aspect-[9/16] w-full rounded-xl overflow-hidden bg-gray-100 mb-6">
              <div className="w-full h-full flex flex-col items-center justify-center p-4 relative">
                <div className="absolute flex flex-col items-center">
                  <Loader2 className="w-12 h-12 animate-spin text-green-500 mb-3" />
                  <p className="text-center text-gray-700 font-medium">Processing your video...</p>
                  <p className="text-sm text-gray-500 mt-2">You'll be notified when it's ready</p>
                </div>
              </div>
            </div>

            {/* Tips */}
            <div className="bg-blue-50 rounded-lg p-4 border border-blue-100">
              <h4 className="font-medium text-blue-800 mb-2">While you wait</h4>
              <p className="text-sm text-blue-700">
                You can continue browsing or create another video while this one processes. We'll notify you when your video is ready!
              </p>

              {/* Action to minimize or continue working */}
              <div className="mt-3 flex justify-end">
                <button
                  onClick={() => {
                    // Keep processing in background but hide modal
                    document.getElementById("notification-container")?.appendChild(
                      Object.assign(document.createElement("div"), {
                        className: "fixed bottom-4 right-4 bg-green-50 p-4 rounded-lg shadow-lg border border-green-200",
                        innerHTML: `
                    <div class="flex items-center">
                      <div class="mr-3">
                        <Loader2 class="w-5 h-5 text-green-500 animate-spin" />
                      </div>
                      <div>
                        <p class="text-sm font-medium text-green-800">Processing in background</p>
                        <p class="text-xs text-green-600">We'll notify you when your video is ready</p>
                      </div>
                    </div>
                  `,
                      })
                    );

                    // Hide modal but keep processing state
                    const modalElement = document.querySelector(".fixed.inset-0.z-50");
                    if (modalElement) {
                      modalElement.style.display = "none";
                    }
                  }}
                  className="text-sm text-green-700 hover:text-green-800 font-medium"
                >
                  Continue Working
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default VideoCreator;
